<template>
  <div class="modal-body">
    <Multiselect
      v-model="selected"
      :options="options"
      label="name"
      track-by="id"
      placeholder="Select saved settings"
      @input="onSettingsSelected"
    />
    <b-table
      v-if="selected"
      class="mt-3"
      :items="items"
      :fields="fields"
      sticky-header="70vh"
      hover
      small
    />
  </div>
</template>

<script>
import {
  EVENTS,
  feedImportHeaderMappingEventBroker
} from "@frontend/group/modules/feed-import-header-mapping/event-broker";
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
  },
  data: () => ({
    items: [],
    selected: null,
    options: [],
    currentHeaders: [],
    fields: [
      { key: 'header', sortable: true },
      { key: 'saved_header', sortable: true },
      { key: 'current_header', sortable: true },
    ]
  }),
  computed: {
    currentSetting() {
      return this.options.find(item => item.id === this.selected?.id)
    },
  },
  mounted() {
    feedImportHeaderMappingEventBroker.on(EVENTS.INIT, this.init.bind(this))
  },
  methods: {
    init(data) {
      this.selected = null;
      this.items = [];
      this.options = data.feedImportMappingsDropdownItems.filter(item => item.disabled).map(item => ({...item, disabled: false}))
      this.currentHeaders = data.xls_feed_file_headers
    },
    onSettingsSelected() {
      const rows = [...this.currentSetting.xlsx_headers]
      rows.push(...this.currentHeaders.filter(header => !rows.includes(header)))
      this.items = rows.map(row => ({
        header: row,
        saved_header: this.currentSetting.xlsx_headers.includes(row) ? '✅' : '❌',
        current_header: this.currentHeaders.includes(row) ? '✅' : '❌',
      }))
    },
  }
}
</script>

<style lang="scss">
.m-feed-import-header-mapping_overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: #000;
  opacity: 0.5;
}
</style>
