var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { label: "Field Name" } },
        [
          _c("b-form-input", {
            attrs: { placeholder: "Enter field name", trim: "" },
            on: {
              input: function ($event) {
                return _vm.onChangeValue("name")
              },
            },
            model: {
              value: _vm.inputFields.name,
              callback: function ($$v) {
                _vm.$set(_vm.inputFields, "name", $$v)
              },
              expression: "inputFields.name",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.inputFields.isShowAngle,
              expression: "inputFields.isShowAngle",
            },
          ],
          attrs: { label: "Angle" },
        },
        [
          _c("b-form-input", {
            attrs: { type: "number", placeholder: "Enter angle" },
            on: {
              change: function ($event) {
                return _vm.onChangeValue("angle")
              },
            },
            model: {
              value: _vm.inputFields.angle,
              callback: function ($$v) {
                _vm.$set(_vm.inputFields, "angle", _vm._n($$v))
              },
              expression: "inputFields.angle",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Link" } },
        [
          _c("b-form-input", {
            attrs: { placeholder: "Enter link", trim: "" },
            on: {
              change: function ($event) {
                return _vm.onChangeValue("link")
              },
            },
            model: {
              value: _vm.inputFields.link,
              callback: function ($$v) {
                _vm.$set(_vm.inputFields, "link", $$v)
              },
              expression: "inputFields.link",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !!_vm.inputFields.notes
        ? _c(
            "b-form-group",
            { attrs: { label: "Notes" } },
            [
              _c("b-form-input", {
                attrs: { trim: "", disabled: "" },
                on: {
                  change: function ($event) {
                    return _vm.onChangeValue("link")
                  },
                },
                model: {
                  value: _vm.inputFields.notes,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputFields, "notes", $$v)
                  },
                  expression: "inputFields.notes",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }