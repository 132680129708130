var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "import-hyperlinks-modal",
        title: "Import Hyperlinks",
        centered: "",
        size: "lg",
      },
      on: { ok: _vm.handleOk, hide: _vm.handleClose },
    },
    [
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Upload JSON file" } },
                [
                  _c("b-form-file", {
                    attrs: {
                      placeholder: "Choose JSON file or drop it here...",
                      "drop-placeholder": "Drop file here...",
                      accept: ".json",
                    },
                    on: { change: _vm.handleFileChange },
                    model: {
                      value: _vm.file,
                      callback: function ($$v) {
                        _vm.file = $$v
                      },
                      expression: "file",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [_c("b-table", { attrs: { items: _vm.hyperlinks } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }