<template>
  <div
    ref="walmartFolderTreeSelect"
    class="folder-tree-select"
  >
    <b-form-input
      v-model="inputVal"
      :placeholder="placeholder"
      @click="handleOpenDropdown"
      @input="handleSearchFolder"
    />

    <b-icon
      icon="triangle-fill"
      class="folder-tree-select__triangle-icon"
      :class="{ 'folder-tree-select__triangle-icon--opened': isVisible }"
    />

    <b-icon
      v-if="selectedFolder"
      icon="x"
      class="folder-tree-select__remove-icon"
      @click="handleRemove"
    />

    <div
      class="folder-tree-select__dropdown"
      :class="{ 'folder-tree-select__dropdown--opened': isVisible }"
    >
      <template v-if="folders.length">
        <WalmartFolderTreeItem
          v-for="folder in folders"
          :key="folder.id"
          :item="folder"
          :selected="selectedFolder"
          :platform-id="platformId"
          :company-id="companyId"
          @change="handleFolderChange"
        />
      </template>
      <template v-else>
        <div class="px-2">
          No folders found.
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import WalmartFolderTreeItem from "@frontend/components/common/atoms/WalmartFolderTreeSelect/WalmartFolderTreeItem.vue";
import { editorControlEventBroker, EVENTS } from '@frontend/group/modules/editor-control-popup/event-broker';

const INPUT_SEARCH_PLACEHOLDER = 'Select folder';

export default {
  name: 'WalmartFolderTree',
  components: { WalmartFolderTreeItem },
  props: {
    defaultFolder: {
      type: Object,
      required: true
    },
    nestedFolders: {
      type: Array,
      required: true
    },
    platformId: {
      type: Number,
      required: true
    },
    companyId: {
      type: Number,
      required: false,
      default: null
    },
    newFolder: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      inputVal: this.defaultFolder.name || '',
      selectedFolder: this.defaultFolder,
      isVisible: false,
      placeholder: INPUT_SEARCH_PLACEHOLDER,
      folders: this.nestedFolders
    }
  },
  mounted() {
    const folder = this.newFolder ? this.newFolder : this.defaultFolder;

    this.handleFolderChange(folder);

    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    handleFolderChange(folder) {
      this.selectedFolder = folder;
      this.inputVal = folder?.name || null;
      this.isVisible = false;
      this.$emit('input', folder);

      editorControlEventBroker.fire(EVENTS.WALMART_FOLDER_CHANGED, { folder: folder });
    },

    handleClickOutside(event) {
      const element = this.$refs.walmartFolderTreeSelect;
      if (element && !element.contains(event.target)) {
        this.isVisible = false;
        this.inputVal = this.selectedFolder?.name || '';
      }
    },

    handleOpenDropdown() {
      if (this.isVisible) return;
      this.isVisible = true;
      this.inputVal = '';
      this.folders = this.nestedFolders;
    },

    handleSearchFolder(value) {
      this.folders = this.nestedFolders.filter(item =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
    },

    handleRemove() {
      this.inputVal = '';
      this.selectedFolder = null;
      this.handleFolderChange(this.defaultFolder);
    }
  }
}
</script>

<style lang="scss" scoped>
.folder-tree-select {
  position: relative;
  width: 364.14px;

  input {
    cursor: pointer;
  }

  &__dropdown {
    display: none;
    position: absolute;
    width: 100%;
    background: white;
    padding: 5px 0;
    border: 1px solid #ced4daed;
    border-radius: 3px;
    box-sizing: border-box;
    max-height: 300px;
    overflow: auto;
    z-index: 100;

    &--opened {
      display: block;
    }
  }

  &__triangle-icon,
  &__remove-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #6c6e71ed;
  }

  &__remove-icon {
    font-size: 15px;
    right: 25px;
    cursor: pointer;

    &:hover {
      color: #209c86;
    }
  }

  &__triangle-icon {
    position: absolute;
    right: 12px;
    font-size: 7px;
    transform: translateY(-50%) rotate(180deg);

    &--opened {
      transform: translateY(-50%) rotate(0);
    }
  }
}
</style>
