var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { id: _vm.modalId, title: "Crop to image" },
      on: { hide: _vm.closeHandler },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "ButtonPrimary",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.accept.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n      Yes\n    ")]
              ),
              _vm._v(" "),
              _c(
                "ButtonOutline",
                {
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.closeHandler.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n      No\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "b-form-group",
        [
          _c(
            "b-form-radio",
            {
              attrs: { value: false },
              model: {
                value: _vm.isReplace,
                callback: function ($$v) {
                  _vm.isReplace = $$v
                },
                expression: "isReplace",
              },
            },
            [_vm._v("\n      Copy before crop\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-form-radio",
            {
              attrs: { value: true },
              model: {
                value: _vm.isReplace,
                callback: function ($$v) {
                  _vm.isReplace = $$v
                },
                expression: "isReplace",
              },
            },
            [_vm._v("\n      Replace image file after crop\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }