export const WALMART = 'walmart';

export const WALMART_API_TYPES = {
    UPLOAD_CREATIVE_IMAGE: 'upload_creative_image',
    CREATE_THE_CREATIVES: 'create_the_creatives',
    CREATE_FOLDER: 'create_folder',
    LIST_ALL_THE_FOLDERS: 'list_all_the_folders',
    REQUEST_CREATIVE_PREVIEW: 'request_creative_preview',
    PREVIEW_STATUS: 'preview_status',
    RETRIEVE_PREVIEW_URL: 'retrieve_preview_url',
    RETRIEVE_CREATIVE_PREVIEW_FOR_EACH_ADUNIT: 'retrieve_creative_preview_for_each_adUnit',
    UPDATE_CREATIVE: 'update_creative',
    UPDATE_THE_FOLDER: 'update_the_folder',
    UPLOAD_MEDIA: 'media_upload_media',
    RETRIEVE_SNAPSHOTS: 'report_retrieve_snapshots',
};

export const adUnits = {
    BRANDBOX_DESKTOP: 'brandboxDesktop',
    BRANDBOX_APP: 'brandboxApp',
    GALLERY_DESKTOP: 'galleryDesktop',
    GALLERY_APP: 'galleryApp',
    MARQUEE_DESKTOP: 'marqueeDesktop',
    MARQUEE_APP: 'marqueeApp',
    SKYLINE_DESKTOP: 'skylineDesktop',
    // SKYLINE_APP: 'skylineApp',
    LOGO: 'logo',
};

export const adUnitsDimensions = {
    '1040x246': adUnits.BRANDBOX_DESKTOP,
    '792x260': adUnits.BRANDBOX_APP,
    '788x444': adUnits.GALLERY_DESKTOP,
    '432x242': adUnits.GALLERY_APP,
    '1510x356': adUnits.MARQUEE_DESKTOP,
    '508x260': adUnits.MARQUEE_APP,
    '1232x104': adUnits.SKYLINE_DESKTOP,
    // '508x260': SKYLINE_APP,
    '150x150': adUnits.LOGO
};

export const adUnitTypes = {
    [adUnits.GALLERY_DESKTOP]: 'Gallery Desktop',
    [adUnits.GALLERY_APP]: 'Gallery App',
    [adUnits.BRANDBOX_DESKTOP]: 'Brandbox Desktop',
    [adUnits.BRANDBOX_APP]: 'Brandbox App',
    [adUnits.MARQUEE_DESKTOP]: 'Marquee Desktop',
    [adUnits.MARQUEE_APP]: 'Marquee App',
    [adUnits.SKYLINE_DESKTOP]: 'Skyline Desktop',
    [adUnits.SKYLINE_APP]: 'Skyline App',
};

export const ASSET_TYPES = {
    LOGO: 'logo',
    LIFESTYLE: 'lifestyle',
}
