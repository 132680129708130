var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-grid-view-item",
      attrs: { title: _vm.getTitle(_vm.item) },
      on: {
        dragstart: function ($event) {
          return _vm.$emit("dragstart", $event)
        },
        drop: _vm.handleDrop,
        dragover: _vm.handleDragEnter,
        dragleave: _vm.handleDragLeave,
      },
    },
    [
      _vm.isTooltipVisible
        ? _c("div", { staticClass: "tooltip" }, [
            _vm._v("\n    " + _vm._s(_vm.tooltip) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "c-grid-view-item__preview",
          class: { active: _vm.isSelected },
          on: {
            click: function ($event) {
              return _vm.$emit("click", _vm.item)
            },
            dblclick: function ($event) {
              return _vm.$emit("dblclick", _vm.item)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "c-grid-view-item__preview--item",
              class: { active: _vm.isSelected || _vm.isDragOver },
            },
            [
              _vm.isPsdParsed
                ? _c("PSDParsedBadge", { attrs: { left: "5", top: "5" } })
                : _vm._e(),
              _vm._v(" "),
              _vm.item.is_favorite
                ? _c("b-icon", {
                    staticClass: "favorite-icon",
                    attrs: { icon: "bookmark-fill" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ItemView", { attrs: { item: _vm.item } }),
              _vm._v(" "),
              _vm.item.is_processing
                ? _c(
                    "div",
                    {
                      staticClass: "c-grid-view-item__preview--progress w-100",
                    },
                    [
                      _c("b-progress", {
                        staticClass: "a-progressing__bar",
                        attrs: {
                          value: _vm.item.percent,
                          variant: "success",
                          striped: "",
                          animated: "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c-grid-view-item__preview--overlay" },
                [
                  _vm.isAvailableOverlay && _vm.item.name !== "..."
                    ? _c("ItemOverlay", { attrs: { item: _vm.item } })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.item.type === "folder"
                ? _c("div", { staticClass: "c-grid-view-item__folder_name" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.truncatedName) + "\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.item.type !== "folder"
        ? _c("div", { staticClass: "c-grid-view-item__name" }, [
            _vm._v("\n    " + _vm._s(_vm.item.name) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }