var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-grid-view",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.clearSelection.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "c-grid-view__controls mb-3" },
        [
          _c(
            "b-button-group",
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.toggleSelectAll },
                },
                [
                  _c("i", {
                    class: [
                      "fas",
                      "mr-1",
                      _vm.selectedItems.length
                        ? "fa-square"
                        : "fa-check-square",
                    ],
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.selectedItems.length ? "Unselect All" : "Select All"
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.folderItems.length,
              expression: "folderItems.length",
            },
          ],
          staticClass: "c-grid-view__container folder-items pb-3",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.clearSelection.apply(null, arguments)
            },
          },
        },
        _vm._l(_vm.folderItems, function (folderItem) {
          return _c("GridItem", {
            key: folderItem.id,
            attrs: {
              item: folderItem,
              "is-selected": _vm.isSelected(folderItem),
              "is-available-overlay": false,
              draggable: _vm.isDragAndDropAvailable,
            },
            on: {
              click: _vm.itemClickHandled,
              dblclick: _vm.dbClickHandler,
              dragstart: function ($event) {
                return _vm.onDragstart($event, folderItem)
              },
              drop: function ($event) {
                $event.stopPropagation()
                return _vm.onDrop($event, folderItem)
              },
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "c-grid-view__container other-items",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.clearSelection.apply(null, arguments)
            },
          },
        },
        [
          _vm._l(_vm.otherItems, function (otherItem) {
            return _c("GridItem", {
              key: otherItem.id,
              attrs: {
                item: otherItem,
                "is-selected": _vm.isSelected(otherItem),
                "is-available-overlay": _vm.isItemOverlayEnabled,
                draggable: _vm.isDragAndDropAvailable,
              },
              on: {
                click: _vm.itemClickHandled,
                dblclick: _vm.dbClickHandler,
                dragstart: function ($event) {
                  return _vm.onDragstart($event, otherItem)
                },
                drop: function ($event) {
                  $event.stopPropagation()
                  return _vm.onDrop($event, otherItem)
                },
              },
            })
          }),
          _vm._v(" "),
          _vm.showItemAdd
            ? _c("GridItemAdd", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                attrs: { title: "Add more product images" },
                on: { click: _vm.addItemHandle, dblclick: _vm.addItemHandle },
              })
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }