export class SettingsTemplateData {

    fields = [];

    constructor(data = {}) {
      this.setData(data);
    }

    setData(data = {}) {
      this.fields = data?.fields || [];
    }

    addField(fieldData) {
      this.fields = this.fields.filter(field => field.id !== fieldData.id);

      this.fields.push(fieldData);
    }

    getValueOfField(id, key, defaultName = '') {
      const field = this.fields.find(field => field.id === id);
      const value = field?.[key];

      return value !== undefined  ? value : defaultName;
    }

    getNameById(id, defaultName = '') {
      return this.fields.find(field => field.id === id)?.name || defaultName;
    }

    getLinkById(id, defaultLink = '') {
      const link = this.fields.find(field => field.id === id)?.link;

      return link !== undefined ? link : defaultLink;
    }

    toJSON(){
      return JSON.stringify({
        fields: this.fields
      })
    }
}
