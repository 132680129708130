var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative", "z-index": "999999" } },
    _vm._l(_vm.MODALS_COMPONENTS_MAP, function (component, key) {
      return _c(component, {
        key: key,
        ref: key,
        refInFor: true,
        tag: "component",
        attrs: { payload: _vm.modal.payload },
        on: { close: _vm.freeModal },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }