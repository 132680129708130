var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { label: "Transformation type" } },
        [
          _c("b-form-select", {
            attrs: { options: _vm.transformationOptions },
            model: {
              value: _vm.selectedTransformation,
              callback: function ($$v) {
                _vm.selectedTransformation = $$v
              },
              expression: "selectedTransformation",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedTransformation === "columns-multiplier"
        ? _c(
            "b-form-group",
            { attrs: { label: "Select columns to multiply" } },
            [
              _c("Multiselect", {
                attrs: { options: _vm.xlsxHeaders, multiple: true },
                model: {
                  value: _vm.headersToMultiply,
                  callback: function ($$v) {
                    _vm.headersToMultiply = $$v
                  },
                  expression: "headersToMultiply",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTransformation === "columns-concatenation"
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Select columns to concatenate" } },
                    [
                      _c("Multiselect", {
                        attrs: {
                          options: _vm.xlsxHeaders,
                          multiple: true,
                          "close-on-select": false,
                        },
                        model: {
                          value: _vm.headersToConcatenate,
                          callback: function ($$v) {
                            _vm.headersToConcatenate = $$v
                          },
                          expression: "headersToConcatenate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: { label: "Character/string to concatenate with" },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          placeholder: "Character/string to concatenate with",
                        },
                        model: {
                          value: _vm.headersDelimiter,
                          callback: function ($$v) {
                            _vm.headersDelimiter = $$v
                          },
                          expression: "headersDelimiter",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.productDescriptionIsVisible
        ? _c(
            "b-form-group",
            { attrs: { label: "Output words" } },
            [
              _c("b-form-input", {
                attrs: { type: "number", placeholder: "Output words" },
                model: {
                  value: _vm.outputWords,
                  callback: function ($$v) {
                    _vm.outputWords = $$v
                  },
                  expression: "outputWords",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.formSelectSeparatorIsVisible
        ? _c(
            "b-form-group",
            { attrs: { label: "Delimiter character" } },
            [
              _c("b-form-input", {
                attrs: { type: "text", placeholder: "Delimiter character" },
                model: {
                  value: _vm.delimiterCharacter,
                  callback: function ($$v) {
                    _vm.delimiterCharacter = $$v
                  },
                  expression: "delimiterCharacter",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.formSelectCharacterIsVisible
        ? _c(
            "b-form-group",
            { attrs: { label: "Character" } },
            [
              _c("b-form-input", {
                attrs: { type: "text", placeholder: "Enter the character" },
                model: {
                  value: _vm.lineBreakCharacter,
                  callback: function ($$v) {
                    _vm.lineBreakCharacter = $$v
                  },
                  expression: "lineBreakCharacter",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.formSelectSubstringIsVisible
        ? _c(
            "b-form-group",
            { attrs: { label: "Text to add" } },
            [
              _c("b-form-input", {
                attrs: {
                  type: "text",
                  placeholder: "Enter the character/string to append/prepend",
                },
                model: {
                  value: _vm.substring,
                  callback: function ($$v) {
                    _vm.substring = $$v
                  },
                  expression: "substring",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.formSelectConfigurableBulletsIsVisible
        ? _c(
            "div",
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.bulletsOptions },
                    model: {
                      value: _vm.bulletsToAdd,
                      callback: function ($$v) {
                        _vm.bulletsToAdd = $$v
                      },
                      expression: "bulletsToAdd",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.addLineJumpIsVisible
                ? _c(
                    "b-form-group",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "ml-3",
                          model: {
                            value: _vm.addLineJump,
                            callback: function ($$v) {
                              _vm.addLineJump = $$v
                            },
                            expression: "addLineJump",
                          },
                        },
                        [_vm._v("\n        Add line jump\n      ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.customTextIsVisible
                ? _c(
                    "b-form-group",
                    { attrs: { label: "Custom text:" } },
                    [
                      _c("b-form-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.bulletsCustom,
                          callback: function ($$v) {
                            _vm.bulletsCustom = $$v
                          },
                          expression: "bulletsCustom",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: { label: "Bullet Color (#000001 to use text color):" },
                },
                [
                  _c("b-form-input", {
                    attrs: { type: "color", placeholder: "Bullet color" },
                    model: {
                      value: _vm.bulletColor,
                      callback: function ($$v) {
                        _vm.bulletColor = $$v
                      },
                      expression: "bulletColor",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.formCustomScriptIsVisible,
              expression: "formCustomScriptIsVisible",
            },
          ],
        },
        [
          _c(
            "b-form-group",
            [
              _c("b-form-select", {
                attrs: { options: _vm.savedScriptsOptions },
                model: {
                  value: _vm.transformationScript,
                  callback: function ($$v) {
                    _vm.transformationScript = $$v
                  },
                  expression: "transformationScript",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Custom script" } },
            [
              !_vm.codeEditor
                ? _c("b-form-textarea", {
                    attrs: { value: _vm.customScript, rows: "10" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                ref: "customScriptCodeEditor",
                staticClass: "custom-script-code-editor",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: { click: _vm.testScript },
                },
                [_vm._v("\n        Test script\n      ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: { click: _vm.saveScript },
                },
                [_vm._v("\n        Save script\n      ")]
              ),
              _vm._v(" "),
              _vm.transformationScript
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "danger" },
                      on: { click: _vm.deleteScript },
                    },
                    [_vm._v("\n        Delete script\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customScriptOutputVisible,
                  expression: "customScriptOutputVisible",
                },
              ],
              staticClass: "card custom-script-output",
            },
            [
              _c("div", { staticClass: "card-header" }, [
                _vm._v("\n        Output:\n      "),
              ]),
              _vm._v(" "),
              _c("div", {
                ref: "customScriptOutput",
                staticClass: "card-body",
                staticStyle: { "white-space": "pre-wrap" },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }