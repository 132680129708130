import {PARSED_FIELD_TYPES} from "@/includes/parse_psd_data/constants";
import MultiLineText from "@/includes/parse_psd_data/psd-types/multi-line-text";
import BackgroundImage from "@/includes/parse_psd_data/psd-types/background-image";
import Template from "@/includes/parse_psd_data/template";
import UserText from "@/includes/parse_psd_data/psd-types/user-text";
import UserImage from "@/includes/parse_psd_data/psd-types/user-image";
import {Folder} from "@/includes/parse_psd_data/psd-types/folder";

export function getInstanceByType(field) {
  let instance = undefined;
  switch (field.type) {
    case PARSED_FIELD_TYPES.MULTI_LINE_TEXT:
      instance = new MultiLineText();
      break;
    case PARSED_FIELD_TYPES.BACKGROUND_IMAGE:
      instance = new BackgroundImage();
      break;
    case PARSED_FIELD_TYPES.FOLDER:
      instance = new Folder();
      break;
    case PARSED_FIELD_TYPES.TEMPLATE:
      instance = new Template()
      break;
    case PARSED_FIELD_TYPES.USER_TEXT:
      instance = new UserText();
      break;
    case PARSED_FIELD_TYPES.USER_IMAGE:
      instance = new UserImage();
      break;
    default:
      return undefined;
  }

  if (instance) {
    instance.buildFromData(field)
  }

  return instance
}