var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      staticClass: "import-mappings-modal",
      attrs: {
        id: _vm.MODAL_ID,
        "ok-title": "Import",
        "cancel-title": "Close",
        size: "xl",
      },
      on: { close: _vm.handleClose, ok: _vm.handleOk },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _vm._v(
                "\n    Import settings" +
                  _vm._s(_vm.file ? `: ${_vm.file.name}` : "") +
                  "\n  "
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function ({ cancel, ok }) {
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "secondary" },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !_vm.isImportAvailable,
                    variant: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return ok()
                    },
                  },
                },
                [
                  _vm.isImporting
                    ? _c("b-spinner", {
                        staticClass: "mr-1",
                        attrs: {
                          variant: "light",
                          small: "",
                          label: "Spinning",
                        },
                      })
                    : _vm._e(),
                  _vm._v("\n      Import\n    "),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "import-mappings-modal__container",
          on: {
            dragover: function ($event) {
              $event.preventDefault()
              return _vm.handleDragOver.apply(null, arguments)
            },
            dragleave: function ($event) {
              $event.preventDefault()
              return _vm.handleDragLeave.apply(null, arguments)
            },
            drop: function ($event) {
              $event.preventDefault()
              return _vm.handleDrop.apply(null, arguments)
            },
          },
        },
        [
          _vm.isDragZoneVisible
            ? _c(
                "div",
                {
                  staticClass: "import-mappings-modal__drop-zone",
                  class: {
                    "import-mappings-modal__drop-zone--drag-over":
                      _vm.isDragOver,
                  },
                },
                [
                  _c(
                    "p",
                    { staticClass: "import-mappings-modal__drop-zone-text" },
                    [_vm._v("\n        Drag & Drop a JSON file\n      ")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    ref: "fileInput",
                    attrs: {
                      type: "file",
                      accept: "application/json",
                      hidden: "",
                    },
                    on: { change: _vm.handleFile },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.mappingsData
            ? _c(
                "div",
                {
                  staticClass: "d-flex flex-column",
                  staticStyle: { gap: "8px" },
                },
                [
                  _c("table", [
                    _c("thead", [
                      _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-2", attrs: { scope: "col" } },
                          [_vm._v("\n              Name\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          { staticClass: "pb-2", attrs: { scope: "col" } },
                          [_vm._v("\n              Template(s)\n            ")]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.paginatedData, function (mappingData) {
                        return _c("ImportMappingsDataRow", {
                          key: mappingData.id,
                          attrs: {
                            id: mappingData.id,
                            name: mappingData.name,
                            templates: mappingData.templates,
                            selected: !_vm.isMappingUnselected(mappingData.id),
                            "is-same-environment": _vm.isSameEnvironment,
                            customers: _vm.customers,
                          },
                          on: {
                            select: _vm.onDataRowSelect,
                            customerChange: _vm.onDataRowCustomerChange,
                            templateMappingsChange:
                              _vm.onDataRowTemplatesChange,
                          },
                        })
                      }),
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between my-4 align-items-center",
                    },
                    [
                      _c("div", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.mappingsData.data.length -
                                _vm.unselectedMappings.length
                            ) +
                            " / " +
                            _vm._s(_vm.mappingsData.data.length) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex", staticStyle: { gap: "8px" } },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: { click: _vm.unselectAll },
                            },
                            [_vm._v("\n            Unselect all\n          ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: { click: _vm.selectAll },
                            },
                            [_vm._v("\n            Select all\n          ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-center mt-3" },
                    [
                      _vm.totalPages > 1
                        ? _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.mappingsData.data.length,
                              "per-page": _vm.PER_PAGE,
                              size: "sm",
                              align: "center",
                            },
                            model: {
                              value: _vm.page,
                              callback: function ($$v) {
                                _vm.page = $$v
                              },
                              expression: "page",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }