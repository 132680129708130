import {calculateTimeByTotalDuration, getAnimationData} from "@frontend/services/EditorControls/animation-module/utils";
import {ShowPageScopeCollector} from "@frontend/group/modules/scope-collectors/show-page-collector";

export const LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_APPLYING_ANIMATIONS = 'selected-templates-animations-applying';

const getScopePage = () => ShowPageScopeCollector.get(['updateLayoutChanges']);

export function handleAnimationsApplying(data) {
  const {templates, currentPreview} = data;
  if (!templates || !templates.length) {
    toastr.warning("No templates selected!", "Animation - Apply To")
    return
  }
  const parsedAnimations = getAnimationData(currentPreview.canvas, currentPreview.data.animation);
  
  for (let template of templates) {
    let nodeWasChanged = false;
    const {data, canvas} = template;

    const templatesAnimationData = getAnimationData(canvas, data.animation);

    for (let node of parsedAnimations.nodes) {
      const theSameNodeIndex = templatesAnimationData.nodes.findIndex(templatesNode => {
        return templatesNode.name === node.name
      })

      if (!node.isPlay || theSameNodeIndex < 0) continue;
      
      templatesAnimationData.nodes[theSameNodeIndex].animations = node.animations;
      templatesAnimationData.nodes[theSameNodeIndex].isPlay = true;
      nodeWasChanged = true
    }
    if (nodeWasChanged) {
      templatesAnimationData.totalDuration = calculateTimeByTotalDuration(templatesAnimationData.nodes)
      templatesAnimationData.numberOfLoops = parsedAnimations.numberOfLoops;
      const data = {
        ...templatesAnimationData,
        nodes: templatesAnimationData.nodes.map(({objectId, startTime, endTime, isPlay, animations}) => ({
          objectId, startTime, endTime, isPlay, animations
        }))
      }
      const {updateLayoutChanges} = getScopePage();
      updateLayoutChanges(template.id, 'animation', JSON.stringify(data));
    }
  }
}