var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          staticClass: "brand_colors_modal",
          attrs: {
            id: "brand_colors_modal",
            title: "Brand Colors",
            centered: "",
            "no-close-on-backdrop": "",
          },
          on: { hide: _vm.close },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        on: { click: _vm.close },
                      },
                      [_vm._v("\n          Cancel\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: { click: _vm.onSave },
                      },
                      [_vm._v("\n          Save\n        ")]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "b-form-group",
                  { attrs: { label: "Brand" } },
                  [
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.brand.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.brand, "name", $$v)
                        },
                        expression: "brand.name",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "b-form-group",
                  { attrs: { label: "Variant" } },
                  [
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.brand.variant,
                        callback: function ($$v) {
                          _vm.$set(_vm.brand, "variant", $$v)
                        },
                        expression: "brand.variant",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("h5", { staticClass: "text-center" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.colors.primary.title) + "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex align-items-center color-inputs" },
              [
                _c(
                  "b-form-group",
                  { staticClass: "mr-3", attrs: { label: "CMYK" } },
                  [
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      on: {
                        input: function ($event) {
                          return _vm.onCMYKUpdate("primary")
                        },
                      },
                      model: {
                        value: _vm.colors.primary.cmyk,
                        callback: function ($$v) {
                          _vm.$set(_vm.colors.primary, "cmyk", $$v)
                        },
                        expression: "colors.primary.cmyk",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { staticClass: "mr-3", attrs: { label: "RGB" } },
                  [
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      on: {
                        input: function ($event) {
                          return _vm.onRGBUpdate("primary")
                        },
                      },
                      model: {
                        value: _vm.colors.primary.rgb,
                        callback: function ($$v) {
                          _vm.$set(_vm.colors.primary, "rgb", $$v)
                        },
                        expression: "colors.primary.rgb",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { label: "HEX" } },
                  [
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      on: {
                        input: function ($event) {
                          return _vm.onHEXUpdate("primary")
                        },
                      },
                      model: {
                        value: _vm.colors.primary.hex,
                        callback: function ($$v) {
                          _vm.$set(_vm.colors.primary, "hex", $$v)
                        },
                        expression: "colors.primary.hex",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12" }, [
            _c("label", [_vm._v("Color Preview")]),
            _vm._v(" "),
            _c("div", {
              staticClass: "color-preview",
              style: { backgroundColor: _vm.colors.primary.fill },
            }),
          ]),
          _vm._v(" "),
          _c("h5", { staticClass: "text-center mt-3" }, [
            _vm._v("\n      " + _vm._s(_vm.colors.secondary.title) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex align-items-center color-inputs" },
            [
              _c(
                "b-form-group",
                { staticClass: "mr-3", attrs: { label: "CMYK" } },
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    on: {
                      input: function ($event) {
                        return _vm.onCMYKUpdate("secondary")
                      },
                    },
                    model: {
                      value: _vm.colors.secondary.cmyk,
                      callback: function ($$v) {
                        _vm.$set(_vm.colors.secondary, "cmyk", $$v)
                      },
                      expression: "colors.secondary.cmyk",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { staticClass: "mr-3", attrs: { label: "RGB" } },
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    on: {
                      input: function ($event) {
                        return _vm.onRGBUpdate("secondary")
                      },
                    },
                    model: {
                      value: _vm.colors.secondary.rgb,
                      callback: function ($$v) {
                        _vm.$set(_vm.colors.secondary, "rgb", $$v)
                      },
                      expression: "colors.secondary.rgb",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { attrs: { label: "HEX" } },
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    on: {
                      input: function ($event) {
                        return _vm.onHEXUpdate("secondary")
                      },
                    },
                    model: {
                      value: _vm.colors.secondary.hex,
                      callback: function ($$v) {
                        _vm.$set(_vm.colors.secondary, "hex", $$v)
                      },
                      expression: "colors.secondary.hex",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-12" }, [
            _c("label", [_vm._v("Color Preview")]),
            _vm._v(" "),
            _c("div", {
              staticClass: "color-preview",
              style: { backgroundColor: _vm.colors.secondary.fill },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }