<template>
  <b-modal
    id="select-way-import-from-psd"
    title="Import PSD"
    centered
    size="lg"
    @ok="handleOkBtn"
    @hide="handleModalClose"
  >
    <Multiselect
      v-if="settings?.isAllowedToImportToExistingLayout"
      v-model="selectedLayout"
      :options="layouts"
      :loading="isLoading"
      searchable
      multiple
      :custom-label="customLabel"
      track-by="id"
      class="mb-3"
      placeholder="Add to existing layout (optional)"
      @search-change="searchCustomerLayouts"
      @select="(option) => selectedLayout = [option]"
    >
      <template 
        v-if="layouts.length < layoutsTotalCount"
        #afterList
      >
        <div class="load-more-btn">
          <b-button
            :disabled="isLoading"
            variant="outline-primary"
            @click="getCustomerLayouts"
          >
            Load More
          </b-button>
        </div>
      </template>
    </Multiselect>
    <b-form-radio-group
      v-model="selectedStrategy"
      name="select-strategy"
      :options="options"
    />
    <SelectTemplateBanner
      v-if="selectedStrategy === IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY"
      v-model="selectedTemplates"
      class="mt-3"
      :customers="customers"
    />
    <b-form-group
      v-if="settings?.sourcePage === PSD_IMPORT_SOURCES.FILE_BROWSER && selectedLayout.length === 0"
      class="mt-3"
      label="New layout name"
    >
      <b-form-input
        v-model="newLayoutName"
      />
    </b-form-group>

    <template #modal-footer="{ cancel, ok }">
      <div class="d-flex justify-content-between w-100 align-items-center">
        <div>
          <template v-if="isSamplePsdExist">
            <b-icon
              id="info-icon"
              icon="info-circle"
            />
            <b-tooltip target="info-icon">
              <div class="info-icon-tooltip">
                When editing your PSD file, <br>please use layer naming as shown <br>in the sample PSD file, such as:
                <ul class="info-icon-tooltip__list">
                  <li>Headline 1</li>
                  <li>Headline 2 (optional)</li>
                  <li>Subhead 1 (optional)</li>
                  <li>Image Area</li>
                  <li>Product Image 1</li>
                  <li>Product Image 2</li>
                  <li>Product Image 3</li>
                  <li>Logo</li>
                </ul>
              </div>
            </b-tooltip>
            <a
              class="download-link"
              :href="samplePsdUrl"
              download
            >
              Download sample PSD
            </a>
          </template>
        </div>

        <div class="d-flex">
          <b-button
            @click="cancel"
          >
            Cancel
          </b-button>
          <div class="mx-1" />
          <b-button
            variant="primary"
            :disabled="hasDisabledOkButton"
            @click="ok"
          >
            OK
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { importPsdEventBroker, EVENTS } from '@frontend/group/modules/import-template-from-psd/event-broker';
import SelectTemplateBanner from '@frontend/components/common/atoms/SelectTemplateBanner.vue';
import {
  IMPORT_AND_CREATE_STRATEGY,
  IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY, ACTION_IMPORT_FROM_PRESELECTED_PSD
} from '@frontend/group/modules/import-template-from-psd/import-from-psd-new-template';
import { createNamespacedHelpers } from 'vuex';
import { MUTATIONS } from '@frontend/store/psd-layers';
const { mapMutations } = createNamespacedHelpers('psdLayers');
import SAMPLE_PSD_API from '@frontend/services/api/sample-psd';
import Multiselect from "vue-multiselect";
import LayoutAPI from "@frontend/services/api/layout";
import {PSD_IMPORT_SOURCES} from "@frontend/components/modules/layouts-page/constants";

let timer;

export default {
  name: 'SelectWayImportFromPsd',
  components: { Multiselect, SelectTemplateBanner },
  props: {
    customers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      IMPORT_AND_CREATE_STRATEGY,
      IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY,
      PSD_IMPORT_SOURCES,
      selectedStrategy: IMPORT_AND_CREATE_STRATEGY,
      selectedTemplates: {
        customer: null,
        templates: []
      },
      options: [
        { text: 'Create new template', value: IMPORT_AND_CREATE_STRATEGY },
        { text: 'Use existing templates', value: IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY },
      ],
      settings: null,
      samplePsdUrl: `${window.location.origin}/share?file=sample-psd-file/sample.psd`,
      isSamplePsdExist: false,
      selectedLayout: [],
      layouts: [],
      layoutsTotalCount: null,
      isLoading: false,
      page: 1,
      searchKey: null,
      newLayoutName: '',
    }
  },
  computed: {
    hasDisabledOkButton() {
      return this.selectedStrategy === IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY ? !this.selectedTemplates.templates.length : false
    }
  },
  mounted() {
    importPsdEventBroker.on(EVENTS.SELECT_WAY_IMPORT_FROM_PSD.INIT, this.init.bind(this));
  },
  methods: {
    ...mapMutations({
      setIsImportIntoTemplate: MUTATIONS.SET_IS_IMPORT_INTO_TEMPLATE
    }),
    async init({ settings } = {}) {
      this.settings = settings;

      this.selectedStrategy = IMPORT_AND_CREATE_STRATEGY;
      this.selectedTemplates = {
        customer: null,
        templates: []
      }
      
      this.isSamplePsdExist = await SAMPLE_PSD_API.checkIfSamplePsdExist(this.samplePsdUrl);
      
      if (settings?.isAllowedToImportToExistingLayout) {
        this.getCustomerLayouts();
      }

      if (settings?.preselectedFiles) {
        this.newLayoutName = settings.preselectedFiles[0].name;
      }
      
      this.$bvModal.show('select-way-import-from-psd');
    },
    handleOkBtn() {
      if (this.settings?.sourcePage === PSD_IMPORT_SOURCES.FILE_BROWSER) {
        return this.handleImportFromFileBrowser();
      }

      this.setIsImportIntoTemplate(this.selectedStrategy === IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY);
      
      importPsdEventBroker.fire(EVENTS.SELECT_WAY_IMPORT_FROM_PSD.SAVE, {
        selectedSratagy: this.selectedStrategy,
        selectedTemplates: this.selectedTemplates,
      });
    },
    async handleImportFromFileBrowser() {
      const isImportIntoExistsTemplate = this.selectedStrategy === IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY;

      if (this.settings?.preselectedFiles.length === 0) {
        console.error('No preselected PSD found.');
        return;
      }

      if (isImportIntoExistsTemplate && this.selectedTemplates.length === 0) {
        console.error('No templates found.');
        return;
      }

      this.isLoading = true;

      const importData = {
        files: this.settings.preselectedFiles,
        ...(isImportIntoExistsTemplate && {
          templatesIds: this.selectedTemplates.templates.map(selectedTemplate => selectedTemplate.id)
        })
      };

      localStorage.setItem(ACTION_IMPORT_FROM_PRESELECTED_PSD, JSON.stringify(importData));

      if (this.selectedLayout.length) {
        window.location.href = `/banner/${this.selectedLayout[0].customer_id}/group/${this.selectedLayout[0].id}/show?action=${ACTION_IMPORT_FROM_PRESELECTED_PSD}`;

        return;
      }

      const { data } = await axios.post('/banner/0/group/create-layout-from-psd', { file: { name: this.newLayoutName } });

      window.location.href = `/banner/${data.customer_id}/group/${data.layout_id}/show?action=${ACTION_IMPORT_FROM_PRESELECTED_PSD}`;
    },

    async getCustomerLayouts() {
      this.isLoading = true;

      const { data: { layouts, totalCount } } = await LayoutAPI.getLayouts({
        params: {
          search: this.searchKey,
          page: this.page,
          limit: 20
        }
      });
      
      this.isLoading = false;

      this.layouts = this.layouts.concat(layouts);
      this.layoutsTotalCount = totalCount;
      this.page = this.page += 1;
    },
    
    searchCustomerLayouts(search) {
      if (timer) {
        clearTimeout(timer);
      }
      
      timer = setTimeout(() => {
        this.page = 1;
        this.searchKey = search;
        this.layouts = [];
        
        this.getCustomerLayouts();
      }, 500);
    },

    handleModalClose() {
      this.layouts = [];
      this.page = 1;
      this.searchKey = null;
    },
    customLabel({ id, name }) {
      return `${id} - ${name}`;
    }
  }
}
</script>

<style lang="scss">
  .download-link {
    color: #3c4b64;
    text-decoration: underline;
  }
  
  .info-icon-tooltip {
    text-align: left;
    
    &__list {
      list-style: none;
      padding-left: 10px;
    }
  }
  
  .load-more-btn {
    padding: 5px 0;
    
    button {
      display: block;
      margin: 0 auto;
    }
  }
</style>
