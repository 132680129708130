var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progress-bar" }, [
    _c(
      "div",
      {
        staticClass: "progress-bar__fill",
        style: {
          width: isNaN((_vm.current / _vm.total) * 100)
            ? "0%"
            : `${(_vm.current / _vm.total) * 100}%`,
        },
      },
      [_c("div", { staticClass: "progress-bar__highlight" })]
    ),
    _vm._v(" "),
    _c("span", { staticClass: "progress-bar__text" }, [
      _vm._v(_vm._s(_vm.current) + " / " + _vm._s(_vm.total)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }