<template>
  <div
    class="c-grid-view"
    @click.self="clearSelection"
  >
    <div
      v-show="folderItems.length"
      class="c-grid-view__container folder-items pb-3"
      @click.self="clearSelection"
    >
      <GridItem
        v-for="folderItem in folderItems"
        :key="folderItem.id"
        :item="folderItem"
        :is-selected="isSelected(folderItem)"
        :is-available-overlay="false"
        :draggable="isDragAndDropAvailable"
        @click="itemClickHandled"
        @dblclick="dbClickHandler"
        @dragstart="onDragstart($event, folderItem)"
        @drop.stop="onDrop($event, folderItem)"
      />
    </div>
 
    <div
      class="c-grid-view__container other-items"
      @click.self="clearSelection"
    >
      <GridItem
        v-for="otherItem in otherItems"
        :key="otherItem.id"
        :item="otherItem"
        :is-selected="isSelected(otherItem)"
        :is-available-overlay="isItemOverlayEnabled"
        :draggable="isDragAndDropAvailable"
        @click="itemClickHandled"
        @dblclick="dbClickHandler"
        @dragstart="onDragstart($event, otherItem)"
        @drop.stop="onDrop($event, otherItem)"
      />
      <GridItemAdd
        v-if="showItemAdd"
        v-b-tooltip.hover
        title="Add more product images"
        @click="addItemHandle"
        @dblclick="addItemHandle"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import GridItem from "../GridItem.vue";
import {MUTATIONS} from "@frontend/store/file-browser-product-selector";
import {FILE_TYPE} from "@frontend/constants/file-image-types";
import GridItemAdd from "@frontend/components/modules/file-browser-product-selector/browser/GridItemAdd.vue";
import {MODES} from "@frontend/components/modules/file-browser-product-selector/constants";

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('fileBrowserProductSelector')

export default {
  name: "GridView",
  components: {GridItem, GridItemAdd},
  props: {
    isDragAndDropAvailable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    dragIcon: null,
  }),
  computed: {
    ...mapGetters([
      'items',
      'isSelected',
      'onlyOneItemSelection',
      'selectedItems',
      'isItemOverlayEnabled',
      'isItemsPickEnabled',
      'currentFolderId',
      'mode',
      'isMoveProductModeEnabled',
    ]),
    folderItems() {
      return this.items.filter(item => item.type === FILE_TYPE.FOLDER);
    },
    otherItems() {
      return this.items.filter(item => item.type !== FILE_TYPE.FOLDER);
    },
    showItemAdd() {
      if (this.isMoveProductModeEnabled) {
        return false;
      }
      return this.mode === MODES.VIEW && !this.currentFolderId;
    }
  },
  mounted() {
    const dragIcon = new Image();
    dragIcon.src = "/img/icons/image-drag-icon.svg";

    this.dragIcon = dragIcon;
  },
  methods: {
    ...mapActions([
      'clearSelection', 
      'setCurrentFolder', 
      'togglePickedItem', 
      'dropProductFile',
      'enableBrowserMode'
    ]),
    ...mapMutations([
      MUTATIONS.TOGGLE_SELECTED_ITEMS, 
      MUTATIONS.CLEAR_SELECTION, 
      MUTATIONS.SET_MODAL,
    ]),
    itemClickHandled(item) {
      if (item.name == '...') {
        return
      }
      if (this.mode !== MODES.BROWSER) {
        this.clearSelection();
      }
      if (this.isItemsPickEnabled &&
        item.type !== FILE_TYPE.FOLDER && 
        (this.onlyOneItemSelection || (item.count === 1 || item.count === 0))) {
        this.togglePickedItem(item);
      }
      this[MUTATIONS.TOGGLE_SELECTED_ITEMS](item);
    },
    dbClickHandler(item) {
      if (item.type === FILE_TYPE.FOLDER) {
        this.setCurrentFolder(item);
      }
    },
    onDragstart(event, item) {
      if (!this.isDragAndDropAvailable) return;
      this.draggedItem = item;

      if (item.type !== FILE_TYPE.FOLDER) {
        event.dataTransfer.setDragImage(this.dragIcon, 64, 64);
      }
    },
    async onDrop(event, itemToDropIn) {
      if (itemToDropIn.type === 'folder') {
        let itemsForSending = [];

        if (this.selectedItems.some(selectedItem => selectedItem.id === this.draggedItem.id)) {
          itemsForSending = this.selectedItems;
        } else {
          itemsForSending = [this.draggedItem];
        }

        itemsForSending = itemsForSending.filter(itemForSending => itemForSending.id !== itemToDropIn.id)
        
        for (const item of itemsForSending) {
          await this.dropProductFile({ id: item.id, parent_id: itemToDropIn.id, type: item.type });
        }
      }
    },
    addItemHandle() {
      this.enableBrowserMode();
    }
  }
}
</script>

<style scoped lang="scss">
  .c-grid-view {
    display: grid;

    &__container {
      display: grid;
      min-height: 280px;
      padding: 0;
      overflow-y: auto;
      grid-template-rows: 150px auto;
      -moz-column-gap: 25px;
      column-gap: 25px;
      row-gap: 5px;
      justify-self: center;
      grid-template-columns: repeat(6, minmax(100px, 1fr));

      @media (max-width: 1700px) {
        grid-template-columns: repeat(5, minmax(100px, 1fr)) !important;
      }

      @media (max-width: 1500px) {
        grid-template-columns: repeat(4, minmax(100px, 1fr)) !important;
      }

      @media (max-width: 1300px) {
        grid-template-columns: repeat(4, minmax(100px, 1fr)) !important;
      }

      @media (max-width: 1155px) {
        grid-template-columns: repeat(3, minmax(100px, 1fr)) !important;
      }

     @media (max-width: 991px) {
        grid-template-columns: repeat(2, minmax(100px, 1fr)) !important;
     }

      @media (max-width: 650px) {
        grid-template-columns: repeat(1, minmax(100px, 1fr)) !important;
      }
    }

    .folder-items {
      min-height: initial;
      row-gap: 14px;
      grid-template-rows: 56px;
      
    }

  }
</style>
