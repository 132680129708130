<template>
  <div ref="modal">
    <div
      id="assetModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="assetModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-xl"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5
              id="assetModalLabel"
              class="modal-title"
            >
              Asset Files
            </h5>
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="closeHandler"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <input
                v-model="searchKey"
                type="text"
                class="form-control"
                placeholder="Search assets..."
                @input="fetchAssets"
              >
            </div>

            <div class="row">
              <div
                v-for="asset in assets"
                :key="asset.id"
                class="col-md-3 mb-4"
              >
                <div
                  class="card h-100"
                  :class="{ 'border-primary': selectedAsset === asset.id }"
                  @click="toggleAssetSelection(asset)"
                >
                  <img
                    :src="asset.path"
                    class="card-img-top mx-auto mt-2"
                    alt="Asset image"
                    style="width: 200px; object-fit: cover;"
                  >
                  <div class="card-footer mt-auto">
                    <p class="card-title mb-0 text-center">
                      {{ asset.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <nav v-if="total > perPage">
              <ul class="pagination">
                <li
                  class="page-item"
                  :class="{ disabled: currentPage === 1 }"
                  @click="changePage(currentPage - 1)"
                >
                  <a
                    class="page-link"
                    href="#"
                  >Previous</a>
                </li>
                <li
                  v-for="page in totalPages"
                  :key="page"
                  class="page-item"
                  :class="{ active: currentPage === page }"
                  @click="changePage(page)"
                >
                  <a
                    class="page-link"
                    href="#"
                  >{{ page }}</a>
                </li>
                <li
                  class="page-item"
                  :class="{ disabled: currentPage === totalPages }"
                  @click="changePage(currentPage + 1)"
                >
                  <a
                    class="page-link"
                    href="#"
                  >Next</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="closeHandler"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :disabled="!selectedAsset"
              @click="confirmSelection"
            >
              Confirm Selection
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssetModal",
  props: {
    adUnit: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      searchKey: "",
      assets: [],
      total: 0,
      currentPage: 1,
      perPage: 12,
      selectedAsset: null,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },
  },
  mounted() {
    this.fetchAssets();
  },
  methods: {
    init() {
      $("#assetModal").modal("show");
    },
    closeHandler() {
      this.selectedAsset = null;

      $("#assetModal").modal("hide");
      this.$emit('close-modal');
    },
    async fetchAssets() {
      try {
        const { data } = await axios.get("/walmart-assets/asset-files", {
          params: {
            searchKey: this.searchKey,
            pageNumber: this.currentPage,
            pageSize: this.perPage,
          },
        });

        this.assets = data.items;
        this.total = data.totalCount;
        this.currentPage = data.current_page;
        this.perPage = data.per_page;
      } catch (error) {
        console.error("Error fetching assets:", error);
      }
    },
    toggleAssetSelection(asset) {
      if (this.selectedAsset === asset.id) {
        this.selectedAsset = null;
      } else {
        this.selectedAsset = asset.id;
      }
    },
    confirmSelection() {
      const selectedAsset = this.assets.find(asset => asset.id === this.selectedAsset);
      const asset = {
        assetId: selectedAsset.asset_id,
        type: this.adUnit.type,
        imageType: this.adUnit.imageType,
      };
      this.$emit("asset-selected", asset);

      this.closeHandler();
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchAssets();
      }
    },
  },
};
</script>

<style scoped>
.card {
  transition: all 0.3s ease;
  cursor: pointer;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.border-primary {
  border-width: 3px !important;
}
</style>