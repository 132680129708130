var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "delete-download-settings-modal",
            title: "Delete",
            centered: "",
          },
          on: { ok: _vm.onDelete },
        },
        [_c("p", [_vm._v("Are you sure you want to delete this setting?")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }