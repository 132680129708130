import { fabric } from "fabric";
import { circleHandler } from "./type-mask/circle-handler"
import { ellipseHandler } from "./type-mask/ellipse-handler"
import { rectangleHandler } from "./type-mask/rectangle-handler"
import { shadowFilterHandler, GRADIENT_FILTER_ID } from "./type-mask/shadow-filter-handler";

let canvasInstance = null;
let imageObject = null;

export const TYPE_MASK = {
    NONE: 'none',
    CIRCLE: 'circle',
    ELLIPSE: 'ellipse',
    RECTANGLE: 'rectangle',
    SHADOW_FILTER: 'shadow_filter',
}

const DEFAULT_TYPE_MASK = TYPE_MASK.NONE;

const TYPE_MASK_ALIAS = {
    rect: TYPE_MASK.RECTANGLE
} 

export const DEFAULT_OBJECT_MASKS = () => ({
    [TYPE_MASK.CIRCLE]: {
        radius: 1,
        x_center: 0.5,
        y_center: 0.5
    },
    [TYPE_MASK.ELLIPSE]: {
        left: 0,
        top: 0,
        right: 1,
        bottom: 1
    },
    [TYPE_MASK.RECTANGLE]: {
        left: 0,
        top: 0,
        right: 1,
        bottom: 1
    },
    [TYPE_MASK.SHADOW_FILTER]: [
        {
            type: 'linear',
            angle: 0,
            opacity: 0.5,
            radius: 1,
            left: 0,
            top: 0,
            colors: [
                { offset: 0, color: '#000000' },
                { offset: 0.5, color: 'rgba(0,0,0,0)' },
            ]
        }
    ],
});

function initImageObject(canvas, object) {
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const imageAspectRatio = object.width / object.height;
    const canvasAspectRatio = canvasWidth / canvasHeight;

    let scale = 1;

    if (imageAspectRatio > canvasAspectRatio) {
        scale = canvasWidth / object.width;
    } else {
        scale = canvasHeight / object.height;
    }

    object.scale(scale);

    object.set({
        evented: false,
        selectable: false,
    });

    return object
}

const defaultOptions = {
    originX: 'center',
    originY: 'center',
}

const MAP_TYPE_MASK_HANDLER = {
    [TYPE_MASK.CIRCLE]: circleHandler(defaultOptions),
    [TYPE_MASK.ELLIPSE]: ellipseHandler(defaultOptions),
    [TYPE_MASK.RECTANGLE]: rectangleHandler(defaultOptions),
}

const MAP_FILTER_MASK_HANDLER = {
    [TYPE_MASK.SHADOW_FILTER]: shadowFilterHandler(defaultOptions),
}

export function applyFilterMaskForImage(typeMask, objectData, object) {

    const handler = MAP_FILTER_MASK_HANDLER[typeMask];

    if (!handler) {
        object.filters = object.filters.filter(filter => filter.id !== GRADIENT_FILTER_ID);
        object.setElement(object._originalElement);
        object.applyFilters();

        return null;
    }

    return handler(object, objectData);
}

export function getMaskForImage(typeMask, objectData, object) {
    if (!typeMask || typeMask === TYPE_MASK.NONE) return null

    const handler = MAP_TYPE_MASK_HANDLER[typeMask];

    return handler ? handler(object, objectData) : null;
}

export const prepareTypeMask = (typeMask) => {
    return TYPE_MASK_ALIAS[typeMask] || typeMask || DEFAULT_TYPE_MASK;
}

export const setTypeMask = (typeMask, objectData) => {
    const object = canvasInstance.getObjects().find(obj => obj.id === imageObject.id);
    objectData = objectData || _.cloneDeep(DEFAULT_OBJECT_MASKS())[typeMask];
    
    applyFilterMaskForImage(typeMask, objectData, object);

    const clipPath = getMaskForImage(
        typeMask,
        objectData,
        object,
        fabric
    );

    object.set({ clipPath });
    canvasInstance.renderAll();
}

export const getCanvasInstance = () => canvasInstance;
window.getCanvasInstance = getCanvasInstance;

export const initPreview = (canvasElement, object) => {
    canvasInstance = new fabric.Canvas(canvasElement, {
      enableRetinaScaling: true,
      backgroundColor: '#e0e0e0' // Light gray background for better visibility
    });
    initImageObject(canvasInstance, object);
    canvasInstance.add(object);
    canvasInstance.centerObject(object);
    imageObject = object;
    canvasInstance.renderAll();
}
