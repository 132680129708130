<template>
  <div
    style="padding: 0 10px; max-height: 600px; overflow-y: auto; overflow-x: hidden;"
  >
    <!--    Enable select-->
    <b-form-row>
      <b-col>
        <b-form-group
          label="Enabled"
        >
          <b-form-select
            v-model="isEnabled"
            :options="isEnabledOptions"
            class="form-control"
          />
        </b-form-group>
      </b-col>
    </b-form-row>
    <!--    Footer rows-->
    <div
      v-if="isEnabled"
    >
      <b-card bg-variant="light">
        <b-form-row>
          <b-col cols="11">
            <b-form-group
              label="Footer Height"
            >
              <b-form-input
                v-model="height"
                type="number"
                @input="updateResultData"
              />
            </b-form-group>
          </b-col>
          <b-col class="d-flex justify-content-center mt-1">
            <b-form-group label="Color">
              <ColorPicker v-model="backgroundColor" />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-card>
      <!--    Footer rows end-->
      <!--    Enable Text-->
      <b-form-row class="mt-2">
        <b-col>
          <b-form-group
            label="Add text"
          >
            <b-form-select
              v-model="isTextEnabled"
              :options="isEnabledOptions"
              class="form-control"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <!--    Text rows-->
      <div v-if="isTextEnabled">
        <div>
          <ExportPageFooterText
            @data-updated="textToAdd = $event"
          />
          <b-col class="d-flex justify-content-end mt-3 mb-3">
            <b-button
              variant="primary"
              @click="addText"
            >
              Add
            </b-button>
          </b-col>
        </div>
        
        <div
          v-for="(text, index) in texts"
          :key="index"
        >
          <ExportPageFooterText
            :text="text"
            @data-updated="Object.assign(text, $event)"
          />
          <b-col class="d-flex justify-content-end mt-3 mb-3">
            <b-button
              variant="danger"
              @click="removeText(index)"
            >
              <i class="fas fa-trash" />
            </b-button>
          </b-col>
        </div>
      </div>
      <!--    Text rows end-->
      <!--    Enable Icon-->
      <b-form-row class="mt-2">
        <b-col>
          <b-form-group
            label="Add icon"
          >
            <b-form-select
              v-model="icon.isEnabled"
              :options="isEnabledOptions"
              class="form-control"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <!--    Icon rows-->
      <div v-if="icon.isEnabled">
        <b-card bg-variant="light">
          <b-form-row>
            <b-col>
              <b-form-group
                label="Icon URL"
              >
                <b-form-input
                  v-model="icon.url"
                  type="text"
                  placeholder="Absolute URL to the icon goes here..."
                  @input="updateResultData"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                label="Icon Width"
              >
                <b-form-input
                  v-model="icon.width"
                  type="number"
                  @input="updateResultData"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Icon Height"
              >
                <b-form-input
                  v-model="icon.height"
                  type="number"
                  @input="updateResultData"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                label="Icon Horizontal position"
              >
                <b-form-select
                  v-model="icon.horizontalPosition"
                  :options="horizontalPositionOptions"
                  class="form-control"
                  @change="updateResultData"
                />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Icon Vertical position">
                <b-form-select
                  v-model="icon.verticalPosition"
                  :options="verticalPositionOptions"
                  class="form-control"
                  @change="updateResultData"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                label="Icon Horizontal offset"
              >
                <b-form-input
                  v-model="icon.horizontalOffset"
                  type="number"
                  @input="updateResultData"
                />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Icon Vertical offset">
                <b-form-input
                  v-model="icon.verticalOffset"
                  type="number"
                  @input="updateResultData"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-card>
        <!--    Icon rows end-->
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from "@frontend/components/modules/layouts-page/EditorControl/components/ColorPicker.vue";
import {debounce} from "lodash";
import ExportPageFooterText from "@frontend/components/modules/layouts-page/Export/components/ExportPageFooterText.vue";

const isEnabledOptions = [{ text: 'Yes', value: true }, { text: 'No', value: false }];

const horizontalPositionOptions = ['Left', 'Center', 'Right'];
const verticalPositionOptions = ['Top', 'Center', 'Bottom'];

export default {
  name: 'ExportPageFooterTab',
  components: {ExportPageFooterText, ColorPicker},
  props: {
    pageFooter: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isEnabled: false,
      isTextEnabled: false,
      height: 0,
      backgroundColor: '',
      textToAdd: null,
      texts: [],
      icon: {
        isEnabled: false,
        url: '',
        width: 0,
        height: 0,
        horizontalPosition: '',
        verticalPosition: '',
        horizontalOffset: 0,
        verticalOffset: 0,
      },
      resultData:{}
    };
  },
  computed: {
    horizontalPositionOptions() {
      return horizontalPositionOptions.map(option => {
        return { text: option, value: option };
      });
    },
    verticalPositionOptions() {
      return verticalPositionOptions.map(option => {
        return { text: option, value: option };
      });
    },
    isEnabledOptions() {
      return isEnabledOptions;
    },
  },
  watch: {
    pageFooter: {
      handler(newVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(this.resultData)) {
          Object.assign(this, newVal);
          this.updateResultData();
        }
      },
      deep: true,
      immediate: true
    },
    texts: {
      handler() {
        this.updateResultData();
      },
      deep: true,
      immediate: true
    },
    backgroundColor: function() {
      this.updateResultData();
    },
    isEnabled: function() {
      this.updateResultData();
    },    
    isTextEnabled: function() {
      this.updateResultData();
    },
    resultData: function(newResultData) {
      this.$emit('data-updated', newResultData);
    },
    'icon.isEnabled': function() {
      this.updateResultData();
    },
    'icon.url': debounce(function (newValue) {
      this.getImageDimensions(newValue);
    }, 400),
  },
  methods: {
    addText() {
      this.texts.push({...this.textToAdd});
    },
    removeText(index) {
      this.texts.splice(index, 1);
    },
    getImageDimensions(url) {
      try {
        const img = new Image();

        img.onload = () => {
          this.icon.width = img.width;
          this.icon.height = img.height;
          this.updateResultData();
        };

        img.src = url;
      } catch (error) {}
    },
    updateResultData() {
      this.resultData = {
        isEnabled: this.isEnabled,
        isTextEnabled: this.isTextEnabled,
        height: +this.height,
        backgroundColor: this.backgroundColor,
        texts: [...this.texts],
        icon: {
          ...this.icon,
          width: +this.icon.width,
          height: +this.icon.height,
          horizontalOffset: +this.icon.horizontalOffset,
          verticalOffset: +this.icon.verticalOffset,
        },
      };
    },
  }
}
</script>
 
<style scoped>
.fas:hover {
  color: black !important;
}
</style>
