var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "modal-body" }, [
      _c("div", {
        staticClass: "modal-body--alert alert alert-danger d-none",
        attrs: { role: "alert" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row h-100 w-100" },
        [
          _c("div", { staticClass: "modal-body--preview col-6" }, [
            !_vm.previewTemplateThumbnail
              ? _c("img", {
                  staticClass: "modal-body--preview__img",
                  class: { "d-none": _vm.isImageLoading },
                  attrs: {
                    src: _vm._f("share")(_vm.entireImageThumbnail),
                    alt: "preview",
                  },
                  on: { load: _vm.imageLoadedHandler },
                })
              : _c("img", {
                  staticClass: "modal-body--preview__img",
                  attrs: { src: _vm.previewTemplateThumbnail, alt: "preview" },
                }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "spinner-border modal-body--preview__spinner",
                class: { "d-none": !_vm.isImageLoading },
                attrs: { role: "status" },
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            ),
            _vm._v(" "),
            _vm.previewTemplateThumbnail
              ? _c(
                  "div",
                  {
                    staticClass: "modal-body--preview__img-close",
                    on: {
                      click: function ($event) {
                        return _vm.setPreviewTemplateThumbnail({
                          previewTemplateThumbnail: "",
                        })
                      },
                    },
                  },
                  [
                    _c("svg", { attrs: { width: "20px", height: "20px" } }, [
                      _c("use", {
                        attrs: {
                          href: "/img/icons/sprite.svg#file-circle-close",
                        },
                      }),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("LayersTree", {
            staticClass: "modal-body--layers-wrapper col-6",
            attrs: { templates: _vm.templates },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary",
            attrs: { type: "button" },
            on: { click: _vm.selectAllHandler },
          },
          [_vm._v("\n        Select All\n      ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-secondary",
            attrs: { type: "button" },
            on: { click: _vm.unselectAllHandler },
          },
          [_vm._v("\n        Unselect All\n      ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-8 d-flex justify-content-end align-items-start",
          staticStyle: { gap: "0.25rem" },
        },
        [
          _c(
            "b-button",
            {
              staticClass: "mr-1",
              attrs: { disabled: _vm.isSavingNodesChanges },
              on: { click: _vm.cancel },
            },
            [_vm._v("\n        Cancel\n      ")]
          ),
          _vm._v(" "),
          _vm.isNodesChangesShouldBeSaved
            ? _c(
                "b-button",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { disabled: _vm.isSavingNodesChanges },
                  on: { click: _vm.saveNodesChanges },
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.isSavingNodesChanges
                            ? "Saving changes..."
                            : "Save changes"
                        ) +
                        "\n          "
                    ),
                    _vm.isSavingNodesChanges
                      ? _c("i", {
                          staticClass: "spinner-border spinner-border-sm m-0",
                        })
                      : _vm._e(),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", [
            _vm.isTemplateFeatures && _vm.isImportIntoTemplate
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      type: "button",
                      disabled: _vm.isUpdatingPreviewTemplate,
                    },
                    on: { click: _vm.updatePreviewTemplate },
                  },
                  [
                    _c("span", {
                      staticClass: "spinner-border spinner-border-sm",
                      class: { "d-none": !_vm.isUpdatingPreviewTemplate },
                      attrs: { role: "status", "aria-hidden": "true" },
                    }),
                    _vm._v("\n          Preview\n        "),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isTemplateFeatures
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", disabled: _vm.isGenerating },
                    on: { click: _vm.addToTemplate },
                  },
                  [
                    _c("span", {
                      staticClass: "spinner-border spinner-border-sm",
                      class: { "d-none": !_vm.isGenerating },
                      attrs: { role: "status", "aria-hidden": "true" },
                    }),
                    _vm._v("\n          Add to template\n        "),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.availableDefaultFlow
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      type: "button",
                      disabled:
                        _vm.isExtractingAssets || _vm.isSavingNodesChanges,
                    },
                    on: { click: _vm.proceedByFlow },
                  },
                  [
                    _c("span", {
                      staticClass: "spinner-border spinner-border-sm",
                      class: { "d-none": !_vm.isExtractingAssets },
                      attrs: { role: "status", "aria-hidden": "true" },
                    }),
                    _vm._v("\n          Extract assets\n        "),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.isTemplateFeatures
      ? _c(
          "div",
          { staticClass: "d-flex mt-1 align-items-center justify-content-end" },
          [
            _c(
              "b-button",
              {
                attrs: {
                  variant: "primary",
                  disabled: _vm.isProductRecognitionProcessing,
                },
                on: { click: _vm.requestProductRecognition },
              },
              [_vm._v("\n      Recognize product images\n    ")]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-2" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _vm.isUseProductImagesFromPSDAvailable
                ? _c(
                    "div",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "isUseProductImagesFromPSD",
                            checked: _vm.isUseProductImagesFromPSD,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.setIsUseProductImagesFromPSD({
                                value: !_vm.isUseProductImagesFromPSD,
                              })
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                Use product images from PSD\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.isTemplateFeatures
              ? _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          id: "replaceExistingProduct",
                          checked: _vm.isReplaceExistingProduct,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.setReplaceExistingProduct({
                              isReplaceExistingProduct:
                                !_vm.isReplaceExistingProduct,
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              Replace existing product images\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isSavePsdAvailable
              ? _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: { id: "savePsd", checked: _vm.isSavePsd },
                        on: {
                          change: function ($event) {
                            return _vm.setSavePsd({ value: !_vm.isSavePsd })
                          },
                        },
                      },
                      [_vm._v("\n              Save PSD\n            ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showSaveImagesToTheme
              ? _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          id: "createIndividualAssets",
                          checked: _vm.isCreateIndividualAssets,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.setCreateIndividualAssets({
                              isCreateIndividualAssets:
                                !_vm.isCreateIndividualAssets,
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              Create individual assets\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _vm.isTemplateFeatures
                ? _c(
                    "div",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "isTreatMultipleSpacesLineBreak",
                            checked: _vm.isTreatMultipleSpacesAsSingle,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.setTreatMultipleSpacesAsSingle({
                                isTreatMultipleSpacesAsSingle:
                                  !_vm.isTreatMultipleSpacesAsSingle,
                              })
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                Treat multiple spaces as single space\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-6 d-flex justify-content-end align-items-start" },
        [
          _c("div", { staticClass: "col-10" }, [
            _c(
              "div",
              [
                _vm.isImportIntoTemplate && _vm.isTemplateFeatures
                  ? _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          id: "autoMapToFields",
                          checked: _vm.isAutoMapToFields,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.setAutoMapFields()
                          },
                        },
                      },
                      [_vm._v("\n            Auto map to fields\n          ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isTemplateFeatures && _vm.isImportIntoTemplate
                  ? _c(
                      "b-form-checkbox",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip",
                            value: { customClass: "custom-tooltip" },
                            expression: "{ customClass: 'custom-tooltip' }",
                          },
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.left",
                            modifiers: { hover: true, left: true },
                          },
                        ],
                        attrs: {
                          title:
                            "Checking this option will remove any default text from the destination templates",
                          checked: _vm.isRemovePlaceholderText,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.setRemovePlaceholderText({
                              isRemovePlaceholderText:
                                !_vm.isRemovePlaceholderText,
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            Remove placeholder text\n          "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isTemplateFeatures && _vm.isImportIntoTemplate
                  ? _c(
                      "b-form-checkbox",
                      {
                        attrs: { checked: _vm.isUseSourceTextSettings },
                        on: {
                          change: function ($event) {
                            return _vm.setUseSourceTextSettings({
                              isUseSourceTextSettings:
                                !_vm.isUseSourceTextSettings,
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            Use source text settings\n          "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }