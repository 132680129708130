<template>
  <b-button
    v-b-tooltip.html.hover.viewport.noninteractive
    variant="outline-primary"
    class="rounded-circle d-flex p-1"
    size="sm"
    :title="`<div class='text-left'>
      <b>To apply updates across templates:</b>
      <ol class='pl-3 m-0'>
        <li>Select the destination templates</li>
        <li>Select the ${actionsText}</li>
        <li>Click Apply</li>
      </ol>
    </div>`"
  >
    <b-icon
      icon="info"
      scale="1.5"
    />
  </b-button>
</template>

<script>
export default {
  name: 'ApplyInfoButton',
  props: {
    actionsText: {
      type: String,
      default: 'actions'
    },
  },
}
</script>

<style scoped lang="scss">

</style>
