import {ShowPageScopeCollector} from "@frontend/group/modules/scope-collectors/show-page-collector";

const getScopePage = () => ShowPageScopeCollector.get(['previews', 'updateLayoutChanges', 'canvas_data']);

export function hexToRgb(hex) {
  const bigint = parseInt(hex.substring(1), 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
}

export function rgbToHex([r, g, b]) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

export function rgbToCmyk([r, g, b]) {
  const scaledR = r / 255, scaledG = g / 255, scaledB = b / 255;
  const k = 1 - Math.max(scaledR, scaledG, scaledB);
  return [
    (1 - scaledR - k) / (1 - k) || 0,
    (1 - scaledG - k) / (1 - k) || 0,
    (1 - scaledB - k) / (1 - k) || 0,
    k
  ].map((val) => Math.round(val * 100));
}

export function cmykToRgb([c, m, y, k]) {
  c /= 100;
  m /= 100;
  y /= 100;
  k /= 100;
  return [
    255 * (1 - c) * (1 - k),
    255 * (1 - m) * (1 - k),
    255 * (1 - y) * (1 - k),
  ].map(Math.round);
}

export function updateLayoutVariantBrand(instanceId, brand, variant) {
  const { updateLayoutChanges } = getScopePage();
  
  updateLayoutChanges(instanceId, 'brand', brand);
  updateLayoutChanges(instanceId, 'variant', variant);
}

function formatRectangleName(name) {
  return name.trim().toLowerCase().split(" ").join("_");
}

export function getPrimarySecondaryColors(instanceId) {
  const {previews} = getScopePage();
  const preview = previews.find((p) => p.id === instanceId);

  if (!preview) {
    console.error(`Preview with id ${instanceId} not found.`);
    return null;
  }

  const rectangles = preview.template.fields.filter((f) => f.type === "Rectangle");
  const colors = {
    primary: {
      title: 'Primary Color',
      fill: '#000000',
      hex: '#000000',
      cmyk: '#000000',
      rgb: '#000000',
    },
    secondary: {
      title: 'Secondary Color',
      fill: '#000000',
      hex: '#000000',
      cmyk: '#000000',
      rgb: '#000000',
    }
  };

  for (const rectangle of rectangles) {
    try {
      const options = JSON.parse(rectangle.options);
      const rectangleDataId = formatRectangleName(rectangle.name);
      const fillColor = preview.data[`rectangle_${rectangleDataId}_fill_color`];

      switch (options?.Option3?.toLowerCase()) {
        case 'primary':
          colors.primary.fill = fillColor || colors.primary.fill;
          break;
        case 'secondary':
          colors.secondary.fill = fillColor || colors.secondary.fill;
          break;
      }
    } catch (error) {
      console.error(`Error processing rectangle "${rectangle.name}":`, error);
    }
  }

  return colors;
}

export function getBrandVariant(instanceId) {
  const {previews} = getScopePage();
  const preview = previews.find((p) => p.id === instanceId);

  if (!preview || !preview.data) {
    console.warn(`Preview not found or data is missing for instanceId: ${instanceId}`);
    
    return {
      brand: {
        name: 'Brand',
        variant: 'Variant',
      }
    };
  }

  return {
    brand: {
      name: preview.data.brand || 'Brand',
      variant: preview.data.variant || 'Variant',
    },
  };
}

export function checkIsUseBrandColor(layoutOptions) {
  return layoutOptions?.use_color_table ?? false;
}

export function checkIsRectanglePrimaryOrSecondary(fieldOptions) {
  const optionValue = fieldOptions?.option3;

  if (typeof optionValue === 'string') {
    return optionValue.toLowerCase() === 'primary' || optionValue.toLowerCase() === 'secondary';
  }

  return false;
}

export function isShodShowBrandColorModal(layoutOptions, fieldOptions) {
  return checkIsUseBrandColor(layoutOptions) && checkIsRectanglePrimaryOrSecondary(fieldOptions);
}
