<template>
  <div class="mb-4">
    <!--    Enable select-->
    <b-form-row>
      <b-col>
        <b-form-group
          label="With underlying shape"
        >
          <b-form-select
            v-model="isEnabled"
            :options="isEnabledOptions"
            class="form-control"
          />
        </b-form-group>
      </b-col>
    </b-form-row>
    <div
      v-if="isEnabled"
    >
      <!--    Enable select-->
      <b-form-row>
        <b-col>
          <b-form-group
            label="Shape type"
          >
            <b-form-select
              v-model="shapeType"
              :options="shapeTypeOptions"
              class="form-control"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <!--  Image rows -->
      <div v-if="shapeType === 'Image'">
        <b-card
          bg-variant="light"
        >
          <b-form-row>
            <b-col>
              <b-form-group
                label="Image URL"
              >
                <b-form-input
                  v-model="image.url"
                  type="text"
                  placeholder="Absolute URL to the image goes here..."
                  autocomplete="off"
                  @change="updateResultData"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                label="Image Width"
              >
                <b-form-input
                  v-model="image.width"
                  type="number"
                  @change="updateResultData"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Image Height"
              >
                <b-form-input
                  v-model="image.height"
                  type="number"
                  @change="updateResultData"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                label="Image Horizontal position"
              >
                <b-form-select
                  v-model="image.horizontalPosition"
                  :options="horizontalPositionOptions"
                  class="form-control"
                  @change="updateResultData"
                />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Image Vertical position">
                <b-form-select
                  v-model="image.verticalPosition"
                  :options="verticalPositionOptions"
                  class="form-control"
                  @change="updateResultData"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                label="Image Horizontal offset"
              >
                <b-form-input
                  v-model="image.horizontalOffset"
                  type="number"
                  @change="updateResultData"
                />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Image Vertical offset">
                <b-form-input
                  v-model="image.verticalOffset"
                  type="number"
                  @change="updateResultData"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-card>
        <!--  Image rows end-->
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from "lodash";

const isEnabledOptions = [{text: 'Yes', value: true}, {text: 'No', value: false}];
const horizontalPositionOptions = ['Left', 'Center', 'Right'];
const verticalPositionOptions = ['Top', 'Center', 'Bottom'];

const shapeTypeOptions = [
  {text: 'Image', value: 'Image'},
];

export default {
  name: 'PageNumberUnderlyingShape',
  props: {
    underlyingShape: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isEnabled: false,
      shapeType: 'Image',
      image: {
        url: '',
        width: 0,
        height: 0,
        horizontalPosition: '',
        verticalPosition: '',
        horizontalOffset: 0,
        verticalOffset: 0,
      },
      resultData: {}
    };
  },
  computed: {
    horizontalPositionOptions() {
      return horizontalPositionOptions.map(option => {
        return {text: option, value: option};
      });
    },
    shapeTypeOptions() {
      return shapeTypeOptions;
    },
    verticalPositionOptions() {
      return verticalPositionOptions.map(option => {
        return {text: option, value: option};
      });
    },
    isEnabledOptions() {
      return isEnabledOptions;
    },
  },
  watch: {
    underlyingShape: {
      handler(newVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(this.resultData)) {
          Object.assign(this, newVal);
          this.updateResultData();
        }
      },
      deep: true,
      immediate: true
    },
    isEnabled: function () {
      this.updateResultData();
    },
    resultData: function (newResultData) {
      this.$emit('data-updated', newResultData);
    },
    'image.url': debounce(function (newValue) {
      this.getImageDimensions(newValue);
    }, 400),
  },
  methods: {
    getImageDimensions(url) {
      try {
        const img = new Image();

        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          this.updateResultData();
        };

        img.src = url;
      } catch (error) {
      }
    },
    updateResultData() {
      this.resultData = {
        isEnabled: this.isEnabled,
        shapeType: this.shapeType,
        image: {...this.image}
      };
    },
  }
}
</script>

<style scoped>
.fas:hover {
  color: black !important;
}
</style>
