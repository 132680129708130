<template>
  <div>
    <b-modal
      id="delete-download-settings-modal"
      title="Delete"
      centered
      @ok="onDelete"
    >
      <p>Are you sure you want to delete this setting?</p>
    </b-modal>
  </div>
</template>

<script>
import savedDowloadSettingsAPI from "@frontend/services/api/layout-saved-download-settings.js";

export default {
  name: 'DeleteDownloadSettingsModal',
  data() {
    return {
      setting: null,
    };
  },
  methods: {
    show(setting) {
      this.setting = setting;
      this.$bvModal.show('delete-download-settings-modal');
    },
    async onDelete() {
      try {
        const { data: setting } = await savedDowloadSettingsAPI.delete(this.setting.id);
        this.$emit('setting-deleted');
      } catch (error) {
        window.toastr.error('Failed to delete setting.');
        console.error(error);
      }
    },
  },
};
</script>
