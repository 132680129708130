export default {
  methods: {
    init() {
      this.$bvModal.show(this.modalId);
    },
    close() {
      this.$bvModal.hide(this.modalId);
    },
    closeHandler() {
      this.$emit('close')
    },
  }
}
