<template>
  <div
    id="bynder-metadata-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="bynderMetadataModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="bynderMetadataModalLabel"
            class="modal-title"
          >
            Bynder Metadata
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeHandler"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div
            v-if="isLoading"
            class="text-center"
          >
            <p>Loading metaproperties...</p>
            <b-spinner variant="primary" />
          </div>

          <div
            v-else-if="error"
            class="alert alert-danger"
          >
            {{ error }}
          </div>

          <div v-else>
            <!-- Text Inputs -->
            <div class="border rounded row p-2 m-2">
              <div
                v-for="prop in metaproperties.longtext"
                :key="prop.id"
                class="col-md-6 mt-2 mb-2"
                :class="{ 'd-none': prop.hidden }"
              >
                <label :for="`longtext-${prop.id}`">{{ prop.label }}</label>
                <input
                  :id="`longtext-${prop.id}`"
                  v-model="form[prop.id].value"
                  type="text"
                  class="form-control"
                  :placeholder="`Enter ${prop.label}`"
                  :disabled="prop.disabled"
                >
              </div>
            </div>

            <!-- Date Inputs -->
            <div class="border rounded row p-2 m-2">
              <div
                v-for="prop in metaproperties.date"
                :key="prop.id"
                class="col-md-6 mt-2 mb-2"
              >
                <label :for="`date-${prop.id}`">{{ prop.label }}</label>
                <input
                  :id="`date-${prop.id}`"
                  v-model="form[prop.id].value"
                  type="date"
                  class="form-control"
                >
              </div>
            </div>

            <!-- Selects -->
            <div class="border rounded row p-2 m-2">
              <div
                v-for="prop in metaproperties.select"
                :key="prop.id"
                class="col-md-6 mt-2 mb-2"
              >
                <label :for="`select-${prop.id}`">{{ prop.label }}</label>
                <Multiselect
                  :id="`multiselect-${prop.id}`"
                  :value="findOptionById(prop.id)"
                  :options="prop.options"
                  :multiple="false"
                  track-by="id"
                  label="label"
                  placeholder="Select options"
                  @input="onSelect($event, prop.id)"
                />
              </div>
            </div>

            <!-- Multiselects -->
            <div class="border rounded row p-2 m-2">
              <div
                v-for="prop in metaproperties.multiselect"
                :key="prop.id"
                class="col-md-6 mt-2 mb-2"
              >
                <label :for="`multiselect-${prop.id}`">{{ prop.label }}</label>
                <Multiselect
                  :id="`multiselect-${prop.id}`"
                  :value="parseSelectedIds(prop.id)"
                  :options="prop.options"
                  :multiple="true"
                  track-by="id"
                  label="label"
                  placeholder="Select options"
                  @input="onMultiSelect($event, prop.id)"
                />
              </div>
            </div>

            <!-- Failed Properties -->
            <div
              v-if="metaproperties.failed.length"
              class="alert alert-info mt-3"
            >
              <div class="text-center">
                <h4 class="alert-heading">
                  Unable to Load Some Properties
                </h4>
                <p>We encountered issues retrieving the following properties:</p>
              </div>
              <ul class="list-unstyled">
                <li
                  v-for="failed in metaproperties.failed"
                  :key="failed.id"
                  class="mb-2"
                >
                  <strong>{{ failed.name }}</strong>
                  <br>
                  <small class="text-muted">
                    ID: {{ failed.id }}
                  </small>
                  <br>
                  <span class="text-danger">
                    Error:
                    {{ failed.message.message || 'Unable to retrieve property' }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <div>
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              :disabled="isLoading"
              @click="saveMetaValues('clear')"
            >
              Clear Selection
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              :disabled="isLoading"
              @click="saveMetaValues('save')"
            >
              Save Selection
            </button>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              data-dismiss="modal"
              @click="closeHandler"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              :disabled="isLoading"
              @click="handleMetaproperties"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BYNDER_METADATA_EVENTS,
  bynderMetadataEventBroker,
} from '@frontend/group/modules/bynder-metadata/event-broker';
import Multiselect from 'vue-multiselect';
import {
  storePublishPreferences,
  getPublishPreferences,
} from '@frontend/group/modules/publish-utils/publish-utils.js';
import {
  BYNDER_META_VALUES,
} from '@frontend/constants/bynder.js';
import toastr from 'toastr';

export default {
  name: 'BynderMetadataModal',
  components: {
    Multiselect
  },
  data: () => ({
    metaproperties: {
      longtext: [],
      date: [],
      select: [],
      multiselect: [],
      failed: [],
    },
    form: {},
    isLoading: false,
    error: null,
    saveSelections: false,
    allProps: {},
  }),
  mounted() {
    bynderMetadataEventBroker.on(BYNDER_METADATA_EVENTS.INIT, () => {
      this.init();

      this.fetchMetaproperties();
    });
  },
  methods: {
    init() {
      $('#bynder-metadata-modal').modal('show');
    },

    closeHandler() {
      $('#bynder-metadata-modal').modal('hide');
    },

    async handleMetaproperties() {
      bynderMetadataEventBroker.fire(BYNDER_METADATA_EVENTS.HANDLE_META, {
        meta: this.form
      });

      if ($('#remember-bynder-settings').is(':checked')) {
        const filteredForm = this.filteredForm(this.form);

        await storePublishPreferences(BYNDER_META_VALUES, filteredForm);

        toastr.success('Saved');
      }

      this.closeHandler();
    },

    onSelect(value, id) {
      this.$set(this.form[id], 'value', value ? value.id : '');
    },

    findOptionById(propId) {
      const selectedId = this.form[propId].value;
      if (!selectedId) return null;
      const prop = this.metaproperties.select.find((p) => p.id === propId);
      return prop ? prop.options.find((opt) => opt.id === selectedId) || null : null;
    },

    onMultiSelect(values, propId) {
      const ids = values ? values.map(option => option.id).join(',') : '';
      this.$set(this.form[propId], 'value', ids);
    },

    parseSelectedIds(propId) {
      const idsString = this.form[propId].value || '';
      if (!idsString) return [];
      const ids = typeof idsString === 'string' ? idsString.split(',') : [];
      const prop = this.metaproperties.multiselect.find(p => p.id === propId);
      if (!prop) return [];
      return ids
        .map(id => prop.options.find(opt => opt.id === id))
        .filter(option => option);
    },

    loadSavedData(savedData) {
      Object.entries(savedData).forEach(([propId, data]) => {
        const prop = this.allProps.find(p => p.id === propId);

        if (prop.default_value === null) {
          this.$set(this.form, propId, {
            value: data.value ?? prop.value,
            name: data.name ?? prop.label,
            is_remembered: data.is_remembered ?? prop.is_remembered,
          });
        }
      });
    },

    async fetchMetaproperties() {
      this.isLoading = true;
      this.error = null;
      this.metaproperties = { longtext: [], date: [], select: [], multiselect: [], failed: [] };
      this.form = {};

      try {
        const platformId = $('#deployment_platform').find(':selected').val();

        const response = await axios.post('/bynder-metadata/fetch-metaproperties', {
          platform_id: platformId,
        });

        if (response.data.status === 'error') {
          throw new Error(response.data.message);
        }

        this.metaproperties = response.data.metaproperties;

        this.initializeForm();

        const savedMetadata = await getPublishPreferences(BYNDER_META_VALUES);

        if (savedMetadata) {
          this.loadSavedData(savedMetadata);
        }
      } catch (err) {
        this.error = err.message || 'Failed to fetch metaproperties';
      } finally {
        this.isLoading = false;
      }
    },

    initializeForm() {
      this.allProps = [
        ...this.metaproperties.longtext,
        ...this.metaproperties.date,
        ...this.metaproperties.select,
        ...this.metaproperties.multiselect,
      ];

      this.allProps.forEach((prop) => {
        const isMultiSelect = prop.type === 'multiselect' || (prop.type === 'select' && prop.isMultiselect);
        const defaultValue = isMultiSelect
          ? (prop.default_value ? prop.default_value : [])
          : (prop.default_value ?? '');

        this.$set(this.form, prop.id, {
          value: defaultValue,
          name: prop.label,
          is_remembered: prop.is_remembered,
        });
      });
    },

    async saveMetaValues(type = 'save') {
      if (type === 'save') {
        const filteredForm = this.filteredForm(this.form);

        await storePublishPreferences(BYNDER_META_VALUES, filteredForm);

        toastr.success('Saved');
      } else if (type === 'clear') {
        await storePublishPreferences(BYNDER_META_VALUES, null);

        this.initializeForm();

        toastr.success('Cleared');
      }
    },

    filteredForm(form) {
      return Object.fromEntries(
        Object.entries(form).filter(([_, propData]) => propData.is_remembered)
      )
    },
  },
};
</script>
