import BaseElement from "./BaseElement";
import urlHelpers from '../../../../services/helpers/url';

export default class TableCell extends BaseElement {

  value = '';
  src = '';
  colspan = 1;

  constructor(value = '') {
    super();

    this.styles.setRules({
      'border-width': '1px',
      'border-color': '#000000',
      'border-style': 'solid',
      'padding-top': '2px',
      'padding-bottom': '2px',
      'padding-left': '5px',
      'padding-right': '5px',
      'background-color': '#ffffff',
      'white-space': 'pre-line',
      'line-height': 1.5
    });

    this.value = value;
  }

  setValue(value) {
    this.value = value;
  }

  setSrc(src = '') {
    this.src = src;
  }

  setColspan(value) {
    this.colspan = value ?? this.colspan;
  }

  getColspanAttribute() {
    return `colspan="${this.colspan}"`;
  }
  
  getSrc() {
    return this.src;
  }
  
  getStyles() {
    return this.styles.getStyles()
  }

  async imageUrlToBase64(url) {
    const _url = urlHelpers.normalizeImgUrl(url)
    
    const data = await fetch(_url);
    const blob = await data.blob();
    
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.readAsDataURL(blob);
      
      reader.onloadend = () => {
        const base64data = reader.result;
        
        resolve(base64data);
      };
      
      reader.onerror = reject;
    });
  };

  async toHTML() {
    if (this.src) {
      // To display the image in the table cell after converting the table to an image, it should be converted to Base64. 
      const base64 = await this.imageUrlToBase64(this.src);
      
      return `<td ${this.getStyleAttribute()} ${this.getColspanAttribute()}><img style="width: 100%; display: block" src="${base64}" alt="img"/></td>`;
    }
    
    return `<td ${this.getStyleAttribute()} ${this.getColspanAttribute()}>${this.value}</td>`;
  }

  toData() {
    return {
      id: this.id,
      stringStyles: this.styles.getStyle(),
      styles: this.styles,
      value: this.value,
      src: this.src,
      colspan: this.colspan,
    }
  }
}
