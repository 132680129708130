var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex-column flex-grow-1",
      class: { "d-none": _vm.hidden, "d-flex": !_vm.hidden },
      staticStyle: { height: "calc(100vh - 132px)" },
    },
    [
      _c(
        "div",
        {
          ref: "messages",
          staticClass: "flex-grow-1 card mb-2",
          attrs: { id: "ai-chat-messages" },
        },
        [
          _c("div", { staticClass: "card-body" }, [
            _vm.messages.length
              ? _c(
                  "div",
                  _vm._l(_vm.messages, function (message, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class: ["message", `message-${message.role}`],
                      },
                      [
                        _c("p", {
                          domProps: { innerHTML: _vm._s(message.content) },
                        }),
                        _vm._v(" "),
                        message.imageUrl
                          ? _c("img", {
                              staticClass: "mt-2",
                              staticStyle: { width: "200px", height: "auto" },
                              attrs: { src: message.imageUrl },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        message.proofLink
                          ? _c("div", { staticClass: "input-group mt-2" }, [
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  id: `proof-link-${index}`,
                                  type: "text",
                                  readonly: "",
                                },
                                domProps: { value: message.proofLink },
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyProofLink(
                                        `proof-link-${index}`
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-clipboard",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v("\n              Copy\n            "),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        message.hasSelectButton
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-primary mt-2",
                                on: {
                                  click: function ($event) {
                                    return _vm.selectProduct(message.productId)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Select this product\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "w-100 h-100 d-flex flex-column justify-content-center align-items-center",
                  },
                  [
                    _c("p", { staticClass: "h5" }, [
                      _vm._v("\n          How can I assist you?\n        "),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-muted" }, [
                      _vm._v(
                        "\n          I can find elements, edit text, and more.\n        "
                      ),
                    ]),
                  ]
                ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-shrink-0 input-group mt-2" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.userInput,
              expression: "userInput",
            },
          ],
          staticClass: "form-control",
          attrs: {
            placeholder: "Type your message...",
            type: "text",
            disabled: _vm.loading,
          },
          domProps: { value: _vm.userInput },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.sendMessage.apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.userInput = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary ml-2",
            attrs: { disabled: _vm.loading, type: "button" },
            on: { click: _vm.sendMessage },
          },
          [_vm._v("\n      Send\n    ")]
        ),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "position-absolute w-100 h-100 d-flex flex-column justify-content-center align-items-center",
            },
            [_vm._m(0)]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "spinner-border", attrs: { role: "status" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }