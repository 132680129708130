<template>
  <div class="p-file-browser">
    <div
      v-if="breadcrumbs.length"
      class="p-file-browser__breadcrumbs-wrapper"
    >
      <BreadcrumbItem
        :breadcrumbs="breadcrumbs"
        @goTo="breadcrumbsNavigation"
      />
    </div>
    <b-card class="c-file-browser">
      <MainView />
      <AllModals />
    </b-card>
  </div>
</template>

<script>
import MainView from "./MainView.vue";
import BreadcrumbItem from "@frontend/components/common/atoms/BreadcrumbItem.vue";
import { createNamespacedHelpers } from 'vuex'
import AllModals from "./modals/AllModals";

const { mapGetters, mapActions } = createNamespacedHelpers('fileBrowserProductSelector')

export default {
  name: "FileBrowser",
  components: {AllModals, BreadcrumbItem, MainView},
  computed: {
    ...mapGetters(['breadcrumbs']),
  },
  methods: {
    ...mapActions(['setCurrentFolder']),
    breadcrumbsNavigation({index}) {
      const goTo = this.breadcrumbs[index - 1];

      if (!goTo) {
        this.setCurrentFolder(null);
        return;
      }
      this.setCurrentFolder({...goTo, name: goTo.text});
    }
  }
}
</script>

<style scoped lang="scss">
.c-file-browser {
  border: none;
  border-radius: 16px;
  font-family: "Nunito", sans-serif;
  margin-bottom: 0;
  
  ::v-deep .card-body {
    padding: 0;
  }

  &__menu {
    ::v-deep .nav-tabs {
      border-bottom: none;
      margin-bottom: 20px;

      a.nav-link {
        font-weight: 700;
        color: #727272;
      }

      a.nav-link:hover {
        border-radius: 12px;
        border-color: #007964;
      }

      a.nav-link.active {
        padding: 6px 23px;
        border-radius: 12px;
        background-color: #007964;
        color: #f6f6f6;
        pointer-events: none;
      }
    }
  }
}
.p-file-browser {
  &__breadcrumbs-wrapper {
    height: 35px;
      & ol.breadcrumb {
          padding: 0;
      }
  }
  &__header h2 {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    color: #061d19;
  }
}
</style>
