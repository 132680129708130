var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-tabs",
    { attrs: { "content-class": "mt-3", small: "", fill: "" } },
    [
      _c(
        "b-tab",
        { attrs: { title: "Images" } },
        [
          _c(
            "b-btn-group",
            { attrs: { vertical: "" } },
            [
              _c(
                "b-btn",
                {
                  attrs: { variant: "outline-primary" },
                  on: { click: _vm.addProductImages },
                },
                [
                  _c("b-icon", { attrs: { icon: "file-earmark-image" } }),
                  _vm._v("\n        Add product images...\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: { variant: "outline-primary" },
                  on: { click: _vm.addProductDimensions },
                },
                [
                  _c("b-icon", { attrs: { icon: "aspect-ratio" } }),
                  _vm._v("\n        Add product dimensions...\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addAdditionalField("Background Theme Image")
                    },
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "card-image" } }),
                  _vm._v("\n        Add image...\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: { variant: "outline-primary" },
                  on: { click: _vm.showSmartObject },
                },
                [
                  _c("b-icon", { attrs: { icon: "card-image" } }),
                  _vm._v("\n        Add Smart Object...\n      "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-tab",
        { attrs: { title: "Add Fields" } },
        [
          _c(
            "b-btn-group",
            { attrs: { vertical: "" } },
            [
              _c(
                "b-btn",
                {
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addAdditionalField("Multi Line Text")
                    },
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "input-cursor-text" } }),
                  _vm._v("\n        Multi Line Text\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addAdditionalField("Rectangle")
                    },
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "square" } }),
                  _vm._v("\n        Rectangle\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addAdditionalField("Circle")
                    },
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "circle" } }),
                  _vm._v("\n        Circle\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addAdditionalField("Safe Zone")
                    },
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "x-square" } }),
                  _vm._v("\n        Safe Zone\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addAdditionalField("Icons")
                    },
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "file-earmark-image" } }),
                  _vm._v("\n        Icons\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addAdditionalField("Line")
                    },
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "border-width" } }),
                  _vm._v("\n        Line\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addAdditionalField("Table")
                    },
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "table" } }),
                  _vm._v("\n        Table\n      "),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("IconsSettingsModal", { ref: "iconsSettingsModal" }),
          _vm._v(" "),
          _c("SimpleTableBuilderModal"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }