var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "tableFileUploadModal",
      attrs: {
        id: "table-file-upload-modal",
        title: "Upload image",
        "ok-title": "Save",
        centered: "",
      },
      on: { ok: _vm.onSave },
    },
    [
      _c("b-input", {
        attrs: { placeholder: "Enter image URL" },
        on: { input: (val) => (_vm.url = val) },
        model: {
          value: _vm.url,
          callback: function ($$v) {
            _vm.url = $$v
          },
          expression: "url",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }