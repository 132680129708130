var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal m-icc-profile",
      attrs: { id: "icc-profile-modal", role: "dialog", "data-focus": "false" },
    },
    [
      _c("div", { staticClass: "m-icc-profile__overlay" }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v("\n          ICC profile selector\n        "),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button", "aria-label": "Close" },
                on: { click: _vm.close },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c("b-form-select", {
                attrs: { options: _vm.options },
                on: { change: _vm.onIccChange },
                model: {
                  value: _vm.selectedIcc,
                  callback: function ($$v) {
                    _vm.selectedIcc = $$v
                  },
                  expression: "selectedIcc",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "mt-3 m-icc-profile__preview" }, [
                _c("img", {
                  staticClass: "m-icc-profile__preview--image",
                  attrs: { src: _vm.previewUrl, alt: "" },
                  on: { load: _vm.onLoadImage },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "m-icc-profile__preview--overlay",
                    class: { "d-none": !_vm.isImageLoading },
                  },
                  [_vm._m(0)]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "primary",
                    disabled: _vm.isApplying || !_vm.selectedIcc,
                  },
                  on: { click: _vm.applyButtonHandler },
                },
                [
                  _c("span", {
                    staticClass: "spinner-border spinner-border-sm",
                    class: { "d-none": !_vm.isApplying },
                    attrs: { role: "status", "aria-hidden": "true" },
                  }),
                  _vm._v("\n          Apply\n        "),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                { attrs: { variant: "secondary" }, on: { click: _vm.close } },
                [_vm._v("\n          Cancel\n        ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border m-icc-profile__preview--overlay__spinner",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }