<template>
  <b-modal
    id="table-file-upload-modal"
    ref="tableFileUploadModal"
    title="Upload image"
    ok-title="Save"
    centered
    @ok="onSave"
  >
    <b-input 
      v-model="url"
      placeholder="Enter image URL"
      @input="(val) => url = val"
    />
  </b-modal>
</template>

<script>
import urlHelpers from '../../../../services/helpers/url';

export default {
  name: 'TableFileUploadModal',
  
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  
  data: () => ({
    url: ''
  }),
  
  methods: {
    show() {
      this.url = this.src;
      
      this.$bvModal.show('table-file-upload-modal');
    },
    
    onSave() {
      if (this.url) {
        this.$emit('save', urlHelpers.normalizeImgUrl(this.url));
      }
    } 
  }
}
</script>

<style lang="scss" scoped>

</style>
