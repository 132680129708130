<template>
  <div>
    <div class="d-flex align-items-center mt-2 mb-3">
      <b-button
        v-for="(preset, key) in presets"
        :key="key"
        class="mr-4 text-capitalize"
        :class="[`preset-${key}`]"
        :variant="`${key === activePreset ? '' : 'outline-'}primary`"
        @click="usePreset(key)"
      >
        {{ key }}
      </b-button>
    </div>
    <template v-if="selectedShadow === 'oval'">
      <OvalShadow
        :preset-data="presets['oval']"
        :target="target"
      />
    </template>
    <template v-else>
      <b-form-group label="Blur">
        <b-input-group>
          <b-input
            v-model="blur"
            :max="100"
            :min="0"
            type="range"
            @input="setShadow"
          />
          <template #append>
            <b-input
              v-model="blur"
              :max="100"
              :min="0"
              type="number"
              @input="setShadow"
            />
          </template>
        </b-input-group>
      </b-form-group>

      <b-form-group label="Distance">
        <b-input-group>
          <b-input
            v-model="distance"
            :max="100"
            :min="0"
            type="range"
            @input="calculateOffsets"
          />
          <template #append>
            <b-input
              v-model="distance"
              :max="100"
              :min="0"
              type="number"
              @input="calculateOffsets"
            />
          </template>
        </b-input-group>
      </b-form-group>

      <b-form-group label="Angle">
        <b-input-group>
          <b-input
            v-model="angle"
            :max="360"
            :min="0"
            type="range"
            @input="calculateOffsets"
          />
          <template #append>
            <b-input
              v-model="angle"
              :max="360"
              :min="0"
              type="number"
              @input="calculateOffsets"
            />
          </template>
        </b-input-group>
      </b-form-group>

      <b-form-group label="Intensity">
        <b-input-group>
          <b-input
            v-model="intensity"
            :max="100"
            :min="0"
            type="range"
          />
          <template #append>
            <b-input
              v-model="intensity"
              :max="100"
              :min="0"
              type="number"
            />
          </template>
        </b-input-group>
      </b-form-group>

      <b-form-group label="Color">
        <ColorPicker
          v-model="color"
          opacity
          copy
          position="right-middle"
          @input="setShadow"
        />
      </b-form-group>
    </template>
  </div>
</template>

<script>
import ColorPicker from './components/ColorPicker.vue'
import OvalShadow from "@frontend/components/modules/layouts-page/EditorControl/components/OvalShadow.vue";

export default {
  name: 'ShadowsModule',

  components: {
    OvalShadow,
    ColorPicker,
  },

  props: {
    target: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    angle: 0,
    distance: 0,
    color: null,
    blur: 0,
    offsetX: 0,
    offsetY: 0,
    
    presets: {
      none: {
        blur: 0,
        angle: 0,
        distance: 0,
        intensity: 50,
        color: '#00000080',
      },
      glow: {
        blur: 20,
        angle: 0,
        distance: 0,
      },
      drop: {
        blur: 15,
        angle: 145,
        distance: 28,
      },
      oval: {
        width: 80,
        height: 15,
        opacity: 50,
        offsetX: 10,
        offsetY: 90,
        blur: 30,
        color: '#000000',
      },
    },
    selectedShadow: 'none'
  }),

  computed: {
    activePreset () {
      if (this.selectedShadow === 'oval') {
        return 'oval'
      }
      return _.findKey(this.presets, {
        blur: +this.blur,
        angle: +this.angle,
        distance: +this.distance,
      })
    },
    intensity: {
      get () {
        // Convert hex alpha (00-FF) to decimal and scale it to 0-100
        const alphaHex = this.color?.length === 9 ? this.color.slice(-2) : 'FF'
        return Math.round(parseInt(alphaHex, 16) / 255 * 100)
      },
      set (val) {
        // Convert intensity (0-100) to hex alpha (00-FF)
        let alphaHex = Math.round(val / 100 * 255).toString(16).padStart(2, '0')
        let baseColor = this.color.length === 9 ? this.color.substring(0, 7) : this.color
        baseColor += alphaHex
        this.color = baseColor
        this.setShadow()
      },
    },
  },

  watch: {
    target: {
      handler (val) {
        this.selectedShadow = 'none';
        if (val?.ovalShadow) {
          this.selectedShadow = 'oval';
          return
        }
        const { angle, distance, color, blur, offsetX, offsetY } = val?.shadow || this.presets.none
        this.angle = angle
        this.distance = distance
        this.color = color
        this.blur = blur
        this.offsetX = Math.round(offsetX)
        this.offsetY = Math.round(offsetY)
      },
      immediate: true,
    },
  },

  methods: {
    setShadow () {
      this.target.set('shadow', {
        angle: +this.angle,
        distance: +this.distance,
        color: this.color,
        blur: +this.blur,
        offsetX: this.offsetX,
        offsetY: this.offsetY,
      })
      this.target.canvas.renderAll()
      this.target.canvas.fire('shadow:changed', this.target)
    },
    usePreset (key) {
      this.selectedShadow = key;
      const { blur, angle, distance, color } = this.presets[key];
      
      if (key === 'oval') {
        this.target.set('shadow', null);
        return
      }
      this.target.set('ovalShadow', null);
      this.target.set('ovalShadowGroupId', null);
      this.angle = angle
      this.distance = distance
      this.blur = blur
      if (color) {
        this.color = color
      }
      this.calculateOffsets()
    },
    calculateOffsets () {
      const angleRadians = this.angle * (Math.PI / 180)
      this.offsetX = Math.round(Math.cos(angleRadians) * -this.distance)
      this.offsetY = Math.round(Math.sin(angleRadians) * this.distance)
      this.setShadow()
    },
  },
}
</script>

<style scoped lang="scss">
.preset {
  &-none {
    box-shadow: none;
  }

  &-glow {
    box-shadow: 0 0 8px rgb(0, 0, 0);
  }

  &-drop {
    box-shadow: 5px 5px 10px rgb(0, 0, 0);
  }
}
</style>
