<template>
  <div>
    <b-form-row>
      <b-col>
        <b-form-group label="Insert PDF">
          <b-form-select
            v-model="isEnabled"
            :options="availabilityOptions"
            class="form-control"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <div v-if="isEnabled">
      <b-card bg-variant="light">
        <b-form-row>
          <b-col cols="6">
            <b-form-group label="Link to PDF">
              <b-form-input
                v-model="tempInsertion.pdf"
                class="form-control"
                placeholder="Enter the link to the PDF"
              />
            </b-form-group>
          </b-col>

          <b-col cols="5">
            <b-form-group label="Insert after">
              <Multiselect
                v-model="tempInsertion.after"
                :multiple="false"
                :options="templates"
                track-by="instance_id"
                label="name"
                placeholder="Select template"
              />
            </b-form-group>
          </b-col>

          <b-col class="align-content-end mb-3">
            <b-button
              variant="primary"
              @click="addInsertion"
            >
              Add
            </b-button>
          </b-col>
        </b-form-row>

        <b-row
          v-for="(insertion, index) in insertions"
          :key="index"
          class="py-2"
          :class="{'border-bottom': index < insertions.length - 1}"
        >
          <b-col
            cols="6"
            class="text-truncate"
          >
            {{ insertion.pdf }}
          </b-col>

          <b-col
            cols="5"
            class="text-truncate"
          >
            {{ insertion.after.name }} : {{ insertion.after.instance_id }}
          </b-col>

          <b-col>
            <b-button
              variant="danger"
              @click="removeInsertion(index)"
            >
              <i class="fas fa-trash" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: "ExportInsertPagesConfig",
  components: {
    Multiselect,
  },
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({
        isEnabled: false,
        insertions: [],
      }),
    },
    groupInstances: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isEnabled: false,
      insertions: [],
      templates: [],
      availabilityOptions: [
        { text: 'Enabled', value: true },
        { text: 'Disabled', value: false },
      ],
      tempInsertion: {
        pdf: null,
        after: null,
      },
      resultData: {
        isEnabled: false,
        insertions: [],
      },
    };
  },
  watch: {
    config: {
      handler(newVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(this.resultData)) {
          Object.assign(this, newVal);
          this.updateResultData();
        }
      },
      deep: true,
      immediate: true,
    },
    groupInstances: {
      handler(newValue) {
        this.templates = newValue.flatMap(
          (group) => group.children.map(instance => ({
            instance_id: instance.value,
            name: instance.text
          }))
        );
      },
      immediate: true,
    },
    isEnabled: function() {
      this.updateResultData();
    },
    insertions: function() {
      this.updateResultData();
    },
    resultData: function(newResultData) {
      this.$emit('data-updated', newResultData);
    },
  },
  methods: {
    addInsertion() {
      this.insertions.push(this.tempInsertion);
      this.tempInsertion = { pdf: null, after: null };
    },
    removeInsertion(index) {
      this.insertions.splice(index, 1);
    },
    updateResultData() {
      this.resultData = {
        isEnabled: this.isEnabled,
        insertions: this.insertions,
      };
    },
  },
};
</script>
