<template>
  <div class="c-folder-layer">
    <div v-if="node.type === PARSED_FIELD_TYPES.FOLDER || node.type === PARSED_FIELD_TYPES.TEMPLATE">
      <div
        class="row c-folder-layer__folder d-flex align-items-center"
      >
        <div class="col-9 d-flex align-items-center">
          <b-form-checkbox
            class="mr-1"
            :checked="node.visible"
            @change="visibilityHandler"
          />
          <div
            class="cursor-pointer"
            @click="collapseHandler"
          >
            <b-icon
              icon="triangle-fill"
              class="mr-2"
              :class="{'closed': !visible, 'opened': visible}"
            />
            <svg
              width="20"
              height="20"
              class="mr-2"
            >
              <use href="/img/icons/sprite.svg#file-folder-svg" />
            </svg>
            {{ node.name }}
          </div>
        </div>
        <div
          v-if="node.type === PARSED_FIELD_TYPES.FOLDER"
          class="col-3 d-flex justify-content-end"
        >
          <div
            v-b-tooltip.hover
            class="mr-1"
            title="To root"
          >
            <b-icon
              id="tooltip-target-1"
              icon="chevron-double-up"
              class="cursor-pointer"
              @click="toRoot"
            />
          </div>

          <div
            v-if="!mergingNodes.includes(node.uuid)"
            v-b-tooltip.hover 
            title="Merge"
          >
            <b-icon
              id="tooltip-target-1"
              icon="file-zip"
              class="cursor-pointer"
              @click="mergeHandler"
            />
          </div>
          <b-spinner
            v-else
            small
          />
        </div>
      </div>
      <b-collapse :visible="visible">
        <div class="ml-3 mb-2">
          <div
            v-for="child in node.children"
            :key="child.uuid"
          >
            <FolderLayer
              :node="child"
            />
          </div>
        </div>
      </b-collapse>
    </div>
    <div
      v-else
      class="row"
    >
      <div class="layer--item col-9 d-flex align-items-center position-relative">
        <b-form-checkbox
          class="mr-2"
          :checked="node.visible"
          @change="visibilityHandler"
        />
        <component
          :is="childComponent(node)"
          :node="node"
        />
      </div>
      <div
        v-if="isTemplateFeatures"
        class="layer--item__actions col-3 d-flex justify-content-end w-100"
      >
        <b-dropdown
          v-if="fieldsByType && fieldsByType.length && PSD_LAYER_TYPE_TO_BASIC_MAP[node.type] === FIELD_TYPES.IMAGE"
          class="layer--item__setting-menu"
          variant="link"
          no-caret
        >
          <template #button-content>
            <i class="c-icon cil-settings" />
          </template>
          <div
            class="px-4"
            @click.stop
          >
            <b-form-checkbox
              :checked="node.isFlipV"
              @input="(value) => setSettings(node, 'isFlipV', value)"
            >
              Flip vertical
            </b-form-checkbox>
            <b-form-checkbox
              :checked="node.isFlipH"
              @input="(value) => setSettings(node, 'isFlipH', value)"
            >
              Flip horizontal
            </b-form-checkbox>
          </div>
        </b-dropdown>
        <b-dropdown
          class="layer--item__mapper-menu"
          variant="link"
          no-caret
        >
          <template #button-content>
            <svg
              class="x-svg"
              width="22"
              height="22"
            >
              <use href="/img/icons/sprite.svg#transfer" />
            </svg>
          </template>
          <template
            v-if="fieldsByType && fieldsByType.length"
          >
            <b-dropdown-item
              :class="{selected: !node.field}"
              @click.stop="fieldClickHandler(node, undefined)"
            >
              None
            </b-dropdown-item>
            <b-dropdown-item
              v-for="field in fieldsByType"
              :key="field.id"
              :class="{selected: node.field && node.field.id === field.id}"
              @click.stop="fieldClickHandler(node, field)"
            >
              {{ field.name }}
            </b-dropdown-item>
          </template>
          <template v-else>
            <b-dropdown-item
              v-for="fieldType in layerFieldMap"
              :key="fieldType"
              :class="{ selected: node.fieldType === fieldType }"
              @click.stop="setLayerTypeHandler(node, fieldType)"
            >
              {{ fieldType }}
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import {PARSED_FIELD_TYPES, PSD_LAYER_TYPE_TO_BASIC_MAP} from "@/includes/parse_psd_data/constants";
import TextLayer from "@frontend/components/modules/psd-layers/layer-views/TextLayer.vue";
import ImageLayer from "@frontend/components/modules/psd-layers/layer-views/ImageLayer.vue";
import {FIELD_TYPES} from "@frontend/constants/field-types";
import {createNamespacedHelpers} from "vuex";
import {MUTATIONS} from "@frontend/store/psd-layers";
import {recursiveFind} from "@/includes/parse_psd_data";
import {LAYER_FIELD_MAP} from "@/includes/data-model-mapper";

const {mapMutations, mapGetters, mapActions} = createNamespacedHelpers('psdLayers')

const LAYER_VIEWS = {
  [PARSED_FIELD_TYPES.TEXT]: TextLayer,
  [PARSED_FIELD_TYPES.MULTI_LINE_TEXT]: TextLayer,
  [PARSED_FIELD_TYPES.BACKGROUND_IMAGE]: ImageLayer,
}

const FolderLayer = {
  name: "FolderLayer",
  props: {
    node: {
      type: Object,
      default: () => ({
        name: 'undefined',
        children: []
      })
    }
  },
  data: () => ({
    visible: false,
  }),
  computed: {
    ...mapGetters([
      'templateFieldsByType',
      'templates',
      'isTemplateFeatures',
      'mergingNodes',
    ]),
    childComponent() {
      return node => LAYER_VIEWS[node.type] || []
    },
    PARSED_FIELD_TYPES() {
      return PARSED_FIELD_TYPES
    },
    PSD_LAYER_TYPE_TO_BASIC_MAP() {
      return PSD_LAYER_TYPE_TO_BASIC_MAP
    },
    FIELD_TYPES() {
      return FIELD_TYPES
    },
    fieldsByType() {
      return this.templateFieldsByType[PSD_LAYER_TYPE_TO_BASIC_MAP[this.node.type]]
        ?.filter(field =>
          !recursiveFind(this.templates, layer => layer.uuid !== this.node.uuid && layer.field && layer.field.id === field.id)
        )
    },
    layerFieldMap() {
      return LAYER_FIELD_MAP[PSD_LAYER_TYPE_TO_BASIC_MAP[this.node.type]] || []
    }
  },
  methods: {
    ...mapMutations({
      toggleVisibility: MUTATIONS.TOGGLE_LAYER,
      updateLayer: MUTATIONS.UPDATE_LAYER,
      setField: MUTATIONS.SET_FIELD,
      selectLayer: MUTATIONS.SELECT_LAYER,
    }),
    ...mapActions([
      'mergeNode',
      'extractToTemplate'
    ]),
    collapseHandler() {
      this.visible = !this.visible
    },
    visibilityHandler() {
      this.toggleVisibility({ layer: this.node })
    },
    setSettings(layer, key, value) {
      if (Array.isArray(key)) {
        key.forEach(key => this.updateLayer({ layer, key, value }));
        return;
      }

      this.updateLayer({layer, key, value});
    },
    fieldClickHandler(node, field) {
      this.setField({
        uuid: node.uuid,
        field: !field || node.field && node.field.id === field.id ? undefined : field,
      })
      this.selectLayer({ layer: node })
    },
    setLayerTypeHandler(layer, fieldType) {
      this.setSettings(layer, ['fieldType', 'data.fieldType'], fieldType);

      this.selectLayer({ layer })
    },
    mergeHandler() {
      this.mergeNode(this.node)
    },
    toRoot() {
      this.extractToTemplate(this.node)
    }
  }
}

export default FolderLayer
</script>

<style scoped lang="scss">
.closed, .opened {
  font-size: 11px;
  transition: transform 0.5s;
}

.closed {
  transform: rotate(90deg);
}

.opened {
  transform: rotate(180deg);
}
</style>
