<template>
  <div>
    <b-row class="justify-content-end">
      <b-col
        cols="2"
        class="mb-3"
      >
        <b-form-select
          v-model="selectedOwner"
          :options="ownerOptions"
          size="sm"
          @change="loadType"
        />
      </b-col>
    </b-row>

    <table class="table table-sm">
      <thead>
        <tr>
          <th class="col-8">
            Name
          </th>

          <th class="col-2">
            Type
          </th>

          <th class="col-2" />
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="setting in settings"
          :key="setting.id"
        >
          <td class="col-8">
            {{ setting.name }}
          </td>

          <td class="col-2">
            {{ setting.type }}
          </td>

          <td class="col-2">
            <template v-if="setting.type !== 'system' || isMasterAdmin">
              <i
                class="cursor-pointer text-secondary fas fa-edit mr-2"
                @click="showEditModal(setting)"
              />

              <i
                class="cursor-pointer text-secondary fas fa-trash"
                @click="showDeleteModal(setting)"
              />
            </template>
          </td>
        </tr>
        <tr v-if="isLoading">
          <td class="col-12 text-center p-5">
            Loading...
          </td>
        </tr>
        <tr v-else-if="settings.length === 0">
          <td class="col-12 text-center p-5">
            No settings found
          </td>
        </tr>
      </tbody>
    </table>

    <div class="d-flex justify-content-center">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        @change="loadPage"
      />
    </div>

    <EditDownloadSettingsModal
      ref="editDownloadSettingsModal"
      @setting-updated="onSettingUpdated"
    />

    <DeleteDownloadSettingsModal
      ref="deleteDownloadSettingsModal"
      @setting-deleted="onSettingUpdated"
    />
  </div>
</template>

<script>
import EditDownloadSettingsModal from "@frontend/components/modules/layouts-page/modals/EditDownloadSettingsModal.vue";
import DeleteDownloadSettingsModal from "@frontend/components/modules/layouts-page/modals/DeleteDownloadSettingsModal.vue";
import savedDowloadSettingsAPI from "@frontend/services/api/layout-saved-download-settings.js";

export default {
  name: 'SavedDownloadSettingsTab',
  components: {
    EditDownloadSettingsModal,
    DeleteDownloadSettingsModal,
  },
  props: {
    isMasterAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      currentPage: 1,
      perPage: 5,
      totalItems: 0,
      selectedOwner: null,
      isLoading: false,
      ownerOptions: [
        { value: null, text: 'All' },
        { value: 'user', text: 'User' },
        { value: 'company', text: 'Company' },
        { value: 'system', text: 'System' },
      ],
      settings: [],
    };
  },
  methods: {
    async load() {
      await this.loadSettings({});
    },
    async loadPage(page) {
      await this.loadSettings({page});
    },
    async loadType(type) {
      this.currentPage = 1;
      this.selectedOwner = type;
      await this.loadSettings({});
    },
    async loadSettings({page}) {
      this.isLoading = true;

      const { data: settings } = await savedDowloadSettingsAPI.index({
        page: page ? page : this.currentPage,
        perPage: this.perPage,
        type: this.selectedOwner
      });

      this.currentPage = settings.current_page;
      this.totalItems = settings.total;
      this.settings = settings.data;

      if (this.currentPage !== 1 && this.settings.length === 0) {
        await this.loadSettings({page: this.currentPage - 1});
      }

      this.isLoading = false;
    },
    showEditModal(setting) {
      this.$refs.editDownloadSettingsModal.show(setting);
    },
    showDeleteModal(setting) {
      this.$refs.deleteDownloadSettingsModal.show(setting);
    },
    onSettingUpdated(setting) {
      this.load();
      this.$emit('settings-updated');
    },
  },
}
</script>

<style scoped>
.fas:hover {
  color: black !important;
}
</style>
