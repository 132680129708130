import axios from 'axios';

export default {
  index(data) {
    return axios.get('/saved-download-settings', { params: data });
  },

  store(data) {
    return axios.post('/saved-download-settings', data);
  },

  update(id, data) {
    return axios.put(`/saved-download-settings/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/saved-download-settings/${id}`);
  }
}
