import BaseElement from "./BaseElement";
import TableBuilder from './index';
import { getFontFaceStyle } from "../helpers";
import {
  ALLOWED_STYLES_TO_APPLY_TO_ALL,
  APPLY_TO_VALUES
} from "@frontend/group/modules/simple-table-builder/constants";

const DEFAUL_COLUMN_TITLE = 'Column';
const DEFAUL_CELL_VALUE = 'Value';

export default class Table extends BaseElement {

  headers = [];

  rows = [];
  
  constructor() {
    super();

    this.styles.setRules({
      'border-width': '1px',
      'border-color': '#000000',
      'border-style': 'solid',
      'border-collapse': 'collapse',
      'color': '#000000',
    });
  }

  setHeaders(headers) {
    this.headers = headers;
  }

  setRows(rows) {
    this.rows = rows;
  }

  setCellsRules(rules) {
    this.rows
      .reduce((acc, row) => [...acc, ...row.cells], [...this.headers])
      .forEach(cell => {
        cell.styles.setRules(rules);
      });
  }

  getSize() {
    let width = 0;
    let height = 0;

    const $table = $(this.toHTML());
    $table.css({
      display: 'block',
      visibility: 'hidden',
      position: 'absolute'
    });
    $table.appendTo('body');

    width = Math.ceil($table.width());
    height = Math.ceil($table.height());

    $table.remove();

    return { width, height }
  }
  
  setDimensions(width, height) {
    this.styles.setRules({
      width: `${width}px`,
      height: height === 'auto' ? height : `${height}px`,
    })
  }

  addColumn(column) {
    this.headers.push(column);
  }

  addRow(row) {
    this.rows.push(row);
  }

  addNewColumn(index = null) {
    index = index ? index : this.headers.length;

    const cell = new TableBuilder.TableCell(DEFAUL_COLUMN_TITLE);

    this.headers.splice(index, 0, cell);

    for (let i = 0; i < this.rows.length; i++) {
      const cell = new TableBuilder.TableCell(DEFAUL_CELL_VALUE);

      this.rows[i].cells.splice(index, 0, cell);
    }
  }

  addNewRow(index = null) {
    index = index ? index : this.rows.length;

    const row = new TableBuilder.TableRow();

    for (let i = 0; i < this.headers.length; i++) {
      row.addCell(new TableBuilder.TableCell(DEFAUL_CELL_VALUE));
    }

    this.rows.splice(index, 0, row);
  }

  removeNewRow(index = null) {
    index = index ? index : this.rows.length;

    this.rows.splice(index -1, 1);
  }

  removeNewColumn(index = null) {
    index = index ? index : this.headers.length;

    this.headers.splice(index -1, 1);

    for (let i = 0; i < this.rows.length; i++) {
      this.rows[i].cells.splice(index - 1, 1);
    }
  }

  createTableGrid(columns = 10, rows = 10) {
    this.headers = [];
    this.rows = [];

    for (let i = 0; i < columns; i++) {
      this.addColumn(new TableBuilder.TableCell(DEFAUL_COLUMN_TITLE));
    }

    for (let i = 0; i < (rows - 1); i++) {
      const row = new TableBuilder.TableRow();

      for (let i = 0; i < columns; i++) {
        row.addCell(new TableBuilder.TableCell('Value'));
      }

      this.addRow(row);
    }
  }

  addCellRow(index = null) {
    index = index ? index : this.headers.length;

    for (let i = 0; i < this.rows.length; i++) {
      const cell = new TableBuilder.TableCell(DEFAUL_CELL_VALUE);
      this.rows[i].cells.splice(index, 0, cell);
    }
  }
  
  addCellHeader(index = null) {
    const cell = new TableBuilder.TableCell(DEFAUL_COLUMN_TITLE);
    this.headers.splice(index, 0, cell);
  }
  
  getCellStyle(id, key, defaultValue) {
    const cell = this.rows
      .reduce((acc, row) => [...acc, ...row.cells], [...this.headers])
      .find(cell => cell.id === id);

    if (!cell) return defaultValue;

    const value = cell.styles.rules[key];
    const number = parseInt(value);

    return value === undefined ? defaultValue : Number.isNaN(number) ? value : number;
  }

  getRowStyle(id, key, defaultValue) {
    const row = this.rows.find(_row => _row.cells.find(_cell => _cell.id === id));
    
    if (!row) return defaultValue;
    
    const value = row.styles.rules[key];
    const number = parseInt(value);

    return value === undefined ? defaultValue : Number.isNaN(number) ? value : number;
  }

  changeRowStyles(params) {
    const rows = this.rows.reduce((acc, _row) => {
      const cellIndex = _row.cells.findIndex(cell => params.cells.includes(cell.id));
      
      if (cellIndex >= 0) {
        return [...acc, _row];
      }
      
      return acc;
    }, []);

    const rules = params.rules.reduce((acc, param) => ({
      ...acc,
      [param.rule]: `${param.value}${param.measure}`
    }), {});
    
    rows.forEach(_row => _row.styles.setRules(rules));
  }
  
  changeCellStyle(cellParams) {
    const cells = this.rows
      .reduce((acc, row) => [...acc, ...row.cells], [...this.headers])
      .filter(cell => cellParams.cells.includes(cell.id));
    
    if (!cells) return;
    
    const rules = cellParams.rules.reduce((acc, param) => ({
      ...acc,
      [param.rule]: `${param.value}${param.measure}`
    }), {});
    
    cells.forEach(cell => {
      cell.styles.setRules(rules);
    });
  }

  changeMultipleCellsStyle(styleParams, applyTo = APPLY_TO_VALUES.ALL, selectedCell = null) {
    const rules = styleParams.reduce((acc, param) => {
      if (!ALLOWED_STYLES_TO_APPLY_TO_ALL.includes(param.rule)) {
        return acc;
      }

      return {
        ...acc,
        [param.rule]: `${param.value}${param.measure}`
      }
    }, {});
    
    let cells;
    
    switch (true) {
      case applyTo === APPLY_TO_VALUES.ALL:
        cells = this.rows.reduce((acc, row) => [...acc, ...row.cells], [...this.headers]);
        break;
      case applyTo === APPLY_TO_VALUES.HEADER:
        cells = this.headers;
        break;
      case applyTo === APPLY_TO_VALUES.BODY:
        cells = this.rows.reduce((acc, row) => [...acc, ...row.cells], []);
        break;
      case applyTo === APPLY_TO_VALUES.COLUMN:
        const rows = this.rows.reduce((acc, row) => [...acc, row.cells], [this.headers]);

        let column = null;
        
        rows.forEach(row => {
          const index = row.findIndex(cell => cell.id === selectedCell.id);

          if (index >= 0) {
            column = index;  
          }
        });
        
        cells = this.rows.reduce((acc, row) => [...acc, row.cells[column]], []);
        
        break;
      case applyTo === APPLY_TO_VALUES.ALTERNATING_ROWS:
        let currentRow = this.rows.findIndex(row => {
          return row.cells.find(cell => cell.id === selectedCell.id);
        });

        // Determine if the odd rows (1) or even (0) should be formatted
        currentRow % 2 === 0 
          ? currentRow = 0 
          : currentRow = 1;
        
        cells = this.rows.reduce((acc, row, index) => {
          if (index === currentRow) {
            currentRow = currentRow + 2;
            
            return [...acc, ...row.cells];
          }
          
          return acc;
        }, []);
        
        break;
      default:
        cells = this.rows.reduce((acc, row) => [...acc, ...row.cells], [...this.headers]);
    }
    
    cells.forEach(cell => {
      cell.styles.setRules(rules);
    });
  }

  changeCellValue(selectedCell) {
    const cell = this.rows
      .reduce((acc, row) => [...acc, ...row.cells], [...this.headers])
      .find(_cell => _cell.id === selectedCell.id);

    const row = this.getRow(cell);
    this.updateRowBasedOnColspan(row, cell, selectedCell.colspan);
    
    cell.setValue(selectedCell.value);
  }
  
  updateRowBasedOnColspan(row, cell, _colspan) {
    if (_colspan < 1) return;
    
    const cellColspan = Number(cell.colspan);
    let colspan = Number(_colspan);
    const cellIndex = row.findIndex(_cell => _cell.id === cell.id);
    
    let availableColspanForCell = cellColspan;
    
    for (let i = cellIndex + 1; i < row.length; i++) {
      if (row[i].colspan > 1) break;
      if (row[i].colspan === 1) availableColspanForCell++;
    }
    
    if (cellColspan < colspan) {
      if (colspan > availableColspanForCell) {
        colspan = availableColspanForCell;
      }

      const diff = colspan - cellColspan;

      row.splice(cellIndex + 1, diff);
      
    } else if (colspan < cellColspan) {
      const diff = cellColspan - colspan;

      for (let i = 0; i < diff; i++) {
        const styles = cell.styles.getStyles();
        const newCell = new TableBuilder.TableCell();
        newCell.styles.setRules(styles);

        row.splice(cellIndex + 1, 0, newCell);
      }
    }

    cell.setColspan(colspan);
  }

  toHTML() {
    const head = `<thead>${this.headers.map(column => column.toHTML()).join('')}</thead>`;
    const body = `<tbody>${this.rows.map(row => row.toHTML()).join('')}</tbody>`;

    return `<table ${this.getStyleAttribute()}>${head}${body}</table>`;
  }

  async toSVG() {
    const fonts = `<style>${await getFontFaceStyle([this.styles.getRule('font-family')])}</style>`;
    const size = this.getSize();
    const div = `<div xmlns="http://www.w3.org/1999/xhtml">${fonts}${this.toHTML()}</div>`;
    const foreignObject = `<foreignObject width="100%" height="100%">${div}</foreignObject>`;

    return `<svg xmlns="http://www.w3.org/2000/svg" width="${size.width + 2}" height="${size.height + 2}">${foreignObject}</svg>`;
  }

  toData() {
    return {
      id: this.id,
      stringStyles: this.styles.getStyle(),
      styles: this.styles,
      headers: this.headers.map(column => column.toData()),
      rows: this.rows.map(rows => rows.toData()),
    }
  }

  async toBlob() {
    const data = await this.toSVG();

    return new Blob([data], {
      type: "image/svg+xml;charset=utf-8"
    });
  }

  getDimension() {
    let width = 0;
    let height = 0;

    const $table = $(this.toHTML());
    $table.css({
      display: 'block',
      visibility: 'hidden',
      position: 'absolute'
    });
    $table.appendTo('body');

    width = Math.ceil($table.width());
    height = Math.ceil($table.height());

    $table.remove();

    return { width, height }
  }
  
  getOuterDimension() {
    const $table = $(this.toHTML());
    
    $table.css({ 
      position: 'absolute', 
      visibility: 'hidden' 
    });
    
    $table.appendTo('body');
    
    let width = $table.outerWidth();
    let height = $table.outerHeight();
    
    $table.remove();
    
    return { width, height };
  }

  getRow(cell) {
    if (this.headers.find(_cell => _cell.id === cell.id)) {
      return this.headers;
    }

    return this.rows.find(row => {
      return row.cells.find(_cell => _cell.id === cell.id);
    }).cells;
  }
}
