import { v4 as uuid } from "uuid";
import {getOrderForAddField, removeUrlOriginLocation, getSettingsDataInstance} from '../../../utils';
import { ShowPageScopeCollector } from '@frontend/group/modules/scope-collectors/show-page-collector';
import { canvasEditModeControl } from "@frontend/group/modules/canvas-edit-mode-control";
import {
  ADDITIONAL_BACKGROUND_THEME_IMAGE_PREFIX_ID, ADDITIONAL_CIRCLE_PREFIX_ID,
  ADDITIONAL_MULTI_LINE_TEXT_FIELD_PREFIX_ID,
  ADDITIONAL_RECTANGLE_PREFIX_ID,
  ADDITIONAL_SAFE_ZONE_PREFIX_ID,
  ADDITIONAL_TEXT_PREFIX_ID,
  ADDITIONAL_LINE_PREFIX_ID, 
  HORIZONTAL_GUIDE_LINE_PREFIX_ID, 
  VERTICAL_GUIDE_LINE_PREFIX_ID,
} from "@frontend/constants/templates-field-prefixes";
import TemplateService from '@frontend/services/api/template'
import {FIELD_TYPE_ADDITIONAL_FIELDS} from "@frontend/constants/type-fields-of-template";
import { NON_USER_LAYER_IDS } from "@/common/preview/positioning-lines";
import { EVENTS, iconsSettingsEventBroker } from "@frontend/group/modules/icons-settings/event-broker";
import { simpleTableBuilderEventBroker, EVENTS as Table_EVENTS } from "@frontend/group/modules/simple-table-builder/event-broker";
import BuilderTable from "@frontend/group/modules/simple-table-builder/builder";
import { parseJsonData } from "@frontend/services/helpers";

const getShowPageScope = () => ShowPageScopeCollector.get([
  'previews',
  'drawPreviews',
  'sliceForCurrentPage',
]);

const getPreview = () => {
  const { previews = [] } = getShowPageScope() ?? {};

  return previews;
}



let canvasData,
  drawFieldsFunctions,
  updateLayoutChanges,
  drawPreviews,
  previews,
  order = 0,
  reorderObjects;

export const DEFAULT_FONT_FOR_ADDITION_TEXT_FIELD = 'AlternateGothicATF-Black';

export const DEFAULT_TEXT_FIELD_PROPERTIES = {
  _alignment: "left",
  _angle: "0.00",
  _b_height: "114",
  _b_width: "186.49969100952148",
  _bounding_rect_data: '{"left":136,"top":57,"width":186,"height":114}',
  _color: "#000",
  _font: DEFAULT_FONT_FOR_ADDITION_TEXT_FIELD,
  _fontsize: "100",
  _height: "0",
  _offset_x: "136.00",
  _offset_y: "57.00",
  _order: "0",
  _shadow: "0",
  _skew_coords: '"[]"',
  _skew_image: "",
  _skew_image_point: "",
  _topmost_y: "19",
  _tracking: "0",
  _width: "185",
  _translated_from_element_id: null,
  _allow_empty_string: false
};

export const DEFAULT_MULTI_LINE_TEXT_FIELD_PROPERTIES = {
  _alignment: "left",
  _angle: "0.00",
  _bounding_data: '{"left":136,"top":57.00000000000001,"width":735.0987739562988,"height":114}',
  _color: "#000",
  _corner_radius: "",
  _corners: "",
  _font: DEFAULT_FONT_FOR_ADDITION_TEXT_FIELD,
  _fontsize: "100",
  _height: "112.99999999999999",
  _leading: "1",
  _line_heights: "113.00",
  _offset_x: "136.00",
  _offset_y: "57.00",
  _opacity: "1",
  _order: "0",
  _radius: "",
  _scaleX: "",
  _scaleY: "",
  _shadow: "0",
  _skew_coords: '"[]"',
  _skew_image: "",
  _skew_image_point: "",
  _thickness: "",
  _topmost_y: '[{"topMostX":14,"topMostY":16}]',
  _tracking: "0",
  _width: "734.0987739562988",
  _translated_from_element_id: null,
  _styles: {}
};
const DEFAULT_RECTANGLE_FIELD_PROPERTIES = {
  _angle: "0",
  _corner_radius: "0",
  _corners: "[0,0,0,0]",
  _fill_color: "#000000",
  _height: "300",
  _offset_x: "0",
  _offset_y: "0",
  _opacity: "1",
  _order: "88",
  _radius: "150",
  _scaleX: "1",
  _scaleY: "1",
  _stroke_color: "#000",
  _thickness: "0",
  _toggle_shape: "on",
  _width: "300",
};
const DEFAULT_CIRCLE_FIELD_PROPERTIES = {
  _angle: "0",
  _corner_radius: "0",
  _corners: "[0,0,0,0]",
  _fill_color: "#000000",
  _height: "300",
  _offset_x: "0",
  _offset_y: "0",
  _opacity: "1",
  _order: "88",
  _radius: "150",
  _scaleX: "1",
  _scaleY: "1",
  _stroke_color: "#000",
  _thickness: "0",
  _toggle_shape: "on",
  _width: "300",
};
export const DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES = {
  'additional_background_images': '/img/icons/image.png',
  "add_bk_img_offset_x": 260,
  "add_bk_img_offset_y": 200,
  "add_bk_img_scale": 1,
  "add_bk_img_scale_y": 1,
  "add_bk_img_shadow": 0,
  "add_bk_img_angle": 0,
  "add_bk_img_is_mirror": false,
  "add_bk_img_mirror_position": "right",
  "add_bk_img_mirror_distance": 0,
  "add_bk_img_mirror_coords": "{}",
  "add_bk_img_mirror_skew": 0,
  "add_bk_img_mirror_delete_source": false,
  "add_bk_img_mirror_fade": false,
  "add_bk_img_order": 150,
  "add_bk_img_duplicate_data": null,
  "add_bk_img_width": 520,
  "add_bk_img_height": 400,
  "add_bk_img_flipX": 0,
  "add_bk_img_flipY": 0,
  "add_bk_img_masks": [],
  "add_bk_img_is_shifted": [],
};
export const DEFAULT_SAFE_ZONE_FIELD_PROPERTIES = {
  _fill_color: "#000000",
  _height: "200",
  _offset_x: "0",
  _offset_y: "0",
  _opacity: "0.2",
  _order: "99999",
  _stroke_color: "#000",
  _thickness: "0",
  _width: "200",
}

export const DEFAULT_ICONS_FIELD_PROPERTIES = {
  top: 0,
  left: 0,
  height: 100,
  width: 100,
  order: 1000,
}

export const DEFAULT_LINE_FIELD_PROPERTIES = {
  _stroke_color: "#000000",
  _thickness: 5,
  _offset_x: 0,
  _offset_y: 0,
  _opacity: 1,
  _order: 99999,
  _coords: [0, 0, 500, 0],
}

function getFieldName(field_type) {
  return "add_" + field_type.toLowerCase().replace(/ /g, '_') + "_" + Date.now();
}

function moveRulerGuideLinesToFront(canvas) {
  canvas.getObjects()
    .filter(
      obj => [HORIZONTAL_GUIDE_LINE_PREFIX_ID, VERTICAL_GUIDE_LINE_PREFIX_ID]
        .some(id => obj.id.startsWith(id))
    )
    .forEach(obj => obj.bringToFront());
}

function orderHandler(currentRenderedObject, options = {}, params = {}) {
  const { hasUsersOrder = false, bringToFront = false } = params;
  let currentOrder = options.currentPreview.data?.users_fields_order ? JSON.parse(options.currentPreview.data.users_fields_order) : false;
  if (hasUsersOrder && Array.isArray(currentOrder)) {
    if (bringToFront) {
      currentOrder = currentOrder.filter(id => id !== currentRenderedObject.id);
      currentOrder.unshift(currentRenderedObject.id); 
      updateLayoutChanges(options.currentInstanceId, 'users_fields_order', JSON.stringify(currentOrder));
    } else {
      if (options.originalId) {
        const originalIndex = currentOrder.indexOf(options.originalId)
        currentOrder.splice(originalIndex, 0, currentRenderedObject.id)
        updateLayoutChanges(options.currentInstanceId, 'users_fields_order', JSON.stringify(currentOrder));
      } else if (!currentOrder.includes(currentRenderedObject.id)) {
        currentOrder.unshift(currentRenderedObject.id);
        updateLayoutChanges(options.currentInstanceId, 'users_fields_order', JSON.stringify(currentOrder));
      }
    }
  }
}

export async function addAdditionalText(field_type, options = {}, params = {}) {
  const fieldName = getFieldName(field_type);
  const newData = {};
  newData[fieldName] = options.text || 'text';

  for (let key of Object.keys(DEFAULT_TEXT_FIELD_PROPERTIES)) {
    newData[`${fieldName + key}`] = options[key] || DEFAULT_TEXT_FIELD_PROPERTIES[key];
  }

  canvasData[options.currentInstanceId].additionalTexts.push(fieldName);

  for (let key of Object.keys(newData)) {
    updateLayoutChanges(options.currentInstanceId, key, newData[key]);
  }

  if (options?.link) {
    const settingTemplate = getSettingsDataInstance(options.currentPreview?.data?.settings_template);
    const fieldIndex = canvasData[options.currentInstanceId].additionalTexts.indexOf(fieldName);

    settingTemplate.addField({id: `${ADDITIONAL_TEXT_PREFIX_ID}${fieldIndex}`, link: options.link});
    updateLayoutChanges(options.currentInstanceId, 'settings_template', settingTemplate.toJSON());
  }

  const currentRenderedObject = options.currentPreview.canvas.getObjects().find(o => o.id === ADDITIONAL_TEXT_PREFIX_ID + (canvasData[options.currentInstanceId].additionalTexts.length - 1));
  orderHandler(currentRenderedObject, options, params);

  await drawFieldsFunctions.drawAdditionalText({canvas: options.currentPreview.canvas, id: options.currentInstanceId});
  await reorderObjects(options.currentPreview.canvas);
  moveRulerGuideLinesToFront(options.currentPreview.canvas)
}

async function addMultiLineText(field_type, options = {}, params = {}) {
  const fieldName = getFieldName(field_type);
  const newData = {};
  newData[fieldName] = options.text || 'Add-multi-line-text';
  
  //set default font by template params
  if (!options['_font']) {
    let defaultFont = 'Proxima-Nova-Semibold';
    let textFont = '';
    
    if (options.currentPreview.template && options.currentPreview.template?.fields && Array.isArray(options.currentPreview.template.fields)) {
      const fields = options.currentPreview.template.fields;
      let extraFont = '';
      for(let i = 0; i < fields.length; i++) {
        let opts = JSON.parse(fields[i].options);
        if (fields[i].element_id.includes('_text_') && opts?.Font) {
          textFont = opts.Font;
          break;
        }

        if (opts?.Font) {
          extraFont = opts.Font;
          break;
        }
      }

      if (!textFont) textFont = extraFont;
    }
    
    options['_font'] = textFont || defaultFont;
  }
  

  for (let key of Object.keys(DEFAULT_MULTI_LINE_TEXT_FIELD_PROPERTIES)) {
    newData[`${fieldName + key}`] = options[key] || DEFAULT_MULTI_LINE_TEXT_FIELD_PROPERTIES[key];
  }

  canvasData[options.currentInstanceId].additionalMultiLineTexts.push(fieldName);

  for (let key of Object.keys(newData)) {
    updateLayoutChanges(options.currentInstanceId, key, newData[key]);
  }

  await drawFieldsFunctions.drawAdditionalMultiLineText({canvas: options.currentPreview.canvas, id: options.currentInstanceId});
  const currentRenderedObject = options.currentPreview.canvas.getObjects().find(o => o.id === ADDITIONAL_MULTI_LINE_TEXT_FIELD_PREFIX_ID + (canvasData[options.currentInstanceId].additionalMultiLineTexts.length - 1));
  orderHandler(currentRenderedObject, options, params);
  await reorderObjects(options.currentPreview.canvas);
  moveRulerGuideLinesToFront(options.currentPreview.canvas)
}

async function addRectangle(field_type, options = {}, params = {}) {
  const fieldName = getFieldName(field_type);
  const newData = {};
  newData[fieldName] = '#000';
  for (let key of Object.keys(DEFAULT_RECTANGLE_FIELD_PROPERTIES)) {
    newData[`${fieldName + key}`] = options[key] || DEFAULT_RECTANGLE_FIELD_PROPERTIES[key];
  }
  canvasData[options.currentInstanceId].additionalRectangles.push(fieldName);
  for (let key of Object.keys(newData)) {
    updateLayoutChanges(options.currentInstanceId, key, newData[key]);
  }
  await drawFieldsFunctions.drawAdditionalRectangle({canvas: options.currentPreview.canvas, id: options.currentInstanceId});
  const currentRenderedObject = options.currentPreview.canvas.getObjects().find(o => o.id === ADDITIONAL_RECTANGLE_PREFIX_ID + (canvasData[options.currentInstanceId].additionalRectangles.length - 1));
  orderHandler(currentRenderedObject, options, params);
  await reorderObjects(options.currentPreview.canvas);
  moveRulerGuideLinesToFront(options.currentPreview.canvas)
}

async function addCircle(field_type, options = {}, params = {}) {
  const fieldName = getFieldName(field_type);
  const newData = {};
  newData[fieldName] = '#000';
  for (let key of Object.keys(DEFAULT_CIRCLE_FIELD_PROPERTIES)) {
    newData[`${fieldName + key}`] = options[key] || DEFAULT_CIRCLE_FIELD_PROPERTIES[key];
  }
  canvasData[options.currentInstanceId].additionalCircles.push(fieldName);
  for (let key of Object.keys(newData)) {
    updateLayoutChanges(options.currentInstanceId, key, newData[key]);
  }
  await drawFieldsFunctions.drawAdditionalCircle({canvas: options.currentPreview.canvas, id: options.currentInstanceId});
  const currentRenderedObject = options.currentPreview.canvas.getObjects().find(o => o.id === ADDITIONAL_CIRCLE_PREFIX_ID + (canvasData[options.currentInstanceId].additionalCircles.length - 1));
  orderHandler(currentRenderedObject, options, params);
  await reorderObjects(options.currentPreview.canvas);
  moveRulerGuideLinesToFront(options.currentPreview.canvas)
}

export async function addBackgroundThemeImage(options = {}, params = {}) {
  const newData = {};
  let properties = {
    ...DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES,
    add_bk_img_order: order,
  };
  
  if (Object.hasOwn(options, 'additional_background_images')) {
    properties = {
      ...properties,
      additional_background_images: removeUrlOriginLocation(options.additional_background_images),
      add_bk_img_offset_x: options.add_bk_img_offset_x ?? DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES.add_bk_img_offset_x,
      add_bk_img_offset_y: options.add_bk_img_offset_y ?? DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES.add_bk_img_offset_y,
      add_bk_img_scale:
        isNaN(options.scaleX) && isNaN(options.scaleY)
          ? DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES.add_bk_img_scale
          : Math.max(options.scaleX || 0, options.scaleY || 0) || DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES.add_bk_img_scale,
      add_bk_img_scale_y: options.scaleY ?? DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES.add_bk_img_scale_y,
      add_bk_img_height: options.height ?? DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES.add_bk_img_height,
      add_bk_img_width: options.width ?? DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES.add_bk_img_width,
      add_bk_img_is_paste_object: !!params.pasteObject,
      add_bk_img_is_another_instance: !!params.pasteObject ? options.currentInstanceId !== options.prevInstanceId : !!params.pasteObject,
      add_bk_img_is_shifted: !!params.pasteObject,
    }
  }
  
  for (let key of Object.keys(properties)) {
    newData[key] = Object.hasOwn(canvasData[options.currentInstanceId]._data, key)
      ? [...canvasData[options.currentInstanceId]._data[key], properties[key]]
      : [properties[key]];
  }
  
  for (let key of Object.keys(newData)) {
    updateLayoutChanges(options.currentInstanceId, key, newData[key]);
  }
  
  await drawFieldsFunctions.drawPreviews(getPreview());
  await drawFieldsFunctions.setAdditionalBackgroundImage({
    data: canvasData[options.currentInstanceId],
    canvas: options.currentPreview.canvas,
    id: options.currentInstanceId
  });
  const currentRenderedObject = options.currentPreview.canvas.getObjects().find(o => o.id === ADDITIONAL_BACKGROUND_THEME_IMAGE_PREFIX_ID + (canvasData[options.currentInstanceId].additional_background_theme_image.length - 1));
  orderHandler(currentRenderedObject, options, params);
  await reorderObjects(options.currentPreview.canvas);
  options.currentPreview.canvas.renderAll();
  moveRulerGuideLinesToFront(options.currentPreview.canvas)
}

async function addSafeZone(fieldType, options = {}, params = {}) {
  const fieldName = getFieldName(fieldType);
  const newData = {};
  newData[fieldName] = '#000';
  for (let key of Object.keys(DEFAULT_SAFE_ZONE_FIELD_PROPERTIES)) {
    newData[`${fieldName + key}`] = options[key] || DEFAULT_SAFE_ZONE_FIELD_PROPERTIES[key];
  }
  canvasData[options.currentInstanceId].additionalSafeZones.push(fieldName);
  for (let key of Object.keys(newData)) {
    updateLayoutChanges(options.currentInstanceId, key, newData[key]);
  }
  await drawFieldsFunctions.drawAdditionalSafeZone({canvas: options.currentPreview.canvas, id: options.currentInstanceId});
  const currentRenderedObject = options.currentPreview.canvas.getObjects().find(o => o.id === ADDITIONAL_SAFE_ZONE_PREFIX_ID + (canvasData[options.currentInstanceId].additionalSafeZones.length - 1));
  orderHandler(currentRenderedObject, options, params);
  await reorderObjects(options.currentPreview.canvas);
  moveRulerGuideLinesToFront(options.currentPreview.canvas)
}
 
let handleSaveIcons = null;

async function addIcons(field_type, options = {}, params = {}) {
  iconsSettingsEventBroker.fire(EVENTS.INIT, {
    isShowPage: true,
    data: {
      urls: ['']
    }
  });
  
  if (handleSaveIcons) {
    iconsSettingsEventBroker.off(EVENTS.SAVE, handleSaveIcons);
  }

  handleSaveIcons = async ({ urls, top, left, width, height, placementOrientation, placementOrder }) => {
    if (!urls.length) return;
    
    const canvas = options.currentPreview.canvas;
      
    const additionalIcons = Object.hasOwn(canvasData[options.currentInstanceId]._data, 'add_icons')
      ? [...canvasData[options.currentInstanceId]._data['add_icons']]
      : [];

    const icons = {
      id: uuid(),
      elements: urls.map(url => ({
        ...DEFAULT_ICONS_FIELD_PROPERTIES, 
        top,
        left,
        width,
        height,
        url
      })),
      placementOrientation,
      placementOrder
    }

    additionalIcons.push(icons);
    
    updateLayoutChanges(options.currentInstanceId, 'add_icons', additionalIcons);
    
    await drawFieldsFunctions.drawAdditionalIcons({
      canvas,  
      data: canvasData[options.currentInstanceId]._data 
    });
    
    await reorderObjects(options.currentPreview.canvas);
    
    canvas.renderAll();
    
    moveRulerGuideLinesToFront(options.currentPreview.canvas);
  }
  
  iconsSettingsEventBroker.on(EVENTS.SAVE, handleSaveIcons);
}

async function addLine(field_type, options = {}, params = {}) {
  const fieldName = getFieldName(field_type);
  const newData = {};
  
  newData[fieldName] = '#000';
  for (let key of Object.keys(DEFAULT_LINE_FIELD_PROPERTIES)) {
    newData[`${fieldName + key}`] = options[key] || DEFAULT_LINE_FIELD_PROPERTIES[key];
  }
  
  canvasData[options.currentInstanceId].additionalLines.push(fieldName);

  for (let key of Object.keys(newData)) {
    updateLayoutChanges(options.currentInstanceId, key, newData[key]);
  }

  await drawFieldsFunctions.drawAdditionalLine({
    canvas: options.currentPreview.canvas,
    id: options.currentInstanceId
  });

  const currentRenderedObject = options.currentPreview.canvas
    .getObjects()
    .find(o =>
      o.id === `${ADDITIONAL_LINE_PREFIX_ID}${(canvasData[options.currentInstanceId].additionalLines.length - 1)}`
    );

  orderHandler(currentRenderedObject, options, params);
  await reorderObjects(options.currentPreview.canvas);
  moveRulerGuideLinesToFront(options.currentPreview.canvas);
}

let handleCreateAdditionalTableField = null;

async function addTable(field_type, options) {
  const canvas = options.currentPreview.canvas;
  
  const table = new BuilderTable.Table();

  table.styles.setRules({
    'font-family': 'Avenir-Black',
    'font-size': '25px',
  });
  
  table.fieldOptions = { X: 0, Y: 0 };
  
  simpleTableBuilderEventBroker.fire(Table_EVENTS.INIT, { table, isAdditionalTableField: true });
  
  if (handleCreateAdditionalTableField) {
    simpleTableBuilderEventBroker.off(Table_EVENTS.ADD_ADDITIONAL_TABLE_FIELD, handleCreateAdditionalTableField);
  }

  handleCreateAdditionalTableField = async ({ tableInstance }) => {
    let additionalTables = Object.hasOwn(canvasData[options.currentInstanceId]._data, 'add_html_tables')
      ? [...canvasData[options.currentInstanceId]._data['add_html_tables']]
      : [];
    
    additionalTables.push(JSON.stringify(tableInstance));
    
    updateLayoutChanges(options.currentInstanceId, 'add_html_tables', additionalTables);

    await drawFieldsFunctions.drawAdditionalHtmlTable({
      canvas,
      data: canvasData[options.currentInstanceId]._data
    });

    await reorderObjects(options.currentPreview.canvas);
    
    moveRulerGuideLinesToFront(options.currentPreview.canvas);
  }
  
  simpleTableBuilderEventBroker.on(Table_EVENTS.ADD_ADDITIONAL_TABLE_FIELD, handleCreateAdditionalTableField);
}

export async function addAddField(type, instanceId, options = {}, params = {}) {

  options.currentInstanceId = instanceId;
  options.currentPreview = getPreview().find(preview => preview.id === options.currentInstanceId);

  if (!type || !options.currentInstanceId || !options.currentPreview) return;
  let currentOrder = [];

  if (!options.currentPreview.data?.users_fields_order) {
    currentOrder = options.currentPreview.canvas.getObjects()
      .filter(
        object => object.id && 
          !NON_USER_LAYER_IDS.includes(object.id) && 
          !NON_USER_LAYER_IDS.some(id => object.id.startsWith(id))
      )
      .sort((prev, curr) => prev.order - curr.order)
      .map(object => object.fieldId || object.id);
    updateLayoutChanges(options.currentInstanceId, 'users_fields_order', JSON.stringify(currentOrder));
  }
  const fields = options.currentPreview.template.fields;
  const additionalFields = options.currentPreview.canvas
    .getObjects()
    .filter(obj => obj.id.startsWith(ADDITIONAL_BACKGROUND_THEME_IMAGE_PREFIX_ID));

  order = getOrderForAddField([
    ...fields,
    ...additionalFields
  ]);

  switch (type) {
    case 'Text':
      await addAdditionalText(type, options, { ...params });
      break;
    case 'Multi Line Text':
      await addMultiLineText(type, options, { ...params, hasUsersOrder: true });
      break;
    case 'Rectangle':
      await addRectangle(type, options, { ...params, hasUsersOrder: true });
      break;
    case 'Circle':
      await addCircle(type, options, { ...params, hasUsersOrder: true });
      break;
    case 'Background Theme Image':
      await addBackgroundThemeImage(options, params);
      break;
    case 'Safe Zone':
      await addSafeZone(type, options, params)
      break;
    case 'Icons':
      await addIcons(type, options, params);
      break;
    case 'Line':
      await addLine(type, options, { ...params, hasUsersOrder: true });
      break;
    case 'Table':
      await addTable(type, options, params);
      break;
  }

  const isAdditionalFieldsExists = options.currentPreview.template.fields.find(({ name }) => name === FIELD_TYPE_ADDITIONAL_FIELDS)

  if (!isAdditionalFieldsExists) {
    const additionalField = (await TemplateService.createAdditionalFieldRow(options.currentPreview.template.id)).data
    options.currentPreview.template.fields.push(additionalField)
    const { drawPreviews, previews } = getShowPageScope()
    drawPreviews(previews)
  }
}

async function onAddField() {
  const type = $(this).attr('value');
  const instanceId = $(this).parent().data('id');

  // Setting the keepSelected variable to false allows the text fields to be unselected when an additional field is added
  canvasEditModeControl.setKeepSelected(false);

  await addAddField(type, instanceId);

  canvasEditModeControl.setKeepSelected(true);
}

export function initAdditionalFields(_canvas_data, _previews, _drawFieldsFunctions, _updateLayoutChanges, _reorderObjects) {
  canvasData = _canvas_data;
  previews = _previews;
  reorderObjects = _reorderObjects;
  drawFieldsFunctions = _drawFieldsFunctions;
  updateLayoutChanges = _updateLayoutChanges;
  $('.additional-fields-dropdown-list li').on('click', onAddField);
}
