<template>
  <div>
    <div
      v-if="isPreviewsLoading"
      class="text-center mt-5"
    >
      <b-spinner variant="primary" />
      <p class="mt-2">
        Generating Previews...
      </p>
    </div>

    <div v-if="previews.length">
      <div class="card mt-3">
        <div class="card-body d-flex justify-content-between">
          <div class="align-items-center">
            <h3 class="card-title">
              Name: {{ creative.name }}
            </h3>
            <h4 class="card-subtitle mb-2 text-muted">
              Status: <span :class="walmart.getStatusBadge(creative.status)">{{ creative.status }}</span>
            </h4>
          </div>
          <div class="card-text">
            <button
              class="btn btn-primary btn-sm mx-2"
              :disabled="creative?.isLoading"
              @click="submitCreative(creative)"
            >
              <span
                v-if="creative?.isLoading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              Submit
            </button>
            <!-- todo add delete -->
            <!-- <button
              class="btn btn-danger btn-sm mx-2"
              :disabled="creative?.isLoading"
            >
              <span
                v-if="creative?.isLoading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              <i class="fas fa-trash-alt" />
            </button> -->
          </div>
        </div>
      </div>
      <div
        v-for="(preview, index) in previews"
        :key="index"
        class="mt-3 mb-2 border rounded p-3"
      >
        <h3>{{ preview.name }}</h3>
        <img
          :src="preview.url"
          alt="preview"
          class="img-fluid"
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as walmart from '@frontend/components/helpers/walmart-helpers.js';

export default {
  name: 'WalmartShowPreview',
  data() {
    return {
      walmart,
      form: {
        creative_id: null,
        platform_id: null,
        company_id: null,
      },
      previews: [],
      creative: {
        name: '',
        status: '',
        creativeId: '',
      },
      errors: {},
      isPreviewsLoading: true
    }
  },
  async mounted() {
    await this.getParams();
    await this.getCreativeData();
    await this.fetchPreviews();
  },
  methods: {
    async getParams() {
      const urlParams = new URLSearchParams(window.location.search);
      this.form.creative_id = urlParams.get('creative_id');
      this.form.platform_id = urlParams.get('platform_id');
      this.form.company_id = urlParams.get('company_id');
    },

    async fetchPreviews() {
      try {
        const { data } = await axios.post('/walmart/fetch-previews', this.form);

        if (data.status === 'error') {
          toastr.options = {
            timeOut: 0,
            extendedTimeOut: 0
          };
          toastr.error(data.message);
          return;
        }

        if (data.status === 'failed') {
          toastr.options = {
            timeOut: 0,
            extendedTimeOut: 0
          };

          toastr.error(data.message);

          return;
        }

        data.forEach((preview) => {
          let newPreview = {};

          newPreview.name = preview.name;

          newPreview.url = this.generateUrl(preview.image);

          this.previews.push(newPreview);
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isPreviewsLoading = false;
      }
    },

    async getCreativeData() {
      this.form.is_specific_creative = true;

      const { data } = await axios.post('/walmart/get-creatives', this.form);

      if (data?.result?.original?.status === 'error') {
        console.log('error', data.result.original.message);
      }

      if (data?.status === 'error') {
        console.log('error', data.message);
      }

      this.creative.name = data.data.response[0].name;
      this.creative.status = data.data.response[0].status;
      this.creative.creativeId = data.data.response[0].creativeId;
    },

    generateUrl(imageBase64) {
      const byteCharacters = atob(imageBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });

      const previewUrl = URL.createObjectURL(blob);

      return previewUrl;
    },

    async submitCreative(creative) {
      await walmart.submitCreative.call(this, creative);

      await this.getCreativeData();
    }
  },
}
</script>