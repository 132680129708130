var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-item-overlay" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.viewport.noninteractive",
            modifiers: { hover: true, viewport: true, noninteractive: true },
          },
        ],
        staticClass: "c-item-overlay__item delete-item",
        attrs: { title: "Replace" },
        on: { click: _vm.replaceItem },
      },
      [
        _c("i", {
          staticClass: "fas fa-redo-alt",
          staticStyle: { color: "#0d6854" },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.viewport.noninteractive",
            modifiers: { hover: true, viewport: true, noninteractive: true },
          },
        ],
        staticClass: "c-item-overlay__item",
        attrs: { title: "Delete" },
        on: { click: _vm.deleteItem },
      },
      [
        _c("i", {
          staticClass: "fas fa-trash",
          staticStyle: { color: "#0d6854" },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.viewport.noninteractive",
            value: "Download",
            expression: "'Download'",
            modifiers: { hover: true, viewport: true, noninteractive: true },
          },
        ],
        staticClass: "c-item-overlay__item",
        attrs: { href: _vm.item.full_url, download: "" },
      },
      [_c("b-icon", { attrs: { icon: "download" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }