<template>
  <div>
    <!-- Prepend -->
    <ExportAddPagesConfig
      title="Prepend PDF"
      :config="prepend"
      @data-updated="prepend = $event"
    />

    <!-- Insert -->
    <ExportInsertPagesConfig
      :config="insert"
      :group-instances="groupInstances"
      @data-updated="insert = $event"
    />

    <!-- Append -->
    <ExportAddPagesConfig
      title="Append PDF"
      :config="append"
      @data-updated="append = $event"
    />
  </div>
</template>

<script>
import ExportAddPagesConfig from '@frontend/components/modules/layouts-page/ExportAddPagesConfig.vue';
import ExportInsertPagesConfig from '@frontend/components/modules/layouts-page/ExportInsertPagesConfig.vue';

export default {
  name: "ExportAddPagesTab",
  components: {
    ExportAddPagesConfig,
    ExportInsertPagesConfig,
  },
  props: {
    addPages: {
      type: Object,
      required: true,
      default: () => ({
        prepend: {isEnabled: false, pdf: null},
        append: {isEnabled: false, pdf: null},
        insert: {isEnabled: false, insertions: []},
      }),
    },
    groupInstances: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      prepend: null,
      append: null,
      insert: null,
      resultData: {
        prepend: null,
        append: null,
        insert: null,
      },
    };
  },
  watch: {
    addPages: {
      handler(newVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(this.resultData)) {
          Object.assign(this, newVal);
          this.updateResultData();
        }
      },
      deep: true,
      immediate: true,
    },
    append: function() {
      this.updateResultData();
    },
    prepend: function() {
      this.updateResultData();
    },
    insert: function() {
      this.updateResultData();
    },
    resultData: function(newResultData) {
      this.$emit('data-updated', newResultData);
    },
  },
  methods: {
    updateResultData() {
      this.resultData = {
        prepend: this.prepend,
        append: this.append,
        insert: this.insert,
      };
    }
  },
}
</script>