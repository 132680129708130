import { adUnits } from "@frontend/constants/walmart-constants.js";

const initState = () => ({
    assets: [],
});

export const MUTATIONS = {
    SET_ASSET: "SET_ASSET",
    REMOVE_ASSET: "REMOVE_ASSET",
    RESET_STATE: "RESET_STATE",
};

export const ACTIONS = {
    SAVE_ASSET: "saveAsset",
    REMOVE_ASSET: "removeAsset",
    RESET_STATE: "resetState",
};

export default {
    namespaced: true,
    state: initState(),
    getters: {
        getAssets: (state) => state.assets,
        getAssetByType: (state) => (type, imageType) =>
            state.assets.find(
                (asset) => asset.type === type && asset.imageType === imageType
            ),
        checkGalleryDesktopAssets(state) {
            const assets = state.assets;
            const hasImage = assets.some(
                (asset) => asset.type === adUnits.GALLERY_DESKTOP && asset.imageType === 'image'
            );
            const hasLogo = assets.some(
                (asset) => asset.type === adUnits.GALLERY_DESKTOP && asset.imageType === 'logo'
            );

            return hasImage && hasLogo;
        },
    },
    mutations: {
        [MUTATIONS.SET_ASSET](state, payload) {
            console.log("SET_ASSET", payload);

            state.assets = state.assets.filter(
                (item) => !(item.type === payload.type && item.imageType === payload.imageType)
            );
            state.assets.push(payload);
        },
        [MUTATIONS.REMOVE_ASSET](state, payload) {
            console.log("REMOVE_ASSET", payload);

            state.assets = state.assets.filter(
                (item) => !(item.type === payload.type && item.imageType === payload.imageType)
            );
        },
        [MUTATIONS.RESET_STATE](state) {
            Object.assign(state, initState());
        },
    },
    actions: {
        [ACTIONS.SAVE_ASSET]({ commit }, payload) {
            commit(MUTATIONS.SET_ASSET, payload);
        },
        [ACTIONS.REMOVE_ASSET]({ commit }, payload) {
            commit(MUTATIONS.REMOVE_ASSET, payload);
        },
        [ACTIONS.RESET_STATE]({ commit }) {
            commit(MUTATIONS.RESET_STATE);
        },
        testAction({ commit }, payload) {
            console.log(payload);
        },
    },
};
