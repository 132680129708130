<template>
  <tr class="import-mappings-data-row">
    <td class="import-mappings-data-row__name">
      {{ name }}
    </td>
    <td class="import-mappings-data-row__details">
      <div class="import-mappings-data-row__content">
        <div
          v-if="isSameEnvironment"
          class="import-mappings-data-row__templates"
        >
          <span
            v-for="template in templates"
            :key="`template_${template.id}`"
            class="import-mappings-data-row__template-name"
          >
            {{ template.template?.name }}
          </span>
        </div>

        <div
          v-else
          class="import-mappings-data-row__multiselects"
        >
          <div
            v-for="templateMapping in templateMappings"
            :key="templateMapping.id"
            class="import-mappings-data-row__template-mapping"
          >
            <div class="import-mappings-data-row__template-original">
              {{ templateMapping.originalTemplateName }}
            </div>
            <div class="import-mappings-data-row__template-select">
              <MultiSelect
                v-model="templateMapping.selectedCustomer"
                placeholder="Customer"
                label="name"
                track-by="id"
                :options="customers"
              />
              <MultiSelect
                v-model="templateMapping.newTemplate"
                placeholder="Select template"
                label="name"
                track-by="id"
                :options="templateMapping.selectedCustomer?.templates ?? []"
                style="max-width: 300px"
              />
            </div>
          </div>
        </div>
      </div>
    </td>

    <td class="import-mappings-data-row__checkbox">
      <div class="d-flex align-items-center justify-content-center">
        <input
          type="checkbox"
          :checked="selected"
          @change="handleSelect"
        >
      </div>
    </td>
  </tr>
</template>

<script>
import MultiSelect from "vue-multiselect";

export default {
  name: 'ImportMappingsDataRow',
  components: { MultiSelect },
  props: {
    id: { type: Number, required: true },
    name: { type: String, required: true },
    isSameEnvironment: { type: Boolean, required: true },
    templates: { type: Array, required: true },
    customers: { type: Array, required: true },
    selected: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      selectedTemplates: [],
      templateMappings: []
    };
  },
  watch: {
    templateMappings: {
      handler(newTemplates) {
        newTemplates.forEach((template) => {
          this.$emit('customerChange', template.selectedCustomer?.id);
        });

        const mappings = newTemplates.map(t => ({
          oldTemplateId: t.originalTemplateId,
          newTemplateId: t.newTemplate?.id || null
        }));

        this.$emit('templateMappingsChange', {
          mappingId: this.id,
          templateMappings: mappings
        });
      },
      deep: true
    }
  },
  mounted() {
    this.resetTemplateMappings();
  },
  methods: {
    handleSelect(event) {
      this.$emit('select', { id: this.id, selected: event.target.checked });
    },
    resetTemplateMappings() {
      this.templateMappings = this.templates.map(t => ({
        id: t.id,
        originalTemplateId: t.template.id,
        originalTemplateName: t.template.name,
        selectedCustomer: null,
        newTemplate: null
      }));
    },
  }
};
</script>

<style lang="scss" scoped>
$import-mappings-table-border-color: #c4c4c4;

.import-mappings-data-row {
  border-top: 1px solid $import-mappings-table-border-color;
  border-bottom: 1px solid $import-mappings-table-border-color;

  &__name {
    font-weight: bold;
    max-width: 300px;
    border-right: 1px solid $import-mappings-table-border-color;
  }

  &__multiselects {
    display: flex;
    flex-direction: column;
  }

  &__template-mapping {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #ccc;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.import-mappings-data-row__details {
  border-right: 1px solid $import-mappings-table-border-color;
  padding: 8px;
}

.import-mappings-data-row__template-mapping {
  display: flex;
  align-items: center;
}

.import-mappings-data-row__template-original {
  flex: 1;
  font-size: 12px;
}

.import-mappings-data-row__template-select {
  flex: 2;
  display: flex;
  align-items: center;
  gap: 8px;
}

</style>
