var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "compression-settings-modal",
        title: "Compression Settings",
      },
      on: {
        ok: _vm.handleOk,
        cancel: _vm.handleCancel,
        show: _vm.fetchCompressSettings,
      },
    },
    [
      _vm.isCompressSettingsFetching
        ? _c(
            "div",
            {
              staticClass: "justify-content-center align-items-center loading",
              staticStyle: {
                position: "absolute",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
                background: "#ffffffb0",
                "z-index": "999",
                display: "flex",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "spinner-border text-primary",
                  attrs: { role: "status" },
                },
                [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form",
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    size: "lg",
                    label: "Quality:",
                    "label-for": "quality-slider",
                    "label-size": "lg",
                    "label-cols": "2",
                  },
                },
                [
                  _c("b-form-input", {
                    staticClass: "w-100",
                    attrs: {
                      id: "quality-slider",
                      type: "range",
                      min: 10,
                      max: _vm.fileSizeKB,
                      step: 1,
                      tooltip: "always",
                    },
                    on: { change: _vm.onFileSizeChange },
                    model: {
                      value: _vm.fileSize,
                      callback: function ($$v) {
                        _vm.fileSize = $$v
                      },
                      expression: "fileSize",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between px-1" },
                    [
                      _c("small", [_vm._v("Least")]),
                      _vm._v(" "),
                      _c("small", [_vm._v("Best")]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { "font-size": "1.25rem", "line-height": "1.5" },
                },
                [
                  _vm._v(
                    "\n        File Size: " +
                      _vm._s(_vm.fileSize) +
                      " KB\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-checkbox",
            {
              attrs: { value: true, "unchecked-value": false },
              model: {
                value: _vm.isCompressSettingsReplaceOriginalImage,
                callback: function ($$v) {
                  _vm.isCompressSettingsReplaceOriginalImage = $$v
                },
                expression: "isCompressSettingsReplaceOriginalImage",
              },
            },
            [_vm._v("\n      Replace original image\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }