<template>
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title">
        <div
          v-if="files.length > 1"
          class="files-container"
        >
          <div
            v-for="(file, index) in files"
            :key="file.filename"
            class="files-container__file position-relative"
            :class="{ 'files-container__file--active': currentPreviewIndex === index }"
            @click="setCurrentPreviewIndex(index)"
          >
            <img
              v-if="file.entireImageThumbnail"
              class="files-container__file-thumbnail"
              :src="file.entireImageThumbnail | fileThumbnail"
              alt="preview"
            >
            <span class="files-container__file-name">
              {{ truncateFieldName(file.originalFileName) }}
            </span>
            <i
              v-if="file.isLoading"
              class="fas fa-spinner fa-spin files-container__file-loader"
            />
            <PSDPreviewBadge
              :right="5"
              :top="7.5"
              :title="getBadgeTitle(file.status)"
              :variant="getBadgeVariant(file.status)"
            />
          </div>
        </div>
        <div class="relative">
          <h5>
            Open PSD: <span> {{ originalFileName }} </span>
            <span
              v-if="templateNameAvailable"
              class="d-block"
            >
              Template: {{ template.name }}
            </span>
          </h5>
          <PSDPreviewBadge
            v-if="files.length === 1"
            :right="30"
            :top="4"
            :title="getBadgeTitle(files[currentPreviewIndex].status)"
            :variant="getBadgeVariant(files[currentPreviewIndex].status)"
          />
        </div>
      </div>
      <button
        id="psd-layers-cancel-modal-button"
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <component
      :is="currentStep"
      :show-save-images-to-theme="showSaveImagesToThemeTyped"
    />
    <ICCProfileSelector />
  </div>
</template>

<script>
import LayerSelector from "./LayerSelector";
import {EVENTS, psdLayersEventBroker} from "../../../group/modules/psd-layers/event-broker";
import InitialLoader from "./InitialLoader";
import SpinnerLoader from "./SpinnerLoader.vue";
import { createNamespacedHelpers } from 'vuex'
import {MUTATIONS} from "../../../store/psd-layers";
import {PSD_PREVIEW_LOADERS_TYPES} from "@frontend/components/modules/psd-layers/constants/psd-preview";
import psdPreviewMixin from "./layer-views/mixin";
import PSDPreviewBadge from "@frontend/components/common/atoms/PSDPreviewBadge.vue";
import {PSD_STATUS} from "@frontend/constants/psd-status";
import ICCProfileSelector from "@frontend/components/modules/psd-layers/modal/ICCProfileSelector.vue";

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('psdLayers')

const PSD_PREVIEW_LOADERS = {
  [PSD_PREVIEW_LOADERS_TYPES.PROGRESS]: InitialLoader,
  [PSD_PREVIEW_LOADERS_TYPES.SPINNER]: SpinnerLoader,
}

export default {
  name: "PSDPreview",
  components: {ICCProfileSelector, PSDPreviewBadge, LayerSelector},
  filters: {
    fileThumbnail(url) {
      const prefix = `${location.origin}/share?file=`;

      if (url.includes(prefix)) {
        return url;
      }

      return `${prefix}${url}&lightweight&scale=30`;
    }
  },
  mixins: [psdPreviewMixin],
  props: {
    showSaveImagesToTheme: {
      type: String,
      default: () => 'false'
    },
  },
  data: () => ({
    loader: PSD_PREVIEW_LOADERS_TYPES.PROGRESS,
  }),

  computed: {
    ...mapGetters([
      'currentPreviewIndex',
      'files',
      'selectedTemplates',
      'templates',
      'isLoading',
      'isQueued',
      'originalFileName',
    ]),
    showSaveImagesToThemeTyped() {
      return this.showSaveImagesToTheme === '1';
    },
    templateNameAvailable() {
      return this.templates.length > 1 && this.template;
    },
    currentStep() {
      return (this.isLoading || this.isQueued || this.isError) ? PSD_PREVIEW_LOADERS[this.loader] : LayerSelector;
    },
  },
  mounted() {
    psdLayersEventBroker.on(EVENTS.INIT, this.init.bind(this), true);
  },
  methods: {
    ...mapMutations({
      setTemplates: MUTATIONS.SET_TEMPLATES,
      reset: MUTATIONS.RESET,
      setThemeFields: MUTATIONS.SET_THEME_FIELDS,
      setFlow: MUTATIONS.SET_FLOW,
      setMultipleTemplateSelectionType: MUTATIONS.SET_MULTIPLE_TEMPLATE_SELECTION_TYPE,
      setFieldsToMap: MUTATIONS.SET_FIELDS_TO_MAP,
      setCurrentPreviewIndex: MUTATIONS.SET_CURRENT_PREVIEW_INDEX,
      setTemplateFeatures: MUTATIONS.SET_TEMPLATE_FEATURES,
      setIsReparse: MUTATIONS.SET_IS_REPARSE,
    }),
    ...mapActions([
      'loadFiles',
      'unsubscribeFileListeners'
    ]),
    init({files, themeFields, flow, isMultipleTemplateSelectionType, fieldsToMap, loaderType = PSD_PREVIEW_LOADERS_TYPES.PROGRESS, isReparse}) {
      this.unsubscribeFileListeners();
      this.reset();
      this.setTemplateFeatures(psdLayersEventBroker.isListen(EVENTS.FILL_TEMPLATE));
      this.setIsReparse(isReparse);
      fieldsToMap?.length && this.setFieldsToMap({ fieldsToMap: fieldsToMap });
      this.loadFiles(files);
      this.setThemeFields(themeFields);
      this.setFlow(flow);
      this.setMultipleTemplateSelectionType(isMultipleTemplateSelectionType)
      this.loader = loaderType;
    },
    getBadgeTitle(status) {
      if (status === null) return 'Loading';
      if (status === PSD_STATUS.QUEUED) return 'Queued';
      if (status === PSD_STATUS.PARSING) return 'Parsing';
      if (status === PSD_STATUS.DONE) return 'Parsed';
      if (status === PSD_STATUS.FAILED) return 'Error';
      return 'Parsing';
    },
    getBadgeVariant(status) {
      if (status === null) return 'primary';
      if (status === PSD_STATUS.QUEUED) return 'warning';
      if (status === PSD_STATUS.PARSING) return 'primary';
      if (status === PSD_STATUS.DONE) return 'success';
      if (status === PSD_STATUS.FAILED) return 'danger';
      return 'primary';
    },
  }
}
</script>

<style lang="scss" scoped>

.files-container {
  display: flex;
  margin-bottom: 8px;
  gap: 8px;
  overflow-x: auto;

  &__file {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 25px 8px 8px;
    border-radius: 4px;
    gap: 8px;

    &--active {
      background-color: #e1e1e1;
    }

    &:hover {
      cursor: pointer;
      background-color: #eeeeee;
    }

    &-thumbnail {
      width: 30px;
      height: 30px;
      object-fit: contain;
    }

    &-name {
      font-size: 14px;
    }

    &-loader {
      font-size: 16px;
      color: #999;
    }
  }
}

</style>
