import { EventBroker } from "@frontend/lib/utils/event-broker";

export const EVENTS = {
    SET_LAYERS: 'SET_LAYERS',
    LAYER_CLICK: 'LAYER_CLICK',
    RESET: 'RESET',
    FILL_TEMPLATE: 'FILL_TEMPLATE',
    UPDATE_PREVIEW_TEMPLATE: 'UPDATE_PREVIEW_TEMPLATE',
    UPDATED_PREVIEW_TEMPLATE: 'UPDATED_PREVIEW_TEMPLATE',
    CLOSE: 'CLOSE',
    CLOSE_AND_RELOAD: 'CLOSE_AND_RELOAD',
    CANCEL: 'CANCEL',
    SAVE_PSD_ASSETS_TO_FOLDER: 'SAVE_PSD_ASSETS_TO_FOLDER',
    SAVE_PSD_ASSETS_TO_FOLDER_END: 'SAVE_PSD_ASSETS_TO_FOLDER_END',
    CREATE_TEMPLATE_ADMIN: 'CREATE_TEMPLATE_ADMIN',
    INIT: 'INIT',
    IMAGE_MADE: 'IMAGE_MADE',
    SAVED_INDIVIDUAL_ASSETS: 'SAVED_INDIVIDUAL_ASSETS',
    PSD_FILE_PARSED: 'PSD_FILE_PARSED',
}

export const psdLayersEventBroker = window.eventBroker = new EventBroker();
