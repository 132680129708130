import toastr from 'toastr';

export const STATUS_MAP = {
    DRAFT: 'DRAFT',
    REJECTED: 'REJECTED',
    APPROVED: 'APPROVED',
    PENDING_APPROVAL: 'PENDING_APPROVAL'
}

export const getStatusBadge = (status) => {
    switch (status) {
        case STATUS_MAP.DRAFT:
            return 'badge badge-secondary';
        case STATUS_MAP.REJECTED:
            return 'badge badge-danger';
        case STATUS_MAP.APPROVED:
            return 'badge badge-success';
        case STATUS_MAP.PENDING_APPROVAL:
            return 'badge badge-warning';
        default:
            return 'badge badge-light';
    }
}

export const statusName = (status) => {
    if (status === STATUS_MAP.PENDING_APPROVAL) {
        return status.replace('_', ' ');
    } else {
        return status;
    }
}

export const submitCreative = async function (creative) {
    this.form.creative_id = creative.creativeId;

    if (!this.form.platform_id) {
        this.errors.platform = 'Please select platform';
    }

    if (!this.form.company_id && this.isMasterAdmin) {
        this.errors.company = 'Please select company';
    }

    if (Object.keys(this.errors).length > 0) {
        toastr.error('Please check errors');
        return false;
    }

    this.$set(creative, 'isLoading', true);

    try {
        const { data } = await axios.post('/admin/auth/walmart/submit-creative', this.form);

        if (data?.result?.original?.status === 'error') {
            toastr.error(data.result.original.message);
            return false;
        }

        if (data?.status === 'error') {
            toastr.error(data.message);
            return false;
        }

        toastr.success('Creative submitted successfully');
        return true;
    } catch (error) {
        toastr.error('An unexpected error occurred. Please try again.');
        return false;
    } finally {
        this.$set(creative, 'isLoading', false);
    }
}
