<template>
  <b-modal
    id="save-as-template-modal"
    title="Save as Template"
    centered
    ok-title="Save"
    :ok-disabled="isSaveDisabled"
    @ok="handleSave"
    @cancel="closeModal"
  >
    <b-form-group>
      <template #label>
        <div class="d-flex justify-content-between align-items-center w-100">
          <span>Name</span>
          <small
            v-if="template?.id"
            class="text-muted"
            style="font-size: 12px; white-space: nowrap;"
          >
            Template ID: {{ template.id }}
          </small>
        </div>
      </template>
      <b-form-input v-model="templateName" />
    </b-form-group>

    <b-form-group label="Customer">
      <Multiselect
        v-model="selectedCustomer"
        track-by="id"
        label="name"
        placeholder="Select Customer"
        :options="customersList"
      />
    </b-form-group>

    <b-form-group label="Tags">
      <Multiselect
        v-model="selectedTags"
        multiple
        taggable
        :options="tags"
        :close-on-select="false"
        @tag="addTag"
      />
    </b-form-group>

    <b-form-group label="Max File Size (KB)">
      <b-form-input
        v-model.number="maxFileSize"
        type="number"
        min="1"
        :placeholder="String(defaultMaxFileSize)"
      />
    </b-form-group>

    <b-form-group label="DPI">
      <b-form-input
        v-model.number="dpi"
        type="number"
        min="72"
        :placeholder="String(defaultDpi)"
      />
    </b-form-group>

    <b-form-group label="File Type">
      <b-form-select
        v-model="fileType"
        :options="fileTypeOptions"
      />
    </b-form-group>

    <div class="form-row">
      <div class="d-flex align-items-center">
        <b-form-checkbox v-model="isUpdateExistingTemplate">
          Update existing template
        </b-form-checkbox>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { saveAsTemplateEventBroker, EVENTS } from '@frontend/group/modules/save-as-template/event-broker';
import Multiselect from 'vue-multiselect';
import axios from 'axios';

export default {
  name: 'SaveAsTemplateModal',
  components: { Multiselect },

  props: {
    customers: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      template: null,
      templateName: '',
      selectedCustomer: null,
      selectedTags: [],
      tags: [],
      isUpdateExistingTemplate: false,
      isTemplateInUse: false,
      maxFileSize: null,
      dpi: null,
      fileType: '',
      isLoading: false,
      defaultMaxFileSize: 50,
      defaultDpi: 300,
      fileTypeOptions: [
        { value: '', text: 'Select' },
        { value: 'jpg', text: 'JPG' },
        { value: 'png', text: 'PNG' },
        { value: 'pdf', text: 'PDF' },
        { value: 'pptx', text: 'PPTX' },
        { value: 'jpg_from_psd', text: 'JPG from PSD' },
        { value: 'png_from_psd', text: 'PNG from PSD' }
      ],
      customersList: this.customers,
    };
  },

  computed: {
    isSaveDisabled() {
      return !this.templateName || this.isLoading;
    }
  },

  watch: {
    isUpdateExistingTemplate(newValue) {
      this.templateName = newValue ? this.template?.name : `${this.template?.name}_copy`;
    }
  },
  
  beforeMount() {
    this.customersList.unshift({ id: 0, name: 'All Customers' });
  },

  mounted() {
    this.registerEventListeners();
  },

  methods: {
    /**
     * Register event listeners for initializing and closing modal
     */
    registerEventListeners() {
      saveAsTemplateEventBroker.on(EVENTS.INIT, this.initializeModal);
      saveAsTemplateEventBroker.on(EVENTS.CLOSE, this.closeModal);
    },

    /**
     * Initialize modal with provided template data
     */
    initializeModal({ preview }) {
      if (!preview?.template) return;

      this.resetForm();
      this.template = preview.template;
      this.templateName = this.isUpdateExistingTemplate
        ? this.template.name
        : `${this.template.name}_copy`;
      this.selectedCustomer = this.findCustomerById(this.template.customer_id) || { id: 0, name: 'All Customers' };
      this.selectedTags = this.parseTags(this.template.tags);

      this.extractTemplateSettings(this.template);

      this.fetchTags();
      this.checkIfTemplateIsInUse();
      
      this.$bvModal.show('save-as-template-modal');
    },

    resetForm() {
      if (!this.isUpdateExistingTemplate) {
        this.maxFileSize = this.defaultMaxFileSize;
        this.dpi = this.defaultDpi;
        this.fileType = '';
      }
      this.templateName = '';
      this.selectedCustomer = null;
      this.isUpdateExistingTemplate = false;
      this.isLoading = false;
    },

    findCustomerById(customerId) {
      return this.customers.find(customer => customer.id === customerId) || null;
    },

    parseTags(tagsString) {
      return tagsString ? tagsString.split(',').filter(tag => tag.trim() !== '') : [];
    },

    extractTemplateSettings(template) {
      if (!template?.fields) return;

      template.fields.forEach(field => {
        try {
          const fieldData = JSON.parse(field.options);
          if (!fieldData?.Option1) return;

          switch (field.type) {
            case 'Max File Size':
              this.maxFileSize = parseInt(fieldData.Option1);
              break;
            case 'DPI':
              this.dpi = parseInt(fieldData.Option1);
              break;
            case 'File Type':
            case 'File Type':
              const inputFileType = fieldData.Option1;
              const foundOption = this.fileTypeOptions.find(
                opt =>
                  opt.value === inputFileType ||
                  opt.text.toLowerCase() === inputFileType.toLowerCase()
              );
              this.fileType = foundOption ? foundOption.value : inputFileType;
              break;
          }
        } catch (error) {
          console.error('Error parsing field options:', error);
        }
      });
    },
 
    closeModal() {
      this.$bvModal.hide('save-as-template-modal');
    },

    async handleSave() {
      this.isLoading = true;

      if (this.isTemplateInUse && this.isUpdateExistingTemplate) {
        return this.showModificationWarning();
      }

      this.saveTemplate();
    },

    showModificationWarning() {
      Swal.fire({
        text: 'This template has already been used in other layouts, modifications can change the design',
        showCancelButton: true,
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        icon: 'info',
      }).then(({ value }) => {
        if (value) this.saveTemplate();
        else this.resetForm();
      });
    },

    saveTemplate() {
      const payload = {
        templateName: this.templateName,
        selectedCustomerId: this.selectedCustomer?.id,
        tags: this.selectedTags.join(','),
        isUpdateExistingTemplate: this.isUpdateExistingTemplate,
        settings: {
          max_file_size: parseInt(this.maxFileSize) || this.defaultMaxFileSize,
          dpi: parseInt(this.dpi) || this.defaultDpi,
          file_type: this.fileType || ''
        }
      };

      saveAsTemplateEventBroker.fire(EVENTS.SAVE, payload);
    },

    async checkIfTemplateIsInUse() {
      const { data } = await axios.post('/banner/check_used_template', { templateId: this.template.id });
      this.isTemplateInUse = data;
    },

    async fetchTags() {
      const { data } = await axios.get(`/projects/template/${this.template.id}/tag`);
      this.tags = data.map(tag => tag.name);
    },

    async addTag(name) {
      const { data } = await axios.post(`/projects/template/${this.template.id}/tag`, { name });
      this.tags.unshift(data.name);
      this.selectedTags.push(data.name);
    }
  }
};
</script>
