import Image from "./image";
import { FIELD_TYPES } from "@/includes/parse_psd_data/constants";

export default class UserImage extends Image {

  buildFromData(field) {
    super.buildFromData(field);
    
    this.data = {
      ...field,
      visible: true
    }

    this.fieldType = FIELD_TYPES.BACKGROUND_IMAGE;
  }

}
