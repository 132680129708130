<template>
  <div
    tabindex="0"
  >
    <div
      v-for="(color, index) in editor.colors"
      :key="index"
      class="d-inline-flex colors-wrap mt-2 align-content-center"
    >
      <b-input
        :value="color.slice(0,7)"
        class="w-10"
        debounce="50"
        size="sm"
        style="width: 10%;"
        type="color"
        @click="copyValue(index)"
        @update="(inputColor) => onInputColor(inputColor,index)"
      />
      <b-input
        :value="color.slice(0,7)"
        class="w-20"
        size="sm"
        style="width: 25%;"
        type="text"
        @change="(inputColor) => onInputColor(inputColor,index)"
      />
      <b-tooltip
        :target="'slider-' + index"
        triggers="hover focus"
      >
        Adjust the position of the color stop (0-100%).<br>
        <strong>Color Stop</strong> — a point in the gradient where a specific color is applied.
      </b-tooltip>
      
      <b-input
        :id="'slider-' + index"
        v-model="colorSteps[index]"
        :max="100"
        :min="0"
        class="w-50"
        number
        size="sm"
        type="range"
        @input="(value) => $emit('colorStepsInput', {index, value})"
      />
      <b-tooltip
        :target="'input-' + index"
        triggers="hover focus"
      >
        Enter the exact position of the color stop (0-100%).<br>
        <strong>Gradient Color Stop</strong> — a point in the gradient where a specific color is applied.
      </b-tooltip>
      <b-input
        :id="'input-' + index"
        v-model="colorSteps[index]"
        :max="100"
        :min="0"
        class="w-20"
        number
        size="sm"
        style="width: 20%;"
        type="number"
        @input="(value) => $emit('colorStepsInput', {index, value})"
        @scroll.stop
      />
    </div>
  </div>
</template>

<script>

export default {
  name:'ColorStop',
  components: {
  },
  props: {
    editor: {
      type: Object,
      default: () => null
    },
    activeColor: {
      type: String,
      default: '#FFFFFF'
    },
    showColorPicker: {
      type: Boolean,
      default: false
    },
  },
  data:() => ({
    colorSteps:[],
  }),
  watch: {
    editor() {
      this.colorSteps = [...this.editor.colorSteps];
    }
  },
  mounted() {
    this.colorSteps = [...this.editor.colorSteps];
  },
  methods: {
    onInputColor(color, index) {
      this.copyValue(index);
      this.$emit('colorUpdated', {color: color ? color : 'transparent', index});
    },
    copyValue(index) {
      const tempInput = document.createElement('input');
      tempInput.value =  this.editor.colors[index].slice(0,7);
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
    }
  }
}
</script>
<style lang="scss" scoped>
.colors-wrap {
  display: flex;
  gap: 10px;
  width: 100%;
  margin: 0;
  align-items: center;
}
</style>