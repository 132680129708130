<template>
  <div>
    <b-modal
      id="simple-table-builder"
      title="Table Builder"
      centered
      size="xl"
      ok-title="Save"
      @ok="onSave"
    >
      <div
        v-if="table && table.rows.length"
        class="mb-3"
      >
        <b-button-group>
          <b-button @click="addRow">
            Add Row
          </b-button>
          <b-button @click="addColumn">
            Add Column
          </b-button>
          <b-button @click="removeRow">
            Remove Row
          </b-button>
          <b-button @click="removeColumn">
            Remove Column
          </b-button>
          <DropdownCounter
            :value="getStyleValue('font-size', 0)"
            :min="1"
            :step="1"
            @input="(value) => onChangeStyleOfTable('font-size', value, 'px')"
          >
            <i class="cil-text-size" />
          </DropdownCounter>
          <DropdownCounter
            :value="getStyleValue('border-width', 0)"
            :min="0"
            :step="1"
            @input="(value) => onChangeStyleOfTable('border-width', value, 'px', true)"
          >
            <i class="cil-border-style" />
          </DropdownCounter>
          <b-button>
            <ColorPicker
              :value="getStyleValue('color', '#000000')"
              @input="(value) => onChangeStyleOfTable('color', value)"
            />
          </b-button>

          <div class="d-flex align-items-baseline p-2 w-10 t-input">
            <label
              class="mr-1"
              for="cell-value"
            >
              W:
            </label>
            <b-input
              id="cell-value"
              :value="tableWidth"
              type="number"
              class="form-control"
              @input="(value) => changeTableWidth(value)"
            />
          </div>

          <div class="d-flex align-items-baseline p-2 w-10 t-input">
            <label
              class="mr-1"
              for="h-cell-value"
            >
              H:
            </label>
            <b-input
              id="cell-value"
              :value="tableHeight"
              type="number"
              class="form-control"
              :disabled="autoHeight"
              @input="(value) => changeTableHeight(value)"
            />
          </div>

          <div class="d-flex align-items-center p-2">
            <b-form-checkbox 
              v-model="autoHeight" 
              switch
              @change="handleAutoHeightChange"
            >
              Use auto height
            </b-form-checkbox>
          </div>
        </b-button-group>
        
        <b-form-row
          v-if="selectedCells.length"
          class="mt-2"
        >
          <b-col cols="2">
            <div>
              <b-form-group
                label="Text"
                description="Press shift + enter to apply changes"
                class="table-text-area"
              >
                <b-form-textarea
                  id="cell-value"
                  :value="selectedCells[0].value"
                  type="text"
                  class="form-control"
                  rows="3"
                  @input="(value) => selectedCells[0].value = value"
                />
              </b-form-group>
            </div>
          </b-col>
          
          <b-col 
            cols="7" 
            class="font-controls"
          >
            <b-form-row>
              <b-col cols="1">
                <label
                  class="mb-0"
                  for="cell-value"
                >
                  Color
                </label>
                <div class="cpw">
                  <ColorPicker
                    v-model="selectedCellTextColor"
                    @input="(value) => handleChangeCellStyleRule('color', value, '')"
                  />
                </div>
              </b-col>

              <b-col cols="1">
                <label
                  class="mb-0"
                  for="cell-value"
                >
                  Cell
                </label>
                <div class="cpw">
                  <ColorPicker
                    v-model="selectedCellBgColor"
                    @input="(value) => handleChangeCellStyleRule('background-color', value, '')"
                  />
                </div>
              </b-col>

              <b-col cols="2">
                <label
                  class="mb-0 mb-1"
                  for="font-family"
                >
                  Font Family
                </label>
                <select
                  id="font-family"
                  class="form-control form-control-sm"
                  :value="fontFamily"
                  @input="setFontFamily"
                >
                  <option
                    v-for="(currentFont, index) in fontsList"
                    :key="(currentFont.index || currentFont.created_at) + index"
                    :value="currentFont.index || currentFont"
                    :style="{'font-family': currentFont.index}"
                  >
                    {{ currentFont.name || currentFont.title }}
                  </option>
                </select>
              </b-col>

              <b-col cols="2">
                <label
                  class="mb-0 mb-1"
                  for="font-family"
                >
                  Aligned
                </label>
                <select
                  id="font-family"
                  class="form-control form-control-sm"
                  :value="align"
                  @input="setFontAligned"
                >
                  <option
                    v-for="(item, index) in textAlignedList"
                    :key="item + index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </b-col>

              <b-col cols="2">
                <div>
                  <label
                    class="mb-0"
                    for="cell-value"
                  >
                    Font Size
                  </label>
                  <b-input
                    id="cell-value"
                    v-model="selectedCellTextSize"
                    type="number"
                    class="form-control"
                    @input="(value) => handleChangeCellStyleRule('font-size', value, 'px')"
                  />
                </div>
              </b-col>

              <b-col cols="2">
                <div>
                  <label
                    class="mb-0"
                    for="cell-value"
                  >
                    Width
                  </label>
                  <b-input
                    id="cell-value"
                    v-model="selectedCellMinWidth"
                    type="number"
                    class="form-control"
                    @input="(value) => changeCellStyleRule('width', value, 'px')"
                  />
                </div>
              </b-col>

              <b-col cols="2">
                <div>
                  <label
                    class="mb-0"
                    for="cell-height"
                  >
                    Height
                  </label>
                  <b-input
                    id="cell-height"
                    v-model="selectedCellMinHeight"
                    type="number"
                    class="form-control"
                    :disabled="!autoHeight"
                    @input="(value) => changeRowStyles('height', value, 'px')"
                  />
                </div>
              </b-col>
            </b-form-row>
            <b-form-row class="justify-content-center mt-2">
              <label class="mb-0 mt-1 mr-2">Apply to:</label>
              <b-form-radio-group
                v-model="applyToCells"
                class="apply-to-buttons"
                :options="applyToOptions"
                button-variant="outline-secondary"
                size="sm"
                buttons
              />
            </b-form-row>
          </b-col>

          <b-col cols="1">
            <div>
              <label
                class="mb-0 mt-1"
                for="cell-value"
              >
                Colspan
              </label>
              <b-input
                id="cell-value"
                :value="selectedCells[0].colspan"
                type="number"
                class="form-control"
                :disabled="selectedCells.length > 1"
                @input="(value) => selectedCells[0].colspan = value"
              />
            </div>
          </b-col>
          
          <b-col cols="0">
            <b-button
              class="btn btn-secondary field-btn"
              @click="onCancel"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col cols="0">
            <b-button
              class="btn btn-success field-btn"
              @click="onApply"
            >
              Apply
            </b-button>
          </b-col>
        </b-form-row>
      </div>

      <div
        v-if="table && table.rows.length"
        ref="tableContainer"
        class="table-builder-container"
      >
        <table
          ref="table"
          class="no-select"
          :style="getStyleOfTable"
        >
          <thead>
            <tr>
              <th
                v-for="column in table.headers"
                :key="column.id"
                :class="{'selected-cell': selectedCells.some(item => item.id === column.id)}"
                :style="column.stringStyles"
                :colspan="column.colspan"
                @click="(event) => onSelectCell(event, column)"
              >
                <span>{{ column.value }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in table.rows"
              :key="row.id"
              :style="row.stringStyles"
            >
              <td
                v-for="cell in row.cells"
                :key="cell.id"
                :class="{'selected-cell': selectedCells.some(item => item.id === cell.id)}"
                :style="cell.stringStyles"
                :colspan="cell.colspan"
                @click="(event) => onSelectCell(event, cell)"
              >
                <span>{{ cell.value }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-else
        class="d-flex justify-content-center"
      >
        <b-dropdown text="Create Table">
          <div class="p-2">
            <div class="d-flex">
              <b-form-group>
                <label>Columns</label>
                <b-form-input
                  v-model.number="createTable.columns"
                  type="number"
                  size="sm"
                />
              </b-form-group>
              <div class="mx-2" />
              <b-form-group>
                <label>Rows</label>
                <b-form-input
                  v-model.number="createTable.rows"
                  type="number"
                  size="sm"
                />
              </b-form-group>
            </div>

            <b-button
              variant="primary"
              size="sm"
              class="w-100"
              @click="onCreateTable"
            >
              Create
            </b-button>
          </div>
        </b-dropdown>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { simpleTableBuilderEventBroker, EVENTS } from '@frontend/group/modules/simple-table-builder/event-broker';
import DropdownCounter from '../../../common/atoms/DropdownCounter.vue';
import ColorPicker from '../EditorControl/components/ColorPicker.vue';
import FontFamilyManager from '../../../../common/load-fonts';
import { APPLY_TO_TEXT, APPLY_TO_VALUES } from "@frontend/group/modules/simple-table-builder/constants";

let tableInstance;

export default {
  name: 'SimpleTableBuilderModal',
  components: {
    DropdownCounter,
    ColorPicker
  },
  data() {
    return {
      $element: null,
      table: null,
      params: null,
      tableData: null,
      createTable: {
        columns: 10,
        rows: 10
      },
      selectedCells: [],
      selectedCellBgColor: '#ffffff',
      selectedCellTextColor: '#000000',
      selectedCellTextSize: 16,
      selectedCellMinWidth: '1',
      selectedCellMinHeight: '1',
      tableWidth: 0,
      tableHeight: 0,
      fontFamilyManager: new FontFamilyManager(),
      fontsList: [],
      fontFamily: 'Nunito-Bold-257',
      align: 'left',
      textAlignedList: [
        'left',
        'center',
        'right',
      ],
      applyToCells: APPLY_TO_VALUES.SELECTED,
      autoHeight: false
    };
  },
  computed: {
    getStyleOfTable() {
      if (!this.table) return {};

      const rules = this.table.styles.rules;

      return {
        ...rules,
      };
    },
    
    applyToOptions() {
      const disabled = this.table.headers.some(cell => cell.id === this.selectedCells[0].id);
      
      return [
        { text: APPLY_TO_TEXT.SELECTED, value: APPLY_TO_VALUES.SELECTED },
        { text: APPLY_TO_TEXT.ALL, value: APPLY_TO_VALUES.ALL },
        { text: APPLY_TO_TEXT.HEADER, value: APPLY_TO_VALUES.HEADER },
        { text: APPLY_TO_TEXT.BODY, value: APPLY_TO_VALUES.BODY },
        { text: APPLY_TO_TEXT.COLUMN, value: APPLY_TO_VALUES.COLUMN, disabled },
        { text: APPLY_TO_TEXT.ALTERNATING_ROWS, value: APPLY_TO_VALUES.ALTERNATING_ROWS, disabled }
      ]
    }
  },
  mounted() {
    simpleTableBuilderEventBroker.on(EVENTS.INIT, this.init.bind(this));
    window.addEventListener('keydown', this.onKeyDown);
    this.fontFamilyManager.loadData();
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    init({ $element, table, styles, params, tableData }) {
      this.clearData();
      
      this.$element = $element;
      this.params = params;
      this.tableData = tableData;
      tableInstance = table;
      this.table = tableInstance.toData();
      this.fontsList = this.fontFamilyManager.fonts;
      this.autoHeight = tableInstance.styles.rules.height === 'auto';
      
      this.$bvModal.show('simple-table-builder');

      this.$nextTick(() => {
        if (tableInstance?.styles.rules.width && tableInstance?.styles.rules.height) {
          this.tableWidth = parseInt(tableInstance.styles.rules.width.replace('px', ''));
          this.tableHeight = tableInstance.styles.rules.height === 'auto' 
            ? tableInstance.getDimension().height
            : parseInt(tableInstance.styles.rules.height.replace('px', ''));
        } else {
          this.tableWidth = tableInstance.getDimension().width
          this.tableHeight = tableInstance.getDimension().height
        }
      });
    },
    clearData() {
      this.$element = null;
      this.table = null;
      this.params = null;
      this.createTable = {
        columns: 10,
        rows: 10
      };
      this.selectedCells = [];
      this.selectedCellBgColor = '#ffffff';
      this.selectedCellTextColor = '#000000';
      this.selectedCellMinWidth = '1';
      this.selectedCellTextSize = 16;
      this.applyToCells = APPLY_TO_VALUES.SELECTED;
    },
    onCreateTable() {
      const columns = this.createTable.columns;
      const rows = this.createTable.rows;

      tableInstance.createTableGrid(columns, rows);

      this.table = tableInstance.toData();

      this.$nextTick(() => {
        this.tableWidth = tableInstance.getDimension().width
        this.tableHeight = tableInstance.getDimension().height
      });
    },
    addRow() {
      tableInstance.addNewRow();

      this.table = tableInstance.toData();
    },
    addColumn() {
      tableInstance.addNewColumn();

      this.table = tableInstance.toData();
    },
    removeRow() {
      tableInstance.removeNewRow();
      this.table = tableInstance.toData();
    },
    removeColumn() {
      tableInstance.removeNewColumn();
      this.table = tableInstance.toData();
    },
    setFontFamily(fontFamily) {
      this.fontObject = this.getFontObjectFromList(fontFamily.target.value);
      this.fontFamily = this.fontObject.index;
      this.handleChangeCellStyleRule('font-family', this.fontFamily, '');
    },
    getFontObjectFromList(font) {
      return this.fontsList.find(o => o.index === font);
    },
    setFontAligned(align) {
      this.align = align.target.value;
      this.handleChangeCellStyleRule('text-align', this.align, '');
    },
    onSelectCell(event, { id, value, colspan }) {
      if (event.button === 0 && event.shiftKey ) {
        const index = this.selectedCells.findIndex(cell => cell.id === id);
        
        index >= 0 
          ? this.selectedCells.splice(index, 1) 
          : this.selectedCells.push({ id, value, colspan });
        
      } else {
        this.selectedCells = [{ id, value, colspan }];
      }
      
      if (!this.selectedCells.length) return;
      // Each time the user changes a cell, enable the selected mode to display the current formatting data of the cell.
      this.applyToCells = APPLY_TO_VALUES.SELECTED;
      
      this.selectedCellBgColor = tableInstance.getCellStyle(this.selectedCells[0].id, 'background-color', '#ffffff');
      this.selectedCellTextColor = tableInstance.getCellStyle(this.selectedCells[0].id, 'color', '#000000');
      this.selectedCellMinWidth = tableInstance.getCellStyle(this.selectedCells[0].id, 'width', '1');
      this.selectedCellTextSize = tableInstance.getCellStyle(this.selectedCells[0].id, 'font-size', 16);
      this.align = tableInstance.getCellStyle(this.selectedCells[0].id, 'text-align', 'left');
      this.fontFamily = tableInstance.getCellStyle(this.selectedCells[0].id, 'font-family', 'Nunito-Bold-257');
      this.selectedCellMinHeight = tableInstance.getRowStyle(this.selectedCells[0].id, 'height', 0);
    },
    handleChangeCellStyleRule(rule, value, measure = '') {
      this.applyToCells === APPLY_TO_VALUES.SELECTED 
        ? this.changeCellStyleRule(rule, value, measure)
        : this.changeMultipleCellsStyleRules(rule, value, measure); 
    },
    changeMultipleCellsStyleRules(rule, value, measure = '') {
      tableInstance.changeMultipleCellsStyle([
        {
          rule: rule,
          value: value,
          measure: measure
        }
      ], 
        this.applyToCells, 
        this.selectedCells[0]
      );

      this.table = tableInstance.toData();

      this.handleTableDimension();
    },
    changeCellStyleRule(rule, value, measure = '') {
      tableInstance.changeCellStyle({
        cells: this.selectedCells.map(cell => cell.id),
        rules: [{
          rule: rule,
          value: value,
          measure: measure
        }],
      });

      this.table = tableInstance.toData();

      this.handleTableDimension();
    },

    changeRowStyles(rule, value, measure = '') {
      tableInstance.changeRowStyles({
        cells: this.selectedCells.map(cell => cell.id),
        rules: [{
          rule: rule,
          value: value,
          measure: measure
        }],
      });
      
      this.table = tableInstance.toData();

      this.handleTableDimension();
    },
    changeTableWidth(width) {
      this.tableWidth = width;
      tableInstance.setDimensions(this.tableWidth, this.tableHeight);
      this.table = tableInstance.toData();
    },
    changeTableHeight(height) {
      this.tableHeight = height;
      tableInstance.setDimensions(this.tableWidth, this.tableHeight);
      this.table = tableInstance.toData();
    },
    onApply() {
      tableInstance.changeCellValue(this.selectedCells[0]);
      this.table = tableInstance.toData();
    },
    onKeyDown(e) {
      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault();
        this.onApply();
      }
    },
    onCancel() {
      this.selectedCells = [];
    },
    getStyleValue(key, defaultValue) {
      const value = this.table.styles.rules[key];
      const number = parseInt(value);

      return value === undefined ? defaultValue : Number.isNaN(number) ? value : number;
    },
    onChangeStyleOfTable(key, value, measure = '', hasChangeForCells = false) {
      tableInstance.styles.setRules({
        [key]: `${value}${measure}`,
      });

      if (hasChangeForCells) {
        tableInstance.setCellsRules({
          [key]: `${value}${measure}`,
        });
      }

      this.table = tableInstance.toData();

      this.$nextTick(() => {
        this.tableWidth = tableInstance.getDimension().width
        this.tableHeight = tableInstance.getDimension().height
      });
    },
    onSave() {
      const height = this.autoHeight ? 'auto' : this.tableHeight;
      
      tableInstance.setDimensions(this.tableWidth, height);
      
      simpleTableBuilderEventBroker.fire(EVENTS.SAVE, {
        $element: this.$element,
        tableInstance,
        styles: this.styles,
        params: this.params,
        tableData: this.tableData
      });
    },
    handleTableDimension() {
      this.$nextTick(() => {
        if (tableInstance?.styles.rules.width && tableInstance?.styles.rules.height) {
          this.tableWidth = parseInt(tableInstance.styles.rules.width.replace('px', ''));
          this.tableHeight = this.autoHeight 
            ? tableInstance.getDimension().height 
            : parseInt(tableInstance.styles.rules.height.replace('px', ''));
        } else {
          this.tableWidth = tableInstance.getDimension().width
          this.tableHeight = tableInstance.getDimension().height
        }
      });
    },
    handleAutoHeightChange() {
      if (this.autoHeight) {
        tableInstance.setDimensions(this.tableWidth, 'auto');
        this.table = tableInstance.toData();
      } else {
        // Remove the height from all rows to adjust the table based on the table's overall height.
        const cells = tableInstance.rows
          .reduce((acc, row) => [...acc, ...row.cells], [...tableInstance.headers])
          .map(cell => cell.id);
        
        tableInstance.changeRowStyles({
          cells,
          rules: [{
            rule: 'height',
            value: 0,
            measure: 'px'
          }],
        });
        
        this.tableHeight = tableInstance.getDimension().height;
        tableInstance.setDimensions(this.tableWidth, tableInstance.getDimension().height);
        this.table = tableInstance.toData();
      }
      
    }
  }
};
</script>

<style lang="scss">

.table-builder-container {
  overflow: auto;
  min-height: 500px;
  max-height: 500px;

  .content-editor {
    cursor: text;
    width: 100%;
    height: 100%;
  }
}

.selected-cell {
  border-width: 4px !important;
  border-color: #2eb85c !important;
}

.field-btn {
  margin-top: 27px;
}

.cpw {
  border: 1px solid #d8dbe0;
  border-radius: 2px;
  margin-top: 3px;
  width: 31px;
}

.t-input {
  width: 120px;
}

.font-controls {
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 5px;
}

.apply-to-buttons {
  .btn-outline-secondary {
    color: #4f5d73;
  }
}

.no-select {
  user-select: none;
}

.table-text-area {
  .text-muted {
    font-size: 10px;
  }
}
</style>
