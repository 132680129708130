import { RENAME_LAYOUT_EVENTS, renameLayoutEventBroker } from "@frontend/group/modules/rename-layout/event-broker";

export const RENAME_LAYOUT_ACTIONS = {
  RENAME_LAYOUTS_TAB: 'renameLayoutsTab',
  RENAME_LAYOUT_FOLDERS_TAB: 'renameLayoutFoldersTab',
}

const RENAME_LAYOUT_HANDLERS = {
  [RENAME_LAYOUT_ACTIONS.RENAME_LAYOUTS_TAB]: (name, id) => {
    const thumbnailLayoutItem = $(`.layout-item[data-id=${id}]`);
    const thumbnailLayoutItemName = thumbnailLayoutItem.find('.layout-item__name');
    const rowLayoutItemName = $(`.layout-row-name[data-id=${id}]`);
    const renameButton = $(`.rename-layout-action[data-layout=${id}]`);
    
    thumbnailLayoutItemName.html(`<strong>Name: </strong> ${name}`);
    rowLayoutItemName.text(name);
    renameButton.data('layout-name', name);
  },
  
  [RENAME_LAYOUT_ACTIONS.RENAME_LAYOUT_FOLDERS_TAB]: (name, id) => {
    renameLayoutEventBroker.fire(RENAME_LAYOUT_EVENTS.SAVED, { name, id });
  }
}

$(document).ready(function () {
  renameLayoutEventBroker.on(RENAME_LAYOUT_EVENTS.SAVE, ({ action, name, id }) => {
    RENAME_LAYOUT_HANDLERS[action](name, id);
  });
});


