var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: "Catalog Management",
            centered: "",
            "ok-title": "Save",
          },
          on: {
            ok: function ($event) {
              $event.preventDefault()
              return _vm.handleSaveBtn.apply(null, arguments)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "secondary", disabled: _vm.loading },
                      on: { click: cancel },
                    },
                    [_vm._v("\n        Cancel\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary", disabled: _vm.loading },
                      on: { click: ok },
                    },
                    [
                      _vm.loading
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loading ? _c("span", [_vm._v("Save")]) : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.isOpened,
            callback: function ($$v) {
              _vm.isOpened = $$v
            },
            expression: "isOpened",
          },
        },
        [
          _c("label", { attrs: { for: "input-live" } }, [
            _vm._v("Layout name"),
          ]),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: { placeholder: "Enter name" },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }