<template>
  <div class="smart-object-ui-container">
    <div>
      <b-form-group
        id="select-smart-object"
        label="Smart Object"
        label-for="select-smart-object"
      >
        <b-form-select
          id="select-smart-object"
          v-model="selectedSmartObjectFieldId"
          :options="smartObjectsList"
          text-field="name"
          value-field="id"
          @change="onSelectSmartObject"
        />
      </b-form-group>
    </div>
    <b-form-checkbox
      v-model="showOtherUIElements"
      :inline="true"
      aria-label="show"
      class="mb-2"
      @change="toggleShowingOtherUiElements"
    >
      Show other template elements
    </b-form-checkbox>
    <PositionAndSize
      v-if="smartObjectFabricRectangleObject"
      :aspect-ratio-option="false"
      :h="(smartObjectFabricRectangleObject.height * smartObjectFabricRectangleObject.scaleY)"
      :w="(smartObjectFabricRectangleObject.width * smartObjectFabricRectangleObject.scaleX)"
      :x="parseFloat(smartObjectFabricRectangleObject.left)"
      :y="parseFloat(smartObjectFabricRectangleObject.top)"
      @input="onChange"
    />
    <b-form-group
      v-for="(setting, key) in option5"
      :id="key"
      :key="key"
      :label="key.charAt(0).toUpperCase() + key.slice(1) + ' Setting'"
    >
      <b-form-row
        v-for="(settingKey) in getKeys(setting)"
        :key="settingKey"
        class="ml-1"
      >
        <b-col           
          cols="3"
        >
          <label
            :for="'input-' + settingKey"
          >
            {{ settingKey.charAt(0).toUpperCase() + settingKey.slice(1) }}
          </label>
        </b-col>
        <b-col
          cols="9"
        >
          <b-row>
            <b-col class="pl-0">
              <b-form-input
                :id="'input-range-' + settingKey"
                v-model="smartObjectData[settingKey]"
                :max="rules[settingKey].max"
                :min="rules[settingKey].min"
                :step="rules[settingKey].step"
                type="range"
                @input="onChange(settingKey, $event)"
              />
            </b-col>
            <b-col
              class="m-0 pl-0"
              cols="4"
            >
              <b-form-input
                :id="'input-' + settingKey"
                v-model="smartObjectData[settingKey]"
                :max="rules[settingKey].max"
                :min="rules[settingKey].min"
                :step="rules[settingKey].step"
                type="number"
                @change="onChange(settingKey, $event)"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-form-row>
    </b-form-group>
    <b-form-group
      id="members"
      label="Members"
    >
      <img
        v-if="hoveredItem !== 'data:,'"
        :src="hoveredItem"
        alt="empty"
        class="member-thumbnail"
      >
      <div class="d-flex flex-column members-container">
        <b-form-checkbox
          v-for="object in fieldsOptions"
          :id="object.value + '_checkbox'"
          :key="object.value"
          :checked="object.isMember"
          :inline="true"
          :name="object.value + '_checkbox'"
          class="member-item"
          @change="updateMembers(object.value)"
        >
          <p
            @mouseenter="hoveredItem = object.thumbnail"
            @mouseleave="hoveredItem = 'data:,'"
          >
            {{ object.name }}
          </p>
        </b-form-checkbox>
        <b-form-checkbox
          v-for="object in additionalFields"
          :id="object.value + '_checkbox'"
          :key="object.value"
          :checked="object.isMember"
          :inline="true"
          :name="object.value + '_checkbox'"
          class="member-item"
          @change="updateAdditionalFieldsMembers(object.id)"
        >
          <p
            @mouseenter="hoveredItem = object.thumbnail"
            @mouseleave="hoveredItem = 'data:,'"
          >
            {{ object.name }}
          </p>
        </b-form-checkbox>
      </div>
    </b-form-group>
    <b-btn
      variant="primary"
      @click="onSave('save', null)"
    >
      Save
    </b-btn>
    <b-btn
      variant="outline-primary"
      @click="onCancel"
    >
      Cancel
    </b-btn>
    <b-button
      v-b-tooltip.hover
      :title="lockedState ? 'Unlock' : 'Lock'"
      variant="outline-primary"
      @click.stop="lockedState = !lockedState"
    >
      <b-icon :icon="lockedState ? 'lock-fill' : 'unlock'" />
    </b-button>
  </div>
</template>

<script>
import PositionAndSize from "./components/PositionAndSize.vue";
import {
  ADDITIONAL_BACKGROUND_THEME_IMAGE_PREFIX_ID,
  ADDITIONAL_CIRCLE_PREFIX_ID,
  ADDITIONAL_LINE_PREFIX_ID,
  ADDITIONAL_MULTI_LINE_TEXT_FIELD_PREFIX_ID,
  ADDITIONAL_RECTANGLE_PREFIX_ID, 
  ADDITIONAL_TEXT_PREFIX_ID,
  MASK_PREFIX_ID
} from "@frontend/constants/templates-field-prefixes";

const ADD_FIELD_PREFIXES = [
  ADDITIONAL_BACKGROUND_THEME_IMAGE_PREFIX_ID,
  ADDITIONAL_MULTI_LINE_TEXT_FIELD_PREFIX_ID,
  ADDITIONAL_TEXT_PREFIX_ID,
  ADDITIONAL_RECTANGLE_PREFIX_ID,
  ADDITIONAL_CIRCLE_PREFIX_ID,
  ADDITIONAL_LINE_PREFIX_ID,
];
export default {
  components: {
    PositionAndSize,
  },
  props: {
    smartObjectsList: {
      type: Array,
      default: () => []
    },
    target: {
      type: Object,
      default: () => {
      },
    },
    options: {
      type: Object,
      default: () => {
      },
    },
    preview: {
      type: Object,
      default: () => {
      },
    },
    smartObjectId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      lockedState: false, 
      showOtherUIElements: true,
      hoveredItem: 'data:,',
      selectedSmartObjectFieldId: null,
      members:[],        
      additionalFieldsMembers: [],        
      smartObjectFabricRectangleObject: null,
      nonEditableKeys: ['type', 'projection', 'spread', 'hskew', 'vskew'],
      smartObjectData: {
        angle: 0,
        distance: 0,
        opacity: 0,
        size: 0,
        radius: 0
      },
      rules:{
        angle: {
          min: 0,
          max: 360,
          step: 1
        },
        distance: {
          min:-200,
          max: 200,
          step: 1
        },
        opacity: {
          min: 0,
          max: 100,
          step: 1
        },
        size: {
          min: 0,
          max: 999,
          step: 1
        },
        radius: {
          min: 0,
          max: 999,
          step: 1
        },
      },
      backupSizeAndPosition: null,
      selectedMembers: [],
      isObjectChanged: false
    }
    
  },
  computed:{
    option5() {
      if (!this.selectedSmartObjectFieldId || !this.smartObjectsList.length) {
        return {};
      }
      const { parsedOptions: parsedOptionsFromObject }  = this.preview.canvas.getObjects().find(object => !!object.fieldId && object.id.startsWith(MASK_PREFIX_ID))
      const {parsedOptions} = this.smartObjectsList.find(item => item.id == this.selectedSmartObjectFieldId);
      return parsedOptionsFromObject || JSON.parse(parsedOptions.Option5);
    },
    fieldsOptions() {
      if (!this.smartObjectFabricRectangleObject) return [];
      return this.preview.canvas.getObjects().filter(object => !!object.fieldId && !object.id.startsWith(MASK_PREFIX_ID))
        .map((obj) => {
          const field = this.preview.template.fields.find(field => field.id == obj.fieldId);
          return {
            value: ''+obj.fieldId,
            text: obj.id,
            groupName: obj?.groupName,
            thumbnail:
              obj.toDataURL('image/jpeg', 0.2),
            name: field.name,
            isMember: this.members.includes(''+obj?.fieldId)
          }
        });
    },
    additionalFields() {
      const addObjects = this.preview.canvas.getObjects().filter(object => !!object?.id && ADD_FIELD_PREFIXES.some(prefix => object.id.startsWith(prefix)))
      return addObjects.map((obj) => ({
        id: obj.id,
        elementId: obj?.element_id,
        value: '' + obj.id,
        text: obj.name,
        groupName: obj?.groupName,
        thumbnail:
          obj.toDataURL('image/jpeg', 0.2),
        name: obj?.name || `none name - ${obj.id}`,
        isMember: this.additionalFieldsMembers.includes(obj?.id)
      }));
    }
  },
  watch: {
    smartObjectId: function (newVal, oldVal) {
      this.selectedSmartObjectFieldId = newVal;
      this.selectSmartObject();
    },
  },
  mounted() {
    if (!this.selectedSmartObjectFieldId && this.smartObjectsList.length) {
      if (this.smartObjectId) {
        this.selectedSmartObjectFieldId = this.smartObjectsList.find(item => item.id == this.smartObjectId)?.id;
      }
      if (!this.selectedSmartObjectFieldId) {
        this.selectedSmartObjectFieldId = this.smartObjectsList[0].id;
      }
      this.selectSmartObject();
    }
  },
  
  async beforeDestroy() {
    if (this.isObjectChanged || this.smartObjectFabricRectangleObject.isObjectChanged) {
      this.isObjectChanged = false;
      this.smartObjectFabricRectangleObject.isObjectChanged = false;
      const { value } = await Swal.fire({
        title: "Do you want to save SmartObject changes?",
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        icon: "warning",
      });
      if (value) {
        await this.onSave()
      } else {
        // this.smartObjectFabricRectangleObject.isNeedCancelMessage = false;
        this.smartObjectFabricRectangleObject.cancelChanges();
      }
    }
  },
  
  methods: {
    selectSmartObject() {
      this.onSelectSmartObject();
      const {angle, distance, opacity, size} = this.option5.shadow
      const {radius} = this.option5.mask
      this.smartObjectData.angle = angle;
      this.smartObjectData.distance = distance;
      this.smartObjectData.opacity = opacity;
      this.smartObjectData.size = size;
      this.smartObjectData.radius = radius;
      this.onChange('init',0);
      this.smartObjectFabricRectangleObject.bringToFront();
      this.toggleShowingOtherUiElements()
    },
    toggleShowingOtherUiElements() {
      if (this.showOtherUIElements) {
        this.smartObjectFabricRectangleObject.set('fill', '#FFFFFF80');
      } else {
        this.smartObjectFabricRectangleObject.set('fill', '#FFFFFFFF');
      }
      this.smartObjectFabricRectangleObject.canvas.renderAll();
    },
    updateMembers(fieldId) {
      if (this.members.includes(fieldId)) {
        this.members = this.members.filter(member => member !== fieldId);
        return
      }
      this.members.push(fieldId);
    },
    updateAdditionalFieldsMembers(id) {
      if (this.additionalFieldsMembers.includes(id)) {
        this.additionalFieldsMembers = this.additionalFieldsMembers.filter(member => member !== id);
        return
      }
      
      this.additionalFieldsMembers.push(id);
    },
    getKeys(setting) {
      return Object.keys(setting).filter(key => !this.nonEditableKeys.includes(key));
    },
    onCancel() {
      this.smartObjectFabricRectangleObject.cancelChanges();
    },
    onChange(action, value) {
      if (action !== 'init') {
        this.isObjectChanged = true;
      }
      switch (action) {
        case 'x':
          this.smartObjectFabricRectangleObject.setPositionByOrigin(
            {
              ...this.smartObjectFabricRectangleObject.getPointByOrigin('left', 'top'),
              x: parseFloat(value)
            }, 'left', 'top')
          break
        case 'y':
          this.smartObjectFabricRectangleObject.setPositionByOrigin(
            {
              ...this.smartObjectFabricRectangleObject.getPointByOrigin('left', 'top'),
              y: parseFloat(value)
            }, 'left', 'top')
          break
        case 'w':
          this.smartObjectFabricRectangleObject.set('width', parseFloat(value))
          break
        case 'h':
          this.smartObjectFabricRectangleObject.set('height', parseFloat(value))
          break
        default:
          break
      }
      if (action !== 'radius')
      this.smartObjectFabricRectangleObject.set('shadow', this.smartObjectFabricRectangleObject.makeShadow({...this.smartObjectData}));
      this.smartObjectFabricRectangleObject.set('rx', parseFloat(this.smartObjectData.radius));
      this.smartObjectFabricRectangleObject.set('ry', parseFloat(this.smartObjectData.radius));
      this.smartObjectFabricRectangleObject.canvas.renderAll()
      this.smartObjectFabricRectangleObject.updateData();
    },
    initMembers() {
      this.members = this.preview.canvas.getObjects()
        .filter(obj => obj.hasOwnProperty('groupName') 
          && obj.hasOwnProperty('fieldId') &&
          this.smartObjectFabricRectangleObject.groupName === obj.groupName
        )
        .map(obj => ''+obj.fieldId);
      let addFieldMembers = [];

      const elementId = this.smartObjectFabricRectangleObject?.element_id ?? 'default';
      const members = this.preview?.data?.[`${elementId}_additional_members`];
      if (Array.isArray(members)) {
        addFieldMembers = members;
      }
      this.additionalFieldsMembers = [...addFieldMembers]
    },
    onSelectSmartObject() {
      const relativeFabricObject = this.preview.canvas.getObjects()
        .find(object => object.fieldId == this.selectedSmartObjectFieldId);
      
      this.smartObjectFabricRectangleObject = relativeFabricObject;
      this.smartObjectFabricRectangleObject.isSelectedByUi = true;
      const {width, height, scaleX, scaleY} = this.smartObjectFabricRectangleObject;
      const {x,y} = this.smartObjectFabricRectangleObject.getPointByOrigin('left', 'top')
      this.smartObjectFabricRectangleObject.backupSizeAndPosition = {left: x, top: y, width: width * scaleX, height: height * scaleY};
      this.smartObjectFabricRectangleObject.isNeedHandleObjectMovingUpdate = false;
      if (!this.smartObjectFabricRectangleObject.evented && !this.smartObjectFabricRectangleObject.selectable) {
        this.smartObjectFabricRectangleObject.evented = true;
        this.smartObjectFabricRectangleObject.selectable = true;
      }
      this.lockedState = this.smartObjectFabricRectangleObject.locked;
      this.smartObjectFabricRectangleObject.set('locked', false);
      this.initMembers()
      this.preview.canvas.setActiveObject(relativeFabricObject);
      this.preview.canvas.renderAll();
    },
    async onSave() {
      this.isObjectChanged = false;
      this.smartObjectFabricRectangleObject.isObjectChanged = false;
      const option5 = this.option5;
      const options = JSON.parse(this.smartObjectFabricRectangleObject.templateField.options);
      const {x, y} = this.smartObjectFabricRectangleObject.getPointByOrigin('left', 'top');
      options.X = x.toFixed(2);
      options.Y = y.toFixed(2);
      options.Width = (this.smartObjectFabricRectangleObject.width * this.smartObjectFabricRectangleObject.scaleX).toFixed(2)
      options.Height = (this.smartObjectFabricRectangleObject.height * this.smartObjectFabricRectangleObject.scaleY).toFixed(2)
      options.Moveable = this.lockedState ? 'No' : 'Yes';
      option5.mask.radius = parseFloat(this.smartObjectData.radius);
      option5.shadow.angle = parseFloat(this.smartObjectData.angle);
      option5.shadow.distance = parseFloat(this.smartObjectData.distance);
      option5.shadow.opacity = parseFloat(this.smartObjectData.opacity);
      option5.shadow.size = parseFloat(this.smartObjectData.size);
      options.Option5 = JSON.stringify(option5)
      this.smartObjectFabricRectangleObject.set('locked', this.lockedState);
      const additionalSmartObjectMembersPayload = {
        instanceID : this.smartObjectFabricRectangleObject.canvas.instance_id,
        smartObjectElementID : this.smartObjectFabricRectangleObject.element_id,
        additionalFieldsMembers: this.additionalFieldsMembers
      };
      this.smartObjectFabricRectangleObject.isSaving = true;
      await this.smartObjectFabricRectangleObject.saveChanges(options, this.members.map(m => parseInt(m)), additionalSmartObjectMembersPayload)
    }
  },
}
</script>

<style lang="scss" scoped>
#members {
  .members-container {
    max-height: 291px;
    overflow: auto;
    margin-left: 7px;

    .custom-checkbox {
      .custom-control-label {
        display: inline-flex;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
      }
    }
    .member-item {
      max-width: max-content;
      left: 3px;
      p {
        margin: 0;
      }
    }
  }
  .member-thumbnail {
    position: absolute;
    left: 45%;
    top: 61%;
    width: 164px;
    height: 68px;
    object-fit: contain;
    object-position: center;
  }
  &:hover{
    .member-thumbnail {
      display: block;
    }
  }
}

@media screen and (width: 1300px) and (height: 738px) {
  .smart-object-ui-container {
    overflow-y: hidden;
    overflow-x: hidden;
    height: 687px;
  }
  .smart-object-ui-container:hover {
    overflow-y: auto;
  }
}
</style>
