import Theme from "./Theme";
import ThemeService from '@/frontend/services/api/themes'

export default class ThemesManager {

  themes = [];

  waitingPromises = {};

  getThemeById(id) {
    if (!id) {
      return undefined;
    }
    const nID = Number(id)
    const theme = this.themes.find(theme => theme.id === nID);
    if (theme) {
      return Promise.resolve(theme)
    }

    return this.createWaitingPromise(id);
  }

  getByIdSync(id) {
    const nID = Number(id)
    return this.themes.find(theme => theme.id === nID);
  }

  createWaitingPromise(id) {
    return new Promise((resolve) => {
      const theme = this.themes.find(theme => theme.id === id);

      if (theme) {
        return resolve(theme);
      }

      if (!this.waitingPromises[id]) {
        this.waitingPromises[id] = [];
        this.fetch([id])
      }

      this.waitingPromises[id].push(resolve);
    })
  }

  onLoad(response) {
    const { data } = response;
    this.themes.push(new Theme(data))

    const waitingPromises = this.waitingPromises[data.id]

    if (waitingPromises) {
      waitingPromises.forEach(resolve => {
        resolve(data)
      })
      delete this.waitingPromises[data.id]
    }
  }

  onError(id) {
    const waitingPromises = this.waitingPromises[id]

    if (!waitingPromises) {
      return;
    }

    waitingPromises.forEach(resolve => {
      resolve(undefined)
    })

    delete this.waitingPromises[id]
  }

  fetch(themeIds) {
    themeIds.forEach(id => {
      ThemeService.getTheme(id)
        .then(this.onLoad.bind(this))
        .catch((error) => {
          console.error(error)
          this.onError(id)
      })
    })
  }

  load(themeIds) {
    const idsToFetch = themeIds.filter(id => !this.themes.find(theme => theme.id === id) && !this.waitingPromises[id])

    if (!idsToFetch.length) {
      return;
    }

    idsToFetch.forEach(id => {
      this.waitingPromises[id] = []
    })

    ThemeService.getMultiple(idsToFetch)
      .then(response => {
        response.data.forEach(theme => {
          this.onLoad({ data: theme })
        })
      }).catch((error) => {
        console.error(error)
        idsToFetch.forEach(id => {
          this.onError(id)
        })
      })
  }

  waitAll() {
    return Promise.all(Object.keys(this.waitingPromises).map(id => this.getThemeById(id)));
  }
}