const ACTIONS = {
  SET_COLOR_TO_DRAW: 'SET_COLOR_TO_DRAW',
  SET_BG_IMAGE: 'SET_BG_IMAGE',
  SET_WORKING_AREA: 'SET_WORKING_AREA',
  SET_REC_MASK_MODE: 'SET_REC_MASK_MODE',
  SET_SVG_CANVAS_INSTANCE: 'SET_SVG_CANVAS_INSTANCE',
  SET_SVG_FABRIC_INSTANCE: 'SET_SVG_FABRIC_INSTANCE',
  SET_BG_PREVIEW: 'SET_BG_PREVIEW_ON',
  SET_PAINT_BUCKED: 'SET_PAINT_BUCKED',
  SET_BRUSH_SIZE: 'SET_BRUSH_SIZE',
  SET_TEXT_MODE: 'SET_TEXT_MODE',
  SET_ALT_SELECTION_MODE: 'SET_ALT_SELECTION_MODE',
  SET_SELECTION_BOX_MODE: 'SET_SELECTION_BOX_MODE',
  SET_PRODUCT_CANVAS_INSTANCE: 'SET_PRODUCT_CANVAS_INSTANCE',
  SET_PRODUCT_FABRIC_INSTANCE: 'SET_PRODUCT_FABRIC_INSTANCE',
  SET_ORIENT: 'SET_ORIENT',
  IS_CROP_AREA_MODE: 'IS_CROP_AREA_MODE',
  SET_INTERACTIVE_MODE: 'SET_INTERACTIVE_MODE',
  SET_SVG_LASSO_MODE: 'SET_SVG_LASSO_MODE',
  SET_BORDER_SIZE: 'SET_BORDER_SIZE',
  SET_IS_BORDER_SHAPE: 'SET_IS_BORDER_SHAPE',
  SET_IS_OPTIMIZE_SVG_ON: 'SET_IS_OPTIMIZE_SVG_ON',
  SET_FILL_INTENSITY: 'SET_FILL_INTENSITY',
  SET_IS_PRODUCT_IMAGE: 'SET_IS_PRODUCT_IMAGE',
  SET_IS_COMPRESS_TIFF_ON: 'SET_IS_COMPRESS_TIFF_ON',
  SET_IS_CLIPPED_TIFF: 'SET_IS_CLIPPED_TIFF',
  SET_IS_REMOVE_SHADOW: 'SET_IS_REMOVE_SHADOW',
  SET_EXPORT_SELECTED: 'SET_EXPORT_SELECTED',
}

const ORIENTATION = {
  TALL: 'tall',
  SQUARE: 'square'
}

const module = {
  namespaced: true,
  state: {
    svgInstance: {},
    productInstance: {},
    isSvgInstanceHasSelectedObjects: false,
    isProductInstanceHasObjects: false,
    isProductInstanceHasOneObject: false,
    isProductInstanceHasActiveObjects: false,
    colorToDraw: '#FFFFFF',
    bgImageInstance: null,
    workingArea: null,
    isRectangleMaskMode: false,
    svgCanvasInstance: null,
    isBgPreviewOn: false,
    isPaintBucketOn: false,
    brushSize: 5,
    textMode: false,
    altSelectionMode: false,
    selectionBoxMode: false,
    productCanvasInstance: null,
    orientation: ORIENTATION.TALL,
    isSvgInstanceHasObjects: false,
    isCropAreaMode: false,
    isInteractiveMode: false,
    isSvgLassoMode: false,
    borderSize: 2,
    isBorderShape: false,
    isImageObjectSelected: false,
    isOptimizeSvgOn: true,
    fillIntensity: 5,
    isProductImage: false,
    isCompressTiffFile: false,
    isClippedTiffExport: false,
    isRemoveShadow: false,
    isExportSelected: false,
  },
  getters: {
    getSvgInstance(state) {
      return state.svgInstance;
    },
    getSvgCanvasInstance(state) {
      return state.svgCanvasInstance;
    },
    getProductInstance(state) {
      return state.productInstance;
    },
    getProductCanvasInstance(state) {
      return state.productCanvasInstance;
    },
    isSvgInstanceHasSelectedObjects(state) {
      return state.isSvgInstanceHasSelectedObjects;
    },
    isProductInstanceHasOneObject(state) {
      return state.isProductInstanceHasOneObject;
    },
    isSvgInstanceHasObjects(state) {
      return state.isSvgInstanceHasObjects;
    },
    isProductInstanceHasObjects(state) {
      return state.isProductInstanceHasObjects;
    },
    isProductInstanceHasActiveObjects(state) {
      return state.isProductInstanceHasActiveObjects;
    },
    getColorToDraw(state) {
      return state.colorToDraw;
    },
    getBgImageInstance(state) {
      return state.bgImageInstance;
    },
    isHasBgImageInstance(state) {
      return !!state.bgImageInstance;
    },
    isHasWorkingArea(state) {
      return !!state.workingArea;
    },
    isRecMaskMode(state) {
      return state.isRectangleMaskMode;
    },
    isBgPreviewOn(state) {
      return state.isBgPreviewOn;
    },
    isPaintBucketOn(state) {
      return state.isPaintBucketOn;
    },
    brushSize(state) {
      return state.brushSize;
    },
    textMode(state) {
      return state.textMode;
    },
    isAltSelectionMode(state) {
      return state.altSelectionMode;
    },
    isSelectionBoxMode(state) {
      return state.selectionBoxMode;
    },
    isSquareOrient(state) {
      return state.orientation === ORIENTATION.SQUARE;
    },
    isTallOrient(state) {
      return state.orientation === ORIENTATION.TALL;
    },
    getOrient(state) {
      return state.orientation;
    },
    isCropAreaMode(state) {
      return state.isCropAreaMode;
    },
    isInteractiveMode(state) {
      return state.isInteractiveMode;
    },
    isSvgLassoMode(state) {
      return state.isSvgLassoMode;
    },
    borderSize(state) {
      return state.borderSize;
    },
    isBorderShape(state) {
      return state.isBorderShape;
    },
    isSelectedImageObject(state) {
      return state.isImageObjectSelected;
    },
    isHasSvgXml(state) {
      return !!state.svgCanvasInstance?.modifiedSvgXml;
    },
    isOptimizeSvgOn(state) {
      return state.isOptimizeSvgOn;
    },
    fillIntensity(state) {
      return state.fillIntensity;
    },
    isProductImage(state) {
      return state.isProductImage;
    },
    isCompressTiffFile(state) {
      return state.isCompressTiffFile;
    },
    isClippedTiffExport(state) {
      return state.isClippedTiffExport;
    },
    isRemoveShadow(state) {
      return state.isRemoveShadow;
    },
    isExportSelected(state) {
      return state.isExportSelected;
    },
  },
  mutations: {
    [ACTIONS.SET_SVG_FABRIC_INSTANCE](state, svgInstance) {
      state.svgInstance = svgInstance;
      state.isSvgInstanceHasSelectedObjects = !!svgInstance.getActiveObjects().length;
      state.isSvgInstanceHasObjects = !!svgInstance.getObjects().length;
      state.isImageObjectSelected = svgInstance.getActiveObject()?.type === 'image';
    },
    [ACTIONS.SET_SVG_CANVAS_INSTANCE](state, svgCanvasInstance) {
      state.svgCanvasInstance = svgCanvasInstance;
    },
    [ACTIONS.SET_PRODUCT_FABRIC_INSTANCE](state, productInstance) {
      state.productInstance = productInstance;
      state.isProductInstanceHasObjects = !!productInstance.getObjects().length;
      state.isProductInstanceHasOneObject = productInstance.getObjects().length === 1;
      state.isProductInstanceHasActiveObjects = !!productInstance.getActiveObjects().length;
    },
    [ACTIONS.SET_PRODUCT_CANVAS_INSTANCE](state, canvasInstance) {
      state.productCanvasInstance = canvasInstance;
    },
    [ACTIONS.SET_COLOR_TO_DRAW](state, selectedColor) {
      state.colorToDraw = selectedColor
    },
    [ACTIONS.SET_BG_IMAGE](state, bgImageInstance) {
      state.bgImageInstance = bgImageInstance;
    },
    [ACTIONS.SET_WORKING_AREA](state, workingArea) {
      state.workingArea = workingArea;
    },
    [ACTIONS.SET_REC_MASK_MODE](state, isRectangleMaskMode) {
      state.isRectangleMaskMode = isRectangleMaskMode;
    },
    [ACTIONS.SET_BG_PREVIEW](state, isBgPreviewOn) {
      state.isBgPreviewOn = isBgPreviewOn;
    },
    [ACTIONS.SET_PAINT_BUCKED](state, isPaintBucketOn) {
      state.isPaintBucketOn = isPaintBucketOn;
    },
    [ACTIONS.SET_BRUSH_SIZE](state, brushSize) {
      state.brushSize = brushSize;
    },
    [ACTIONS.SET_TEXT_MODE](state, textMode) {
      state.textMode = textMode;
    },
    [ACTIONS.SET_ALT_SELECTION_MODE](state, alsSelectionMode) {
      state.altSelectionMode = alsSelectionMode;
    },
    [ACTIONS.SET_SELECTION_BOX_MODE](state, selectionBoxMode) {
      state.selectionBoxMode = selectionBoxMode;
    },
    [ACTIONS.SET_ORIENT](state, orient) {
      state.orientation = orient;
    },
    [ACTIONS.IS_CROP_AREA_MODE](state, isCropAreaMode) {
      state.isCropAreaMode = isCropAreaMode;
    },
    [ACTIONS.SET_INTERACTIVE_MODE](state, isInteractiveMode) {
      state.isInteractiveMode = isInteractiveMode;
    },
    [ACTIONS.SET_SVG_LASSO_MODE](state, isSvgLassoMode) {
      state.isSvgLassoMode = isSvgLassoMode;
    },
    [ACTIONS.SET_BORDER_SIZE](state, payload) {
      state.borderSize = payload;
    },
    [ACTIONS.SET_IS_BORDER_SHAPE](state, payload) {
      state.isBorderShape = payload;
    },
    [ACTIONS.SET_IS_OPTIMIZE_SVG_ON](state, payload) {
      state.isOptimizeSvgOn = payload;
    },
    [ACTIONS.SET_FILL_INTENSITY](state, payload) {
      state.fillIntensity = payload;
    },
    [ACTIONS.SET_IS_PRODUCT_IMAGE](state, payload) {
      state.isProductImage = payload;
    },
    [ACTIONS.SET_IS_COMPRESS_TIFF_ON](state, payload) {
      state.isCompressTiffFile = payload;
    },
    [ACTIONS.SET_IS_CLIPPED_TIFF](state, payload) {
      state.isClippedTiffExport = payload;
    },
    [ACTIONS.SET_IS_REMOVE_SHADOW](state, payload) {
      state.isRemoveShadow = payload;
    },
    [ACTIONS.SET_EXPORT_SELECTED](state, payload) {
      state.isExportSelected = payload;
    },
  },
  actions: {
    setColorToDraw({ commit }, selectedColor) {
      commit(ACTIONS.SET_COLOR_TO_DRAW, selectedColor);
    },
    setBgImageInstance({ commit }, bgImageInstance) {
      commit(ACTIONS.SET_BG_IMAGE, bgImageInstance);
    },
    setWorkingArea({ commit }, workingArea) {
      commit(ACTIONS.SET_WORKING_AREA, workingArea);
    },
    setRecMaskMode({ commit }, isRectangleMaskMode) {
      commit(ACTIONS.SET_REC_MASK_MODE, isRectangleMaskMode);
    },
    convertTiffToPng({ commit }, { file, format }) {
      const formData = new FormData();
      formData.append('image', file);

      return new Promise((resolve, reject) => {
        axios.post(`/svg/convert-image?format=${format}`, formData)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    setSvgCanvasInstance({ commit }, svgCanvasInstance) {
      commit(ACTIONS.SET_SVG_CANVAS_INSTANCE, svgCanvasInstance);
    },
    setSvgFabricInstance({ commit }, svgFabricInstance) {
      commit(ACTIONS.SET_SVG_FABRIC_INSTANCE, svgFabricInstance);
    },
    setBgPreviewOn({ commit }, isBgPreviewOn) {
      commit(ACTIONS.SET_BG_PREVIEW, isBgPreviewOn);
    },
    setIsPaintBucketOn({ commit }, isPaintBucketOn) {
      commit(ACTIONS.SET_PAINT_BUCKED, isPaintBucketOn);
    },
    setBrushSize({ commit }, brushSize) {
      commit(ACTIONS.SET_BRUSH_SIZE, brushSize);
    },
    setTextMode({ commit }, textMode) {
      commit(ACTIONS.SET_TEXT_MODE, textMode);
    },
    setAltSelectionMode({ commit }, altSelectionMode) {
      commit(ACTIONS.SET_ALT_SELECTION_MODE, altSelectionMode);
    },
    setSelectionBoxMode({ commit }, selectionBoxMode) {
      commit(ACTIONS.SET_SELECTION_BOX_MODE, selectionBoxMode);
    },
    setProductFabricInstance({ commit }, productFabricInstance) {
      commit(ACTIONS.SET_PRODUCT_FABRIC_INSTANCE, productFabricInstance);
    },
    setProductCanvasInstance({ commit }, productCanvasInstance) {
      commit(ACTIONS.SET_PRODUCT_CANVAS_INSTANCE, productCanvasInstance);
    },
    setOrientation({ commit }, orientation) {
      commit(ACTIONS.SET_ORIENT, orientation);
    },
    setCropAreaMode({ commit }, isCropAreaMode) {
      commit(ACTIONS.IS_CROP_AREA_MODE, isCropAreaMode);
    },
    setInteractiveMode({ commit }, isInteractiveMode) {
      commit(ACTIONS.SET_INTERACTIVE_MODE, isInteractiveMode);
    },
    setSvgLassoMode({ commit }, svgLassoMode) {
      commit(ACTIONS.SET_SVG_LASSO_MODE, svgLassoMode);
    },
    setBorderSize({ commit }, payload) {
      commit(ACTIONS.SET_BORDER_SIZE, payload);
    },
    setIsBorderShape({ commit }, payload) {
      commit(ACTIONS.SET_IS_BORDER_SHAPE, payload);
    },
    setIsOptimizeSvgOn({ commit }, payload) {
      commit(ACTIONS.SET_IS_OPTIMIZE_SVG_ON, payload);
    },
    setFillIntensity({ commit }, payload) {
      commit(ACTIONS.SET_FILL_INTENSITY, payload);
    },
    setIsProductImage({ commit }, payload) {
      commit(ACTIONS.SET_IS_PRODUCT_IMAGE, payload);
    },
    setIsCompressTiffFile({ commit }, payload) {
      commit(ACTIONS.SET_IS_COMPRESS_TIFF_ON, payload);
    },
    setIsClippedTiffExport({ commit }, payload) {
      commit(ACTIONS.SET_IS_CLIPPED_TIFF, payload);
    },
    setIsRemoveShadow({ commit }, payload) {
      commit(ACTIONS.SET_IS_REMOVE_SHADOW, payload);
    },
    setIsExportSelected({ commit }, payload) {
      commit(ACTIONS.SET_EXPORT_SELECTED, payload);
    },
  }
};

export default module;
