var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "save-as-template-modal",
        title: "Save as Template",
        centered: "",
        "ok-title": "Save",
        "ok-disabled": _vm.isSaveDisabled,
      },
      on: { ok: _vm.handleSave, cancel: _vm.closeModal },
    },
    [
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center w-100",
                    },
                    [
                      _c("span", [_vm._v("Name")]),
                      _vm._v(" "),
                      _vm.template?.id
                        ? _c(
                            "small",
                            {
                              staticClass: "text-muted",
                              staticStyle: {
                                "font-size": "12px",
                                "white-space": "nowrap",
                              },
                            },
                            [
                              _vm._v(
                                "\n          Template ID: " +
                                  _vm._s(_vm.template.id) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("b-form-input", {
            model: {
              value: _vm.templateName,
              callback: function ($$v) {
                _vm.templateName = $$v
              },
              expression: "templateName",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Customer" } },
        [
          _c("Multiselect", {
            attrs: {
              "track-by": "id",
              label: "name",
              placeholder: "Select Customer",
              options: _vm.customersList,
            },
            model: {
              value: _vm.selectedCustomer,
              callback: function ($$v) {
                _vm.selectedCustomer = $$v
              },
              expression: "selectedCustomer",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Tags" } },
        [
          _c("Multiselect", {
            attrs: {
              multiple: "",
              taggable: "",
              options: _vm.tags,
              "close-on-select": false,
            },
            on: { tag: _vm.addTag },
            model: {
              value: _vm.selectedTags,
              callback: function ($$v) {
                _vm.selectedTags = $$v
              },
              expression: "selectedTags",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Max File Size (KB)" } },
        [
          _c("b-form-input", {
            attrs: {
              type: "number",
              min: "1",
              placeholder: String(_vm.defaultMaxFileSize),
            },
            model: {
              value: _vm.maxFileSize,
              callback: function ($$v) {
                _vm.maxFileSize = _vm._n($$v)
              },
              expression: "maxFileSize",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "DPI" } },
        [
          _c("b-form-input", {
            attrs: {
              type: "number",
              min: "72",
              placeholder: String(_vm.defaultDpi),
            },
            model: {
              value: _vm.dpi,
              callback: function ($$v) {
                _vm.dpi = _vm._n($$v)
              },
              expression: "dpi",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "File Type" } },
        [
          _c("b-form-select", {
            attrs: { options: _vm.fileTypeOptions },
            model: {
              value: _vm.fileType,
              callback: function ($$v) {
                _vm.fileType = $$v
              },
              expression: "fileType",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-row" }, [
        _c(
          "div",
          { staticClass: "d-flex align-items-center" },
          [
            _c(
              "b-form-checkbox",
              {
                model: {
                  value: _vm.isUpdateExistingTemplate,
                  callback: function ($$v) {
                    _vm.isUpdateExistingTemplate = $$v
                  },
                  expression: "isUpdateExistingTemplate",
                },
              },
              [_vm._v("\n        Update existing template\n      ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }