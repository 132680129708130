<template>
  <div class="product-section">
    <div class="product-input-wrapper">
      <div>
        <SvgFileInput
          ref="fileInput"
          placeholder="Select image file"
          suffix="product-section"
          @on-file-selected="onFileSelected"
          @clear-canvas="clearCanvas"
        />

        <ProductImagesDropdown
          :selected-products="selectedProducts"
          @dropdown-item-click="removeExtension"
          @clear-input-value="clearInputValue"
        />
      </div>
    </div>

    <div class="controls">
      <div
        v-b-tooltip.hover
        class="d-inline-block"
        tabindex="0"
        title="Paste area"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          @click="initPasteArea"
        >
          <b-icon icon="bounding-box" />
        </b-button>
      </div>
      
      <b-button
        :disabled="!isSvgInstanceHasSelectedObjects"
        variant="outline-primary"
        size="sm"
        @click="pasteSelectedSvgInstanceAsImage"
      >
        Paste
      </b-button>
      
      <DownloadBtn
        :disabled="!isProductInstanceHasObjects"
        :canvas="ProductCanvas"
        @load="$emit('load')"
      />

      <b-button
        :disabled="!isProductInstanceHasActiveObjects"
        variant="outline-primary"
        size="sm"
        @click="clearSelections"
      >
        Clear selections
      </b-button>

      <b-button
        :disabled="!isProductInstanceHasObjects"
        variant="outline-primary"
        size="sm"
        @click="resetToInitial"
      >
        Reset
      </b-button>

      <div
        v-b-tooltip.hover
        class="d-inline-block"
        tabindex="0"
        title="Center"
      >
        <b-button
          :disabled="!(isProductInstanceHasOneObject || isProductInstanceHasActiveObjects)"
          variant="outline-primary"
          size="sm"
          @click="centerSelected"
        >
          <b-icon icon="fullscreen" />
        </b-button>
      </div>

      <ShapesDD
        :canvas="ProductCanvas"
      />

      <BrushesDD
        :canvas="ProductCanvas"
      />

      <FillImageColor
        :canvas="ProductCanvas"
      />

      <div
        v-b-tooltip.hover
        class="d-inline-block"
        tabindex="0"
        title="Color dropper"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          @click="eyeDropper"
        >
          <b-icon icon="eyedropper" />
        </b-button>
      </div>

      <div
        v-b-tooltip.hover
        class="d-inline-block"
        tabindex="0"
        title="Color palette"
      >
        <ColorPickerAtom
          :value="getColorToDraw"
          format="hex8"
          @input="setColor"
        />
      </div>

      <div
        v-b-tooltip.hover
        class="d-inline-block"
        tabindex="0"
        title="Fill canvas background"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          @click="fillBackground"
        >
          <b-icon icon="image-fill" />
        </b-button>
      </div>

      <div class="mt-2">
        <div
          v-b-tooltip.hover
          class="d-inline-block"
          tabindex="0"
          title="Text"
        >
          <b-button
            variant="outline-primary"
            size="sm"
            :class="{ 'active': textMode }"
            @click="drawTextRect"
          >
            <b-icon icon="textarea-t" />
          </b-button>
        </div>

        <div
          v-b-tooltip.hover
          class="d-inline-block"
          tabindex="0"
          title="Rotate right 90 deg"
        >
          <b-button
            variant="outline-primary"
            size="sm"
            @click="rotateObject(90)"
          >
            <b-icon icon="arrow-clockwise" />
          </b-button>
        </div>

        <div
          v-b-tooltip.hover
          class="d-inline-block"
          tabindex="0"
          title="Rotate left 90 deg"
        >
          <b-button
            variant="outline-primary"
            size="sm"
            @click="rotateObject(-90)"
          >
            <b-icon icon="arrow-counterclockwise" />
          </b-button>
        </div>

        <div
          v-b-tooltip.hover
          class="d-inline-block"
          tabindex="0"
          title="Layer up"
        >
          <b-button
            :disabled="!isProductInstanceHasActiveObjects"
            variant="outline-primary"
            size="sm"
            @click="bringForward"
          >
            <b-icon icon="arrow-up" />
          </b-button>
        </div>

        <div
          v-b-tooltip.hover
          class="d-inline-block"
          tabindex="0"
          title="Layer down"
        >
          <b-button
            :disabled="!isProductInstanceHasActiveObjects"
            variant="outline-primary"
            size="sm"
            @click="sendBackwards"
          >
            <b-icon icon="arrow-down" />
          </b-button>
        </div>

        <div
          v-b-tooltip.hover
          class="d-inline-block"
          tabindex="0"
          title="Square canvas"
        >
          <b-button
            variant="outline-primary"
            size="sm"
            :class="{ 'active': isSquareOrient }"
            @click="squareOrientation"
          >
            <b-icon icon="square" />
          </b-button>
        </div>

        <div
          v-b-tooltip.hover
          class="d-inline-block"
          tabindex="0"
          title="Tall canvas"
        >
          <b-button
            variant="outline-primary"
            size="sm"
            :class="{ 'active': isTallOrient }"
            @click="tallOrientation"
          >
            <b-icon icon="file" />
          </b-button>

          <b-button
            variant="outline-primary"
            style="display: none"
            size="sm"
            :class="{ 'active': isCropAreaMode }"
            @click="cropAreaMode"
          >
            <b-icon icon="crop" />
          </b-button>
        </div>

        <div
          v-b-tooltip.hover
          tabindex="0"
          title="Fit & Center"
          class="d-inline-block"
        >
          <b-button
            :disabled="!isProductInstanceHasActiveObjects"
            variant="outline-primary"
            size="sm"
            @click="objectFit"
          >
            <b-icon icon="arrows-fullscreen" />
          </b-button>
        </div>

        <div
          v-b-tooltip.hover
          tabindex="0"
          title="Center"
          class="d-inline-block"
        >
          <b-button
            :disabled="!isProductInstanceHasActiveObjects"
            class="action-btn"
            variant="outline-primary"
            size="sm"
            @click="centerSelectedObject"
          >
            <b-icon
              class="icon-arrows-fullscreen"
              icon="aspect-ratio"
            />
          </b-button>
        </div>
        
        <b-button
          variant="outline-primary"
          size="sm"
          :class="{ active: isCropAreaMode }"
          @click="cameraButtonHandler"
        >
          <b-icon icon="camera" />
        </b-button>

        <div
          v-b-tooltip.hover
          tabindex="0"
          title="OCR"
          class="d-inline-block"
        >
          <b-button
            :disabled="!isProductInstanceHasActiveObjects"
            variant="outline-primary"
            size="sm"
            @click="ocr"
          >
            OCR
          </b-button>

          <b-form-input
            v-model="textEditbox"
            class="c-product-section__text-editbox"
            size="sm"
          />
        </div>

        <b-button
          variant="outline-primary"
          size="sm"
          @click="copyHandler"
        >
          <svg
            class="b-icon bi"
            width="1em"
            height="1em"
            fill="currentColor"
          >
            <use href="/img/icons/sprite.svg#copy" />
          </svg>
        </b-button>

        <b-button
          variant="outline-primary"
          size="sm"
          @click="collisionHandler"
        >
          <svg
            class="b-icon bi"
            width="1em"
            height="1em"
            fill="currentColor"
          >
            <use href="/img/icons/sprite.svg#collision" />
          </svg>
        </b-button>
      </div>
      
      <div class="mt-2 mb-2">
        <div
          v-b-tooltip.hover
          class="d-inline-block"
          tabindex="0"
          title="Send to template"
        >
          <b-button
            :disabled="!hasNutritionOrIngredients"
            variant="outline-primary"
            size="sm"
            @click="sendToTemplate"
          >
            Send to template
          </b-button>
        </div>
      </div>
    </div>

    <SvgTextModal
      ref="svgTextModal"
    />
    
    <SvgShapeModal
      ref="svgShapeModal"
    />
    
    <SvgImageTypeModal
      ref="svgImageTypeModal"
      @upload-file="uploadFile"
    />
    
    <SVGPutToTemplateModal
      ref="svgPutToTemplateModal"
    />

    <div
      tabindex="1000"
      class="cancel-outline"
      @keyup="onKeyUp"
      @keydown="onKeyDown"
    >
      <canvas
        ref="productCanvas"
        class="canvas-section cancel-outline"
      />
    </div>
  </div>
</template>

<script>
import ProductCanvas from '../../../services/SVGCanvas/ProductCanvas';
import { mapActions, mapGetters } from 'vuex';
import ShapesDD from './Shapes.vue';
import DownloadBtn from './DownloadBtn.vue';
import BrushesDD from './BrushesDD.vue';
import SvgTextModal from './SvgTextModal.vue';
import {SVGEditorContext} from "../../../contexts/svg-editor-context";
import {SVG_EDITOR_EVENTS} from "../../../services/SVGCanvas/events";
import {checkInstanceBlob, copyToClipboard, getDataURLFromBlob} from '../../../services/helpers';
import { TYPE_IMAGE, TYPE_IMAGE_BG } from "./constants";
import SvgFileInput from './SvgFileInput.vue';
import ColorPickerAtom from "./atoms/ColorPickerAtom.vue";
import {collisionEditBox} from "./helpers";
import { FILE_TYPE, MIME_TYPE } from '../../../constants/file-image-types';
import SvgShapeModal from './SvgShapeModal.vue';
import FillImageColor from './FillImageColor.vue';
import ProductImagesDropdown from './ProductImagesDropdown.vue';
import SvgImageTypeModal from '@frontend/components/modules/svg-editor/SvgImageTypeModal.vue';
import SVGPutToTemplateModal from "@frontend/components/modules/svg-editor/SVGPutToTemplateModal.vue";

const TIFF_MIME = 'image/tiff';
const PNG = 'png';
const SQUARE = 'square'
const TALL = 'tall'

export default {
  name: 'ProductSection',

  components: {
    SVGPutToTemplateModal,
    FillImageColor,
    SvgShapeModal,
    ColorPickerAtom,
    SvgFileInput,
    BrushesDD,
    DownloadBtn,
    ShapesDD,
    SvgTextModal,
    ProductImagesDropdown,
    SvgImageTypeModal,
  },

  data: () => ({
    selectedProducts: [],
    dropdownIsExpanded: false,
    ProductCanvas: null,
    EyeDropper: null,
    colorValue: '#000000',
    textEditbox: '',
  }),

  computed: {
    ...mapGetters('svgEditor', [
      'isSvgInstanceHasSelectedObjects',
      'isProductInstanceHasObjects',
      'isProductInstanceHasActiveObjects',
      'isPaintBucketOn',
      'textMode',
      'getColorToDraw',
      'isSquareOrient',
      'isTallOrient',
      'isProductInstanceHasOneObject',
      'isCropAreaMode',
      'hasNutritionOrIngredients'
    ])
  },

  mounted() {
    this.ProductCanvas = new ProductCanvas(
      this.$refs.productCanvas,
      this.$store,
      window.$context.get(SVGEditorContext)?.height
    );
    this.ProductCanvas.on(SVG_EDITOR_EVENTS.LOAD, this.onFileLoad.bind(this))
    this.ProductCanvas.on(SVG_EDITOR_EVENTS.FILE_LOADED, this.onFileLoaded.bind(this))
    this.ProductCanvas.textModalRef = this.$refs.svgTextModal;
    this.ProductCanvas.shapeModalRef = this.$refs.svgShapeModal;
    this.EyeDropper = new EyeDropper();
    this.fetchLayouts();
    this.fetchUserData();
    this.fetchCustomers();
  },

  methods: {
    ...mapActions('svgEditor', [
      'setColorToDraw',
      'convertTiffToPng',
      'fetchLayouts',
      'fetchUserData',
      'fetchCustomers',
    ]),

    onKeyUp(e) {
      this.ProductCanvas.onKeyUp(e)
    },
    onKeyDown(e) {
      this.ProductCanvas.onKeyDown(e)
    },
    onFileLoaded() {
      this.$emit('loaded')
    },
    onFileLoad() {
      this.$emit('load')
    },

    clearInputValue() {
      this.selectedProducts = [];
      this.clearCanvas();
    },

    removeExtension(file) {
      const fileName = file.name;
      const parts = fileName.split('.');

      if (parts.length) {
        this.clearCanvas();
        this.selectedProducts = [file];
        this.onProductFileSelected(file);
      }
    },

    async getURLFromBlob(file) {
      checkInstanceBlob(file, 'file');

      if (file.type.includes(TIFF_MIME)) {
        toastr.success('Converting TIFF to PNG');

        try {
          const data = await this.convertTiffToPng({ file, format: PNG });

          return data.path;
        } catch(error) {
          console.log(error);
          toastr.error('Converting fail');
        }
      }

      return await getDataURLFromBlob(file)
    },

    async onProductFileSelected(file) {
      if (!file) return;

      this.$emit('load');

      this.ProductCanvas.checkOnProductImage(file.name);

      await this.ProductCanvas.pasteImageFromUrl('/share?file=' + file.path);
    },

    async onFileSelected(file, typeImage = TYPE_IMAGE_BG, props) {
      if (!file) return;

      this.$emit('load');
      this.$refs.svgImageTypeModal.show(file, typeImage, props);
    },

    async uploadFile(file, typeImage = TYPE_IMAGE_BG, props) {
      let fileName = file.name;

      if (props?.fileType.includes(FILE_TYPE.TIFF)) {
        const fileName = `${props.fileName}.${props.fileExt}`;
        file = new File([file], fileName, { type: MIME_TYPE.TIFF });
      }

      if (props) {
        fileName = `${props.fileName}.${props.fileExt}`;
        this.$refs.fileInput.setFileName({ name: fileName });
      }

      const path = await this.getURLFromBlob(file);

      let imageDataDTO = {
        path,
        name: file.name
      };

      this.ProductCanvas.checkOnProductImage(fileName);

      switch (typeImage) {
        case TYPE_IMAGE_BG:
          await this.ProductCanvas.drawImageFromURL(imageDataDTO);
          break;
        case TYPE_IMAGE:
          await this.ProductCanvas.pasteImageFromUrl(path);
          break;
        default:
          throw new Error('No section for provided type image. Provided: ' + typeImage);
      }
    },

    async removeBG() {
      await this.ProductCanvas.removeBG();
    },

    pasteSelectedSvgInstanceAsImage() {
      this.ProductCanvas.pasteSelectedSvgInstanceAsImage();
    },

    clearSelections() {
      this.ProductCanvas.clearSelections();
    },

    bringForward() {
      this.ProductCanvas.bringForward();
    },

    sendBackwards() {
      this.ProductCanvas.sendBackwards();
    },

    resetToInitial() {
      this.ProductCanvas.resetToInitState();
    },

    setColor(hexColor) {
      this.colorValue = hexColor;
      this.setColorToDraw(this.colorValue);
      this.ProductCanvas.changeColorForActiveObject();
    },

    eyeDropper() {
      this.EyeDropper
        .open()
        .then((result) => {
          const { sRGBHex } = result
          this.setColor(sRGBHex);
        })
        .catch(() => {})
    },

    initPasteArea() {
      this.ProductCanvas.pasteAreaMode();
    },

    drawTextRect() {
      this.ProductCanvas.textRectMode();
    },

    showTextModal() {
      this.$refs.svgTextModal.show();
    },

    rotateObject(deg) {
      this.ProductCanvas.rotateSelectedObject(deg);
    },

    fillBackground() {
      this.ProductCanvas.setCanvasBackgroundColor();
    },

    centerSelected() {
      this.ProductCanvas.centerSelected();
    },

    squareOrientation() {
      this.ProductCanvas.setOrientation(SQUARE);
    },

    tallOrientation() {
      this.ProductCanvas.setOrientation(TALL);
    },

    cropAreaMode() {
      this.ProductCanvas.initCropArea();
    },

    saveTo() {
      this.ProductCanvas.saveTo();
    },

    clearCanvas() {
      this.ProductCanvas.clearCanvas();
    },

    async ocr() {
      this.textEditbox = await this.ProductCanvas.convertSelectedToText()
    },

    copyHandler() {
      copyToClipboard(this.textEditbox)
      toastr.success('Copied')
    },

    collisionHandler() {
      this.textEditbox = collisionEditBox(this.textEditbox)
    },

    cameraButtonHandler() {
      this.ProductCanvas.initCropArea();
    },

    objectFit() {
      this.ProductCanvas.objectFitToCanvas();
    },
    
    centerSelectedObject() {
      this.ProductCanvas.centerSelectedObject();
    },

    sendToTemplate() {
      this.$refs.svgPutToTemplateModal.show();
    },
  }
};
</script>

<style scoped lang="scss">
@import "./styles/rounded.scss";

.product-section {
  margin-left: 1px;
}

.canvas-section {
  border: 1px solid #e1e1e1;
}

::v-deep .cancel-outline:focus-visible {
  outline: unset;
}

::v-deep .a-color-picker {
  &__preview {
    width: 29px;
    height: 29px;
    border-radius: initial;
  }

  .color-input-btn {
    width: 31px;
    height: 31px;
    border-radius: 0.2rem !important;
  }
}

.product-input-wrapper {
  position: relative;
  margin-bottom: 5px;
}

.controls {
  margin: 13px 0 0 0;
}

.c-product-section__text-editbox {
  width: 130px;
  display: inline-block;
  vertical-align: middle;
}

.action-btn {
  position: relative;
  width: 35px;
  height: 31px;
  
  .icon-arrows-fullscreen {
    position: absolute;
    left: 4px;
    top: 3px;
    font-size: 178% !important
  }
}
</style>
