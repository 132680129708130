var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "modal" }, [
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "assetModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "assetModalLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-xl", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title",
                    attrs: { id: "assetModalLabel" },
                  },
                  [_vm._v("\n            Asset Files\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button", "aria-label": "Close" },
                    on: { click: _vm.closeHandler },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchKey,
                        expression: "searchKey",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "Search assets..." },
                    domProps: { value: _vm.searchKey },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.searchKey = $event.target.value
                        },
                        _vm.fetchAssets,
                      ],
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.assets, function (asset) {
                    return _c(
                      "div",
                      { key: asset.id, staticClass: "col-md-3 mb-4" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "card h-100",
                            class: {
                              "border-primary": _vm.selectedAsset === asset.id,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleAssetSelection(asset)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "card-img-top mx-auto mt-2",
                              staticStyle: {
                                width: "200px",
                                "object-fit": "cover",
                              },
                              attrs: { src: asset.path, alt: "Asset image" },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "card-footer mt-auto" }, [
                              _c(
                                "p",
                                { staticClass: "card-title mb-0 text-center" },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(asset.name) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.total > _vm.perPage
                  ? _c("nav", [
                      _c(
                        "ul",
                        { staticClass: "pagination" },
                        [
                          _c(
                            "li",
                            {
                              staticClass: "page-item",
                              class: { disabled: _vm.currentPage === 1 },
                              on: {
                                click: function ($event) {
                                  return _vm.changePage(_vm.currentPage - 1)
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { href: "#" },
                                },
                                [_vm._v("Previous")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.totalPages, function (page) {
                            return _c(
                              "li",
                              {
                                key: page,
                                staticClass: "page-item",
                                class: { active: _vm.currentPage === page },
                                on: {
                                  click: function ($event) {
                                    return _vm.changePage(page)
                                  },
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "page-link",
                                    attrs: { href: "#" },
                                  },
                                  [_vm._v(_vm._s(page))]
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              staticClass: "page-item",
                              class: {
                                disabled: _vm.currentPage === _vm.totalPages,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changePage(_vm.currentPage + 1)
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { href: "#" },
                                },
                                [_vm._v("Next")]
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: { click: _vm.closeHandler },
                  },
                  [_vm._v("\n            Close\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", disabled: !_vm.selectedAsset },
                    on: { click: _vm.confirmSelection },
                  },
                  [_vm._v("\n            Confirm Selection\n          ")]
                ),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }