import BuilderTable from './builder';
import { getDataURLFromBlob } from "../../../services/helpers";

const CACHE_FONT_DATA_URL = {};

export async function getFontFaceStyle(fonts = []) {
  let fontFace = '';
  const fontFamilyManager = window.fontFamilyManager;

  if (!fontFamilyManager) return fonts;

  for (const font of fonts) {
    let fontInfo = CACHE_FONT_DATA_URL[font];
    let url = '';

    if (!fontInfo) {
      fontInfo = fontFamilyManager.getFontByIndex(font);

      if (fontInfo) {
        const blob = await fetch(`/fonts/${fontInfo.file}`).then(res => res.blob());
        url = await getDataURLFromBlob(blob);
      }
    } else {
      url = fontInfo.url;
    }

    if (!fontInfo) continue;

    fontFace += `@font-face{ font-family: ${fontInfo.index}; font-weight: ${fontInfo.weight}; src: url("${url}");}`
    CACHE_FONT_DATA_URL[font] = {
      ...fontInfo,
      url
    };
  }

  return fontFace;
}


function initDataBuilderCells(cells = []) {
  return cells.map(_cell => {
    const cell = new BuilderTable.TableCell();
    const styles = new BuilderTable.Styles();

    styles.setRules(_cell.styles.rules);
    
    if (!_cell.styles.rules?.['white-space']) {
      styles.setRule('white-space', 'pre-line');
    }

    cell.setId(_cell.id);
    cell.setStyles(styles);
    cell.setValue(_cell.value);
    cell.setColspan(_cell.colspan);

    return cell;
  });
}

export function initDataBuilderTable(data) {
  if (!data) return new BuilderTable.Table();

  const table = new BuilderTable.Table();
  const styles = new BuilderTable.Styles();

  styles.setRules(data.styles.rules);

  table.setId(data.id);
  table.setStyles(styles);
  table.setHeaderStyles(data.headerRowStyles?.rules);

  const headers = initDataBuilderCells(data.headers);
  
  if (!Array.isArray(data.rows)) return table;
  const rows = data.rows.map(_row => {
    const row = new BuilderTable.TableRow();
    const rowStyles = Array.isArray(_row.styles.rules) ? {} : _row.styles.rules;
    const styles = new BuilderTable.Styles(rowStyles);

    styles.setRules(styles.rules);

    row.setId(_row.id);
    row.setStyles(styles);
    row.setCells(initDataBuilderCells(_row.cells));

    return row;
  });

  table.setHeaders(headers);
  table.setRows(rows);

  return table;
}

export function groupConnectedCells(rows, selectedCellsInRows) {
  const getKey = (row, col) => `${row}-${col}`;
  const selectedKeys = new Set();
  const visited = new Set();

  // Create a set of all keys of the selected cells
  selectedCellsInRows.forEach((row) => {
    row.forEach((cell) => {
      rows.forEach((r, ri) => {
        const colIndex = r.findIndex((c) => c.id === cell.id);
        
        if (colIndex !== -1) {
          selectedKeys.add(getKey(ri, colIndex));
        }
      });
    });
  });

  const groups = [];

  function dfs(row, col, group) {
    const key = getKey(row, col);
    if (!selectedKeys.has(key) || visited.has(key)) return;

    visited.add(key);
    group.push([row, col]); // Store cell position

    // Check neighbor cells
    const directions = [
      [0, 1],  // Left
      [0, -1], // Right
      [1, 0],  // Down
      [-1, 0]  // Up
    ];

    directions.forEach(([dr, dc]) => {
      const newRow = row + dr;
      const newCol = col + dc;
      const newKey = getKey(newRow, newCol);
      
      if (rows[newRow] && rows[newRow][newCol] && selectedKeys.has(newKey) && !visited.has(newKey)) {
        dfs(newRow, newCol, group);
      }
    });
  }

  rows.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {
      const key = getKey(rowIndex, colIndex);
      
      if (selectedKeys.has(key) && !visited.has(key)) {
        const group = [];
        dfs(rowIndex, colIndex, group);
        groups.push(group);
      }
    });
  });


  // Group the found cells into rows
  return groups.map((group) => {
    const rowMap = new Map();
    
    group.forEach(([row, col]) => {
      if (!rowMap.has(row)) rowMap.set(row, []);
      
      rowMap.get(row).push(rows[row][col]);
    });

    // Sort each row by column order
    return Array.from(rowMap.entries()).map(([row, rowCells]) =>
      rowCells.sort((a, b) => {
        const colA = rows[row].findIndex((cell) => cell.id === a.id);
        const colB = rows[row].findIndex((cell) => cell.id === b.id);
        return colA - colB;
      })
    );
  });
}
