<template>
  <b-modal
    :id="MODAL_ID"
    ok-title="Import"
    cancel-title="Close"
    class="import-mappings-modal"
    size="xl"
    @close="handleClose"
    @ok="handleOk"
  >
    <template #modal-title>
      Import settings{{ file ? `: ${file.name}` : '' }}
    </template>

    <div
      class="import-mappings-modal__container"
      @dragover.prevent="handleDragOver"
      @dragleave.prevent="handleDragLeave"
      @drop.prevent="handleDrop"
    >
      <div
        v-if="isDragZoneVisible"
        class="import-mappings-modal__drop-zone"
        :class="{'import-mappings-modal__drop-zone--drag-over': isDragOver}"
      >
        <p class="import-mappings-modal__drop-zone-text">
          Drag & Drop a JSON file
        </p>
        <input
          ref="fileInput"
          type="file"
          accept="application/json"
          hidden
          @change="handleFile"
        >
      </div>
      <div
        v-if="mappingsData"
        class="d-flex flex-column"
        style="gap: 8px"
      >
        <table>
          <thead>
            <tr>
              <th
                scope="col"
                class="pb-2"
              >
                Name
              </th>
              <th
                scope="col"
                class="pb-2"
              >
                Template(s)
              </th>
            </tr>
          </thead>
          <tbody>
            <ImportMappingsDataRow
              v-for="mappingData in paginatedData"
              :id="mappingData.id"
              :key="mappingData.id"
              :name="mappingData.name"
              :templates="mappingData.templates"
              :selected="!isMappingUnselected(mappingData.id)"
              :is-same-environment="isSameEnvironment"
              :customers="customers"
              @select="onDataRowSelect"
              @customerChange="onDataRowCustomerChange"
              @templateMappingsChange="onDataRowTemplatesChange"
            />
          </tbody>
        </table>

        <div class="d-flex justify-content-between my-4 align-items-center">
          <div class="font-weight-bold">
            {{ mappingsData.data.length - unselectedMappings.length }} / {{ mappingsData.data.length }}
          </div>
          <div
            class="d-flex"
            style="gap: 8px"
          >
            <button
              class="btn btn-primary"
              @click="unselectAll"
            >
              Unselect all
            </button>
            <button
              class="btn btn-primary"
              @click="selectAll"
            >
              Select all
            </button>
          </div>
        </div>

        <div class="d-flex justify-content-center mt-3">
          <b-pagination
            v-if="totalPages > 1"
            v-model="page"
            :total-rows="mappingsData.data.length"
            :per-page="PER_PAGE"
            size="sm"
            align="center"
          />
        </div>
      </div>
    </div>

    <template #modal-footer="{ cancel, ok }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        :disabled="!isImportAvailable"
        variant="primary"
        @click="ok()"
      >
        <b-spinner
          v-if="isImporting"
          variant="light"
          small
          label="Spinning"
          class="mr-1"
        />
        Import
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {importMappingsEventBroker, IMPORT_MAPPINGS_EVENTS} from "@frontend/group/modules/import-mappings/event-broker";
import ImportMappingsDataRow from './ImportMappingsDataRow.vue';
import Vue from 'vue';

const PER_PAGE = 10;
const MODAL_ID = 'import-mappings-modal';

let debounceTimer = undefined;

export default {
  components: {ImportMappingsDataRow},
  data() {
    return {
      MODAL_ID,
      PER_PAGE,
      file: null,
      mappingsData: null,
      page: 1,
      unselectedMappings: [],
      mappedMappings: [],
      isDragOver: false,
      isImporting: false,
      environment: window.location.hostname.split('.')[0],
      customers: [],
    };
  },
  computed: {
    paginatedData() {
      if (!this.mappingsData) {
        return []
      }

      const start = (this.page - 1) * PER_PAGE;
      const end = start + PER_PAGE;

      return this.mappingsData.data.slice(start, end);
    },
    totalPages() {
      if (!this.mappingsData) return 0;
      return Math.ceil(this.mappingsData.data.length / PER_PAGE);
    },
    isImportInOtherEnvironmentAvailable() {
      if (this.isSameEnvironment) {
        return false;
      }

      const unmappedMappings = this.mappingsData.data.filter(mapping => {
        const mappedMapping = this.mappedMappings.find(_mappedMapping => _mappedMapping.id === mapping.id);

        if (!mappedMapping) {
          return true;
        }

        const originalMappingTemplatesCount = mapping.templates.length;
        const mappedMappingTemplatesCount = mappedMapping.templates.filter(t => t.newTemplateId !== null).length;

        return originalMappingTemplatesCount !== mappedMappingTemplatesCount;
      });

      return this.unselectedMappings.length === unmappedMappings.length;
    },
    isImportAvailable() {
      return this.mappingsData
          && this.mappingsData.data.length > this.unselectedMappings.length
          && (this.isSameEnvironment || this.isImportInOtherEnvironmentAvailable)
          && !this.isImporting;
    },
    isDragZoneVisible() {
      return this.isDragOver || !this.mappingsData;
    },
    isSameEnvironment() {
      return this.mappingsData?.environment === this.environment;
    }
  },
  mounted() {
    importMappingsEventBroker.on(IMPORT_MAPPINGS_EVENTS.INIT, this.init);
  },
  methods: {
    async init() {
      this.$bvModal.show(MODAL_ID);
      this.customers = (await axios.get('/banner/customers')).data;
    },
    handleDrop(event) {
      const files = event.dataTransfer.files;

      if (!files.length || !files[0].type === "application/json") {
        return;
      }

      this.reset();
      this.file = files[0];
      this.readFile(files[0]);
      this.handleDragLeave();
    },
    handleFile(event) {
      const file = event.target.files[0];
      if (file && file.type === "application/json") {
        this.file = file;
        this.readFile(file);
      }
    },
    readFile(file) {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const mappingsData = JSON.parse(reader.result);

          if (!mappingsData?.environment || !mappingsData?.data || !Array.isArray(mappingsData.data)) {
            toastr.error('Invalid data: missing environment or data');
            return;
          }

          this.mappingsData = mappingsData;
          this.page = 1;
        } catch (error) {
          console.error("Invalid JSON file:", error);
        }
      };
      reader.readAsText(file);
    },
    reset() {
      this.mappingsData = null;
      this.page = 1;
      this.file = null;
      this.unselectedMappings = [];
      this.mappedMappings = [];
      this.isDragOver = false;
      this.isImporting = false;
    },
    selectAll() {
      this.unselectedMappings = [];
    },
    unselectAll() {
      if (!this.mappingsData) {
        return;
      }

      this.unselectedMappings = this.mappingsData.data.map(mapping => mapping.id);
    },
    getMappingsForImport() {
      if (!this.mappingsData) {
        return [];
      }

      const selectedMappings = this.mappingsData.data.filter(mappingData => !this.isMappingUnselected(mappingData.id));

      return this.isSameEnvironment ? selectedMappings : this.getMappedMappings(selectedMappings)
    },
    isMappingUnselected(id) {
      return this.unselectedMappings.some(unselectedMappingId => unselectedMappingId === id);
    },
    handleDragOver() {
      this.isDragOver = true;
      clearTimeout(debounceTimer);
    },
    handleDragLeave() {
      debounceTimer = setTimeout(() => {
        this.isDragOver = false;
      }, 50)
    },
    getMappedMappings(mappings) {
      return mappings.map(mapping => {
        const mappedMapping = this.mappedMappings.find(_mappedMapping => _mappedMapping.id === mapping.id);

        if (!mappedMapping) {
          throw new Error('Mapped mapping not found')
        }

        return {
          ...mapping,
          templates: mapping.templates.map(template => {
            const mappedTemplate = mappedMapping.templates.find(_mappedTemplate => _mappedTemplate.oldTemplateId === template.template_id);

            if (!mappedTemplate || !mappedTemplate.newTemplateId) {
              throw new Error('Mapped template not found')
            }

            return {
              ...template,
              template_id: mappedTemplate.newTemplateId,
            }
          })
        }
      })
    },
    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();

      if (!this.isImportAvailable) {
        return;
      }

      const mappingsForImport = this.getMappingsForImport();

      if (mappingsForImport.length === 0) {
        return;
      }

      try {
        this.isImporting = true;
        await axios.post('/import-mappings/import', mappingsForImport);
        importMappingsEventBroker.fire(IMPORT_MAPPINGS_EVENTS.IMPORTED);
        toastr.success('Settings imported successfully')
        this.$bvModal.hide(MODAL_ID);
        this.handleClose();
      } catch (e) {
        console.error(e);
        toastr.error('Something went wrong.');
        this.isImporting = false;
      }
    },
    handleClose() {
      this.reset();
    },
    onDataRowSelect({id}) {
      const existingUnselectedMappingIndex = this.unselectedMappings.findIndex(unselectedMappingId => unselectedMappingId === id);

      if (existingUnselectedMappingIndex === -1) {
        this.unselectedMappings.push(id);
      } else {
        this.unselectedMappings.splice(existingUnselectedMappingIndex, 1);
      }
    },
    async onDataRowCustomerChange(customerId) {
      const customer = this.customers.find(customer => customer.id === customerId);

      if (!customer) {
        return;
      }

      if (customer.templates) {
        return;
      }

      const response = await axios.post(`/banner/get-templates/${customerId}`);
      customer.templates = response.data;

      const customerIndex = this.customers.findIndex(_customer => _customer.id === customerId);

      if (customerIndex >= 0) {
        Vue.set(this.customers, customerIndex, customer);
      }
    },
    onDataRowTemplatesChange({mappingId, templateMappings}) {
      const mappedMapping = this.mappedMappings.find(mappedMapping => mappedMapping.id === mappingId);

      if (mappedMapping) {
        mappedMapping.templates = templateMappings;
      } else {
        this.mappedMappings.push({
          id: mappingId,
          templates: templateMappings
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#import-mappings-modal___BV_modal_outer_ {
  z-index: 1051 !important;
}

.import-mappings-modal {
  &__container {
    position: relative;
    min-height: 300px;
  }

  &__drop-zone {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #d3d3d3;
    opacity: 50%;

    &:hover {
      background-color: #eff0f1;
    }

    &--drag-over {
      border-color: blue;
    }
  }

  &__drop-zone-text {
    color: #ababab;
    font-size: 36px;
  }

  &__file-name {
    color: black;
    font-size: 24px;
  }
}
</style>
