<template>
  <b-dropdown
    variant="outline-primary"
    text="Actions"
    right
    dropup
    boundary="window"
    class="a-selected-actions-dropdown"
    toggle-class="a-selected-actions-dropdown__toggle"
  >
    <b-dropdown-item
      link-class="a-selected-actions-dropdown__item"
      @click="handleAction(MODALS.CROP)"
    >
      <svg class="a-selected-actions-dropdown__item--icon">
        <use href="/img/icons/sprite.svg#file-crop-svg" />
      </svg>
      Crop to image
    </b-dropdown-item>
    <b-dropdown-item
      link-class="a-selected-actions-dropdown__item"
      @click="handleAction(MODALS.REMOVE_BACKGROUND)"
    >
      <i class="fas fa-images a-selected-actions-dropdown__item--icon" />
      Remove Background
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import {MUTATIONS} from "@frontend/store/file-browser-product-selector";
import { MODALS } from "./constants";
import {createModal} from "./modals/action-creater";

const { mapMutations, mapGetters } = createNamespacedHelpers('fileBrowserProductSelector');

export default {
  name: "SelectedActionsDropdown",
  computed: {
    ...mapGetters(['firstSelectedItem', 'selectedItems']),
    MODALS() {
      return MODALS;
    }
  },
  methods: {
    ...mapMutations([MUTATIONS.SET_MODAL]),
    handleAction(modalType) {
      this[MUTATIONS.SET_MODAL](createModal(modalType))
    }
  }
}
</script>

<style scoped lang="scss">
  .a-selected-actions-dropdown {
    ::v-deep &__toggle {
      color: #007964;
      border-color: #007964!important;
      border-radius: 8px!important;

      &:hover, &:not(:disabled):not(.disabled):active {
        background-color: #007964;
        color: white;
      }

      &:focus, &:not(:disabled):not(.disabled):active:focus {
        box-shadow: 0 0 0 0.2rem rgba(32, 156, 134, 0.5);
        border-color: #007964;
      }
    }

   ::v-deep &__item {
     padding: 12px 16px;

     &--icon {
       width: 20px;
       height: 20px;
     }
   }
  }
</style>

<style>
.a-selected-actions-dropdown.show .a-selected-actions-dropdown__toggle {
  background-color: #007964;
}
</style>
