<template>
  <div class="c-selected-actions d-flex align-items-end flex-column">
    <div>
      <SelectedActionsDropdown v-if="isActionsAvailable" />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import SelectedActionsDropdown from "./SelectedActionsDropdown.vue";
import { IMAGE_FILE_TYPES } from "./constants";

const { mapGetters } = createNamespacedHelpers('fileBrowserProductSelector');


export default {
  name: "SelectionActionsComponent",
  components: {SelectedActionsDropdown},
  computed: {
    ...mapGetters([
        'selectedItems',
    ]),
    isActionsAvailable() {
      return this.selectedItems.length > 0 && !this.selectedItems.some(item => !IMAGE_FILE_TYPES.includes(item.type));
    }
  },
}
</script>

<style scoped lang="scss">

</style>
