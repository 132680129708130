<template>
  <div>
    <b-modal
      id="brand_colors_modal"
      class="brand_colors_modal"
      title="Brand Colors"
      centered
      no-close-on-backdrop
      @hide="close"
    >
      <div>
        <div class="col-12">
          <b-form-group label="Brand">
            <b-form-input
              v-model="brand.name"
              type="text"
            />
          </b-form-group>
        </div>
        
        <div class="col-12">
          <b-form-group label="Variant">
            <b-form-input
              v-model="brand.variant"
              type="text"
            />
          </b-form-group>
        </div>

        <h5 class="text-center">
          {{ colors.primary.title }}
        </h5>
        <div class="d-flex align-items-center color-inputs">
          <b-form-group 
            label="CMYK" 
            class="mr-3"
          >
            <b-form-input
              v-model="colors.primary.cmyk"
              type="text"
              @input="onCMYKUpdate('primary')"
            />
          </b-form-group>
          <b-form-group 
            label="RGB" 
            class="mr-3"
          >
            <b-form-input
              v-model="colors.primary.rgb"
              type="text"
              @input="onRGBUpdate('primary')"
            />
          </b-form-group>
          <b-form-group label="HEX">
            <b-form-input
              v-model="colors.primary.hex"
              type="text"
              @input="onHEXUpdate('primary')"
            />
          </b-form-group>
        </div>
      </div>
      <div class="col-12">
        <label>Color Preview</label>
        <div 
          class="color-preview"
          :style="{ backgroundColor: colors.primary.fill }"
        />
      </div>

      <h5 class="text-center mt-3">
        {{ colors.secondary.title }}
      </h5>
      <div class="d-flex align-items-center color-inputs">
        <b-form-group
          label="CMYK"
          class="mr-3"
        >
          <b-form-input
            v-model="colors.secondary.cmyk"
            type="text"
            @input="onCMYKUpdate('secondary')"
          />
        </b-form-group>
        <b-form-group
          label="RGB"
          class="mr-3"
        >
          <b-form-input
            v-model="colors.secondary.rgb"
            type="text"
            @input="onRGBUpdate('secondary')"
          />
        </b-form-group>
        <b-form-group label="HEX">
          <b-form-input
            v-model="colors.secondary.hex"
            type="text"
            @input="onHEXUpdate('secondary')"
          />
        </b-form-group>
      </div>
      <div class="col-12">
        <label>Color Preview</label>
        <div
          class="color-preview"
          :style="{ backgroundColor: colors.secondary.fill }"
        />
      </div>

      <template #modal-footer>
        <div>
          <button
            class="btn btn-secondary"
            @click="close"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary"
            @click="onSave"
          >
            Save
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BRAND_COLORS_API from '@frontend/services/api/brand-colors';
import {EVENTS, brandColorsEventBroker} from "@frontend/group/modules/brand-colors/event-broker";
import {
  getPrimarySecondaryColors,
  cmykToRgb,
  hexToRgb,
  rgbToCmyk, getBrandVariant, updateLayoutVariantBrand,
} from "@frontend/group/modules/brand-colors/helper";
import {rgbToHex} from "@frontend/lib/utils/color";

export default {
  name: 'BrandColorsModal',

  props: {},

  data: () => ({
    instnceId: null,
    brand: {
      name: '',
      variant: '',
    },
    colors: {
      primary: {
        title: 'Primary Color',
        fill: '#000000',
        hex: '#000000',
        cmyk: '#000000',
        rgb: '#000000',
      },
      secondary: {
        title: 'Secondary Color',
        fill: '#000000',
        hex: '#000000',
        cmyk: '#000000',
        rgb: '#000000',
      } 
    },
  }),

  computed: {},

  async mounted() {
    brandColorsEventBroker.on(EVENTS.INIT, this.show.bind(this));
  },

  methods: {
    show(data) {
      this.initData(data);
      this.$bvModal.show('brand_colors_modal');
    },

    close() {
      this.$bvModal.hide('brand_colors_modal');
    },

    initData(data) {
      const { instance_id } = data;
      this.instnceId = instance_id;
      const currentBrand = getBrandVariant(instance_id);
      const currentBrandColors = getPrimarySecondaryColors(instance_id);
      this.initColors(currentBrandColors);
      this.initBrandVariant(currentBrand);
    },

    initColors(colors) {
      this.colors.primary = {
        ...colors.primary,
        rgb: hexToRgb(colors.primary.fill).join(','),
        cmyk: rgbToCmyk(hexToRgb(colors.primary.fill)).join(','),
        hex: colors.primary.fill,
      };
      this.colors.secondary = {
        ...colors.secondary,
        rgb: hexToRgb(colors.secondary.fill).join(','),
        cmyk: rgbToCmyk(hexToRgb(colors.secondary.fill)).join(','),
        hex: colors.secondary.fill,
      };
    },
    
    initBrandVariant(currentBrand) {
      this.brand = { ...currentBrand.brand };
    },

    onHEXUpdate(colorKey) {
      const color = this.colors[colorKey];
      const rgb = hexToRgb(color.hex);
      color.rgb = rgb.join(',');
      color.cmyk = rgbToCmyk(rgb).join(',');
      color.fill = color.hex;
    },

    onRGBUpdate(colorKey) {
      const color = this.colors[colorKey];
      const rgb = color.rgb.split(',').map(Number);
      color.hex = rgbToHex(rgb);
      color.cmyk = rgbToCmyk(rgb).join(',');
      color.fill = color.hex;
    },

    onCMYKUpdate(colorKey) {
      const color = this.colors[colorKey];
      const cmyk = color.cmyk.split(',').map(Number);
      const rgb = cmykToRgb(cmyk);
      color.rgb = rgb.join(',');
      color.hex = rgbToHex(rgb);
      color.fill = color.hex;
    },

    async checkDuplicateAndConfirm(brandColorData) {
      try {
        const { data } = await BRAND_COLORS_API.checkDuplicate(brandColorData);

        if (data?.existing) {
          const result = await Swal.fire({
            title: 'Duplicate Found',
            text: 'Brand and Variant already exist in color table. Replace?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            icon: 'warning',
            width: '34em',
          });

          return result.isConfirmed;
        }

        return true;
      } catch (e) {
        console.error('Error checking duplicate:', e);
        toastr.error('There was an error checking for duplicates.');
        return false;
      }
    },

    async onSave() {
      try {
        const brandColorData = {
          brand: this.brand.name,
          variant: this.brand.variant,
          primary: this.colors.primary.fill,
          secondary: this.colors.secondary.fill,
        };

        const shouldProceed = await this.checkDuplicateAndConfirm(brandColorData);

        if (shouldProceed) {
          await BRAND_COLORS_API.storeColors(brandColorData);
          updateLayoutVariantBrand(this.instnceId, this.brand.name, this.brand.variant);
          toastr.success('Brand colors have been updated successfully');
          this.close();
        }
      } catch (e) {
        console.error(e)
        toastr.error('There was an error updating brand colors');
        this.close();
      }
    },
  }
};
</script>

<style lang="scss">
.color-inputs {
  padding: 0 15px;
}

.color-preview {
  width: 100%;
  height: 38px;
  border-radius: 3px;
}
</style>
