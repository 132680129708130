var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { staticClass: "d-flex align-items-center" },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "primary" },
              on: { click: _vm.extendContainer },
            },
            [_vm._v("\n      Extend container\n    ")]
          ),
          _vm._v(" "),
          _c("i", {
            staticClass: "c-icon cil-settings ml-2",
            on: { click: _vm.toggleConfig },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isExtended
        ? _c(
            "b-col",
            { staticClass: "mt-2", attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-form-select",
                    {
                      model: {
                        value: _vm.isEnabled,
                        callback: function ($$v) {
                          _vm.isEnabled = $$v
                        },
                        expression: "isEnabled",
                      },
                    },
                    [
                      _c("b-form-select-option", { attrs: { value: "true" } }, [
                        _vm._v("\n          Enabled\n        "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-form-select-option",
                        { attrs: { value: "false" } },
                        [_vm._v("\n          Disabled\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Container name" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.containerName,
                              callback: function ($$v) {
                                _vm.containerName = $$v
                              },
                              expression: "containerName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Margin" } },
                        [
                          _c("b-form-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.margin,
                              callback: function ($$v) {
                                _vm.margin = $$v
                              },
                              expression: "margin",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }