<template>
  <div class="chat-module d-flex flex-column">
    <nav class="nav nav-pills nav-fill">
      <a
        class="nav-link"
        :class="{ 'active': selectedTab === 'ai-editor' }"
        href="javascript:void(0);"
        @click="selectedTab = 'ai-editor'"
      >AI Editor</a>
      <a
        class="nav-link"
        :class="{ 'active': selectedTab === 'ai-analyst' }"
        href="javascript:void(0);"
        @click="selectedTab = 'ai-analyst'"
      >AI Analyst</a>
    </nav>
    <div class="d-flex flex-column flex-grow-1 pt-2">
      <AiEditorTab
        :hidden="selectedTab !== 'ai-editor'"
        :target-element="targetElement"
        :is-tab-active="isTabActive"
      />
      <AiAnalystTab
        :hidden="selectedTab !== 'ai-analyst'"
        :target-element="targetElement"
        :is-tab-active="isTabActive"
      />
    </div>
  </div>
</template>

<script>
    import AiEditorTab from "./components/AiEditorTab.vue";
    import AiAnalystTab from "./components/AiAnalystTab.vue";

    export default {
        name: "AiChatModule",
        components: {
            AiEditorTab,
            AiAnalystTab,
        },
        props: {
            targetElement: {
                type: fabric.Object,
                require: true,
                default: () => {},
            },
            isTabActive: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            selectedTab: "ai-editor",
        }),
    };
</script>
