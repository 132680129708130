import axios from "axios";
import {deepCopy} from "../../utils";
import MediaService from './media';
import {EVENTS as PSD_LAYERS_EVENT, EVENTS, psdLayersEventBroker} from "../../group/modules/psd-layers/event-broker";
import {PARSED_FIELD_TYPES} from "@/includes/parse_psd_data/constants";
import {extractLayersFromTree} from "@frontend/group/modules/psd-layers/helpers";
import {FIELD_TYPE_BACKGROND_THEME_IMAGE} from "@frontend/constants/type-fields-of-template";

const DEFAULT_OPTIONS = {
    isImportWithoutLayers: false,
}

export class ApplyStrategy {
    static FLOWS = {
        SAVE_TO_FILE_BROWSER: 'SAVE_TO_FILE_BROWSER',
        ADD_TO_TEMPLATE: 'ADD_TO_TEMPLATE',
        CREATE_TEMPLATE_FROM_ADMIN: 'CREATE_TEMPLATE_FROM_ADMIN',
        UPDATE_PREVIEW_TEMPLATE: 'UPDATE_PREVIEW_TEMPLATE',
    }
    // TODO should be a structure to select specific action
    actions = [];
    state;
    commit;
    options = DEFAULT_OPTIONS;

    constructor(module, options = {}) {
        this.state = deepCopy(module.state);
        this.state.layersByTemplate = module.getters.layers;
        this.commit = module.commit;
        this.options = { ...this.options, ...options };

        this.FLOW_HANDLERS = {
            [ApplyStrategy.FLOWS.ADD_TO_TEMPLATE]: this.addToTemplateFlow.bind(this),
            [ApplyStrategy.FLOWS.SAVE_TO_FILE_BROWSER]: this.saveToFileBrowser.bind(this),
            [ApplyStrategy.FLOWS.CREATE_TEMPLATE_FROM_ADMIN]: this.createTemplateFormAdmin.bind(this),
            [ApplyStrategy.FLOWS.UPDATE_PREVIEW_TEMPLATE]: this.updatePreviewTemplateFlow.bind(this)
        }
    }

    getSelectedLayers(fileStateIndex) {
        const fileState = this.state.files[fileStateIndex];

        if (!fileState) {
            return;
        }

        return extractLayersFromTree(fileState.templates)
    }

    getDefaultLayer(file) {
        const data = {
            type: PARSED_FIELD_TYPES.BACKGROUND_IMAGE,
            left: 0,
            top: 0,
            height: file.parsedPsdModel.height,
            width: file.parsedPsdModel.width,
            src: file.entireImage,
            name: 'Background Theme Image',
            visible: true,
            opacity: 1,
            blendingMode: "normal",
            ancestors: []
        }
        return {
            ...data,
            data: {
                ...data,
                fieldType: FIELD_TYPE_BACKGROND_THEME_IMAGE,
                publicPath: file.entireImage,
                scaleX: 1,
                scaleY: 1,
            }
        }
    }

    add(action) {
        this.actions.push(action);
    }

    all() {
        return new Promise(resolve => {
            Promise.all(this.actions).then(() => {
                // TODO find close action and attache to
                if (this.actions.length) this.actions[0].then(resolve)
            })
        })
    }

    build(flow) {

        if (!this.FLOW_HANDLERS[flow]) {
            throw new Error('unhandled flow');
        }

        this.FLOW_HANDLERS[flow]();

        return this;
    }

    async addToTemplateFlow() {
        const closeAction = new Promise(resolve => {
            const closeListener = () => {
                resolve();
                psdLayersEventBroker.off(PSD_LAYERS_EVENT.CLOSE, closeListener);
                psdLayersEventBroker.off(PSD_LAYERS_EVENT.CLOSE_AND_RELOAD, closeAndReloadListener);
            }
            const closeAndReloadListener = () => {
                resolve(true);
                psdLayersEventBroker.off(PSD_LAYERS_EVENT.CLOSE, closeListener);
                psdLayersEventBroker.off(PSD_LAYERS_EVENT.CLOSE_AND_RELOAD, closeAndReloadListener);
            }

            psdLayersEventBroker.on(PSD_LAYERS_EVENT.CLOSE, closeListener);
            psdLayersEventBroker.on(PSD_LAYERS_EVENT.CLOSE_AND_RELOAD, closeAndReloadListener);
        });

        this.add(closeAction);

        this.savePSDs();

        const filesDto = this.state.files.map((file, index) => ({
            templates: file.templates.filter(template => template.visible),
            layers: !this.options.isImportWithoutLayers ? this.getSelectedLayers(index) : [this.getDefaultLayer(file)],
            isTreatMultipleSpaces: file.isTreatMultipleSpacesAsSingle,
            originalFileName: file.originalFileName,
            isReplace: file.isReplaceExistingProduct,
            isRemovePlaceholderText: file.isRemovePlaceholderText,
            isUseSourceTextSettings: file.isUseSourceTextSettings,
            isUseProductImagesFromPSD: file.isUseProductImagesFromPSD,
            isImportWithoutLayers: this.options.isImportWithoutLayers,
        }));

        psdLayersEventBroker.fire(EVENTS.FILL_TEMPLATE, filesDto)
    }

    updatePreviewTemplateFlow() {
        // TODO: It may be necessary to prepare the state of all files

        const fileState = this.state.files[this.state.currentPreviewIndex];

        if (!fileState) {
            return;
        }

        const updatedPreviewTemplateAction = new Promise(resolve => {
            const updatedPreviewTemplateListener = (event) => {
                resolve(event);
                psdLayersEventBroker.off(PSD_LAYERS_EVENT.UPDATED_PREVIEW_TEMPLATE, updatedPreviewTemplateListener);
            }

            psdLayersEventBroker.on(PSD_LAYERS_EVENT.UPDATED_PREVIEW_TEMPLATE, updatedPreviewTemplateListener);
        });

        this.add(updatedPreviewTemplateAction);
        
        psdLayersEventBroker.fire(EVENTS.UPDATE_PREVIEW_TEMPLATE, {
            templates: fileState.selectedTemplates,
            layers: this.getSelectedLayers(this.state.currentPreviewIndex),
            isTreatMultipleSpaces: fileState.isTreatMultipleSpacesAsSingle,
            originalFileName: fileState.originalFileName,
        });
    }

    collectPsdDataToStore(fileState) {
        return {
            entireImageThumbnail: fileState.entireImageThumbnail,
            layers: fileState.layers,
            parsePsdData: fileState.parsedPsdModel
        }
    }

    saveToFileBrowser() {
        const fileState = this.state.files[this.state.currentPreviewIndex];

        if (!fileState) {
            return;
        }

        const savedToFileBrowserAction = new Promise(resolve => {
            const savedToFileBrowserListener = (event) => {
                psdLayersEventBroker.off(PSD_LAYERS_EVENT.SAVE_PSD_ASSETS_TO_FOLDER_END, savedToFileBrowserListener);

                resolve({ success: event, index: this.state.currentPreviewIndex });
            }

            psdLayersEventBroker.on(PSD_LAYERS_EVENT.SAVE_PSD_ASSETS_TO_FOLDER_END, savedToFileBrowserListener);
        });

        this.add(savedToFileBrowserAction);

        const folderContent = this.getSelectedLayers(this.state.currentPreviewIndex)
            .filter(layer => layer.type === PARSED_FIELD_TYPES.BACKGROUND_IMAGE)
            .map((layer, index) => ({
                path: layer.src,
                name: [index, layer.data.name].join('---'),
            }))

        psdLayersEventBroker.fire(PSD_LAYERS_EVENT.SAVE_PSD_ASSETS_TO_FOLDER, {
            folderContent,
            parentId: +fileState.filename.split('/')[1],
            folderName: fileState.originalFileName.split('.')[0],
        })
    }

    createTemplateFormAdmin() {
        // TODO: It may be necessary to prepare the state of all files

        const fileState = this.state.files[this.state.currentPreviewIndex];

        if (!fileState) {
            return;
        }

        psdLayersEventBroker.fire(PSD_LAYERS_EVENT.CREATE_TEMPLATE_ADMIN, {
            layers: this.getSelectedLayers(this.state.currentPreviewIndex),
            name: fileState.originalFileName.split('.')[0],
            width: fileState.parsedPsdModel.width,
            height: fileState.parsedPsdModel.height,
        })

        psdLayersEventBroker.fire(PSD_LAYERS_EVENT.CLOSE)
    }

    savePSDs() {
        for (const file of this.state.files) {
            if (!file.isSavePsd) {
                return;
            }

            const savePSDAction = PSDLayerService.savePSD({
                filename: file.filename,
                originName: file.originalFileName,
                psdData: this.collectPsdDataToStore(file),
            })

            this.add(savePSDAction);
        }
    }
}

const PSDLayerService = {
    savePSD(payload) {
        return axios
            .post('/file/save-psd-to-file-browser', payload)
    },
    saveIndividualAssets(payload) {
        return MediaService
            .addImageToThemeByItsLocation(payload)
            .then(() => {
                psdLayersEventBroker.fire(
                    EVENTS.SAVED_INDIVIDUAL_ASSETS,
                    payload
                )
            })
    },
    updatePSD(payload) {
        return axios
            .post('/file/update-psd-file-in-browser', payload);
    },
    uploadUsersLogo(payload) {
        return axios.post('/file/upload_users_logo', payload);
    },
    mergeNode(payload) {
      return axios.post('/file/merge_psd_node', payload)
    },
    applyICCProfile(payload) {
        return axios.post('/file/icc/apply', payload);
    }
}

export default PSDLayerService
