<template>
  <div>
    <b-form-group label="Transformation type">
      <b-form-select
        v-model="selectedTransformation"
        :options="transformationOptions"
      />
    </b-form-group>

    <b-form-group
      v-if="selectedTransformation === 'columns-multiplier'"
      label="Select columns to multiply"
    >
      <Multiselect
        v-model="headersToMultiply"
        :options="xlsxHeaders"
        :multiple="true"
      />
    </b-form-group>

    <b-row v-if="selectedTransformation === 'columns-concatenation'">
      <b-col cols="6">
        <b-form-group label="Select columns to concatenate">
          <Multiselect
            v-model="headersToConcatenate"
            :options="xlsxHeaders"
            :multiple="true"
            :close-on-select="false"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group label="Character/string to concatenate with">
          <b-form-input
            v-model="headersDelimiter"
            type="text"
            placeholder="Character/string to concatenate with"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- old transformation-modal--form-shorten-product-description -->
    <b-form-group
      v-if="productDescriptionIsVisible"
      label="Output words"
    >
      <b-form-input
        v-model="outputWords"
        type="number"
        placeholder="Output words"
      />
    </b-form-group>

    <!-- old transformation-modal--form-select-separator -->
    <b-form-group
      v-if="formSelectSeparatorIsVisible"
      label="Delimiter character"
    >
      <b-form-input
        v-model="delimiterCharacter"
        type="text"
        placeholder="Delimiter character"
      />
    </b-form-group>

    <b-form-group
      v-if="formSelectCharacterIsVisible"
      label="Character"
    >
      <b-form-input
        v-model="lineBreakCharacter"
        type="text"
        placeholder="Enter the character"
      />
    </b-form-group>

    <!-- old transformation-modal--form-select-substring -->
    <b-form-group
      v-if="formSelectSubstringIsVisible"
      label="Text to add"
    >
      <b-form-input
        v-model="substring"
        type="text"
        placeholder="Enter the character/string to append/prepend"
      />
    </b-form-group>

    <!-- old transformation-modal--form-select-configurable-bullets -->
    <div v-if="formSelectConfigurableBulletsIsVisible">
      <b-form-group>
        <b-form-select
          v-model="bulletsToAdd"
          :options="bulletsOptions"
        />
      </b-form-group>

      <b-form-group v-if="addLineJumpIsVisible">
        <b-form-checkbox
          v-model="addLineJump"
          class="ml-3"
        >
          Add line jump
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        v-if="customTextIsVisible"
        label="Custom text:"
      >
        <b-form-input
          v-model="bulletsCustom"
          type="text"
        />
      </b-form-group>

      <b-form-group label="Bullet Color (#000001 to use text color):">
        <b-form-input
          v-model="bulletColor"
          type="color"
          placeholder="Bullet color"
        />
      </b-form-group>
    </div>

    <!-- old transformation-modal--form-custom-script -->
    <div v-show="formCustomScriptIsVisible">
      <b-form-group>
        <b-form-select
          v-model="transformationScript"
          :options="savedScriptsOptions"
        />
      </b-form-group>

      <b-form-group label="Custom script">
        <b-form-textarea
          v-if="!codeEditor"
          :value="customScript"
          rows="10"
        />

        <div
          ref="customScriptCodeEditor"
          class="custom-script-code-editor"
        />
      </b-form-group>

      <div>
        <b-button
          variant="primary"
          @click="testScript"
        >
          Test script
        </b-button>

        <b-button
          variant="primary"
          @click="saveScript"
        >
          Save script
        </b-button>

        <b-button
          v-if="transformationScript"
          variant="danger"
          @click="deleteScript"
        >
          Delete script
        </b-button>
      </div>

      <div
        v-show="customScriptOutputVisible"
        class="card custom-script-output"
      >
        <div class="card-header">
          Output:
        </div>

        <div
          ref="customScriptOutput"
          class="card-body"
          style="white-space: pre-wrap;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { EditorState } from '@codemirror/state';
import { basicSetup } from "codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { EditorView } from "@codemirror/view";
import { transformationsEventBroker, TRANSFORMATIONS_EVENTS } from '@frontend/components/modules/edit-page/import/transformations/event-broker';

export default {
  name: 'TransformationForm',
  components: {
    Multiselect,
  },
  props: {
    transformation: {
      type: Object,
      required: false,
      default: () => ({
        trasnformation: null,
        varriables: {}
      }),
    },
    scripts: {
      type: Array,
      required: false,
      default: () => [],
    },
    xlsxHeaders: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTransformation: null,
      transformationOptions: [
        { value: null, text: 'No transformation' },
        { value: 'add-line-break-after-character', text: 'Add a line break after the character' },
        { value: 'append', text: 'Append character/text' },
        { value: 'excel_date_yyyy_mm_dd', text: 'Excel date to yyyy-mm-dd' },
        { value: 'excel_date_dd_mm_yyyy', text: 'Excel date to dd/mm/yyyy' },
        { value: 'generate-QR', text: 'Generate QR code' },
        { value: 'multiple-images', text: 'Multiple images' },
        { value: 'values-to-bullets', text: 'Multiple values to bullets' },
        { value: 'values-to-configurable-bullets', text: 'Multiple values to configurable bullets' },
        { value: 'bullet-to-text', text: 'Prepend bullet to text' },
        { value: 'prepend', text: 'Prepend character/text' },
        { value: 'reduce-product-name', text: 'Reduce product name' },
        { value: 'remove-special-characters', text: 'Remove special characters' },
        { value: 'shorten-product-description', text: 'Shorten product description' },
        { value: 'shorten-term-description', text: 'Shorten term description' },
        { value: 'combine-adjacent-cells', text: 'Table: combine adjacent cells with equal values' },
        { value: 'static-table', text: 'Table with static number of rows' },
        { value: 'truncate', text: 'Truncate' },
        { value: 'custom-script', text: 'Custom script' },
        { value: 'columns-multiplier', text: 'Columns multiplier' },
        { value: 'columns-concatenation', text: 'Concatenate Fields' },
      ],
      bulletsOptions: [
        { value: null, text: 'Select bullet' },
        { value: 'custom_text', text: 'Custom text' },
        {
          label: '● Circular Shapes',
          options: [
            { value: '●', text: '● Solid Circle' },
            { value: '○', text: '○ Hollow Circle' },
            { value: '◉', text: '◉ Target Circle' },
            { value: '◎', text: '◎ Double Circle' },
            { value: '◌', text: '◌ Dotted Circle' },
          ],
        },
        {
          label: '■ Square & Rectangular Shapes',
          options: [
            { value: '■', text: '■ Solid Square' },
            { value: '□', text: '□ Hollow Square' },
            { value: '▪', text: '▪ Small Solid Square' },
            { value: '▫', text: '▫ Small Hollow Square' },
            { value: '▮', text: '▮ Block Square' },
            { value: '▯', text: '▯ Outline Square' },
          ],
        },
        {
          label: '▶ Triangular Shapes',
          options: [
            { value: '▶', text: '▶ Black Right Triangle' },
            { value: '▷', text: '▷ White Right Triangle' },
            { value: '◀', text: '◀ Black Left Triangle' },
            { value: '◁', text: '◁ White Left Triangle' },
            { value: '▲', text: '▲ Black Up Triangle' },
            { value: '△', text: '△ White Up Triangle' },
            { value: '▼', text: '▼ Black Down Triangle' },
            { value: '▽', text: '▽ White Down Triangle' },
            { value: '◢', text: '◢ Lower Right Triangle' },
            { value: '◣', text: '◣ Lower Left Triangle' },
            { value: '◤', text: '◤ Upper Left Triangle' },
            { value: '◥', text: '◥ Upper Right Triangle' },
          ],
        },
        {
          label: '★ Star Shapes',
          options: [
            { value: '★', text: '★ Black Star' },
            { value: '☆', text: '☆ White Star' },
          ]
        },
        {
          label: '➤ Arrow Shapes',
          options: [
            { value: '➤', text: '➤ Black Arrowhead' },
            { value: '➜', text: '➜ Medium Arrow' },
            { value: '➝', text: '➝ Thin Arrow' },
            { value: '➡', text: '➡ Right Arrow' },
            { value: '⬅', text: '⬅ Left Arrow' },
            { value: '⬆', text: '⬆ Up Arrow' },
            { value: '⬇', text: '⬇ Down Arrow' },
            { value: '↗', text: '↗ Diagonal Up-Right' },
            { value: '↘', text: '↘ Diagonal Down-Right' },
            { value: '↙', text: '↙ Diagonal Down-Left' },
            { value: '↖', text: '↖ Diagonal Up-Left' },
          ],
        },
        {
          label: '♦ Diamond Shapes',
          options: [
            { value: '♦', text: '♦ Black Diamond' },
            { value: '◇', text: '◇ White Diamond' },
          ],
        },
      ],
      outputWords: 2,
      delimiterCharacter: null,
      substring: null,

      bulletsToAdd: null,
      addLineJump: 0,
      bulletsCustom: '',
      bulletColor: '#000001',

      transformationScript: null,
      customScript: null,
      codeEditor: null,
      customScriptOutputVisible: false,
      lineBreakCharacter: null,

      headersToMultiply: [],

      headersToConcatenate: [],
      headersDelimiter: null,
    }
  },
  computed: {
    savedScriptsOptions() {
      return [
        { value: null, text: 'Select a saved custom script...' },
        ...this.scripts.map((script) => ({value: script.id, text: script.name})),
      ];
    },
    productDescriptionIsVisible() {
      return this.selectedTransformation === 'shorten-product-description'
    },
    formSelectSeparatorIsVisible() {
      return ['values-to-bullets', 'values-to-configurable-bullets', 'multiple-images', 'truncate'].includes(this.selectedTransformation);
    },
    formSelectCharacterIsVisible() {
      return this.selectedTransformation === 'add-line-break-after-character';
    },
    formSelectSubstringIsVisible() {
      return ['append', 'prepend'].includes(this.selectedTransformation);
    },
    formSelectConfigurableBulletsIsVisible() {
      return ['values-to-configurable-bullets', 'bullet-to-text'].includes(this.selectedTransformation);
    },
    addLineJumpIsVisible() {
      return ['values-to-configurable-bullets'].includes(this.selectedTransformation);
    },
    customTextIsVisible() {
      return this.bulletsToAdd === 'custom_text';
    },
    formCustomScriptIsVisible() {
      return ['custom-script', 'multiple-images', 'truncate'].includes(this.selectedTransformation);
    },
  },
  watch: {
    transformationScript: {
      handler(newVal) {
        this.customScript = newVal
          ? this.scripts.find((script) => script.id === newVal).content
          : null;

        this.initializeCodeMirror();
        this.hideTestOutput();
      },
    },
    selectedTransformation: {
      handler(newVal) {
        if (this.formCustomScriptIsVisible) {
          this.initializeCodeMirror();
        }
      },
    },
  },
  mounted() {
    if (!this.transformation.transformation) {
      return;
    }

    this.selectedTransformation = this.transformation.transformation;

    if (['shorten-product-description'].includes(this.selectedTransformation)) {
      this.outputWords = this.transformation.variables.outputWords;
    }

    if (['values-to-bullets', 'multiple-images', 'truncate'].includes(this.selectedTransformation)) {
      this.delimiterCharacter = this.transformation.variables.delimiter;
    }

    if (['values-to-configurable-bullets'].includes(this.selectedTransformation)) {
      this.delimiterCharacter = this.transformation.variables.delimiter;

      this.setBullets();
      this.addLineJump = !!this.transformation.variables.add_line_jump;
    }

    if (['bullet-to-text'].includes(this.selectedTransformation)) {
      this.setBullets();
    }

    if (['prepend', 'append'].includes(this.selectedTransformation)) {
      this.substring = this.transformation.variables.substring;
    }

    if (["custom-script"].includes(this.selectedTransformation)) {
      this.customScript = this.transformation.variables.script;
    }
    if (["add-line-break-after-character"].includes(this.selectedTransformation)) {
      this.lineBreakCharacter = this.transformation.variables.lineBreakCharacter;
    }

    if (["columns-multiplier"].includes(this.selectedTransformation)) {
      this.headersToMultiply = this.transformation.variables.headersToMultiply;
    }

    if (["columns-concatenation"].includes(this.selectedTransformation)) {
      this.headersToConcatenate = this.transformation.variables.headersToConcatenate;
      this.headersDelimiter = this.transformation.variables.headersDelimiter;
    }
  },
  methods: {
    getTransformationData() {
      const transformation = {
        transformation: this.selectedTransformation,
        variables: {},
      };

      if (['shorten-product-description'].includes(transformation.transformation)) {
        transformation.variables.outputWords = this.outputWords;
      }

      if (['add-line-break-after-character'].includes(transformation.transformation)) {
        transformation.variables.lineBreakCharacter = this.lineBreakCharacter;
      }

      if (['multiple-images'].includes(transformation.transformation)) { // @todo
        // let field = $("#field_mapping_"+templateFieldIndex);
        // let field_name = field.attr("name");
        // if (field_name && field_name.indexOf('_') !== -1){
        // alert ("Please select this transformation on the first image field of the group")
        // return;
        // }
        // settings.variables.delimiter = $('[data-variable-name="delimiter"]').val();
        // toggle_image_fields(templateFieldIndex,containerQuery, true);
        if (this.delimiterCharacter) {
          transformation.variables.delimiter = this.delimiterCharacter;
        }
      } else if (['values-to-bullets', 'truncate'].includes(transformation.transformation)) {
        transformation.variables.delimiter = this.delimiterCharacter;
      } else if (['values-to-configurable-bullets', 'bullet-to-text'].includes(transformation.transformation)) {
        let bullet = $('[data-variable-name="configurableBullets"]').val();
        let bulletColor = $('[data-variable-name="configurableBulletsColor"]').val();

        if (this.delimiterCharacter) {
          transformation.variables.delimiter = this.delimiterCharacter;
        }

        // there's no need to set bullet if the field is empty
        if (this.bulletsToAdd) {
          transformation.variables.bullet = this.bulletsToAdd;
        }

        // custom bullet user input
        if (this.bulletsToAdd === 'custom_text') {
          transformation.variables.bullet = this.bulletsCustom;
        }

        //set color in case if the user pick another color
        //default color = #000001 if color = #000001 the bullet color will be as text
        if (this.bulletsToAdd && this.bulletColor !== '#000001') {
          transformation.variables.bullet_color = this.bulletColor;
        }

        if (this.addLineJumpIsVisible) {
          transformation.variables.add_line_jump = this.addLineJump ? 1 : 0;
        }
      } else if (['columns-multiplier'].includes(transformation.transformation)) {
        transformation.variables.headersToMultiply = this.headersToMultiply;
      } else if (['columns-concatenation'].includes(transformation.transformation)) {
        transformation.variables.headersToConcatenate = this.headersToConcatenate;
        transformation.variables.headersDelimiter = this.headersDelimiter;
      } else if (['prepend', 'append'].includes(transformation.transformation)) {
        transformation.variables.substring = this.substring;
      } else if (['custom-script', 'append'].includes(transformation.transformation)) {
        transformation.variables.script = this.customScript;
      } else { // @todo
        // // check if it previously had 'multiple-images' so we can re-enable the other inputs.
        // let previous_value_input = $(`${containerQuery} .field-mapping-transformation[data-template-field-index="${templateFieldIndex}"]`);
        // let previous_value = previous_value_input.val();
        // if (previous_value && JSON.parse(previous_value).transformation !== undefined && JSON.parse(previous_value).transformation == 'multiple-images' ){
        // toggle_image_fields(templateFieldIndex,containerQuery, false);
        // }
      }

      return transformation;
    },
    initializeCodeMirror() {
      const state = EditorState.create({
        doc: this.customScript,
        extensions: [
          basicSetup,
          javascript(),
          EditorView.updateListener.of((update) => {
            if (update.docChanged) {
              this.customScript = update.state.doc.toString();
            }
          }),
        ],
      });

      if (this.codeEditor) {
        this.codeEditor.destroy();
      }

      this.codeEditor = new EditorView({
        state,
        parent: this.$refs.customScriptCodeEditor
      });
    },
    testScript() {
      if (!this.customScript) {
        alert('Please enter a script or choose a saved script to test');
        return;
      }

      transformationsEventBroker.fire(TRANSFORMATIONS_EVENTS.TEST_SCRIPT, {
        script: this.customScript,
        instance: this,
      });
    },
    saveScript() {
      if (!this.customScript) {
        alert('Please enter a script to save');
        return;
      }

      const name = this.transformationScript
        ? this.scripts.find((script) => script.id === this.transformationScript).name
        : '';

      transformationsEventBroker.fire(TRANSFORMATIONS_EVENTS.SAVE_SCRIPT, {
        id: this.transformationScript,
        script: this.customScript,
        originalName: name,
        instance: this,
      });
    },
    setScript(script) {
      this.transformationScript = script;
    },
    deleteScript() {
      transformationsEventBroker.fire(TRANSFORMATIONS_EVENTS.DELETE_SCRIPT, {
        script: this.transformationScript,
        instance: this,
      });
    },
    hideTestOutput() {
      if (this.$refs.customScriptOutput) {
        this.$refs.customScriptOutput.innerHTML = '';
      }

      this.customScriptOutputVisible = false;
    },
    showTestOutput({output}) {
      this.customScriptOutputVisible = true;
      this.$refs.customScriptOutput.innerHTML = output;
    },
    setBullets() {
      //if bullet in select options set option, if not set as custom
      const recursiveFind = (nodes, callback) => {
        for (const node of nodes) {
          if (node.options) {
            const found = recursiveFind(node.options, callback);

            if (found) return found;
          }

          if (callback(node)) return node;
        }

        return undefined;
      };

      const bulletIsFromOptions = recursiveFind(
        this.bulletsOptions,
        (option) => option.value === this.transformation.variables.bullet,
      );

      if (bulletIsFromOptions) {
        this.bulletsToAdd = this.transformation.variables.bullet;
      } else {
        this.bulletsToAdd = 'custom_text';
        this.bulletsCustom = this.transformation.variables.bullet;
      }

      //set custom color or get default
      this.bulletColor = this.transformation.variables.bullet_color || '#000001';
    }
  },
}
</script>
