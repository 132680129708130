import { fabric } from 'fabric';

export const GRADIENT_TYPES = [
  { text: 'Linear', value: 'linear' },
  { text: 'Radial', value: 'radial' }
];

export const GRADIENT_DIRECTIONS = [
  { text: 'Left to Right', value: 'to right' },
  { text: 'Right to Left', value: 'to left' },
  { text: 'Top to Bottom', value: 'to bottom' },
  { text: 'Bottom to Top', value: 'to top' }
];

export const FILL_TYPE_OPTIONS = [
  { text: 'Solid', value: 'solid' },
  { text: 'Gradient', value: 'gradient' }
];

export const DEFAULT_COLOR_STOPS = [
  { offset: 0, color: '#000000' },
  { offset: 100, color: '#ffffff' }
];

export function createGradient({ type, direction, colorStops }) {
  if (!type || !colorStops?.length) {
    return null;
  }

  const gradientConfig = {
    type,
    gradientUnits: 'percentage',
    coords: getGradientCoords({ type, direction }),
    colorStops
  };

  return new fabric.Gradient(gradientConfig);
}

function getGradientCoords({ type, direction }) {
  if (type === "linear") {
    return getLinearCoords({ direction });
  }
  return getRadialCoords();
}

function getLinearCoords({ direction }) {
  const coords = {
    'to right': { x1: 0, y1: 0, x2: 1, y2: 0 },
    'to left': { x1: 1, y1: 0, x2: 0, y2: 0 },
    'to top': { x1: 0, y1: 1, x2: 0, y2: 0 },
    'to bottom': { x1: 0, y1: 0, x2: 0, y2: 1 }
  };

  return coords[direction] || coords['to right'];
}

function getRadialCoords() {
  return {
    x1: 0.5,
    y1: 0.5,
    r1: 0,
    x2: 0.5,
    y2: 0.5,
    r2: 0.5
  };
}