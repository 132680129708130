<template>
  <div
    class="c-main-view"
    @click.self="clearSelection"
    @drop.prevent.stop="onDrop"
    @dragover.prevent.stop="dragover"
    @dragleave.prevent.stop="dragleave"
  >
    <TopBar v-if="!isMoveProductModeEnabled" />
    <div
      class="c-main-view__container"
      @click.self="clearSelection"
    >
      <component
        :is="view"
        :is-drag-and-drop-available="isDragAndDropEnabled"
      />
      <div
        v-if="isDragging"
        class="c-main-view__drag-overlay"
      >
        <div class="c-main-view__drag-overlay--drag-overlay-background">
          <span class="c-main-view__drag-overlay--text">Drag & drop files here</span>
        </div>
      </div>
      <div
        v-if="isListLoading"
        class="c-main-view__spinner-overlay"
      >
        <b-spinner
          class="c-main-view__spinner-overlay__spinner"
          variant="success"
          label="Spinning"
        />
      </div>
    </div>
    <PaginationComponent />
    <SelectionActionsComponent />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TopBar from "./TopBar.vue";
import GridView from "./browser/views/GridView.vue";
import {MODES} from "./constants";
import SelectionActionsComponent from "./SelectionActionsComponent.vue";
import PaginationComponent from "./PaginationComponent.vue";

let debounceTimer;

export default {
  name: "MainView",
  components: {
    SelectionActionsComponent,
    PaginationComponent,
    TopBar
  },
  data: () => ({
    isDragging: false
  }),
  computed: {
    ...mapGetters('fileBrowserProductSelector', [
      'isListLoading', 
      'isDragAndDropEnabled', 
      'isFolderViewEnabled', 
      'grouped', 
      'selectedCount', 
      'currentFolderId', 
      'mode',
      'items',
      'pickedItems',
      'isMoveProductModeEnabled',
      'currentFolderId'
    ]),
    ...mapGetters('layout', [
      'selectedInstance',
    ]),
    view() {
      return GridView
    },
    isDragAndDropAvailable() {
      return this.mode !== MODES.VIEW;
    }
  },
  watch: {
    currentFolderId: function () {
      this.fetchData()
    }
  },
  mounted() {
    this.preventDefaultDropEvents();
  },
  methods: {
    ...mapActions('fileBrowserProductSelector', [
      'clearSelection',
      'fetchData',
      'setCurrentPickedItems',
      'uploadProductImages',
    ]),
    preventDefaultDropEvents() {
      const events = ['dragenter', 'dragover', 'dragleave', 'drop']
      events.forEach((eventName) => {
        document.body.addEventListener(eventName, e => e.preventDefault())
      })
    },
    onDrop(event) {
      if (!this.isDragAndDropAvailable) {
        return;
      }

      const { items } = event.dataTransfer;
      if (!items || items.length === 0) return;

      const files = {};
      const itemsArray = Array.from(items);
      const promises = itemsArray.map((item) => this.processFile(item, +this.currentFolderId, files));

      Promise.all(promises).then(async () => {
        for (const [folderID, filesInFolder] of Object.entries(files)) {
          await this.uploadProductImages({ files: filesInFolder, folderId: folderID })
        }
      });

      this.isDragging = false;
    },
    dragover(event) {
      if (!this.isDragAndDropAvailable) {
        return
      }

      if (event.dataTransfer.types?.[0] === 'Files') {
        this.isDragging = true;
      }

      clearTimeout(debounceTimer);
    },
    dragleave() {
      debounceTimer = setTimeout(() => {
        this.isDragging = false;
      }, 50)
    },
    processFile(item, folderId, files) {
      const entry = item.webkitGetAsEntry();

      return new Promise((resolve) => {
        entry.file((file) => {
          if (!files[folderId]) {
            files[folderId] = [];
          }
          files[folderId].push(file);
          resolve();
        });
      });
    }
  }
}
</script>

<style scoped lang="scss">
.c-main-view {
  border: 1px solid #727272;
  border-radius: 20px;
  padding: 20px;
  position: relative;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &__spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.5;

    &__spinner {
      position: absolute;
      top: calc(50% - 32px/2);
      left: calc(50% - 32px/2);
    }
  }

  &__drag-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    &--drag-overlay-background {
      width: 100%;
      height: 100%;
      background-color: #a0a0a0;
      opacity: 0.15;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed #727272;
    }

    &--text {
      color: black;
      font-size: 36px;
    }
  }
}
</style>
