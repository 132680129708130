var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "transformationsModal",
      attrs: {
        id: "transformations-modal",
        title: "Field Transformation",
        size: "lg",
        scrollable: "",
      },
      on: { close: _vm.hideModal },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "secondary" },
                      on: { click: _vm.hideModal },
                    },
                    [_vm._v("\n        Cancel\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.addTransformation },
                    },
                    [_vm._v("\n        Add another transformation\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.saveTransformations },
                    },
                    [_vm._v("\n        Save transformation settings\n      ")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    _vm._l(_vm.transformations, function (transformation, index) {
      return _c(
        "b-row",
        {
          key: index,
          staticClass: "p-1",
          class: { "border-bottom": index !== _vm.transformations.length - 1 },
        },
        [
          _c(
            "b-col",
            { attrs: { cols: "11" } },
            [
              _c("TransformationForm", {
                ref: "transFomrationRefs",
                refInFor: true,
                attrs: {
                  transformation: Array.isArray(transformation)
                    ? transformation[0]
                    : transformation,
                  scripts: _vm.scripts,
                  "xlsx-headers": _vm.xlsxHeaders,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-center align-items-center",
              attrs: { cols: "1" },
            },
            [
              index !== _vm.transformations.length - 1
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      on: {
                        click: function ($event) {
                          return _vm.removeTransformation(index)
                        },
                      },
                    },
                    [_c("i", { staticClass: "bi bi-trash" })]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }