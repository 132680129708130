var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.show
        ? _c(
            "b-alert",
            {
              staticClass: "position-warning",
              attrs: { show: "", variant: "warning" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between",
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "exclamation-triangle-fill" },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.message))]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "warning-actions" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "outline-warning" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("dismiss")
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            staticClass: "mr-1",
                            attrs: { icon: "x" },
                          }),
                          _vm._v("\n          Dismiss\n        "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }