var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "tree-select-item pl-2 py-1",
          class: {
            "tree-select-item--selected": _vm.selected?.id === _vm.option.id,
          },
          on: {
            click: function ($event) {
              return _vm.handleFolderClick(_vm.option)
            },
          },
        },
        [
          _c("b-icon", {
            staticClass: "mr-2",
            class: { closed: !_vm.visible, opened: _vm.visible },
            attrs: { icon: "triangle-fill" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCollapseClick(_vm.option?.id)
              },
            },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "tree-select-item__label" }, [
            _vm._v("\n      " + _vm._s(_vm.option?.name) + "\n    "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { staticClass: "ml-3", attrs: { visible: _vm.visible } },
        [
          _vm.loading
            ? _c(
                "div",
                { staticClass: "pl-2" },
                [
                  _c("b-spinner", {
                    attrs: { variant: "primary", type: "grow", small: "" },
                  }),
                  _vm._v("\n      Loading...\n    "),
                ],
                1
              )
            : !_vm.option.children?.length
            ? _c(
                "div",
                { staticClass: "pl-2" },
                [
                  _c("b-icon", {
                    attrs: { icon: "exclamation-circle", variant: "warning" },
                  }),
                  _vm._v("\n      No subfolders\n    "),
                ],
                1
              )
            : _vm._l(_vm.option.children, function (child) {
                return _c(
                  "div",
                  { key: child.id },
                  [
                    _c("WalmartFolderTreeItem", {
                      attrs: {
                        item: child,
                        selected: _vm.selected,
                        "platform-id": _vm.platformId,
                        "company-id": _vm.companyId,
                      },
                      on: { change: _vm.handleFolderClick },
                    }),
                  ],
                  1
                )
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }