import {fabric} from "fabric";
import FontFaceObserver from "fontfaceobserver";
import { FILE_TYPE } from '../../constants/file-image-types'
import { TYPE_SUBSCENE_MUSIC } from '../../constants/type-subscene';
import API_MEDIA from "../api/media";
import { BACKGROUND_COLOR_TYPES } from "../../../backend/constants/theme-background-color-types";

const removeExtension = (filename) => {
  return filename.split('.').slice(0, -1).join('.')
}

const copyToClipboard = (text) => {
	if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text);
  } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy");  // Security exception may be thrown by some browsers.
    }
    catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return false;
    }
    finally {
      document.body.removeChild(textarea);
    }
  }
}

const timeFormattedString = (time) => {
  const min = (Math.floor(time / 60)).toString().padStart(2, '0');
  const sec = (Math.floor(time) % 60).toString().padStart(2, '0');

  return `${min}:${sec}`
}

const timeFormattedStringWithDecimal = (time) => {
  const min = (Math.floor(time / 60)).toString().padStart(2, '0');
  const sec = ((time) % 60).toFixed(2).toString().padStart(5, '0');

  return `${min}:${sec}`
}

const timeFromFormattedString = (str) => {
  const min = str.substring(0, 2);
  const sec = str.substring(3, 5);
  const dec = str.substring(6);
  if(dec){
    return +min * 60 + +sec+'.'+dec
  } else{
    return +min * 60 + +sec
  }
}

const getValueMeasurementLineSpacing = (str) => {
  if (typeof str === 'string' && str.includes('%')) {
    return '%';
  }
  if (typeof str === 'string' && str.includes('pt')) {
    return 'pt';
  }
  if (typeof str === 'string' && str.includes('px')) {
    return 'px';
  }
  if (typeof str === 'string' && str.includes('em')) {
    return 'em';
  }
  if (typeof str === 'string' && str.includes('px')) {
    return 'rem';
  }
  return '';
};

const getFileNameByPath = (path) => {
  return path.slice(path.lastIndexOf('/') + 1)
};

const getRangeBetweenTwoNum = (min, max) => {
  return parseFloat((max+min)/2);
}

const replaceSpecialCharToUnderscore = (string) => {
  return string.replace(/[&\/\\#,+()$~%.'":*?<>{} ]/g,'_');
}

function getFixedNumber(number, decimals = 2) {
  return (+number || 0).toFixed(decimals)
}

function listFonts() {
  let { fonts } = document;
  const it = fonts.entries();

  let arr = [];
  let done = false;

  while (!done) {
    const font = it.next();
    if (!font.done) {
      font.value[0] && arr.push(font.value[0].family);
    } else {
      done = font.done;
    }
  }

  return [...new Set(arr)];
}

function checkInstanceBlob(blob, nameVar) {
  if (!(blob instanceof Blob)) {
    throw new Error(`Error: ${nameVar} must to be instance Blob'`)
  }
}

function downloadFileByBlob(blobData, nameFile) {
  checkInstanceBlob(blobData, 'blobData')

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blobData);
  link.download = nameFile;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

function downloadFileByUrl (url, name = null) {
  const link = document.createElement('a')
  link.href = url
  if (name) {
    link.download = name
  }
  document.body.appendChild(link)
  link.click()
  link.remove()
}

function checkFileSizeByMaxFileSize(file, maxFileSize) {
  checkInstanceBlob(file, 'file')

  return file.size <= maxFileSize
}

function setMinMaxValue($element) {
  const min = +$($element).attr('min')
  const max = +$($element).attr('max')

  if (typeof min === 'number' && !Number.isNaN(min)) {
    const value = +$($element).val()
    $($element).val(min > value ? min : value)
  }
  if (typeof max === 'number' && !Number.isNaN(max)) {
    const value = +$($element).val()
    $($element).val(value > max ? max : value)
  }
}
function componentToHex(c) {
  let hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}
function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function dataURLtoFile(dataUrl, filename) {

  var arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {type: mime});
}

function colorNameToHex (color) {
  const ctx = document.createElement('canvas').getContext('2d', { willReadFrequently: true })
  ctx.fillStyle = color
  return ctx.fillStyle
}

function dataURIToBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else
    byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {type:mimeString});
}


function checkTargetResolution(array1, array2) {
  const matches = array1.filter((n) => array2.indexOf(n) !== -1);
  
  return matches.length === 2;
}

// c - current, t - target
function getScaleFactor([cWidth, cHeight], [tWidth, tHeight]) {
  if (checkTargetResolution([cWidth, cHeight], [755, 93])) {
    return 0.5 / Math.max(tWidth / cWidth, tHeight / cHeight);
  }
  
  if (checkTargetResolution([tWidth, tHeight], ['728', '90'])) {
    return 0.25 / Math.max(tWidth / cWidth, tHeight / cHeight);
  }
  
  if (
    (checkTargetResolution([tWidth, tHeight], ['300', '250'])) ||
    (checkTargetResolution([tWidth, tHeight], ['1456', '180']))
  ) {
    return 0.5 / Math.max(tWidth / cWidth, tHeight / cHeight);
  }
  
  return 1.0 / Math.max(tWidth / cWidth, tHeight / cHeight);
}

function getVideoInfo(url) {
  return new Promise((resolve, reject) => {
    var video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = function() {
      window.URL.revokeObjectURL(video.src);

      resolve({
        duration: this.duration,
        width: this.videoWidth,
        height: this.videoHeight
      });
    };

    video.onerror = (err) => {
      reject(err);
    };

    video.src = url;
  })
}

function getAudioInfo(url) {
  return new Promise((resolve) => {
    var audio = document.createElement('audio');
    audio.preload = 'metadata';

    audio.onloadedmetadata = function() {
      window.URL.revokeObjectURL(audio.src);

      resolve({
        duration: this.duration,
      });
    };
    
    audio.onerror = () => {
      resolve({
        duration: 0,
        error: 'audio file is corrupted'
      });
    };

    audio.src = url;
  })
}

export async function getFolderAudioInfo(items) {
  let result = [];

  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    if (itValidAudioType(item.type)) {
      const itemInfo = await getAudioInfo(item.path);
      item.duration = itemInfo.duration;

      if (itemInfo.error) {
        toastr.error(`${item.name} ${itemInfo.error}`);
      }
    }

    result.push(item);
  }

  return result;
}

export function itValidAudioType(type) {
  return [FILE_TYPE.MP3, FILE_TYPE.WAV, FILE_TYPE.M4A].includes(type);
}

function trimFileName(filename, limit = 5) {
  if (filename.length > (limit + 5)) {
    const ext = filename.substr(5 * -1).replace(' ', '');

    let trimName = filename.substr(0, limit);

    return trimName + '...' + ext;
  }
  return filename;
}

function addPrefixToFileNameIntoPath(path, prefix) {
  const { extension, name } = getFileInfoByPath(path);

  return { fileName: `${name}${prefix}.${extension}`, name: `${name}${prefix}` }
}

function getFileInfoByPath(path) { // http://localhost/share?file=files/1/00012000001666.png
    const fileName = path.substring(path.lastIndexOf('/') + 1); // 00012000001666.png
    const fileNameData = fileName.split('.');

    const extension = fileNameData.reverse().shift(); // png
    const name = fileNameData.reverse().join('.'); // 00012000001666

    const filePath = path.split('=').pop(); // files/1/00012000001666.png
    const pathToFile = filePath.replace(fileName, '');

    const route = filePath.replace(`/${fileName}`, '').replace('files/', '');

    return { name, fileName, extension, route, pathToFile }
}

function parseJsonData(data, defaultValue = null) {
  if (typeof data !== 'string' && data) {
    return data;
  } else if (!data) {
    return defaultValue;
  } 

  try {
    return JSON.parse(data);
  } catch(error) {
    return defaultValue;
  }
}

function checkRowProps(type, props) {
  if (type === TYPE_SUBSCENE_MUSIC) {
    let data = parseJsonData(props, {fadein: 0, fadeout: 0});

    for (let key in data) {
      if (data[key] === null) {
        return {};
      }
    }
    
    return data;
  }

  return parseJsonData(props, {});
}

function isEqual(source, target) {
  return JSON.stringify(source) === JSON.stringify(target)
}

function cloneCanvas(canvas) {
  const newCanvas = document.createElement('canvas');
  const context = newCanvas.getContext('2d');
  newCanvas.width = canvas.width;
  newCanvas.height = canvas.height;
  context.drawImage(canvas, 0, 0);

  return newCanvas;
}

function getCanvasDimensions(value) {
  if (value <= 3) {
    return {
      width: 480,
      height: 270
    };
  }

  if (value >= 4 && value <= 6) {
    return {
      width: 420,
      height: 420
    };
  }

  if (value >= 7 && value <= 8) {
    return {
      width: 375,
      height: 667
    };
  }

  if (value >= 9 && value <= 10) {
    return {
      width: 472,
      height: 410
    };
  }

  if (value > 10) {
    return {
      width: 755,
      height: 93
    };
  }

  return {
    width: 480,
    height: 270
  };
}

function getScaledCanvas(previewWidth, previewHeight, canvasHeight = 360) {
  return {
    width: previewWidth * canvasHeight / previewHeight,
    height: canvasHeight
  }
}

function getScaledCanvasPreview(previewSize, canvasHeight = 360) {
  const { value, width, height } = previewSize;

  if (value > 10) {
    return { ...getCanvasDimensions(value) };
  }

  return { ...getScaledCanvas(width, height, canvasHeight) };
}

function imageToCanvas(imageElement) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = imageElement.width;
  canvas.height = imageElement.height;

  ctx.drawImage(imageElement, 0, 0);

  return canvas;
}
export async function pasteImage(file, options) {
    const {customer_id, theme, templateId, fileDataUrl} = options;
    const formData = new FormData();
    formData.append('filename_1', file.name);
    formData.append('customer_id', customer_id);
    formData.append('theme_id', theme);
    formData.append('template_id', templateId);
    formData.append('file_1', file);
    
    if (fileDataUrl) {
      formData.append('file_data_url', fileDataUrl);
    }
    
    const {data} = await API_MEDIA.uploadBackgroundImage(formData);
    return data[0].path;
}

function getInfoColorTheme(color = '') {
  const types = Object.values(BACKGROUND_COLOR_TYPES);
  const pattern = new RegExp(`^(${types.join('|')}),(#[0-9a-fA-F]{6}),?(#[0-9a-fA-F]{6})?`);
  const info = color.match(pattern);

  return {
    type: info?.[1],
    color1: info?.[2],
    color2: info?.[3]
  }
}

function htmlEntities(str) {
  return String(str)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');
}

function resizeWithAspectRatio(
  currentWidth,
  currentHeight,
  desiredWidth,
  desiredHeight,
  isReverse = false
)
{
  const desiredSizeRatio = desiredWidth / desiredHeight;
  const currentSizeRatio = currentWidth / currentHeight;

  let newWidth, newHeight;

  if ((isReverse ? desiredSizeRatio > currentSizeRatio : currentSizeRatio > desiredSizeRatio)) {
      newWidth = desiredWidth;
      newHeight = desiredWidth / currentSizeRatio;
  } else {
      
      newHeight = desiredHeight;
      newWidth = desiredHeight * currentSizeRatio;
  }

  const widthScale = newWidth / currentWidth;
  const heightScale = newHeight / currentHeight;
  
  newWidth = Math.round(newWidth);
  newHeight = Math.round(newHeight);

  return {
      width: newWidth,
      height: newHeight,
      scaleX: widthScale,
      scaleY: heightScale
  };
}

function getFilePath(url) {
  const _url = new URL(url, window.location.origin);
  const searchParams = _url.searchParams;

  return searchParams.get('file');
}

function rgbaString2hex(color) {
  const rgbaRegex = /^rgba\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,\s*[01](\.\d+)?\s*\)$/;

  if (rgbaRegex.test(color)) {
    const hex = `#${color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/)
      .slice(1)
      .map((n, i) => (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n))
        .toString(16)
        .padStart(2, '0')
        .replace('NaN', ''))
      .join('')}
  `;

    const hexResult = hex.trim().replace(/\n/g, '');

    return hexResult;
  } else {
    return color;
  }
}

function getDataURLFromBlob(file) {
  checkInstanceBlob(file, 'file');

  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      resolve(e.target.result);
    };

    reader.onerror = (error) => {
      reject(error);
    }

    reader.readAsDataURL(file);
  });
}

function isFilenameOrPath(string) {
  // Regular expression to match filenames within URLs and query strings
  var filenamePattern = /[^\\/:\*\?"<>\|]+(\.[^\\/:\*\?"<>\|]+)+/;
  return filenamePattern.test(string);
}

function getImageDimensions(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.src = URL.createObjectURL(file);

    img.onload = function () {
      const width = img.width;
      const height = img.height;

      resolve({ width, height });
    };

    img.onerror = function () {
      reject(new Error('Failed to load image'));
    };
  });
}

export {
  getDataURLFromBlob,
  getFilePath,
  resizeWithAspectRatio,
  getInfoColorTheme,
  removeExtension,
  copyToClipboard,
  timeFormattedString,
  timeFromFormattedString,
  timeFormattedStringWithDecimal,
  getValueMeasurementLineSpacing,
  getFileNameByPath,
  getRangeBetweenTwoNum,
  replaceSpecialCharToUnderscore,
  listFonts,
  downloadFileByBlob,
  downloadFileByUrl,
  checkFileSizeByMaxFileSize,
  checkInstanceBlob,
  setMinMaxValue,
  rgbToHex,
  dataURLtoFile,
  colorNameToHex,
  dataURIToBlob,
  getScaleFactor,
  getVideoInfo,
  getAudioInfo,
  trimFileName,
  addPrefixToFileNameIntoPath,
  getFileInfoByPath,
  parseJsonData,
  cloneCanvas,
  getCanvasDimensions,
  getScaledCanvasPreview,
  checkRowProps,
  imageToCanvas,
  getFixedNumber,
  htmlEntities,
  isEqual,
  rgbaString2hex,
  isFilenameOrPath,
  getImageDimensions
}
