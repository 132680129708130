var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          staticClass: "put-to-template-wrapper",
          attrs: {
            id: "svg_put_to_template_modal",
            title: "Send to template",
            centered: "",
            "no-close-on-backdrop": "",
          },
          on: { shown: _vm.handleModalShown, hide: _vm.close },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { disabled: _vm.loading },
                        on: { click: _vm.close },
                      },
                      [_vm._v("\n          Cancel\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { disabled: _vm.isSaveDisabled || _vm.loading },
                        on: { click: _vm.onSave },
                      },
                      [_vm._v("\n          Ok\n        ")]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.loading
            ? _c("div", { staticClass: "put-to-template-wrapper__overlay" }, [
                _c("div", {
                  staticClass: "put-to-template-wrapper__overlay--background",
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "put-to-template-wrapper__overlay--spinner" },
                  [_c("b-spinner", { attrs: { size: "lg" } })],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "b-form-group",
                  { attrs: { label: "Create new layout:" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "text",
                        placeholder: "Create layout",
                        required: "",
                        disabled: !!_vm.selectedLayout,
                      },
                      model: {
                        value: _vm.uploadData.layoutName,
                        callback: function ($$v) {
                          _vm.$set(_vm.uploadData, "layoutName", $$v)
                        },
                        expression: "uploadData.layoutName",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "b-form-group",
                  { attrs: { label: "Select existing layout:" } },
                  [
                    _c("Multiselect", {
                      attrs: {
                        options: _vm.layouts,
                        label: "name",
                        "track-by": "id",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "placeholder",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n              None selected\n            "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.selectedLayout,
                        callback: function ($$v) {
                          _vm.selectedLayout = $$v
                        },
                        expression: "selectedLayout",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "b-form-group",
                  { attrs: { label: "Select customer:" } },
                  [
                    _c("Multiselect", {
                      attrs: {
                        options: _vm.customers,
                        label: "name",
                        "track-by": "id",
                        placeholder: "Select customer:",
                        "allow-empty": true,
                        searchable: "",
                      },
                      on: { input: _vm.onCustomerChanged },
                      model: {
                        value: _vm.uploadData.templates.customer,
                        callback: function ($$v) {
                          _vm.$set(_vm.uploadData.templates, "customer", $$v)
                        },
                        expression: "uploadData.templates.customer",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Select template:" } },
                [
                  _c("Multiselect", {
                    attrs: {
                      options: _vm.templatesForSelectedCustomer,
                      label: "name",
                      "track-by": "id",
                      placeholder: "Select template:",
                      "allow-empty": true,
                      searchable: "",
                    },
                    on: { input: _vm.onTemplateChanged },
                    model: {
                      value: _vm.uploadData.templates.templateId,
                      callback: function ($$v) {
                        _vm.$set(_vm.uploadData.templates, "templateId", $$v)
                      },
                      expression: "uploadData.templates.templateId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.bgFields.length > 0 && _vm.bgLinks.length > 0
            ? _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("div", { staticClass: "mb-2" }, [
                    _vm._v("\n        Map Fields:\n      "),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.bgFields, function (field) {
                    return _c(
                      "div",
                      { key: field.id, staticClass: "mb-2 ml-2" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(field.name) +
                            ":\n        \n        "
                        ),
                        _c("div", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedLinks[field.id],
                                  expression: "selectedLinks[field.id]",
                                },
                              ],
                              staticClass:
                                "form-control form-control-sm d-inline-block",
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.selectedLinks,
                                      field.id,
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function ($event) {
                                    return _vm.onSelectField(
                                      field.id,
                                      $event.target.value
                                    )
                                  },
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v(
                                  "\n              Default value\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.bgLinks, function (link) {
                                return _c(
                                  "option",
                                  {
                                    key: link.src,
                                    domProps: { value: link.src },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(link.type) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }