import { EventBroker } from "../../../lib/utils/event-broker";

export const EVENTS = {
  INIT: 'INIT',
  SAVE: 'SAVE',
  ADD_ADDITIONAL_TABLE_FIELD: 'ADD_ADDITIONAL_TABLE_FIELD',
  CLOSE: 'CLOSE',
}

export const simpleTableBuilderEventBroker = window.eventBroker = new EventBroker();
