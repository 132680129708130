export const BYNDER_UPLOAD_TYPES = {
    MULTIPLE: 'multiple',
    SINGLE: 'single'
};

export const BYNDER_IMAGE_TYPES = {
    JPEG: 'jpeg',
    PNG: 'png',
    PSD: 'psd'
};

export const BYNDER_PUBLISH_SETTINGS = 'bynder_publish_settings';

export const BYNDER_META_VALUES = 'bynder_meta_values';
