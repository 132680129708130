var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("table", { staticClass: "table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.histories, function (history) {
          return _c("tr", { key: history.id, staticClass: "history-row" }, [
            _c("td", [_vm._v(_vm._s(_vm.formatDate(history.created_at)))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.formatTime(history.time)))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(history.type))]),
            _vm._v(" "),
            _c("td", [
              _c(
                "a",
                {
                  attrs: {
                    href: `/banner/${history.layout.customer_id}/group/${history.layout_id}/show`,
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(history.layout_id) +
                      "\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(history?.mapping?.name || "N/A"))]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "action",
                    attrs: {
                      href: "#",
                      role: "button",
                      "data-toggle": "dropdown",
                    },
                  },
                  [
                    _c("div", { staticClass: "ellipsis-vertical" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "26",
                            height: "22",
                            viewBox: "0 0 4 14",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              stroke: "",
                              d: "M2 1.875C2.06904 1.875 2.125 1.93096 2.125 2L0.625 2C0.625 2.75939 1.24061 3.375 2 3.375L2 1.875ZM2.125 2C2.125 2.06904 2.06904 2.125 2 2.125L2 0.625C1.24061 0.625 0.625 1.24061 0.625 2L2.125 2ZM2 2.125C1.93096 2.125 1.875 2.06904 1.875 2L3.375 2C3.375 1.24061 2.75939 0.625 2 0.625L2 2.125ZM1.875 2C1.875 1.93096 1.93096 1.875 2 1.875L2 3.375C2.75939 3.375 3.375 2.75939 3.375 2L1.875 2ZM2 6.875C2.06904 6.875 2.125 6.93096 2.125 7H0.625C0.625 7.75939 1.24061 8.375 2 8.375L2 6.875ZM2.125 7C2.125 7.06904 2.06904 7.125 2 7.125L2 5.625C1.24061 5.625 0.625 6.24061 0.625 7H2.125ZM2 7.125C1.93096 7.125 1.875 7.06904 1.875 7H3.375C3.375 6.24061 2.75939 5.625 2 5.625L2 7.125ZM1.875 7C1.875 6.93096 1.93096 6.875 2 6.875L2 8.375C2.75939 8.375 3.375 7.75939 3.375 7H1.875ZM2 11.875C2.06904 11.875 2.125 11.931 2.125 12H0.625C0.625 12.7594 1.24061 13.375 2 13.375L2 11.875ZM2.125 12C2.125 12.069 2.06904 12.125 2 12.125L2 10.625C1.24061 10.625 0.625 11.2406 0.625 12H2.125ZM2 12.125C1.93096 12.125 1.875 12.069 1.875 12H3.375C3.375 11.2406 2.75939 10.625 2 10.625L2 12.125ZM1.875 12C1.875 11.931 1.93096 11.875 2 11.875L2 13.375C2.75939 13.375 3.375 12.7594 3.375 12H1.875Z",
                              fill: "#464646",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-menu" }, [
                  _c(
                    "button",
                    {
                      staticClass: "dropdown-item",
                      on: {
                        click: function ($event) {
                          return _vm.downloadFile(history.file_path)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "bi bi-cloud-arrow-down" }),
                      _vm._v(" Download\n              "),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "dropdown-item",
                      on: {
                        click: function ($event) {
                          return _vm.rerun(history)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "bi bi-repeat" }),
                      _vm._v(" Rerun\n              "),
                    ]
                  ),
                ]),
              ]),
            ]),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _vm.pagination.last_page > 1
      ? _c("nav", [
          _c(
            "ul",
            { staticClass: "pagination" },
            [
              _c(
                "li",
                {
                  staticClass: "page-item",
                  class: { disabled: _vm.pagination.current_page === 1 },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "page-link",
                      on: {
                        click: function ($event) {
                          return _vm.changePage(_vm.pagination.current_page - 1)
                        },
                      },
                    },
                    [_vm._v("\n          Previous\n        ")]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.pagination.last_page, function (page) {
                return _c(
                  "li",
                  {
                    key: page,
                    staticClass: "page-item",
                    class: { active: _vm.pagination.current_page === page },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "page-link",
                        on: {
                          click: function ($event) {
                            return _vm.changePage(page)
                          },
                        },
                      },
                      [_vm._v("\n          " + _vm._s(page) + "\n        ")]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "page-item",
                  class: {
                    disabled:
                      _vm.pagination.current_page === _vm.pagination.last_page,
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "page-link",
                      on: {
                        click: function ($event) {
                          return _vm.changePage(_vm.pagination.current_page + 1)
                        },
                      },
                    },
                    [_vm._v("\n          Next\n        ")]
                  ),
                ]
              ),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("\n          Date\n        "),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("\n          Time\n        "),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("\n          Type\n        "),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("\n          Layout\n        "),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("\n          Settings\n        "),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("\n          Actions\n        "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }