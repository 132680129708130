const replaceSymbols = {
  "\\r": "\n",
  "</u>": "<u>"
}

function replaceAllSymbols(text = '') {
  let newString = text;

  for (const key in replaceSymbols) {
    newString = newString.replaceAll(key, replaceSymbols[key]);
  }

  return newString;
}

export default function parseText(input, default_color, selectedChars = null, target = null) {
    if (!input) {
      return { text: "", styles: {} };
    }
    const styles = {};
    let charIndex = 0;
    let lineIndex = 0;
    let lineStyle = {};
    let text = "";
    // force to convert to string as it was failing for numbers
    input = input + "";
    const textArray = replaceAllSymbols(input).split("<u>");
      
    textArray.forEach((t, i) => {
      let color = "";
      let subArray = t.split("<c ");

      subArray.forEach((element, j) => {
        let subText = element;
        if (j) {
          const pos = element.indexOf("'>");
          color = element.slice(1, pos);
          subText = element.slice(pos + 2, element.length);
        }
        for (let k = 0; k < subText.length; k++) {
          if (subText[k] === "\n") {
            styles[lineIndex] = lineStyle;
            lineStyle = {};
            lineIndex += 1;
            charIndex = 0;
          } else {
            const charStyle = {
              fill: color || default_color,
              underline: i % 2 === 1,
            };
            
            
            if (selectedChars?.length && target && target.get('styles') && target.get('styles')[lineIndex]) {
              const char = selectedChars.find(char => char === k);

              const targetCharStyles = target.get('styles')[lineIndex][charIndex];

              char >= 0
                ? charStyle['fill'] = default_color
                : charStyle['fill'] = targetCharStyles?.fill || target.fontColor;
            }
            
            lineStyle = { ...lineStyle, [charIndex]: charStyle };
            charIndex += 1;
          }
        }
        text += subText;
      });
    });

    styles[lineIndex] = lineStyle;

    return { text, styles };
}
