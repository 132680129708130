<template>
  <div
    :class="{ 'd-none': hidden, 'd-flex': !hidden }"
    class="flex-column flex-grow-1"
    style="height: calc(100vh - 132px);"
  >
    <div
      id="ai-chat-messages"
      ref="messages"
      class="flex-grow-1 card mb-2"
    >
      <div class="card-body">
        <div v-if="messages.length">
          <div
            v-for="(message, index) in messages"
            :key="index"
            :class="['message', `message-${message.role}`]"
          >
            <p v-html="message.content" />
          </div>
        </div>
        <div
          v-else
          class="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
        >
          <p class="h5">
            How can I assist you?
          </p>
          <p class="text-muted text-center">
            I can analyze templates and suggest improvements.
          </p>
          <img
            v-if="userInputImage"
            :src="userInputImage"
            style="width: 300px; height: 300px; object-fit: contain;"
          >
          <div class="mt-2">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="refreshImage()"
            >
              Refresh
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-shrink-0 mt-2">
      <div class="btn-group dropup">
        <button
          type="button"
          class="btn btn-primary btn-sm dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Prompts
        </button>
        <div class="dropdown-menu">
          <a
            v-for="(prompt, index) in prompts"
            :key="index"
            class="dropdown-item"
            href="javascript:void(0);"
            @click="selectPrompt(prompt)"
          >{{ prompt.name }}</a>
        </div>
      </div>
      <div class="flex-shrink-0 input-group mt-2">
        <input
          v-model="userInput"
          class="form-control"
          placeholder="Type your message..."
          type="text"
          :disabled="loading"
          @keyup.enter="sendMessage"
        >
        <button
          class="btn btn-primary ml-2"
          :disabled="loading"
          type="button"
          @click="sendMessage"
        >
          Send
        </button>
      </div>
    </div>
    <div
      v-if="loading"
      class="position-absolute w-100 h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const showdown = require('showdown');

export default {
    name: "AiAnalystTab",
    props: {
        targetElement: {
            type: fabric.Object,
            require: true,
            default: () => {},
        },
        isTabActive: {
            type: Boolean,
            default: false,
        },
        hidden: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        messages: [],
        userInput: "",
        userInputImage: null,
        loading: false,
        layersOrdered: [],
        prompts: [
            {
                name: 'Mobile & Conversion Analysis',
                prompt: 'Generate a report on optimizing the image for Mobile & Conversion Analysis'
            },
            {
                name: 'Mobile Readiness with a specific focus area',
                prompt: 'Generate a report on optimizing the image for Mobile Readiness with a specific focus area'
            }
        ]
    }),
    computed: {
        ...mapGetters("layout", ["layers", "target", "selectedInstance"]),
    },
    watch: {
        isTabActive() {
            try {
                this.refreshImage();
            } catch (error) {
                console.error("Error getting image data:", error);
            }
        },
        targetElement() {
            try {
                this.refreshImage();
            } catch (error) {
                console.error("Error getting image data:", error);
            }
        }
    },
    methods: {
        async callOpenAI(settings) {
            const { data } = await axios.post(`/call-open-ai`, settings);
            return data;
        },
        refreshImage() {
            const imageDataURL = this.selectedInstance.canvas.toDataURL("image/png");
            this.userInputImage = imageDataURL;
        },
        selectPrompt(prompt) {
            this.userInput = prompt.prompt;
        },
        dataUrlToBase64(dataUrl) {
            return dataUrl.split(',')[1];
        },
        async sendMessage() {
            if (!this.userInput.trim() || this.loading) return;

            const userMessage = {
                role: "user",
                content: this.userInput.trim(),
            };

            this.messages.push(userMessage);
            this.userInput = "";
            this.loading = true;

            try {
                const responseMessage = await this.callOpenAI({
                    messages: [
                        {
                            role: "system",
                            content: `You are a banner ad designer AI assistant.`,
                        },
                        // Send only the latest 20 messages
                        ...this.messages.slice(-20).map((message) => ({
                            role: message.role,
                            content: message.content,
                        })),
                    ],
                    images: [
                        {
                            "type": "base64",
                            "data": this.dataUrlToBase64(this.userInputImage),
                        }
                    ],
                    model: "gpt-4o",
                });

                const converter = new showdown.Converter();
                const htmlContent = converter.makeHtml(responseMessage.content);

                this.messages.push({
                    role: "assistant",
                    content: htmlContent
                });
                this.loading = false;
            } catch (error) {
                console.error("Error:", error);
                this.messages.push({
                    role: "assistant",
                    content:
                        "Sorry, there was an error processing your message.",
                });
                this.loading = false;
            } finally {
                this.$nextTick(() => {
                    const messagesDiv = this.$refs.messages;
                    messagesDiv.scrollTop = messagesDiv.scrollHeight;
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.chat-module {
    height: calc(100vh - 98px);
}
.chat-module .card {
    position: relative;
    overflow-y: auto;
}
.message {
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
}
.message p {
    margin-bottom: 0px;
}
.message-user {
    background-color: #209c86;
    color: white;
    text-align: right;
}
.message-assistant {
    background-color: #e9ecef;
    text-align: left;
}
</style>
