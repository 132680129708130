var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-content" },
    [
      _c("div", { staticClass: "modal-header" }, [
        _c("div", { staticClass: "modal-title" }, [
          _vm.files.length > 1
            ? _c(
                "div",
                { staticClass: "files-container" },
                _vm._l(_vm.files, function (file, index) {
                  return _c(
                    "div",
                    {
                      key: file.filename,
                      staticClass: "files-container__file position-relative",
                      class: {
                        "files-container__file--active":
                          _vm.currentPreviewIndex === index,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setCurrentPreviewIndex(index)
                        },
                      },
                    },
                    [
                      file.entireImageThumbnail
                        ? _c("img", {
                            staticClass: "files-container__file-thumbnail",
                            attrs: {
                              src: _vm._f("fileThumbnail")(
                                file.entireImageThumbnail
                              ),
                              alt: "preview",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "files-container__file-name" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.truncateFieldName(file.originalFileName)
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      file.isLoading
                        ? _c("i", {
                            staticClass:
                              "fas fa-spinner fa-spin files-container__file-loader",
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !file.isLoading
                        ? _c("PSDParsedBadge", {
                            attrs: { right: "5", top: "0" },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("h5", [
            _vm._v("\n        Open PSD: "),
            _c("span", [_vm._v(" " + _vm._s(_vm.originalFileName) + " ")]),
            _vm._v(" "),
            _vm.templateNameAvailable
              ? _c("span", { staticClass: "d-block" }, [
                  _vm._v(
                    "\n          Template: " +
                      _vm._s(_vm.template.name) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c(_vm.currentStep, {
        tag: "component",
        attrs: { "show-save-images-to-theme": _vm.showSaveImagesToThemeTyped },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          id: "psd-layers-cancel-modal-button",
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }