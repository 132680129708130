<template>
  <div>
    <b-modal
      id="icons-settings-modal"
      title="Icons Settings"
      centered
      size="l"
      ok-title="Save"
      @ok="handleSave"
    >
      <div>
        <div class="inputs-container">
          <div
            v-for="(url, index) in urls"
            :key="index"
            class="d-flex mb-2"
          >
            <b-form-input
              :value="url"
              class="mr-2"
              placeholder="Enter the icon URL"
              @input="(value) => handleURLInput(value, index)"
            />

            <b-button
              v-if="urls.length > 1"
              size="sm"
              variant="outline-danger"
              @click="() => handleDeleteBtn(index)"
            >
              <b-icon icon="trash" />
            </b-button>
          </div>
        </div>
        
        <div class="d-flex justify-content-end">
          <b-button
            size="sm"
            variant="outline-primary"
            @click="handleAddUrlBtn"
          >
            Add URL
          </b-button>
        </div>
      </div>

      <b-form-row class="mt-2">
        <b-col cols="6">
          <b-form-group
            label="Placement Orientation"
          >
            <b-form-select
              v-model="placementOrientationValue"
              :options="placementOrientation" 
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Placement Order"
          >
            <b-form-select
              v-model="placementOrderValue"
              :options="placementOrder"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      
      <b-form-row 
        v-if="isShowPage" 
        class="mt-2"
      >
        <b-col cols="6">
          <b-form-group
            label="X"
            label-cols="1"
          >
            <b-input
              v-model.number="left"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Y"
            label-cols="1"
          >
            <b-input
              v-model.number="top"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="W"
            label-cols="1"
          >
            <b-input
              v-model.number="width"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="H"
            label-cols="1"
          >
            <b-input
              v-model.number="height"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-modal>
  </div>
</template>

<script>
import { iconsSettingsEventBroker, EVENTS } from "@frontend/group/modules/icons-settings/event-broker";

const PLACEMENT_ORIENTATION = [
  { value: 'leftToRight', text: 'Left to right'},
  { value: 'rightToLeft', text: 'Right to left'},
  { value: 'upToDown', text: 'Up to Down'},
  { value: 'downToUp', text: 'Down to Up'}
];

const PLACEMENT_ORDER = [
  { value: 'asc', text: 'Ascending'},
  { value: 'dec', text: 'Descending'},
];

export default {
  name: 'IconsSettingsModal',
  
  data: () => ({
    isShowPage: false,
    element: null,
    urls: [''],
    top: 0,
    left: 0,
    width: 100,
    height: 100,
    placementOrientationValue: PLACEMENT_ORIENTATION[0].value,
    placementOrderValue: PLACEMENT_ORDER[0].value
  }),
  
  computed: {
    placementOrientation() {
      return PLACEMENT_ORIENTATION;
    },
    
    placementOrder() {
      return PLACEMENT_ORDER;
    }
  },
  
  mounted() {
    iconsSettingsEventBroker.on(EVENTS.INIT, this.init.bind(this));
  },

  methods: {
    init({ element, data, isShowPage = false }) {
      this.isShowPage = isShowPage;
      this.element = element;
      this.urls = data.urls?.length ? data.urls : [''];
      this.placementOrientationValue = data.placementOrientation || PLACEMENT_ORIENTATION[0].value;
      this.placementOrderValue = data.placementOrder || PLACEMENT_ORDER[0].value;
      this.$bvModal.show('icons-settings-modal');
    },
    
    handleSave() {
      iconsSettingsEventBroker.fire(EVENTS.SAVE, {
        element: this.element,
        urls: this.urls.filter(url => url.length),
        placementOrientation: this.placementOrientationValue,
        placementOrder: this.placementOrderValue,
        top: this.top,
        left: this.left,
        width: this.width,
        height: this.height
      });
    },
    
    handleURLInput(value, index) {
      this.$set(this.urls, index, value);
    },
    
    handleAddUrlBtn() {
      this.urls.push('');
    },

    handleDeleteBtn(index) {
      this.urls.splice(index, 1);
    }
  }
}
</script>

<style lang="scss" scoped>
  .inputs-container {
    max-height: 322px;
    overflow: auto;
  }
</style>
