<template>
  <div
    @change="$emit('toggle')"
    @drop.prevent="onDrop"
    @dragstart.prevent
    @dragenter.prevent="onDragEnter"
    @dragleave.prevent="onDragLeave"
    @dragover.prevent="onDragOver"
  >
    <CropImage
      :is-show="isCropMode"
      :src="target.getSrc()"
      @onSave="(data) => onChange('newCrop', data)"
      @onCancel="isCropMode = false"
    />

    <template v-if="options.isSelectImageFromBackground">
      <b-form-group :label="imageName">
        <b-button
          variant="primary"
          @click="onChange('selectBackground')"
        >
          <b-icon icon="cloud-upload" /> {{ `${imageSrc && !imageIsDefault ? 'Replace' : 'Select…'}` }}
        </b-button>
        <b-img
          v-if="imageSrc"
          :src="imageSrc"
          class="img-preview shadow ml-1"
          fluid
          rounded
        />
      </b-form-group>
      <b-form-group
        label="Theme"
        label-cols="3"
        invalid-feedback="No theme selected"
        :state="!!selectedTheme"
      >
        <b-form-select
          v-model="selectedTheme"
          @change="onChange('selectTheme', selectedTheme)"
        >
          <b-form-select-option
            v-for="theme in cThemesOptions"
            :key="theme.id"
            :value="theme.id"
          >
            {{ theme.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
    </template>

    <b-form-group
      v-if="options.isUploadImage"
      label="Image"
      label-cols="3"
    >
      <b-form-file
        placeholder="Choose a file"
        drop-placeholder="Drop file here"
        @input="onChange('upload', $event)"
      />
    </b-form-group>

    <template v-if="options.isOutline">
      <b-form-group
        label="Outline"
        label-cols="3"
      >
        <b-form-input
          v-model="objectInput.fileOutlineId"
          @change="onChange('productOutline', outlineData)"
        />
      </b-form-group>
      <b-form-group
        label="Color"
        label-cols="3"
      >
        <ColorPicker
          v-model="objectInput.fileOutlineColor"
          @input="onChange('productOutline', outlineData)"
        />
      </b-form-group>
    </template>

    <ProductImageDropdown
      v-show="options.isFileImage"
      v-model="objectInput.fileIds"
      @input="onChange('productImage', objectInput.fileIds)"
      @uploadInput="(fileIds) => onChange('productImage',{productIds: fileIds, type: 'uploaded_product_image'})"
    />
    <ObjectFillFit 
      v-if="target && target.id.startsWith('bk_theme_img_')"
      :target="target" 
    />
    <b-button
      v-show="options.isHtmlTable"
      class="mb-2"
      variant="success"
      @click="initTableEditor"
    >
      Edit Table
    </b-button>
    <b-form-group label="Opacity">
      <b-input-group>
        <b-input
          v-model="opacity"
          min="0"
          max="1"
          step="0.01"
          type="range"
          @input="onChange('opacity', $event)"
        />
        <template #append>
          <b-input
            v-model="opacity"
            min="0"
            max="1"
            step="0.01"
            type="number"
            @input="onChange('opacity', $event)"
          />
        </template>
      </b-input-group>
    </b-form-group>

    <b-form-group
      label="Scale"
      :disabled="target.locked"
    >
      <b-input-group>
        <b-input
          v-model="scale"
          min="0"
          max="10"
          step="0.01"
          type="range"
          :disabled="isScaleDisabled"
          @input="onChange('scale', $event)"
        />
        <template #append>
          <b-input
            v-model="scale"
            min="0"
            max="10"
            step="0.01"
            type="number"
            :disabled="isScaleDisabled"
            @input="onChange('scale', $event)"
          />
        </template>
      </b-input-group>
    </b-form-group>

    <PositionAndSize
      :x="parseFloat(objectInput.left)"
      :y="parseFloat(objectInput.top)"
      :h="parseFloat(objectInput.height)"
      :w="parseFloat(objectInput.width)"
      aspect-ratio-option:
      :is-scale-disabled="isScaleDisabled"
      @input="onChange"
    />

    <div class="d-flex justify-content-between align-items-start mb-3">
      <ApplyToAllImageDropdown
        :target="target"
      />
      <div>
        <b-form-checkbox
          v-if="options.isFileImage"
          v-model="objectInput.isAutoPosition"
          class="mt-1 d-none"
          size="sm"
          @change="onChange('autoPosition', objectInput.isAutoPosition)"
        >
          Auto position
        </b-form-checkbox>
      </div>
      <div class="image-editor-actions image-editor-actions--bg-actions">
        <button
          v-if="options.isSelectImageFromBackground"
          v-b-tooltip.hover
          :title="resolveScaleImageAction('width').tooltip"
          @click="onChange(resolveScaleImageAction('width').action, 'width')"
        >
          <img src="/img/icons/expand.svg">
        </button>
        <button
          v-if="options.isSelectImageFromBackground"
          v-b-tooltip.hover
          :title="resolveScaleImageAction('height').tooltip"
          @click="onChange(resolveScaleImageAction('height').action, 'height')"
        >
          <img
            style="transform: rotate(90deg);"
            src="/img/icons/expand.svg"
          >
        </button>
        <button
          v-if="options.isSelectImageFromBackground"
          v-b-tooltip.hover
          :title="resolveScaleImageAction('default').tooltip"
          @click="onChange(resolveScaleImageAction('default').action, resolveScaleImageAction().scaleParam)"
        >
          <img
            src="/img/icons/full-screen.svg"
          >
        </button>
        <button
          v-b-tooltip.hover
          :title="resolveCenterImageAction.tooltip"
          @click="onChange(resolveCenterImageAction.action, resolveCenterImageAction)"
        >
          <img
            src="/img/icons/center-image.svg"
            alt="center-icon"
          >
        </button>
        <button
          v-b-tooltip.hover
          class="flip-x"
          title="Flip horizontal"
          @click="onChange('flipX')"
        >
          <img src="/img/icons/horizontal-flip.svg">
        </button>
        <button
          v-b-tooltip.hover
          class="flip-y"
          title="Flip vertical"
          @click="onChange('flipY')"
        >
          <img src="/img/icons/vertical-flip.svg">
        </button>
        <button
          v-b-tooltip.hover
          class="crop"
          title="Crop"
          @click="isCropMode = true"
        >
          <svg
            class="x-svg"
            width="22"
            height="22"
          >
            <use href="/img/icons/sprite.svg#crop" />
          </svg>
        </button>
        <button
          v-if="target.originalPathForRestoreAfterCrop"
          v-b-tooltip.hover
          class="restore-original undo-button"
          title="Restore original image (crop)"
          @click="restoreOriginalAfterCrop"
        >
          <img
            src="/img/icons/reset.svg"
            alt="restore"
          >
        </button>
        <button
          v-if="isBgThemeImage"
          v-b-tooltip.hover
          class="remove-gb"
          title="Remove background"
          @click="handleRemoveBackground"
        >
          <img src="/img/icons/remove-bg.svg">
        </button>
      </div>
    </div>
    <b-button
      variant="danger"
      @click="onChange('deleteConfirmation')"
    >
      <b-icon icon="trash" />
      Delete
    </b-button>
    <button
      v-b-tooltip.hover="'Mask'"
      :data-id="selectedInstance.canvas.instance_id"
      class="btn btn-primary mask-settings"
    >
      <b-icon icon="mask" />
    </button>

    <LockToggle />
    
    <SimpleTableBuilderModal
      ref="simpleTableBuilderModal"
    />
  </div>
</template>

<script>
import ThemesContainer from '../../../../../banner_template/themes/ThemesContainer';
import { editorControlEventBroker, EVENTS } from '../../../../group/modules/editor-control-popup/event-broker';
import ProductImageDropdown from './components/ProductImageDropdown.vue';
import { LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE } from '../../../../group/modules/editor-control-popup/selected-template-for-apply-to-all';
import CropImage from './components/CropImage.vue';
import ColorPicker from './components/ColorPicker.vue';
import PositionAndSize from './components/PositionAndSize.vue'
import { mapGetters } from 'vuex'
import LockToggle from '@frontend/components/modules/layouts-page/EditorControl/components/LockToggle.vue'
import ApplyToAllImageDropdown from '@frontend/components/modules/layouts-page/EditorControl/components/ApplyToAllImageDropdown.vue';
import { DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES } from '@frontend/group/modules/additional-fields'
import FileUploadService from '@frontend/services/api/file-upload';
import {
  ADDITIONAL_BACKGROUND_THEME_IMAGE_PREFIX_ID,
  BACKGROUND_THEME_IMAGE_PREFIX_ID
} from "@frontend/constants/templates-field-prefixes";
import { GLOBAL_UPDATE_LOCAL_STORAGE_KEY } from "@frontend/constants/global-update";
import ObjectFillFit from "@frontend/components/modules/layouts-page/EditorControl/components/ObjectFillFit.vue";
import { parseJsonData } from '@frontend/services/helpers';
import { initDataBuilderTable } from '@frontend/group/modules/simple-table-builder/helpers';

const DEBOUNCE_UPDATE_TIME = 50;

const IMAGE_EDITOR_ACTIONS = {
  SET_TEMPLATE_SIZE_BY_WIDTH_OR_HEIGHT: 'setTemplateSizeByWidthOrHeight',
  SET_BY_SMART_OBJECT_WIDTH_OF_HEIGHT: 'setBySmartObjectWidthOrHeight',
  CENTER_IMAGE: 'centerImage',
}

const SCALE_ACTIONS_MAP = {
  [IMAGE_EDITOR_ACTIONS.SET_TEMPLATE_SIZE_BY_WIDTH_OR_HEIGHT]: {
    width: 'Set to template width',
    height: 'Set to template height',
    default: 'Scale to template size'
  },
  [IMAGE_EDITOR_ACTIONS.SET_BY_SMART_OBJECT_WIDTH_OF_HEIGHT]: {
    width: 'Set to SmartObject width',
    height: 'Set to SmartObject height',
    default: 'Scale to SmartObject size'
  }
}

const BG_THEME_PREFIXES = [
  BACKGROUND_THEME_IMAGE_PREFIX_ID,
  ADDITIONAL_BACKGROUND_THEME_IMAGE_PREFIX_ID
];

const CENTER_ACTIONS_MAP = {
  CENTER_BY_TEMPLATE_DIMENSIONS: 'Center to template size',
  CENTER_BY_SMART_OBJECT_DIMENSIONS: 'Center to SmartObject size',
}

const PSD_FILE_TYPE = 'image/vnd.adobe.photoshop';

export default {
  components: {
    ObjectFillFit,
    ApplyToAllImageDropdown,
    LockToggle,
    PositionAndSize,
    ProductImageDropdown,
    CropImage,
    ColorPicker
},
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    themes: {
      type: ThemesContainer,
      default: () => new ThemesContainer()
    },
    target: {
      type: fabric.Object,
      default: () => {}
    },
  },
  data() {
    return {
      LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE,
      isCropMode: false,
      objectInput: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        fileIds: '',
        isAutoPosition: false,
        isApplyToAll: false,
        fileOutlineId: '',
        fileOutlineColor: '#000000',
      },
      selectedTheme: null,
      opacity: null,
      scale: null,
      themesOptions: [],
    }
  },
  computed: {
    ...mapGetters('layout', [
      'selectedInstance',
    ]),
    isScaleDisabled() {
      if (this.target.id.startsWith(BACKGROUND_THEME_IMAGE_PREFIX_ID)) {
        const {objectFitSettings, objectFillSettings} = this.target;
        return !!objectFitSettings.align_to_fit || !!objectFillSettings.align_to_fill;
      }
      return false;
    },
    outlineData() {
      return {
        productId: this.objectInput.fileOutlineId,
        color: this.objectInput.fileOutlineColor
      }
    },
    isSmartObject() {
      return !!this.selectedInstance.canvas.getActiveObject()?.groupName;
    },
    getSmartObject() {
      return this.selectedInstance.canvas
        .getObjects()
        .find(obj => obj.groupName === this.selectedInstance.canvas.getActiveObject().groupName && obj.id === 'mask');
    },
    resolveCenterImageAction() {
      let tooltip =  CENTER_ACTIONS_MAP.CENTER_BY_TEMPLATE_DIMENSIONS;
      let centerPoint = this.selectedInstance.canvas.getCenter();

      if (this.isSmartObject) {
        tooltip = CENTER_ACTIONS_MAP.CENTER_BY_SMART_OBJECT_DIMENSIONS;
        centerPoint = this.getSmartObject.getCenterPoint();
      }

      return {
        action: IMAGE_EDITOR_ACTIONS.CENTER_IMAGE,
        tooltip,
        centerPoint,
        isSmartObject: this.isSmartObject
      }
    },
    cThemesOptions() {
      return [...this.themesOptions];
    },
    imageName () {
      return this.target?.templateField?.name || this.target?.name
    },
    imageSrc () {
      return this.target?.getSrc()
    },
    imageIsDefault () {
      return this.imageSrc?.includes(DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES['additional_background_images'])
    },
    isBgThemeImage() {
      return BG_THEME_PREFIXES.some(prefix => this.target.id.startsWith(prefix));
    }
  },
  watch: {
    ...(
      [
        'target.left',
        'target.top',
        'target.width',
        'target.height',
        'target.fileName',
        'target.fileOutlineId',
        'target.color'
      ].reduce(
        (acc, key) => {
          acc[key] = _.debounce(function () {
            this.initTargetData();
          }, DEBOUNCE_UPDATE_TIME)

          return acc;
        },
        {}
      )
    ),
  },
  mounted() {
    this.initTargetData();
    this.getThemesOptions();
  },
  methods: {
    async getThemesOptions() {
      const {data} = await axios.get(
        window.location.origin 
        + `/banner/get-template-themes?instance_id=${this.target.canvas.instance_id}` 
        + `&layout_id=${$("#template-group-preview").attr("data-layout-id")}`
      );
      this.themesOptions = data;
    },
    restoreOriginalAfterCrop() {
      console.log('restoreOriginalAfterCrop')
      editorControlEventBroker.fire(EVENTS.IMAGE_CHANGED, {
        action: 'undo',
        value: {
          target: this.target,
        },
      })
    },
    initTargetData() {
      const { x, y } = this.target.getPointByOrigin('left', 'top');

      this.objectInput.left = x.toFixed(2);
      this.objectInput.top = y.toFixed(2);
      this.objectInput.width = this.target.getScaledWidth().toFixed(2);
      this.objectInput.height = this.target.getScaledHeight().toFixed(2);

      if (this.target.group) {
        const matring = [...this.target.calcTransformMatrix()]

        this.objectInput.top = (matring.pop() - this.objectInput.height / 2).toFixed(2)
        this.objectInput.left = (matring.pop() - this.objectInput.width / 2).toFixed(2)
      }

      this.objectInput.isAutoPosition = !!this.target.isAutoPosition;
      this.objectInput.fileIds = this.target.fileName;
      this.objectInput.isApplyToAll = this.target.isApplyToAll;

      this.selectedTheme = this.options.selectedThemeId;
      this.objectInput.fileOutlineId = this.target.fileOutlineId;
      this.objectInput.fileOutlineColor = this.target.color;
      this.opacity = this.target.opacity ?? 1
      this.scale = parseFloat(this.target.scaleX ?? 1).toFixed(2)
    },
    onChange(action, value) {
      editorControlEventBroker.fire(EVENTS.IMAGE_CHANGED, {
        action, value
      })
    },
    resolveScaleImageAction(scaleParam = null) {
      const action = this.isSmartObject
        ? IMAGE_EDITOR_ACTIONS.SET_BY_SMART_OBJECT_WIDTH_OF_HEIGHT
        : IMAGE_EDITOR_ACTIONS.SET_TEMPLATE_SIZE_BY_WIDTH_OR_HEIGHT;

      if (!scaleParam) {
        const canvas = action === IMAGE_EDITOR_ACTIONS.SET_TEMPLATE_SIZE_BY_WIDTH_OR_HEIGHT
          ? this.selectedInstance.canvas
          : this.getSmartObject;

        const aspectRatio = canvas.width / canvas.height;

        scaleParam = aspectRatio < 1 ? 'height' : 'width';
      }

      return {
        action,
        tooltip: SCALE_ACTIONS_MAP[action][scaleParam],
        scaleParam
      }
    },
    async handleRemoveBackground() {
      try {
        toastr.info(
          `<div class="d-inline-flex align-content-center">Removing background...<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div></div>`,
          null,
          {
            timeOut: 0,
            tapToDismiss: false,
            closeButton: false
          })
        
        const filePath = new URL(this.target.getSrc()).searchParams.get('file');
        
        const { data } = await FileUploadService.removeImageBackground({ filePath });
        
        this.onChange('removeBackgroundThemeImgBG', {
          imgUrl: `/share?file=${data.imgUrl}`,
          objectId: this.target.id,
          instanceId: this.selectedInstance.id,
        });
        
        toastr.clear();
        
      } catch (error) {
        toastr.clear();
        toastr.error('Something went wrong while removing background');
        
      }
    },
    isPSDFileType(file) {
      return file.type === PSD_FILE_TYPE
        || file.name.split('.').pop().toLowerCase() === 'psb'
        || file.name.split('.').pop().toLowerCase() === 'psd';
    },
    async onDrop(event) {
      if (this.options.isSelectImageFromBackground) {
        this.onImageChange('selectBackground');
        const droppedFiles = event.dataTransfer.files;
        const $newBackgroundImage = $('#new-background-image');
        const $selectBkImgModal = $('#selectBkImgModal');
        const $buttonSelect = $("#selectBkImgModal #submit");
        const $compressCheckbox = $("#compress_check")
        const $fileCaption = $newBackgroundImage.closest(".input-group").find(".file-caption-name")

        $newBackgroundImage.fileinput('readFiles', droppedFiles);
        $newBackgroundImage[0].files = droppedFiles;

        $fileCaption.val(`${droppedFiles.length} file selected`);
        $fileCaption.addClass("is-valid");

        if (droppedFiles.length) {
          droppedFiles?.length && this.isPSDFileType(droppedFiles[0])
            ? $compressCheckbox.prop('checked', false)
            : $compressCheckbox.prop('checked', true);

          $buttonSelect.hasClass('active')
            ? $buttonSelect.removeClass('active')
            : $buttonSelect.addClass('active');


          $selectBkImgModal.find('#upload').show();
          $selectBkImgModal.find('.compress_check, .crop_check').show();

        } else {
          $selectBkImgModal.find('#upload').hide();
          $selectBkImgModal.find('.compress_check, .crop_check').hide();
        }
      }

      localStorage.setItem(GLOBAL_UPDATE_LOCAL_STORAGE_KEY, false);

      this.$root.$emit('productImageReplace', event)

      $('.b-sidebar').css({ border: 'none' });
    },
    onDragEnter() {
      $('.b-sidebar').css({ border: '1px solid blue' });
    },
    onDragLeave(e, forcibly = false) {
      $('.b-sidebar').css({ border: "none" });
    },
    onDragOver() {
      $('.b-sidebar').css({ border: "1px solid blue" });
    },
    onImageChange(action, value) {
      editorControlEventBroker.fire(EVENTS.IMAGE_CHANGED, {
        action, value
      })
    },
    initTableEditor() {
      const data = this.target.html_table_data.find((data) => {
        const table = parseJsonData(data, null);
        return table.id === this.target.field_id;
      });
      
      const table = initDataBuilderTable(parseJsonData(data, null));
      
      this.$refs.simpleTableBuilderModal.init({
        table,
        params: this.options.params,
        tableData: this.options.tableData
      });
    },
  }
}
</script>

<style scoped lang="scss">
.img-preview {
  max-width: 65%;
  max-height: 38px;
}
</style>
