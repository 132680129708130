<template>
  <div class="container-fluid mt-2">
    <div
      v-for="adUnit in units"
      :key="adUnit"
      class="row d-flex align-items-center m-3"
    >
      <span class="label m-0">
        <p class="badge badge-success m-0">{{ adUnitTypes[adUnit] }}</p>
      </span>
      <button
        class="btn btn-primary btn-sm mx-2"
        @click="selectImage(adUnit)"
      >
        <span>
          <i
            v-if="selectedImages[adUnit] && selectedImages[adUnit].selected"
            class="fas fa-check mr-2"
          />
          {{ selectedImages[adUnit] && selectedImages[adUnit].selected ? 'Image Selected' : 'Select Image' }}
        </span>
      </button>
      <button
        class="btn btn-info btn-sm"
        @click="selectLogo(adUnit)"
      >
        <i
          v-if="selectedLogos[adUnit] && selectedLogos[adUnit].selected"
          class="fas fa-check mr-2"
        />
        {{ selectedLogos[adUnit] && selectedLogos[adUnit].selected ? 'Logo Selected' : 'Select Logo' }}
      </button>
      <div
        v-if="adUnit === units[0] && checkGalleryDesktopAssets()"
        class="d-flex align-items-center justify-content-center mt-2"
      >
        <span>If you don't select others, these files will be used for all Ad Units.</span>
      </div>
    </div>

    <div>
      <AssetModal
        ref="assetModal"
        :ad-unit="selectedAdUnit"
        @close-modal="closeModal"
        @asset-selected="handleAssetSelection"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { adUnits, adUnitTypes } from "@frontend/constants/walmart-constants.js";
import AssetModal from "@frontend/components/modals/AssetModal.vue";
import { ACTIONS } from "@frontend/store/walmart";

const { mapGetters, mapActions } = createNamespacedHelpers('walmart');

export default {
  name: "WalmartAssetSelector",
  components: {
    AssetModal
  },
  data() {
    return {
      adUnits,
      adUnitTypes,
      units: Object.keys(adUnitTypes),
      selectedImages: {},
      selectedLogos: {},
      selectedAdUnit: null
    };
  },
  computed: {
    ...mapGetters(['getAssets'])
  },
  mounted() {
    this.selectedImages = this.initializeSelections(this.units);
    this.selectedLogos = this.initializeSelections(this.units);
  },
  methods: {
    ...mapActions({
      saveAsset: ACTIONS.SAVE_ASSET,
      removeAsset: ACTIONS.REMOVE_ASSET
    }),
    initializeSelections(units) {
      return units.reduce((acc, adUnit) => {
        acc[adUnit] = { type: adUnit, imageType: null, selected: false };
        return acc;
      }, {});
    },
    selectImage(adUnit) {
      const selectedImage = this.selectedImages[adUnit];

      if (selectedImage.selected) {
        selectedImage.selected = false;

        this.removeAsset({ type: adUnit, imageType: 'image' });
      } else {
        selectedImage.imageType = 'image';

        this.selectedAdUnit = selectedImage;

        this.openModal();
      }
    },
    selectLogo(adUnit) {
      const selectedLogo = this.selectedLogos[adUnit];
      if (selectedLogo.selected) {
        selectedLogo.selected = false;

        this.removeAsset({ type: adUnit, imageType: 'logo' });
      } else {
        selectedLogo.imageType = 'logo';

        this.selectedAdUnit = selectedLogo;

        this.openModal();
      }
    },
    handleAssetSelection(asset) {
      if (asset.imageType === 'image') {
        const selectedImage = Object.values(this.selectedImages).find(
          (item) => item.type === asset.type && item.imageType === asset.imageType
        );

        this.saveAsset(asset);

        selectedImage.selected = true;
      }

      if (asset.imageType === 'logo') {
        const selectedLogo = Object.values(this.selectedLogos).find(
          (item) => item.type === asset.type && item.imageType === asset.imageType
        );

        this.saveAsset(asset);

        selectedLogo.selected = true;
      }
      this.selectedAdUnit = null;

      if (this.checkGalleryDesktopAssets()) {
        toastr.success('Now You can create the Creative with this assets');
      }
    },
    openModal() {
      this.$refs.assetModal.init();
    },
    closeModal() {
      // console.log('Modal closed');
    },
    checkGalleryDesktopAssets() {
      const assets = this.getAssets;
      const hasImage = assets.some(
        (asset) => asset.type === this.adUnits.GALLERY_DESKTOP && asset.imageType === 'image'
      );
      const hasLogo = assets.some(
        (asset) => asset.type === this.adUnits.GALLERY_DESKTOP && asset.imageType === 'logo'
      );

      if (hasImage && hasLogo) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.label {
  flex: 1;
}
</style>