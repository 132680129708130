var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-module d-flex flex-column" }, [
    _c("nav", { staticClass: "nav nav-pills nav-fill" }, [
      _c(
        "a",
        {
          staticClass: "nav-link",
          class: { active: _vm.selectedTab === "ai-editor" },
          attrs: { href: "javascript:void(0);" },
          on: {
            click: function ($event) {
              _vm.selectedTab = "ai-editor"
            },
          },
        },
        [_vm._v("AI Editor")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "nav-link",
          class: { active: _vm.selectedTab === "ai-analyst" },
          attrs: { href: "javascript:void(0);" },
          on: {
            click: function ($event) {
              _vm.selectedTab = "ai-analyst"
            },
          },
        },
        [_vm._v("AI Analyst")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex flex-column flex-grow-1 pt-2" },
      [
        _c("AiEditorTab", {
          attrs: {
            hidden: _vm.selectedTab !== "ai-editor",
            "target-element": _vm.targetElement,
            "is-tab-active": _vm.isTabActive,
          },
        }),
        _vm._v(" "),
        _c("AiAnalystTab", {
          attrs: {
            hidden: _vm.selectedTab !== "ai-analyst",
            "target-element": _vm.targetElement,
            "is-tab-active": _vm.isTabActive,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }