import axios from "axios";

import Resumable from "resumablejs";
import parsePSDData, {recursiveFind, recursiveWalker} from "@/includes/parse_psd_data";
import {FileFormURL} from "@/includes/file-form-url";
import {setTemplateFields} from "@/includes/data-model-mapper";
import { EVENTS as PSD_LAYERS_EVENT, EVENTS, psdLayersEventBroker } from "@frontend/group/modules/psd-layers/event-broker";
import {ApplyStrategy} from "@frontend/services/api/psd-layers";
import PSDLayerService from "@frontend/services/api/psd-layers";
import Base from "@/includes/parse_psd_data/psd-types/base";
import {PSD_LAYER_TYPE_TO_BASIC_MAP} from "@/includes/parse_psd_data/constants";
import { PARSED_FIELD_TYPES } from "@/includes/parse_psd_data/constants";
import { getImageDimensions } from "@frontend/services/helpers";
import BackgroundImage from "@/includes/parse_psd_data/psd-types/background-image";

import {Folder} from "@/includes/parse_psd_data/psd-types/folder";
import {Layer} from "@/includes/parse_psd_data/psd-types/layer";
import {
    getAutoMappedFieldType,
    isSourceNodeMappable
} from "@frontend/store/psd-layers/features/auto-map";
import { matchesBoundingBox } from "./features/product-recognition";
import { FIELD_TYPE_PRODUCT_IMAGE, FIELD_TYPE_BACKGROND_THEME_IMAGE } from '@frontend/constants/type-fields-of-template';
import { setNestedValue } from '@frontend/lib/utils/object/set-nested-value';
import _ from "lodash";
import {getNestedValue} from "@frontend/lib/utils/object/get-nested-value";
import Vue from "vue";
import { updateFileState } from "@frontend/store/psd-layers/helpers/update-file-state";
import { getFormattedFieldName } from "@frontend/store/psd-layers/features/field-name-formatter";
import {PSD_PARSE_CHANNEL_PREFIX, PSD_PARSE_EVENTS} from "@frontend/constants/psd-parse-events";
import {PSD_STATUS} from "@frontend/constants/psd-status";


const initialState = () => ({
    files: [],
    currentPreviewIndex: 0,
    isGenerating: false,
    themeFields: {},
    isMultipleTemplateSelectionType: false,
    flow: ApplyStrategy.FLOWS.ADD_TO_TEMPLATE,
    isImportIntoTemplate: false,
    isTemplateFeatures: false,
    isReparse: false,
});

const initialFileStateData = {
    isUseProductImagesFromPSD: false,
    previewTemplateThumbnail: '',
    entireImageThumbnail: '',
    entireImage: '',
    filename: '',
    originalFileName: '',
    parsedPsdModel: [],
    isLoading: true,
    isQueued: false,
    templates: [],
    selectedTemplates: [],
    isUseSourceTextSettings: false,
    templateFieldsByType: [],
    nodesChanges: [],
    mergingNodes: [],
    progress: 0,
    isError: false,
    errorMessage: 'Something went wrong',
    layersToImport: [],
    isUpdatingPreviewTemplate: false,
    isReplaceExistingProduct: false,
    isAutoMapToFields: false,
    isRemovePlaceholderText: false,
    fieldsToMap: [],
    recognizedProducts: [],
    mappedFieldIds: [],
    isProductRecognitionProcessing: false,
    isSavingNodesChanges: false,
    isCreateIndividualAssets: false,
    isTreatMultipleSpacesAsSingle: false,
    isSavePsd: true,
    isExtractingAssets: false,
    status: null,
}

const initialFileState = (args) => ({
    ...initialFileStateData,
    ...args,
});

export const MUTATIONS = {
    RESET: 'RESET',
    SET_LAYERS_TO_IMPORT: 'SET_LAYERS_TO_IMPORT',
    SET_FILENAME: 'SET_FILENAME',
    SET_SELECTED_TEMPLATES: 'SET_SELECTED_TEMPLATES',
    SET_TEMPLATES: 'SET_TEMPLATES',
    TOGGLE_LAYER: 'TOGGLE_LAYER',
    SELECT_LAYER: 'SELECT_LAYER',
    TOGGLE_ALL: 'TOGGLE_ALL',
    SET_PROGRESS: 'SET_PROGRESS',
    SET_TEMPLATE_FIELDS_BY_TYPE: 'SET_TEMPLATE_FIELDS_BY_TYPE',
    SET_PARSED_PSD_MODEL: 'SET_PARSED_PSD_MODEL',
    SET_LOADING: 'SET_LOADING',
    SET_ORIGINAL_FILENAME: 'SET_ORIGINAL_FILENAME',
    SET_ERROR: 'SET_ERROR',
    SET_GENERATING: 'SET_GENERATING',
    SET_UPDATING_PREVIEW_TEMPLATE: 'SET_UPDATING_PREVIEW_TEMPLATE',
    SET_SAVE_PSD: 'SET_SAVE_PSD',
    SET_THEME_FIELDS: 'SET_THEME_FIELDS',
    SET_CREATE_INDIVIDUAL_ASSETS: 'SET_CREATE_INDIVIDUAL_ASSETS',
    SET_IMPORT_TEXT_FIELDS_AS_TEXT: 'SET_IMPORT_TEXT_FIELDS_AS_TEXT',
    SET_TREAT_MULTIPLE_SPACES_AS_SINGLE: 'SET_TREAT_MULTIPLE_SPACES_AS_SINGLE',
    SET_FLOW: 'SET_FLOW',
    SET_ENTIRE_IMAGE_THUMBNAIL: 'SET_ENTIRE_IMAGE_THUMBNAIL',
    SET_PREVIEW_TEMPLATE_THUMBNAIL: 'SET_PREVIEW_TEMPLATE_THUMBNAIL',
    SET_ENTIRE_IMAGE: 'SET_ENTIRE_IMAGE',
    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
    SET_MULTIPLE_TEMPLATE_SELECTION_TYPE: 'SET_MULTIPLE_TEMPLATE_SELECTION_TYPE',
    SET_VIEW_TYPE: 'SET_VIEW_TYPE',
    SET_FIELD: 'SET_FIELD',
    UPDATE_LAYER: 'UPDATE_LAYER',
    SET_IS_IMPORT_INTO_TEMPLATE: 'SET_IS_IMPORT_INTO_TEMPLATE',
    SET_IS_REPLACE_EXISTING_PRODUCT: 'SET_IS_REPLACE_EXISTING_PRODUCT',
    SET_AUTO_MAP_TO_FIELDS: 'SET_AUTO_MAP_TO_FIELDS',
    SET_REMOVE_PLACEHOLDER_TEXT: 'SET_REMOVE_PLACEHOLDER_TEXT',
    SET_FIELDS_TO_MAP: 'SET_FIELDS_TO_MAP',
    SET_LOGO_FIELD_URL: 'SET_LOGO_FIELD_URL',
    SET_TEMPLATE_FEATURES: 'SET_TEMPLATE_FEATURES',
    REPLACE_NODE: 'REPLACE_NODE',
    ADD_MERGING_NODE: 'ADD_MERGING_NODE',
    REMOVE_MERGING_NODE: 'REMOVE_MERGING_NODE',
    SET_FOR_ALL_PARENTS: 'SET_FOR_ALL_PARENTS',
    SET_USE_SOURCE_TEXT_SETTINGS: 'SET_USE_SOURCE_TEXT_SETTINGS',
    SET_RECOGNIZED_PRODUCTS: 'SET_RECOGNIZED_PRODUCTS',
    SET_NODES_CHANGES: 'SET_NODES_CHANGES',
    SET_IS_SAVING_NODES_CHANGES: 'SET_IS_SAVING_NODES_CHANGES',
    UPDATE_MAPPED_FIELD_IDS: 'UPDATE_MAPPED_FIELD_IDS',
    SET_IS_PRODUCT_RECOGNITION_PROCESSING: 'SET_IS_PRODUCT_RECOGNITION_PROCESSING',
    SET_IS_USE_PRODUCT_IMAGES_FROM_PSD: 'SET_IS_USE_PRODUCT_IMAGES_FROM_PSD',
    INIT_FILE_STATE: 'INIT_FILE_STATE',
    SET_CURRENT_PREVIEW_INDEX: 'SET_CURRENT_PREVIEW_INDEX',
    SET_IS_EXTRACTING_ASSETS: 'SET_IS_EXTRACTING_ASSETS',
    ADD_TEMPLATE: 'ADD_TEMPLATE',
    REMOVE_NODE: 'REMOVE_NODE',
    SET_IS_REPARSE: 'SET_IS_REPARSE',
    SET_IS_QUEUED: 'SET_IS_QUEUED',
    SET_STATUS: 'SET_STATUS',
};

let cancelToken;
let resumable;

const getFileStateProperty = (state, key) => {
    const fileState = state.files[state.currentPreviewIndex];
    return fileState ? fileState[key] : initialFileStateData[key];
};

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        isGenerating: state => state.isGenerating,
        flow: state => state.flow,
        isImportIntoTemplate: state => state.isImportIntoTemplate,
        isTemplateFeatures: state => state.isTemplateFeatures,
        isMultipleTemplateSelectionType: state => state.isMultipleTemplateSelectionType,
        files: state => state.files,
        currentPreviewIndex: state => state.currentPreviewIndex,
        isCreateIndividualAssets: state => getFileStateProperty(state, 'isCreateIndividualAssets'),
        isTreatMultipleSpacesAsSingle: state => getFileStateProperty(state, 'isTreatMultipleSpacesAsSingle'),
        isSavingNodesChanges: state => getFileStateProperty(state, 'isSavingNodesChanges'),
        isProductRecognitionProcessing: state => getFileStateProperty(state, 'isProductRecognitionProcessing'),
        mappedFieldIds: state => getFileStateProperty(state, 'mappedFieldIds'),
        recognizedProducts: state => getFileStateProperty(state, 'recognizedProducts'),
        isRemovePlaceholderText: state => getFileStateProperty(state, 'isRemovePlaceholderText'),
        isAutoMapToFields: state => getFileStateProperty(state, 'isAutoMapToFields'),
        isReplaceExistingProduct: state => getFileStateProperty(state, 'isReplaceExistingProduct'),
        isUpdatingPreviewTemplate: state => getFileStateProperty(state, 'isUpdatingPreviewTemplate'),
        progress: state => getFileStateProperty(state, 'progress'),
        isError: state => getFileStateProperty(state, 'isError'),
        errorMessage: state => getFileStateProperty(state, 'errorMessage'),
        entireImage: state => getFileStateProperty(state, 'entireImage'),
        entireImageThumbnail: state => getFileStateProperty(state, 'entireImageThumbnail'),
        previewTemplateThumbnail: state => getFileStateProperty(state, 'previewTemplateThumbnail'),
        selectedTemplates: state => getFileStateProperty(state, 'selectedTemplates'),
        templates: state => getFileStateProperty(state, 'templates'),
        isLoading: state => getFileStateProperty(state, 'isLoading'),
        templateFieldsByType: state => getFileStateProperty(state, 'templateFieldsByType'),
        isSavePsd: state => getFileStateProperty(state, 'isSavePsd'),
        mergingNodes: state => getFileStateProperty(state, 'mergingNodes'),
        isUseSourceTextSettings: state => getFileStateProperty(state, 'isUseSourceTextSettings'),
        filename: state => getFileStateProperty(state, 'filename'),
        parsedPsdModel: state => getFileStateProperty(state, 'parsedPsdModel'),
        isUseProductImagesFromPSD: state => getFileStateProperty(state, 'isUseProductImagesFromPSD'),
        originalFileName: state => getFileStateProperty(state, 'originalFileName'),
        nodesChanges: state => getFileStateProperty(state, 'nodesChanges'),
        isExtractingAssets: state => getFileStateProperty(state, 'isExtractingAssets'),
        isQueued: state => getFileStateProperty(state, 'isQueued'),
        status: state => getFileStateProperty(state, 'status'),
    },
    mutations: {
        [MUTATIONS.RESET](_state) {
            const initial = {
              ...initialState(),
              isImportIntoTemplate: _state.isImportIntoTemplate
            };

            Object.keys(initial).forEach(key => {
                _state[key] = initial[key]
            })
        },
        [MUTATIONS.INIT_FILE_STATE](state, { fileState, index }) {
            Vue.set(state.files, index, fileState);
        },
        [MUTATIONS.SET_CURRENT_PREVIEW_INDEX](state, payload) {
            state.currentPreviewIndex = payload;
        },
        [MUTATIONS.SET_TEMPLATE_FEATURES](state, payload) {
            state.isTemplateFeatures = payload
        },
        [MUTATIONS.SET_FILENAME](state, { index, filename }) {
            const updater = (fileState) => fileState.filename = filename;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_SELECTED_TEMPLATES](state, { index, selectedTemplates }) {
            const updater = (fileState) => fileState.selectedTemplates = selectedTemplates || [];

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_TEMPLATES](state, { index, templates }) {
            const updater = (fileState) => {
                fileState.templates = templates;

                if (templates.length === 1) {
                    fileState.selectedTemplates = templates;
                }
            }

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.TOGGLE_LAYER](state, { index, layer }) {
            const updater = (fileState) => {
                recursiveWalker(fileState.templates, node => {
                    if (node.uuid === layer.uuid) {
                        node.visible = !node.visible;

                        if (node.visible) {
                            let _node = node.parent;

                            while (!!_node) {
                                _node.visible = true;
                                _node = _node.parent;
                            }
                        }

                        if (node.children) {
                            recursiveWalker(
                              node.children,
                              _node => _node.visible = node.visible
                            )
                        }
                    }
                })
            }

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.TOGGLE_ALL](state, { index, selected }) {
            const updater = (fileState) => {
                recursiveWalker(fileState.templates, node => {
                    node.visible = selected;
                })
            }

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SELECT_LAYER](state, { index, layer }) {
            const updater = (fileState) => {
                recursiveWalker(fileState.templates, node => {
                    if (node.uuid === layer.uuid) {
                        node.visible = true;

                        let _node = node;

                        while (!!_node) {
                            _node.visible = true;
                            _node = _node.parent;
                        }
                    }
                })
            }

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_PROGRESS](state, { index, progress }) {
            const updater = (fileState) => fileState.progress = progress;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_TEMPLATE_FIELDS_BY_TYPE](state, { index, templateFieldsByType }) {
            const updater = (fileState) => fileState.templateFieldsByType = templateFieldsByType;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_PARSED_PSD_MODEL](state, { index, parsedPsdModel }) {
            const updater = (fileState) => fileState.parsedPsdModel = parsedPsdModel;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_LOADING](state, { index, isLoading }) {
            const updater = (fileState) => fileState.isLoading = isLoading;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_ORIGINAL_FILENAME](state, { index, originalFileName}) {
            const updater = (fileState) => fileState.originalFileName = originalFileName;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_ERROR](state, { index, isError}) {
            const updater = (fileState) => fileState.isError = isError;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_GENERATING](state, payload) {
            state.isGenerating = payload;
        },
        [MUTATIONS.SET_SAVE_PSD](state, { index, isSavePsd }) {
            const updater = (fileState) => fileState.isSavePsd = isSavePsd;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_THEME_FIELDS](state, payload) {
            state.themeFields = payload;
        },
        [MUTATIONS.SET_CREATE_INDIVIDUAL_ASSETS](state, { index, isCreateIndividualAssets }) {
            const updater = (fileState) => fileState.isCreateIndividualAssets = isCreateIndividualAssets;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_TREAT_MULTIPLE_SPACES_AS_SINGLE](state, { index, isTreatMultipleSpacesAsSingle }) {
            const updater = (fileState) => fileState.isTreatMultipleSpacesAsSingle = isTreatMultipleSpacesAsSingle;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_FLOW](state, payload) {
            state.flow = payload;
        },
        [MUTATIONS.SET_ENTIRE_IMAGE_THUMBNAIL](state, { index, entireImageThumbnail }) {
            const updater = (fileState) => fileState.entireImageThumbnail = entireImageThumbnail;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_PREVIEW_TEMPLATE_THUMBNAIL](state, { index, previewTemplateThumbnail }) {
            const updater = (fileState) => fileState.previewTemplateThumbnail = previewTemplateThumbnail;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_ENTIRE_IMAGE](state, { index, entireImage }) {
            const updater = (fileState) => fileState.entireImage = entireImage;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_ERROR_MESSAGE](state, { index, errorMessage }) {
            const updater = (fileState) => {
                fileState.errorMessage = errorMessage;
                fileState.isError = true;
            };

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_MULTIPLE_TEMPLATE_SELECTION_TYPE](state, payload) {
            state.isMultipleTemplateSelectionType = payload;
        },
        [MUTATIONS.SET_LAYERS_TO_IMPORT](state, { index, layersToImport }) {
            const updater = (fileState) => fileState.layersToImport = layersToImport;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_FIELD](state, { index, uuid, field } ) {
            const updater = (fileState) => {
                const _layer = recursiveFind(fileState.templates, _layer => _layer.uuid === uuid);

                if (_layer) {
                    _.set(_layer, 'field', field)
                }
            };

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.UPDATE_LAYER](state, { index, layer, key, value }) {
            const updater = (fileState) => {
                const _layer = recursiveFind(fileState.templates, _layer => _layer.uuid === layer.uuid);

                if (_layer) {
                    _.set(_layer, key, value);
                }
            };

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_UPDATING_PREVIEW_TEMPLATE](state, { index, isUpdatingPreviewTemplate }) {
            const updater = (fileState) => fileState.isUpdatingPreviewTemplate = isUpdatingPreviewTemplate;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_IS_IMPORT_INTO_TEMPLATE](state, payload) {
            state.isImportIntoTemplate = payload;
        },
        [MUTATIONS.SET_IS_REPLACE_EXISTING_PRODUCT](state, { index, isReplaceExistingProduct }) {
            const updater = (fileState) => fileState.isReplaceExistingProduct = isReplaceExistingProduct;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_AUTO_MAP_TO_FIELDS](state, { index, isAutoMapToFields }) {
            const updater = (fileState) => fileState.isAutoMapToFields = isAutoMapToFields;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_REMOVE_PLACEHOLDER_TEXT](state, { index, isRemovePlaceholderText }) {
            const updater = (fileState) => fileState.isRemovePlaceholderText = isRemovePlaceholderText;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_FIELDS_TO_MAP](state, { index, fieldsToMap }) {
            const updater = (fileState) => fileState.fieldsToMap = fieldsToMap;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_LOGO_FIELD_URL](state, { index, logoUrl, width, height }) {
            const updater = (fileState) => {
                fileState.fieldsToMap = fileState.fieldsToMap.map(field => {
                    if (field.type === PARSED_FIELD_TYPES.USER_IMAGE) {
                        return {
                            ...field,
                            publicPath: logoUrl,
                            width,
                            height
                        }
                    }

                    return field;
                });
            }

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.REPLACE_NODE](state, { node, index, newNode }) {
            const updater = (fileState) => {
                recursiveWalker(fileState.templates, _node => {
                    if (_node instanceof Folder) {
                        const index = _node.children.findIndex(child => child.uuid === node.uuid)
                        if (index > -1) {
                            _node.children[index] = newNode
                            _node.children = [..._node.children]
                        }
                    }
                })
            };

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.ADD_MERGING_NODE](state, { index, uuid }) {
            const updater = (fileState) => fileState.mergingNodes.push(uuid);

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.REMOVE_MERGING_NODE](state, { index, uuid }) {
            const updater = (fileState) => fileState.mergingNodes.splice(fileState.mergingNodes.indexOf(uuid), 1);

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_USE_SOURCE_TEXT_SETTINGS](state, { index, isUseSourceTextSettings }) {
            const updater = (fileState) => fileState.isUseSourceTextSettings = isUseSourceTextSettings;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_RECOGNIZED_PRODUCTS](state, { index, recognizedProducts }) {
            const updater = (fileState) => fileState.recognizedProducts = recognizedProducts;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_NODES_CHANGES](state, { index, value }) {
            const updater = (fileState) => fileState.nodesChanges = value;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_IS_SAVING_NODES_CHANGES](state, { index, value }) {
            const updater = (fileState) => fileState.isSavingNodesChanges = value;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.UPDATE_MAPPED_FIELD_IDS](state, { index, value }) {
            const updater = (fileState) => fileState.mappedFieldIds.push(value);

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_IS_PRODUCT_RECOGNITION_PROCESSING](state, { index, value }) {
            const updater = (fileState) => fileState.isProductRecognitionProcessing = value;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_IS_USE_PRODUCT_IMAGES_FROM_PSD](state, { index, isUseProductImagesFromPSD }) {
            const updater = (fileState) => fileState.isUseProductImagesFromPSD = isUseProductImagesFromPSD;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_IS_EXTRACTING_ASSETS](state, { index, isExtractingAssets }) {
            const updater = (fileState) => fileState.isExtractingAssets = isExtractingAssets;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.ADD_TEMPLATE](state, { index, template }) {
            const updater = (fileState) => fileState.templates.push(template);

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.REMOVE_NODE](state, { index, node }) {
            const updater = (fileState) => {
                recursiveWalker(fileState.templates, _node => {
                    if (_node instanceof Folder) {
                        const index = _node.children.findIndex(child => child.uuid === node.uuid)
                        if (index > -1) {
                            _node.children.splice(index, 1)
                            _node.children = [..._node.children]
                        }
                    }
                })
            }

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_IS_REPARSE](state, payload) {
            state.isReparse = payload;
        },
        [MUTATIONS.SET_IS_QUEUED](state, { index, isQueued }) {
            const updater = (fileState) => fileState.isQueued = isQueued;

            updateFileState({ state, index, updater });
        },
        [MUTATIONS.SET_STATUS](state, { index, status }) {
            const updater = (fileState) => fileState.status = status;

            updateFileState({ state, index, updater });
        },
    },
    actions: {
        async loadFiles({ dispatch, commit }, _files) {
            const files = Array.isArray(_files) ? _files : [_files];

            files.forEach((file, index) => {
                commit(MUTATIONS.INIT_FILE_STATE, {
                    index,
                    fileState: initialFileState({
                        id: file.id,
                        filename: file.url,
                        originalFileName: `${file.name}.psd`
                    })
                });

                dispatch('initFileListeners', { fileId: file.id, index });

                switch (true) {
                    case file instanceof File:
                        dispatch('fromFile', {file, index});
                        break;
                    case file instanceof FileFormURL:
                        dispatch('fromUrl', {index});
                        break;
                }
            });
        },
        async fromFile({ commit, state, dispatch, getters }, { file, index }) {
            return new Promise((resolve) => {
                resumable = new Resumable({
                    target: '/file/psd_layer_split',
                    query: { _token: $('#psd-preview').data('csrf') },
                    chunkSize: 1024 * 10000, // in bytes
                    testChunks: false,
                    simultaneousUploads: 1,
                });

                resumable.addFile(file)

                resumable.on('fileAdded', () => {
                    resumable.upload();
                })

                resumable.on('uploadStart', () => {
                    commit(MUTATIONS.SET_PROGRESS, { progress: 0, index });
                })

                resumable.on('complete', () => {
                    commit(MUTATIONS.SET_PROGRESS, { progress: 1, index });
                })

                resumable.on('progress', () => {
                    const currentValue = Math.floor(resumable.progress() * 100) / 100;
                    commit(MUTATIONS.SET_PROGRESS, { progress: currentValue, index })
                })

                resumable.on('fileSuccess', async (r, response) => {
                    const fileState = state.files[index];

                    if (!fileState) {
                        return;
                    }

                    const data = JSON.parse(response);

                    const userLogo = fileState.fieldsToMap.find(field => field.type === PARSED_FIELD_TYPES.USER_IMAGE);

                    if (fileState.fieldsToMap.length && userLogo) {
                        await dispatch('uploadUsersLogo', { file: userLogo.value, filePath: data.parsePsdData.entireImage, index });
                    }

                    const parsePsdData = parsePSDData(data.parsePsdData, fileState.fieldsToMap)

                    commit(MUTATIONS.SET_ENTIRE_IMAGE_THUMBNAIL, { entireImageThumbnail: parsePsdData.entireImageThumbnail, index });
                    commit(MUTATIONS.SET_ENTIRE_IMAGE, { entireImage: parsePsdData.entireImageThumbnail, index });
                    commit(MUTATIONS.SET_FILENAME, { filename: data.fileName, index });
                    commit(MUTATIONS.SET_TEMPLATE_FIELDS_BY_TYPE, { templateFieldsByType: setTemplateFields(), index })
                    commit(MUTATIONS.SET_PARSED_PSD_MODEL, { parsedPsdModel: parsePsdData, index })
                    commit(MUTATIONS.SET_TEMPLATES, { templates: parsePsdData.templates, index })
                    commit(MUTATIONS.SET_LOADING, { isLoading: false, index })

                    if (state.isImportIntoTemplate) {
                        commit(MUTATIONS.TOGGLE_ALL, { selected: false, index });
                        commit(MUTATIONS.SET_REMOVE_PLACEHOLDER_TEXT, { isRemovePlaceholderText: true, index });
                    }

                    dispatch('setAutoMapFields', { index });

                    if (state.isTemplateFeatures) {
                        if (!state.isImportIntoTemplate) {
                            dispatch('formatFieldsNames', { index });
                        }

                        commit(MUTATIONS.SET_IS_USE_PRODUCT_IMAGES_FROM_PSD, { isUseProductImagesFromPSD: true, index });
                    }

                    resolve();
                })

                resumable.on('error', () => {
                    commit(MUTATIONS.SET_ERROR_MESSAGE, { errorMessage: initialState().errorMessage, index });
                    resolve();
                });
            })
        },
        async fromUrl({commit, dispatch, getters, state}, {index, isReparse = state.isReparse}) {
            const fileState = state.files[index];

            if (!fileState) {
                return;
            }

            if (  ![ApplyStrategy.FLOWS.SAVE_TO_FILE_BROWSER,
                ApplyStrategy.FLOWS.CREATE_TEMPLATE_FROM_ADMIN].includes(state.flow)) {
                commit(MUTATIONS.SET_SAVE_PSD, { isSavePsd: false, index })
            }

            try {
                const response = await axios.post('/file/preview_psd_file', {
                    filename: fileState.filename,
                    originFileName: fileState.originalFileName,
                    isReparse: isReparse,
                });

                const { status, data } = response.data;

                commit(MUTATIONS.SET_STATUS, { index, status: +status });

                if (!data) {
                    return;
                }

                const parsePsdData = parsePSDData(data.parsePsdData)
                commit(MUTATIONS.SET_ENTIRE_IMAGE_THUMBNAIL, {
                    entireImageThumbnail: parsePsdData.entireImageThumbnail || parsePsdData.entireImage,
                    index
                });
                commit(MUTATIONS.SET_ENTIRE_IMAGE, { entireImage: parsePsdData.entireImage, index });
                commit(MUTATIONS.SET_TEMPLATE_FIELDS_BY_TYPE, { templateFieldsByType: setTemplateFields(), index })
                commit(MUTATIONS.SET_PARSED_PSD_MODEL, { parsedPsdModel: parsePsdData, index })
                commit(MUTATIONS.SET_TEMPLATES, { templates: parsePsdData.templates , index })
                commit(MUTATIONS.SET_LOADING, { isLoading: false, index })

                if (state.isImportIntoTemplate) {
                    commit(MUTATIONS.TOGGLE_ALL, { selected: false, index });
                    commit(MUTATIONS.SET_REMOVE_PLACEHOLDER_TEXT, { isRemovePlaceholderText: true, index });
                }

                dispatch('setAutoMapFields', { index });

                if (state.isTemplateFeatures) {
                    if (!state.isImportIntoTemplate) {
                        dispatch('formatFieldsNames', { index });
                    }

                    commit(MUTATIONS.SET_IS_USE_PRODUCT_IMAGES_FROM_PSD, { isUseProductImagesFromPSD: true, index });
                }
            } catch (e) {
                const errorMessage = e.response?.data?.message ?? initialState().errorMessage;
                toastr.error(errorMessage);
                commit(MUTATIONS.SET_ERROR_MESSAGE, { errorMessage: initialState().errorMessage, index });
            }
        },
        async updatePSD({ state, rootGetters }, { index }) {
            const fileState = state.files[index];

            if (!fileState) {
                return;
            }

            await PSDLayerService.updatePSD({
                filePath: fileState.filename,
                psdData: {
                    parsePsdData: fileState.parsedPsdModel,
                }
            });

            psdLayersEventBroker.fire(PSD_LAYERS_EVENT.PSD_FILE_PARSED, {
                fileId: fileState.id
            });
        },
        cancel() {
            if (cancelToken) {
                cancelToken.cancel();
            }

            if (resumable) {
                resumable.cancel();
            }

            psdLayersEventBroker.fire(EVENTS.CANCEL);
        },
        addToTemplate(module, options = {}) {
            module.commit(MUTATIONS.SET_GENERATING, true);
            const applyStrategy = new ApplyStrategy(module, options);
            applyStrategy
                .build(ApplyStrategy.FLOWS.ADD_TO_TEMPLATE)
                .all()
                .then((isReload) => {
                    if (isReload) {
                        location.reload();
                    }
                })
        },
        addToTemplateWithoutLayers({ dispatch }) {
            dispatch('addToTemplate', { isImportWithoutLayers: true });
        },
        proceedByFlow(module) {
            module.commit(MUTATIONS.SET_IS_EXTRACTING_ASSETS, { isExtractingAssets: true });
            const applyStrategy = new ApplyStrategy(module);
            applyStrategy
                .build(module.state.flow)
                .all()
                .then(({ success, index }) => {
                    module.commit(MUTATIONS.SET_IS_EXTRACTING_ASSETS, { isExtractingAssets: false, index });

                    if (success && module.state.files.length === 1) {
                        psdLayersEventBroker.fire(PSD_LAYERS_EVENT.CLOSE)
                    }
                })
        },
        updatePreviewTemplate(module) {
            const { commit } = module;
            commit(MUTATIONS.SET_UPDATING_PREVIEW_TEMPLATE, { isUpdatingPreviewTemplate: true });

            const applyStrategy = new ApplyStrategy(module);
            applyStrategy
                .build(ApplyStrategy.FLOWS.UPDATE_PREVIEW_TEMPLATE)
                .all()
                .then((event) => {
                    commit(MUTATIONS.SET_UPDATING_PREVIEW_TEMPLATE, { isUpdatingPreviewTemplate: false });
                    commit(MUTATIONS.SET_PREVIEW_TEMPLATE_THUMBNAIL, { previewTemplateThumbnail: event.preview })
                })
        },
        formatFieldsNames({ state, commit, getters }, { index }) {
            const fileState = state.files[index];

            if (!fileState) {
                return;
            }

            recursiveWalker(fileState.templates, node => {
                if (!(node instanceof Layer)) {
                    return;
                }

                const fieldName = getFormattedFieldName({
                    fieldName: node.data.name,
                    fieldType: node.data.fieldType
                });

                if (fieldName === node.data.name) {
                    return;
                }

                commit(MUTATIONS.UPDATE_LAYER, { index, layer: node, key: 'data.name', value: fieldName })
                commit(MUTATIONS.UPDATE_LAYER, { index, layer: node, key: 'name', value: fieldName })
            });
        },
        setAutoMapFields({ state, commit, getters }, { index }) {
            const fileState = state.files[index];

            if (!fileState) {
                return;
            }

            commit(MUTATIONS.SET_AUTO_MAP_TO_FIELDS, { index, isAutoMapToFields: !fileState.isAutoMapToFields });

            if (!fileState.isAutoMapToFields) {
                return
            }

            if (!getters.isImportIntoTemplate) { // import to new template
                recursiveWalker(fileState.templates, node => {
                    if (!(node instanceof Layer)) {
                        return;
                    }

                    const fieldType = getAutoMappedFieldType({
                        sourceName: node.data.name,
                        sourceFieldType: node.fieldType
                    });

                    if (!fieldType) {
                        return;
                    }

                    commit(MUTATIONS.UPDATE_LAYER, { index, layer: node, key: 'fieldType', value: fieldType })
                    commit(MUTATIONS.UPDATE_LAYER, { index, layer: node, key: 'data.fieldType', value: fieldType })
                })
            } else { // import into existing template
                let isAnyMapped = false
                recursiveWalker(fileState.templates[0].children, node => {
                    const mapped = fileState.templateFieldsByType[PSD_LAYER_TYPE_TO_BASIC_MAP[node.type]]
                        ?.find(field => isSourceNodeMappable({
                            sourceName: node.data.name,
                            targetName: field.name,
                            targetType: field.type,
                        }));

                    if (!(node instanceof Base) || !mapped) {
                        return;
                    }

                    isAnyMapped = true

                    commit(MUTATIONS.SET_FIELD, { index, uuid: node.uuid, field: mapped });
                    commit(MUTATIONS.SELECT_LAYER, { index, layer: node });
                    commit(MUTATIONS.UPDATE_MAPPED_FIELD_IDS, { index, value: mapped.id });
                })
                if (isAnyMapped) {
                    commit(MUTATIONS.SELECT_LAYER, { index, layer: fileState.templates[0] })
                }
            }
        },
        async uploadUsersLogo({ commit, dispatch }, { file, filePath, index }) {
            try {
                const path = filePath.split('/').slice(0, 2).join('/');
                const formData = new FormData();
                formData.append('logo', file);
                formData.append('path', path);

                const { data } = await PSDLayerService.uploadUsersLogo(formData);

                const { width, height } = await getImageDimensions(file);

                commit(MUTATIONS.SET_LOGO_FIELD_URL, {
                    logoUrl: data.logoUrl,
                    width,
                    height,
                    index
                });

            } catch (e) {
                console.error('The error occurred in the uploadUsersLogo action.');
            }
        },
        async mergeNode({ state, commit, getters }, node) {
            const splitEntireImagePath = getters.entireImageThumbnail.split('/');
            splitEntireImagePath.pop()

            commit(MUTATIONS.ADD_MERGING_NODE, { uuid: node.uuid })

            try {
                const response = await PSDLayerService.mergeNode({
                    node,
                    folder: splitEntireImagePath.join('/')
                })

                const backgroundImage = new BackgroundImage(response.data)

                commit(MUTATIONS.REPLACE_NODE, {
                    node,
                    newNode: backgroundImage
                })
            } finally {
                commit(MUTATIONS.REMOVE_MERGING_NODE, { uuid: node.uuid })
            }
        },
        extractToTemplate({ commit }, node) {
            const template = node.toTemplate()
            commit(MUTATIONS.ADD_TEMPLATE, { template })
            commit(MUTATIONS.REMOVE_NODE, { node })
        },
        mapProductImagesForImportIntoTemplate({ getters, commit }) {
            toastr.info('Mapping product images...');
            let anyMapped = false;
            let mappedImages = 0;
            let templateFieldsToMap = {...getters.templateFieldsByType};
            let boundingBoxes = getters.recognizedProducts;
            let coordinatesTolerance = 0;
            let sizeTolerance = 0;

            while (boundingBoxes.length > 0) {
                recursiveWalker(getters.templates[0].children, node => {
                    if (! (node instanceof BackgroundImage) || ! (node.fieldType === FIELD_TYPE_BACKGROND_THEME_IMAGE)) {
                        return;
                    }

                    if (node.field && node.field.type === FIELD_TYPE_PRODUCT_IMAGE) {
                        return;
                    }

                    const match = matchesBoundingBox({
                        nodeCoordinates: {
                            x: node.data.left,
                            y: node.data.top,
                            w: node.data.width,
                            h: node.data.height,
                        },
                        boundingBoxes,
                        coordinatesTolerance,
                        sizeTolerance,
                    });

                    if (!match.matches) {
                        return
                    }

                    boundingBoxes = match.boundingBoxes;

                    const mapped = templateFieldsToMap[PSD_LAYER_TYPE_TO_BASIC_MAP[node.type]]
                        ?.find(field => field.type === FIELD_TYPE_PRODUCT_IMAGE && !getters.mappedFieldIds.includes(field.id));

                    if (!(node instanceof Base) || !mapped) {
                        return;
                    }

                    templateFieldsToMap[PSD_LAYER_TYPE_TO_BASIC_MAP[node.type]] = templateFieldsToMap[PSD_LAYER_TYPE_TO_BASIC_MAP[node.type]].filter(field => field.id !== mapped.id);

                    anyMapped = true;
                    mappedImages++;

                    commit(MUTATIONS.SET_FIELD, { uuid: node.uuid, field: mapped });
                    commit(MUTATIONS.SELECT_LAYER, { layer: node });
                    commit(MUTATIONS.UPDATE_MAPPED_FIELD_IDS, { value: mapped.id });
                });

                coordinatesTolerance += 10;
                sizeTolerance += 20;
            }

            if (anyMapped) {
                commit(MUTATIONS.SELECT_LAYER, { layer: getters.templates[0] })
            }

            toastr.success(`Mapped ${mappedImages} product images`);
        },
        mapProductImagesForImportTemplate({getters, commit}) {
            toastr.info('Mapping product images...');
            let mappedImages = 0;

            let boundingBoxes = getters.recognizedProducts;
            let coordinatesTolerance = 0;
            let sizeTolerance = 0;

            while (boundingBoxes.length > 0) {
                recursiveWalker(getters.templates, node => {
                    if (!(node instanceof Layer)) {
                        return;
                    }

                    const match = matchesBoundingBox({
                        nodeCoordinates: {
                            x: node.data.left,
                            y: node.data.top,
                            w: node.data.width,
                            h: node.data.height,
                        },
                        boundingBoxes,
                        coordinatesTolerance,
                        sizeTolerance,
                    });

                    if (match.matches) {
                        mappedImages++;
                        boundingBoxes = match.boundingBoxes;
                        commit(MUTATIONS.UPDATE_LAYER, { layer: node, key: 'fieldType', value: FIELD_TYPE_PRODUCT_IMAGE })
                        commit(MUTATIONS.UPDATE_LAYER, { layer: node, key: 'data.fieldType', value: FIELD_TYPE_PRODUCT_IMAGE })
                    }
                });


                coordinatesTolerance += 10;
                sizeTolerance += 20;
            }

            toastr.success(`Mapped ${mappedImages} product images`);
        },
        requestProductRecognition({ state, commit, dispatch, getters }) {
            commit(MUTATIONS.SET_IS_PRODUCT_RECOGNITION_PROCESSING, { value: true });
            toastr.info('Requesting Product images recognition...');

            axios.post('/ocr/recognize-product-images', {image: getters.entireImage})
                .then(({ data }) => {
                    if (data.status !== 'success') {
                        toastr.error('Failed to recognize product images.');
                        commit(MUTATIONS.SET_RECOGNIZED_PRODUCTS, { recognizedProducts: [] });
                        return;
                    }

                    commit(MUTATIONS.SET_RECOGNIZED_PRODUCTS, { recognizedProducts: data.data });

                    getters.isImportIntoTemplate
                        ? dispatch('mapProductImagesForImportIntoTemplate')
                        : dispatch('mapProductImagesForImportTemplate');

                    commit(MUTATIONS.SET_IS_PRODUCT_RECOGNITION_PROCESSING, { value: false });
                })
                .catch((error) => {
                    console.log(error);
                    toastr.error('Failed to recognize product images.');

                    commit(MUTATIONS.SET_RECOGNIZED_PRODUCTS, { recognizedProducts: [] });
                    commit(MUTATIONS.SET_IS_PRODUCT_RECOGNITION_PROCESSING, { value: false });
                });
        },
        setNodeChanges({ state, commit, getters }, { nodeId, changes }) {
            if (!changes || !Array.isArray(changes) || changes.length === 0 || !nodeId) {
                return;
            }

            const nodesChanges = _.cloneDeep(getters.nodesChanges);

            changes.forEach(change => {
                const { key, value } = change;

                if (!key) {
                    return;
                }

                const nodeChangesIndex = nodesChanges.findIndex(_nodeChanges => _nodeChanges.nodeId === nodeId);
                const nodeChanges = nodesChanges[nodeChangesIndex];

                if (!nodeChanges) {
                    nodesChanges.push({
                        nodeId: nodeId,
                        changes: [change],
                    });
                } else {
                    const changeItemIndex = nodeChanges.changes.findIndex(_changeItem => _changeItem.key === key);
                    const isNewValueEqualsOriginal = !!recursiveFind(getters.parsedPsdModel.data.tree, (node) => {
                        const originalValue = getNestedValue(node, key);

                        if (!originalValue) {
                            return false;
                        }

                        return originalValue === value;
                    });

                    if (changeItemIndex === -1) {
                        nodeChanges.changes.push(change);
                        return;
                    }

                    if (isNewValueEqualsOriginal) {
                        if (nodeChanges.changes.length - 1 <= 0) {
                            nodesChanges.splice(nodeChangesIndex, 1);
                        } else {
                            nodeChanges.changes.splice(changeItemIndex, 1);
                        }

                        return;
                    }

                    nodeChanges.changes[changeItemIndex].value = value;
                }
            })

            commit(MUTATIONS.SET_NODES_CHANGES, { value: nodesChanges });
        },
        async saveNodesChanges({ state, commit, getters }) {
            const parsedPsdData = _.cloneDeep(getters.parsedPsdModel.data);

            recursiveWalker(parsedPsdData.tree, (node) => {
                getters.nodesChanges.forEach(updateData => {
                    const { nodeId, changes } = updateData;

                    if (node.uuid === nodeId) {
                        changes.forEach(change => {
                            setNestedValue(node, change.key, change.value);
                        });
                    }
                });
            });

            commit(MUTATIONS.SET_IS_SAVING_NODES_CHANGES, { value: true });
            await PSDLayerService.updatePSD({ filePath: getters.filename, psdData: { parsePsdData: parsedPsdData } });
            commit(MUTATIONS.SET_IS_SAVING_NODES_CHANGES, { value: false });
            commit(MUTATIONS.SET_NODES_CHANGES, { value: [] });
        },
        handleFileStatusUpdate({ commit, dispatch }, { event, index, fileId }) {
            const status = +event.status;

            commit(MUTATIONS.SET_STATUS, { index, status: +event.status });

            if (status === PSD_STATUS.DONE) {
                dispatch('fromUrl', { index, isReparse: false });

                psdLayersEventBroker.fire(PSD_LAYERS_EVENT.PSD_FILE_PARSED, {
                    fileId: fileId
                });
            }

            if (status === PSD_STATUS.FAILED) {
                toastr.error(event.data.error);
            }
        },
        initFileListeners({ commit, dispatch },{ fileId, index }) {
            const channelName = `${PSD_PARSE_CHANNEL_PREFIX}${fileId}`;

            Echo
              .channel(channelName)
              .listen(PSD_PARSE_EVENTS.STATUS_UPDATE, event => dispatch('handleFileStatusUpdate', { event, index, fileId }));
        },
        unsubscribeFileListeners({ state }) {
            state.files.forEach(fileState => {
                const channelName = `${PSD_PARSE_CHANNEL_PREFIX}${fileState.id}`;

                Echo.channel(channelName).stopListening(PSD_PARSE_EVENTS.STATUS_UPDATE);
                Echo.leave(channelName);
            });
        },
    },
}
