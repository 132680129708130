var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { tabindex: "0" } },
    _vm._l(_vm.editor.colors, function (color, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "d-inline-flex colors-wrap mt-2 align-content-center",
        },
        [
          _c("b-input", {
            staticClass: "w-10",
            staticStyle: { width: "10%" },
            attrs: {
              value: color.slice(0, 7),
              debounce: "50",
              size: "sm",
              type: "color",
            },
            on: {
              click: function ($event) {
                return _vm.copyValue(index)
              },
              update: (inputColor) => _vm.onInputColor(inputColor, index),
            },
          }),
          _vm._v(" "),
          _c("b-input", {
            staticClass: "w-20",
            staticStyle: { width: "25%" },
            attrs: { value: color.slice(0, 7), size: "sm", type: "text" },
            on: { change: (inputColor) => _vm.onInputColor(inputColor, index) },
          }),
          _vm._v(" "),
          _c(
            "b-tooltip",
            { attrs: { target: "slider-" + index, triggers: "hover focus" } },
            [
              _vm._v("\n      Adjust the position of the color stop (0-100%)."),
              _c("br"),
              _vm._v(" "),
              _c("strong", [_vm._v("Color Stop")]),
              _vm._v(
                " — a point in the gradient where a specific color is applied.\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("b-input", {
            staticClass: "w-50",
            attrs: {
              id: "slider-" + index,
              max: 100,
              min: 0,
              number: "",
              size: "sm",
              type: "range",
            },
            on: {
              input: (value) => _vm.$emit("colorStepsInput", { index, value }),
            },
            model: {
              value: _vm.colorSteps[index],
              callback: function ($$v) {
                _vm.$set(_vm.colorSteps, index, $$v)
              },
              expression: "colorSteps[index]",
            },
          }),
          _vm._v(" "),
          _c(
            "b-tooltip",
            { attrs: { target: "input-" + index, triggers: "hover focus" } },
            [
              _vm._v(
                "\n      Enter the exact position of the color stop (0-100%)."
              ),
              _c("br"),
              _vm._v(" "),
              _c("strong", [_vm._v("Gradient Color Stop")]),
              _vm._v(
                " — a point in the gradient where a specific color is applied.\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("b-input", {
            staticClass: "w-20",
            staticStyle: { width: "20%" },
            attrs: {
              id: "input-" + index,
              max: 100,
              min: 0,
              number: "",
              size: "sm",
              type: "number",
            },
            on: {
              input: (value) => _vm.$emit("colorStepsInput", { index, value }),
              scroll: function ($event) {
                $event.stopPropagation()
              },
            },
            model: {
              value: _vm.colorSteps[index],
              callback: function ($$v) {
                _vm.$set(_vm.colorSteps, index, $$v)
              },
              expression: "colorSteps[index]",
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }