import axios from 'axios';

export default {
  importFile(data) {
    return axios.post('/video/import', data)
  },

  importTemplate(id) {
    return axios.get(`/video/templates/${id}`)
  },

  exportTemplate(data) {
    return axios({
        url: '/video/export-template',
        method: 'POST',
        data: { data },
        responseType: 'blob', // important
    })
  },

  saveTemplate(rows, filename, themeID, newFontColor, newStrokeColor) {
    return axios({
        url: '/video/export-template-to-server',
        method: 'POST',
        data: { rows, filename, themeID, newFontColor, newStrokeColor}
    })
  },

  createVideo (data) {
    return axios.post('/video/create-video', data)
  },

  exportAssets(data) {
    return axios({
        url: '/video/export-assets',
        method: 'POST',
        data: { data },
        // responseType: 'blob', // important
    })
  },

  uploadFile(data) {
    return axios.post('/video/upload_file', data)
  },

  unzip(data) {
    return axios.post('/file/unzip', data)
  },

  addTiffData(data) {
    return axios.post('/video/tiff_data', data)
  },

  uploadThumb(data) {
    return axios.post('/video/upload_thumb', data)
  },

  trimVideo(data) {
    return axios.post('/video/media/file/trim-video', data)
  },

  cropVideo(data) {
    return axios.post('/video/media/file/crop-video', data)
  },
  convertVideo(data) {
    return axios.post('/video/media/file/convert-video', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  convertSharedVideo(data) {
    return axios.post('/video/media/file/convert-shared-video', data)
  },

  compressVideo(data) {
    return axios.post('/video/media/file/compress-video', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  getThemes() {
    return axios.get('/video/api/themes')
  },

  saveCroppedImage(data) {
    return axios.post('/video/crop-image', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  saveRemoveBGImage(data) {
    return axios.post('/video/remove-bg-image', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  getProjectVideoComments(data) {
    return axios.post(`/projects/video/get_comment`, data)
  },

  addProjectVideoComment(data) {
    return axios.post('/projects/video/comment', data)
  },

  addProjectVideoApproval(data) {
    return axios.post('/projects/video/approval', data)
  },

  deleteProjectVideoComment(data) {
    return axios.post('/projects/video/delete_comment', data)
  },

  addHistoriesProjectComment(data) {
    return axios.post('/history_projects/comment', data)
  },

  updateHistoriesProjectComment (data, id) {
    return axios.post(`/history_projects/comment/${id}`, data)
  },

  getHistoriesProjectComments(data) {
    return axios.post(`/history_projects/get_comment`, data)
  },

  addHistoriesProjectApproval(data) {
    return axios.post('/history_projects/approval', data)
  },

  deleteHistoriesProjectComment(data) {
    return axios.post('/history_projects/delete_comment', data)
  },
  
  resolveHistoriesProjectComment(data) {
    return axios.post('/history_projects/resolve_comment', data);
  },

  sendMail(data) {
    return axios.post('/send_mail', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  addVideoScreenshot(data) {
    return axios.post('/video/proof/share/save-video-screenshot', data);
  },
  deleteScreenshot(data) {
    return axios.post('/video/proof/share/delete-screenshot', data);
  },
  addNewProjectComment(data) {
    return axios.post(`/projects/create/new_projects/comment`, data)
  },

  getNewProjectComments(data) {
    return axios.post(`/projects/create/new_projects/get_comment`, data)
  },

  deleteNewProjectComment(data) {
    return axios.post(`/projects/create/new_projects/delete_comment`, data)
  },

  addNewProjectApproval(data) {
    return axios.post(`/projects/create/new_projects/approval`, data)
  },

  convertImage(formData, headers) {
    return axios.post('/file/uploadimg/convert-image', formData, {
      headers,
      responseType: 'arraybuffer',
    })
  },
  getBannerView(data) {
    return axios.post('/banner/view', data)
  },
  getBannerThemeSettings(data) {
    return axios.post('/banner/get_theme_settings', data)
  },
  getOutlineProductImage(data) {
    return axios.post('/banner/get-outline-product-image', data)
  },

  deleteFile (data) {
    return axios.post('/video/media/file/delete', data)
  },

  checkFileExists (data) {
    return axios.post('/video/media/file/exists', data)
  },

  copyFile (data) {
    return axios.post('/video/media/file/copy', data)
  },

  replaceFile(data) {
    return axios.post('/video/media/file/replace', data)
  },

  layoutTemplateSort(customer_id, layout_id, data) {
    return axios.post(`/banner/${customer_id}/group/${layout_id}/template-sort`, data);
  },

  getVideos (payload) {
    return axios.post('/video/media', payload)
  },
  uploadCroppedProductImage(data) {
    return axios.post('/banner/upload_cropped_product_image', data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
  },
  uploadCroppedBgImage(data) {
    return axios.post('/banner/upload-cropped-bg-image', data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
  },
  uploadBackgroundImage(data) {
    return axios.post('/banner/upload_bk_image', data, { dataType: "JSON" })
  },
  updateShadowSettings(customer_id, theme, shadows) {
    return axios.post(`/admin/auth/settings/theme/${customer_id}/${theme}/update_shadows`, {
      shadows
    })
  },
  getGenerateFonts() {
    return axios.get('/generate_fonts_json', { async: false })
  },
  getFolderAudioFiles(data) {
    return axios.get('/file/music-files', {
      params: {
        folderId: data.id,
        pageSize: data.per_page,
        pageNumber: data.page,
      }
    });
  },
  deleteFolderAudioFiles(fileId) {
    return axios.delete(`/file/delete_folder_file`, { data: { fileId: fileId }});
  },
  getLibraryAudioFiles(data) {
    return axios.post('/video/music-library', null, { params: data });
  },
  deleteLibraryAudioFile(musicId) {
    return axios.post(`/video/music-library/delete/${musicId}`)
  },
  uploadLibraryAudioFiles (data, config = null) {
    return axios.post('/video/music-library/upload', data, config)
  },

  getBackgroundStockImages (params) {
    return axios.get('/banner/background-stock', { params })
  },
  addImageToThemeByItsLocation(payload) {
    return axios.post('/file/images_for_save_in_theme', payload)
  },
  exportPSDFromLayout(data) {
    return axios.post('/banner/download_layout_assets', data);
  },
  storeUploadImages(data) {
    return axios.post('/file/uploadimg/store', data);
  },
  checkDuplications(data) {
    return axios.post('/file/uploadimg/check_duplications', data);
  },
  getDominantColors(data) {
    return axios.post('/banner/get_dominant_colors', data);
  }
}

export function storeRemoteImage(formData) {
  return axios.post(
    '/banner/store_remote_image',
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
}
