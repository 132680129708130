import { EventBroker } from "@frontend/lib/utils/event-broker";

export const TRANSFORMATIONS_EVENTS = {
    SHOW_TRANSFORMATIONS_MODAL: 'SHOW_TRANSFORMATIONS_MODAL',
    HIDE_TRANSFORMATIONS_MODAL: 'HIDE_TRANSFORMATIONS_MODAL',
    UPDATE_TRANSFORMATIONS: 'UPDATE_TRANSFORMATIONS',

    SCRIPTS_LOADED: 'SCRIPTS_LOADED',
    TEST_SCRIPT: 'TEST_SCRIPT',
    SAVE_SCRIPT: 'SAVE_SCRIPT',
    DELETE_SCRIPT: 'DELETE_SCRIPT',
};

export const transformationsEventBroker = new EventBroker();