var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { id: _vm.modalId, title: "Remove background" },
      on: { hide: _vm.closeHandler, show: _vm.reset },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "ButtonOutline",
                {
                  attrs: { variant: "outline-primary" },
                  on: { click: _vm.closeHandler },
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
              _vm._v(" "),
              _c(
                "ButtonPrimary",
                { attrs: { variant: "primary" }, on: { click: _vm.accept } },
                [_vm._v("\n      Remove background\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "b-form-group",
            [
              _c(
                "b-form-radio",
                {
                  attrs: { value: false },
                  model: {
                    value: _vm.isReplace,
                    callback: function ($$v) {
                      _vm.isReplace = $$v
                    },
                    expression: "isReplace",
                  },
                },
                [_vm._v("\n      Copy before removing background\n    ")]
              ),
              _vm._v(" "),
              _c(
                "b-form-radio",
                {
                  attrs: { value: true },
                  model: {
                    value: _vm.isReplace,
                    callback: function ($$v) {
                      _vm.isReplace = $$v
                    },
                    expression: "isReplace",
                  },
                },
                [
                  _vm._v(
                    "\n      Replace image file after remove background\n    "
                  ),
                ]
              ),
            ],
            1
          )
        : _c("b-spinner"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }