var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.previews.length
      ? _c(
          "div",
          { staticClass: "text-center mt-5" },
          [
            _c("b-spinner", { attrs: { variant: "primary" } }),
            _vm._v(" "),
            _c("p", { staticClass: "mt-2" }, [
              _vm._v("\n      Generating Previews...\n    "),
            ]),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("div", { staticClass: "card mt-3" }, [
              _c(
                "div",
                { staticClass: "card-body d-flex justify-content-between" },
                [
                  _c("div", { staticClass: "align-items-center" }, [
                    _c("h3", { staticClass: "card-title" }, [
                      _vm._v(
                        "\n            Name: " +
                          _vm._s(_vm.creative.name) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("h4", { staticClass: "card-subtitle mb-2 text-muted" }, [
                      _vm._v("\n            Status: "),
                      _c(
                        "span",
                        {
                          class: _vm.walmart.getStatusBadge(
                            _vm.creative.status
                          ),
                        },
                        [_vm._v(_vm._s(_vm.creative.status))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-text" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-sm mx-2",
                        attrs: { disabled: _vm.creative?.isLoading },
                        on: {
                          click: function ($event) {
                            return _vm.submitCreative(_vm.creative)
                          },
                        },
                      },
                      [
                        _vm.creative?.isLoading
                          ? _c("span", {
                              staticClass: "spinner-border spinner-border-sm",
                              attrs: { role: "status", "aria-hidden": "true" },
                            })
                          : _vm._e(),
                        _vm._v("\n            Submit\n          "),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.previews, function (preview, index) {
              return _c(
                "div",
                { key: index, staticClass: "mt-3 mb-2 border rounded p-3" },
                [
                  _c("h3", [_vm._v(_vm._s(preview.name))]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: { src: preview.url, alt: "preview" },
                  }),
                ]
              )
            }),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }