var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "icons-settings-modal",
            title: "Icons Settings",
            centered: "",
            size: "l",
            "ok-title": "Save",
          },
          on: { ok: _vm.handleSave },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "inputs-container" },
              _vm._l(_vm.urls, function (url, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "d-flex mb-2" },
                  [
                    _c("b-form-input", {
                      staticClass: "mr-2",
                      attrs: { value: url, placeholder: "Enter the icon URL" },
                      on: {
                        input: (value) => _vm.handleURLInput(value, index),
                      },
                    }),
                    _vm._v(" "),
                    _vm.urls.length > 1
                      ? _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "outline-danger" },
                            on: { click: () => _vm.handleDeleteBtn(index) },
                          },
                          [_c("b-icon", { attrs: { icon: "trash" } })],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-end" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { size: "sm", variant: "outline-primary" },
                    on: { click: _vm.handleAddUrlBtn },
                  },
                  [_vm._v("\n          Add URL\n        ")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.isShowPage
            ? _c(
                "b-form-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "X", "label-cols": "1" } },
                        [
                          _c("b-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.left,
                              callback: function ($$v) {
                                _vm.left = _vm._n($$v)
                              },
                              expression: "left",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Y", "label-cols": "1" } },
                        [
                          _c("b-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.top,
                              callback: function ($$v) {
                                _vm.top = _vm._n($$v)
                              },
                              expression: "top",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "W", "label-cols": "1" } },
                        [
                          _c("b-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.width,
                              callback: function ($$v) {
                                _vm.width = _vm._n($$v)
                              },
                              expression: "width",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "H", "label-cols": "1" } },
                        [
                          _c("b-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.height,
                              callback: function ($$v) {
                                _vm.height = _vm._n($$v)
                              },
                              expression: "height",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }