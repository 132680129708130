<template>
  <div>
    <b-modal
      id="svg_put_to_template_modal"
      class="put-to-template-wrapper"
      title="Send to template"
      centered
      no-close-on-backdrop
      @shown="handleModalShown"
      @hide="close"
    >
      <div
        v-if="loading"
        class="put-to-template-wrapper__overlay"
      >
        <div class="put-to-template-wrapper__overlay--background" />
        <div class="put-to-template-wrapper__overlay--spinner">
          <b-spinner size="lg" />
        </div>
      </div>
      <div>
        <div class="col-12">
          <b-form-group label="Create new layout:">
            <b-form-input
              v-model="uploadData.layoutName"
              type="text"
              placeholder="Create layout"
              required
              :disabled="!!selectedLayout"
            />
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="Select existing layout:">
            <Multiselect
              v-model="selectedLayout"
              :options="layouts"
              label="name"
              track-by="id"
            >
              <template #placeholder>
                None selected
              </template>
            </Multiselect>
          </b-form-group>
        </div>
        
        <div class="col-12">
          <b-form-group label="Select customer:">
            <Multiselect
              v-model="uploadData.templates.customer"
              :options="customers"
              label="name"
              track-by="id"
              placeholder="Select customer:"
              :allow-empty="true"
              searchable
              @input="onCustomerChanged"
            />
          </b-form-group>
        </div>
      </div>
      
      <div class="col-12">
        <b-form-group
          label="Select template:"
        >
          <Multiselect
            v-model="uploadData.templates.templateId"
            :options="templatesForSelectedCustomer"
            label="name"
            track-by="id"
            placeholder="Select template:"
            :allow-empty="true"
            searchable
            @input="onTemplateChanged"
          />
        </b-form-group>
      </div>

      <div 
        v-if="bgFields.length > 0 && bgLinks.length > 0"
        class="col-12"
      >
        <div class="mb-2">
          Map Fields:
        </div>
        <div
          v-for="field in bgFields"
          :key="field.id"
          class="mb-2 ml-2"
        >
          {{ field.name }}:
          
          <div>
            <select
              v-model="selectedLinks[field.id]"
              class="form-control form-control-sm d-inline-block"
              @change="onSelectField(field.id, $event.target.value)"
            >
              <option value="">
                Default value
              </option>
              <option
                v-for="link in bgLinks"
                :key="link.src"
                :value="link.src"
              >
                {{ link.type }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div>
          <button
            :disabled="loading"
            class="btn btn-secondary"
            @click="close"
          >
            Cancel
          </button>
          <button
            :disabled="isSaveDisabled || loading"
            class="btn btn-primary"
            @click="onSave"
          >
            Ok
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import TEMPLATE_API from '@frontend/services/api/template';
import LAYOUT_API from '@frontend/services/api/layout';
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from "uuid";

export default {
  name: 'SVGPutToTemplateModal',
  components: {
    Multiselect
  },

  props: {
    canvas: {
      type: Object,
      default: null,
    }
  },

  data: () => ({
    selectedLayout: null,
    uploadData: {
      layoutName: 'New NFI Layout',
      projectName: null,
      disableAutoPosition: false,
      useAlternateImageIfAvailable: false,
      autoAlign: true,
      maxFontSize: true,
      useTemplateMaxFontSize: true,
      templates: {
        customer: null,
        templateId: null,
        mappingValues: {},
        mappingTransformations: {},
        removeBackground: false,
        cropImage: false,
        themeId: null
      }
    },
    templatesForSelectedCustomer: [],
    layoutChanges: [],
    instance_id: '',
    bgFields: [],
    bgLinks: [],
    selectedTemplateId: null,
    loading: false,
    isSaveDisabled: true,
    selectedLinks: {}
  }),

  computed: {
    ...mapGetters('svgEditor', [
      'isCompressTiffFile',
      'isClippedTiffExport',
      'isRemoveShadow',
      'isExportSelected',
      'getProductCanvasInstance',
      'layouts',
      'customers',
    ]),
  },

  methods: {
    ...mapActions('svgEditor', [
      'setIsProductImage',
      'setIsCompressTiffFile',
      'setIsClippedTiffExport',
      'setIsRemoveShadow',
      'setIsExportSelected',
    ]),

    show() {
      this.initData();
      this.$bvModal.show('svg_put_to_template_modal');
    },

    close() {
      this.$bvModal.hide('svg_put_to_template_modal');
    },
    
    initData() {
      this.selectedLayout = null;
      this.uploadData = {
        layoutName: 'New NFI Layout',
          projectName: null,
          disableAutoPosition: false,
          useAlternateImageIfAvailable: false,
          autoAlign: true,
          maxFontSize: true,
          useTemplateMaxFontSize: true,
          templates: {
          customer: null,
            templateId: null,
            mappingValues: {},
          mappingTransformations: {},
          removeBackground: false,
            cropImage: false,
            themeId: null
        }
      };
      this.templatesForSelectedCustomer = [];
      this.layoutChanges = [];
      this.instance_id = '';
      this.bgFields=  [];
      this.bgLinks = [];
      this.selectedTemplateId = null;
      this.loading = false;
      this.isSaveDisabled = true;
    },

    async onSave() {
      const newTab = window.open('', '_blank');

      try {
        this.loadingOn();
        await this.updateTemplateScale();
        await this.createLayout();
        await this.updateLayout();
        await TEMPLATE_API.updateLayoutTemplate(
          this.selectedLayout.customer_id,
          this.selectedLayout.id,
          this.layoutChanges
        )
        toastr.success('Saved to template');
        this.loadedOff();
        this.close();
        newTab.location.href = `/banner/${this.selectedLayout.customer_id}/group/${this.selectedLayout.id}/show?latest_modified_version=1`;
      } catch (e) {
        toastr.error('Save to template error');
        console.log(e)
        this.loadedOff();
        newTab.close();
        this.close();
      }
    },
    
    async createLayout() {
      if (this.selectedLayout) return;

      const createLayoutData = {
        name: this.uploadData.layoutName,
        customer_id: this.uploadData.templates.customer.id,
        new_project_id: null,
        layoutId: null,
        settings: '[]',
        disable_auto_position: false,
        electedLayout: null,
        options: {
          include_template_name: true,
        },
      };

      const { data: layout } = await LAYOUT_API.createLayout(createLayoutData.customer_id, createLayoutData)
      this.selectedLayout = {
        id: layout.id,
        name: layout.name,
        customer_id: layout.customer_id,
      }
    },

    async updateLayout() {
      const { data } = await TEMPLATE_API.getTemplatesByIds(this.selectedTemplateId);
      const settings = [{
        'instance_id': this.instance_id,
        'template_id': data[0].id,
        'width': data[0].width,
        'height': data[0].height,
        'name': data[0].name,
        'w': 8,
        'h': 8,
        'x': 0,
        'y': 0
      }];
      const updateLayoutData = {
        'name': this.selectedLayout.name,
        'new_project_id': null,
        'settings': JSON.stringify(settings),
      }
      await TEMPLATE_API.updateLayout(this.selectedLayout.customer_id, this.selectedLayout.id, updateLayoutData);
    },

    handleModalShown() {
      this.show();
    },

    async onCustomerChanged(event) {
      const value = event === null ? null : (typeof event === 'object' ? event.id : event);

      if (value) {
        this.customer_id = value
        const { data } = await TEMPLATE_API.getTemplatesByCustomer(value);
        this.templatesForSelectedCustomer = data;
      } else {
        this.templatesForSelectedCustomer = [];
      }
    },

    async onTemplateChanged(event) {
      this.loadingOn();
      this.layoutChanges = [];
      this.selectedTemplateId = event === null ? null : (typeof event === 'object' ? event.id : event);

      if (!this.selectedTemplateId) {
        this.loadedOff();
        return;
      }

      await this.setBgFields();
      
      this.instance_id = uuidv4();
      this.createLayoutChanges(this.instance_id, this.bgFields);
      const productCanvasInstance = this.getProductCanvasInstance;
      this.bgLinks = await productCanvasInstance.uploadNI(this.uploadData.templates.customer.id, this.selectedTemplateId);
      this.initializeSelectedLinks();
      
      this.loadedOff();
    },

    initializeSelectedLinks() {
      this.layoutChanges.push({
        instance_id: this.instance_id,
        key: 'background_theme_image_fitting_disabled',
        value: true,
      })
      
      this.selectedLinks = this.bgFields.reduce((acc, field) => {
        const matchedLink = this.bgLinks.find(link =>
          field.name.toLowerCase().includes(link.type.toLowerCase()) ||
          link.type.toLowerCase().includes(field.name.toLowerCase())
        );
        const selectedLink = matchedLink ? matchedLink.src : '';

        acc[field.id] = selectedLink;

        if (selectedLink) {
          this.onSelectField(field.id, selectedLink);
        }

        return acc;
      }, {});
    },

    async setBgFields() {
      const {
        data: {
          template_fields
        }
      } = await TEMPLATE_API.getTemplateFields(this.uploadData.templates.customer.id, this.selectedTemplateId);
      this.bgFields = Object.values(template_fields).filter(field => field.type === 'Background Theme Image');
    },

    onSelectField(id, selectedLink) {
      const fieldIndex = this.bgFields.findIndex((x) => x.id === id);
      this.updateLayoutChangesValue(this.instance_id, 'background', selectedLink, fieldIndex);
      this.isSaveDisabled = !this.checkSelectedBackground();
    },

    async updateTemplateScale() {
      const background = this.layoutChanges.find(data => data.key === 'background');

      if (!background) return;

      const base_url = window.location.origin;

      const promises = background.value.map(async (link, i) => {
        if (!link) return;

        let url = '';
        if (link.startsWith('/share?file=')) {
          url = `${base_url}${link}`;
        } else {
          url = `${base_url}/share?file=${link}`;
        }

        const containerWidth = this.bgFields[i].options.Width;
        const containerHeight = this.bgFields[i].options.Height;

        try {
          const {scale} = await this.calculateImageScale(containerWidth, containerHeight, url, 'fit');
          this.updateLayoutChangesValue(this.instance_id, 'bk_img_scale', scale, i);
          this.updateLayoutChangesValue(this.instance_id, 'bk_img_scale_y', scale, i);
        } catch (e) {
          console.error(`error link: ${url}:`, e);
        }
      });

      await Promise.all(promises);
    },

    calculateImageScale(containerWidth, containerHeight, imageUrl, mode = 'fit') {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imageUrl;

        img.onload = () => {
          const imageWidth = img.width;
          const imageHeight = img.height;

          const scaleWidth = containerWidth / imageWidth;
          const scaleHeight = containerHeight / imageHeight;

          const scale = mode === 'fit' ? Math.min(scaleWidth, scaleHeight) : Math.max(scaleWidth, scaleHeight);

          resolve({scale, imageWidth, imageHeight});
        };

        img.onerror = (err) => {
          reject(`error image loading: ${err}`);
        };
      });
    },

    checkSelectedBackground() {
      const background = this.layoutChanges.find(item => item.key === "background");

      if (!background) {
        return false;
      }

      return background.value.some(v => v !== "" && v !== null);
    },

    createLayoutChanges(instance_id, bgFields) {
      const length = bgFields.length;
      const config = [
        {
          key: 'background',
          array: new Array(length).fill(''),
          value: field => field.options.Placeholder && field.options.Placeholder !== '' ? field.options.Placeholder : field.options.Filename
        },
        {
          key: 'bk_img_scale',
          array: new Array(length).fill(''),
          value: field => field.options.Scale && field.options.Scale !== '' ? field.options.Scale : 1
        },
        {
          key: 'bk_img_scale_y',
          array: new Array(length).fill(''),
          value: field => field.options.Scale && field.options.Scale !== '' ? field.options.Scale : 1
        },
      ];

      for (let i = 0; i < bgFields.length; i++) {
        const field = bgFields[i];

        for (const { key, array, value } of config) {
          array[i] = value(field);
          this.setLayoutChanges(instance_id, key, array);
        }
      }
    },

    setLayoutChanges(instance_id, key, value) {
      const index = this.layoutChanges.findIndex(
        (x) => x.instance_id === instance_id && x.key === key
      );

      if (index < 0) {
        this.layoutChanges.push({instance_id, key, value});
      } else {
        this.layoutChanges[index].value = value;
      }
    },
    
    updateLayoutChangesValue(instance_id, key, value, index) {
      for (let i = 0; i < this.layoutChanges.length; i++) {
        let changes = this.layoutChanges[i];
        
        if (changes.instance_id === instance_id && changes.key === key) {
          changes.value[index] = value;
        }
      }
    },
    
    loadedOff() {
      this.loading = false;
    },
    
    loadingOn() {
      this.loading = true;
    }
  },
};
</script>

<style lang="scss">
#svg_put_to_template_modal {
  .modal-title {
    font-weight: bold;
  }
}

.put-to-template-wrapper {
  &__overlay {
    &--background, &--spinner {
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% + 40px);
      height: 40vh;
      margin: -20px;
    }
    &--background {
      opacity: 0.1;
    }
    &--spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }
  }
}
</style>
