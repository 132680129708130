var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Insert PDF" } },
                [
                  _c("b-form-select", {
                    staticClass: "form-control",
                    attrs: { options: _vm.availabilityOptions },
                    model: {
                      value: _vm.isEnabled,
                      callback: function ($$v) {
                        _vm.isEnabled = $$v
                      },
                      expression: "isEnabled",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isEnabled
        ? _c(
            "div",
            [
              _c(
                "b-card",
                { attrs: { "bg-variant": "light" } },
                [
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Link to PDF" } },
                            [
                              _c("b-form-input", {
                                staticClass: "form-control",
                                attrs: {
                                  placeholder: "Enter the link to the PDF",
                                },
                                model: {
                                  value: _vm.tempInsertion.pdf,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempInsertion, "pdf", $$v)
                                  },
                                  expression: "tempInsertion.pdf",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { cols: "5" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Insert after" } },
                            [
                              _c("Multiselect", {
                                attrs: {
                                  multiple: false,
                                  options: _vm.templates,
                                  "track-by": "instance_id",
                                  label: "name",
                                  placeholder: "Select template",
                                },
                                model: {
                                  value: _vm.tempInsertion.after,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tempInsertion, "after", $$v)
                                  },
                                  expression: "tempInsertion.after",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { staticClass: "align-content-end mb-3" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary" },
                              on: { click: _vm.addInsertion },
                            },
                            [_vm._v("\n            Add\n          ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.insertions, function (insertion, index) {
                    return _c(
                      "b-row",
                      {
                        key: index,
                        staticClass: "py-2",
                        class: {
                          "border-bottom": index < _vm.insertions.length - 1,
                        },
                      },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass: "text-truncate",
                            attrs: { cols: "6" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(insertion.pdf) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          {
                            staticClass: "text-truncate",
                            attrs: { cols: "5" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(insertion.after.name) +
                                " : " +
                                _vm._s(insertion.after.instance_id) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeInsertion(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-trash" })]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }