import { updateObject } from '@frontend/group/modules/copy-and-resize/helpers';

export function handleObjectsIntersections({ objects, dimension, rsW, rsH }) {
  const origins = ['left', 'top'];
  const spacing = -10; // Adjust this value to increase/decrease spacing between objects to avoid and ignoring small overlaps

  for (const [currentObjectIndex, currentObject] of objects.entries()) {
    const currentPoints = currentObject.getPointByOrigin(...origins);
    const currentX = currentPoints.x;
    const currentY = currentPoints.y;
    const currentWidth = currentObject.getScaledWidth();
    const currentHeight = currentObject.getScaledHeight();

    // Ignore background layer
    if (
      currentObject.width >= dimension.width &&
      currentObject.height >= dimension.height
    ) {
      continue;
    }

    for (const [otherObjectIndex, otherObject] of objects.entries()) {
      if (currentObject === otherObject) continue;

      // Ignore background layer
      if (
        otherObject.width >= dimension.width &&
        otherObject.height >= dimension.height
      ) {
        continue;
      }

      const otherPoints = otherObject.getPointByOrigin(...origins);
      const otherX = otherPoints.x;
      const otherY = otherPoints.y;
      const otherWidth = otherObject.getScaledWidth();
      const otherHeight = otherObject.getScaledHeight();

      // Check for intersection (overlap)
      const isHorizontalOverlap =
        currentX < otherX + otherWidth + spacing &&
        currentX + currentWidth + spacing > otherX;
      const isVerticalOverlap =
        currentY < otherY + otherHeight + spacing &&
        currentY + currentHeight + spacing > otherY;
      const isOverlapping = isHorizontalOverlap && isVerticalOverlap;

      if (!isOverlapping) continue;
      
      let newOffsetX =
        currentObjectIndex < otherObjectIndex
          ? otherX + otherWidth
          : currentX;
      let newOffsetY = currentY;

      // Ensure it fits within canvas width
      if (newOffsetX + currentWidth > dimension.width) {
        newOffsetX = otherX;
        newOffsetY = otherY + otherHeight;
      }

      // Ensure it fits within canvas height
      if (newOffsetY + currentHeight > dimension.height) {
        console.warn("Not enough space for repositioning.");
        continue;
      }

      // Update object position
      currentObject.setPositionByOrigin(
        { x: newOffsetX, y: newOffsetY },
        ...origins
      );
      currentObject.setCoords();
      updateObject(currentObject, { x: newOffsetX, y: newOffsetY });

      break; // Stop checking once repositioned
    }
  }
}
