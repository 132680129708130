<template>
  <div>
    <b-modal
      id="simple-table-builder"
      title="Table Builder"
      centered
      size="xl"
      ok-title="Save"
      @ok="onSave"
    >
      <div
        v-if="table && table.rows.length"
        class="mb-3"
      >
        <b-button-group>
          <b-button @click="addRow">
            Add Row
          </b-button>
          <b-button @click="addColumn">
            Add Column
          </b-button>
          <b-button @click="removeRow">
            Remove Row
          </b-button>
          <b-button @click="removeColumn">
            Remove Column
          </b-button>
          <DropdownCounter
            :value="getStyleValue('font-size', 0)"
            :min="1"
            :step="1"
            @input="(value) => onChangeStyleOfTable('font-size', value, 'px')"
          >
            <i class="cil-text-size" />
          </DropdownCounter>
          <DropdownCounter
            :value="getStyleValue('border-width', 0)"
            :min="0"
            :step="1"
            @input="(value) => onChangeStyleOfTable('border-width', value, 'px', true)"
          >
            <i class="cil-border-style" />
          </DropdownCounter>
          <b-button>
            <ColorPicker
              :value="getStyleValue('color', '#000000')"
              @input="(value) => onChangeStyleOfTable('color', value)"
            />
          </b-button>

          <div class="d-flex align-items-baseline p-2 w-10 t-input">
            <label
              class="mr-1"
              for="cell-value"
            >
              W:
            </label>
            <b-input
              id="cell-value"
              :value="tableWidth"
              type="number"
              class="form-control"
              @input="(value) => changeTableWidth(value)"
            />
          </div>

          <div class="d-flex align-items-baseline p-2 w-10 t-input">
            <label
              class="mr-1"
              for="h-cell-value"
            >
              H:
            </label>
            <b-input
              id="cell-value"
              :value="tableHeight"
              type="number"
              class="form-control"
              :disabled="autoHeight"
              @input="(value) => changeTableHeight(value)"
            />
          </div>

          <div class="d-flex align-items-center p-2">
            <b-form-checkbox 
              v-model="autoHeight" 
              switch
              @change="handleAutoHeightChange"
            >
              Use auto height
            </b-form-checkbox>
          </div>
        </b-button-group>
        
        <b-form-row
          v-if="selectedCells.length"
          class="mt-2"
        >
          <b-col cols="2">
            <div>
              <b-form-group
                label="Text"
                description="Press shift + enter to apply changes"
                class="table-text-area"
              >
                <b-form-textarea
                  id="cell-value"
                  :value="selectedCells[0].value"
                  type="text"
                  class="form-control"
                  rows="3"
                  @input="(value) => selectedCells[0].value = value"
                />
              </b-form-group>
            </div>
          </b-col>
          
          <b-col 
            cols="8" 
            class="font-controls"
          >
            <b-form-row>
              <b-col cols="2">
                <label
                  class="mb-0 mb-1"
                  for="font-family"
                >
                  Font Family
                </label>
                <select
                  id="font-family"
                  class="form-control form-control-sm"
                  :value="fontFamily"
                  @input="setFontFamily"
                >
                  <option
                    v-for="(currentFont, index) in fontsList"
                    :key="(currentFont.index || currentFont.created_at) + index"
                    :value="currentFont.index || currentFont"
                    :style="{'font-family': currentFont.index}"
                  >
                    {{ currentFont.name || currentFont.title }}
                  </option>
                </select>
              </b-col>

              <b-col cols="2">
                <label
                  class="mb-0 mb-1"
                  for="font-family"
                >
                  Aligned
                </label>
                <select
                  id="font-family"
                  class="form-control form-control-sm"
                  :value="align"
                  @input="setFontAligned"
                >
                  <option
                    v-for="(item, index) in textAlignedList"
                    :key="item + index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </b-col>

              <b-col cols="2">
                <div>
                  <label
                    class="mb-1"
                    for="cell-value"
                  >
                    Font Size
                  </label>
                  <b-input
                    id="cell-value"
                    v-model="selectedCellTextSize"
                    type="number"
                    class="form-control"
                    size="sm"
                    @input="(value) => handleChangeCellStyleRule('font-size', value, 'px')"
                  />
                </div>
              </b-col>

              <b-col cols="2">
                <div>
                  <label
                    class="mb-1"
                    for="cell-value"
                  >
                    Fixed Width
                  </label>
                  <b-input
                    id="cell-value"
                    v-model="selectedCellWidth"
                    type="number"
                    class="form-control"
                    size="sm"
                    @input="(value) => handleChangeColumnWidth('width', value)"
                  />
                </div>
              </b-col>

              <b-col cols="2">
                <div>
                  <label
                    class="mb-1"
                    for="cell-value"
                  >
                    Min Width
                  </label>
                  <b-input
                    id="cell-value"
                    v-model="selectedCellMinWidth"
                    type="number"
                    class="form-control"
                    size="sm"
                    @input="(value) => handleChangeColumnWidth('min-width', value)"
                  />
                </div>
              </b-col>

              <b-col cols="2">
                <div>
                  <label
                    class="mb-1"
                    for="cell-height"
                  >
                    Height
                  </label>
                  <b-input
                    id="cell-height"
                    v-model="selectedCellMinHeight"
                    type="number"
                    class="form-control"
                    :disabled="!autoHeight"
                    size="sm"
                    @input="(value) => changeRowStyles('height', value, 'px')"
                  />
                </div>
              </b-col>
            </b-form-row>
            <b-form-row class="align-items-end mt-2">
              <b-col cols="0">
                <label
                  class="mb-0"
                  for="cell-value"
                >
                  Color
                </label>
                <div class="cpw">
                  <ColorPicker
                    v-model="selectedCellTextColor"
                    @input="(value) => handleChangeCellStyleRule('color', value, '')"
                  />
                </div>
              </b-col>

              <b-col cols="0">
                <label
                  class="mb-0"
                  for="cell-value"
                >
                  Cell
                </label>
                <div class="cpw">
                  <ColorPicker
                    v-model="selectedCellBgColor"
                    @input="(value) => handleChangeCellStyleRule('background-color', value, '')"
                  />
                </div>
              </b-col>
              
              <b-col cols="0">
                <label
                  class="mb-0"
                  for="cell-value"
                >
                  Border
                </label>
                <div class="cpw">
                  <ColorPicker
                    v-model="selectedCellBorderColor"
                    @input="(value) => changeCellBorders('border-color', value, '')"
                  />
                </div>
              </b-col>

              <b-col cols="2">
                <label
                  class="mb-1"
                  for="cell-value"
                >
                  Border width
                </label>
                <b-input
                  id="cell-value"
                  v-model="selectedCellBorderWidth"
                  type="number"
                  class="form-control"
                  min="1"
                  size="sm"
                  @input="(value) => changeCellBorders('border-width', value, 'px')"
                />
              </b-col>

              <b-col cols="2">
                <label
                  class="mb-0 mb-1"
                  for="border-style"
                >
                  Style
                </label>
                <select
                  id="border-style"
                  class="form-control form-control-sm"
                  :value="borderStyle"
                  @input="setBorderStyle"
                >
                  <option
                    v-for="(item, index) in borderStyleOptions"
                    :key="item + index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </b-col>
              
              <b-col cols="5">
                <b-form-radio-group
                  v-model="applyToBorders"
                  class="apply-to-buttons"
                  :options="applyToBordersOptions"
                  button-variant="outline-secondary"
                  size="sm"
                  buttons
                />
              </b-col>
            </b-form-row>
            
            <b-form-row class="align-items-center mt-2 px-1">
              <div>
                <label class="mb-0 mt-1 mr-2">Apply to:</label>
                <b-form-radio-group
                  v-model="applyToCells"
                  class="apply-to-buttons"
                  :options="applyToOptions"
                  button-variant="outline-secondary"
                  size="sm"
                  buttons
                />
              </div>

              <b-button
                class="ml-2 text-dark"
                variant="outline-secondary"
                size="sm"
                @click="showFileUploadModal"
              >
                <b-icon icon="image-fill" />
              </b-button>

              <b-button
                class="ml-2 text-dark"
                variant="outline-secondary"
                size="sm"
                @click="moveColumn('left')"
              >
                <b-icon icon="arrow-left" />
              </b-button>

              <b-button
                class="ml-2 text-dark"
                variant="outline-secondary"
                size="sm"
                @click="moveColumn('right')"
              >
                <b-icon icon="arrow-right" />
              </b-button>

              <b-button
                class="ml-2 text-dark"
                variant="outline-secondary"
                size="sm"
                @click="copyColumnStyle"
              >
                <b-icon 
                  v-if="columnStyles.length" 
                  icon="clipboard-check"
                />
                <b-icon 
                  v-else 
                  icon="clipboard"
                />
              </b-button>
            </b-form-row>
          </b-col>

          <b-col 
            cols="2" 
            style="padding: 5px;"
          >
            <b-form-row>
              <b-col cols="12">
                <div>
                  <label
                    class="mb-0 mt-1"
                    for="cell-value"
                  >
                    Colspan
                  </label>
                  <b-input
                    id="cell-value"
                    :value="selectedCells[0].colspan"
                    type="number"
                    class="form-control"
                    :disabled="selectedCells.length > 1"
                    size="sm"
                    @input="(value) => selectedCells[0].colspan = value"
                  />
                </div>
              </b-col>
            </b-form-row>
            
            <b-form-row>
              <b-col cols="6">
                <b-button
                  class="btn btn-secondary field-btn mt-0 w-100 mt-2"
                  size="sm"
                  @click="onCancel"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col cols="6">
                <b-button
                  class="btn btn-success field-btn mt-0 w-100 mt-2"
                  size="sm"
                  @click="onApply"
                >
                  Apply
                </b-button>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col cols="12">
                <div
                  class="mt-3"
                  style="font-size: 14px"
                >
                  Sum column widths: {{ setColumnWidthsSum }}
                </div>
              </b-col>
            </b-form-row>
          </b-col>
        </b-form-row>
      </div>

      <div
        v-if="table && table.rows.length"
        ref="tableContainer"
        class="table-builder-container"
      >
        <table
          ref="table"
          class="no-select"
          :style="getStyleOfTable"
        >
          <thead>
            <tr :style="table.headerRowStyles.getStyle()">
              <th
                v-for="column in table.headers"
                :key="column.id"
                :class="{'selected-cell': selectedCells.some(item => item.id === column.id)}"
                :style="column.stringStyles"
                :colspan="column.colspan"
                @click="(event) => onSelectCell(event, column)"
              >
                <span>{{ column.value }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in table.rows"
              :key="row.id"
              :style="row.stringStyles"
            >
              <td
                v-for="cell in row.cells"
                :key="cell.id"
                :class="{'selected-cell': selectedCells.some(item => item.id === cell.id)}"
                :style="cell.stringStyles"
                :colspan="cell.colspan"
                @click="(event) => onSelectCell(event, cell)"
              >
                <img 
                  v-if="cell.src" 
                  :src="cell.src"
                  style="width: 100%; height: 100%;"
                  alt="img"
                >
                <span v-else>{{ cell.value }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-else
        class="d-flex justify-content-center"
      >
        <b-dropdown text="Create Table">
          <div class="p-2">
            <div class="d-flex">
              <b-form-group>
                <label>Columns</label>
                <b-form-input
                  v-model.number="createTable.columns"
                  type="number"
                  size="sm"
                />
              </b-form-group>
              <div class="mx-2" />
              <b-form-group>
                <label>Rows</label>
                <b-form-input
                  v-model.number="createTable.rows"
                  type="number"
                  size="sm"
                />
              </b-form-group>
            </div>

            <b-button
              variant="primary"
              size="sm"
              class="w-100"
              @click="onCreateTable"
            >
              Create
            </b-button>
          </div>
        </b-dropdown>
      </div>
    </b-modal>
    
    <TableFileUploadModal 
      ref="tableFileUploadModal"
      :src="selectedCellUrl"
      @save="handleAddImage"
    />
  </div>
</template>

<script>
import { simpleTableBuilderEventBroker, EVENTS } from '@frontend/group/modules/simple-table-builder/event-broker';
import DropdownCounter from '../../../common/atoms/DropdownCounter.vue';
import ColorPicker from '../EditorControl/components/ColorPicker.vue';
import FontFamilyManager from '../../../../common/load-fonts';
import {
  APPLY_TO_BORDERS_HANDLERS,
  APPLY_TO_BORDERS_LABEL,
  APPLY_TO_BORDERS_VALUES,
  APPLY_TO_TEXT,
  APPLY_TO_VALUES, BORDER_STYLE_OPTIONS
} from "@frontend/group/modules/simple-table-builder/constants";
import TableFileUploadModal from "@frontend/components/modules/layouts-page/modals/TableFileUploadModal.vue";

let tableInstance;
let timeout;

export default {
  name: 'SimpleTableBuilderModal',
  components: {
    TableFileUploadModal,
    DropdownCounter,
    ColorPicker
  },
  data() {
    return {
      $element: null,
      table: null,
      params: null,
      tableData: null,
      createTable: {
        columns: 10,
        rows: 10
      },
      selectedCells: [],
      selectedCellBgColor: '#ffffff',
      selectedCellTextColor: '#000000',
      selectedCellTextSize: 25,
      selectedCellWidth: '1',
      selectedCellMinWidth: '1',
      selectedCellMinHeight: '1',
      selectedCellBorderColor: '#000000',
      tableWidth: 0,
      tableHeight: 0,
      fontFamilyManager: new FontFamilyManager(),
      fontsList: [],
      fontFamily: 'Nunito-Bold-257',
      align: 'left',
      textAlignedList: [
        'left',
        'center',
        'right',
      ],
      applyToCells: APPLY_TO_VALUES.SELECTED,
      autoHeight: true,
      applyToBorders: APPLY_TO_BORDERS_VALUES.ALL,
      applyToBordersOptions: [
        { text: APPLY_TO_BORDERS_LABEL.ALL, value: APPLY_TO_BORDERS_VALUES.ALL },
        { text: APPLY_TO_BORDERS_LABEL.OUTER, value: APPLY_TO_BORDERS_VALUES.OUTER },
        { text: APPLY_TO_BORDERS_LABEL.INNER, value: APPLY_TO_BORDERS_VALUES.INNER },
        { text: APPLY_TO_BORDERS_LABEL.LEFT, value: APPLY_TO_BORDERS_VALUES.LEFT },
        { text: APPLY_TO_BORDERS_LABEL.RIGHT, value: APPLY_TO_BORDERS_VALUES.RIGHT },
        { text: APPLY_TO_BORDERS_LABEL.TOP, value: APPLY_TO_BORDERS_VALUES.TOP },
        { text: APPLY_TO_BORDERS_LABEL.BOTTOM, value: APPLY_TO_BORDERS_VALUES.BOTTOM },
      ],
      borderStyle: 'solid',
      borderStyleOptions: BORDER_STYLE_OPTIONS,
      selectedCellBorderWidth: 1,
      setColumnWidthsSum: 0,
      selectedCellUrl: '',
      columnStyles: [],
      isAdditionalTableField: false,
    };
  },
  computed: {
    getStyleOfTable() {
      if (!this.table) return {};

      const rules = this.table.styles.rules;

      return {
        ...rules,
      };
    },
    
    applyToOptions() {
      const disabled = this.table.headers.some(cell => cell.id === this.selectedCells[0].id);
      
      return [
        { text: APPLY_TO_TEXT.SELECTED, value: APPLY_TO_VALUES.SELECTED },
        { text: APPLY_TO_TEXT.ALL, value: APPLY_TO_VALUES.ALL },
        { text: APPLY_TO_TEXT.HEADER, value: APPLY_TO_VALUES.HEADER },
        { text: APPLY_TO_TEXT.BODY, value: APPLY_TO_VALUES.BODY },
        { text: APPLY_TO_TEXT.COLUMN, value: APPLY_TO_VALUES.COLUMN, disabled },
        { text: APPLY_TO_TEXT.ALTERNATING_ROWS, value: APPLY_TO_VALUES.ALTERNATING_ROWS, disabled }
      ]
    }
  },
  mounted() {
    simpleTableBuilderEventBroker.on(EVENTS.INIT, this.init.bind(this));
    window.addEventListener('keydown', this.onKeyDown);
    this.fontFamilyManager.loadData();
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    async init({ $element, table, styles, params, tableData, isAdditionalTableField = false }) {
      this.clearData();
      
      this.$element = $element;
      this.params = params;
      this.tableData = tableData;
      tableInstance = table;
      this.table = tableInstance.toData();
      this.fontsList = this.fontFamilyManager.fonts;
      this.autoHeight = tableInstance.styles.rules.height === 'auto';
      this.setColumnWidthsSum = tableInstance.getSumOfColumnWidths();
      this.isAdditionalTableField = isAdditionalTableField;
      
      this.$bvModal.show('simple-table-builder');

      this.$nextTick(async () => {
        const tableDimension = await tableInstance.getDimension()
        
        if (tableInstance?.styles.rules.width && tableInstance?.styles.rules.height) {
          this.tableWidth = parseInt(tableInstance.styles.rules.width.replace('px', ''));
          this.tableHeight = tableInstance.styles.rules.height === 'auto' 
            ? tableDimension.height
            : parseInt(tableInstance.styles.rules.height.replace('px', ''));
        } else {
          this.tableWidth = tableDimension.width
          this.tableHeight = tableDimension.height
        }
      });
    },
    clearData() {
      this.$element = null;
      this.table = null;
      this.params = null;
      this.createTable = {
        columns: 10,
        rows: 10
      };
      this.selectedCells = [];
      this.selectedCellBgColor = '#ffffff';
      this.selectedCellTextColor = '#000000';
      this.selectedCellMinWidth = '1';
      this.selectedCellTextSize = 16;
      this.applyToCells = APPLY_TO_VALUES.SELECTED;
    },
    onCreateTable() {
      const columns = this.createTable.columns;
      const rows = this.createTable.rows;

      tableInstance.createTableGrid(columns, rows);

      this.table = tableInstance.toData();
      
      this.$nextTick(async () => {
        const tableDimension = await tableInstance.getDimension();
        
        this.tableWidth = tableDimension.width
        this.tableHeight = tableDimension.height
      });
    },
    addRow() {
      tableInstance.addNewRow();

      this.table = tableInstance.toData();
    },
    addColumn() {
      tableInstance.addNewColumn();

      this.table = tableInstance.toData();
    },
    removeRow() {
      tableInstance.removeRows(this.selectedCells);
      this.table = tableInstance.toData();
      this.selectedCells = [];
    },
    removeColumn() {
      tableInstance.removeColumns(this.selectedCells);
      this.table = tableInstance.toData();
      this.selectedCells = [];
    },
    moveColumn(action) {
      tableInstance.moveColumn(action, this.selectedCells[0]);
      this.table = tableInstance.toData();
    },
    setFontFamily(fontFamily) {
      this.fontObject = this.getFontObjectFromList(fontFamily.target.value);
      this.fontFamily = this.fontObject.index;
      this.handleChangeCellStyleRule('font-family', this.fontFamily, '');
    },
    getFontObjectFromList(font) {
      return this.fontsList.find(o => o.index === font);
    },
    setFontAligned(align) {
      this.align = align.target.value;
      this.handleChangeCellStyleRule('text-align', this.align, '');
    },
    setBorderStyle({ target }) {
      this.borderStyle = target.value;
      this.changeCellBorders('border-style', this.borderStyle, '');
    },
    onSelectCell(event, { id, value, colspan }) {
      if (event.button === 0 && event.shiftKey ) {
        const index = this.selectedCells.findIndex(cell => cell.id === id);
        
        index >= 0 
          ? this.selectedCells.splice(index, 1) 
          : this.selectedCells.push({ id, value, colspan });
        
      } else {
        this.selectedCells = [{ id, value, colspan }];
      }
      
      if (!this.selectedCells.length) return;

      if (this.columnStyles.length) {
       this.applyStylesToColumn();
      }
      
      this.selectedCellBgColor = tableInstance.getStyle(this.selectedCells[0].id, 'background-color', '#ffffff');
      this.selectedCellTextColor = tableInstance.getStyle(this.selectedCells[0].id, 'color', '#000000');
      this.selectedCellTextSize = tableInstance.getStyle(this.selectedCells[0].id, 'font-size', 25);
      this.align = tableInstance.getStyle(this.selectedCells[0].id, 'text-align', 'left');
      this.fontFamily = tableInstance.getStyle(this.selectedCells[0].id, 'font-family', 'Nunito-Bold-257');
      this.selectedCellMinHeight = tableInstance.getStyle(this.selectedCells[0].id, 'height', 0);
      this.selectedCellBorderColor = tableInstance.getStyle(this.selectedCells[0].id, 'border-color', '#000000', this.applyToBorders);
      this.selectedCellBorderWidth = tableInstance.getStyle(this.selectedCells[0].id, 'border-width', 1, this.applyToBorders);
      this.borderStyle = tableInstance.getStyle(this.selectedCells[0].id, 'border-style', 'solid', this.applyToBorders);
      this.selectedCellUrl = tableInstance.getCellSrc(this.selectedCells[0].id);
      this.selectedCellWidth = tableInstance.getStyle(this.selectedCells[0].id, 'width', '') !== 'auto'
        ? tableInstance.getStyle(this.selectedCells[0].id, 'width', '')
        : '';
      this.selectedCellMinWidth = tableInstance.getStyle(this.selectedCells[0].id, 'min-width', '') !== 'auto'
        ? tableInstance.getStyle(this.selectedCells[0].id, 'min-width', '')
        : '';
      
    },
    handleChangeCellStyleRule(rule, value, measure = '') {
      this.applyToCells === APPLY_TO_VALUES.SELECTED 
        ? this.changeCellStyleRule(rule, value, measure)
        : this.changeMultipleCellsStyleRules(rule, value, measure); 
    },
    changeMultipleCellsStyleRules(rule, value, measure = '') {
      tableInstance.changeMultipleCellsStyle([
        {
          rule: rule,
          value: value,
          measure: measure
        }
      ], 
        this.applyToCells, 
        this.selectedCells[0]
      );

      this.table = tableInstance.toData();

      this.handleTableDimension();
    },
    changeCellStyleRule(rule, value, measure = '') {
      tableInstance.changeCellStyle({
        cells: this.selectedCells.map(cell => cell.id),
        rules: [{
          rule: rule,
          value: value,
          measure: measure
        }],
      });

      this.table = tableInstance.toData();

      this.handleTableDimension();
    },

    changeRowStyles(rule, value, measure = '') {
      tableInstance.changeRowStyles({
        cells: this.selectedCells.map(cell => cell.id),
        rules: [{
          rule: rule,
          value: value,
          measure: measure
        }],
      });
      
      this.table = tableInstance.toData();

      this.handleTableDimension();
    },
    changeCellBorders(rule, value, measure = '') {
      tableInstance.changeCellBorderStyle({
        cells: this.selectedCells.map(cell => cell.id),
        applyTo: this.applyToCells,
        handler: APPLY_TO_BORDERS_HANDLERS[this.applyToBorders],
        styleRule: {
          rule: rule,
          value: value,
          measure: measure,
          applyToBorder: this.applyToBorders
        },
      });

      this.table = tableInstance.toData();

      this.handleTableDimension();
    },
    changeTableWidth(width) {
      this.tableWidth = width;
      tableInstance.setDimensions(this.tableWidth, this.tableHeight);
      this.table = tableInstance.toData();
    },
    changeTableHeight(height) {
      this.tableHeight = height;
      tableInstance.setDimensions(this.tableWidth, this.tableHeight);
      this.table = tableInstance.toData();
    },
    onApply() {
      tableInstance.changeCellValue(this.selectedCells[0]);
      this.table = tableInstance.toData();
    },
    onKeyDown(e) {
      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault();
        this.onApply();
      }
    },
    onCancel() {
      this.selectedCells = [];
    },
    getStyleValue(key, defaultValue) {
      const value = this.table.styles.rules[key];
      const number = parseInt(value);

      return value === undefined ? defaultValue : Number.isNaN(number) ? value : number;
    },
    onChangeStyleOfTable(key, value, measure = '', hasChangeForCells = false) {
      tableInstance.styles.setRules({
        [key]: `${value}${measure}`,
      });

      if (hasChangeForCells) {
        tableInstance.setCellsRules({
          [key]: `${value}${measure}`,
        });
      }

      this.table = tableInstance.toData();

      this.$nextTick(async () => {
        const tableDimension = await tableInstance.getDimension();
        
        this.tableWidth = tableDimension.width
        this.tableHeight = tableDimension.height
      });
    },
    onSave() {
      const height = this.autoHeight ? 'auto' : this.tableHeight;
      
      tableInstance.setDimensions(this.tableWidth, height);
      
      if (this.isAdditionalTableField) {
        simpleTableBuilderEventBroker.fire(EVENTS.ADD_ADDITIONAL_TABLE_FIELD, { tableInstance });
        
        this.isAdditionalTableField = false;
        
        return;
      }
      
      simpleTableBuilderEventBroker.fire(EVENTS.SAVE, {
        $element: this.$element,
        tableInstance,
        styles: this.styles,
        params: this.params,
        tableData: this.tableData
      });
    },
    handleTableDimension() {
      this.$nextTick(async () => {
        const tableDimension = await tableInstance.getDimension();
        
        if (tableInstance?.styles.rules.width && tableInstance?.styles.rules.height) {
          this.tableWidth = parseInt(tableInstance.styles.rules.width.replace('px', ''));
          this.tableHeight = this.autoHeight 
            ? tableDimension.height 
            : parseInt(tableInstance.styles.rules.height.replace('px', ''));
        } else {
          this.tableWidth = tableDimension.width
          this.tableHeight = tableDimension.height
        }
      });
    },
    async handleAutoHeightChange() {
      if (this.autoHeight) {
        tableInstance.setDimensions(this.tableWidth, 'auto');
        this.table = tableInstance.toData();
      } else {
        // Remove the height from all rows to adjust the table based on the table's overall height.
        const cells = tableInstance.rows
          .reduce((acc, row) => [...acc, ...row.cells], [...tableInstance.headers])
          .map(cell => cell.id);
        
        tableInstance.changeRowStyles({
          cells,
          rules: [{
            rule: 'height',
            value: 0,
            measure: 'px'
          }],
        });

        const tableDimension = await tableInstance.getDimension();

        this.tableHeight = tableDimension.height;
        tableInstance.setDimensions(this.tableWidth, this.tableHeight);
        this.table = tableInstance.toData();
      }
      
    },
    handleChangeColumnWidth(rule, val) {
      const value = val.length ? val : 'auto';
      const measure = val.length ? 'px' : '';

      this.handleChangeCellStyleRule(rule, value, measure);
      
      if (timeout) {
        clearTimeout(timeout);
      }
      
      timeout = setTimeout(() => {
        this.setColumnWidthsSum = tableInstance.getSumOfColumnWidths();
      }, 300);
    },
    showFileUploadModal() {
      this.$refs.tableFileUploadModal.show();
    },
    handleAddImage(url) {
      tableInstance.setImage({
        cells: this.selectedCells.map(cell => cell.id),
        src: url
      });

      this.table = tableInstance.toData();
      this.selectedCellUrl = url;
      this.selectedCells[0].value = '';
    },
    copyColumnStyle() {
      this.columnStyles = tableInstance.getColumnStyles(this.selectedCells[0]);
    },
    
    applyStylesToColumn() {
      tableInstance.applyColumnStylesToColumn(this.selectedCells[0], this.columnStyles);
      this.columnStyles = [];
      this.table = tableInstance.toData();
    }
  }
};
</script>

<style lang="scss">

.table-builder-container {
  overflow: auto;
  min-height: 500px;
  max-height: 500px;

  .content-editor {
    cursor: text;
    width: 100%;
    height: 100%;
  }
}

.selected-cell {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-color: #2eb85c;
    border-style: solid;
  }
}

.field-btn {
  margin-top: 27px;
}

.cpw {
  border: 1px solid #d8dbe0;
  border-radius: 2px;
  margin-top: 3px;
  width: 31px;
}

.t-input {
  width: 120px;
}

.font-controls {
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 5px;
}

.apply-to-buttons {
  .btn-outline-secondary {
    color: #4f5d73;
  }
}

.no-select {
  user-select: none;
}

.table-text-area {
  .text-muted {
    font-size: 10px;
  }
}
</style>
