<template>
  <b-row>
    <b-col class="d-flex align-items-center">
      <b-button
        variant="primary"
        @click="extendContainer"
      >
        Extend container
      </b-button>

      <i
        class="c-icon cil-settings ml-2"
        @click="toggleConfig"
      />
    </b-col>

    <b-col
      v-if="isExtended"
      cols="12"
      class="mt-2"
    >
      <b-form-group>
        <b-form-select v-model="isEnabled">
          <b-form-select-option value="true">
            Enabled
          </b-form-select-option>

          <b-form-select-option value="false">
            Disabled
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-row>
        <b-col>
          <b-form-group label="Container name">
            <b-form-input
              v-model="containerName"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Margin">
            <b-form-input
              v-model="margin"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    target: {
      type: fabric.Object,
      require: true,
      default: () => {},
    },
    config: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data() {
    return {
      isExtended: false,
      isEnabled: false,
      containerName: '',
      margin: 0,
      result: {},
    }
  },
  watch: {
    config: {
      handler(newVal) {
        const value = JSON.parse(newVal);
        if (newVal !== JSON.stringify(this.result)) {
          Object.assign(this, value);
          this.updateResult();
        }
      },
      deep: true,
      immediate: true,
    },
    isEnabled: function () {
      this.updateResult();
    },
    containerName: function () {
      this.updateResult();
    },
    margin: function () {
      this.updateResult();
    },
    result: function () {
      this.$emit('data-updated', JSON.stringify(this.result));
    }
  },
  methods: {
    toggleConfig() {
      this.isExtended = !this.isExtended;
    },
    extendContainer() {
      this.$emit('extend-container', {target: this.target});
    },
    updateResult() {
      this.result = {
        isEnabled: this.isEnabled,
        containerName: this.containerName,
        margin: this.margin,
      };
    }
  },
}
</script>
