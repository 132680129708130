import { fabric } from "fabric";

export const circleHandler = (defaultOptions) => (object, objectData) => {

    const smallImageThreshold = 450;
    let reductionFactor = 0.1;
    let reductionFactorHeight = 0;
    if (object.width < smallImageThreshold || object.height < smallImageThreshold) {
        reductionFactor = reductionFactorHeight = 0.3;
    } 

    const reductionAmount = object.width * reductionFactor;
    const reductionAmountHeight = object.height * reductionFactorHeight;

    const percentBase = object.width <= object.height ? object.width - reductionAmount : object.height - reductionAmountHeight

    const left = objectData.x_center * object.width - object.width / 2;
    const top = objectData.y_center * object.height - object.height / 2;
    const radius = objectData.radius * percentBase / 2;

    return new fabric.Circle({
        ...defaultOptions,
        left,
        top,
        radius,
        objectData: objectData
    });
}