
export default class Base {
    parent;

    buildFromData(field) {
        this.parent = field.parent;
    }

    toJSON() {
        const data = {...this};
        delete data.parent;
        return data;
    }
}