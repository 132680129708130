var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-main-view",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.clearSelection.apply(null, arguments)
        },
        drop: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.onDrop.apply(null, arguments)
        },
        dragover: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.dragover.apply(null, arguments)
        },
        dragleave: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.dragleave.apply(null, arguments)
        },
      },
    },
    [
      !_vm.isMoveProductModeEnabled ? _c("TopBar") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "c-main-view__container",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.clearSelection.apply(null, arguments)
            },
          },
        },
        [
          _c(_vm.view, {
            tag: "component",
            attrs: { "is-drag-and-drop-available": _vm.isDragAndDropEnabled },
          }),
          _vm._v(" "),
          _vm.isDragging
            ? _c("div", { staticClass: "c-main-view__drag-overlay" }, [
                _vm._m(0),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isListLoading
            ? _c(
                "div",
                { staticClass: "c-main-view__spinner-overlay" },
                [
                  _c("b-spinner", {
                    staticClass: "c-main-view__spinner-overlay__spinner",
                    attrs: { variant: "success", label: "Spinning" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("PaginationComponent"),
      _vm._v(" "),
      _c("SelectionActionsComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "c-main-view__drag-overlay--drag-overlay-background" },
      [
        _c("span", { staticClass: "c-main-view__drag-overlay--text" }, [
          _vm._v("Drag & drop files here"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }