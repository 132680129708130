import _ from "lodash";
import FileBrowser from "../../services/api/file-browser";
import MediaAPI from "../../services/api/media";
import {FILE_TYPE, TYPE} from "@frontend/constants/file-image-types";
import {MODES} from "@frontend/components/modules/file-browser-product-selector/constants";
import {prepareProductFileForm} from "@frontend/services/file-uploader";
import router from "@frontend/router";
import {fetchListDataBasedActionCreator} from "@frontend/components/modules/file-browser/dto/fetch-list-dto";

const getFileIdOfItem = (item) => {
  return item.name.split('.')[0];
}

const getFilteredItems = (items) => {
  const resultItems = [];
  
  for (const itemToAdd of items) {
    if (itemToAdd.type === TYPE.FOLDER) {
      resultItems.push(itemToAdd);
      continue;
    }
    
    if (!resultItems.find(item => item.fileId === itemToAdd.fileId)) {
      resultItems.push(itemToAdd);
    }
  }

  return resultItems;
}

const initState = () => ({
  //settings block start
  mode: MODES.VIEW,
  isMoveProductModeEnabled: false,
  isDragAndDropEnabled: false,
  onlyOneItemSelection: false,
  isFolderViewEnabled: false,
  showOnlySelected: false,
  grouped: false,
  multiselectEnabled: false,
  isItemsPickEnabled: true,
  isTopBarSwitchersEnabled: true,
  isItemOverlayEnabled: true,
  //settings block end
  currentCanvasElements: [],
  selectedCanvasElement: null,
  selectedFileNames: '',
  isListLoading: false,
  pickedItems: [],
  items: [],
  selectedItems: [],
  currentFolderId: null,
  currentFolderName: '',
  pagination: {
    currentPage: 1,
    pageSize: 12,
    totalCount: 0,
    lastPage: 1,
  },
  searchQuery: '',
  breadcrumbs: [],
  modal: {
    type: '',
    payload: null,
  }
})

export const MUTATIONS = {
  SET_ITEMS: 'SET_ITEMS',
  UPDATE_PROGRESS_PERCENTAGE: 'UPDATE_PROGRESS_PERCENTAGE',
  SET_LIST_LOADING: 'SET_LIST_LOADING',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  SET_LAST_PAGE: 'SET_LAST_PAGE',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  TOGGLE_SELECTED_ITEMS: 'TOGGLE_SELECTED_ITEMS',
  CLEAR_SELECTION: 'CLEAR_SELECTION',
  SET_MODAL: 'SET_MODAL',
  FINISH_PROGRESSING: 'FINISH_PROGRESSING',
  UPDATE_ITEM_NAME: 'UPDATE_ITEM_NAME',
  SET_BREADCRUMBS: 'SET_BREADCRUMBS',
  SET_SHOW_ONLY_FOLDERS: 'SET_SHOW_ONLY_FOLDERS',
  SET_CURRENT_FOLDER_ID: 'SET_CURRENT_FOLDER_ID',
  SET_CURRENT_FOLDER_NAME: 'SET_CURRENT_FOLDER_NAME',
  SET_MULTISELECT_ENABLED: 'SET_MULTISELECT_ENABLED',
  SET_SELECTION: 'SET_SELECTION',
  SET_IS_FOLDER_VIEW_ENABLED: 'SET_IS_FOLDER_VIEW_ENABLED',
  SET_SHOW_ONLY_SELECTED: 'SET_SHOW_ONLY_SELECTED',
  SET_PICKED_ITEMS: 'SET_PICKED_ITEMS',
  SET_ONLY_ONE_ITEM_SELECTION: 'SET_ONLY_ONE_ITEM_SELECTION',
  SET_SELECTED_FILE_NAMES: 'SET_SELECTED_FILE_NAMES',
  SET_IS_DRAG_AND_DROP_ENABLED: 'SET_IS_DRAG_AND_DROP_ENABLED',
  SET_IS_ITEMS_PICK_ENABLED: 'SET_IS_ITEMS_PICK_ENABLED',
  SET_IS_TOP_BAR_SWITCHERS_ENABLED: 'SET_IS_TOP_BAR_SWITCHERS_ENABLED',
  SET_IS_ITEM_OVERLAY_ENABLED: 'SET_IS_ITEM_OVERLAY_ENABLED',
  SET_IS_MOVE_PRODUCT_MODE_ENABLED: 'SET_IS_MOVE_PRODUCT_MODE_ENABLED',
  SET_GROUPED: 'SET_GROUPED',
  SET_CURRENT_CANVAS_ELEMENTS: 'SET_CURRENT_CANVAS_ELEMENTS',
  SET_SELECTED_CANVAS_ELEMENT: 'SET_SELECTED_CANVAS_ELEMENT',
  SET_MODE: 'SET_MODE',
}

export default {
  namespaced: true,
  state: initState(),
  getters: {
    mode: state => state.mode,
    isListLoading: state => state.isListLoading,
    items: state => state.items,
    firstSelectedItem: state => state.selectedItems.length && state.items.find(item => item.id === state.selectedItems[0].id),
    selectedItems: state => state.selectedItems,
    selectedFolders: state => state.items.filter(item => item.type === 'folder' && state.selectedItems.some(selectedItem => selectedItem.id === item.id)).map(item => item.id),
    totalCount: state => state.pagination.totalCount,
    currentPage: state => state.pagination.currentPage,
    searchQuery: state => state.searchQuery,
    isSelected: state => item => state.selectedItems.some(selectedItem => selectedItem.id === item.id),
    selectedCount: state => state.selectedItems.length,
    isAnySelected: state => !!state.selectedItems.length,
    isAnyPicked: state => !!state.pickedItems.length,
    breadcrumbs: state => state.breadcrumbs,
    currentFolderId: state => state.currentFolderId,
    currentFolderName: state => state.currentFolderName,
    isFolderViewEnabled: state => state.isFolderViewEnabled,
    showOnlySelected: state => state.showOnlySelected,
    pickedItems: state => state.pickedItems,
    selectedFileNames: state => state.selectedFileNames,
    onlyOneItemSelection: state => state.onlyOneItemSelection,
    isDragAndDropEnabled: state => state.isDragAndDropEnabled,
    isItemsPickEnabled: state => state.isItemsPickEnabled,
    isTopBarSwitchersEnabled: state => state.isTopBarSwitchersEnabled,
    isItemOverlayEnabled: state => state.isItemOverlayEnabled,
    isMoveProductModeEnabled: state => state.isMoveProductModeEnabled,
    grouped: state => state.grouped,
    currentCanvasElements: state => state.currentCanvasElements,
    selectedCanvasElement: state => state.selectedCanvasElement,
    modal: state => state.modal,
  },
  mutations: {
    [MUTATIONS.SET_LIST_LOADING](state, payload) {
      state.isListLoading = payload;
    },
    [MUTATIONS.SET_ITEMS](state, payload) {
      state.items = payload;
    },
    [MUTATIONS.SET_TOTAL_COUNT](state, payload) {
      state.pagination.totalCount = payload
    },
    [MUTATIONS.SET_LAST_PAGE](state, payload) {
      state.pagination.lastPage = payload
    },
    [MUTATIONS.SET_CURRENT_PAGE](state, payload) {
      state.pagination.currentPage = payload
    },
    [MUTATIONS.SET_SEARCH_QUERY](state, payload) {
      state.searchQuery = payload
    },
    [MUTATIONS.TOGGLE_SELECTED_ITEMS](state, item) {
      const existingItemIndex = state.selectedItems.findIndex(selectedItem => selectedItem.id === item.id);

      if (existingItemIndex >= 0) {
        state.selectedItems.splice(existingItemIndex, 1)
      } else {
        state.selectedItems.push(item)
      }
    },
    [MUTATIONS.CLEAR_SELECTION](state) {
      state.selectedItems = [];
    },
    [MUTATIONS.SET_SELECTION](state, payload) {
      state.selectedItems = payload
    },
    [MUTATIONS.SET_BREADCRUMBS](state, payload) {
      state.breadcrumbs = payload;
    },
    [MUTATIONS.SET_SHOW_ONLY_FOLDERS] (state, payload) {
      state.showOnlyFolders = payload
    },
    [MUTATIONS.SET_CURRENT_FOLDER_ID] (state, payload) {
      state.currentFolderId = payload
    },
    [MUTATIONS.SET_CURRENT_FOLDER_NAME] (state, payload) {
      state.currentFolderName = payload
    },
    [MUTATIONS.SET_MULTISELECT_ENABLED] (state, payload) {
      state.multiselectEnabled = payload
    },
    [MUTATIONS.SET_IS_FOLDER_VIEW_ENABLED] (state, payload) {
      state.isFolderViewEnabled = payload
    },
    [MUTATIONS.SET_SHOW_ONLY_SELECTED] (state, payload) {
      state.showOnlySelected = payload
    },
    [MUTATIONS.SET_PICKED_ITEMS] (state, payload) {
      state.pickedItems = payload
    },
    [MUTATIONS.SET_ONLY_ONE_ITEM_SELECTION] (state, payload) {
      state.onlyOneItemSelection = payload
    },
    [MUTATIONS.SET_SELECTED_FILE_NAMES] (state, payload) {
      state.selectedFileNames = payload
    },
    [MUTATIONS.SET_IS_DRAG_AND_DROP_ENABLED] (state, payload) {
      state.isDragAndDropEnabled = payload
    },
    [MUTATIONS.SET_IS_ITEMS_PICK_ENABLED] (state, payload) {
      state.isItemsPickEnabled = payload
    },
    [MUTATIONS.SET_IS_TOP_BAR_SWITCHERS_ENABLED] (state, payload) {
      state.isTopBarSwitchersEnabled = payload
    },
    [MUTATIONS.SET_IS_ITEM_OVERLAY_ENABLED] (state, payload) {
      state.isItemOverlayEnabled = payload
    },
    [MUTATIONS.SET_IS_MOVE_PRODUCT_MODE_ENABLED] (state, payload) {
      state.isMoveProductModeEnabled = payload
    },
    [MUTATIONS.SET_CURRENT_CANVAS_ELEMENTS] (state, payload) {
      state.currentCanvasElements = payload
    },
    [MUTATIONS.SET_SELECTED_CANVAS_ELEMENT] (state, payload) {
      state.selectedCanvasElement = payload
    },
    [MUTATIONS.SET_GROUPED] (state, payload) {
      state.grouped = payload
    },
    [MUTATIONS.SET_MODE] (state, payload) {
      state.mode = payload
    },
    [MUTATIONS.SET_MODAL](state, payload) {
      state.modal = payload;
    }
  },
  actions: {
    async fetchList({ commit, state, rootGetters }) {
      if (state.mode === MODES.VIEW && !(state.isFolderViewEnabled || state.grouped)) {
        return;
      }

      const resolveType = (item) => {
        if (item.type === FILE_TYPE.FOLDER) {
          return FILE_TYPE.FOLDER;
        }
        
        return FILE_TYPE.IMAGE;
      }
      
      commit(MUTATIONS.SET_LIST_LOADING, true)
      commit(MUTATIONS.SET_IS_TOP_BAR_SWITCHERS_ENABLED, false);

      if (!state.isMoveProductModeEnabled && state.pickedItems.length === 0) {
        commit(MUTATIONS.SET_SHOW_ONLY_SELECTED, false);
      }
      
      let query = state.searchQuery;

      let exactMatch = false;

      if (!state.isMoveProductModeEnabled && state.showOnlySelected) {
        //add dot here in order to filter other items with similar name
        const queryArray = state.pickedItems.map(item => item.fileId + '.');
        query = queryArray.join(',');

        exactMatch = true;
      }
      
      const requestParams = {
        pageSize: state.pagination.pageSize,
        pageNumber: state.pagination.currentPage,
        searchKey: query,
        exactMatch,
      };


      if (state.mode === MODES.VIEW && !state.currentFolderId) {
        requestParams.pageSize = 11;
      }
      
      if (state.showOnlySelected) {
        requestParams.pageSize = 100;
      }
      
      if (state.isFolderViewEnabled) {
        requestParams['fileBrowser'] = true;

        if (!!state.currentFolderId) {
          requestParams['folder_id'] = state.currentFolderId;
        }
      }
      
      const requests = [
        FileBrowser.getFileList(requestParams)
      ];

      if (state.pickedItems.length > 0) {
        const layoutId = rootGetters['layout/selectedInstance'].id;
        
        requests.push(
          MediaAPI.getBannerView({
            file_ids: state.selectedFileNames,
            show_warning: true,
            layout_id: layoutId
          })
        )
      }
      
      const allData = await Promise.all(requests);
      const itemsData = allData[0].data;
      
      let items = itemsData.items.map((item) => {
        const pickedItem = state.pickedItems.find(pickedItem => pickedItem.id === item.id);
        let count = 0;
        
        if (pickedItem) {
          count = pickedItem.count;
        }
        
        return {
          ...item,
          type: resolveType(item),
          count,
          fileId: getFileIdOfItem(item),
        }
      });
      
      items = getFilteredItems(items);

      // get correct thumbnail and path mappings for picked items
      if (allData.length > 1) {
        const mappedFiles = allData[1].data.files;

        for (const pickedItem of state.pickedItems) {
          const correctedFile = mappedFiles.find(file => file.name === pickedItem.name);

          if (correctedFile) {
            pickedItem.path = correctedFile.path;
            pickedItem.thumbnail = correctedFile.thumbnail;
          }
        }
      }

      commit(MUTATIONS.SET_ITEMS, items)
      commit(MUTATIONS.SET_TOTAL_COUNT, itemsData.totalCount)
      commit(MUTATIONS.SET_LAST_PAGE, itemsData.last_page)
      commit(MUTATIONS.SET_IS_TOP_BAR_SWITCHERS_ENABLED, true);
      commit(MUTATIONS.SET_LIST_LOADING, false)
    },
    async fetchBreadcrumbs({ commit, state }){
      if (!state.currentFolderId) {
        return commit(MUTATIONS.SET_BREADCRUMBS, []);
      }
      try {
        const { data } = await FileBrowser.getBreadcrumbs(state.currentFolderId, 'products');

        const breadcrumbs = data.map(folder => {
          return {
            id: folder.id,
            text: folder.name
          };
        })

        commit(MUTATIONS.SET_BREADCRUMBS, breadcrumbs);
      } catch (error) {
        return commit(MUTATIONS.SET_BREADCRUMBS, []);
      }
    },
    async fetchData({ dispatch, state }) {
      await Promise.all([
        dispatch('fetchList'),
        dispatch('fetchBreadcrumbs'),
      ]);
    },
    setSelection({commit}, payload) {
      commit(MUTATIONS.SET_SELECTION, payload);
    },
    clearSelection ({ commit }) {
      commit(MUTATIONS.CLEAR_SELECTION)
    },
    selectAll ({ commit, state }) {
      commit(MUTATIONS.SET_SELECTION, _.filter(_.map(state.items, 'id')))
    },
    setShowOnlyFolders({ commit }, payload) {
      commit(MUTATIONS.SET_SHOW_ONLY_FOLDERS, payload)
    },
    setCurrentFolder({ commit, state }, payload) {
      commit(MUTATIONS.SET_CURRENT_PAGE, 1)
      if (!state.isMoveProductModeEnabled) {
        commit(MUTATIONS.SET_SEARCH_QUERY, '')
      }
      commit(MUTATIONS.SET_CURRENT_FOLDER_ID, payload?.id || null)
      commit(MUTATIONS.SET_CURRENT_FOLDER_NAME, payload?.name || null)
    },
    setCurrentPage({ commit, dispatch, state }, payload) {
      commit(MUTATIONS.SET_CURRENT_PAGE, payload)
    },
    setSearchQuery({ commit, dispatch, state }, payload) {
      commit(MUTATIONS.SET_SHOW_ONLY_SELECTED, false)
      commit(MUTATIONS.SET_CURRENT_PAGE, 1)
      commit(MUTATIONS.SET_SEARCH_QUERY, payload)
    },
    setIsFolderViewEnabled({ state, commit, dispatch }, payload) {
      commit(MUTATIONS.SET_LIST_LOADING, true)
      commit(MUTATIONS.SET_IS_FOLDER_VIEW_ENABLED, payload)
       if (!state.isMoveProductModeEnabled && payload) {
           commit(MUTATIONS.SET_SHOW_ONLY_SELECTED, false)
       }
      commit(MUTATIONS.SET_CURRENT_PAGE, 1)
      commit(MUTATIONS.SET_LIST_LOADING, false)
    },
    setShowOnlySelected({ state, commit, dispatch }, payload) {
      commit(MUTATIONS.SET_LIST_LOADING, true)
      commit(MUTATIONS.SET_CURRENT_PAGE, 1)
      commit(MUTATIONS.SET_SHOW_ONLY_SELECTED, payload)
      if (!state.isMoveProductModeEnabled && payload) {
        commit(MUTATIONS.SET_IS_FOLDER_VIEW_ENABLED, false)
      }
      commit(MUTATIONS.SET_LIST_LOADING, false)
    },
    setOnlyOneItemSelection({ commit }, payload) {
      commit(MUTATIONS.SET_ONLY_ONE_ITEM_SELECTION, payload)
    },
    pickItem({ state, commit, dispatch }, payload) {
      if (state.onlyOneItemSelection) {
        commit(MUTATIONS.SET_PICKED_ITEMS, [])
        const items = state.items.map(item => {
          return {
            ...item,
            count: 0
          }
        });
        commit(MUTATIONS.SET_ITEMS, items)
      }
      
      const items = state.items.map((item) => {
        return {
          ...item,
          count: payload.id === item.id ? payload.count : item.count
        }
      });

      commit(MUTATIONS.SET_ITEMS, items)
      
      const pickedItems = [...state.pickedItems];
      const alreadyExistingItem = pickedItems.find(pickedItem => pickedItem.id === payload.id);
      
      if (alreadyExistingItem) {
        alreadyExistingItem.count = payload.count;
      } else {
        pickedItems.push(payload);
      }
      
      commit(MUTATIONS.SET_PICKED_ITEMS, pickedItems)
    },
    deleteItem({ state, commit, dispatch }, payload) {
      let items = [...state.items];
      
      const itemToDecreaseCount = items.find(item => item.id === payload.id);
      itemToDecreaseCount.count = payload.count;
      
      commit(MUTATIONS.SET_ITEMS, items);

      let pickedItems = [...state.pickedItems];
      
      if (payload.count <= 0) {
        pickedItems = state.pickedItems.filter((item) => item.id !== payload.id);
      } else {
        const alreadyExistingItem = pickedItems.find(item => item.id === payload.id);
        alreadyExistingItem.count = payload.count;
      }
      
      commit(MUTATIONS.SET_PICKED_ITEMS, pickedItems);

      if (pickedItems.length === 0) {
        dispatch('fetchList');
      }
    },
    async setCurrentPickedItems({ state, commit, dispatch }, payload) {
      commit(MUTATIONS.SET_LIST_LOADING, true)
      
      if (payload.length === 0) {
        commit(MUTATIONS.SET_PICKED_ITEMS, [])
        await dispatch('fetchList');
        commit(MUTATIONS.SET_LIST_LOADING, false)
        return;
      }
      
      const getUniqueValues = arr => {
        return Array.from(new Set(arr));
      }
      
      const uniqueNames = getUniqueValues(payload);
      
      const nameCountMap = {};
      
      for (const uniqueName of uniqueNames) {
        nameCountMap[uniqueName] = payload.filter(name => name === uniqueName).length;
      }
      
      //add dot here in order to filter other items with similar name
      const searchKey = uniqueNames.map(name => name + '.').join(',')

      const requestParams = {
        pageSize: 100,
        pageNumber: 1,
        exactMatch: true,
        searchKey
      };

      const { data } = await FileBrowser.getFileList(requestParams);
      
      let items = data.items.map((item) => {
        const fileId = getFileIdOfItem(item);
        
        return {
          ...item,
          fileId,
          count: nameCountMap[fileId] ?? 1
        }
      });
      
      items = getFilteredItems(items);

      commit(MUTATIONS.SET_PICKED_ITEMS, items);
      // commit(MUTATIONS.SET_SHOW_ONLY_SELECTED, true);
      // commit(MUTATIONS.SET_ITEMS, items);
      commit(MUTATIONS.SET_LIST_LOADING, false)
    },
    togglePickedItem({ state, commit, dispatch }, payload) {
      let pickedItems = [...state.pickedItems];
      const item = pickedItems.find(item => item.fileId === payload.fileId);
      
      if (state.onlyOneItemSelection) {
        pickedItems = [];
      }
      
      if (item) {
        pickedItems = state.pickedItems.filter((pickedItem) => pickedItem.fileId !== payload.fileId);
        payload.count = 0;
      } else {
        payload.count = 1;
        pickedItems.push(payload);
      }
      
      const newItems = state.items.map(item => {return {...item}});
      
      if (state.onlyOneItemSelection) {
        for (const newItem of newItems) {
          if (newItem.id !== payload.id) {
            newItem.count = 0;
          }
        }
      }
      
      const newItem = newItems.find(item => item.id === payload.id);
      newItem.count = payload.count;
      
      commit(MUTATIONS.SET_PICKED_ITEMS, pickedItems);
      commit(MUTATIONS.SET_ITEMS, newItems);
      
      if (state.showOnlySelected && pickedItems.length === 0) {
        dispatch('fetchList');
      }
    },
    setSelectedFileNames({ commit }, payload) {
      commit(MUTATIONS.SET_SELECTED_FILE_NAMES, payload);
    },
    syncPickedItemsWithSelectedFileNames({ state, commit }) {
      const resArray = [];
      for (const item of state.pickedItems) {
        for (let i = 0; i < item.count; i++) {
          resArray.push(item.fileId);
        }
      }

      commit(MUTATIONS.SET_SELECTED_FILE_NAMES, resArray.join(' '));
    },
    async dropProductFile({ commit, dispatch }, { id, parent_id, type }) {
      try {
        await FileBrowser.dropFileFolder({ id, parent_id, type });
        dispatch('fetchList');
      } catch (error) {
        console.log (error);
        toastr.error('Something went wrong on updating file')
      }
    },
    async enableMoveProductsMode({ commit, dispatch, state }, productName) {
      commit(MUTATIONS.SET_IS_DRAG_AND_DROP_ENABLED, true);
      commit(MUTATIONS.SET_IS_FOLDER_VIEW_ENABLED, true);
      commit(MUTATIONS.SET_GROUPED, true);
      commit(MUTATIONS.SET_SHOW_ONLY_SELECTED, true);
      commit(MUTATIONS.SET_IS_MOVE_PRODUCT_MODE_ENABLED, true);

      commit(MUTATIONS.SET_IS_TOP_BAR_SWITCHERS_ENABLED, false);
      commit(MUTATIONS.SET_IS_ITEMS_PICK_ENABLED, false);
      commit(MUTATIONS.SET_IS_ITEM_OVERLAY_ENABLED, false);

      commit(MUTATIONS.SET_SEARCH_QUERY, productName + '.');
      dispatch('fetchData');
    },
    disableMoveProductsMode({ commit }) {
      commit(MUTATIONS.SET_IS_DRAG_AND_DROP_ENABLED, false);
      commit(MUTATIONS.SET_IS_MOVE_PRODUCT_MODE_ENABLED, false);

      commit(MUTATIONS.SET_IS_TOP_BAR_SWITCHERS_ENABLED, true);
      commit(MUTATIONS.SET_IS_ITEMS_PICK_ENABLED, true);
      commit(MUTATIONS.SET_IS_ITEM_OVERLAY_ENABLED, true);
    },
    setCurrentCanvasElements({commit}, elements) {
      commit(MUTATIONS.SET_CURRENT_CANVAS_ELEMENTS, elements);
    },
    setSelectedCanvasElement({commit}, elements) {
      commit(MUTATIONS.SET_SELECTED_CANVAS_ELEMENT, elements);
    },
    setGrouped({commit}, payload) {
      commit(MUTATIONS.SET_GROUPED, payload);
    },
    setMode({commit}, payload) {
      commit(MUTATIONS.SET_MODE, payload);
    },
    async initView({state, commit, dispatch, rootGetters}, payload) {
      commit(MUTATIONS.SET_MODE, MODES.VIEW);
      commit(MUTATIONS.CLEAR_SELECTION);

      let {currentProductImages, selectedObjects} = payload;

      const selectedInstance = rootGetters['layout/selectedInstance'];

      let files = await MediaAPI.getBannerView({
        file_ids: currentProductImages.map(image => image.fileName).join(' '),
        show_warning: true,
        layout_id: selectedInstance.id
      });

      files = files?.data?.files ?? [];

      currentProductImages = currentProductImages.map(productImage => {
        const file = files.find(file => file.name.includes(productImage.fileName));
        let thumbnail = file.thumbnail ?? file.path ?? null;
        
        if (thumbnail) {
          thumbnail = '/share?file=' + thumbnail;
          // thumbnail = 'https://testing.rapidads.io' + thumbnail; //for testing on local
        }
        
        return {
          id: productImage.id,
          element_id: productImage.element_id,
          fieldId: productImage.fieldId,
          fileName: file.name ?? '',
          name: file.name ?? '',
          thumbnail_path: thumbnail ?? productImage._element?.currentSrc ?? '/images/image.svg',
          full_url: `/share?file=${file?.path}`,
        }
      });

      let selectedProductImage = null;

      if (selectedObjects.length === 1) {
        selectedProductImage = currentProductImages.find(productImage => productImage.id === selectedObjects[0].id);
      }

      commit(MUTATIONS.SET_CURRENT_FOLDER_ID, null);
      commit(MUTATIONS.SET_CURRENT_FOLDER_NAME, '');
      commit(MUTATIONS.SET_GROUPED, false);
      commit(MUTATIONS.SET_SHOW_ONLY_SELECTED, true);
      commit(MUTATIONS.SET_IS_FOLDER_VIEW_ENABLED, false);
      commit(MUTATIONS.SET_ONLY_ONE_ITEM_SELECTION, false);
      commit(MUTATIONS.SET_IS_ITEMS_PICK_ENABLED, false);
      commit(MUTATIONS.SET_CURRENT_CANVAS_ELEMENTS, currentProductImages);
      commit(MUTATIONS.SET_SELECTED_CANVAS_ELEMENT, selectedProductImage?.id || null);
      commit(MUTATIONS.SET_ITEMS, currentProductImages);
      
      dispatch('setSelectedFileNames', currentProductImages.map(image => image.fileName.split('.')[0]).join(' '));
      await dispatch('setCurrentPickedItems', currentProductImages.map(image => image.fileName.split('.')[0]));
      
      if (selectedProductImage) {
        commit(MUTATIONS.SET_SELECTION, [selectedProductImage]);
      }
    },
    async goBackToView({rootGetters}) {
      const selectedInstance = rootGetters['layout/selectedInstance'];
      selectedInstance.canvas.fire('product-images-selector:init-view', selectedInstance.id);
    },
    async enableBrowserMode({commit, dispatch}) {
      commit(MUTATIONS.SET_MODE, MODES.BROWSER);
      commit(MUTATIONS.SET_SHOW_ONLY_SELECTED, false);
      commit(MUTATIONS.SET_IS_ITEMS_PICK_ENABLED, true);
      commit(MUTATIONS.SET_ONLY_ONE_ITEM_SELECTION, false);
      commit(MUTATIONS.SET_GROUPED, true);
      commit(MUTATIONS.CLEAR_SELECTION);
      await dispatch('fetchData');
    },
    async enableReplaceMode({commit, dispatch}) {
      commit(MUTATIONS.SET_MODE, MODES.REPLACE);
      commit(MUTATIONS.SET_SHOW_ONLY_SELECTED, false);
      commit(MUTATIONS.SET_PICKED_ITEMS, []);
      commit(MUTATIONS.SET_IS_ITEMS_PICK_ENABLED, false);
      commit(MUTATIONS.SET_ONLY_ONE_ITEM_SELECTION, true);
      commit(MUTATIONS.SET_GROUPED, false);
      commit(MUTATIONS.CLEAR_SELECTION);
      await dispatch('fetchData');
    },
    toggleFolderView({commit, dispatch, state, rootGetters}) {
      const newValue = !state.isFolderViewEnabled;
      
      commit(MUTATIONS.SET_IS_FOLDER_VIEW_ENABLED, newValue);
      
      if (newValue) {
        commit(MUTATIONS.SET_GROUPED, newValue);
      }
      
      if (newValue === false && state.mode === MODES.VIEW) {
        const selectedInstance = rootGetters['layout/selectedInstance'];
        selectedInstance.canvas.fire('product-images-selector:init-view', selectedInstance.id);
        return;
      }
      
      dispatch('fetchData');
    },
    toggleGrouped({state, commit, dispatch, rootGetters}) {
      const newValue = !state.grouped;
      commit(MUTATIONS.SET_GROUPED, newValue);
      if (newValue) {
        dispatch('fetchData');
      } else {
        const selectedInstance = rootGetters['layout/selectedInstance'];
        selectedInstance.canvas.fire('product-images-selector:init-view', selectedInstance.id);
      }
    },
    setLoading({commit}, payload) {
      commit(MUTATIONS.SET_LIST_LOADING, payload);
    },
    async navigateToUploadedFile({ state, dispatch, commit }) {
      const currentPage = Number(router.currentRoute.query.page);

      if (state.pagination.totalCount % state.pagination.pageSize === 0 && state.pagination.lastPage !== 0) {
        commit(MUTATIONS.SET_CURRENT_PAGE, state.pagination.lastPage + 1);
      } else if (currentPage !== state.pagination.lastPage && state.pagination.lastPage !== 0) {
        commit(MUTATIONS.SET_CURRENT_PAGE, state.pagination.lastPage);
      }

      await dispatch('fetchList');
    },
    async uploadProductImages({commit, dispatch}, payload) {
      try {
        commit(MUTATIONS.SET_LIST_LOADING, true);

        const formData = prepareProductFileForm(payload);

        const { data } = await FileBrowser.storeProductFiles(formData);

        await dispatch('navigateToUploadedFile');

        toastr.success(`${data.filenames.length > 1 ? 'Files were' : 'File was'} uploaded successfully!`);
      } catch (error) {
        commit(MUTATIONS.SET_LIST_LOADING, false);
        toastr.error('Something went wrong while uploading files.');
      }
    }
  }
}
