<template>
  <div class="svg-section">
    <SvgFileInput
      ref="svgFileInput"
      accept="image/*,application/pdf"
      placeholder="Select PDF or SVG file…"
      suffix="svg-section"
      @on-file-selected="onFileSelected"
      @clear-canvas="clearCanvas"
    />

    <div class="d-flex flex-wrap mb-3 mt-2">
      <div class="optimize-wrapper d-flex align-items-center">
        <b-form-checkbox
          id="optimizeSvg"
          v-model="isOptimizeSvgChecked"
          :value="true"
          :unchecked-value="false"
          switch
          size="lg"
          @change="setOptimizeSvg"
        />
        <label
          class="align-self-center optimize-label"
          for="optimizeSvg"
        >
          Optimize SVG
        </label>
      </div>
    </div>

    <div class="d-flex justify-content-between flex-wrap mt-1 mb-1">
      <div>
        <b-button
          variant="outline-primary"
          size="sm"
          @click="resetCanvasToInitialState"
        >
          Reset
        </b-button>

        <b-button
          :disabled="!isHasWorkingArea"
          variant="outline-primary"
          size="sm"
          @click="resetCanvasWorkingArea"
        >
          Reset working area
        </b-button>

        <b-button
          :disabled="!isHasSvgXml"
          variant="outline-primary"
          size="sm"
          :class="{ 'active': isInteractiveMode }"
          @click="loadSvgFromString"
        >
          Interactive mode
        </b-button>

        <b-button
          :disabled="!isSvgInstanceHasSelectedObjects"
          variant="outline-primary"
          size="sm"
          @click="deselectAll"
        >
          Clear selections
        </b-button>
      </div>
      <div>
        <b-button
          variant="outline-primary"
          size="sm"
          @click="undo"
        >
          Undo
        </b-button>
        <b-button
          variant="outline-primary"
          size="sm"
          @click="redo"
        >
          Redo
        </b-button>
      </div>
    </div>

    <div class="control-buttons mt-2 mb-2">
      <b-button
        :disabled="!isSvgInstanceHasObjects"
        variant="outline-primary"
        size="sm"
        @click="createCanvasFromSelected"
      >
        Select working area
      </b-button>

      <b-button
        :disabled="!isSelectedImageObject"
        variant="outline-primary"
        size="sm"
        @click="removeBG"
      >
        Remove BG
      </b-button>

      <b-button
        variant="outline-primary"
        size="sm"
        :class="{ 'active': isRecMaskMode }"
        @click="rectangleMaskMode"
      >
        Mask
      </b-button>

      <b-button
        variant="outline-primary"
        size="sm"
        @click="selectAll"
      >
        Select all
      </b-button>

      <b-button
        variant="outline-primary"
        size="sm"
        @click="centerSelected"
      >
        Center
      </b-button>

      <b-button
        variant="outline-primary"
        size="sm"
        @click="enlargeSelected"
      >
        Enlarge
      </b-button>

      <b-button
        :disabled="!(isHasBgImageInstance)"
        variant="outline-primary"
        size="sm"
        :class="{ 'active': isBgPreviewOn }"
        @click="setBgImagePreview"
      >
        <b-icon icon="eye" />
      </b-button>

      <b-button
        variant="outline-primary"
        size="sm"
        @click="zoomIn"
      >
        <b-icon icon="plus-lg" />
      </b-button>

      <b-button
        variant="outline-primary"
        size="sm"
        @click="zoomOut"
      >
        <b-icon icon="dash-lg" />
      </b-button>
      
      <div class="mt-2">
        <b-button
          :disabled="!isHasSvgXml"
          variant="outline-primary"
          size="sm"
          :class="{ 'active': isAltSelectionMode }"
          @click="altSelectionMode"
        >
          <b-icon icon="plus-square-dotted" />
        </b-button>

        <b-button
          variant="outline-primary"
          size="sm"
          :class="{ 'active': isSelectionBoxMode, }"
          @click="selectionBoxMode"
        >
          <b-icon icon="camera" />
        </b-button>

        <b-button
          variant="outline-primary"
          size="sm"
          :class="{ 'active': isSvgLassoMode }"
          @click="initLasso"
        >
          <b-icon icon="vector-pen" />
        </b-button>

        <b-button
          :disabled="!isHasSvgXml"
          variant="outline-primary"
          size="sm"
          @click="showLayers"
        >
          <b-icon icon="layers" />
        </b-button>

        <b-button
          :disabled="!isHasSvgXml"
          variant="outline-primary"
          size="sm"
          @click="showPreview"
        >
          <b-icon icon="window-dock" />
        </b-button>

        <b-button
          :disabled="!isSvgInstanceHasSelectedObjects"
          variant="outline-primary"
          size="sm"
          @click="ocr"
        >
          OCR
        </b-button>

        <b-form-input
          v-model="textEditbox"
          class="c-svg-section__text-editbox"
          size="sm"
        />

        <b-button
          variant="outline-primary"
          size="sm"
          @click="copyHandler"
        >
          <svg
            class="b-icon bi"
            width="1em"
            height="1em"
            fill="currentColor"
          >
            <use href="/img/icons/sprite.svg#copy" />
          </svg>
        </b-button>

        <b-button
          variant="outline-primary"
          size="sm"
          @click="collisionHandler"
        >
          <svg
            class="b-icon bi"
            width="1em"
            height="1em"
            fill="currentColor"
          >
            <use href="/img/icons/sprite.svg#collision" />
          </svg>
        </b-button>

        <b-button
          :disabled="!isSvgInstanceHasObjects"
          variant="outline-primary"
          size="sm"
          @click="getIngredients"
        >
          Ingredients
        </b-button>

        <b-button
          :disabled="!isSvgInstanceHasObjects"
          variant="outline-primary"
          size="sm"
          @click="getNutritionFacts"
        >
          Nutrition Facts
        </b-button>
      </div>
    </div>
    
    <SvgLayersModal
      ref="svgLayersModal"
    />
    
    <SvgExtractModal
      ref="svgExtractModal"
      @on-file-loaded="onFileLoaded"
    />
    
    <SvgPreviewEditor
      ref="svgPreviewEditor"
      :svg-text="svgContent"
    />
    
    <SvgPdfApi
      ref="svgPdfApi"
      @cloud-convert="cloudConvertAPI"
      @convert-api="convertPdfAPI"
    />

    <div
      tabindex="1000"
      class="cancel-outline"
      @keyup="onKeyUp"
    >
      <canvas
        ref="svgCanvas"
        class="canvas-section cancel-outline"
      />
    </div>
  </div>
</template>

<script>
import SVGCanvas from '../../../services/SVGCanvas/SVGCanvas';
import { mapActions, mapGetters } from 'vuex';
import {SVGEditorContext} from "../../../contexts/svg-editor-context";
import {SVG_EDITOR_EVENTS} from "../../../services/SVGCanvas/events";
import SvgFileInput from './SvgFileInput.vue';
import SvgPreviewEditor from './SvgPreviewEditor.vue';
import mediaAPI from '../../../services/api/media';
import SvgLayersModal from './SvgLayersModal.vue';
import SvgExtractModal from './SvgExtractModal.vue';
import OCRService from '../../../services/api/ocr'
import { checkInstanceBlob, copyToClipboard, getDataURLFromBlob } from '../../../services/helpers';
import {collisionEditBox} from "./helpers";
import { FILE_TYPE, MIME_TYPE } from '../../../constants/file-image-types';
import SvgPdfApi from './SvgPdfApiModal.vue';
import SvgImageEditor from '../../../services/api/svg-image-editor';
import { IMPORT_TARGET } from './constants';

export default {
  name: 'SVGSection',
  components: { SvgExtractModal, SvgPdfApi, SvgLayersModal, SvgFileInput, SvgPreviewEditor },

  data: () => ({
    SVGCanvas: null,
    svgContent: '',
    fileDataUrl: '',
    textEditbox: '',
  }),

  computed: {
    ...mapGetters('svgEditor', [
      'isSvgInstanceHasSelectedObjects',
      'isProductInstanceHasObjects',
      'isProductInstanceHasActiveObjects',
      'isHasBgImageInstance',
      'isHasWorkingArea',
      'isRecMaskMode',
      'isBgPreviewOn',
      'isAltSelectionMode',
      'isSelectionBoxMode',
      'isSvgInstanceHasObjects',
      'isInteractiveMode',
      'isSvgLassoMode',
      'isSelectedImageObject',
      'isHasSvgXml',
      'isOptimizeSvgOn',
    ]),

    isOptimizeSvgChecked: {
      get(){
        return this.isOptimizeSvgOn
      },
      set(newName){
        return newName
      }
    }
  },

  mounted() {
    this.SVGCanvas = window.svg = new SVGCanvas(
      this.$refs.svgCanvas,
      this.$store,
      window.$context.get(SVGEditorContext)?.height
    );

    this.SVGCanvas.on(SVG_EDITOR_EVENTS.FILE_LOADED, this.onFileLoaded.bind(this))
    this.SVGCanvas.extractModalRef = this.$refs.svgExtractModal;
    window.addEventListener('keydown', this.handleEnterPress);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleEnterPress);
  },

  methods: {
    ...mapActions('svgEditor', [
      'setInteractiveMode',
      'setAltSelectionMode',
      'convertTiffToPng',
      'setIsOptimizeSvgOn',
    ]),

    async onFileSelected(file, type, props = null) {
      this.setAltSelectionMode(false);

      if (!file) {
        return
      }

      this.$emit('load');

      if (props) {
        return this.loadFromFileBrowser(props, file);
      }

      if (file.type.includes(MIME_TYPE.PDF)) {
        return this.$refs.svgPdfApi.show(file);
      }

      if (!file.type.includes(FILE_TYPE.SVG)) {
        return await this.loadImageFile(file);
      }

      await this.readFile(file);
    },

    async loadImageFile(file) {
      const path = await this.getURLFromBlob(file);
      this.SVGCanvas.pasteImageFromUrl(path);
    },

    async getURLFromBlob(file) {
      checkInstanceBlob(file, 'file');

      if (file.type.includes(MIME_TYPE.TIFF)) {
        toastr.success('Converting TIFF to PNG');

        try {
          const data = await this.convertTiffToPng({ file, format: FILE_TYPE.PNG });

          return data.path;
        } catch(error) {
          console.log(error);
          toastr.error('Converting fail');
        }
      }

      return await getDataURLFromBlob(file)
    },

    loadFromFileBrowser(props, file) {
      const fileName = `${props.fileName}.${props.fileExt}`;
      this.$refs.svgFileInput.setFileName({ name: fileName });

      if (props.fileType.includes(FILE_TYPE.SVG)) {
        file = new File([file], fileName, { type: MIME_TYPE.SVG });

        return this.readFile(file);
      }

      if (props.fileType.includes(FILE_TYPE.PDF)) {
        file = new File([file], fileName, { type: MIME_TYPE.PDF });

        return this.$refs.svgPdfApi.show(file);
      }
    },

    async cloudConvertAPI(file) {
      try {
        this.$emit('load');
        const formData = new FormData();
        formData.append('image', file)
        formData.append('format', FILE_TYPE.SVG)

        const { data: convertFile } = await mediaAPI.convertImage(formData);
        const fileBlob = new Blob([convertFile]);
        file = await this.getSvgFileFromBlob(file, fileBlob);
        return this.readFile(file);
      } catch (error) {
        toastr.error('Converting error, try another type of file.')
        return this.$emit('loaded');
      }
    },

    async convertPdfAPI(file) {
      try {
        this.$emit('load');
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await SvgImageEditor.convertPdfToSvg(formData);

        if (data.url) {
          const fileBlob = await axios.get(data.url, { responseType: 'blob' });
          file = await this.getSvgFileFromBlob(file, fileBlob.data);
          return this.readFile(file);
        }

        toastr.error('Converting error, try another type of file.')
        return this.$emit('loaded');
      } catch (error) {
        toastr.error('Converting error, try another type of file.')
        return this.$emit('loaded');
      }
    },

    cleanUpSvgFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function () {
          const cleanedContent = reader.result.replace(/&(?!amp;|lt;|gt;|quot;|apos;)/g, '&amp;');
          resolve(cleanedContent);
        };

        reader.onerror = error => reject(error);
        reader.readAsText(file);
      });
    },

    customOptimizer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function () {
          let cleanedContent = reader.result
            .replace(/&(?!amp;|lt;|gt;|quot;|apos;)/g, '&amp;')
            .replace(/<!--[\s\S]*?-->/g, '')
            .replace(/<metadata[^>]*>[\s\S]*?<\/metadata>/gi, '')
            .replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '')
            .replace(/style="[^"]*"/gi, '')
            .replace(/<(title|desc|metadata)[^>]*>[\s\S]*?<\/\1>/gi, '')
            // for better optimization but less view quality
            // .replace(/<g[^>]*>\s*<\/g>/gi, '')
            // .replace(/<defs[^>]*>[\s\S]*?<\/defs>/gi, '')
            // .replace(/<clipPath[^>]*>[\s\S]*?<\/clipPath>/gi, '')
            // .replace(/opacity="[^"]*"/gi, '')
            // .replace(/fill-opacity="[^"]*"/gi, '')
            // .replace(/stroke-opacity="[^"]*"/gi, '')
            // .replace(/\s(?:inkscape:[^"']+)=["'][^"']*["']/gi, '')
          
          resolve(cleanedContent);
        };

        reader.onerror = error => reject(error);
        reader.readAsText(file);
      });
    },

    async getSvgFileFromBlob(file, fileBlob) {
      const reader = new FileReader();
      const that = this;

      return new Promise((resolve, reject) => {
        reader.onloadend = async function() {
          let svgContent = await that.cleanUpSvgFile(fileBlob);

          const newBlob = new Blob([svgContent], { type: `${MIME_TYPE.SVG}` });
          const fileName = file.name.substring(0, file.name.lastIndexOf('.'));
          const newFile = new File(
            [newBlob],
            `${fileName}.${FILE_TYPE.SVG}`,
            { type: `${MIME_TYPE.SVG}` }
          );

          resolve(newFile);
        };

        reader.onerror = error => reject(error);
        reader.readAsText(fileBlob);
      });
    },

    async readFile(file) {
      file = await this.getOptimizedSvg(file);
      this.setInteractiveMode(false);
      this.SVGCanvas.resetGLayers();
      const textReader = new FileReader();
      const urlReader = new FileReader();
      textReader.readAsText(file);
      urlReader.readAsDataURL(file);

      textReader.onload = (e) => {
        this.svgContent = e.target.result;
        this.SVGCanvas.initialState = this.svgContent;
        this.SVGCanvas.modifiedSvgXml = this.svgContent;
      };

      urlReader.onload = (e) => {
        this.fileDataUrl = e.target.result;
        this.SVGCanvas.modifiedSvgUrl = this.fileDataUrl;
        this.SVGCanvas.initialSvgUrl = this.fileDataUrl;
        this.SVGCanvas.pasteImageFromUrl(this.fileDataUrl);
      };
    },

    async getOptimizedSvg(file) {
      if (this.isOptimizeSvgOn) {
        try {
          const cleanedContent = await this.cleanUpSvgFile(file);
          const cleanedFile = new File([cleanedContent], file.name, { type: file.type });
          const { data } = await SvgImageEditor.optimizeSVGO(cleanedFile);
          toastr.success('SVGO optimization was successful');

          return this.getSvgFileFromBlob(file, data);
        } catch (error) {
          console.log(error);

          const cleanedContent = await this.customOptimizer(file);

          toastr.success('SVG custom optimization was successful');
          
          return new File([cleanedContent], file.name, {type: file.type});
        }
      }

      return file;
    },

    onFileLoaded() {
      this.$emit('loaded');
    },

    onKeyUp(e) {
      this.SVGCanvas.onKeyUp(e)
    },

    resetCanvasToInitialState() {
      this.SVGCanvas.resetToInitialState();
    },

    resetCanvasWorkingArea() {
      this.SVGCanvas.resetWorkingArea();
    },

    loadSvgFromString() {
      this.$emit('load');
      this.setInteractiveMode(!this.isInteractiveMode);

      if (this.isInteractiveMode) {
        return this.SVGCanvas.interactiveMode();
      }

      return this.SVGCanvas.pasteImageFromUrl(this.SVGCanvas.modifiedSvgUrl);
    },

    clearCanvas() {
      this.SVGCanvas.clearCanvas();
    },

    createCanvasFromSelected() {
      this.SVGCanvas.drawFromSelectedObjects();
    },

    removeBG() {
      this.$emit('load');
      this.SVGCanvas.removeBG();
    },

    saveAsSVG() {
      this.SVGCanvas.saveAsSVG();
    },

    zoomIn() {
      this.SVGCanvas.zoomIn();
    },

    zoomOut() {
      this.SVGCanvas.zoomOut();
    },

    centerSelected() {
      this.selectAll();
      this.$nextTick(() => this.SVGCanvas.centerSelected());
    },

    enlargeSelected() {
      this.selectAll();
      this.$nextTick(() => this.SVGCanvas.enlargeSelected());
    },

    selectAll() {
      this.SVGCanvas.selectAllObjects();
    },

    rectangleMaskMode() {
      this.SVGCanvas.rectangleMaskMode();
    },

    setBgImagePreview() {
      this.SVGCanvas.drawBgImagePreview();
    },

    altSelectionMode() {
      this.SVGCanvas.beforeAltSelectionMode();
    },

    selectionBoxMode() {
      this.SVGCanvas.switchSelectionBoxMode();
    },

    showPreview() {
      this.$refs.svgPreviewEditor.show();
    },

    showLayers() {
      this.$refs.svgLayersModal.show();
    },

    async ocr() {
      this.textEditbox = await this.SVGCanvas.convertSelectedToText()
    },

    copyHandler() {
      copyToClipboard(this.textEditbox)
      toastr.success('Copied')
    },

    collisionHandler() {
      this.textEditbox = collisionEditBox(this.textEditbox)
    },

    getIngredients() {
      this.$emit('load');
      this.SVGCanvas.extractInfo('ingredients');
    },

    getNutritionFacts() {
      this.$emit('load');
      this.SVGCanvas.extractInfo('nutrition');
    },

    deselectAll() {
      this.SVGCanvas.deselectAll();
    },

    initLasso() {
      this.SVGCanvas.initLasso();
    },

    undo() {
      this.SVGCanvas.undoCanvasState();
    },

    redo() {
      this.SVGCanvas.redoCanvasState();
    },

    setOptimizeSvg(isOptimizeSvgChecked) {
      this.setIsOptimizeSvgOn(isOptimizeSvgChecked);

      if (this.SVGCanvas.initialState && this.isOptimizeSvgOn) {
        const fileBlob = new Blob([this.SVGCanvas.initialState], { type: `${MIME_TYPE.SVG}` });
        const file = this.getSvgFileFromBlob(this.$refs.svgFileInput.selectedFile, fileBlob);
        this.onFileSelected(file);
      }
    },

    handleEnterPress(event) {
      switch (event.keyCode) {
        // Esc key
        case 27:
        case 53:
          this.SVGCanvas.closeAltSelectionMode();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-section {
  margin-right: 1px;
}

.canvas-section {
  border: 1px solid #e1e1e1;
}

::v-deep .cancel-outline:focus-visible {
  outline: unset;
}

.control-buttons {
  max-width: 750px;
}

.c-svg-section__text-editbox {
  width: 130px;
  display: inline-block;
  vertical-align: middle;
}

.optimize-wrapper {
  margin: -5px 5px 0 5px;
  
  .optimize-label {
    margin: 8px 0 0 5px;
  }
}
</style>
