var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "bynder-metadata-modal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "bynderMetadataModalLabel",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg", attrs: { role: "document" } },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h5",
                {
                  staticClass: "modal-title",
                  attrs: { id: "bynderMetadataModalLabel" },
                },
                [_vm._v("\n          Bynder Metadata\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: { click: _vm.closeHandler },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _vm.isLoading
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("p", [_vm._v("Loading metaproperties...")]),
                      _vm._v(" "),
                      _c("b-spinner", { attrs: { variant: "primary" } }),
                    ],
                    1
                  )
                : _vm.error
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v("\n          " + _vm._s(_vm.error) + "\n        "),
                  ])
                : _c("div", [
                    _c(
                      "div",
                      { staticClass: "border rounded row p-2 m-2" },
                      _vm._l(_vm.metaproperties.longtext, function (prop) {
                        return _c(
                          "div",
                          {
                            key: prop.id,
                            staticClass: "col-md-6 mt-2 mb-2",
                            class: { "d-none": prop.hidden },
                          },
                          [
                            _c(
                              "label",
                              { attrs: { for: `longtext-${prop.id}` } },
                              [_vm._v(_vm._s(prop.label))]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form[prop.id].value,
                                  expression: "form[prop.id].value",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: `longtext-${prop.id}`,
                                type: "text",
                                placeholder: `Enter ${prop.label}`,
                                disabled: prop.disabled,
                              },
                              domProps: { value: _vm.form[prop.id].value },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form[prop.id],
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "border rounded row p-2 m-2" },
                      _vm._l(_vm.metaproperties.date, function (prop) {
                        return _c(
                          "div",
                          { key: prop.id, staticClass: "col-md-6 mt-2 mb-2" },
                          [
                            _c("label", { attrs: { for: `date-${prop.id}` } }, [
                              _vm._v(_vm._s(prop.label)),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form[prop.id].value,
                                  expression: "form[prop.id].value",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { id: `date-${prop.id}`, type: "date" },
                              domProps: { value: _vm.form[prop.id].value },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form[prop.id],
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "border rounded row p-2 m-2" },
                      _vm._l(_vm.metaproperties.select, function (prop) {
                        return _c(
                          "div",
                          { key: prop.id, staticClass: "col-md-6 mt-2 mb-2" },
                          [
                            _c(
                              "label",
                              { attrs: { for: `select-${prop.id}` } },
                              [_vm._v(_vm._s(prop.label))]
                            ),
                            _vm._v(" "),
                            _c("Multiselect", {
                              attrs: {
                                id: `multiselect-${prop.id}`,
                                value: _vm.findOptionById(prop.id),
                                options: prop.options,
                                multiple: false,
                                "track-by": "id",
                                label: "label",
                                placeholder: "Select options",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.onSelect($event, prop.id)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "border rounded row p-2 m-2" },
                      _vm._l(_vm.metaproperties.multiselect, function (prop) {
                        return _c(
                          "div",
                          { key: prop.id, staticClass: "col-md-6 mt-2 mb-2" },
                          [
                            _c(
                              "label",
                              { attrs: { for: `multiselect-${prop.id}` } },
                              [_vm._v(_vm._s(prop.label))]
                            ),
                            _vm._v(" "),
                            _c("Multiselect", {
                              attrs: {
                                id: `multiselect-${prop.id}`,
                                value: _vm.parseSelectedIds(prop.id),
                                options: prop.options,
                                multiple: true,
                                "track-by": "id",
                                label: "label",
                                placeholder: "Select options",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.onMultiSelect($event, prop.id)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.metaproperties.failed.length
                      ? _c("div", { staticClass: "alert alert-info mt-3" }, [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "list-unstyled" },
                            _vm._l(
                              _vm.metaproperties.failed,
                              function (failed) {
                                return _c(
                                  "li",
                                  { key: failed.id, staticClass: "mb-2" },
                                  [
                                    _c("strong", [_vm._v(_vm._s(failed.name))]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("small", { staticClass: "text-muted" }, [
                                      _vm._v(
                                        "\n                  ID: " +
                                          _vm._s(failed.id) +
                                          "\n                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  Error:\n                  " +
                                          _vm._s(
                                            failed.message.message ||
                                              "Unable to retrieve property"
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer justify-content-between" }, [
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-sm",
                    attrs: { type: "button", disabled: _vm.isLoading },
                    on: {
                      click: function ($event) {
                        return _vm.saveMetaValues("clear")
                      },
                    },
                  },
                  [_vm._v("\n            Clear Selection\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm",
                    attrs: { type: "button", disabled: _vm.isLoading },
                    on: {
                      click: function ($event) {
                        return _vm.saveMetaValues("save")
                      },
                    },
                  },
                  [_vm._v("\n            Save Selection\n          ")]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-sm",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.closeHandler },
                  },
                  [_vm._v("\n            Cancel\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm",
                    attrs: { type: "button", disabled: _vm.isLoading },
                    on: { click: _vm.handleMetaproperties },
                  },
                  [_vm._v("\n            Confirm\n          ")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("h4", { staticClass: "alert-heading" }, [
        _vm._v(
          "\n                Unable to Load Some Properties\n              "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("We encountered issues retrieving the following properties:"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }