export const V_ALIGN = {
    TOP: 'Top',
    CENTER: 'Center',
    BOTTOM: 'Bottom'
}

export const H_ALIGN = {
    LEFT_FF: 'Left-FF',
    LEFT_LH: 'Left-LH',
    LEFT_RH: 'Left-RH',
    RIGHT_FF: 'Right-FF',
    RIGHT_LH: 'Right-LH',
    RIGHT_RH: 'Right-RH',
    CENTER_FF: 'Center-FF',
    CENTER_LH: 'Center-LH',
    CENTER_RH: 'Center-RH',
    FF: 'FF',
    LH: 'LH',
    RH: 'RH',
    CENTER: 'Center',
    LEFT: 'Left',
    RIGHT: 'Right',
    JUSTIFY: 'Justify',
}

export const TEXT_ALIGNMENTS = [
    { text: 'left', value: 'left' },
    { text: 'center', value: 'center' },
    { text: 'right', value: 'right' },
    { text: 'justify-inter-word', value: 'justify' },
    { text: 'justify inter character', value: 'justify-inter-char' },
    { text: 'justify inter character (overflow special chars)', value: 'justify-inter-char-overflow' },
]
