var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "With underlying shape" } },
                [
                  _c("b-form-select", {
                    staticClass: "form-control",
                    attrs: { options: _vm.isEnabledOptions },
                    model: {
                      value: _vm.isEnabled,
                      callback: function ($$v) {
                        _vm.isEnabled = $$v
                      },
                      expression: "isEnabled",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isEnabled
        ? _c(
            "div",
            [
              _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Shape type" } },
                        [
                          _c("b-form-select", {
                            staticClass: "form-control",
                            attrs: { options: _vm.shapeTypeOptions },
                            model: {
                              value: _vm.shapeType,
                              callback: function ($$v) {
                                _vm.shapeType = $$v
                              },
                              expression: "shapeType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.shapeType === "Image"
                ? _c(
                    "div",
                    [
                      _c(
                        "b-card",
                        { attrs: { "bg-variant": "light" } },
                        [
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "Image URL" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          placeholder:
                                            "Absolute URL to the image goes here...",
                                          autocomplete: "off",
                                        },
                                        on: { change: _vm.updateResultData },
                                        model: {
                                          value: _vm.image.url,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.image, "url", $$v)
                                          },
                                          expression: "image.url",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "Image Width" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "number" },
                                        on: { change: _vm.updateResultData },
                                        model: {
                                          value: _vm.image.width,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.image, "width", $$v)
                                          },
                                          expression: "image.width",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "Image Height" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "number" },
                                        on: { change: _vm.updateResultData },
                                        model: {
                                          value: _vm.image.height,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.image, "height", $$v)
                                          },
                                          expression: "image.height",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Image Horizontal position",
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        staticClass: "form-control",
                                        attrs: {
                                          options:
                                            _vm.horizontalPositionOptions,
                                        },
                                        on: { change: _vm.updateResultData },
                                        model: {
                                          value: _vm.image.horizontalPosition,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.image,
                                              "horizontalPosition",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "image.horizontalPosition",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Image Vertical position",
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        staticClass: "form-control",
                                        attrs: {
                                          options: _vm.verticalPositionOptions,
                                        },
                                        on: { change: _vm.updateResultData },
                                        model: {
                                          value: _vm.image.verticalPosition,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.image,
                                              "verticalPosition",
                                              $$v
                                            )
                                          },
                                          expression: "image.verticalPosition",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Image Horizontal offset",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "number" },
                                        on: { change: _vm.updateResultData },
                                        model: {
                                          value: _vm.image.horizontalOffset,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.image,
                                              "horizontalOffset",
                                              $$v
                                            )
                                          },
                                          expression: "image.horizontalOffset",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: { label: "Image Vertical offset" },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "number" },
                                        on: { change: _vm.updateResultData },
                                        model: {
                                          value: _vm.image.verticalOffset,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.image,
                                              "verticalOffset",
                                              $$v
                                            )
                                          },
                                          expression: "image.verticalOffset",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }