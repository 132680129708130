import { fabric } from "fabric";
import Observer from "../../frontend/services/helpers/Observer";
import { parseJsonData } from "../../frontend/services/helpers";
import { parseStyleToText } from "./parse-style-to-text";

const clipboard = window.navigator.clipboard;
const observer = new Observer();
let scope = {};

export async function copy(canvas) {
    if (!(canvas instanceof fabric.Canvas)) return;

    const activeObject = canvas.getActiveObject();

    if (!activeObject || !activeObject?.hasControls) return;

    let objectData = activeObject.toObject();

    objectData = {
        ...objectData,
        name: activeObject?.templateField?.name || activeObject?.name || null,
        fieldType: activeObject?.templateField?.type || activeObject?.fieldType || null,
        fontColor: activeObject?.fontColor || '#000000',
        fontSize: (activeObject?.fontSize * activeObject?.scaleX) || 100,
        fileName: activeObject?.fileName || null,
        id: activeObject.id,
        order: activeObject.order,
        prevInstanceId: canvas.instance_id,
    }

    if (activeObject.type === 'textbox') {
        objectData.text = parseStyleToText(activeObject?.textLines, activeObject?.styles, true);
    }

    if (navigator.clipboard && navigator.clipboard.writeText) {
        await clipboard.writeText(JSON.stringify(objectData));
    } else{
        window.prompt("Copy to clipboard: Ctrl+C, Enter", activeObject.type === 'textbox' ? objectData : JSON.stringify(objectData));
    }
}

async function paste(nativeEvent) {
    const files = nativeEvent.clipboardData.files;
    
    let data = null;
    if (navigator.clipboard && navigator.clipboard.writeText) {
        data = await navigator.clipboard.readText();
    } else{
        data = nativeEvent.clipboardData.getData("text/plain");
    }

    const parseData = parseJsonData(data, null);

    const fileDataUrls = await Promise.all(Array.from(files).map(file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }));

    let eventData = {
        text: parseData ? parseData.text : data,
        object: parseData,
        dataFromClipboard: data,
        files: files,
        dataUrls: fileDataUrls,
    };

    observer.dispatch('paste', eventData);
}


function init() {
    document.addEventListener('copy', async function (e) {
        switch (e.target?.tagName) {
            case 'INPUT':
            case 'TEXTAREA':
                return
        }
        copy(scope.canvas);
    });

    document.addEventListener('paste', async function (e) {
        paste(e)
    });
}

function setScope(data) {
    scope = { ...scope, ...data };
}

export default {
    init,
    setScope,
    on: observer.on.bind(observer)
}
