var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      staticClass: "a-selected-actions-dropdown",
      attrs: {
        variant: "outline-primary",
        text: "Actions",
        right: "",
        dropup: "",
        boundary: "window",
        "toggle-class": "a-selected-actions-dropdown__toggle",
      },
    },
    [
      _c(
        "b-dropdown-item",
        {
          attrs: { "link-class": "a-selected-actions-dropdown__item" },
          on: {
            click: function ($event) {
              return _vm.handleAction(_vm.MODALS.CROP)
            },
          },
        },
        [
          _c(
            "svg",
            { staticClass: "a-selected-actions-dropdown__item--icon" },
            [
              _c("use", {
                attrs: { href: "/img/icons/sprite.svg#file-crop-svg" },
              }),
            ]
          ),
          _vm._v("\n    Crop to image\n  "),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: { "link-class": "a-selected-actions-dropdown__item" },
          on: {
            click: function ($event) {
              return _vm.handleAction(_vm.MODALS.REMOVE_BACKGROUND)
            },
          },
        },
        [
          _c("i", {
            staticClass:
              "fas fa-images a-selected-actions-dropdown__item--icon",
          }),
          _vm._v("\n    Remove Background\n  "),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }