import { importHyperlinksEventBroker, EVENTS } from "./event-broker";
import { addAddField } from "@frontend/group/modules/additional-fields";
import { canvasEditModeControl } from "@frontend/group/modules/canvas-edit-mode-control";

export const MAPPER_TYPES = {
  DEFAULT: 'default',
};

function openImportHyperlinksModal() {
  importHyperlinksEventBroker.fire(EVENTS.INIT);
}

export function mapHyperlinks(content, mapperType) {
  let hyperlinks = [];

  switch (mapperType) {
    case MAPPER_TYPES.DEFAULT:
      hyperlinks = defaultMapper(content);
      break;
    default:
      throw new Error(`Mapper type ${mapperType} not found`);
  }

  return hyperlinks;
}

function defaultMapper(content) {
  return content.map((item) => {
    return {
      top: item.coords.y,
      left: item.coords.x,
      width: item.coords.width,
      height: item.coords.height,
      url: item.url,
    };
  });
}

async function handleHyperlinksImport(event) {
    const { hyperlinks, instanceIds } = event;

    const promises = [];
    canvasEditModeControl.setKeepSelected(false);

    const previewsToUpdate = instanceIds && instanceIds.length > 0
      ? previews.filter((preview) => instanceIds.includes(preview.id))
      : previews;

    previewsToUpdate.forEach((preview) => {
      const hyperlinksToAdd = hyperlinks;

      hyperlinksToAdd.forEach((hyperlink) => {
        promises.push(
          addAddField('Text', preview.canvas.instance_id, {
            text: ' ',
            _offset_x: hyperlink.left,
            _offset_y: hyperlink.top,
            _width: hyperlink.width,
            _height: hyperlink.height,
            _allow_empty_string: true,
            link: hyperlink.url,
          })
        );
      });
    });

    await Promise.all(promises);

    canvasEditModeControl.setKeepSelected(true);
}

importHyperlinksEventBroker.on(EVENTS.DRAW, handleHyperlinksImport);


$(document).ready(function () {
  $("#import-hyperlinks-dropdown-item").on("click", openImportHyperlinksModal);
});
