<template>
  <div>
    <b-sidebar
      v-model="visible"
      class="prevent-unselect"
      shadow
      z-index="1040"
      :title="selectedInstance?.template.name || 'No selected item'"
      :footer-class="['px-3 py-2', {'d-none': !target}]"
      :width="width"
      :right="right"
      @change="$emit('toggle')"
    >
      <b-tabs
        v-if="selectedInstance"
        v-model="tabIndex"
        class="no-gutters"
        nav-wrapper-class="layout-sidebar-nav-wrapper"
        content-class="px-3 py-2"
        small
        pills
        vertical
        end
        :nav-class="target ? '' : 'no-target'"
      >
        <b-tab>
          <template #title>
            <TabTitle
              title="Settings"
              icon="sliders"
            />
          </template>
          <template v-if="target">
            <b-link
              v-b-tooltip.hover.left.viewport.noninteractive="`Go to layer`"
              class="layer-name-link"
              @click="goToLayersTab"
            >
              {{ layerName }}
            </b-link>
            <TextEditorControl
              v-if="typeEditorControl === 'TEXT'"
              id="text-editor-control-settings"
              :target="target"
              :options="options"
              :themes="themes"
              :field-type="fieldType"
            />
            <ShapeEditorControl
              v-else-if="typeEditorControl === 'SHAPE'"
              :target="target"
              :themes="themes"
            />
            <ImageEditorControl
              v-else-if="typeEditorControl === 'IMAGE'"
              :target="target"
              :options="options"
              :themes="themes"
              @toggle="$emit('toggle')"
            />
            <ProductDimensionEditorControl
              v-else-if="typeEditorControl === 'PRODUCT_DIMENSION'"
              :options="options"
              :target="target"
            />
            <GroupEditorControl
              v-else-if="typeEditorControl === 'GROUP'"
              :options="options"
              :target="target"
            />
            <SmartObjectEditorControl
              v-else-if="typeEditorControl === 'SMART_OBJECT'"
              :options="options"
              :preview="target.preview"
              :smart-objects-list="target.smartObjectsList"
              :target="target"
              :smart-object-id="parseInt(target?.smartObjectId)"
            />
            <b-form-group
              v-else-if="typeEditorControl === 'NEW_SMART_OBJECT'"
              label="Create a new smart object"
            >
              <b-form-input 
                v-model="newSmartObjectName" 
                placeholder="name"
              />
              <b-button
                variant="primary"
                :disabled="!newSmartObjectName"
                @click="createSmartObject"
              >
                create
              </b-button>
            </b-form-group>
          </template>
        </b-tab>
        <b-tab>
          <template #title>
            <TabTitle
              title="Properties"
              icon="gear-wide-connected"
            />
          </template>
          <PropertiesModule
            :target="target"
            :options="modules.properties"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <TabTitle
              title="Shadows"
              icon="back"
            />
          </template>
          <ShadowsModule :target="target" />
        </b-tab>
        <b-tab>
          <template #title>
            <TabTitle
              title="Animations"
              icon="collection-play"
            />
          </template>
          <AnimationsModule
            :target="target"
            :options="modules.animation"
          />
        </b-tab>
        <b-tab title-item-class="instance-related">
          <template #title>
            <TabTitle
              title="Fields"
              icon="diagram3"
            />
          </template>
          <FieldsModule />
        </b-tab>
        <b-tab title-item-class="instance-related">
          <template #title>
            <TabTitle
              title="Layers"
              icon="layers"
            />
          </template>
          <LayersModule
            ref="layersModule"
            :is-tab-active="tabIndex === 5"
          />
        </b-tab>
        <b-tab
          v-if="backgroundColorRectangle"
          title-item-class="instance-related"
        >
          <template #title>
            <TabTitle title="Background Color Editor">
              <b-iconstack class="overflow-hidden">
                <b-icon
                  stacked
                  icon="square"
                />
                <b-icon
                  stacked
                  scale="1.5"
                  rotate="-45"
                  icon="justify"
                />
              </b-iconstack>
            </TabTitle>
          </template>
          <BackgroundColorEditor 
            :height="backgroundColorRectangle.height"
            :target="backgroundColorRectangle"
            :width="backgroundColorRectangle.width"
          />
        </b-tab>
        <b-tab title-item-class="instance-related">
          <template #title>
            <TabTitle
              title="Content"
              icon="images"
            />
          </template>
          <ContentModule 
            :switch-to-tab="switchToTab" 
          />
        </b-tab>
        <b-tab title-item-class="instance-related">
          <template #title>
            <TabTitle
              title="AI Chat"
              icon="chat"
            />
          </template>
          <AiChatModule
            ref="aiChatModule"
            :is-tab-active="tabIndex === 8"
            :target-element="target"
          />
        </b-tab>
        <template #tabs-end>
          <b-button
            v-b-tooltip.hover="`${right ? 'Left' : 'Right'} side`"
            class="my-auto ml-auto mr-1 d-none"
            variant="primary"
            size="sm"
            @click="right = !right"
          >
            <b-icon :icon="`arrow-bar-${right ? 'left' : 'right'}`" />
          </b-button>
        </template>
      </b-tabs>
      <template #footer>
        <span class="text-muted float-right">{{ fieldType }}</span>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AnimationsModule from './EditorControl/AnimationsModule.vue'
import PropertiesModule from './EditorControl/PropertiesModule.vue'
import TextEditorControl from './EditorControl/TextEditorControl.vue'
import FieldsModule from './EditorControl/FieldsModule.vue'
import ShapeEditorControl from './EditorControl/ShapeEditorControl.vue'
import ImageEditorControl from './EditorControl/ImageEditorControl.vue'
import ShadowsModule from './EditorControl/ShadowsModule.vue'
import ProductDimensionEditorControl from "./EditorControl/ProductDimensionEditorControl.vue";
import GroupEditorControl from "@frontend/components/modules/layouts-page/EditorControl/GroupEditorControl.vue";
import TabTitle from '@frontend/components/common/atoms/TabTitle.vue'
import LayersModule from '@frontend/components/modules/layouts-page/EditorControl/LayersModule.vue'
import SmartObjectEditorControl
  from "@frontend/components/modules/layouts-page/EditorControl/SmartObjectEditorControl.vue";
import BackgroundColorEditor from "@frontend/components/modules/layouts-page/EditorControl/BackgroundColorEditor.vue";
import ContentModule from './EditorControl/ContentModule.vue';
import AiChatModule from './EditorControl/AiChatModule.vue';
import {ShowPageScopeCollector} from "@frontend/group/modules/scope-collectors/show-page-collector";

export default {
  name: 'LayoutSidebar',
  components: {
    BackgroundColorEditor,
    LayersModule,
    TabTitle,
    SmartObjectEditorControl,
    GroupEditorControl,
    ShadowsModule,
    ImageEditorControl,
    ShapeEditorControl,
    FieldsModule,
    TextEditorControl,
    PropertiesModule,
    AnimationsModule,
    ProductDimensionEditorControl,
    ContentModule,
    AiChatModule
  },

  data: () => ({
    typeEditorControl: null,
    visible: false,
    defaultTabIndex: 4,
    tabIndex: 4,
    width: '430px',
    right: true,
    target: null,
    themes: null,
    options: {},
    fieldType: '',
    modules: {
      animation: {},
      properties: {},
    },
    isLayoutInitialized: false
  }),

  computed: {
    ...mapGetters('layout', [
      'selectedInstance',
    ]),
    layerName () {
      return this.target?.templateField?.name || this.target?.name
    },
    backgroundColorRectangle () {
      if (!this.selectedInstance || !this.selectedInstance.canvas || !this.selectedInstance.canvas.getObjects().length) {
        return null;
      }
      return this.selectedInstance.canvas.getObjects().find(object => object?.fieldType === 'Background Color')
    },
    newSmartObjectName() {
      return 'SmartObject' + ((this.target?.smartObjectsList?.length + 1) || 1);
    }
  },

  methods: {
    init (data) {
      if (!this.visible || this.visible && this.tabIndex === this.defaultTabIndex) {
        this.visible = true
        this.tabIndex = 0
      }
      if (data && _.isEqual(data.target, this.target)) {
        return
      }

      this.typeEditorControl = data.typeEditorControl
      this.fieldType = data.fieldType
      this.target = data.target
      this.$store.commit('layout/SET_TARGET', data.target)
      this.options = data.options
      this.modules = data.modules
      this.themes = data.themes
      this.isLayoutInitialized = true;
    },
    async createSmartObject() {
      const {rerenderTemplates, getUserLayersOrder} = ShowPageScopeCollector.get([
        'rerenderTemplates',
        'getUserLayersOrder',
      ]);
      const instance_id = this.target.preview.id;
      const template_id = this.target.preview.template.id;
      const template_width = this.target.preview.template.width;
      const template_height = this.target.preview.template.height;
      const name = this.newSmartObjectName;
      let users_fields_order = this.target.preview.data.users_fields_order ? JSON.parse(this.target.preview.data.users_fields_order) : false;
      let usersOrderData = null;
      if (!users_fields_order) {
        usersOrderData = getUserLayersOrder(instance_id);
      }
      const response = await axios.post(
        window.location.origin + '/banner/add-smart-object-field',
        {
          template_id,
          template_width,
          template_height,
          name,
          users_fields_order: usersOrderData ? usersOrderData.orders : users_fields_order,
          layout_id: $("#template-group-preview").attr("data-layout-id")
        }
      );
      await rerenderTemplates();
      
      setTimeout(() => {
        const preview = previews.find(preview => preview.id === instance_id)
        this.selectedInstance.canvas.fire('show:smart-object-editor', {instanceId: instance_id, preview: preview, smartObjectId: response.data.id});
      }, 500)
      
    },
    goToLayersTab () {
      this.tabIndex = 5
    },
    rerenderLayersModule() {
      this.$refs.layersModule.rerenderList();
    },
    switchToTab(number = this.defaultTabIndex) {
      this.tabIndex = number;
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .b-sidebar-header {
    font-size: 1rem;
    overflow-wrap: anywhere;

    strong {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }

    .close {
      order: 2;
      margin-right: 5px !important;
    }
  }

  .no-target {
    .nav-item:not(.instance-related) {
      display: none;
    }
  }

  .layout-sidebar-nav-wrapper {
    position: sticky;
    top: 0;
    align-self: flex-start;
    padding-right: .75rem;
    padding-top: .5rem;

    .nav-link {
      padding: .75rem
    }
  }

  .layer-name-link {
    display: block;
    margin: -0.5rem 0 0.5rem 0;
  }
}
</style>
