<template>
  <b-modal
    :id="modalId"
    title="Remove background"
    @hide="closeHandler"
    @show="reset"
  >
    <template #modal-footer>
      <ButtonOutline
        variant="outline-primary"
        @click="closeHandler"
      >
        Cancel
      </ButtonOutline>
      <ButtonPrimary
        variant="primary"
        @click="accept"
      >
        Remove background
      </ButtonPrimary>
    </template>

    <b-form-group v-if="!isLoading">
      <b-form-radio
        v-model="isReplace"
        :value="false"
      >
        Copy before removing background
      </b-form-radio>
      <b-form-radio
        v-model="isReplace"
        :value="true"
      >
        Replace image file after remove background
      </b-form-radio>
    </b-form-group>

    <b-spinner v-else />
  </b-modal>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
import ButtonPrimary from "@frontend/components/common/atoms/ButtonPrimary.vue";
import ButtonOutline from "@frontend/components/common/atoms/ButtonOutline.vue";
import {MUTATIONS} from "@frontend/store/file-browser-product-selector";
import Requests from "@frontend/services/api/file-browser";
import mixinModal from './mixin'
import {MODALS} from "@frontend/components/modules/file-browser-product-selector/constants";

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('fileBrowserProductSelector')

export default {
  name: "RemoveBackgroundModal",
  components: {ButtonOutline, ButtonPrimary},
  mixins: [mixinModal],
  data: () => ({
    modalId: MODALS.REMOVE_BACKGROUND,
    isReplace: false,
    isLoading: false,
  }),
  computed: {
    ...mapGetters(['selectedItems'])
  },
  methods: {
    ...mapMutations([MUTATIONS.CLEAR_SELECTION]),
    ...mapActions(['fetchList']),
    async accept() {
      try {
        this.isLoading = true
        const file = await this.fetchFile();
        const formData = new FormData();
        formData.append("file", file);
        formData.append("is_replace", this.isReplace ? 1 : 0);
        formData.append("path", this.selectedItems[0].url.split('?file=')[1]);
        await Requests.saveRemoveBGImage(formData);
        await this.fetchList();
        toastr.success('Removed File Background!');
      } catch (error) {
        const file = await this.fetchFile();
        const maxFileSizeInBytes = 22 * 1024 * 1024;
        file.size > maxFileSizeInBytes ?
          toastr.error('Request entity too large') :
          toastr.error('Remove background failed');
      } finally {
        this.close();
        this[MUTATIONS.CLEAR_SELECTION]();
      }
    },
    reset() {
      this.isReplace = false;
      this.isLoading = false;
    },
    async fetchFile() {
      const response = await fetch(this.selectedItems[0].url)
      const contentType = response.headers.get("content-type");
      const blob = await response.blob();
      
      return new File([blob], "remove_bg_image.jpg", { contentType });
    }
  }
}
</script>

<style scoped lang="scss">

</style>
