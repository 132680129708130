<template>
  <div class="d-flex align-items-center apply-block">
    <div class="mr-1">
      Apply to:
    </div>

    <SelectTemplateDropdown
      :local-storage-key="LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE"
    />

    <b-dropdown
      size="sm"
      variant="primary"
      text="Actions"
      class="select-properties-dropdown mr-1"
      menu-class="p-0"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(property, index) in properties"
          :key="index"
        >
          <b-form-checkbox
            v-model="property.value"
            size="sm"
            class="select-size"
            @change="updateSelectedProperties(property)"
          >
            {{ property.text }}
          </b-form-checkbox>
        </b-list-group-item>
      </b-list-group>
    </b-dropdown>

    <b-button
      size="sm"
      class="mr-1"
      variant="primary"
      @click="apply"
    >
      Apply
    </b-button>

    <ApplyInfoButton />
  </div>
</template>

<script>
import SelectTemplateDropdown from './SelectTemplateDropdown.vue';
import {editorControlEventBroker, EVENTS} from '@frontend/group/modules/editor-control-popup/event-broker';
import {
  LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE,
} from '@frontend/group/modules/editor-control-popup/selected-template-for-apply-to-all'
import ApplyInfoButton from '@frontend/components/modules/layouts-page/EditorControl/components/ApplyInfoButton.vue'

export default {
  name: 'ApplyToAllImageDropdown',

  components: { ApplyInfoButton, SelectTemplateDropdown},

  props: {
    target: {
      type: Object,
      default: () => {
      },
    },
  },

  data() {
    return {
      LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE,
      isApplyToAll: false,
      properties: [
        {
          text: 'Change size',
          key: 'size',
          value: false,
        },
        {
          text: 'Change % size',
          key: 'size_percent',
          value: false
        },
        {
          text: 'Change position',
          key: 'position',
          value: false,
        },
        {
          text: 'Replace images',
          key: 'replace',
          value: false
        },
        {
          text: 'Add images',
          key: 'add_images',
          value: false,
        },
        {
          text: 'Hide layers',
          key: 'hide',
          value: false
        },
        {
          text: 'Show layers',
          key: 'show',
          value: false
        },
        {
          text: 'Delete layers',
          key: 'delete',
          value: false
        }
      ],
      selectedProperties: []
    };
  },

  methods: {
    apply() {
      editorControlEventBroker.fire(EVENTS.CHANGE_IMAGE_PROPERTIES, this.selectedProperties);
      this.resetProperties();
    },
    updateSelectedProperties(property) {
      if (property.value) {
        if (property.key === 'show') {
          this.setPropertyValue('hide', false);
        } else if (property.key === 'hide') {
          this.setPropertyValue('show', false);
        } else if (property.key === 'delete') {
          this.properties.forEach(prop => {
            if (prop.key !== 'delete') {
              prop.value = false;
            }
          });
          this.selectedProperties = [property];
          return;
        }
        if (property.key != 'delete') {
          this.setPropertyValue('delete', false);
        } 
        if (!this.selectedProperties.some((p) => p.key === property.key)) {
          this.selectedProperties.push(property);
        }
      } else {
        this.selectedProperties = this.selectedProperties.filter((p) => p.key !== property.key);
      }
    },
    setPropertyValue(key, value) {
      const property = this.properties.find(p => p.key === key);
      if (property) {
        property.value = value;
        this.selectedProperties = this.selectedProperties.filter(p => p.key !== key);
      }
    },
    resetProperties() {
      this.properties.forEach(property => {
        property.value = false;
      });
      
      this.selectedProperties = [];
    },
  },
};
</script>

<style lang="scss">
.align-items-start {
  flex-wrap: wrap;

  .image-editor-actions--bg-actions {
    max-width: 100%;
  }
  .select-size {
    width: 170px;
  }
}
</style>
