import api from "../../services/api/project";
import Vue from 'vue'

const ITEM_TYPE = 'project';
const ITEM = 'Projects'

async function updateDataAndCommit({ commit, itemType, apiFunction, payload }) {
  if (payload) {
    await apiFunction(payload);
  } else {
    await apiFunction({ item_type: itemType });
  }
  
  const response = await api.fetchFolders(itemType);
  commit('SET_FOLDERS', response.data.folders);
}

const module = {
  namespaced: true,

  state: {
    showCommentBox: false,
    showImageDimensions: false,
    showPins: false,
    commentText: "",
    commentImages: [],

    folders:[],
    projects: [],
    projectsDrafts: [],
    projectsNew: [],
    tags: [],
    galleryMode: false,
    openFolder: {
      id: '',
      name: '',
      mode: false,
    },
    selectedFolderData: {
      folderId: '',
      project: ''
    },
    navProjectsTitle: ITEM,
    modalFolders:[],
  },

  getters: {
    folders: state => state.folders,
    projects: state => state.projects,

    projectsDrafts(state) {
      return state.projectsDrafts;
    },
    projectsNew(state) {
      return state.projectsNew;
    },
    tags: state => _.map(state.tags, 'text'),

    showCommentBox: state => state.showCommentBox,
    showImageDimensions: state => state.showImageDimensions,
    commentText: state => state.commentText,
    commentImages: state => state.commentImages,
    galleryMode: state => state.galleryMode,
    showPins: state => state.showPins,
    openFolderMode: state => state.openFolder.mode,
    selectedFolderData: state => state.selectedFolderData,
    navProjectsTitle: state => state.navProjectsTitle,
    modalFolders: state => state.modalFolders,
  },

  mutations: {
    SET_FOLDERS(state, folders) {
      state.folders = folders;
    },
    SET_MODAL_FOLDERS(state, folders) {
      state.modalFolders = folders;
    },
    SET_OPEN_FOLDER(state, folder) {
      state.openFolder.id = folder.id;
      state.openFolder.name = folder.name;
      state.openFolder.mode = folder.mode;
    },
    SET_SELECTED_PROJECT(state, project) {
      state.selectedFolderData.project = project
    },
    SET_SELECTED_FOLDER(state, folderId) {
      state.selectedFolderData.folderId = folderId
    },
    
    SET_PROJECTS(state, projects) {
      state.projects = projects;
    },
    UPDATE_PROJECT (state, payload) {
      const index = _.findIndex(state.projects, ['id', payload.id])
      if (index !== -1) {
        Vue.set(state.projects, index, payload)
      }
    },
    SET_PROJECTS_DRAFTS(state, projects) {
      state.projectsDrafts = projects;
    },
    SET_PROJECTS_NEW(state, projectsNew) {
      state.projectsNew = projectsNew;
    },
    SET_PROJECTS_TITLE(state, folderName) {
      state.navProjectsTitle = `${folderName}`
    },

    DELETE_PROJECT (state, payload) {
      const index = _.findIndex(state.projects, ['id', payload.id])
      if (index !== -1) {
        state.projects.splice(index, 1)
      }
    },

    DELETE_PROJECT_DRAFT (state, payload) {
      const index = _.findIndex(state.projectsDrafts, ['id', payload.id])
      if (index !== -1) {
        state.projectsDrafts.splice(index, 1)
      }
    },

    UPDATE_PROJECT_TAGS (state, { id, tag }) {
      updateTags(state.projects, id, tag, 'attach')
    },

    REMOVE_PROJECT_TAG (state, { id, tag }) {
      updateTags(state.projects, id, tag, 'detach')
    },

    UPDATE_DRAFT_PROJECT_TAGS (state, { id, tag }) {
      updateTags(state.projectsDrafts, id, tag, 'attach')
    },

    REMOVE_DRAFT_PROJECT_TAG (state, { id, tag }) {
      updateTags(state.projectsDrafts, id, tag, 'detach')
    },

    FETCH_TAGS (state, tags) {
      state.tags = tags
    },

    SET_COMMENT_BOX(state, status) {
      state.showCommentBox = status;
    },
    SET_SHOW_IMAGE_DIMENSIONS (state, status) {
      state.showImageDimensions = status
    },
    SET_COMMENT_TEXT(state, text) {
      state.commentText = text;
    },
    SET_COMMENT_IMAGES(state, images) {
      state.commentImages = images;
    },
    SET_GALLERY_MODE (state, payload) {
      state.galleryMode = payload
    },
  },

  actions: {
    //FOLDERS
    async fetchFolder({ commit }, payload) {
      const response = await api.fetchFolders(payload);
      commit('SET_FOLDERS', response.data.folders);
      commit('SET_MODAL_FOLDERS', response.data.folders);
    },
    async createFolder({ commit }) {
      await updateDataAndCommit(
        {
          commit,
          itemType: ITEM_TYPE,
          apiFunction: api.createFolder,
        }
      );
    },
    async editFolder({ commit }, payload) {
      await updateDataAndCommit(
        {
          commit,
          itemType: ITEM_TYPE,
          apiFunction: api.updateFolder,
          payload
        }
      );
    },
    async deleteFolder({ commit }, payload) {
      await updateDataAndCommit(
        {
          commit,
          itemType: ITEM_TYPE,
          apiFunction: api.deleteFolder,
          payload
        }
      );
    },
    async deleteFolderItem({ commit, state }, payload) {
      await api.deleteFolderItem(payload);
      const response = await api.folderContent({ id: state.openFolder.id });
      commit('SET_FOLDERS', response.data.folders);
      commit("SET_PROJECTS", response.data.projects);
    },
    async openFolder({ commit }, payload) {
      const response = await api.folderContent({ id: payload.id });
      commit('SET_PROJECTS_TITLE', `${ITEM} - ${payload.name}`);
      commit('SET_OPEN_FOLDER', { id: payload.id, name:payload.name, mode: true });
      commit('SET_FOLDERS', response.data.folders);
      commit("SET_PROJECTS", response.data.projects);
    },
    async goBack({ commit }, payload) {
      const foldersData = await api.fetchFolders(ITEM_TYPE);
      const projectsData = await api.getProjects(payload);
      commit('SET_PROJECTS_TITLE', ITEM);
      commit('SET_OPEN_FOLDER', { id: '', name: '', mode: false });
      commit('SET_FOLDERS', foldersData.data.folders);
      commit("SET_PROJECTS", projectsData.data.projects);
    },
    async searchModalFolders({ commit }, payload) {
      const response = await api.fetchFolders(payload);
      commit('SET_MODAL_FOLDERS', response.data.folders);
    },
    async searchFolders({ commit }, payload) {
      const response = await api.fetchFolders(payload);
      commit('SET_FOLDERS', response.data.folders);
    },
    setSelectedProject({ commit }, payload) {
      commit('SET_SELECTED_PROJECT', payload);
    },
    setSelectedFolder({ commit }, payload) {
      commit('SET_SELECTED_FOLDER', payload);
    },
    async addToProjectFolder({ commit }, payload) {
      await api.addToProjectFolder(payload);
      const response = await api.getProjects(payload);
      commit("SET_PROJECTS", response.data.projects);
    },
    async moveFromProjectFolder({ commit, state }, payload) {
      await api.moveFromProjectFolder({ project: payload });
      const response = await api.folderContent({ id: state.openFolder.id });
      commit('SET_FOLDERS', response.data.folders);
      commit("SET_PROJECTS", response.data.projects);
    },
    async searchFolderContent({ commit, state }, payload) {
      const response = await api.folderContent({
        id: state.openFolder.id,
        search: payload?.search,
        tags: payload?.tags
      });
      commit('SET_FOLDERS', response.data.folders);
      commit("SET_PROJECTS", response.data.projects);
    },
    
    //PROJECTS
    async getProjects({ commit }, payload) {
      const response = await api.getProjects(payload);
      commit("SET_PROJECTS", response.data.projects);
    },
    async getProjectsDrafts({commit}, payload = {}) {
      payload.drafts = true
      const response = await api.getProjects(payload);
      commit("SET_PROJECTS_DRAFTS", response.data.projectsDrafts);
    },
    async getProjectsNew({commit}, payload = {}) {
      const response = await api.getProjectsNew(payload);
      commit("SET_PROJECTS_NEW", response.data.newProjects);
    },
    async saveProject({ commit, dispatch }, payload) {
      const { data: { message, data: { project } } } = await api.saveProject(payload);
      if (payload.thumbnails.length) {
        project.thumbnails = await dispatch('updateThumbnails', { ...payload, id: project.id });
      }
      if (payload.id) {
        commit('UPDATE_PROJECT', project);
      }
      toastr.success(message);
      return project;
    },
    async copyProject({ commit }, id) {
      const { data } = await api.copyProject(id);
      const message = data.message;
      const project = data.data;
      commit('UPDATE_PROJECT', project);
      toastr.success(message);
      return data;
    },
    async updateThumbnails ({ commit }, payload) {
      const thumbnails = await Promise.all(payload.thumbnails.map(async ({ Image, Scene }) => ({
        thumbnail: await api.base64ToFile(Image),
        sceneNumber: Scene,
      })))
      await api.clearThumbnails(payload.id)
      const chunks = _.chunk(thumbnails, 20)
      const res = await Promise.all(chunks.map(thumbnails => api.saveThumbnails({
        thumbnails,
        id: payload.id,
      })))
      return _.flatMap(res, 'data')
    },
    loadProject({ commit }, id) {
     return api.loadProject(id);
    },

    async deleteProject ({ commit }, payload) {
      await api.deleteProject(payload.id)
      commit('DELETE_PROJECT', payload)
    },

    async deleteProjectDraft ({ commit }, payload) {
      await api.deleteProject(payload.id)
      commit('DELETE_PROJECT_DRAFT', payload)
    },

    async attachTagToProject ({ commit }, { id, tag }) {
      const { data } = await api.attachTagToProject(id, tag)
      commit('UPDATE_PROJECT_TAGS', { id, tag: data })
    },

    async detachTagFromProject ({ commit }, { id, tag }) {
      const { data } = await api.detachTagFromProject(id, tag)
      commit('REMOVE_PROJECT_TAG', { id, tag: data })
    },

    async attachTagToDraftProject ({ commit }, { id, tag }) {
      const { data } = await api.attachTagToProject(id, tag)
      commit('UPDATE_DRAFT_PROJECT_TAGS', { id, tag: data })
    },

    async detachTagFromDraftProject ({ commit }, { id, tag }) {
      const { data } = await api.detachTagFromProject(id, tag)
      commit('REMOVE_DRAFT_PROJECT_TAG', { id, tag: data })
    },

    async fetchTags ({ commit }) {
      const { data } = await api.getAllTags()
      commit('FETCH_TAGS', data)
    },

    updateCommentBox ({ commit }, val) {
      commit('SET_COMMENT_BOX', val)
    },
    updateCommentText ({ commit }, text) {
      commit('SET_COMMENT_TEXT', text)
    },
    updateCommentImages ({ commit }, images) {
      commit('SET_COMMENT_IMAGES', images)
    },
  },
};

function updateTags(projectList, id, tag, operation) {
  const project = projectList.find(project => project.id === id)
  if (!project) {
    return
  }

  if (operation === 'attach') {
    project.tags.push(tag)
  } else if (operation === 'detach') {
    const index = _.findIndex(project.tags, ['id', tag.id])
    if (index !== -1) {
      project.tags.splice(index, 1)
    }
  }
}

export default module;
