<template>
  <div>
    <b-form-group
      inline
    >
      <label>
        Object Fill
        <b-tooltip
          target="object-fill-info-icon"
          placement="top"
        >
          To scale an image, set Object Fill to None
        </b-tooltip>
        <b-icon-info-circle
          id="object-fill-info-icon"
          variant="primary"
          class="ml-2 object-fill-fit-info"
        />
      </label>
      <b-form-select
        v-model="objectFillSettings.align_to_fill"
        :options="objectFitAndFillOptions.objectFillOptions"
        text-field="name"
        value-field="value"
        @change="onChange('objectFillSettings')"
      />
      <b-form-checkbox 
        v-model="objectFillCropMode" 
        name="crop-mode" 
        switch
        @change="onChange('objectFillSettings')"
      >
        Crop
      </b-form-checkbox>
    </b-form-group>
    <b-form-group
      inline
    >
      <label>
        Object Fit
        <b-tooltip
          target="object-fit-info-icon"
          placement="top"
        >
          To scale an image, set Object Fit to None
        </b-tooltip>
        <b-icon-info-circle
          id="object-fit-info-icon"
          variant="primary"
          class="ml-2 object-fill-fit-info"
        />
      </label>
      <b-form-select
        v-model="objectFitSettings.align_to_fit"
        :options="objectFitAndFillOptions.objectFitOptions"
        text-field="name"
        value-field="value"
        @change="onChange('objectFitSettings')"
      />
    </b-form-group>
  </div>
</template>

<script>
import {isString} from "lodash";
import {ShowPageScopeCollector} from "@frontend/group/modules/scope-collectors/show-page-collector";
import {mapGetters} from "vuex";
import {BACKGROUND_THEME_IMAGE_PREFIX_ID} from "@frontend/constants/templates-field-prefixes";
import {deepCopy} from "@frontend/utils";

export default {
  name: 'ObjectFillFit',
  props: {
    target: {
      type: fabric.Object,
      default: () => {}
    },
  },
  data() {
    return {
      objectFillSettings: {
        width_to_fill: 0,
        height_to_fill: 0,
        align_to_fill: ''
      },
      objectFitSettings: {
        width_to_fit: 0,
        height_to_fit: 0,
        align_to_fit: ''
      },
      objectFitAndFillOptions: {
        objectFitOptions: [
          {
            "value": "",
            "name": "none"
          },
          {
            "value": "left,top",
            "name": "┍ left, top"
          },
          {
            "value": "center,top",
            "name": "┯ center, top"
          },
          {
            "value": "right,top",
            "name": "┑right, top"
          },
          {
            "value": "left,center",
            "name": "┡ left, center"
          },
          {
            "value": "center,center",
            "name": "╇ center, center"
          },
          {
            "value": "right,center",
            "name": "┥ right, center"
          },
          {
            "value": "left,bottom",
            "name": "┕ left, bottom"
          },
          {
            "value": "center,bottom",
            "name": "┻ center, bottom"
          },
          {
            "value": "right,bottom",
            "name": "┙right, bottom"
          }
        ],
        objectFillOptions: [
          {
            "value": "",
            "name": "none"
          },
          {
            "value": "left,top",
            "name": "┍ left, top"
          },
          {
            "value": "center,top",
            "name": "┯ center, top"
          },
          {
            "value": "right,top",
            "name": "┑right, top"
          },
          {
            "value": "left,center",
            "name": "┡ left, center"
          },
          {
            "value": "center,center",
            "name": "╇ center, center"
          },
          {
            "value": "right,center",
            "name": "┥ right, center"
          },
          {
            "value": "left,bottom",
            "name": "┕ left, bottom"
          },
          {
            "value": "center,bottom",
            "name": "┻ center, bottom"
          },
          {
            "value": "right,bottom",
            "name": "┙right, bottom"
          }
        ]
      },
      objectFillCropMode: true
    }
  },
  computed: {
    ...mapGetters('layout', [
      'selectedInstance',
    ]),
    isValidFill() {
      const {width_to_fill, height_to_fill, align_to_fill} = this.objectFillSettings;
      return width_to_fill && height_to_fill && isString(align_to_fill) && align_to_fill.includes(',')
    },
    isValidFit() {
      const {width_to_fit, height_to_fit, align_to_fit} = this.objectFiTSettings;
      return width_to_fit && height_to_fit && isString(align_to_fit) && align_to_fit.includes(',')
    },
  },
  watch: {
    target() {
      this.initData()
    }
  },
  mounted() {
   this.initData()
  },
  methods: {
    toggle () {
      this.target.set('locked', !this.target.locked)
      this.target.canvas.fire('locked:changed', this.target)
    },
    initData() {
      const {objectFitSettings, objectFillSettings} = this.target;
      this.objectFillSettings = {
        ...objectFillSettings,
        align_to_fill: objectFillSettings.align_to_fill 
          ? objectFillSettings.align_to_fill : '' 
      };
      this.objectFitSettings = {
        ...objectFitSettings, align_to_fit: objectFitSettings.align_to_fit 
          ? objectFitSettings.align_to_fit : ''
      };
      
      this.objectFillCropMode = objectFillSettings.hasOwnProperty('objectFillCrop') ? objectFillSettings.objectFillCrop : true;
    },
    async onChange(action) {
      const savedObjectId = this.target.id;
      const {
        updateLayoutChanges,
        setBackgroundImage,
        drawSmartObject,
        reorderObjectCanvas
      } = ShowPageScopeCollector.get([
        'updateLayoutChanges',
        'setBackgroundImage',
        'drawSmartObject',
        'reorderObjectCanvas',
      ]);
      switch (action) {
        case 'objectFillSettings':
          this.objectFitSettings.align_to_fit = '';
          break;
        case 'objectFitSettings':
          this.objectFillSettings.align_to_fill = '';
          break;
      }
      const index = this.target.id.split('_').reverse()[0];
      const dataObjectFill = this.selectedInstance.canvas.getObjects()
        .filter(object => object.id.startsWith(BACKGROUND_THEME_IMAGE_PREFIX_ID))
        .sort((a, b) => a.id.localeCompare(b.id))
        .map(object => object.objectFillSettings);
      const dataObjectFit = this.selectedInstance.canvas.getObjects()
        .filter(object => object.id.startsWith(BACKGROUND_THEME_IMAGE_PREFIX_ID))
        .sort((a, b) => a.id.localeCompare(b.id))
        .map(object => object.objectFitSettings);

      let objectFitData = [...dataObjectFit.map(deepCopy)]
        .map(item => {
          if (!item.align_to_fit) {
            item.align_to_fit = ''
          }
          return item;
        });

      if (this.selectedInstance.data.hasOwnProperty(`bk_img_object_fit`)) {
        objectFitData = this.selectedInstance.data[`bk_img_object_fit`];
      }
      
      objectFitData[index]['align_to_fit'] = this.objectFitSettings.align_to_fit;
      let objectFillData = [...dataObjectFill.map(deepCopy)]
        .map(item => {
          if (!item.align_to_fill) {
            item.align_to_fill = ''
          }
          return item;
        });

      if (this.selectedInstance.data.hasOwnProperty(`bk_img_object_fill`)) {
        objectFillData = this.selectedInstance.data[`bk_img_object_fill`];
      }
      
      objectFillData[index]['align_to_fill'] = this.objectFillSettings.align_to_fill;
      objectFillData[index]['objectFillCrop'] = this.objectFillCropMode;
      updateLayoutChanges(this.selectedInstance.id, `bk_img_object_fill`, objectFillData);
      updateLayoutChanges(this.selectedInstance.id, `bk_img_object_fit`, objectFitData);
      await setBackgroundImage(this.selectedInstance);
      drawSmartObject({...this.selectedInstance, fields: this.selectedInstance.template.fields});
      reorderObjectCanvas(this.selectedInstance.canvas);
      this.selectedInstance.canvas.setActiveObject(this.selectedInstance.canvas.getObjects().find(obj => obj.id === savedObjectId));
      this.selectedInstance.canvas.requestRenderAll();
    }
  }
}
</script>

<style scoped lang="scss">
.object-fill-fit-info {
  position: absolute;
  left: 81px;
  margin-top: -4px;
  font-size: 14px;
}
</style>
