var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-body" },
    [
      _c("Multiselect", {
        attrs: {
          options: _vm.options,
          label: "name",
          "track-by": "id",
          placeholder: "Select saved settings",
        },
        on: { input: _vm.onSettingsSelected },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _vm._v(" "),
      _vm.selected
        ? _c("b-table", {
            staticClass: "mt-3",
            attrs: {
              items: _vm.items,
              fields: _vm.fields,
              "sticky-header": "70vh",
              hover: "",
              small: "",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }