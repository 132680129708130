import { fabric } from "fabric";

export const ellipseHandler = (defaultOptions) => (object, objectData) => {
    
    const smallImageThreshold = 450;
    let reductionFactor = 0.1; // Reduce by 10% of the smaller dimension
    if (object.width < smallImageThreshold || object.height < smallImageThreshold) {
        reductionFactor = 0.3; // Reduce by 30% of the smaller dimension
    } 
    const reductionAmount = object.width * reductionFactor; // Ensure minimum reduction
    const reductionAmountHeight = object.height * reductionFactor; // Ensure minimum reduction

    // Determine width and height bases to prevent clipping
    const widthBase = object.width <= object.height ? object.width - reductionAmount : object.width - reductionAmountHeight;
    const heightBase = object.height <= object.width ? object.height - reductionAmount : object.height - reductionAmountHeight;

    // Calculate left and top (preserving previous logic)
    const leftEllipse = objectData.left * widthBase / 2;
    const topEllipse = objectData.top * heightBase / 2;

    // Calculate radiusX (rx) and radiusY (ry)
    const rx = (objectData.right * widthBase - objectData.left * widthBase) / 2;
    const ry = (objectData.bottom * heightBase - objectData.top * heightBase) / 2;

    return new fabric.Ellipse({
        ...defaultOptions,
        left: leftEllipse - ((object.width - objectData.right * object.width) / 2),
        top: topEllipse - ((object.height - objectData.bottom * object.height) / 2),
        rx,
        ry,
        absolutePositioned: false, // Ensure it stays within the image
        objectData: objectData
    });
}