var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      on: {
        change: function ($event) {
          return _vm.$emit("toggle")
        },
        drop: function ($event) {
          $event.preventDefault()
          return _vm.onDrop.apply(null, arguments)
        },
        dragstart: function ($event) {
          $event.preventDefault()
        },
        dragenter: function ($event) {
          $event.preventDefault()
          return _vm.onDragEnter.apply(null, arguments)
        },
        dragleave: function ($event) {
          $event.preventDefault()
          return _vm.onDragLeave.apply(null, arguments)
        },
        dragover: function ($event) {
          $event.preventDefault()
          return _vm.onDragOver.apply(null, arguments)
        },
      },
    },
    [
      _c("PositionWarning", {
        attrs: { show: _vm.showPositionWarning, message: _vm.positionWarning },
        on: { dismiss: _vm.dismissWarning },
      }),
      _vm._v(" "),
      _c("CropImage", {
        attrs: { "is-show": _vm.isCropMode, src: _vm.target.getSrc() },
        on: {
          onSave: (data) => _vm.onChange("newCrop", data),
          onCancel: function ($event) {
            _vm.isCropMode = false
          },
        },
      }),
      _vm._v(" "),
      _vm.options.isSelectImageFromBackground
        ? [
            _c(
              "b-form-group",
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.onChange("selectBackground")
                      },
                    },
                  },
                  [
                    _c("b-icon", { attrs: { icon: "cloud-upload" } }),
                    _vm._v(
                      " " +
                        _vm._s(
                          `${
                            _vm.imageSrc && !_vm.imageIsDefault
                              ? "Replace"
                              : "Select…"
                          }`
                        ) +
                        "\n      "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    attrs: {
                      title: "Chooose product image...",
                      variant: "primary",
                    },
                    on: { click: _vm.toggleProductDropdown },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/img/icons/choose-product.png",
                        alt: "choose-product-icon",
                        width: "20",
                        height: "20",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm.imageSrc
                  ? _c(
                      "span",
                      {
                        staticClass: "download-image",
                        attrs: { href: _vm.imageSrc, download: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "close-btn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onChange("deleteConfirmationImage")
                              },
                            },
                          },
                          [_vm._v("\n          ✖\n        ")]
                        ),
                        _vm._v(" "),
                        _vm.imageSrc
                          ? _c("b-img", {
                              staticClass: "img-preview",
                              attrs: {
                                src: _vm.imageSrc,
                                fluid: "",
                                rounded: "",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName:
                                  "v-b-tooltip.hover.viewport.noninteractive",
                                value: "Download",
                                expression: "'Download'",
                                modifiers: {
                                  hover: true,
                                  viewport: true,
                                  noninteractive: true,
                                },
                              },
                            ],
                            staticClass: "download-image-href",
                            attrs: { href: _vm.imageSrc, download: "" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [_c("b-icon", { attrs: { icon: "download" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName:
                                  "v-b-tooltip.hover.viewport.noninteractive",
                                value: "Edit image",
                                expression: "'Edit image'",
                                modifiers: {
                                  hover: true,
                                  viewport: true,
                                  noninteractive: true,
                                },
                              },
                            ],
                            staticClass: "edit-image-btn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.showImageEditorModal.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_c("b-icon", { attrs: { icon: "pencil-square" } })],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              {
                attrs: {
                  label: "Theme",
                  "label-cols": "3",
                  "invalid-feedback": "No theme selected",
                  state: !!_vm.selectedTheme,
                },
              },
              [
                _c(
                  "b-form-select",
                  {
                    on: {
                      change: function ($event) {
                        return _vm.onChange("selectTheme", _vm.selectedTheme)
                      },
                    },
                    model: {
                      value: _vm.selectedTheme,
                      callback: function ($$v) {
                        _vm.selectedTheme = $$v
                      },
                      expression: "selectedTheme",
                    },
                  },
                  _vm._l(_vm.cThemesOptions, function (theme) {
                    return _c(
                      "b-form-select-option",
                      { key: theme.id, attrs: { value: theme.id } },
                      [
                        _vm._v(
                          "\n          " + _vm._s(theme.name) + "\n        "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.options.isUploadImage
        ? _c(
            "b-form-group",
            { attrs: { label: "Image", "label-cols": "3" } },
            [
              _c("b-form-file", {
                attrs: {
                  placeholder: "Choose a file",
                  "drop-placeholder": "Drop file here",
                },
                on: {
                  input: function ($event) {
                    return _vm.onChange("upload", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.options.isOutline
        ? [
            _c(
              "b-form-group",
              { attrs: { label: "Outline", "label-cols": "3" } },
              [
                _c("b-form-input", {
                  on: {
                    change: function ($event) {
                      return _vm.onChange("productOutline", _vm.outlineData)
                    },
                  },
                  model: {
                    value: _vm.objectInput.fileOutlineId,
                    callback: function ($$v) {
                      _vm.$set(_vm.objectInput, "fileOutlineId", $$v)
                    },
                    expression: "objectInput.fileOutlineId",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              { attrs: { label: "Color", "label-cols": "3" } },
              [
                _c("ColorPicker", {
                  on: {
                    input: function ($event) {
                      return _vm.onChange("productOutline", _vm.outlineData)
                    },
                  },
                  model: {
                    value: _vm.objectInput.fileOutlineColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.objectInput, "fileOutlineColor", $$v)
                    },
                    expression: "objectInput.fileOutlineColor",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("ProductImageDropdown", {
        ref: "productImageDropdown",
        attrs: { "is-file-image": _vm.options.isFileImage },
        on: {
          input: function ($event) {
            return _vm.onChange("productImage", _vm.objectInput.fileIds)
          },
          uploadInput: (fileIds) =>
            _vm.onChange("productImage", {
              productIds: fileIds,
              type: "uploaded_product_image",
            }),
        },
        model: {
          value: _vm.objectInput.fileIds,
          callback: function ($$v) {
            _vm.$set(_vm.objectInput, "fileIds", $$v)
          },
          expression: "objectInput.fileIds",
        },
      }),
      _vm._v(" "),
      _vm.target && _vm.target.id.startsWith("bk_theme_img_")
        ? _c("ObjectFillFit", { attrs: { target: _vm.target } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.options.isHtmlTable,
              expression: "options.isHtmlTable",
            },
          ],
          staticClass: "mb-2",
          attrs: { variant: "success" },
          on: { click: _vm.initTableEditor },
        },
        [_vm._v("\n    Edit Table\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Opacity" } },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: {
                          min: "0",
                          max: "1",
                          step: "0.01",
                          type: "number",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onChange("opacity", $event)
                          },
                        },
                        model: {
                          value: _vm.opacity,
                          callback: function ($$v) {
                            _vm.opacity = $$v
                          },
                          expression: "opacity",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: { min: "0", max: "1", step: "0.01", type: "range" },
                on: {
                  input: function ($event) {
                    return _vm.onChange("opacity", $event)
                  },
                },
                model: {
                  value: _vm.opacity,
                  callback: function ($$v) {
                    _vm.opacity = $$v
                  },
                  expression: "opacity",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          attrs: { disabled: _vm.target.locked },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c("span", [_vm._v("Scale")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.percentOfTemplate) + "% of Template"),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: {
                          min: "0",
                          max: "10",
                          step: "0.001",
                          type: "number",
                          disabled: _vm.isScaleDisabled,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onChange("scale", $event)
                          },
                        },
                        model: {
                          value: _vm.scale,
                          callback: function ($$v) {
                            _vm.scale = $$v
                          },
                          expression: "scale",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: {
                  min: "0",
                  max: "10",
                  step: "0.001",
                  type: "range",
                  disabled: _vm.isScaleDisabled,
                },
                on: {
                  input: function ($event) {
                    return _vm.onChange("scale", $event)
                  },
                },
                model: {
                  value: _vm.scale,
                  callback: function ($$v) {
                    _vm.scale = $$v
                  },
                  expression: "scale",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("PositionAndSize", {
        attrs: {
          x: parseFloat(_vm.objectInput.left),
          y: parseFloat(_vm.objectInput.top),
          h: parseFloat(_vm.objectInput.height),
          w: parseFloat(_vm.objectInput.width),
          "aspect-ratio-option:": "",
          "is-scale-disabled": _vm.isScaleDisabled,
        },
        on: { input: _vm.onChange },
      }),
      _vm._v(" "),
      _vm.target.fieldType === `HTML Table`
        ? _c(
            "b-form-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Related field" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          value: _vm.target.relatedField?.field,
                          options: _vm.relatedTemplateFields,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onChange("changeRelatedField", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Margin" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          value: _vm.target.relatedField?.margin,
                          type: "number",
                          min: "0",
                          step: "1",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onChange("changeMargin", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-start mb-3",
        },
        [
          _c("ApplyToAllImageDropdown", { attrs: { target: _vm.target } }),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.options.isFileImage
                ? _c(
                    "b-form-checkbox",
                    {
                      staticClass: "mt-1 d-none",
                      attrs: { size: "sm" },
                      on: {
                        change: function ($event) {
                          return _vm.onChange(
                            "autoPosition",
                            _vm.objectInput.isAutoPosition
                          )
                        },
                      },
                      model: {
                        value: _vm.objectInput.isAutoPosition,
                        callback: function ($$v) {
                          _vm.$set(_vm.objectInput, "isAutoPosition", $$v)
                        },
                        expression: "objectInput.isAutoPosition",
                      },
                    },
                    [_vm._v("\n        Auto position\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "image-editor-actions image-editor-actions--bg-actions",
            },
            [
              _vm.options.isSelectImageFromBackground
                ? _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: {
                        title: _vm.resolveScaleImageAction("width").tooltip,
                      },
                      on: {
                        click: function ($event) {
                          _vm.onChange(
                            _vm.resolveScaleImageAction("width").action,
                            "width"
                          )
                        },
                      },
                    },
                    [_c("img", { attrs: { src: "/img/icons/expand.svg" } })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.options.isSelectImageFromBackground
                ? _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: {
                        title: _vm.resolveScaleImageAction("height").tooltip,
                      },
                      on: {
                        click: function ($event) {
                          _vm.onChange(
                            _vm.resolveScaleImageAction("height").action,
                            "height"
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { transform: "rotate(90deg)" },
                        attrs: { src: "/img/icons/expand.svg" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.options.isSelectImageFromBackground
                ? _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: {
                        title: _vm.resolveScaleImageAction("default").tooltip,
                      },
                      on: {
                        click: function ($event) {
                          _vm.onChange(
                            _vm.resolveScaleImageAction("default").action,
                            _vm.resolveScaleImageAction().scaleParam
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: { src: "/img/icons/full-screen.svg" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  attrs: { title: _vm.resolveCenterImageAction.tooltip },
                  on: {
                    click: function ($event) {
                      return _vm.onChange(
                        _vm.resolveCenterImageAction.action,
                        _vm.resolveCenterImageAction
                      )
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "/img/icons/center-image.svg",
                      alt: "center-icon",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  attrs: {
                    title: _vm.resolveHorizontalCenterImageAction.tooltip,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onChange(
                        _vm.resolveHorizontalCenterImageAction.action,
                        _vm.resolveCenterImageAction
                      )
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "/img/icons/align-center.png",
                      alt: "align-center-icon",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "flip-x",
                  attrs: { title: "Flip horizontal" },
                  on: {
                    click: function ($event) {
                      return _vm.onChange("flipX")
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: "/img/icons/horizontal-flip.svg" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "flip-y",
                  attrs: { title: "Flip vertical" },
                  on: {
                    click: function ($event) {
                      return _vm.onChange("flipY")
                    },
                  },
                },
                [_c("img", { attrs: { src: "/img/icons/vertical-flip.svg" } })]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "crop",
                  attrs: { title: "Crop" },
                  on: {
                    click: function ($event) {
                      _vm.isCropMode = true
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "x-svg",
                      attrs: { width: "22", height: "22" },
                    },
                    [
                      _c("use", {
                        attrs: { href: "/img/icons/sprite.svg#crop" },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.target.originalPathForRestoreAfterCrop
                ? _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "restore-original undo-button",
                      attrs: { title: "Restore original image (crop)" },
                      on: { click: _vm.restoreOriginalAfterCrop },
                    },
                    [
                      _c("img", {
                        attrs: { src: "/img/icons/reset.svg", alt: "restore" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isBgThemeImage
                ? _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "remove-gb",
                      attrs: { title: "Remove background" },
                      on: { click: _vm.handleRemoveBackground },
                    },
                    [_c("img", { attrs: { src: "/img/icons/remove-bg.svg" } })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFileImage
                ? _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "remove-gb",
                      attrs: { title: "Remove background" },
                      on: { click: _vm.showProductImageRemoveBGModal },
                    },
                    [_c("img", { attrs: { src: "/img/icons/remove-bg.svg" } })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  attrs: { title: "Reset aspect ratio" },
                  on: {
                    click: function ($event) {
                      return _vm.onChange("resetAspectRatio")
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: "/img/icons/reset-aspect-ratio.svg" },
                  }),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          attrs: { variant: "danger" },
          on: {
            click: function ($event) {
              return _vm.onChange("deleteConfirmation")
            },
          },
        },
        [
          _c("b-icon", { attrs: { icon: "trash" } }),
          _vm._v("\n    Delete Layer\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: "Mask",
              expression: "'Mask'",
              modifiers: { hover: true },
            },
          ],
          staticClass: "btn btn-primary mask-settings",
          attrs: { "data-id": _vm.selectedInstance.canvas.instance_id },
        },
        [_c("b-icon", { attrs: { icon: "mask" } })],
        1
      ),
      _vm._v(" "),
      _c("LockToggle"),
      _vm._v(" "),
      _c("ProductImageRemoveBGModal", {
        ref: "productImageRemoveBGModal",
        on: {
          replaceExistingImage: _vm.replaceImage,
          copyAndReplaceImage: _vm.copyImage,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }