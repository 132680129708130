<template>
  <b-modal
    :id="modalId"
    title="Crop to image"
    @hide="closeHandler"
  >
    <template #modal-footer>
      <ButtonPrimary
        variant="primary"
        @click.stop="accept"
      >
        Yes
      </ButtonPrimary>
      <ButtonOutline
        variant="outline-primary"
        @click.stop="closeHandler"
      >
        No
      </ButtonOutline>
    </template>

    <b-form-group>
      <b-form-radio
        v-model="isReplace"
        :value="false"
      >
        Copy before crop
      </b-form-radio>
      <b-form-radio
        v-model="isReplace"
        :value="true"
      >
        Replace image file after crop
      </b-form-radio>
    </b-form-group>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ButtonPrimary from "@frontend/components/common/atoms/ButtonPrimary.vue";
import ButtonOutline from "@frontend/components/common/atoms/ButtonOutline.vue";
import { MUTATIONS } from "@frontend/store/file-browser-product-selector";
import Requests from "@frontend/services/api/file-browser";
import mixinModal from './mixin'
import { MODALS } from "../constants";

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('fileBrowserProductSelector')

export default {
  name: "CropModal",
  components: {ButtonOutline, ButtonPrimary},
  mixins: [
    mixinModal
  ],
  data: () => ({
    modalId: MODALS.CROP,
    isReplace: false
  }),
  computed: {
    ...mapGetters(['selectedItems'])
  },
  methods: {
    ...mapMutations([MUTATIONS.CLEAR_SELECTION]),
    ...mapActions(['fetchList']),
    async accept() {
      try {
        const selectedImagesIds = this.selectedItems.map((item) => item.id);
        await Requests.saveCropImages(selectedImagesIds.join(','), this.isReplace)
        toastr.success('Successfully cropped')
        await this.fetchList();
      } catch (error) {
        console.log(error);
        toastr.error('Something went wrong while cropping.');
      } finally {
        this[MUTATIONS.CLEAR_SELECTION]();
        this.close();
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
