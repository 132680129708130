import BaseElement from "./BaseElement";
import TableBuilder from './index';
import { getFontFaceStyle, groupConnectedCells } from "../helpers";
import {
  ALLOWED_STYLES_TO_APPLY_TO_ALL, 
  APPLY_TO_BORDERS_VALUES,
  APPLY_TO_VALUES,
  BORDER_STYLES,
  BORDERS, CELL_BORDER_STYLE_KEYS,
  CELL_STYLE_KEYS,
  ROW_STYLE_KEYS,
} from "@frontend/group/modules/simple-table-builder/constants";

const DEFAUL_COLUMN_TITLE = 'Column';
const DEFAUL_CELL_VALUE = 'Value';

export default class Table extends BaseElement {

  headers = [];
  
  headerRowStyles = new TableBuilder.Styles();

  rows = [];
  
  constructor() {
    super();

    this.styles.setRules({
      'border-collapse': 'collapse',
      'color': '#000000',
      'height': 'auto'
    });
  }

  setHeaders(headers) {
    this.headers = headers;
  }

  setRows(rows) {
    this.rows = rows;
  }

  setCellsRules(rules) {
    this.rows
      .reduce((acc, row) => [...acc, ...row.cells], [...this.headers])
      .forEach(cell => {
        cell.styles.setRules(rules);
      });
  }

  setImage({ cells, src }) {
    const selectedCells = this.rows
      .reduce((acc, row) => [...acc, ...row.cells], [...this.headers])
      .filter(cell => cells.includes(cell.id));
    
    selectedCells.forEach(cell => {
      cell.setSrc(src);
      cell.setValue('');
    });
  }

  async getSize() {
    const $table = $(await this.toHTML());

    // Create an empty iframe so the css styles from it'sRapid don't affect the metrics of the table
    let $iframe = $('<iframe>', { style: 'position:absolute; visibility:hidden; width:0; height:0; border:none;' });
    const fonts = await this.getTableFonts(); // Contains base64-encoded fonts in <style>

    $iframe.appendTo('body');

    let iframeDoc = $iframe[0].contentDocument || $iframe[0].contentWindow.document;
    
    // Append a new blank <html> and <body> to the iframe
    iframeDoc.open();
    iframeDoc.write('<!DOCTYPE html><html><head></head><body></body></html>');
    iframeDoc.close();

    let $iframeHead = $(iframeDoc.head);
    let $iframeBody = $(iframeDoc.body);

    // Inject font styles into the iframe's head
    $iframeHead.append(fonts);

    // Append the table to the iframe's body
    $iframeBody.append($table);

    // Ensure images load
    await this.waitForImagesToLoad($table);

    // Wait for fonts to be fully applied
    await new Promise((resolve) => setTimeout(resolve, 100)); // Small delay to allow styles to apply

    // Alternative: Ensure all fonts are loaded before measuring
    if (iframeDoc.fonts && iframeDoc.fonts.ready) {
        await iframeDoc.fonts.ready;
    }

    // Reflow trick: Force browser to apply styles before measuring
    $iframeBody[0].offsetHeight;

    // Measure table dimensions inside the iframe
    let width = Math.ceil($table.width());
    let height = Math.ceil($table.height());

    // Clean up
    $iframe.remove();

    return { width, height };
  }

  setDimensions(width, height) {
    this.styles.setRules({
      width: `${width}px`,
      height: height === 'auto' ? height : `${height}px`,
    })
  }
  
  setHeaderStyles(rules = {}) {
    this.headerRowStyles.setRules(rules);
  }

  addColumn(column) {
    this.headers.push(column);
  }

  addRow(row) {
    this.rows.push(row);
  }

  addNewColumn(index = null) {
    index = index ? index : this.headers.length;

    const cell = new TableBuilder.TableCell(DEFAUL_COLUMN_TITLE);
    
    const styles = this.headers[index - 1].getStyles();
    
    cell.styles.setRules(styles)

    this.headers.splice(index, 0, cell);

    for (let i = 0; i < this.rows.length; i++) {
      const cell = new TableBuilder.TableCell(DEFAUL_CELL_VALUE);
      
      const styles = this.rows[i].cells[index - 1].getStyles();

      cell.styles.setRules(styles)
      
      this.rows[i].cells.splice(index, 0, cell);
    }
  }

  addNewRow(index = null) {
    index = index ? index : this.rows.length;

    const row = new TableBuilder.TableRow();

    for (let i = 0; i < this.headers.length; i++) {
      const cell = new TableBuilder.TableCell(DEFAUL_CELL_VALUE);
      
      const styles = this.rows[index - 1].cells[i].getStyles();
      
      cell.styles.setRules(styles)
      
      row.addCell(cell);
    }

    this.rows.splice(index, 0, row);
  }

  removeNewRow(index = null) {
    index = index !== null && index >= 0 
      ? index 
      : this.rows.length - 1;

    this.rows.splice(index, 1);
  }
  
  removeRows(selectedCells = []) {
    if (!selectedCells.length) {
      this.removeNewRow();
      return;
    }
    
    const indicesToRemove = new Set();

    selectedCells.forEach(cell => {
      const index = this.rows.findIndex(row => row.cells.some(_cell => _cell.id === cell.id));
      
      if (index >= 0) {
        indicesToRemove.add(index);
      }
    });
    
    [...indicesToRemove]
      .sort((a, b) => b - a)
      .forEach(index => this.removeNewRow(index));
  }

  removeNewColumn(index = null) {
    index = index !== null && index >= 0
      ? index 
      : this.headers.length - 1;
    
    this.headers.splice(index, 1);

    for (let i = 0; i < this.rows.length; i++) {
      this.rows[i].cells.splice(index, 1);
    }
  }
  
  removeColumns(selectedCells = []) {
    if (!selectedCells.length) {
      this.removeNewColumn();
      return;
    }

    const rows = this.getRows();
    const indicesToRemove = new Set();

    selectedCells.forEach(cell => {
      rows.forEach(row => {
        const index = row.findIndex(_cell => _cell.id === cell.id);
        
        if (index >= 0) {
          indicesToRemove.add(index);
        }
      });
    });
    
    // Delete indices in descending order to avoid displacement
    [...indicesToRemove]
      .sort((a, b) => b - a)
      .forEach(index => this.removeNewColumn(index));
  }

  moveColumnTo(arr, fromIndex, toIndex) {
    if (fromIndex < 0 || toIndex < 0 || fromIndex >= arr.length || toIndex >= arr.length) {
      return;
    }

    const [movedCell] = arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, movedCell);
  }

  moveColumn(action, selectedCell) {
    const rows = this.getRows();
    let fromIndex = null;
    const indices = [];

    rows.forEach(row => {
      const index = row.findIndex(_cell => _cell.id === selectedCell.id);

      if (index >= 0) {
        fromIndex = index;
      }
    });

    rows.forEach(row => {
      const numOfColspan = row.reduce((acc, item) => acc + item.colspan, 0);
      const hasColspan = row.length < numOfColspan;
      const diff = hasColspan ? numOfColspan - row.length : 0;

      const _fromIndex = fromIndex - diff;
      const _toIndex = action === 'left' ? _fromIndex - 1 : _fromIndex + 1;
      
      indices.push({
        fromIndex: _fromIndex,
        toIndex: _toIndex
      });
      
    });
    
    indices.forEach((el, i) => {
      i === 0 
        ? this.moveColumnTo(this.headers, el.fromIndex, el.toIndex) 
        : this.moveColumnTo(this.rows[i - 1].cells, el.fromIndex, el.toIndex);
    });
  }


  createTableGrid(columns = 10, rows = 10) {
    this.headers = [];
    this.rows = [];

    for (let i = 0; i < columns; i++) {
      this.addColumn(new TableBuilder.TableCell(DEFAUL_COLUMN_TITLE));
    }

    for (let i = 0; i < rows; i++) {
      const row = new TableBuilder.TableRow();

      for (let i = 0; i < columns; i++) {
        row.addCell(new TableBuilder.TableCell('Value'));
      }

      this.addRow(row);
    }
    
    const cellsRules = [
      { rule: 'font-family', value: this.styles.rules['font-family'], measure: '' },
      { rule: 'font-size', value: this.styles.rules['font-size'], measure: '' },
      { rule: 'text-align', value: this.styles.rules['text-align'], measure: '' },
      { rule: 'color', value: this.styles.rules['color'], measure: '' },
    ];

    this.changeMultipleCellsStyle(cellsRules, APPLY_TO_VALUES.ALL);
  }

  addCellRow(index = null) {
    index = index ? index : this.headers.length;

    for (let i = 0; i < this.rows.length; i++) {
      const cell = new TableBuilder.TableCell(DEFAUL_CELL_VALUE);
      this.rows[i].cells.splice(index, 0, cell);
    }
  }
  
  addCellHeader(index = null) {
    const cell = new TableBuilder.TableCell(DEFAUL_COLUMN_TITLE);
    this.headers.splice(index, 0, cell);
  }
  
  getStyle(id, key, defaultValue, applyTo = APPLY_TO_BORDERS_VALUES.ALL) {
    switch(true) {
      case CELL_STYLE_KEYS.includes(key):
        return this.getCellStyle(id, key, defaultValue);
      case ROW_STYLE_KEYS.includes(key):
        return this.getRowStyle(id, key, defaultValue);
      case CELL_BORDER_STYLE_KEYS.includes(key):
        return this.getCellBorderStyle(id, key, defaultValue, applyTo);
      default:
        return this.getCellStyle(id, key, defaultValue);
    }
  }
  
  getCellStyle(id, key, defaultValue) {
    const cell = this.rows
      .reduce((acc, row) => [...acc, ...row.cells], [...this.headers])
      .find(cell => cell.id === id);
   
    if (!cell) return defaultValue;

    const value = cell.styles.rules[key];
    const number = parseInt(value);

    return value === undefined ? defaultValue : Number.isNaN(number) ? value : number;
  }
  
  getCellSrc(id) {
    const cell = this.rows
      .reduce((acc, row) => [...acc, ...row.cells], [...this.headers])
      .find(cell => cell.id === id);
    
    if (!cell) return;
    
    return cell.getSrc();
  }

  getRowStyle(id, key, defaultValue) {
    const rowStyles = this.rows
      .find(_row => _row.cells.find(_cell => _cell.id === id))?.styles 
      || this.headerRowStyles;
    
    if (!rowStyles) return defaultValue;
    
    const value = rowStyles.rules[key];
    const number = parseInt(value);

    return value === undefined ? defaultValue : Number.isNaN(number) ? value : number;
  }

  getCellBorderStyle(id, rule, defaultValue, applyTo) {
    const cell = this.rows
      .reduce((acc, row) => [...acc, ...row.cells], [...this.headers])
      .find(cell => cell.id === id);
    
    let value;
    
    switch(applyTo) {
      case APPLY_TO_BORDERS_VALUES.ALL:
      case APPLY_TO_BORDERS_VALUES.INNER:
      case APPLY_TO_BORDERS_VALUES.OUTER:
        value = cell.styles.rules[BORDER_STYLES[rule][BORDERS.TOP]] || cell.styles.rules[rule];
        break;
      case APPLY_TO_BORDERS_VALUES.RIGHT:
        value = cell.styles.rules[BORDER_STYLES[rule][BORDERS.RIGHT]];
        break;
      case APPLY_TO_BORDERS_VALUES.LEFT:
        value = cell.styles.rules[BORDER_STYLES[rule][BORDERS.LEFT]];
        break;
      case APPLY_TO_BORDERS_VALUES.TOP:
        value = cell.styles.rules[BORDER_STYLES[rule][BORDERS.TOP]];
        break;
      case APPLY_TO_BORDERS_VALUES.BOTTOM:
        value = cell.styles.rules[BORDER_STYLES[rule][BORDERS.BOTTOM]];
        break;
      default:
        value = defaultValue;
    }

    const number = parseInt(value);
    
    return value === undefined 
      ? defaultValue 
      : Number.isNaN(number) ? value : number;
  }

  changeRowStyles(params) {
    const rules = params.rules.reduce((acc, param) => ({
      ...acc,
      [param.rule]: `${param.value}${param.measure}`
    }), {});

    if (this.headers.some(cell => params.cells.includes(cell.id))) {
      this.headerRowStyles.setRules(rules);
    }
    
    const rows = this.rows.reduce((acc, _row) => {
      const cellIndex = _row.cells.findIndex(cell => params.cells.includes(cell.id));
      
      if (cellIndex >= 0) {
        return [...acc, _row];
      }
      
      return acc;
    }, []);
    
    rows.forEach(_row => _row.styles.setRules(rules));
  }
  
  changeCellStyle(cellParams) {
    const rules = cellParams.rules.reduce((acc, param) => ({
      ...acc,
      [param.rule]: `${param.value}${param.measure}`
    }), {});
    
    let cells = rules['width'] || rules['min-width'] 
      ? this.getCellsByColumns(cellParams.cells) 
      : this.rows
        .reduce((acc, row) => [...acc, ...row.cells], [...this.headers])
        .filter(cell => cellParams.cells.includes(cell.id));
    
    if (!cells) return;
    
    cells.forEach(cell => {
      cell.styles.setRules(rules);
    });
  }

  changeMultipleCellsStyle(styleParams, applyTo = APPLY_TO_VALUES.ALL, selectedCell = null) {
    const rules = styleParams.reduce((acc, param) => {
      if (!ALLOWED_STYLES_TO_APPLY_TO_ALL.includes(param.rule)) {
        return acc;
      }

      return {
        ...acc,
        [param.rule]: `${param.value}${param.measure}`
      }
    }, {});
    
    let cells;
    
    switch (true) {
      case applyTo === APPLY_TO_VALUES.ALL:
        cells = this.rows.reduce((acc, row) => [...acc, ...row.cells], [...this.headers]);
        break;
      case applyTo === APPLY_TO_VALUES.HEADER:
        cells = this.headers;
        break;
      case applyTo === APPLY_TO_VALUES.BODY:
        cells = this.rows.reduce((acc, row) => [...acc, ...row.cells], []);
        break;
      case applyTo === APPLY_TO_VALUES.COLUMN:
        const rows = this.rows.reduce((acc, row) => [...acc, row.cells], [this.headers]);

        let column = null;
        
        rows.forEach(row => {
          const index = row.findIndex(cell => cell.id === selectedCell.id);

          if (index >= 0) {
            column = index;  
          }
        });
        
        cells = this.rows.reduce((acc, row) => [...acc, row.cells[column]], []);
        
        break;
      case applyTo === APPLY_TO_VALUES.ALTERNATING_ROWS:
        let currentRow = this.rows.findIndex(row => {
          return row.cells.find(cell => cell.id === selectedCell.id);
        });

        // Determine if the odd rows (1) or even (0) should be formatted
        currentRow % 2 === 0 
          ? currentRow = 0 
          : currentRow = 1;
        
        cells = this.rows.reduce((acc, row, index) => {
          if (index === currentRow) {
            currentRow = currentRow + 2;
            
            return [...acc, ...row.cells];
          }
          
          return acc;
        }, []);
        
        break;
      default:
        cells = this.rows.reduce((acc, row) => [...acc, ...row.cells], [...this.headers]);
    }
    
    cells.forEach(cell => {
      cell.styles.setRules(rules);
    });
  }

  changeCellBorderStyle({ cells, applyTo, handler, styleRule }) {
    let rows;
    
    switch (true) {
      case applyTo === APPLY_TO_VALUES.SELECTED:
        rows = this.getRows()
          .map(row => row.filter(cell => cells.includes(cell.id)))
          .filter(row => row.length);
        break;
      case applyTo === APPLY_TO_VALUES.ALL:
        rows = this.getRows();
        break;
      case applyTo === APPLY_TO_VALUES.HEADER:
        rows = [this.headers];
        break;
      case applyTo === APPLY_TO_VALUES.BODY:
        rows = this.rows.reduce((acc, row) => ([...acc, row.cells]), []);
        break;
      case applyTo === APPLY_TO_VALUES.COLUMN:
        let column = null;

        this.rows.forEach(row => {
          const index = row.cells.findIndex(cell => cell.id === cells[0]);

          if (index >= 0) {
            column = index;
          }
        });

        rows = this.rows.reduce((acc, row) => [...acc, [row.cells[column]]], []);
        break;
      case applyTo === APPLY_TO_VALUES.ALTERNATING_ROWS:
        let currentRow = this.rows.findIndex(row => {
          return row.cells.find(cell => cell.id === cells[0]);
        });

        // Determine if the odd rows (1) or even (0) should be formatted
        currentRow % 2 === 0
          ? currentRow = 0
          : currentRow = 1;

        rows = this.rows.reduce((acc, row, index) => {
          if (index === currentRow) {
            currentRow = currentRow + 2;

            return [...acc, row.cells];
          }

          return acc;
        }, []);

        break;
      default:
        rows = this.getRows()
          .map(row => row.filter(cell => cells.includes(cell.id)))
          .filter(row => row.length);
    }
    
    this[handler](rows, styleRule);
  }
  
  setOuterBorders(rows, { rule, value, measure }) {
    const allRows = this.getRows();
    
    const groupedCells = groupConnectedCells(allRows, rows);
    
    const _value = value + measure;
    
    groupedCells.forEach(group => {
      group.forEach((row, index) => {
        row.forEach((cell, index, arr) => {
          if (index === 0) {
            cell.styles.setRules({
              [BORDER_STYLES[rule][BORDERS.LEFT]]: _value,
            });
          }
          
          if (index === arr.length - 1) {
            cell.styles.setRules({
              [BORDER_STYLES[rule][BORDERS.RIGHT]]: _value
            });
          }

          if (this.headers.find(headerCell => headerCell.id === cell.id)) {
            cell.styles.setRules({
              [BORDER_STYLES[rule][BORDERS.TOP]]: _value
            });
          }
        });

        if (index === 0) {
          row.forEach(cell => cell.styles.setRules({
            [BORDER_STYLES[rule][BORDERS.TOP]]: _value
          }));
        }

        if (index === group.length - 1) {
          row.forEach(cell => cell.styles.setRules({
            [BORDER_STYLES[rule][BORDERS.BOTTOM]]: _value
          }));
        }
      });
    });

    this.changeNeighborsCellsStyle(rows.flatMap(row => row.map(cell => cell.id)), { rule, value, measure });
  }
  
  setInnerBorders(rows, { rule, value, measure }) {
    const _value = value + measure;
    
    rows.forEach((row, index) => {
      row.forEach((cell, index, array) => {
        if (index !== array.length - 1) {
          cell.styles.setRules({
            [BORDER_STYLES[rule][BORDERS.RIGHT]]: _value
          });
        }

        if (index > 0) {
          cell.styles.setRules({
            [BORDER_STYLES[rule][BORDERS.LEFT]]: _value
          });
        }
      });

      if (index !== rows.length - 1) {
        row.forEach(cell => cell.styles.setRules({
          [BORDER_STYLES[rule][BORDERS.BOTTOM]]: _value
        }));
      }

      if (index > 0) {
        row.forEach(cell => cell.styles.setRules({
          [BORDER_STYLES[rule][BORDERS.TOP]]: _value
        }));
      }
    });
  }
  
  setAllBorders(rows, { rule, value, measure }) {
    const _value = value + measure;
    
    rows.forEach(row => {
      row.forEach(cell => {
        cell.styles.setRules({
          [BORDER_STYLES[rule][BORDERS.TOP]]: _value,
          [BORDER_STYLES[rule][BORDERS.RIGHT]]: _value,
          [BORDER_STYLES[rule][BORDERS.BOTTOM]]: _value,
          [BORDER_STYLES[rule][BORDERS.LEFT]]: _value,
        });
      });
    });
    
    this.changeNeighborsCellsStyle(rows.flatMap(row => row.map(cell => cell.id)), { rule, value, measure });
  }

  setOneBorder(rows, { rule, value, measure, applyToBorder }) {
    const tableRows = this.getRows();
    const _value = value + measure;
    
    rows.forEach(row => {
      row.forEach(cell => {
        cell.styles.setRules({
          [BORDER_STYLES[rule][applyToBorder]]: _value,
        });

        const rowIndex = tableRows.findIndex(row => row.find(_cell => _cell.id === cell.id));
        const cellIndex = tableRows[rowIndex].findIndex(_cell => _cell.id === cell.id);
        
        switch (true) {
          case applyToBorder === BORDERS.LEFT:
            if (tableRows[rowIndex][cellIndex - 1]) {
              tableRows[rowIndex][cellIndex - 1].styles.setRules({
                [BORDER_STYLES[rule][BORDERS.RIGHT]]: _value,
              })
            }
            break;
          case applyToBorder === BORDERS.RIGHT:
            if (tableRows[rowIndex][cellIndex + 1]) {
              tableRows[rowIndex][cellIndex + 1].styles.setRules({
                [BORDER_STYLES[rule][BORDERS.LEFT]]: _value,
              })
            }
            break;
          case applyToBorder === BORDERS.TOP:
            if (
              tableRows[rowIndex - 1] 
              && tableRows[rowIndex - 1].length === tableRows[rowIndex].length
              && tableRows[rowIndex - 1][cellIndex]
            ) {
              tableRows[rowIndex - 1][cellIndex].styles.setRules({
                [BORDER_STYLES[rule][BORDERS.BOTTOM]]: _value,
              })
            }
            break;
          case applyToBorder === BORDERS.BOTTOM:
            if (
              tableRows[rowIndex + 1] 
              && tableRows[rowIndex + 1].length === tableRows[rowIndex].length
              && tableRows[rowIndex + 1][cellIndex]
            ) {
              tableRows[rowIndex + 1][cellIndex].styles.setRules({
                [BORDER_STYLES[rule][BORDERS.TOP]]: _value,
              })
            }
            break;
          default:
            if (tableRows[rowIndex + 1][cellIndex]) {
              tableRows[rowIndex + 1][cellIndex].styles.setRules({
                [BORDER_STYLES[rule][BORDERS.TOP]]: _value,
              })
            }
            break;
        }
      });
    });
  }
  
  changeNeighborsCellsStyle(selectedCells, { rule, value, measure }) {
    const rows = this.getRows();
    const _value = value + measure;
    
    selectedCells.forEach(cell => {
      let rowIndex = null;
      let cellIndex = null;

      rows.forEach((_row, i) => {
        const index = _row.findIndex(_cell => _cell.id === cell);

        if (index >= 0) {
          rowIndex = i;
          cellIndex = index;
        }
      });
      
      // Left neighbor cell
      if (cellIndex > 0 && rows[rowIndex][cellIndex - 1] && !selectedCells.includes(rows[rowIndex][cellIndex - 1].id)) {
        rows[rowIndex][cellIndex - 1].styles.setRules({
          [BORDER_STYLES[rule][BORDERS.RIGHT]]: _value
        });
      }

      // Right neighbor cell
      if (
        cellIndex < rows[rowIndex].length - 1 
        && rows[rowIndex][cellIndex + 1] 
        && !selectedCells.includes(rows[rowIndex][cellIndex + 1].id)
      ) {
        rows[rowIndex][cellIndex + 1].styles.setRules({
          [BORDER_STYLES[rule][BORDERS.LEFT]]: _value
        });
      }

      // Top neighbor cell
      if (rowIndex > 0 && rows[rowIndex - 1][cellIndex] && !selectedCells.includes(rows[rowIndex - 1][cellIndex].id)) {
        rows[rowIndex - 1][cellIndex].styles.setRules({
          [BORDER_STYLES[rule][BORDERS.BOTTOM]]: _value
        });
      }

      // Bottom neighbor cell
      if (
        rowIndex < rows.length - 1 
        && rows[rowIndex + 1][cellIndex] 
        && !selectedCells.includes(rows[rowIndex + 1][cellIndex].id)
      ) {
        rows[rowIndex + 1][cellIndex].styles.setRules({
          [BORDER_STYLES[rule][BORDERS.TOP]]: _value
        });
      }
    });
  }
  
  changeCellValue(selectedCell) {
    const cell = this.rows
      .reduce((acc, row) => [...acc, ...row.cells], [...this.headers])
      .find(_cell => _cell.id === selectedCell.id);

    const row = this.getRow(cell);
    this.updateRowBasedOnColspan(row, cell, selectedCell.colspan);
    
    cell.setValue(selectedCell.value);
    cell.setSrc('');
  }
  
  updateRowBasedOnColspan(row, cell, _colspan) {
    if (_colspan < 1) return;
    
    const cellColspan = Number(cell.colspan);
    let colspan = Number(_colspan);
    const cellIndex = row.findIndex(_cell => _cell.id === cell.id);
    
    let availableColspanForCell = cellColspan;
    
    for (let i = cellIndex + 1; i < row.length; i++) {
      if (row[i].colspan > 1) break;
      if (row[i].colspan === 1) availableColspanForCell++;
    }
    
    if (cellColspan < colspan) {
      if (colspan > availableColspanForCell) {
        colspan = availableColspanForCell;
      }

      const diff = colspan - cellColspan;

      row.splice(cellIndex + 1, diff);
      
    } else if (colspan < cellColspan) {
      const diff = cellColspan - colspan;

      for (let i = 0; i < diff; i++) {
        const styles = cell.styles.getStyles();
        const newCell = new TableBuilder.TableCell();
        newCell.styles.setRules(styles);

        row.splice(cellIndex + 1, 0, newCell);
      }
    }

    cell.setColspan(colspan);
  }

  async toHTML() {
    const headRows = await Promise.all(this.headers.map(async column => await column.toHTML()))
    const bodyRows = await Promise.all(this.rows.map(async row => await row.toHTML()))
    
    const head = `<thead><tr ${this.getHeaderStyleAttribute()}>${headRows.join('')}</tr></thead>`;
    const body = `<tbody>${bodyRows.join('')}</tbody>`;

    return `<table ${this.getStyleAttribute()}>${head}${body}</table>`;
  }

  async toSVG() {
    const fonts = await this.getTableFonts();
    const size = await this.getSize();
    let table = await this.toHTML();

    // Escape special characters only inside HTML tags
    table = table.replace(/(>)([^<]+)(<)/g, (match, open, content, close) => {
      // Only replace special characters inside the content between tags
      const escapedContent = content
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#39;");
      return `${open}${escapedContent}${close}`;
    });


    const div = `<div xmlns="http://www.w3.org/1999/xhtml">${fonts}${table}</div>`;
    const foreignObject = `<foreignObject width="100%" height="100%">${div}</foreignObject>`;

    return `<svg xmlns="http://www.w3.org/2000/svg" width="${size.width + 2}" height="${size.height + 2}">${foreignObject}</svg>`;
  }

  toData() {
    return {
      id: this.id,
      stringStyles: this.styles.getStyle(),
      styles: this.styles,
      headers: this.headers.map(column => column.toData()),
      rows: this.rows.map(rows => rows.toData()),
      headerRowStyles: this.headerRowStyles
    }
  }

  async toBlob() {
    const data = await this.toSVG();

    return new Blob([data], {
      type: "image/svg+xml;charset=utf-8"
    });
  }

  async getDimension() {
    const $table = $(await this.toHTML());

    // Create an empty iframe so the css styles from it'sRapid don't affect the metrics of the table
    let $iframe = $('<iframe>', { style: 'position:absolute; visibility:hidden; width:0; height:0; border:none;' });
    const fonts = await this.getTableFonts(); // Contains base64-encoded fonts in <style>

    $iframe.appendTo('body');

    let iframeDoc = $iframe[0].contentDocument || $iframe[0].contentWindow.document;
    
    // Append a new blank <html> and <body> to the iframe
    iframeDoc.open();
    iframeDoc.write('<!DOCTYPE html><html><head></head><body></body></html>');
    iframeDoc.close();

    let $iframeHead = $(iframeDoc.head);
    let $iframeBody = $(iframeDoc.body);

    // Inject font styles into the iframe's head
    $iframeHead.append(fonts);

    // Append the table to the iframe's body
    $iframeBody.append($table);

    // Ensure images load
    await this.waitForImagesToLoad($table);

    // Wait for fonts to be fully applied
    await new Promise((resolve) => setTimeout(resolve, 100)); // Small delay to allow styles to apply

    // Alternative: Ensure all fonts are loaded before measuring
    if (iframeDoc.fonts && iframeDoc.fonts.ready) {
        await iframeDoc.fonts.ready;
    }

    // Reflow trick: Force browser to apply styles before measuring
    $iframeBody[0].offsetHeight;

    // Measure table dimensions inside the iframe
    let width = Math.ceil($table.width());
    let height = Math.ceil($table.height());

    // Clean up
    $iframe.remove();

    return { width, height };
  }

  waitForImagesToLoad(table) {
    return new Promise(resolve => {
      const images = table.find('img').toArray();
      let loaded = 0;

      const handleImageLoading = () => {
        loaded++;
        if (images.length === loaded) resolve();
      }
      
      if (images.length === 0) {
        resolve();
      }
      
      images.forEach(img => {
        if (img.complete) {
          handleImageLoading();
        } else {
          $(img).on("load", () => {
            handleImageLoading();
          });

          $(img).on("error", () => {
            console.log('An error occurred while loading the table image.');
            
            handleImageLoading();
          });
        }
      })
    });
  }

  async getOuterDimension() {
    const $table = $(await this.toHTML());

    // Create an empty iframe so the css styles from it'sRapid don't affect the metrics of the table
    let $iframe = $('<iframe>', { style: 'position:absolute; visibility:hidden; width:0; height:0; border:none;' });
    const fonts = await this.getTableFonts(); // Contains base64-encoded fonts in <style>

    $iframe.appendTo('body');

    let iframeDoc = $iframe[0].contentDocument || $iframe[0].contentWindow.document;
    
    // Append a new blank <html> and <body> to the iframe
    iframeDoc.open();
    iframeDoc.write('<!DOCTYPE html><html><head></head><body></body></html>');
    iframeDoc.close();

    let $iframeHead = $(iframeDoc.head);
    let $iframeBody = $(iframeDoc.body);

    // Inject font styles into the iframe's head
    $iframeHead.append(fonts);

    // Append the table to the iframe's body
    $iframeBody.append($table);

    // Ensure images load
    await this.waitForImagesToLoad($table);

    // Wait for fonts to be fully applied
    await new Promise((resolve) => setTimeout(resolve, 100)); // Small delay to allow styles to apply

    // Alternative: Ensure all fonts are loaded before measuring
    if (iframeDoc.fonts && iframeDoc.fonts.ready) {
        await iframeDoc.fonts.ready;
    }

    // Reflow trick: Force browser to apply styles before measuring
    $iframeBody[0].offsetHeight;

    // Measure table dimensions inside the iframe
    let width = $table.outerWidth();
    let height = $table.outerHeight();

    // Clean up
    $iframe.remove();

    return { width, height };
  }

  getRow(cell) {
    if (this.headers.find(_cell => _cell.id === cell.id)) {
      return this.headers;
    }

    return this.rows.find(row => {
      return row.cells.find(_cell => _cell.id === cell.id);
    }).cells;
  }
  
  getRows() {
    return this.rows.reduce((acc, row) => ([...acc, row.cells]), [this.headers]);
  }
  
  getCellsByColumns(selectedCells) {
    const rows = this.rows.reduce((acc, row) => [...acc, row.cells], [this.headers]);

    const columns = [];

    selectedCells.forEach(selectedCell => {
      rows.forEach(row => {
        const index = row.findIndex(cell => cell.id === selectedCell);

        if (index >= 0 && !columns.includes(index)) {
          columns.push(index);
        }
      });
    });

    return rows.reduce((acc, row) => {
      const filteredRow = row.filter((cell, index) => columns.includes(index));

      return [
        ...acc,
        ...filteredRow
      ]
    }, []);
  }
  
  async getTableFonts() {
    const fonts = [this.styles.getRule('font-family')];
    
    const cells = this.rows.reduce((acc, row) => ([...acc, ...row.cells]), [...this.headers]);
    
    cells.forEach(cell => {
      const fontFamily = cell.styles.getRule('font-family');
      
      if (!fonts.includes(fontFamily) && fontFamily) {
        fonts.push(fontFamily);
      }
    });
    
    return `<style>${await getFontFaceStyle(fonts)}</style>`;
  }

  getHeaderStyleAttribute() {
    const styles = this.headerRowStyles.getStyle();

    return styles ? `style="${styles}"` : '';
  }

  getSumOfColumnWidths() {
    return this.headers.reduce((acc, column) => {
      const width = column.styles.getRule('width') 
        ? parseInt(column.styles.getRule('width')) || 0
        : 0;

      const minWidth = column.styles.getRule('min-width')
        ? parseInt(column.styles.getRule('min-width')) || 0
        : 0;
      
      return acc + Math.max(width, minWidth);
    }, 0);
  }

  getColumnStyles(selectedCell) {
    return this.getCellsByColumns([selectedCell.id]).map(cell => cell.getStyles());
  }

  applyColumnStylesToColumn(selectedCell, columnStyles) {
    const cellsByColumn = this.getCellsByColumns([selectedCell.id]);
    
    cellsByColumn.forEach((cell, i) => {
      cell.styles.setRules(columnStyles[i]);
    });
  }
}
