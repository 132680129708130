<template>
  <b-modal
    id="compression-settings-modal"
    title="Compression Settings"
    @ok="handleOk"
    @cancel="handleCancel"
    @show="fetchCompressSettings"
  >
    <div
      v-if="isCompressSettingsFetching"
      class="justify-content-center align-items-center loading"
      style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; background: #ffffffb0; z-index: 999; display: flex;"
    >
      <div
        class="spinner-border text-primary"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <b-form>
      <div class="form-group">
        <b-form-group 
          size="lg" 
          label="Quality:" 
          label-for="quality-slider"
          label-size="lg"
          label-cols="2"
        >
          <b-form-input
            id="quality-slider"
            v-model="fileSize"
            type="range"
            :min="10"
            :max="fileSizeKB"
            :step="1"
            tooltip="always"
            class="w-100"
            @change="onFileSizeChange"
          />
          <div 
            class="d-flex justify-content-between px-1"
          >
            <small>Least</small>
            <small>Best</small>
          </div>
        </b-form-group>
        <div
          style="
            font-size: 1.25rem;
            line-height: 1.5;
          "
        >
          File Size: {{ fileSize }} KB
        </div>
      </div>
      <b-form-checkbox
        v-model="isCompressSettingsReplaceOriginalImage"
        :value="true"
        :unchecked-value="false"
      >
        Replace original image
      </b-form-checkbox>
    </b-form>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { MUTATIONS } from '../../../../store/file-browser'
import { SETTINGS_MODAL_FIELDS_NAME } from './settings-modal/fields'

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('fileBrowser')

export default {
  name: 'CompressionSettingsModal',
  data: () => ({
    fileSize: 280,
  }),
  computed: {
    ...mapGetters([
      'isCompressSettingsFetching',
      'compressSettingsData',
      'selectedItems',
      'firstSelectedItem'
    ]),
    isCompressSettingsReplaceOriginalImage: {
      get() {
        return this.$store.getters['fileBrowser/isCompressSettingsReplaceOriginalImage'];
      },
      set(value) {
        this.$store.commit('fileBrowser/' + MUTATIONS.COMPRESS_SETTINGS.SET_REPLACE_ORIGINAL_IMAGE, value);
      }
    },
    fileSizeKB () {
      return (this.firstSelectedItem.file_size / 1000).toFixed(0)
    },
    SETTINGS_MODAL_FIELDS_NAME() {
      return SETTINGS_MODAL_FIELDS_NAME
    },
  },
  watch: {
    firstSelectedItem() {
      this.fileSize = this.fileSizeKB;
    }
  },
  mounted() {
    // Initialize tooltips
    $(this.$el).find('[data-toggle="tooltip"]').tooltip()
    this.fileSize = this.fileSizeKB;
  },
  beforeDestroy() {
    // Clean up tooltips
    $(this.$el).find('[data-toggle="tooltip"]').tooltip('dispose')
  },
  methods: {
    ...mapActions(['fetchCompressSettings']),
    ...mapMutations([MUTATIONS.COMPRESS_SETTINGS.SET_DATA_KEY]),
    onFileSizeChange() {
      this.filedHandler(SETTINGS_MODAL_FIELDS_NAME.image_compress_max_size_webp, this.fileSize)
      this.filedHandler(SETTINGS_MODAL_FIELDS_NAME.image_compress_quality, 100);
    },
    filedHandler(key, value) {
      this[MUTATIONS.COMPRESS_SETTINGS.SET_DATA_KEY]({ key, value })
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$root.$emit(
          'bv::modal::ok',
          {settings: this.compressSettingsData, replaceOriginalImage: this.isCompressSettingsReplaceOriginalImage}
      )
      this.$bvModal.hide('compression-settings-modal')
    },
    handleCancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>
.input-label {
  font-weight: 500;
}
#quality-slider {
  margin-top: 13px;
}
</style> 
