<template>
  <div
    style="padding: 0 10px; max-height: 600px; overflow-y: auto; overflow-x: hidden;"
  >
    <!--    Text rows-->
    <b-card bg-variant="light">
      <b-form-row>
        <b-col>
          <b-form-group
            label="Text"
          >
            <b-form-input
              v-model="value"
              type="text"
              @input="updateResultData"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="8">
          <b-form-group
            label="Font"
          >
            <FontSelector v-model="fontFamily" />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Font size"
          >
            <b-form-input
              v-model="fontSize"
              type="number"
              @input="updateResultData"
            />
          </b-form-group>
        </b-col>
        <b-col class="d-flex justify-content-center mt-1">
          <b-form-group label="Color">
            <ColorPicker v-model="fontColor" />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
            label="Text Decoration"
          >
            <b-form-select
              v-model="textDecoration"
              :options="textDecorationOptions"
              class="form-control"
              @change="updateResultData"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
            label="Text Horizontal position"
          >
            <b-form-select
              v-model="horizontalPosition"
              :options="horizontalPositionOptions"
              class="form-control"
              @change="updateResultData"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Text Vertical position">
            <b-form-select
              v-model="verticalPosition"
              :options="verticalPositionOptions"
              class="form-control"
              @change="updateResultData"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
            label="Text Horizontal offset"
          >
            <b-form-input
              v-model="horizontalOffset"
              type="number"
              @input="updateResultData"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Text Vertical offset">
            <b-form-input
              v-model="verticalOffset"
              type="number"
              @input="updateResultData"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import FontSelector from "@frontend/components/modules/layouts-page/EditorControl/components/FontSelector.vue";
import ColorPicker from "@frontend/components/modules/layouts-page/EditorControl/components/ColorPicker.vue";

const horizontalPositionOptions = ['Left', 'Center', 'Right'];
const verticalPositionOptions = ['Top', 'Center', 'Bottom'];
const textDecorationOptions = [
  { text: 'None', value: 'none' }, 
  { text: 'Underline', value: 'underline' }, 
  { text: 'Line through', value: 'lineThrough' },
];

export default {
  name: 'ExportPageFooterText',
  components: {ColorPicker, FontSelector},
  props: {
    text: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      value: '',
      fontFamily: 'Arial',
      fontColor: '#000',
      fontSize: 50,
      textDecoration: 'none',
      horizontalPosition: 'Left',
      verticalPosition: 'Center',
      horizontalOffset: 100,
      verticalOffset: 0,
      resultData: {}
    };
  },
  computed: {
    horizontalPositionOptions() {
      return horizontalPositionOptions.map(option => {
        return {text: option, value: option};
      });
    },
    verticalPositionOptions() {
      return verticalPositionOptions.map(option => {
        return {text: option, value: option};
      });
    },
    textDecorationOptions() {
      return textDecorationOptions.map(option => {
        return {text: option.text, value: option.value};
      });
    }
  },
  watch: {
    text: {
      handler(newVal) {
        if (newVal !== null && JSON.stringify(newVal) !== JSON.stringify(this.resultData)) {
          Object.assign(this, newVal);
          this.updateResultData();
        }
      },
      deep: true,
      immediate: true
    },
    fontColor: function() {
      this.updateResultData();
    },
    fontFamily: function() {
      this.updateResultData();
    },
    resultData: function (newResultData) {
      this.$emit('data-updated', newResultData);
    },
  },
  methods: {
    updateResultData() {
      this.resultData = {
        value: this.value,
        fontFamily: this.fontFamily,
        fontColor: this.fontColor,
        textDecoration: this.textDecoration,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        fontSize: +this.fontSize,
        horizontalOffset: +this.horizontalOffset,
        verticalOffset: +this.verticalOffset,
      };
    },
  }
}
</script>

<style scoped>
.fas:hover {
  color: black !important;
}
</style>
