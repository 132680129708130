<template>
  <div class="progress-bar">
    <div
      class="progress-bar__fill"
      :style="{ width: isNaN((current / total) * 100) ? '0%' : `${(current / total) * 100}%` }"
    >
      <div class="progress-bar__highlight" />
    </div>
    <span class="progress-bar__text">{{ current }} / {{ total }}</span>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      required: true,
      validator: value => value > 0,
    },
    current: {
      type: Number,
      required: true,
      validator: value => value >= 0,
    },
  },
};
</script>

<style scoped>
.progress-bar {
  width: 100%;
  height: 25px;
  background-color: #e0e0e0;
  border-radius: 12.5px;
  overflow: hidden;
  position: relative;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.progress-bar__fill {
  height: 100%;
  background: linear-gradient(90deg, #4caf50, #81c784);
  position: relative;
  transition: width 0.5s ease-out;
  width: 0%; /* Установим начальную ширину */
}

.progress-bar__highlight {
  width: 15px;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.5s ease-out;
}

.progress-bar__fill .progress-bar__highlight {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.progress-bar__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
</style>
