<template>
  <div
    style="padding: 0 10px; max-height: 600px; overflow-y: auto; overflow-x: hidden;"
  >
    <b-form-row>
      <b-col>
        <b-form-group
          label="Enabled"
        >
          <b-form-select
            v-model="isEnabled"
            :options="isEnabledOptions"
            class="form-control"
          />
        </b-form-group>
      </b-col>
    </b-form-row>
    <div v-if="isEnabled">
      <PageNumberUnderlyingShape
        :underlying-shape="underlyingShape"
        @data-updated="onUnderlyingShapeUpdate($event)"
      />
      <b-card bg-variant="light">
        <b-form-row>
          <b-col cols="8">
            <b-form-group
              label="Number Font"
            >
              <FontSelector v-model="fontFamily" />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Number Font size"
            >
              <b-form-input
                v-model="fontSize"
                type="number"
                @input="updateResultData"
              />
            </b-form-group>
          </b-col>
          <b-col class="d-flex justify-content-center mt-1">
            <b-form-group label="Color">
              <ColorPicker v-model="fontColor" />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
              label="Number horizontal position"
            >
              <b-form-select
                v-model="horizontalPosition"
                :options="horizontalPositionOptions"
                class="form-control"
                @change="updateResultData"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Number vertical position">
              <b-form-select
                v-model="verticalPosition"
                :options="verticalPositionOptions"
                class="form-control"
                @change="updateResultData"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              label="Mirror"
            >
              <b-form-select
                v-model="mirrorType"
                :options="mirrorOptions"
                class="form-control"
                @change="updateResultData"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
              label="Number horizontal offset"
            >
              <b-form-input
                v-model="horizontalOffset"
                type="number"
                @input="updateResultData"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Number vertical offset">
              <b-form-input
                v-model="verticalOffset"
                type="number"
                @input="updateResultData"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Start from">
              <b-form-input
                v-model="startFromPage"
                type="number"
                @input="updateResultData"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import FontSelector from "@frontend/components/modules/layouts-page/EditorControl/components/FontSelector.vue";
import ColorPicker from "@frontend/components/modules/layouts-page/EditorControl/components/ColorPicker.vue";
import PageNumberUnderlyingShape from "@frontend/components/modules/layouts-page/Export/PageNumberUnderlyingShape.vue";

const isEnabledOptions = [{ text: 'Yes', value: true }, { text: 'No', value: false }];
const horizontalPositionOptions = ['Left', 'Center', 'Right'];
const mirrorOptions = [
  { text: 'Disabled', value: 'None' },
  { text: 'Even page numbers', value: 'Even'},
  { text: 'Odd page numbers', value: 'Odd'}
];
const verticalPositionOptions = ['Top', 'Center', 'Bottom'];

export default {
  name: 'ExportPageNumerationTab',
  components: {PageNumberUnderlyingShape, ColorPicker, FontSelector},
  props: {
    pageNumeration: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isEnabled: false,
      mirrorType: '',
      horizontalOffset: 0,
      verticalOffset: 0,
      fontFamily: '',
      fontSize: 0,
      fontColor: '',
      horizontalPosition: '',
      verticalPosition: '',
      startFromPage: 1,
      underlyingShape: {
        isEnabled: false,
        shapeType: 'Image',
        image: {
          url: '',
          width: 0,
          height: 0,
          horizontalPosition: '',
          verticalPosition: '',
          horizontalOffset: 0,
          verticalOffset: 0,
        }
      },
      resultData: {}
    };
  },
  computed: {
    horizontalPositionOptions() {
      return horizontalPositionOptions.map(option => {
        return { text: option, value: option };
      });
    },
    mirrorOptions() {
      return mirrorOptions;
    },
    verticalPositionOptions() {
      return verticalPositionOptions.map(option => {
        return { text: option, value: option };
      });
    },
    isEnabledOptions() {
      return isEnabledOptions;
    },
  },
  watch: {
    pageNumeration: {
      handler(newVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(this.resultData)) {
          Object.assign(this, newVal);
          this.updateResultData();
        }
      },
      deep: true,
      immediate: true
    },
    isEnabled: function() {
      this.updateResultData();
    },
    fontFamily: function() {
      this.updateResultData();
    },
    fontColor: function() {
      this.updateResultData();
    },
    resultData: function(newResultData) {
      this.$emit('data-updated', newResultData);
    },
  },
  methods: {
    onUnderlyingShapeUpdate(newUnderlyingShape) {
      this.underlyingShape = {
        ...newUnderlyingShape,
        image: {...newUnderlyingShape.image}
      }
      this.updateResultData();
    },
    updateResultData() {
      this.resultData = {
        isEnabled: this.isEnabled,
        fontFamily: this.fontFamily,
        fontSize: this.fontSize,
        fontColor: this.fontColor,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        mirrorType: this.mirrorType,
        horizontalOffset: this.horizontalOffset,
        verticalOffset: this.verticalOffset,
        startFromPage: this.startFromPage,
        underlyingShape: {
          ...this.underlyingShape, 
          image: {...this.underlyingShape.image}
        },
      };
    },
  }
}
</script>

<style scoped>
.fas:hover {
  color: black !important;
}
</style>
