<template>
  <div
    :class="{ 'd-none': hidden, 'd-flex': !hidden }"
    class="flex-column flex-grow-1"
    style="height: calc(100vh - 132px);"
  >
    <div
      id="ai-chat-messages"
      ref="messages"
      class="flex-grow-1 card mb-2"
    >
      <div class="card-body">
        <div v-if="messages.length">
          <div
            v-for="(message, index) in messages"
            :key="index"
            :class="['message', `message-${message.role}`]"
          >
            <p v-html="message.content" />
            <img
              v-if="message.imageUrl"
              class="mt-2"
              :src="message.imageUrl"
              style="width: 200px; height: auto"
            >
            <div
              v-if="message.proofLink"
              class="input-group mt-2"
            >
              <input
                :id="`proof-link-${index}`"
                type="text"
                class="form-control"
                :value="message.proofLink"
                readonly
              >
              <button
                class="btn btn-primary"
                @click="copyProofLink(`proof-link-${index}`)"
              >
                <i
                  class="fa fa-clipboard"
                  aria-hidden="true"
                />
                Copy
              </button>
            </div>
            <button
              v-if="message.hasSelectButton"
              class="btn btn-primary mt-2"
              @click="selectProduct(message.productId)"
            >
              Select this product
            </button>
          </div>
        </div>
        <div
          v-else
          class="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
        >
          <p class="h5">
            How can I assist you?
          </p>
          <p class="text-muted">
            I can find elements, edit text, and more.
          </p>
        </div>
      </div>
    </div>
    <div class="flex-shrink-0 input-group mt-2">
      <input
        v-model="userInput"
        class="form-control"
        placeholder="Type your message..."
        type="text"
        :disabled="loading"
        @keyup.enter="sendMessage"
      >
      <button
        class="btn btn-primary ml-2"
        :disabled="loading"
        type="button"
        @click="sendMessage"
      >
        Send
      </button>
    </div>
    <div
      v-if="loading"
      class="position-absolute w-100 h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    editorControlEventBroker,
    EVENTS,
} from "@frontend/group/modules/editor-control-popup/event-broker";
import {
    HORIZONTAL_GUIDE_LINE_PREFIX_ID,
    VERTICAL_GUIDE_LINE_PREFIX_ID,
} from "@frontend/constants/templates-field-prefixes";
import { GLOBAL_UPDATE_LOCAL_STORAGE_KEY } from "@frontend/constants/global-update";
import selectedTemplateForApplyToAll from "@frontend/group/modules/editor-control-popup/selected-template-for-apply-to-all";
import aiChatFunctions from "@frontend/components/modules/layouts-page/aiChatFunctions";

export default {
    name: "AiEditorTab",
    props: {
        targetElement: {
            type: fabric.Object,
            require: true,
            default: () => {},
        },
        isTabActive: {
            type: Boolean,
            default: false,
        },
        hidden: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        messages: [],
        userInput: "",
        loading: false,
        layersOrdered: [],
    }),
    computed: {
        ...mapGetters("layout", ["layers", "target", "selectedInstance"]),
    },
    watch: {
        isTabActive() {
            this.rerenderList();
        },
    },
    methods: {
        getFonts() {
            const fonts = [];

            const fontsList = fontFamilyManager.getFontList() || {};

            for (const key in fontsList) {
                const font = fontsList[key];
                const selectedFontFamily = this.value;

                if (Array.isArray(font)) {
                    fonts.push({
                        name: key,
                        value: font.includes(selectedFontFamily)
                            ? selectedFontFamily
                            : font[0],
                    });
                } else {
                    fonts.push({
                        name: font,
                        value: key,
                    });
                }
            }

            return fonts;
        },
        async callOpenAI(settings) {
            const { data } = await axios.post(`/call-open-ai`, settings);
            return data;
        },
        setActiveObject(object) {
            if (!object) {
                return;
            }
            this.selectedInstance.canvas.setActiveObject(object);
            this.selectedInstance.canvas.renderAll();
        },
        reorderLayers(instanceId) {
            const usersFieldsOrder = JSON.parse(
                this.selectedInstance.data.users_fields_order || null,
            );
            this.selectedInstance.canvas.fire("layers:update", instanceId);
            if (usersFieldsOrder) {
                this.layersOrdered = _.sortBy(this.layers, (layer) =>
                    usersFieldsOrder.findIndex((id) => id === layer.id),
                );
                this.moveRulerGuideLinesToFront();
                return;
            }
            this.layersOrdered = this.layers;
            this.moveRulerGuideLinesToFront();
        },
        rerenderList() {
            if (this.isTabActive) {
                this.reorderLayers(this.selectedInstance.id);
            }
        },
        moveRulerGuideLinesToFront() {
            this.selectedInstance.canvas
                .getObjects()
                .filter((obj) =>
                    [
                        HORIZONTAL_GUIDE_LINE_PREFIX_ID,
                        VERTICAL_GUIDE_LINE_PREFIX_ID,
                    ].some((id) => obj.id.startsWith(id)),
                )
                .forEach((obj) => obj.bringToFront());
        },
        async getImageDimensions(blob) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                const url = URL.createObjectURL(blob);

                img.onload = () => {
                    URL.revokeObjectURL(url);
                    resolve({ width: img.width, height: img.height });
                };

                img.onerror = () => {
                    URL.revokeObjectURL(url);
                    reject(new Error("Failed to load the image blob."));
                };

                img.src = url;
            });
        },
        async sendMessage() {
            if (!this.userInput.trim() || this.loading) return;

            const userMessage = {
                role: "user",
                content: this.userInput.trim(),
            };

            this.messages.push(userMessage);
            this.userInput = "";
            this.loading = true;

            try {
                // We need to get only the canvas data that is necessary for the AI's context.
                const layersOrderedPrettyJson = JSON.stringify(
                    this.layersOrdered.map(function (layer) {
                        return {
                            element_id: layer.element_id,
                            id: layer.id,
                            name: layer.name,
                            note: layer.note,
                            details: layer.relatedCanvasObject
                                ? {
                                      angle:
                                          layer.relatedCanvasObject.angle ??
                                          null,
                                      charSpacing:
                                          layer.relatedCanvasObject
                                              .charSpacing ?? null,
                                      cornerSize:
                                          layer.relatedCanvasObject
                                              .cornerSize ?? null,
                                      fill:
                                          layer.relatedCanvasObject.fill ??
                                          null,
                                      fontColor:
                                          layer.relatedCanvasObject.fontColor ??
                                          null,
                                      fontFamily:
                                          layer.relatedCanvasObject
                                              .fontFamily ?? null,
                                      fontSize:
                                          layer.relatedCanvasObject.fontSize ??
                                          null,
                                      opacity:
                                          layer.relatedCanvasObject
                                              .teopacityxt ?? null,
                                      text:
                                          layer.relatedCanvasObject.text ??
                                          null,
                                      textAlign:
                                          layer.relatedCanvasObject.textAlign ??
                                          null,
                                  }
                                : null,
                        };
                    }),
                    null,
                    2,
                );
                const selectedLayerElementId =
                    this.targetElement && this.targetElement.templateField
                        ? this.targetElement.templateField.element_id
                        : "none";
                const responseMessage = await this.callOpenAI({
                    messages: [
                        {
                            role: "system",
                            content: `You are a banner ad designer AI assistant. Here is the current list of layers in the template: \n\n${layersOrderedPrettyJson}\n\nThe element_id of the currently selected layer is: ${selectedLayerElementId}`,
                        },
                        // Send only the latest 20 messages
                        ...this.messages.slice(-20).map((message) => ({
                            role: message.role,
                            content: message.content,
                        })),
                    ],
                    model: "gpt-4o",
                    functions: aiChatFunctions,
                });
                let assistantMessage;

                if (responseMessage.function_call) {
                    const functionName = responseMessage.function_call.name;
                    const functionArgs = JSON.parse(
                        responseMessage.function_call.arguments,
                    );

                    if (functionName === "select_element") {
                        const layer = this.layersOrdered.find(
                            (layer) =>
                                layer.element_id === functionArgs.element_id,
                        );
                        this.setActiveObject(layer.relatedCanvasObject);
                        assistantMessage = {
                            role: "assistant",
                            content: `I've selected the element with ID: "${functionArgs.element_id}"`,
                        };
                    } else if (functionName === "change_element_product_id") {
                        const layer = this.layersOrdered.find(
                            (layer) =>
                                layer.element_id === functionArgs.element_id,
                        );
                        this.setActiveObject(layer.relatedCanvasObject);
                        const response = await axios.get(`/file/file_list`, {
                            params: {
                                pageSize: 12,
                                pageNumber: 1,
                                searchKey:
                                    functionArgs.new_product_id.toString(),
                            },
                        });
                        if (response.data.items.length === 0) {
                            assistantMessage = {
                                role: "assistant",
                                content: `The product ID/GTIN/ASIN was not found in our database: ${functionArgs.new_product_id}`,
                            };
                            this.messages.push(assistantMessage);
                            this.loading = false;
                            return;
                        }
                        editorControlEventBroker.fire(EVENTS.IMAGE_CHANGED, {
                            action: "productImage",
                            value: response.data.items[0].name.split(".")[0],
                        });
                        assistantMessage = {
                            role: "assistant",
                            content: `I've changed the product ID/GTIN/ASIN of the element with ID: "${functionArgs.element_id}". The new ID is: ${functionArgs.new_product_id}`,
                        };
                    } else if (functionName === "replace_element_image") {
                        const layer = this.layersOrdered.find(
                            (layer) =>
                                layer.element_id === functionArgs.element_id,
                        );
                        this.setActiveObject(layer.relatedCanvasObject);

                        const imageUrl = this.target?.getSrc();
                        const formData = new FormData();
                        let imageWidth = 0;
                        let imageHeight = 0;

                        fetch(imageUrl)
                            .then((response) => response.blob())
                            .then(async (blob) => {
                                const { width, height } =
                                    await this.getImageDimensions(blob);
                                formData.append("width", width);
                                formData.append("height", height);
                                formData.append(
                                    "promptForGenerating",
                                    functionArgs.description_of_the_new_image,
                                );
                                const response = await axios.post(
                                    "/ai-create-image",
                                    formData,
                                    {
                                        headers: {
                                            "Content-Type":
                                                "multipart/form-data",
                                        },
                                    },
                                );

                                const newImageUrl =
                                    window.location.origin + response.data.url;
                                this.messages.push({
                                    role: "assistant",
                                    content: `I've generated a new image to replace the image of the element with ID: "${functionArgs.element_id}". This is the new image.`,
                                    imageUrl: newImageUrl,
                                });
                                this.loading = false;

                                // Wait for the image to show in the chat.
                                setTimeout(() => {
                                    $("#ai-chat-messages").scrollTop(
                                        $("#ai-chat-messages")[0].scrollHeight,
                                    );
                                    setTimeout(() => {
                                        const confirmed = confirm(
                                            "Do you want to save the new image?",
                                        );

                                        if (!confirmed) {
                                            this.messages.push({
                                                role: "assistant",
                                                content: `No changes were applied to the image.`,
                                            });
                                            this.loading = false;
                                            return;
                                        }

                                        fetch(newImageUrl)
                                            .then((response) => response.blob())
                                            .then((blob) => {
                                                const now = new Date();
                                                const timestamp = now
                                                    .toISOString()
                                                    .replace(/[:.]/g, "-");
                                                const filename = `image-${timestamp}.png`;
                                                const file = new File(
                                                    [blob],
                                                    filename,
                                                    { type: blob.type },
                                                );

                                                const dataTransfer =
                                                    new DataTransfer();
                                                dataTransfer.items.add(file);

                                                editorControlEventBroker.fire(
                                                    EVENTS.IMAGE_CHANGED,
                                                    {
                                                        action: "selectBackground",
                                                        value: undefined,
                                                    },
                                                );
                                                const droppedFiles =
                                                    dataTransfer.files;
                                                const $newBackgroundImage = $(
                                                    "#new-background-image",
                                                );
                                                const $selectBkImgModal =
                                                    $("#selectBkImgModal");
                                                const $buttonSelect = $(
                                                    "#selectBkImgModal #submit",
                                                );
                                                const $compressCheckbox =
                                                    $("#compress_check");
                                                const $fileCaption =
                                                    $newBackgroundImage
                                                        .closest(".input-group")
                                                        .find(
                                                            ".file-caption-name",
                                                        );

                                                $newBackgroundImage.fileinput(
                                                    "readFiles",
                                                    droppedFiles,
                                                );
                                                $newBackgroundImage[0].files =
                                                    droppedFiles;

                                                $fileCaption.val(
                                                    `${droppedFiles.length} file selected`,
                                                );
                                                $fileCaption.addClass(
                                                    "is-valid",
                                                );

                                                if (droppedFiles.length) {
                                                    $compressCheckbox.prop(
                                                        "checked",
                                                        true,
                                                    );

                                                    $buttonSelect.hasClass(
                                                        "active",
                                                    )
                                                        ? $buttonSelect.removeClass(
                                                              "active",
                                                          )
                                                        : $buttonSelect.addClass(
                                                              "active",
                                                          );

                                                    $selectBkImgModal
                                                        .find("#upload")
                                                        .show();
                                                    $selectBkImgModal
                                                        .find(
                                                            ".compress_check, .crop_check",
                                                        )
                                                        .show();
                                                } else {
                                                    $selectBkImgModal
                                                        .find("#upload")
                                                        .hide();
                                                    $selectBkImgModal
                                                        .find(
                                                            ".compress_check, .crop_check",
                                                        )
                                                        .hide();
                                                }

                                                localStorage.setItem(
                                                    GLOBAL_UPDATE_LOCAL_STORAGE_KEY,
                                                    false,
                                                );

                                                this.$root.$emit(
                                                    "productImageReplace",
                                                    {
                                                        dataTransfer,
                                                    },
                                                );
                                            });
                                    }, 100);
                                }, 2000);
                            })
                            .catch((error) => {
                                console.error(
                                    "Error fetching or processing the image:",
                                    error,
                                );
                                this.loading = false;
                            });
                    } else if (functionName === "edit_element_image") {
                        const layer = this.layersOrdered.find(
                            (layer) =>
                                layer.element_id === functionArgs.element_id,
                        );
                        this.setActiveObject(layer.relatedCanvasObject);

                        const imageUrl = this.target?.getSrc();
                        const formData = new FormData();
                        const newFileName = "image.png";

                        fetch(imageUrl)
                            .then((response) => response.blob())
                            .then(async (blob) => {
                                const file = new File([blob], newFileName, {
                                    type: blob.type,
                                });
                                const { width, height } =
                                    await this.getImageDimensions(blob);
                                formData.append("width", width);
                                formData.append("height", height);
                                formData.append("image", file);
                                formData.append(
                                    "promptForEditing",
                                    functionArgs.changes_to_the_image,
                                );
                                const response = await axios.post(
                                    "/ai-edit-image",
                                    formData,
                                    {
                                        headers: {
                                            "Content-Type":
                                                "multipart/form-data",
                                        },
                                    },
                                );

                                const newImageUrl =
                                    window.location.origin + response.data.url;
                                this.messages.push({
                                    role: "assistant",
                                    content: `I've edited the image of the element with ID: "${functionArgs.element_id}". This is the edited image.`,
                                    imageUrl: newImageUrl,
                                });
                                this.loading = false;

                                // Wait for the image to show in the chat.
                                setTimeout(() => {
                                    $("#ai-chat-messages").scrollTop(
                                        $("#ai-chat-messages")[0].scrollHeight,
                                    );
                                    setTimeout(() => {
                                        const confirmed = confirm(
                                            "Do you want to save the edited image?",
                                        );

                                        if (!confirmed) {
                                            this.messages.push({
                                                role: "assistant",
                                                content: `No changes were applied to the image.`,
                                            });
                                            this.loading = false;
                                            return;
                                        }

                                        fetch(newImageUrl)
                                            .then((response) => response.blob())
                                            .then((blob) => {
                                                const now = new Date();
                                                const timestamp = now
                                                    .toISOString()
                                                    .replace(/[:.]/g, "-");
                                                const filename = `image-${timestamp}.png`;
                                                const file = new File(
                                                    [blob],
                                                    filename,
                                                    { type: blob.type },
                                                );

                                                const dataTransfer =
                                                    new DataTransfer();
                                                dataTransfer.items.add(file);

                                                editorControlEventBroker.fire(
                                                    EVENTS.IMAGE_CHANGED,
                                                    {
                                                        action: "selectBackground",
                                                        value: undefined,
                                                    },
                                                );
                                                const droppedFiles =
                                                    dataTransfer.files;
                                                const $newBackgroundImage = $(
                                                    "#new-background-image",
                                                );
                                                const $selectBkImgModal =
                                                    $("#selectBkImgModal");
                                                const $buttonSelect = $(
                                                    "#selectBkImgModal #submit",
                                                );
                                                const $compressCheckbox =
                                                    $("#compress_check");
                                                const $fileCaption =
                                                    $newBackgroundImage
                                                        .closest(".input-group")
                                                        .find(
                                                            ".file-caption-name",
                                                        );

                                                $newBackgroundImage.fileinput(
                                                    "readFiles",
                                                    droppedFiles,
                                                );
                                                $newBackgroundImage[0].files =
                                                    droppedFiles;

                                                $fileCaption.val(
                                                    `${droppedFiles.length} file selected`,
                                                );
                                                $fileCaption.addClass(
                                                    "is-valid",
                                                );

                                                if (droppedFiles.length) {
                                                    $compressCheckbox.prop(
                                                        "checked",
                                                        true,
                                                    );

                                                    $buttonSelect.hasClass(
                                                        "active",
                                                    )
                                                        ? $buttonSelect.removeClass(
                                                              "active",
                                                          )
                                                        : $buttonSelect.addClass(
                                                              "active",
                                                          );

                                                    $selectBkImgModal
                                                        .find("#upload")
                                                        .show();
                                                    $selectBkImgModal
                                                        .find(
                                                            ".compress_check, .crop_check",
                                                        )
                                                        .show();
                                                } else {
                                                    $selectBkImgModal
                                                        .find("#upload")
                                                        .hide();
                                                    $selectBkImgModal
                                                        .find(
                                                            ".compress_check, .crop_check",
                                                        )
                                                        .hide();
                                                }

                                                localStorage.setItem(
                                                    GLOBAL_UPDATE_LOCAL_STORAGE_KEY,
                                                    false,
                                                );

                                                this.$root.$emit(
                                                    "productImageReplace",
                                                    {
                                                        dataTransfer,
                                                    },
                                                );
                                            });
                                    }, 100);
                                }, 2000);
                            })
                            .catch((error) => {
                                console.error(
                                    "Error fetching or processing the image:",
                                    error,
                                );
                                this.loading = false;
                            });
                    } else if (functionName === "edit_element_text") {
                        const layer = this.layersOrdered.find(
                            (layer) =>
                                layer.element_id === functionArgs.element_id,
                        );
                        this.setActiveObject(layer.relatedCanvasObject);
                        editorControlEventBroker.fire(EVENTS.TEXT_CHANGED, {
                            value: functionArgs.new_text,
                            action: "inputText",
                        });
                        if (functionArgs.apply_to_all) {
                            editorControlEventBroker.fire(EVENTS.TEXT_CHANGED, {
                                value: true,
                                action: "text",
                                keys: ["text"],
                            });
                        }
                        assistantMessage = {
                            role: "assistant",
                            content: `I've edited the text of the element with ID: "${functionArgs.element_id}". The new text is: "${functionArgs.new_text}"`,
                        };
                    } else if (functionName === "edit_element_color") {
                        const layer = this.layersOrdered.find(
                            (layer) =>
                                layer.element_id === functionArgs.element_id,
                        );
                        this.setActiveObject(layer.relatedCanvasObject);
                        editorControlEventBroker.fire(EVENTS.TEXT_CHANGED, {
                            value: functionArgs.new_color,
                            action: "fontColor",
                        });
                        if (functionArgs.apply_to_all) {
                            editorControlEventBroker.fire(EVENTS.TEXT_CHANGED, {
                                value: true,
                                action: "fontColor",
                                keys: ["color"],
                            });
                        }
                        assistantMessage = {
                            role: "assistant",
                            content: `I've edited the text of the element with ID: "${functionArgs.element_id}". The new color is: "${functionArgs.new_color}"`,
                        };
                    } else if (functionName === "edit_element_font_size") {
                        const layer = this.layersOrdered.find(
                            (layer) =>
                                layer.element_id === functionArgs.element_id,
                        );
                        this.setActiveObject(layer.relatedCanvasObject);
                        editorControlEventBroker.fire(EVENTS.TEXT_CHANGED, {
                            value: functionArgs.new_font_size,
                            action: "fontSize",
                        });
                        if (functionArgs.apply_to_all) {
                            editorControlEventBroker.fire(EVENTS.TEXT_CHANGED, {
                                value: true,
                                action: "fontSize",
                                keys: ["size"],
                            });
                        }
                        assistantMessage = {
                            role: "assistant",
                            content: `I've edited the text of the element with ID: "${functionArgs.element_id}". The new font size is: "${functionArgs.new_font_size}"`,
                        };
                    } else if (functionName === "edit_element_font_family") {
                        // Use another prompt for selecting the prompt. We do not send the font list in the system prompt to improve accuracy.
                        const responseMessage = await this.callOpenAI({
                            messages: [
                                {
                                    role: "system",
                                    content: `You are a banner ad designer AI assistant.`,
                                },
                                // Send only the latest 20 messages
                                ...this.messages.slice(-20).map((message) => ({
                                    role: message.role,
                                    content: message.content,
                                })),
                                {
                                    role: "system",
                                    content: `You must return a font that satisfies the requeriments of the latest message. The font must be one from the following list:\n\nJSON.parse(JSON.stringify(this.getFonts())).`,
                                },
                            ],
                            response_format: {
                                name: "font",
                                schema: {
                                    type: "object",
                                    properties: {
                                        font: {
                                            anyOf: [
                                                {
                                                    type: "string",
                                                    description:
                                                        "The name of the font.",
                                                },
                                                {
                                                    type: "null",
                                                    description:
                                                        "Null if no font is specified.",
                                                },
                                            ],
                                        },
                                    },
                                    required: ["font"],
                                    additionalProperties: false,
                                },
                                strict: true,
                            },
                            model: "gpt-4o",
                        });
                        const newFontName = JSON.parse(
                            responseMessage.content,
                        ).font;
                        if (newFontName) {
                            const layer = this.layersOrdered.find(
                                (layer) =>
                                    layer.element_id ===
                                    functionArgs.element_id,
                            );
                            this.setActiveObject(layer.relatedCanvasObject);
                            editorControlEventBroker.fire(EVENTS.TEXT_CHANGED, {
                                value: newFontName,
                                action: "fontFamily",
                            });
                            if (functionArgs.apply_to_all) {
                                editorControlEventBroker.fire(
                                    EVENTS.TEXT_CHANGED,
                                    {
                                        value: true,
                                        action: "fontFamily",
                                        keys: ["font"],
                                    },
                                );
                            }
                            assistantMessage = {
                                role: "assistant",
                                content: `I've changed the font of the element with ID: "${functionArgs.element_id}". The new font is: "${newFontName}"`,
                            };
                        } else {
                            assistantMessage = {
                                role: "assistant",
                                content: `I couldn't find a font that matches your requirements.`,
                            };
                        }
                    } else if (functionName === "edit_project_name") {
                        const instanceId = this.selectedInstance.id;
                        const preview = window.previews.find((preview) => {
                            return preview.id === instanceId;
                        });
                        const grid = $("#template-group-preview");
                        const customer_id = grid.attr("data-customer-id");
                        const layout_id = grid.attr("data-layout-id");
                        const dataForUpdate = [
                            {
                                instance_id: instanceId,
                                key: "project_name",
                                value: functionArgs.new_project_name,
                            },
                        ];
                        try {
                            await axios.put(
                                `/banner/${customer_id}/groups/${layout_id}/save_changes`,
                                dataForUpdate,
                            );
                            preview.data.project_name =
                                functionArgs.new_project_name;
                            const templateTitleElement = $(
                                `.template-item[data-instance=${instanceId}]`,
                            ).find(".template-name-canvas");
                            templateTitleElement.html(
                                window.getTemplateNameText(
                                    preview.template,
                                    preview.data,
                                ),
                            );
                            templateTitleElement.css({
                                fontSize: "18px",
                                fontFamily: "Arial",
                                color: "rgb(0, 0, 0)",
                                fontWeight: "normal",
                            });
                        } catch (e) {
                            console.error(e);
                            assistantMessage = {
                                role: "assistant",
                                content: `There was an error and I couldn't update the project name.`,
                            };
                        } finally {
                            assistantMessage = {
                                role: "assistant",
                                content: `I've successfully updated the project name. The new name is: ${functionArgs.new_project_name}`,
                            };
                        }
                    } else if (functionName === "publish_to_platform") {
                        try {
                            this.loading = true;

                            const platformType =
                                functionArgs.platform_type?.toLowerCase();

                            if (!platformType) {
                                assistantMessage = {
                                    role: "assistant",
                                    content: `I couldn't determine which platform you want to publish to. Please specify Bynder, Salsify, or Walmart.`,
                                };
                                this.messages.push(assistantMessage);
                                this.loading = false;
                                return;
                            }

                            $("#publishToPlatformModal").modal("show");

                            setTimeout(() => {
                                const platformSelect = $(
                                    "#deployment_platform",
                                );
                                const options = platformSelect.find("option");
                                let platformFound = false;
                                let multiplePlatforms = false;
                                let matchingPlatformIds = [];

                                options.each(function () {
                                    const optionPlatform = $(this)
                                        .data("platform")
                                        ?.toLowerCase();
                                    if (optionPlatform === platformType) {
                                        matchingPlatformIds.push($(this).val());
                                    }
                                });

                                if (matchingPlatformIds.length > 0) {
                                    platformFound = true;

                                    if (matchingPlatformIds.length > 1) {
                                        multiplePlatforms = true;
                                    } else {
                                        platformSelect
                                            .val(matchingPlatformIds[0])
                                            .trigger("change");
                                    }
                                }

                                if (platformFound) {
                                    if (multiplePlatforms) {
                                        assistantMessage = {
                                            role: "assistant",
                                            content: `I found multiple ${platformType} connections. Please select the specific one you want to use from the publish dialog.`,
                                        };
                                    } else {
                                        assistantMessage = {
                                            role: "assistant",
                                            content: `I've opened the publish dialog with ${platformType} selected for you.`,
                                        };
                                    }
                                } else {
                                    assistantMessage = {
                                        role: "assistant",
                                        content: `I couldn't find a ${platformType} connection. Please select a platform from the publish dialog manually.`,
                                    };
                                }

                                this.messages.push(assistantMessage);
                                this.loading = false;
                            }, 500);

                            return;
                        } catch (e) {
                            console.error(
                                "Error setting up publish dialog:",
                                e,
                            );
                            assistantMessage = {
                                role: "assistant",
                                content: `Sorry, there was an error opening the publish dialog. Please try using the Publish button manually.`,
                            };
                            this.loading = false;
                        }
                    } else if (functionName === "generate_proof_link") {
                        try {
                            const layoutVersionId = $(
                                "#template-group-preview",
                            ).attr("data-layout-id");

                            this.loading = true;

                            const response = await axios({
                                method: "get",
                                url: `/banner/groups/${layoutVersionId}/get_latest_share_proof_link_for_version`,
                            });

                            if (response.data && response.data.url) {
                                assistantMessage = {
                                    role: "assistant",
                                    content: `Here's the proof link:`,
                                    proofLink: response.data.url,
                                };
                            } else {
                                const response = await axios.post(
                                    `/banner/groups/${layoutVersionId}/share_proof_link`,
                                    {
                                        from: 0,
                                        template_images: window.previews.map(
                                            (preview) => ({
                                                instance_id: preview.id,
                                                name:
                                                    preview.template?.name ||
                                                    "",
                                            }),
                                        ),
                                    },
                                );

                                if (response.data && response.data.url) {
                                    assistantMessage = {
                                        role: "assistant",
                                        content: `Here's the proof link:`,
                                        proofLink: response.data.url,
                                    };
                                } else {
                                    assistantMessage = {
                                        role: "assistant",
                                        content: `Sorry, I couldn't generate a proof link. Please try again or use the 'Share' button manually.`,
                                    };
                                }
                            }

                            this.loading = false;
                        } catch (e) {
                            console.error("Error generating proof link:", e);
                            assistantMessage = {
                                role: "assistant",
                                content: `Sorry, there was an error generating the proof link. Please try using the 'Share' button manually.`,
                            };
                            this.loading = false;
                        }
                    } else if (functionName === "check_product_id") {
                        try {
                            this.loading = true;

                            // Manually execute the parsing logic that would normally run server-side
                            const rawInput = functionArgs.product_id;
                            let parsedArgs = {};

                            // Check if it contains multiple IDs (contains spaces, commas, or newlines)
                            if (/[\s,\n]/.test(rawInput)) {
                                // Parse multiple identifiers
                                const identifiers = rawInput
                                    .split(/[\s,\n]+/) // Split by spaces, commas, or newlines
                                    .map((id) => id.trim())
                                    .filter((id) => id.length > 0); // Remove empty strings

                                if (identifiers.length === 0) {
                                    assistantMessage = {
                                        role: "assistant",
                                        content: `No valid product identifiers provided.`,
                                    };
                                    this.messages.push(assistantMessage);
                                    this.loading = false;
                                    return;
                                }

                                parsedArgs = { multiple: true, identifiers };
                            } else {
                                // Single identifier case
                                parsedArgs = {
                                    multiple: false,
                                    product_id: rawInput.trim(),
                                };
                            }

                            // Now use the parsed arguments
                            if (parsedArgs.multiple) {
                                // Handle multiple product IDs
                                const { identifiers } = parsedArgs;

                                if (!identifiers || !identifiers.length) {
                                    assistantMessage = {
                                        role: "assistant",
                                        content: `I need valid product identifiers to check. Please provide ASINs, GTINs, or UPCs.`,
                                    };
                                    this.messages.push(assistantMessage);
                                    this.loading = false;
                                    return;
                                }

                                const results = [];
                                let foundCount = 0;
                                let notFoundCount = 0;

                                // Process each identifier sequentially
                                for (const identifier of identifiers) {
                                    try {
                                        const response = await axios.get(
                                            "/file/file_list",
                                            {
                                                params: {
                                                    pageSize: 1, // Only need to know if it exists
                                                    pageNumber: 1,
                                                    searchKey: identifier,
                                                    exactMatch: true,
                                                },
                                            },
                                        );

                                        const found =
                                            response.data &&
                                            response.data.items &&
                                            response.data.items.length > 0;

                                        results.push({
                                            identifier,
                                            found,
                                            count: found
                                                ? response.data.items.length
                                                : 0,
                                        });

                                        if (found) {
                                            foundCount++;
                                        } else {
                                            notFoundCount++;
                                        }
                                    } catch (error) {
                                        console.error(
                                            `Error checking identifier ${identifier}:`,
                                            error,
                                        );
                                        results.push({
                                            identifier,
                                            found: false,
                                            error: true,
                                        });
                                        notFoundCount++;
                                    }
                                }

                                // Generate a comprehensive response with HTML instead of markdown
                                let content = `<p><strong>Product Identifier Check Results</strong></p>`;
                                content += `<p>Checked ${identifiers.length} identifiers:</p>`;
                                content += `<ul>`;
                                content += `<li>\u2705 Found: ${foundCount}</li>`;
                                content += `<li>\u274c Not found: ${notFoundCount}</li>`;
                                content += `</ul>`;
                                content += `<p><strong>Details:</strong></p>`;
                                content += `<ul>`;

                                // Add each identifier to the content
                                results.forEach((result) => {
                                    if (result.found) {
                                        content += `<li><strong>${result.identifier}</strong>: \u2705 Found</li>`;
                                    } else if (result.error) {
                                        content += `<li><strong>${result.identifier}</strong>: \u2753 Error checking</li>`;
                                    } else {
                                        content += `<li><strong>${result.identifier}</strong>: \u274c Not found</li>`;
                                    }
                                });

                                content += `</ul>`;

                                // Collect all found product IDs for the Select button
                                const foundProductIds = results
                                    .filter((result) => result.found)
                                    .map((result) => result.identifier);

                                const hasFoundProducts =
                                    foundProductIds.length > 0;
                                const allFoundProductIds =
                                    foundProductIds.join(" ");

                                assistantMessage = {
                                    role: "assistant",
                                    content,
                                    // Add hasSelectButton and productId for all found products
                                    hasSelectButton: hasFoundProducts,
                                    productId: allFoundProductIds,
                                };
                            } else {
                                // Handle single product ID (original behavior)
                                const productId = parsedArgs.product_id;

                                if (!productId) {
                                    assistantMessage = {
                                        role: "assistant",
                                        content: `I need a valid product ID to check. Please provide a product ID, GTIN, or ASIN.`,
                                    };
                                    this.messages.push(assistantMessage);
                                    this.loading = false;
                                    return;
                                }

                                try {
                                    const response = await axios.get(
                                        "/file/file_list",
                                        {
                                            params: {
                                                pageSize: 12,
                                                pageNumber: 1,
                                                searchKey: productId,
                                                exactMatch: true,
                                            },
                                        },
                                    );

                                    if (
                                        response.data &&
                                        response.data.items &&
                                        response.data.items.length > 0
                                    ) {
                                        const foundProducts =
                                            response.data.items;

                                        assistantMessage = {
                                            role: "assistant",
                                            content: `\u2705 Product ID "${productId}" exists in the database. Found ${foundProducts.length} matching item${foundProducts.length > 1 ? "s" : ""}.`,
                                            foundProductData: response.data,
                                            hasSelectButton: true,
                                            productId: productId,
                                        };
                                        this.loading = false;
                                    } else {
                                        assistantMessage = {
                                            role: "assistant",
                                            content: `Product ID "${productId}" was not found in the database. Please check the ID and try again.`,
                                        };
                                        this.loading = false;
                                    }
                                } catch (error) {
                                    console.error(
                                        "Error checking product existence:",
                                        error,
                                    );
                                    assistantMessage = {
                                        role: "assistant",
                                        content: `Sorry, there was an error checking if product ID "${productId}" exists. Please try again.`,
                                    };
                                    this.loading = false;
                                }
                            }
                        } catch (e) {
                            console.error("Error in check_product_id:", e);
                            assistantMessage = {
                                role: "assistant",
                                content: `Sorry, there was an error processing your request. Please try again.`,
                            };
                            this.loading = false;
                        }
                    }
                } else {
                    assistantMessage = {
                        role: "assistant",
                        content: responseMessage.content,
                    };
                }

                if (assistantMessage) {
                    this.messages.push(assistantMessage);
                    this.loading = false;
                }
            } catch (error) {
                console.error("Error:", error);
                this.messages.push({
                    role: "assistant",
                    content:
                        "Sorry, there was an error processing your message.",
                });
                this.loading = false;
            } finally {
                this.$nextTick(() => {
                    const messagesDiv = this.$refs.messages;
                    messagesDiv.scrollTop = messagesDiv.scrollHeight;
                });
            }
        },
        selectProduct(productId) {
            $("#addProductImages").modal("show");

            setTimeout(() => {
                const searchInput = $(
                    "#addProductImages .c-top-bar__search--input",
                );
                if (searchInput.length) {
                    searchInput.val(productId);
                    searchInput.trigger("input");

                    const events = ["keydown", "keypress", "keyup"];
                    events.forEach((eventType) => {
                        const event = new Event(eventType, { bubbles: true });
                        Object.defineProperties(event, {
                            keyCode: { value: 13 },
                            key: { value: "Enter" },
                            which: { value: 13 },
                            code: { value: "Enter" },
                        });
                        searchInput[0].dispatchEvent(event);
                    });
                }
            }, 300);
        },
        copyProofLink(id) {
            const input = document.getElementById(id);
            input.select();
            document.execCommand("copy");
            alert("Proof link copied to clipboard!");
        },
    },
};
</script>

<style scoped lang="scss">
.chat-module {
    height: calc(100vh - 98px);
}
.chat-module .card {
    position: relative;
    overflow-y: auto;
}
.message {
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
}
.message p {
    white-space: pre-wrap;
    margin-bottom: 0px;
}
.message-user {
    background-color: #209c86;
    color: white;
    text-align: right;
}
.message-assistant {
    background-color: #e9ecef;
    text-align: left;
}
</style>
