<template>
  <div>
    <b-modal
      id="export-templates-modal"
      title="Export"
      centered
    >
      <b-card no-body>
        <b-tabs
          pills
          card
        >
          <b-tab
            title="Download"
            active
          >
            <div class="form-row">
              <div class="col-md-2">
                <label>Saved settings</label>
              </div>

              <div class="col-md-10 form-group">
                <Multiselect
                  v-model="selectedDownloadSettings"
                  :options="downloadSettingsOptions"
                  placeholder="Select settings"
                  track-by="value"
                  label="text"
                  :multiple="false"
                  :disabled="useTemplateSettings"
                  @select="applySavedSettings"
                />
              </div>
            </div>

            <!-- Only show the Format field if not using template settings
                 or if using template settings and all selected templates have identical settings -->
            <div
              v-if="!useTemplateSettings || templateSettingsConsistent"
              class="form-row"
            >
              <div class="col-md-2">
                <label>Format</label>
              </div>
              <div class="col-md-10 form-group">
                <b-form-select
                  v-model="selectedFormat"
                  :options="formats"
                  class="form-control"
                  :disabled="useTemplateSettings"
                  @change="onExportTypeUpdated"
                />
              </div>
            </div>

            <div v-show="selectedFormat === 'pdf'">
              <div class="form-row">
                <div class="col-md-2">
                  <label>Desired size</label>
                </div>
                <div class="col-md-10 form-group">
                  <b-form-select
                    v-model="selectedPdfPageSize"
                    :options="pdfPageSizes"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div v-show="selectedFormat === 'pptx'">
              <div class="form-row">
                <div class="col-md-2">
                  <label>PPTX output type</label>
                </div>
                <div class="col-md-10 form-group">
                  <b-form-select
                    v-model="pptxIncludeFonts"
                    :options="pptxOutputTypes"
                    class="form-control"
                    @change="setSavedSettingsToNull"
                  />
                </div>
              </div>
            </div>
            <div v-show="selectedFormat === 'gif' || selectedFormat === 'pdf'">
              <div class="form-row container-downloadable-groups">
                <div class="col-md-2">
                  <label>Group</label>
                </div>
                <div class="col-md-10 form-group">
                  <MultipleSelectJquery
                    v-model="selectedGroupInstances"
                    class="export-pdf-groups-dropdown"
                    multiple
                    :data="groupsInstances"
                  />
                </div>
              </div>

              <div
                v-if="selectedFormat === 'pdf'"
                class="form-row"
              >
                <div class="col-md-2" />
                <div class="col-md-10 form-group">
                  <label>
                    <input
                      v-model="createOneFilePerGroup"
                      type="checkbox"
                      @change="setSavedSettingsToNull"
                    >
                    Create one file for each group
                  </label>
                </div>
              </div>

              <div
                v-if="selectedFormat === 'pdf'"
                class="form-row"
              >
                <div class="col-md-2" />
                <div class="col-md-10 form-group">
                  <label>
                    <input
                      v-model="createPageForEachTemplate"
                      type="checkbox"
                      @change="setSavedSettingsToNull"
                    >
                    Create one page for each template in the group
                  </label>
                </div>
              </div>

              <div
                v-if="selectedFormat === 'pdf' && selectedGroupInstances.length == 1"
                class="form-row"
              >
                <div class="col-md-2" />
                <div class="col-md-10 form-group">
                  <label>
                    <input
                      v-model="getDirectLinktoFile"
                      type="checkbox"
                      @change="setSavedSettingsToNull"
                    >
                    Get direct link to the file
                  </label>
                </div>
              </div>

              <div 
                v-if="selectedFormat !== 'pdf'"
                class="form-row container-downloadable-single-template"
              >
                <div class="col-md-2">
                  <label>Single Templates</label>
                </div>
                <div class="col-md-10 form-group">
                  <MultipleSelectJquery
                    v-model="selectedSingleInstances"
                    multiple
                    :data="singleInstances"
                    @change="setSavedSettingsToNull"
                  />
                </div>
              </div>
            </div>

            <div v-show="!['gif', 'pdf'].includes(selectedFormat)">
              <div class="form-row container-downloadable-templates">
                <div class="col-md-2">
                  <label>Download</label>
                </div>
                <div class="col-md-10 form-group">
                  <Multiselect
                    v-model="selectedInstances"
                    :options="instancesOption"
                    track-by="instance_id"
                    label="name"
                    :loading="isTemplatesLoading"
                    placeholder="Select instances..."
                    :close-on-select="false"
                    multiple
                    @select="setSavedSettingsToNull"
                    @remove="setSavedSettingsToNull"
                  >
                    <template #tag="{ option, remove }">
                      <span class="multiselect__tag">
                        <i
                          v-if="option.name.length > 25"
                          v-b-tooltip.hover="{ customClass: 'my-tooltip-inner', placement: 'right' }"
                          class="fas fa-info-circle info-icon"
                          :title="option.name"
                        />
                        <span>{{ option.name }}</span>
                        <i
                          class="multiselect__tag-icon"
                          @click="remove(option)"
                        />
                      </span>
                    </template>
                  </Multiselect>
                </div>
              </div>
            </div>
            <div
              v-show="['pptx'].includes(selectedFormat)"
              class="form-row"
            >
              <div class="col-md-2" />
              <div class="col-md-10">
                <label>
                  <input
                    v-model="downloadAsOneFile"
                    type="checkbox"
                    @change="setSavedSettingsToNull"
                  >
                  Download as one file
                </label>
              </div>
            </div>

            <div
              v-show="['psd', 'jpg_from_psd', 'psd_and_jpg', 'pptx', 'pdf', 'png_from_psd'].includes(selectedFormat)"
              class="form-row"
            >
              <div class="col-md-2" />
              <div class="col-md-10 form-group">
                <label>
                  <input
                    v-model="downloadInBackground"
                    type="checkbox"
                    @change="setSavedSettingsToNull"
                  >
                  Download in background
                </label>
              </div>
            </div>

            <div
              v-show="['psd', 'jpg_from_psd', 'psd_and_jpg', 'png_from_psd'].includes(selectedFormat)"
              class="form-row"
            >
              <div class="col-md-2" />
              <div class="col-md-10 form-group">
                <label>
                  <input
                    v-model="usePSD3"
                    type="checkbox"
                    @change="setSavedSettingsToNull"
                  >
                  Use PSD3
                </label>
              </div>
            </div>

            <!-- DPI and related settings are only shown if not using template settings
                 or if using template settings and all selected templates share the same settings -->
            <div
              v-if="(!useTemplateSettings || templateSettingsConsistent) && ['jpg', 'png', 'pdf', 'jpg_from_psd', 'png_from_psd'].includes(selectedFormat)"
              class="form-row container-platforms"
            >
              <div class="col-md-2">
                <label>DPI</label>
              </div>
              <div class="col-md-10 form-group">
                <b-form-select
                  v-model.number="selectedDpi"
                  class="form-control"
                  :disabled="useTemplateSettings"
                  @change="updateDpi(selectedDpi)"
                >
                  <option value="72">
                    72
                  </option>
                  <option value="75">
                    75
                  </option>
                  <option value="100">
                    100
                  </option>
                  <option value="144">
                    144
                  </option>
                  <option value="150">
                    150
                  </option>
                  <option value="200">
                    200
                  </option>
                  <option value="300">
                    300
                  </option>
                  <option value="0">
                    Custom
                  </option>
                </b-form-select>
                <div class="my-2" />
                <b-form-input
                  v-show="!selectedDpi"
                  v-model.number="dpi"
                  type="number"
                  :disabled="useTemplateSettings"
                  @change="setSavedSettingsToNull"
                />
              </div>
            </div>
            <div
              v-if="['jpg', 'png'].includes(selectedFormat)"
              class="form-row"
            >
              <div class="col-md-2">
                <label>Size</label>
              </div>
              <div class="col-md-10 form-group">
                <b-form-select
                  v-model.number="selectedSize"
                  class="form-control"
                  :disabled="useTemplateSettings"
                  @change="setSavedSettingsToNull"
                >
                  <option :value="SIZE_OPTIONS_TYPE.KEEP_ORIGINAL">
                    Keep original size
                  </option>
                  <option :value="SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_PERCENTAGE">
                    Scale down by %
                  </option>
                  <option
                    v-if="selectedInstances.length === 1"
                    :value="SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_DIMENSIONS"
                  >
                    Scale down to specific dimensions
                  </option>
                </b-form-select>
                <div v-if="selectedSize === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_PERCENTAGE">
                  <div class="my-2" />
                  <b-form-input
                    v-model="size.percentage"
                    type="number"
                    @change="setSavedSettingsToNull"
                  />
                </div>
                <div
                  v-if="selectedSize === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_DIMENSIONS"
                  class="dimensions-container"
                >
                  <div class="my-2" />
                  <b-form-input
                    v-model="size.dimensions.width"
                    type="number"
                    @change="handleWidthChange"
                  />
                  x
                  <b-form-input
                    v-model="size.dimensions.height"
                    type="number"
                    @change="handleHeightChange"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="selectedFormat === 'html'"
              class="form-row container-platforms"
            >
              <div class="col-md-2">
                <label>Platform</label>
              </div>
              <div class="col-md-10 form-group">
                <b-form-select
                  v-model="selectedPlatform"
                  class="form-control"
                  @change="setSavedSettingsToNull"
                >
                  <option value="">
                    None
                  </option>
                  <option value="ttd">
                    The Trade Desk (TTD)
                  </option>
                </b-form-select>
              </div>
            </div>

            <div v-if="checkTestMode()">
              <div class="form-row">
                <div class="col-md-2">
                  <label>Test mode
                    <i
                      class="cursor-pointer glyphicon glyphicon-refresh reset-text-position"
                      title="Get data to json"
                      @click="setJsonText"
                    />
                  </label>
                </div>
                <div class="col-md-10 form-group">
                  <textarea
                    v-model="jsonText"
                    rows="15"
                    cols="80"
                    class="json-editor"
                    @input="validateJSON"
                  />
                  <p
                    v-if="errorMessage"
                    class="error-message"
                  >
                    {{ errorMessage }}
                  </p>
                </div>
              </div>
            </div>

            <div
              class="form-row"
            >
              <div class="col-md-2" />

              <div class="col-md-10 d-flex justify-content-start align-items-center mb-2">
                <template v-if="!useTemplateSettings || templateSettingsConsistent">
                  <div
                    v-if="compressionCanBeApplied"
                    class="mt-1"
                  >
                    <label for="compressToMaxFileSize">
                      <input
                        id="compressToMaxFileSize"
                        v-model="compressToMaxFileSize"
                        type="checkbox"
                        :disabled="useTemplateSettings"
                        @change="handleCheckboxChange('compressToMaxFileSize')"
                      >
                      Compress to Max File Size
                    </label>
                  </div>

                  <div
                    v-if="compressToMaxFileSize"
                    class="d-flex align-items-center ml-3"
                  >
                    <b-form-input
                      v-model="compressToMaxFileSizeValue"
                      class="w-75"
                      type="number"
                      placeholder="Max file size"
                      :disabled="useTemplateSettings"
                      @change="setSavedSettingsToNull"
                    />
                    <span class="ml-2">KB</span>
                  </div>
                </template>

                <div
                  v-if="templateSettingsCanBeApplied"
                  class="mt-1 ml-3"
                >
                  <label for="useTemplateSettings">
                    <input
                      id="useTemplateSettings"
                      v-model="useTemplateSettings"
                      type="checkbox"
                      @change="handleCheckboxChange('useTemplateSettings')"
                    >
                    Use template settings
                  </label>
                </div>
              </div>
            </div>

            <div 
              v-if="selectedFormat != 'feed'" 
              class="form-row -groups"
            >
              <div class="col-md-2">
                <label>Save to folder</label>
              </div>
              <div class="col-md-10 form-group">
                <FolderTreeSelect
                  ref="selectedFolderInput"
                  v-model="selectedFolder"
                  :items="folders"
                  @input="setSavedSettingsToNull"
                />
                <div v-show="selectedFolder && selectedFormat">
                  <div>
                    <button
                      class="btn btn-primary my-2 font-xs"
                      @click="openFolderInNewTab"
                    >
                      Open folder
                    </button>
                    <button
                      class="btn btn-primary font-xs"
                      @click="copyToClipboard"
                    >
                      Copy link
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row mt-3">
              <div class="col-md-2" />
              <div class="col-md-10">
                <a
                  href=""
                  @click="showSaveSettingsModal"
                >
                  Save settings
                </a>
              </div>
            </div>
            <div 
              v-if="errors.length" 
              class="form-row mt-3"
            >
              <div class="col-md-2" />
              <div class="col-md-10">
                <div
                  v-for="(error, index) in errors"
                  :key="index"
                  class="alert alert-warning"
                  role="alert"
                >
                  {{ error }}
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab title="Saved settings">
            <SavedDownloadSettingsTab
              ref="savedDownloadSettingsTab"
              :is-master-admin="isMasterAdmin"
              @settings-updated="onSettingsUpdated"
            />
          </b-tab>
          <b-tab
            v-if="['pptx', 'pdf'].includes(selectedFormat)"
            title="Page numbers"
          >
            <ExportPageNumerationTab
              :page-numeration="pageNumeration"
              @data-updated="onPageNumerationUpdate($event)"
            />
          </b-tab>
          <b-tab
            v-if="['pptx', 'pdf'].includes(selectedFormat)"
            title="Page footer"
          >
            <ExportPageFooterTab
              :page-footer="pageFooter"
              @data-updated="pageFooter = $event"
            />
          </b-tab>

          <b-tab
            v-if="['pdf'].includes(selectedFormat)"
            title="Add pages"
          >
            <ExportAddPagesTab
              :add-pages="addPages"
              :group-instances="groupsInstances"
              @data-updated="addPages = $event"
            />
          </b-tab>
        </b-tabs>
      </b-card>

      <template #modal-footer>
        <div
          v-if="showBar && isSaving"
          class="col-12"
        >
          <ProgressBarComponent
            :current="current"
            :total="total"
          />
        </div>
        <b-button
          variant="primary"
          :disabled="isSaving"
          @click="onSave"
        >
          <div v-if="isSaving">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            Exporting...
          </div>
          <span v-else>Export</span>
        </b-button>
        <b-button
          variant="secondary"
          @click="onClose"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <SaveDownloadSettingsModal
      ref="saveDownloadSettingsModal"
      :is-master-admin="isMasterAdmin"
      @saved="onSettingSaved"
    />
  </div>
</template>

<script>
import { exportTemplatesEventBroker, EVENTS } from '@frontend/group/modules/export-templates/event-broker';
import MultipleSelectJquery from '../../../common/atoms/MultipleSelectJquery.vue';
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import {bus} from '@frontend/app'
import {SIZE_OPTIONS_TYPE} from "@frontend/constants/layout-export";
import layoutApi from "@frontend/services/api/layout";
import folderService from '@frontend/services/api/folder';
import FolderTreeSelect from "@frontend/components/common/atoms/FolderTreeSelect/FolderTreeSelect.vue";
import Pusher from "pusher-js";
import ProgressBarComponent from "@frontend/components/ProgressBarComponent.vue";
import SaveDownloadSettingsModal from "@frontend/components/modules/layouts-page/modals/SaveDownloadSettingsModal.vue";
import SavedDownloadSettingsTab from "@frontend/components/modules/layouts-page/Export/SavedDownloadSettingsTab.vue";
import ExportPageNumerationTab from "@frontend/components/modules/layouts-page/Export/ExportPageNumerationTab.vue";
import ExportPageFooterTab from "@frontend/components/modules/layouts-page/Export/ExportPageFooterTab.vue";
import {applySavedSettings, getSavedSettings} from "@frontend/group/modules/export-templates/saved-settings-service";
import ExportAddPagesTab from "@frontend/components/modules/layouts-page/Export/ExportAddPagesTab.vue";
import {
  ADDITIONAL_MULTI_LINE_TEXT_FIELD_PREFIX_ELEMENT_ID
} from "@frontend/constants/templates-field-prefixes";

const initSize = {
  percentage: 100,
  dimensions: {
    width: null,
    height: null,
  }
};

const DPI_PRESETS = [
  {
    text: '72',
    value: 72
  },
  {
    text: '75',
    value: 75
  },
  {
    text: '100',
    value: 100
  },
  {
    text: '144',
    value: 144
  },
  {
    text: '150',
    value: 150
  },
  {
    text: '200',
    value: 200
  },
  {
    text: '300',
    value: 300
  },
  {
    text: 'Custom',
    value: 0
  },
]


export default {
  name: 'ExportTemplatesModal',
  components: {
    ExportPageFooterTab,
    FolderTreeSelect,
    MultipleSelectJquery,
    Multiselect,
    ProgressBarComponent,
    SaveDownloadSettingsModal,
    SavedDownloadSettingsTab,
    ExportPageNumerationTab,
    ExportAddPagesTab,
  },
  props: {
    defaultInstances: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isSaving: false,
      selectedPdfPageSize: 'none',
      dpiPresets: DPI_PRESETS,
      pdfPageSizes: [
        {
          text: 'None',
          value: 'none'
        },
        {
          text: 'Letter (Portrait) @ 300 DPI (2550x3300px)',
          value: 'letter-portrait'
        },
        {
          text: 'Letter (Landscape) @ 300 DPI (3300x2550px)',
          value: 'letter-landscape'
        },
        {
          text: 'A4 (Portrait) @ 300 DPI (2480x3508px)',
          value: 'a4-portrait'
        },
        {
          text: 'A4 (Landscape) @ 300 DPI (3508x2480px)',
          value: 'a4-landscape'
        },
      ],
      formats: [],
      dpi: 72,
      selectedDpi: 72,
      selectedFormat: 'jpg',
      selectedPlatform: '',
      selectedSize: SIZE_OPTIONS_TYPE.KEEP_ORIGINAL,
      size: {...initSize},
      templateDimensions: {
        width: null,
        height: null,
      },
      isTemplatesLoading: false,
      templateAspectRatio: 1,
      selectedInstances: [],
      selectedGroupInstances: [],
      selectedSingleInstances: [],
      selectedFolder: null,
      instances: [],
      groupsInstances: [],
      singleInstances: [],
      folders: null,
      selectAllText : 'Select All',
      createPageForEachTemplate: false,
      createOneFilePerGroup: false,
      getDirectLinktoFile: false,
      downloadInBackground: true,
      downloadAsOneFile: false,
      singleInstanceId: null,
      groupInstancesMap: [],
      pptxOutputTypes: [
        {
          text: 'PPTX only',
          value: false
        },
        {
          text: 'PPTX and fonts in zip file',
          value: true
        }
      ],
      pptxIncludeFonts: false,
      layoutId: null,
      groupsTemplates: null,
      layoutOptions: null,
      errorMessage: null,
      jsonText: `{}`,
      showBar: false,
      total: 0,
      current: 0,
      selectedDownloadSettings: null,
      downloadSettingsOptions: [],
      isMasterAdmin: false,
      compressToMaxFileSize: false,
      compressToMaxFileSizeValue: null,
      pageNumeration: {
        isEnabled: true,
        mirrorType: 'None',
        horizontalOffset: 0,
        verticalOffset: 0,
        fontFamily: 'Arial',
        fontSize: 50,
        fontColor: '#000000',
        horizontalPosition: 'Right',
        verticalPosition: 'Bottom',
        underlyingShape: {
          isEnabled: false,
          shapeType: 'Image',
          image: {
            url: '',
            width: 0,
            height: 0,
            horizontalPosition: 'Left',
            verticalPosition: 'Top',
            horizontalOffset: 0,
            verticalOffset: 0,
          }
        },
        resultData:{}
      },
      pageFooter: {
        isEnabled: false,
        height: 50,
        backgroundColor: '#858585',
        texts: [],
        icon: {
          isEnabled: false,
          height: 40,
          width: 40,
          horizontalPosition: 'Left',
          verticalPosition: 'Center',
          horizontalOffset: 0,
          verticalOffset: 0,
        },
      },
      addPages: {
        prepend: {
          isEnabled: false,
        },
        append: {
          isEnabled: false,
        },
        insert: {
          isEnabled: false,
          insertions: [],
        },
      },
      useTemplateSettings: false,
      page: null,
      limit: null,
      templateSettings: {
        DPI: this.dpi,
        'File Type': this.selectedFormat,
        'Max File Size': null,
      },
      uniqueTemplateSettings:{},
      selectedTemplateSettings: null,
      usePSD3: true,
      errors: []
    };
  },
  computed:{
    SIZE_OPTIONS_TYPE() {
      return SIZE_OPTIONS_TYPE
    },
      instancesOption() {
          const selectAllOption = {instance_id: -1, name: this.selectAllText};
          return [selectAllOption, ...this.instances];
      },
    compressionCanBeApplied() {
      return ['jpg', 'jpg_from_psd', 'png_from_psd', 'png'].includes(this.selectedFormat);
    },
    templateSettingsCanBeApplied() {
      return ['jpg', 'jpg_from_psd', 'png', 'psd', 'png_from_psd'].includes(this.selectedFormat);
    },
    pdfPageSizeConfig() {
      const DPI = 300;
      
      switch (this.selectedPdfPageSize) {
        case 'letter-portrait':
          return {
            dpi: DPI,
            type: 'letter',
            orientation: 'portrait',
            width: 2550,
            height: 3300
          }
        case 'letter-landscape':
          return {
            dpi: DPI,
            type: 'letter',
            orientation: 'landscape',
            width: 3300,
            height: 2550
          }
        case 'a4-portrait':
          return {
            dpi: DPI,
            type: 'a4',
            orientation: 'portrait',
            width: 2480,
            height: 3508,
          }
        case 'a4-landscape':
          return {
            dpi: DPI,
            type: 'a4',
            orientation: 'landscape',
            width: 3508,
            height: 2480,
          }
        default:
          return null;
      }
    },
    disableUnrenderedTemaplates() {
      return this.selectedFormat === 'pdf';
    },
    templateSettingsConsistent() {
      if (!this.useTemplateSettings) return true;
      if (this.selectedInstances.length <= 1) return true;
      let firstSetting = null;
      for (const instance of this.selectedInstances) {
        const settings = this.uniqueTemplateSettings[instance.instance_id];
        if (!settings) return false;
        const settingStr = `${settings.DPI}|${settings['File Type']}|${settings['Max File Size']}`;
        if (firstSetting === null) {
          firstSetting = settingStr;
        } else if (firstSetting !== settingStr) {
          return false;
        }
      }
      return true;
    }
  },
  watch : {
      async selectedFormat() {
        this.selectedSize = SIZE_OPTIONS_TYPE.KEEP_ORIGINAL;
        this.size = {...initSize};

        this.chackDefaultPaginationData();
        await this.prepareModalData();
        this.updateGroupTemplatesItems();
        this.errors = [];

        this.selectedInstances = this.selectedInstances.filter(selectedInstance => {
          return this.instances.find(instance => instance.instance_id === selectedInstance.instance_id) !== undefined;
        });
      },
      async downloadInBackground(newValue) {
        await this.prepareModalData();
        this.updateGroupTemplatesItems();

        this.selectedInstances = this.selectedInstances.filter(selectedInstance => {
          return this.instances.find(instance => instance.instance_id === selectedInstance.instance_id) !== undefined;
        });
        
        // Make it empty array when switch to non-background 
        // in order to avoid errors due to having non-presented on the page instances selected
        if (newValue === false) {
          this.selectedGroupInstances = [];
        }
      },
      selectedInstances(newSelectedInstances) {
          this.useTemplateSettings = false;
          //validate selected items
          this.validateSelected();
          const allSelected = newSelectedInstances.some(option => option.instance_id === -1);
          if (allSelected) {
              this.toggleSelectAllText();
          } else if (this.selectedInstances.length < this.instances.length) {
            this.selectAllText = 'Select All'
          } else if (this.selectedInstances.length === this.instances.length) {
            this.selectAllText = 'Unselect All'
          }

          if (!newSelectedInstances.length ||
            (this.selectedSize === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_DIMENSIONS &&
              (newSelectedInstances.length > 1 || newSelectedInstances.length === 0)
            )) {
            this.selectedSize = SIZE_OPTIONS_TYPE.KEEP_ORIGINAL;

            this.templateDimensions = {
              width: null,
              height: null,
            }
            
            this.size.dimensions = {
              width: null,
              height: null
            }
          } else {
            //if select all get first template
            let instanceId = newSelectedInstances[0].instance_id === -1 ? this.selectedInstances[0].instance_id : newSelectedInstances[0].instance_id;
            const preview = previews.find(preview => preview.id === instanceId);
            
            if (preview) {
              this.templateDimensions = {
                width: preview.template.width,
                height: preview.template.height
              };

              this.templateAspectRatio = preview.template.width / preview.template.height;
            }
            this.uniqueTemplateSettings = {};
            if (this.selectedInstances.length) {
              
              this.selectedInstances.map(instance => {
                let preview = previews.find(preview => preview.id === instance.instance_id);
                if (preview) {
                  if (preview.template && preview.template.fields) {
                    for (const field of preview.template.fields) {
                      if (['DPI', 'File Type', 'Max File Size'].includes(field.type)) {
                        let opt = JSON.parse(field.options);
                        if (!this.uniqueTemplateSettings[preview.id]) {
                          this.uniqueTemplateSettings[preview.id] = {
                            name: instance.name,
                            DPI: this.dpi,
                            'Max File Size': null,
                            'File Type': this.selectedFormat,
                          };
                        }

                        const fieldMapping = {
                          DPI: 'DPI',
                          'Max File Size': 'Max File Size',
                          'File Type': 'File Type',
                        };

                        if (fieldMapping[field.type]) {
                          this.uniqueTemplateSettings[preview.id][fieldMapping[field.type]] =
                            opt.Option1 || this.uniqueTemplateSettings[preview.id][fieldMapping[field.type]];
                        }
                      }
                    }
                  }
                }
              })
            }
          }
      },
      selectedSize() {
        this.size.percentage = 100;
        
        if (this.selectedInstances.length === 1) {
          this.size.dimensions = {
            width: this.templateDimensions.width,
            height: this.templateDimensions.height
          }
        } else {
          this.size.dimensions = {
            width: null,
            height: null
          }
        }
    },
    dpi() {
      if (!DPI_PRESETS.some(({value}) => value === this.dpi)) {
        this.selectedDpi = 0
      }
    },
      instancesOption: {
        immediate: true,
        handler(newOptions) {
          if(this.selectedInstances.length === 0){
            if (newOptions.length === 2 && !this.selectedInstances.includes(newOptions[0])) {
              this.selectedInstances = [newOptions[0]];
            }
          }
        },
      }
  },
  mounted() {
    exportTemplatesEventBroker.on(EVENTS.INIT, this.init.bind(this));
    exportTemplatesEventBroker.on(EVENTS.SHOW, this.onShow.bind(this));
    exportTemplatesEventBroker.on(EVENTS.CLOSE, this.onClose.bind(this));
    exportTemplatesEventBroker.on(EVENTS.FINISH, this.onFinish.bind(this));
    bus.$on('singleInstanceId', (singleInstanceId) => {
      if(singleInstanceId) {
        this.selectedInstances = this.instancesOption.filter(option => option.instance_id == singleInstanceId); 
      }
    });

    const user_id = $('meta[name="userId"]').attr("content");
    let pusher = new Pusher(process.env.MIX_PUSHER_APP_KEY, {
      cluster: process.env.MIX_PUSHER_APP_CLUSTER
    });

    let channel = pusher.subscribe('export.'+user_id);
    channel.bind('fileAddToZip', (data) => {
      this.current++;
    });
  },
  methods: {
    //POR-8547 validate some additional fields to prevent errors if field was added with error
    validateSelected() {
      this.errors = [];
      if (!this.selectedInstances.length) {
        return;
      }
      const validationFields = [
        'left',
        'top',
        'width',
        'fontFamily'
      ];

      const validateNames = {
        left: 'X',
        top: 'Y',
        width: 'Width',
        fontFamily: 'Font'
      }
      
      this.selectedInstances.map(instance => {
        let preview = previews.find(preview => preview.id === instance.instance_id);
        if (preview) {
          if (['jpg_from_psd', 'png_from_psd', 'psd'].includes(this.selectedFormat)) {
            let additionalTextLayers = preview.canvas.getObjects().filter(obj => obj.visible === true && obj.element_id && String(obj.element_id).startsWith(ADDITIONAL_MULTI_LINE_TEXT_FIELD_PREFIX_ELEMENT_ID) );
            
            additionalTextLayers.forEach(layer => {
              //case for this font because this font was set as default but absent on the production. 
              // so need to check this case on production
              if (layer.fontFamily === 'AlternateGothicATF-Black' && location.host === 'app.rapidads.io') {
                this.errors.push(`Template ${instance.name} - ${layer.name} - please set Font before exporting.`);
              }

              validationFields.forEach(field => {
                if ((typeof layer[field] === "number" && isNaN(layer[field]))
                  || typeof layer[field] === "undefined") {
                  this.errors.push(`Template ${instance.name} - ${layer.name} - please set ${validateNames[field]} before exporting.`)
                }
              })
            })
          }
        }
      })
    },
    onPageNumerationUpdate(newPageNumeration) {
      this.pageNumeration = {
        ...newPageNumeration,
        underlyingShape: {
          ...newPageNumeration.underlyingShape,
          image: {...newPageNumeration.underlyingShape.image}
        }
      };
    },
    checkTestMode() {
      return this.layoutOptions?.test_mode === 'on';
    },
    copyToClipboard() {
      if (this.selectedFolder) {
        navigator.clipboard.writeText(window.location.origin + '/file/browser/files/' + this.selectedFolder.id)
          .then(() => {
            toastr.success('Folder link copied to clipboard!');
          })
          .catch(err => {
            console.error('Failed to copy: ', err);
          });
      } else {
        alert('No folder selected to copy.');
      }
    },
    openFolderInNewTab() {
      if (this.selectedFolder) {
        window.open(window.location.origin + '/file/browser/files/' + this.selectedFolder.id, '_blank');
      } else {
        alert('No folder selected to open.');
      }
    },
    validateJSON() {
      try {
        JSON.parse(this.jsonText);
        this.errorMessage = null;
      } catch (error) {
        this.errorMessage = "Invalid JSON: " + error.message;
      }
    },
    init({ layoutId, groupsTemplates, layout_options, isMasterAdmin, page, limit }) {
      this.layoutId = layoutId;
      this.groupsTemplates = groupsTemplates;
      this.layoutOptions = layout_options;
      this.isMasterAdmin = isMasterAdmin;
      this.compressToMaxFileSize = this.layoutOptions.compress_max_file_size || false;
      this.compressToMaxFileSizeValue = this.layoutOptions.compress_max_file_size_value || null;
      this.page = page;
      this.limit = limit;

      this.prepareModalData();
    },
    updateGroupTemplatesItems() {
      this.groupsInstances = [];

      const visibleTemplates = this.layoutOptions.groupsTemplates.flatMap((group) => group.instancesId);

      this.groupsInstances = this.groupsTemplates.map((group, groupIndex) => {
        const children = this.instances.filter(instance => group.instancesId.includes(instance.instance_id))
          .map(instance => {
            let isDisabled = this.disableUnrenderedTemaplates && !visibleTemplates.includes(instance.instance_id);

            if (this.selectedFormat === 'pdf' && this.downloadInBackground) {
              isDisabled = false;
            }
            
            return {
              text: instance.name,
              value: instance.instance_id,
              disabled: isDisabled,
              classes: "export-pdf-groups-dropdown__option",
            };
          });

        let isDisabled = this.disableUnrenderedTemaplates && children.find((child) => child.disabled);
        
        if (this.selectedFormat === 'pdf' && this.downloadInBackground) {
          isDisabled = false;
        }

        return {
          type: "optgroup",
          label: `Group ${groupIndex + 1}`,
          disabled: isDisabled,
          children: children,
        };
      });
      
      // Go over leftovers children that are not disabled but inside disabled group and disable them
      for (const groupInstance of this.groupsInstances) {
        if (groupInstance.disabled) {
          for (const child of groupInstance.children) {
            child.disabled = true;
          }
        }
      }
    },
    async prepareModalData() {
      this.isTemplatesLoading = true;
      try {
        if (this.defaultInstances.length > 0) {
          this.instances = this.defaultInstances;
        }
        
        const getAllTemplates = this.selectedFormat === 'pdf' || (this.selectedFormat === 'pptx' && this.downloadInBackground);
        
        if (!this.defaultInstances.length || getAllTemplates) {
          const page = getAllTemplates ? 1 : this.page;
          const limit = getAllTemplates ? 999 : this.limit;
          
          const {data: templates} = await layoutApi.getLayoutTemplates(this.layoutId, {
            simplified: true,
            sortBySettingsOrder: true,
            page,
            limit
          });

          this.instances = templates.map((template, index) => ({
            instance_id: template.instance_id,
            name: `${index + 1} - ${template.name}`
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isTemplatesLoading = false;
      }

      if (this.groupsTemplates.length) {
        this.updateGroupTemplatesItems();

        this.groupInstancesMap = this.groupsTemplates.map((group, groupIndex) => ({
          group: groupIndex,
          instances: [...group.instancesId]
        }));
      }

      this.singleInstances = this.instances.filter(instance => {
        return !this.groupsTemplates.find((group) => {
          return group.instancesId.includes(instance.instance_id);
        })
      }).map(instance => {
        return {
          value: instance.instance_id,
          text: instance.name
        }
      });
    },
    onShow() {
      this.prepareModalData();
      this.$bvModal.show('export-templates-modal');
      this.loadSavedSettingsDropdown();
      this.$nextTick(() => this.$refs.savedDownloadSettingsTab.load());
      this.loadFolders();
      this.validateSelected()
    },
    onClose() {
      this.isSaving = false;
      this.showBar = false;
      this.errors = [];
      this.$bvModal.hide('export-templates-modal');
    },
    onFinish(){
      this.isSaving = false;
      this.showBar = false;
    },
    validateSaveRequest() {
      if (this.selectedFormat === 'pdf' && (!this.selectedGroupInstances.length && !this.selectedSingleInstances.length)) {
        window.toastr.error("Please select at least one group or template from the list");
        return false;
      }

      if (this.selectedFormat === 'gif' && (!this.selectedGroupInstances.length && !this.selectedSingleInstances.length)) {
        window.toastr.error("Please select at least one group from the list");
        return false;
      }

      if (this.selectedFormat !== 'gif' && this.selectedFormat !== 'pdf' && !this.selectedInstances.length){
        window.toastr.error("Please select at least one template from the list");
        return false;
      }

      return true;
    },
    onSave() {
      this.isSaving = true;

      if (! this.validateSaveRequest()) {
        this.isSaving = false;
        return;
      }

      if (this.selectedFormat !== 'gif' && this.selectedFormat !== 'pdf' && !this.downloadInBackground) {
        this.showBar = true;
        this.total = this.selectedInstances.length;
        this.current = 0;
      }

      const data = this.getDataToEvent();

      // update indexes for inserts
      if (data?.addPages?.insert?.isEnabled === true && data?.addPages?.insert?.insertions?.length) {
        data.addPages.insert.insertions.forEach((insertion) => {
          insertion.after.index = data.selectedGroupInstances.findIndex((selectedInstanceIndex) => {
            return this.groupInstancesMap[selectedInstanceIndex].instances.includes(insertion.after.instance_id);
          });
        });
      }
      exportTemplatesEventBroker.fire(EVENTS.EXPORT, {
        ...data,
        useTemplateSettings: this.useTemplateSettings
      });
    },
    getDataToEvent() {
      if (this.checkTestMode()) {
        return JSON.parse(this.jsonText);
      }

      const data = {
        allGroupInstancesMap: this.groupInstancesMap,
        exportFormat: this.selectedFormat,
        selectedInstances: this.selectedInstances,
        selectedGroupInstances: this.getSelectedTemplateGroups(),
        selectedSingleInstances: this.selectedSingleInstances,
        selectedHTMLPlatform: this.selectedPlatform,
        dpi: this.dpi,
        createPageForEachTemplate: this.createPageForEachTemplate,
        getDirectLinktoFile: this.getDirectLinktoFile,
        selectedFolder: this.selectedFolder,
        createOneFilePerGroup: this.createOneFilePerGroup,
        pdfPageSizeConfig: this.pdfPageSizeConfig,
        downloadInBackground: this.downloadInBackground,
        downloadAsOneFile: this.downloadAsOneFile,
        sizeOptions: {
          type: this.selectedSize,
          scalePercentage: this.size.percentage,
          dimensions: this.size.dimensions,
        },
        pptxIncludeFonts: this.pptxIncludeFonts,
        pageNumeration: this.pageNumeration,
        pageFooter: this.pageFooter,
        addPages: this.addPages,
        usePSD3: this.usePSD3,
      };

      if (this.compressionCanBeApplied && this.compressToMaxFileSize) {
        data.compressionOptions = {
          compress: this.compressToMaxFileSize,
          compressValue: this.compressToMaxFileSizeValue,
        };
      }

      if (this.useTemplateSettings) {
        data.useTemplateSettings = this.useTemplateSettings;
      }

      return data;
    },
    selectAllOptions(selected) {
        if (selected) {
            this.selectedInstances = this.instances.map(instance => instance.instance_id);
        } else {
            this.selectedInstances = [];
        }
    },
    toggleSelectAllText() {
        if (this.selectAllText === 'Select All') {
            this.selectedInstances = this.instancesOption.filter(option => option.instance_id !== -1);
            this.selectAllText = 'Unselect All';
        } else {
            this.selectedInstances = [];
            this.selectAllText = 'Select All';
        }
    },
    handleSelection(selectedFolder) {
      this.selectedFolder = selectedFolder.id;
    },
    handleWidthChange(newWidth) {
      this.size.dimensions.width = +newWidth;
      this.size.dimensions.height = Math.round(newWidth / this.templateAspectRatio);
      this.setSavedSettingsToNull();
    },
    handleHeightChange(newHeight) {
      this.size.dimensions.height = +newHeight;
      this.size.dimensions.width = Math.round(newHeight * this.templateAspectRatio);
      this.setSavedSettingsToNull();
    },
    getSelectedTemplateGroups() {
      const selectedGroups = new Set();
      
      this.selectedGroupInstances.forEach(instance => {
        const group = this.groupInstancesMap.find(groupInstance => groupInstance.instances.includes(instance));

        if (group) {
          selectedGroups.add(group.group);
        }
      });
      
      return Array.from(selectedGroups);
    },
    setJsonText() {
      let data = {
        exportFormat: this.selectedFormat,
        selectedInstances: this.selectedInstances,
        selectedGroupInstances: this.getSelectedTemplateGroups(),
        selectedSingleInstances: this.selectedSingleInstances,
        selectedHTMLPlatform: this.selectedPlatform,
        dpi: this.dpi,
        createPageForEachTemplate: this.createPageForEachTemplate,
        getDirectLinktoFile: this.getDirectLinktoFile,
        selectedFolder: this.selectedFolder,
        createOneFilePerGroup: this.createOneFilePerGroup,
        downloadInBackground: this.downloadInBackground,
        downloadAsOneFile: this.downloadAsOneFile,
        sizeOptions: {
          type: this.selectedSize,
          scalePercentage: this.size.percentage,
          dimensions: this.size.dimensions,
        },
        pptxIncludeFonts: this.pptxIncludeFonts,
        pageNumeration: this.pageNumeration,
        pageFooter: this.pageFooter,
        usePSD3: this.usePSD3,
      };

      if (this.compressionCanBeApplied) {
        data.compressionOptions = {
          compress: this.compressToMaxFileSize,
          compressValue: this.compressToMaxFileSizeValue,
        };
      }

      this.jsonText = JSON.stringify(data, null, 2);
    },
    showSaveSettingsModal(event) {
      event.preventDefault();

      this.$refs.saveDownloadSettingsModal.show(this.getDataToEvent());
    },
    async loadSavedSettingsDropdown() {
      const { settings, downloadFormats } = await getSavedSettings();
      this.downloadSettingsOptions = settings.map(option => ({ text: option.name, value: option }));
      this.formats = downloadFormats;
      this.selectedFormat = this.formats?.find((format) => this.selectedFormat === format.value)?.value || this.selectedFormat;
    },
    onSettingsUpdated() {
      this.loadSavedSettingsDropdown();
    },
    onSettingSaved() {
      this.loadSavedSettingsDropdown();
      this.$refs.savedDownloadSettingsTab.load();
    },
    applySavedSettings(selectedOption) {
      applySavedSettings(selectedOption.value, (settings) => {
        this.dpi = settings.dpi;
        this.selectedDpi = settings.dpi;
        this.selectedFormat = settings.exportFormat;
        this.selectedFolder = settings.selectedFolder;
        this.selectedPlatform = settings.selectedHTMLPlatform || '';
        this.createPageForEachTemplate = settings.createPageForEachTemplate;
        this.createOneFilePerGroup = settings.createOneFilePerGroup;
        this.downloadInBackground = settings.downloadInBackground;
        this.downloadAsOneFile = settings.downloadAsOneFile;
        this.pptxIncludeFonts = settings.pptxIncludeFonts;
        this.getDirectLinktoFile = settings.getDirectLinktoFile;
        this.pageNumeration = settings.pageNumeration || {};
        this.usePSD3 = settings.usePSD3;
        
        // For backwards compatability
        const pageFooterSetting = settings.pageFooter;
        if (pageFooterSetting && pageFooterSetting.text && pageFooterSetting.text.isEnabled) {
          pageFooterSetting.isTextEnabled = true;
          pageFooterSetting.texts = [pageFooterSetting.text];
        }
        this.pageFooter = pageFooterSetting || {};
        
        this.addPages = settings.addPages;
        
        // Pdf page size
        const selectedPdfPageSizeType = settings.pdfPageSizeConfig?.type ?? 'none';
        const selectedPdfPageSizeOrientation = settings.pdfPageSizeConfig?.orientation;
        
        if (selectedPdfPageSizeType !== 'none' && selectedPdfPageSizeOrientation) {
          this.selectedPdfPageSize = selectedPdfPageSizeType + '-' + selectedPdfPageSizeOrientation;
        }
        
        // add selected instances from settings if they present in layout and no instances were selected before
        if (
          settings.selectedInstances
          && settings.selectedInstances.length
          && !this.selectedInstances.length
        ) {
          settings.selectedInstances.forEach(settingSelectedInstance => {
            if (this.instances.find(instance => instance.instance_id === settingSelectedInstance.instance_id)) {
              this.selectedInstances.push(settingSelectedInstance);
            }
          });
        }

        // same as above
        if (
          settings.selectedSingleInstances
          && settings.selectedSingleInstances.length
          && !this.selectedSingleInstances.length
        ) {
          settings.selectedSingleInstances.forEach(settingSelectedInstance => {
            if (this.singleInstances.find(instance => instance.value === settingSelectedInstance)) {
              this.selectedSingleInstances.push(settingSelectedInstance);
            }
          });
        }

        // same as above
        if (
          settings.selectedGroupInstances
          && settings.selectedGroupInstances.length
          && !this.selectedGroupInstances.length
        ) {
          const visibleTemplates = this.layoutOptions.groupsTemplates.flatMap((group) => group.instancesId);

          settings.selectedGroupInstances.forEach(selectedGroupInstanceIndex => {
            let groupInstances = this.groupInstancesMap.find(instance => instance.group === selectedGroupInstanceIndex);

            if (groupInstances) {
              groupInstances = groupInstances.instances;

              for (const groupInstance of groupInstances) {
                if (!this.instances.find(instance => instance.instance_id === groupInstance)) {
                  continue;
                }

                if (this.disableUnrenderedTemaplates && !visibleTemplates.includes(groupInstance)) {
                  continue;
                }

                this.selectedGroupInstances.push(groupInstance);
              }
            }
          });

          this.selectedGroupInstances = [...this.selectedGroupInstances];
        }

        // selected size scale down by dimensions applyable only for single instance
        if (settings.sizeOptions.type === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_DIMENSIONS) {
          this.selectedSize = this.selectedInstances.length === 1
            ? settings.sizeOptions.type
            : SIZE_OPTIONS_TYPE.KEEP_ORIGINAL;
        } else {
          this.selectedSize = settings.sizeOptions.type;
        }

        this.$nextTick(() => {
          setTimeout(() => {
            if (this.selectedFolder) {
              this.$refs.selectedFolderInput.handleFolderChange(this.selectedFolder, true);
            }
            if (this.selectedSize === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_PERCENTAGE) {
              this.size.percentage = settings.sizeOptions.scalePercentage;
            } else if (this.selectedSize === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_DIMENSIONS) {
              this.size.dimensions.width = settings.sizeOptions.dimensions.width;
              this.size.dimensions.height = settings.sizeOptions.dimensions.height;
            }
          }, 0);
        });

        if (this.compressionCanBeApplied) {
          this.compressToMaxFileSize = settings.compressionOptions.compress;
          this.compressToMaxFileSizeValue = settings.compressionOptions.compressValue;
        }
      })
    },
    onExportTypeUpdated() {
      this.setSavedSettingsToNull()
      if (this.selectedFormat === 'pdf') {
        this.dpi = 300;
        this.selectedDpi = 300;
      }
    },
    setSavedSettingsToNull() {
      this.selectedDownloadSettings = null;
    },
    updateDpi(selectedDpi) {
      if (selectedDpi === 0) {
        return
      }

      this.dpi = selectedDpi;
      this.setSavedSettingsToNull();
    },
    async loadFolders() {
      try {
        const response = await folderService.getFolders();
        this.folders = response.data.items;
      } catch (error) {
        console.error('Failed to load folders:', error);
      }
    },
    handleCheckboxChange(changedField) {
      switch (changedField) {
        case 'compressToMaxFileSize':
          this.useTemplateSettings = false;
          break;
        case 'useTemplateSettings':
          this.useTemplateSettings = !!this.useTemplateSettings
          if (this.useTemplateSettings) {
            this.selectedInstances.map(instance => {
              let newName = instance.name;
              if (this.uniqueTemplateSettings && this.uniqueTemplateSettings[instance.instance_id]) {
                if (this.uniqueTemplateSettings[instance.instance_id]['DPI']) {
                  newName += ' (DPI: ' + this.uniqueTemplateSettings[instance.instance_id]['DPI'];
                }

                if (this.uniqueTemplateSettings[instance.instance_id]['Max File Size']) {
                  newName += ', Max file size: ' + this.uniqueTemplateSettings[instance.instance_id]['Max File Size'] + 'kb';
                }

                if (this.uniqueTemplateSettings[instance.instance_id]['File Type']) {
                  newName += ', File Type: ' + this.uniqueTemplateSettings[instance.instance_id]['File Type'];
                }
              }
              if (newName !== instance.name) {
                instance.orig_name = instance.name;
                instance.name = newName + ')';
              }
            })
          } else {
            this.selectedInstances.map(instance => {
              // ignore empty
              if (!instance.orig_name) return false;
              instance.name = instance.orig_name;
            })
          }
          break;
        default:
          this.compressToMaxFileSize = false;
          this.useTemplateSettings = false;
          break;
      }

      this.setSavedSettingsToNull();
    },
    chackDefaultPaginationData() {
      // for PDF default is true, for PPTX default is false
      this.pageNumeration.isEnabled = ['pdf', 'pptx'].includes(this.selectedFormat)
        ? this.selectedFormat === 'pdf'
        : this.pageNumeration.isEnabled;
    }
  }
}
</script>
<style lang="scss">
@media (min-width: 576px) {
    #export-templates-modal .modal-dialog {
        width: 900px !important;
        max-width: 900px !important;
    }
}
.dimensions-container {
  input {
    display: inline-block;
    width: 30%;
  }
}

.container-downloadable-templates .multiselect__tags {
    max-height: 135px;
    overflow: scroll;
}

.export-pdf-groups-dropdown {
  &__option {
    pointer-events: none;
    
    input {
      display: none;
    }
  }
}
textarea.json-editor {
  width: 100%; /* Заполнит всю доступную ширину */
  height: 300px; /* Высота редактора */
  font-family: monospace; /* Шрифт, удобный для кода */
  padding: 10px; /* Внутренние отступы */
  border: 1px solid #ccc; /* Граница редактора */
  border-radius: 5px; /* Скругленные углы */
  box-sizing: border-box; /* Учет отступов и границ в размере */
  resize: vertical; /* Пользователь может изменять только высоту */
}

#export-templates-modal .modal-body {
  padding: 0 !important;

  .card {
    border: none !important;
    margin-bottom: 0 !important;

    .tabs {
      .card-header {
        background-color: transparent !important;
      }
    }
  }
}

.my-tooltip-inner {
  max-width: 300px !important;
  white-space: normal;
}

#export-templates-modal {
  label {
    cursor: pointer;
    
    input[type="checkbox"] {
      cursor: pointer;
    }
  }
}
</style>
