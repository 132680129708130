<template>
  <b-modal
    id="transformations-modal"
    ref="transformationsModal"
    title="Field Transformation"
    size="lg"
    scrollable
    @close="hideModal"
  >
    <b-row
      v-for="(transformation, index) in transformations"
      :key="index"
      class="p-1"
      :class="{'border-bottom': index !== transformations.length - 1}"
    >
      <b-col cols="11">
        <TransformationForm
          ref="transFomrationRefs"
          :transformation="Array.isArray(transformation) ? transformation[0] : transformation"
          :scripts="scripts"
          :xlsx-headers="xlsxHeaders"
        />
      </b-col>

      <b-col
        cols="1"
        class="d-flex justify-content-center align-items-center"
      >
        <button
          v-if="index !== transformations.length - 1"
          class="btn btn-danger"
          @click="removeTransformation(index)"
        >
          <i class="bi bi-trash" />
        </button>
      </b-col>
    </b-row>

    <template #modal-footer>
      <div>
        <b-button
          variant="secondary"
          @click="hideModal"
        >
          Cancel
        </b-button>

        <b-button
          variant="primary"
          @click="addTransformation"
        >
          Add another transformation
        </b-button>

        <b-button
          variant="primary"
          @click="saveTransformations"
        >
          Save transformation settings
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import TransformationForm from "@frontend/components/modules/edit-page/import/transformations/TransformationForm.vue";
import { transformationsEventBroker, TRANSFORMATIONS_EVENTS } from "@frontend/components/modules/edit-page/import/transformations/event-broker";

export default {
  name: 'TransformationsModal',
  components: {
    TransformationForm,
  },
  data() {
    return {
      transformations: [],
      containerQuery: null,
      templateId: null,
      templateFieldName: null,
      templateFieldIndex: null,
      scripts: [],
      xlsxHeaders: [],
    }
  },
  mounted() {
    window.transformationsModal = this;
  },
  methods: {
    showModal(eventData) {
      this.transformations = eventData.transformationSettings;
      this.containerQuery = eventData.containerQuery;
      this.templateId = eventData.templateId;
      this.templateFieldName = eventData.templateFieldName;
      this.templateFieldIndex = eventData.templateFieldIndex;
      this.xlsxHeaders = eventData.xlsxHeaders;

      if (this.transformations.length === 0) {
        this.addTransformation();
      }

      this.$refs.transformationsModal.show();
    },
    hideModal() {
      this.$refs.transformationsModal.hide();
      transformationsEventBroker.fire(TRANSFORMATIONS_EVENTS.HIDE_TRANSFORMATIONS_MODAL);
    },
    addTransformation() {
      this.transformations.push({transformation: null, variables: {}});
    },
    removeTransformation(index) {
      this.transformations.splice(index, 1);
    },
    saveTransformations() {
      const transformations = this.collectTransformations();

      transformationsEventBroker.fire(TRANSFORMATIONS_EVENTS.UPDATE_TRANSFORMATIONS, {
        transformations: transformations,
        containerQuery: this.containerQuery,
        templateId: this.templateId,
        templateFieldName: this.templateFieldName,
        templateFieldIndex: this.templateFieldIndex,
      });

      this.hideModal();
    },
    collectTransformations() {
      return this.$refs.transFomrationRefs.map(transformationComponent => transformationComponent.getTransformationData())
        .filter(transformation => transformation.transformation);
    },
    setScripts(scripts) {
      this.scripts = scripts;
    },
  },
}
</script>