var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "justify-content-end" },
        [
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "2" } },
            [
              _c("b-form-select", {
                attrs: { options: _vm.ownerOptions, size: "sm" },
                on: { change: _vm.loadType },
                model: {
                  value: _vm.selectedOwner,
                  callback: function ($$v) {
                    _vm.selectedOwner = $$v
                  },
                  expression: "selectedOwner",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("table", { staticClass: "table table-sm" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.settings, function (setting) {
              return _c("tr", { key: setting.id }, [
                _c("td", { staticClass: "col-8" }, [
                  _vm._v("\n          " + _vm._s(setting.name) + "\n        "),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "col-2" }, [
                  _vm._v("\n          " + _vm._s(setting.type) + "\n        "),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "col-2" },
                  [
                    setting.type !== "system" || _vm.isMasterAdmin
                      ? [
                          _c("i", {
                            staticClass:
                              "cursor-pointer text-secondary fas fa-edit mr-2",
                            on: {
                              click: function ($event) {
                                return _vm.showEditModal(setting)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("i", {
                            staticClass:
                              "cursor-pointer text-secondary fas fa-trash",
                            on: {
                              click: function ($event) {
                                return _vm.showDeleteModal(setting)
                              },
                            },
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            }),
            _vm._v(" "),
            _vm.isLoading
              ? _c("tr", [
                  _c("td", { staticClass: "col-12 text-center p-5" }, [
                    _vm._v("\n          Loading...\n        "),
                  ]),
                ])
              : _vm.settings.length === 0
              ? _c("tr", [
                  _c("td", { staticClass: "col-12 text-center p-5" }, [
                    _vm._v("\n          No settings found\n        "),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-center" },
        [
          _c("b-pagination", {
            attrs: { "total-rows": _vm.totalItems, "per-page": _vm.perPage },
            on: { change: _vm.loadPage },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("EditDownloadSettingsModal", {
        ref: "editDownloadSettingsModal",
        on: { "setting-updated": _vm.onSettingUpdated },
      }),
      _vm._v(" "),
      _c("DeleteDownloadSettingsModal", {
        ref: "deleteDownloadSettingsModal",
        on: { "setting-deleted": _vm.onSettingUpdated },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "col-8" }, [
          _vm._v("\n          Name\n        "),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "col-2" }, [
          _vm._v("\n          Type\n        "),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "col-2" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }