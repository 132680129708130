import Pickr from "@simonwep/pickr";
import "@simonwep/pickr/dist/themes/nano.min.css";
import {FILE_TYPE} from "./constants/file-image-types";
import fileDocSvg from "./templates/file_image/file-doc-svg";
import filePdfSvg from "./templates/file_image/file-pdf-svg";
import filePsdSvg from "./templates/file_image/file-psd-svg";
import fileXlsSvg from "./templates/file_image/file-xls-svg";
import fileZipSvg from "./templates/file_image/file-zip-svg";
import fileTiffSvg from "./templates/file_image/file-tiff";
import fileSvg from "./templates/file_image/file-svg";
import fileMp4Svg from "./templates/file_image/file-mp4-svg";
import fileMp3Svg from "./templates/file_image/file-mp3-svg";
import fileAviSvg from "./templates/file_image/file-avi-svg";
import fileMovSvg from "./templates/file_image/file-mov-svg";
import filePptSvg from "./templates/file_image/file-ppt-svg";
import filePptxSvg from "./templates/file_image/file-pptx-svg";
import fileLoadingSvg from "./templates/file_image/file-loading-svg";
import fileAnySvg from "./templates/file_image/file-any-svg";
import fileGifSvg from "./templates/file_image/file-gif-svg";
import fileWebmSvg from "./templates/file_image/file-webm-svg";
import fileLink from "./templates/file_image/file-link";
import folderSvg from "./templates/file_image/folder-svg";
import folderUpSvg from "./templates/file_image/folder-up-svg";
import fileAiSvg from './templates/file_image/file-ai-svg'
import fileEpsSvg from './templates/file_image/file-eps-svg'
import fileLayoutSvg from "@frontend/templates/file_image/file-layout";
import { PRIMARY_MODE, SECONDARY_MODE } from '@frontend/group/modules/dominant-colors';
import {
  FIELD_TYPE_MULTI_LINE_TEXT,
  FIELD_TYPE_TEXT,
  FIELD_TYPE_TEXT_FROM_SPREADSHEET,
  FIELD_TYPE_TEXT_OPTIONS
} from '@frontend/constants/type-fields-of-template';
import { TEXT_PREFIX_ID } from '@frontend/constants/templates-field-prefixes';
import { fabric } from 'fabric';
import { SettingsTemplateData } from '@frontend/group/modules/classes-for-preview-data/settings-template-data';
import { parseJsonData } from '@frontend/services/helpers';
import { ShowPageScopeCollector } from '@frontend/group/modules/scope-collectors/show-page-collector';

const getShowPageScope = () => ShowPageScopeCollector.get([
  'previews',
  'getIsLightweight',
]);

const eyeDroppers = []

export function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const deepCopy = (a) => {
  if (!a) return a;
  return JSON.parse(JSON.stringify(a));
};

export const isValidJson = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export function isValidHexColor (color) {
  return /^#[0-9a-fA-F]{6}$/.test(color)
}

export function drawFileSvg(element) {
  switch (element.type) {
    case FILE_TYPE.FOLDER:
      if (element.name !== undefined && element.name == '...'){
        return folderUpSvg();
      }else{
        return folderSvg();
      }
    case FILE_TYPE.DOC:
      return fileDocSvg();
    case FILE_TYPE.PDF:
      if (!element.thumbnail_path) {
        return filePdfSvg();
      }
      if (element.is_processing) {
        return fileLoadingSvg();
      }
      return drawFileImageThumbnail(element);
    case FILE_TYPE.XLS:
      return fileXlsSvg();
    case FILE_TYPE.ZIP:
      return fileZipSvg();
    case FILE_TYPE.PSD:
      if (!element.thumbnail_path) {
        return filePsdSvg();
      }
      if (element.is_processing) {
        return fileLoadingSvg();
      }
      return drawFileImageThumbnail(element);
    case FILE_TYPE.SVG:
      if (!element.thumbnail_path) {
        return fileSvg();
      }
      if (element.is_processing) {
        return fileLoadingSvg();
      }
      return drawFileImageThumbnail(element);
    case FILE_TYPE.TIFF:
      if (!element.thumbnail_path) {
        return fileTiffSvg();
      }
      if (element.is_processing) {
        return fileLoadingSvg();
      }
      return drawFileImageThumbnail(element);
    case FILE_TYPE.MP4:
      if (element.is_processing) {
        return fileLoadingSvg();
      }
      return drawFileVideoPreview(element);
    case FILE_TYPE.MP3:
      return fileMp3Svg()
    case FILE_TYPE.AVI:
      return fileAviSvg()
    case FILE_TYPE.MOV:
      if (!element.thumbnail_path) {
        return fileMovSvg();
      }
      if (element.is_processing) {
        return fileLoadingSvg();
      }
      return drawFileImageThumbnail(element);
    case FILE_TYPE.PPT:
      return filePptSvg();
    case FILE_TYPE.PPTX:
      return filePptxSvg();
    case FILE_TYPE.GIF:
      return fileGifSvg()
    case FILE_TYPE.WEBM:
      return fileWebmSvg()
    case FILE_TYPE.IMAGE:
      if (element.image_type === FILE_TYPE.GIF) {
        return fileGifSvg();
      }
      if (element.is_processing) {
        return fileLoadingSvg();
      }
      return drawFileImageThumbnail(element);
    case FILE_TYPE.LINK:
      return fileLink();
    case FILE_TYPE.AI:
      return fileAiSvg()
    case FILE_TYPE.EPS:
      return fileEpsSvg()
    case FILE_TYPE.LAYOUT:
      return fileLayoutSvg()
    default:
      return fileAnySvg();
  }
}

function drawFileVideoPreview(element) {
    let return_draw = '';
    if (element.thumbnail_path)
      return_draw = `<img alt="image" src="${(element.thumbnail_path ? element.thumbnail_path : element.path) + '&is_thumbnail=1'}" loading="lazy"/>`;
    else
      return_draw = fileMp4Svg();

      return_draw +=  `<div class="video-container" data-video-src="${element.path}"></div>`;
    return return_draw
  ;
}


function drawFileImageThumbnail(element) {
  return "<img alt='image' src='" + (element.thumbnail_path || element.url || element.path) + "&is_thumbnail=1' loading='lazy'/>";
}

export function initColorPicker() {
  $(`.color-picker-container .pickr`).each((index, el) => {
    const $colorInput = $(el).siblings(".color-picker-input").first();
    const elementId = $colorInput.attr("id");
    const pickr = new Pickr({
      el,
      container: "body",
      theme: "nano",
      swatches: [],
      components: {
        preview: true,
        hue: true,
        opacity: true,
        interaction: {
          hex: true,
          rgba: true,
          hsla: true,
          input: true,
        },
      },
      default: $colorInput.val(),
    }).on("changestop", (source, instance) => {
      instance.applyColor();
      let color = pickr.getColor();
      const hexColor = color.toHEXA().toString();
      let inputId = '';
      if (elementId.includes('_fill')) {
        inputId = elementId.replace('_fill_color', '_fill_color_hex');
      }else {
        inputId = elementId.replace('_color', '_color_hex');
        $(`div.dropdown #dropdownMenuButton_${inputId.replace('_color_hex' ,'')} .glyphicon-text-color`).css({
          color: hexColor
        })
        $(`#${inputId}`).trigger('change');
      }
      document.getElementById(inputId).value = hexColor;
      $colorInput.val(hexColor).trigger('change');

    });

    $colorInput.on("change", async function (event) {
      try {
        const currentValue = pickr.getColor().toRGBA.toString();
        const body = { color: currentValue };

        await axios.put("/user/color", body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } catch (e) {
        return false;
      }
    });

    const root = pickr.getRoot()
    root.interaction.options.forEach(option => {
      option.defaultValue = option.defaultValue.substring(0, 3)
    })

    eyeDroppers.push($(
      '<a href="#"><i class="c-icon cil-eyedropper"></i></a>',
    ).on('click', function (e) {
      e.preventDefault()
      new EyeDropper()
        .open()
        .then(result => pickr.setColor(result.sRGBHex))

      $(this).blur();
    }));

    $(document).on('keyup', function (e) {
      if (e.keyCode === 13) {
        pickr.hide();
      }
    })
  });

  $('.pcr-interaction').each((index, el) => {
    $(el).append(eyeDroppers[index])
  })
}

export function getFileNameFromURL(url) {
  let full_url = url;
  const url_ary = url.split('?');
  if (url_ary && url_ary.length > 1) {
    full_url = url_ary[0];
  }
  const full_url_ary = full_url.split('/');
  let file_name = '';
  let file_extension = '';
  if (full_url_ary && full_url_ary.length > 0) {
    file_name = full_url_ary[full_url_ary.length - 1];
  }
  if (file_name && file_name.indexOf('.') > -1) {
    const file_name_ary = file_name.split('.');
    file_extension = file_name_ary[file_name_ary.length - 1];
    file_name = file_name.replace('.' + file_extension, '');
  }
  if (!file_name && full_url_ary.length >= 3) {
    file_name = full_url_ary[2];
  }
  const fileName = file_name ? file_name : new Date().getTime();
  return fileName;
}

export function fetchFileData(url) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        let full_url = url;
        const url_ary = url.split('?');
        if (url_ary && url_ary.length > 1) {
          full_url = url_ary[0];
        }
        const full_url_ary = full_url.split('/');
        let file_name = '';
        let file_extension = '';
        if (full_url_ary && full_url_ary.length > 0) {
          file_name = full_url_ary[full_url_ary.length - 1];
        }
        if (file_name && file_name.indexOf('.') > -1) {
          const file_name_ary = file_name.split('.');
          file_extension = file_name_ary[file_name_ary.length - 1];
          file_name = file_name.replace('.' + file_extension, '');
        }
        if (!file_extension) {
          file_extension = blob.type.split('/')[1]
        }
        const fileName = (file_name ? file_name : new Date().getTime()) + '.' + file_extension;
        const file = new File([blob], fileName, { type: blob.type, path: url });
        resolve(file);
      })
      .catch(err => {
        toastr.error('There are some errors to get file content. Try again with proper file url.');
        reject(err);
      })
  })
}

export function copyTextToClipboard(text) {
  var textArea = document.createElement( "textarea" );
  textArea.value = text;
  document.body.appendChild( textArea );
  textArea.select();

  try {
    var successful = document.execCommand( 'copy' );
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Copying text command was ' + msg);
  } catch (err) {
    console.log('Oops, unable to copy',err);
  }
  document.body.removeChild( textArea );
}
export function getOrderForAddField(fields) {
  let order = 2000;
  for (let field of fields) {

    if (typeof field.order !== 'number') {
      continue
    }
    if (field.order < order) {
      order = field.order;
    }
  }
  return order - 1;
}
export function getArrayByFileIds(fileIds) {
  let filedIdsArray = [];
  
  if (fileIds.includes(' ')) {
    filedIdsArray = fileIds.split(' ');
  }
  if (fileIds.includes(',')) {
    filedIdsArray = fileIds.split(',')
  }
  if (!fileIds.includes(' ') && !fileIds.includes(',')) {
    filedIdsArray = [fileIds];
  }
  return filedIdsArray
}
export function getFileIdByProductObjectId(fileIds, objectId) {
  const fileIndex = objectId.split('_').reverse()[0];
  let filedIdsArray = getArrayByFileIds(fileIds);
  return filedIdsArray.at(fileIndex);
}

export function isURL(str) {
  // Check if the string is a URL (absolute or relative)
  const pattern = new RegExp('^(https?:\\/\\/)?(\\/[^\\s]*)?$');
  return !!pattern.test(str);
}

export function setURLParameters(url, params) {
  // Check if the URL is absolute or relative
  const isAbsoluteURL = url.startsWith('http://') || url.startsWith('https://');
  const baseURL = isAbsoluteURL ? url : window.location.origin + url;

  // Create a URL object to work with parameters
  const urlObj = new URL(baseURL);

  // Add or update parameters
  for (const [key, value] of Object.entries(params)) {
    urlObj.searchParams.set(key, value);
  }

  // Return the updated URL
  return isAbsoluteURL ? urlObj.href : urlObj.pathname + urlObj.search;
}

export function deleteURLParameters(url, params) {
  // Check if the URL is absolute or relative
  const isAbsoluteURL = url.startsWith('http://') || url.startsWith('https://');
  const baseURL = isAbsoluteURL ? url : window.location.origin + url;

  // Create a URL object to work with parameters
  const urlObj = new URL(baseURL);

  // Delete specified parameters
  for (const param of params) {
    urlObj.searchParams.delete(param);
  }

  // Return the updated URL
  return isAbsoluteURL ? urlObj.href : urlObj.pathname + urlObj.search;
}

export function getURLParametersByKey(url, paramKey) {
  // Check if the URL is absolute or relative
  const isAbsoluteURL = url.startsWith('http://') || url.startsWith('https://');
  const baseURL = isAbsoluteURL ? url : window.location.origin + url;

  // Create a URL object to work with parameters
  const urlObj = new URL(baseURL);
 
  return urlObj.searchParams.get(paramKey);
}

export function getPrimarySecondaryColorFromTheme(color, selectedTheme){
  if ((color === PRIMARY_MODE || color === SECONDARY_MODE) && selectedTheme){
    let theme_color = selectedTheme.getColorByName(color);
    if (theme_color)
      return (theme_color)

  }
  return color;
}

export function isInGroup(groupFields, fieldName) {
  const fieldNames = groupFields.split(',');
  for (const name of fieldNames) {
    if (name.trim() === fieldName) {
      return true;
    }
  }

  return false;
}

export function getPositioningOption(positioning_options, data, fields, field) {
  if (typeof positioning_options !== "undefined") {
    const textFields = fields.filter(
      ({ type }) => [
        FIELD_TYPE_TEXT,
        FIELD_TYPE_TEXT_OPTIONS,
        FIELD_TYPE_TEXT_FROM_SPREADSHEET,
        FIELD_TYPE_MULTI_LINE_TEXT
      ].includes(type)
    );
    const fieldFlags = {};
    Object.keys(data)
      .filter(
        (key) =>
          (key.startsWith(TEXT_PREFIX_ID) ||
            key.startsWith("multi_line_text_")) &&
          !key.endsWith("_offset_x") &&
          !key.endsWith("_offset_y") &&
          !key.endsWith("_angle")
      )
      .map((key) => {
        const field = textFields.find((field) => field.element_id === key);
        if (field) {
          fieldFlags[field.name] = !!data[key];
        }
      });

    const parsedOptions = positioning_options.map((option) => {
      const parsedOption = {};
      option.fields.map(({ field_name, fields, x, y, width }) => {
        parsedOption[field_name] = { fields, x, y, width };
      });
      return parsedOption;
    });

    for (const option of parsedOptions) {
      let res = true;
      for (const key in option) {
        if (
          (option[key].fields == 1 && fieldFlags[key] !== true) ||
          (option[key].fields == null && fieldFlags[key] == true)
        ) {
          res = false;
          break;
        }
      }
      if (res) {
        return option[field.name];
      }
    }
  }
  return null;
}

export function getShadowFabricInstance(instanceId, elementId, shadows = null, bShadow = null, scaleX = 1, scaleY = 1) {
  if (!$('#shadow_effects').is(':checked')) return null

  const customShadow = canvas_data[instanceId]?._data[`${elementId}_custom_shadow`]
  if (customShadow) {
    return new fabric.Shadow(JSON.parse(customShadow))
  }

  if (!(shadows?.shadow && bShadow && shadows.shadow.length)) return null

  const sh = shadows.shadow[bShadow - 1].list;

  return new fabric.Shadow({
    angle: sh[1].value,
    distance: sh[2].value,
    color: "#000000" + parseInt(String(2.5 * sh[0].value)).toString(16),
    blur: Math.ceil(sh[4].value / scaleX),
    offsetX: -sh[2].value * Math.cos((sh[1].value * Math.PI) / 180) / scaleX,
    offsetY: sh[2].value * Math.sin((sh[1].value * Math.PI) / 180) / scaleY,
  });
}

export function getLockValue (instanceId, elementId) {
  return canvas_data[instanceId]?._data[`${elementId}_locked`] || false
}

export function removeObjectFromCanvas(canvas, search) {
  canvas.getObjects().forEach(function (object) {
    if (object.id && object.id.includes(search)) {
      canvas.remove(object);
    }
  });
}

export async function createMirror(oImg, elementId, canvas, data) {
  return await new Promise((resolve) => {
    oImg.clone(async (copy) => {
      let mirrorPosition;
      let distance;
      let skew;
      let fade;
      let coords;
      if (oImg.id.startsWith('bk_theme_img')) {
        mirrorPosition = data[`bk_img_mirror_position`][elementId];
        distance = parseInt(data[`bk_img_mirror_distance`][elementId]);
        skew = parseInt(data[`bk_img_mirror_skew`][elementId]);
        fade = data[`bk_img_mirror_fade`][elementId] === "true";
        coords = JSON.parse(data[`bk_img_mirror_coords`][elementId]);
      } else {
        mirrorPosition = data[`${elementId}_mirror_position`];
        distance = parseInt(data[`${elementId}_mirror_distance`]);
        skew = parseInt(data[`${elementId}_mirror_skew`]);
        fade = data[`${elementId}_mirror_fade`] === "true";
      }
      const flipY = mirrorPosition === "top" || mirrorPosition === 'bottom';
      let left =  oImg.left;
      const xTranslate = oImg.width * oImg.scaleX + distance;
      let top = oImg.top;
      const yTranslate = oImg.height * oImg.scaleY + distance;
      switch (mirrorPosition) {
        case 'top':
          top -= yTranslate;
          break;
        case 'right':
          left += xTranslate;
          break;
        case 'bottom':
          top += yTranslate;
          break;
        case 'left':
          left -= xTranslate;
          break;
      }
      const deleteSource = data[`${elementId}_mirror_delete_source`] === 'true';

      copy.set({
        id: oImg.id + '_mirror',
        flipY: flipY,
        flipX: !flipY,
        scaleX: oImg.scaleX,
        scaleY: oImg.scaleY,
        left: left,
        top: top,
        groupName: oImg.groupName,
        element_id: oImg.element_id + '_mirror',
        order: oImg.order,
        fieldId: oImg.fieldId,
      });
      
      if (oImg.angle && oImg.angle !== 0) {
        const angleRad = Math.abs(oImg.angle) * Math.PI / 180;
        const betweenX = (oImg.width * oImg.scaleX * Math.cos(angleRad)) + (oImg.height * oImg.scaleY * Math.sin(angleRad));
        const betweenY = (oImg.width * oImg.scaleX * Math.sin(angleRad)) + (oImg.height * oImg.scaleX * Math.cos(angleRad));
        copy.set({angle: - oImg.angle});
        
        switch (mirrorPosition) {
          case 'top':
            copy.set({top: oImg.top - betweenY});
            break;
          case 'right':
            copy.set({left: oImg.left + betweenX});
            break;
          case 'bottom':
            copy.set({top: oImg.top + betweenY});
            break;
          case 'left':
            copy.set({left: oImg.left - betweenX});
            break;
        }
      }

      if (skew !== 0) {
        const xSkewTranslate = (Math.tan(skew * Math.PI / 180) * copy.height * copy.scaleY) / 2;
        const ySkewTranslate = (Math.tan(skew * Math.PI / 180) * copy.width * copy.scaleY) / 2;
        
        switch (mirrorPosition) {
          case 'top':
            copy.set({
              skewX: skew,
              left: left + xSkewTranslate,
            });
            break;
          case 'right':
            copy.set({
              skewY: skew,
              top: top - ySkewTranslate,
            });
            break;
          case 'bottom':
            copy.set({
              skewX: skew,
              left: left - xSkewTranslate,
            });
            break;
          case 'left':
            copy.set({
              skewY: skew,
              top: top + ySkewTranslate,
            });
            break;
        }
      }
      if (deleteSource) {
        canvas.remove(oImg);
      }
      
      if (fade) {
        const backgroundColor = 'white';
        var rect = new fabric.Rect();
        
        rect.set({
          id: 'gradient',
          left: left - copy.width * copy.scaleX / 2,
          top: top - copy.height * copy.scaleY / 2,
          opacity: 1,
          width: copy.width * copy.scaleX,
          height: copy.height * copy.scaleY,
          order: copy.order + 1,
        })

        let gradientSettings = {
          type: 'linear',
          gradientUnits: 'pixels',
          coords: {
            x1: 0,
            y1: 0,
            x2: rect.width,
            y2: 0
          },
          colorStops:[
            { offset: 0, color: backgroundColor, opacity: 0 },
            { offset: 1, color: backgroundColor, opacity: 1},
          ]
        }
        
        switch (mirrorPosition) {
          case 'top':
            gradientSettings.coords.x2 = 0;
            gradientSettings.coords.y1 = rect.height;
            break;
          case 'bottom':
            gradientSettings.coords.x2 = 0;
            gradientSettings.coords.y2 = rect.height;
            break;
          case 'left':
            gradientSettings.coords.x2 = 0;
            gradientSettings.coords.x1 = rect.width;
            break;
        }
        
        rect.fill = new fabric.Gradient(gradientSettings);
        copy.set({opacity:0.4});
      }
      
      if (fade) {
        canvas.add(copy, rect);
      } else {
        canvas.add(copy);
      }
      
      await new Promise((resolve) => {
        let settings = {
          id: oImg.id,
          groupName: oImg.groupName,
          element_id: oImg.element_id,
          order: oImg.order,
          selectable: oImg.selectable,
          evented: oImg.evented,
          fieldId: oImg.fieldId ? oImg.fieldId : `mirror_group_${elementId}`,
        }
        
        if (coords?.left !== undefined || coords.top !== undefined) {
          settings = {...settings,left: coords.left,
            top: coords.top}
        }
        
        const grouped = fade ? [oImg, copy, rect] : [oImg, copy];
        const group = new fabric.Group(grouped, settings);
        
        canvas.remove(copy);
        
        if (fade) {
          canvas.remove(rect);
        }
        
        canvas.add(group);
        resolve();
      })
      
      resolve();
    });
  })
}


export function setOrder(canvas) {
  let order = 1;
  const objects = canvas.getObjects();
  const objects_ordered = [];
  
  objects.sort(function (a, b) {
    return b.order - a.order;
  });
  
  objects.forEach(function (element) {
    if (element.order) {
      objects_ordered[order] = element;
      order ++;
    }
    
    canvas.bringToFront(element);
  });
  
  objects_ordered.slice().forEach(function (x) {
    canvas.bringToFront(x);
  });
}

export function updateBoundingBoxInfo(formData, templateId) {
  const EXCLUDE_KEYS = ['type', 'element_ids[]'];
  const grid = $('#template-group-preview');
  const customer_id = grid.attr('data-customer-id');
  const layout_id = grid.attr('data-layout-id');
  const url = '/banner/' + customer_id + '/group/' + layout_id + '/template/' + templateId + '/update_bounding_box';
  
  axios({
    method: 'post',
    url: url,
    data: formData,
  }).then((res) => {
    const preview = previews.find(({ id }) => id === templateId);
    
    if (!preview) {
      return;
    }
    
    for (const [key, value] of formData.entries()) {
      if (EXCLUDE_KEYS.includes(key)) {
        continue;
      }
      
      preview.data[key] = value;
    }
  });
}

export function getSettingsDataInstance(settingData) {
  return new SettingsTemplateData(parseJsonData(settingData, {
    fields: []
  }));
}

const getPreview = () => {
  const { previews = [] } = getShowPageScope() ?? {};

  return previews;
};

export function unFocusObjects(activeObject) {
  getPreview().forEach(({ canvas }) => {
    if (activeObject === canvas.getActiveObject()) return;
    
    if (canvas.getActiveObject()) {
      canvas.discardActiveObject();
      canvas.renderAll();
    }
  });
}

export async function calculateDataForAllProductImages(files, canvasData, data) {
  const product = canvasData.product;
  const productImageSettings = canvasData.product_image_settings;
  const smartObjCoords = canvasData.smartObjCoords;
  const productAutoPositioning = canvasData?._data?.product_auto_positioning;

  let product_width = +product['width'];
  let product_height = +product['height'];

  let left = 0;
  const top = +product['top'];
  const margin = +data['product_space_product_space'] || 0;

  let auto_position = true;
  if (['0', 'false', false, 0].includes(productAutoPositioning)) {
    auto_position = false;
  }

  if (files.length > productImageSettings.length) {
    files = files.slice(0, productImageSettings.length);
  }

  let sum_width_dimension = 0;
  let gname;

  files.forEach((file, index) => {
    if (productImageSettings[index]['Option1'] !== 'Hero') {
      if (auto_position) {
        sum_width_dimension += (file.related_files?.[0]?.width || 0);
      }
    }

    if (productImageSettings[index]['Group Name']) {
      gname = productImageSettings[index]['Group Name'];
    }
  });

  if (smartObjCoords[gname] && smartObjCoords[gname]['Width'] && smartObjCoords[gname]['Height']) {
    product_width = +smartObjCoords[gname]['Width'];
    product_height = +smartObjCoords[gname]['Height'];
  }

  product_width -= margin * (files.length - 1);

  let imagesData = await Promise.all(
    files.map((file) => loadFabricImage(file.related_files[0], sum_width_dimension, product_width)
    )
  );

  const maxHeight = imagesData.reduce(
    (acc, { height }) => acc < height ? height : acc, 0
  );

  let imageRatio = 1;

  if (auto_position) {
    imageRatio = maxHeight > product_height ? product_height / maxHeight : 1;
  }

  imagesData = imagesData.map((item) => {
    if (!auto_position) {
      imageRatio = product_height / item.height;
    }
    item.width *= imageRatio;
    item.height *= imageRatio;

    return item;
  });
  
  let totalWidth = 0;
  if (auto_position) {
    totalWidth = imagesData.reduce((acc, { width }) => acc + width, 0);
  } else {
    totalWidth = 0;
  }

  if (product['alignment'] === 'center') {
    left += (product_width - totalWidth) / 2;
  } else if (product['alignment'] === 'right') {
    left += product_width - totalWidth;
  }

  return {
    left,
    top,
    product_height,
    margin,
    imagesData
  };
}

function loadFabricImage(file, sum_width_dimension, product_width) {
  return new Promise((resolve) => {
    const { getIsLightweight } = getShowPageScope();
    const is_lightweight = getIsLightweight();

    const url = file.hasOwnProperty('is_url') && file.is_url === true
      ? file.path
      : `/share?file=${file.path}` + is_lightweight;

    // for testing on local
    // url = 'https://testing.rapidads.io' + url;

    fabric.Image.fromURL(url, (oImg) => {
      if (!oImg) {
        console.error('Image loading failed');
        return;
      }

      let width;
      if (sum_width_dimension) {
        width = (product_width * file.width) / sum_width_dimension;
      } else {
        width = product_width;
      }

      const ratio = width / oImg.width;
      let height = oImg.height * ratio;

      if (file.hasOwnProperty('is_url') && file.is_url === true) {
        oImg.set({ fileName: file.path });
      } else {
        // split on the last occurence of the '.' character
        oImg.set({ fileName: (file?.name || '').split('.').slice(0, -1).join('.') });
      }

      resolve({ image: oImg, width, height });
    });
  });
}