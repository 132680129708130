var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "save-layout-download-settings-modal",
        title: "Save settings",
        centered: "",
      },
      on: { ok: _vm.onOk, cancel: _vm.onCancel },
    },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Name", "label-for": "saved-settings-name" } },
        [
          _c("b-form-input", {
            staticClass: "form-control",
            attrs: { id: "saved-settings-name", type: "text" },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        [
          _c(
            "b-form-radio",
            {
              attrs: { value: "user" },
              model: {
                value: _vm.owner,
                callback: function ($$v) {
                  _vm.owner = $$v
                },
                expression: "owner",
              },
            },
            [_vm._v("\n      Save for me\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-form-radio",
            {
              attrs: { value: "company" },
              model: {
                value: _vm.owner,
                callback: function ($$v) {
                  _vm.owner = $$v
                },
                expression: "owner",
              },
            },
            [_vm._v("\n      Save for company\n    ")]
          ),
          _vm._v(" "),
          _vm.isMasterAdmin
            ? _c(
                "b-form-radio",
                {
                  attrs: { value: "system" },
                  model: {
                    value: _vm.owner,
                    callback: function ($$v) {
                      _vm.owner = $$v
                    },
                    expression: "owner",
                  },
                },
                [_vm._v("\n      Save for system\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }