var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex-column flex-grow-1",
      class: { "d-none": _vm.hidden, "d-flex": !_vm.hidden },
      staticStyle: { height: "calc(100vh - 132px)" },
    },
    [
      _c(
        "div",
        {
          ref: "messages",
          staticClass: "flex-grow-1 card mb-2",
          attrs: { id: "ai-chat-messages" },
        },
        [
          _c("div", { staticClass: "card-body" }, [
            _vm.messages.length
              ? _c(
                  "div",
                  _vm._l(_vm.messages, function (message, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class: ["message", `message-${message.role}`],
                      },
                      [
                        _c("p", {
                          domProps: { innerHTML: _vm._s(message.text) },
                        }),
                        _vm._v(" "),
                        message.imageUrl
                          ? _c("img", {
                              staticClass: "mt-2",
                              staticStyle: { width: "200px", height: "auto" },
                              attrs: { src: message.imageUrl },
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "w-100 h-100 d-flex flex-column justify-content-center align-items-center",
                  },
                  [
                    _c("p", { staticClass: "h5" }, [
                      _vm._v("\n          How can I assist you?\n        "),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-muted text-center" }, [
                      _vm._v(
                        "\n          I can analyze templates and suggest improvements.\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.userInputImage
                      ? _c("img", {
                          staticStyle: {
                            width: "300px",
                            height: "300px",
                            "object-fit": "contain",
                          },
                          attrs: { src: _vm.userInputImage },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.refreshImage()
                            },
                          },
                        },
                        [_vm._v("\n            Refresh\n          ")]
                      ),
                    ]),
                  ]
                ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-shrink-0 mt-2" }, [
        _c("div", { staticClass: "btn-group dropup" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-sm dropdown-toggle",
              attrs: {
                type: "button",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false",
              },
            },
            [_vm._v("\n        Prompts\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dropdown-menu" },
            _vm._l(_vm.prompts, function (prompt, index) {
              return _c(
                "a",
                {
                  key: index,
                  staticClass: "dropdown-item",
                  attrs: { href: "javascript:void(0);" },
                  on: {
                    click: function ($event) {
                      return _vm.selectPrompt(prompt)
                    },
                  },
                },
                [_vm._v(_vm._s(prompt.name))]
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-shrink-0 input-group mt-2" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.userInput,
                expression: "userInput",
              },
            ],
            staticClass: "form-control",
            attrs: {
              placeholder: "Type your message...",
              type: "text",
              disabled: _vm.loading,
            },
            domProps: { value: _vm.userInput },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.sendMessage.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.userInput = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary ml-2",
              attrs: { disabled: _vm.loading, type: "button" },
              on: { click: _vm.sendMessage },
            },
            [_vm._v("\n        Send\n      ")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "position-absolute w-100 h-100 d-flex flex-column justify-content-center align-items-center",
            },
            [_vm._m(0)]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "spinner-border", attrs: { role: "status" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }