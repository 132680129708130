export const MODES = {
  VIEW: 0,
  BROWSER: 1,
  REPLACE: 2
}

export const MODALS = {
  CROP: 'CROP',
  REMOVE_BACKGROUND: 'REMOVE_BACKGROUND',
}

export const IMAGE_FILE_TYPES = ['png', 'jpg', 'webp', 'tiff', 'image', 'jpeg', 'bmp', 'gif'];
