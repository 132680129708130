var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { staticClass: "import-mappings-data-row" }, [
    _c("td", { staticClass: "import-mappings-data-row__name" }, [
      _vm._v("\n    " + _vm._s(_vm.name) + "\n  "),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "import-mappings-data-row__details" }, [
      _c("div", { staticClass: "import-mappings-data-row__content" }, [
        _vm.isSameEnvironment
          ? _c(
              "div",
              { staticClass: "import-mappings-data-row__templates" },
              _vm._l(_vm.templates, function (template) {
                return _c(
                  "span",
                  {
                    key: `template_${template.id}`,
                    staticClass: "import-mappings-data-row__template-name",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(template.template?.name) +
                        "\n        "
                    ),
                  ]
                )
              }),
              0
            )
          : _c(
              "div",
              { staticClass: "import-mappings-data-row__multiselects" },
              _vm._l(_vm.templateMappings, function (templateMapping) {
                return _c(
                  "div",
                  {
                    key: templateMapping.id,
                    staticClass: "import-mappings-data-row__template-mapping",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "import-mappings-data-row__template-original",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(templateMapping.originalTemplateName) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "import-mappings-data-row__template-select",
                      },
                      [
                        _c("MultiSelect", {
                          attrs: {
                            placeholder: "Customer",
                            label: "name",
                            "track-by": "id",
                            options: _vm.customers,
                          },
                          model: {
                            value: templateMapping.selectedCustomer,
                            callback: function ($$v) {
                              _vm.$set(templateMapping, "selectedCustomer", $$v)
                            },
                            expression: "templateMapping.selectedCustomer",
                          },
                        }),
                        _vm._v(" "),
                        _c("MultiSelect", {
                          staticStyle: { "max-width": "300px" },
                          attrs: {
                            placeholder: "Select template",
                            label: "name",
                            "track-by": "id",
                            options:
                              templateMapping.selectedCustomer?.templates ?? [],
                          },
                          model: {
                            value: templateMapping.newTemplate,
                            callback: function ($$v) {
                              _vm.$set(templateMapping, "newTemplate", $$v)
                            },
                            expression: "templateMapping.newTemplate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
      ]),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "import-mappings-data-row__checkbox" }, [
      _c(
        "div",
        { staticClass: "d-flex align-items-center justify-content-center" },
        [
          _c("input", {
            attrs: { type: "checkbox" },
            domProps: { checked: _vm.selected },
            on: { change: _vm.handleSelect },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }