var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showNextTemplateButton || _vm.selectedInstanceIndex
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-between mb-3" },
            [
              _c(
                "b-button-group",
                { attrs: { size: "sm" } },
                [
                  _vm.showNextTemplateButton
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              return _vm.moveInstanceSelection(1)
                            },
                          },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "arrow-down" } }),
                          _vm._v("\n        Next template\n      "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedInstanceIndex
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              return _vm.moveInstanceSelection(-1)
                            },
                          },
                        },
                        [
                          _vm._v("\n        Prev template\n        "),
                          _c("b-icon", { attrs: { icon: "arrow-up" } }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.totalTemplateOrder) +
                    "/" +
                    _vm._s(_vm.totalTemplatesCount)
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.textFields, function (field, index) {
        return _c(
          "b-form-group",
          {
            key: `${field.element_id}_${index}`,
            attrs: { "label-for": `field_${field.id}` },
          },
          [
            _c(
              "FormGroupCharLimit",
              {
                attrs: {
                  label: field.name,
                  value: _vm.selectedInstance.data[field.element_id],
                  max: 10000,
                },
              },
              [
                _c("b-input", {
                  attrs: {
                    id: `field_${field.id}`,
                    value: _vm.selectedInstance.data[field.element_id],
                  },
                  on: {
                    focus: function ($event) {
                      return _vm.setActiveObject(field)
                    },
                    input: function ($event) {
                      return _vm.updateField(field, $event)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.multilineTextFields, function (field, index) {
        return _c(
          "b-form-group",
          {
            key: `${field.element_id}_${index}`,
            attrs: { "label-for": `field_${field.id}` },
          },
          [
            _c(
              "FormGroupCharLimit",
              {
                attrs: {
                  label: field.name,
                  value: _vm.selectedInstance.data[field.element_id],
                  max: 10000,
                },
              },
              [
                _c("b-textarea", {
                  attrs: {
                    id: `field_${field.id}`,
                    value: _vm.getValidatedValue(
                      _vm.selectedInstance.data[field.element_id]
                    ),
                    "max-rows": "8",
                  },
                  on: {
                    focus: function ($event) {
                      return _vm.setActiveObject(field)
                    },
                    input: function ($event) {
                      return _vm.updateField(field, $event)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.shouldShowThisField(field)
              ? _c(
                  "div",
                  { staticClass: "d-flex align-items-baseline" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-1 btn-sm w-50",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.redraw(field)
                          },
                        },
                      },
                      [
                        _vm._v("\n        Align to "),
                        _c("strong", [
                          _vm._v(_vm._s(JSON.parse(field.options).Option1)),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                        staticClass: "ml-1 w-40",
                        attrs: { checked: _vm.getAutoPositionValue(field) },
                        on: {
                          change: function ($event) {
                            return _vm.onChangeAutoPositionValue(field, $event)
                          },
                        },
                      },
                      [_vm._v("\n        Auto position\n      ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.additionalTextFields, function (field) {
        return _c(
          "b-form-group",
          {
            key: `${field.element_id}`,
            attrs: { "label-for": `add_field_${field.element_id}` },
          },
          [
            _c(
              "FormGroupCharLimit",
              { attrs: { label: field.name, value: field.text, max: 10000 } },
              [
                _c("b-input", {
                  attrs: {
                    id: `add_field_${field.element_id}`,
                    value: field.text,
                  },
                  on: {
                    focus: function ($event) {
                      return _vm.setActiveObject(field)
                    },
                    input: function ($event) {
                      return _vm.updateField(field, $event)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.additionalMultilineTextFields, function (field) {
        return _c(
          "b-form-group",
          {
            key: `${field.element_id}`,
            attrs: { "label-for": `add_field_${field.element_id}` },
          },
          [
            _c(
              "FormGroupCharLimit",
              { attrs: { label: field.name, value: field.text, max: 10000 } },
              [
                _c("b-textarea", {
                  attrs: {
                    id: `add_field_${field.element_id}`,
                    value: field.text,
                    "max-rows": "8",
                  },
                  on: {
                    focus: function ($event) {
                      return _vm.setActiveObject(field)
                    },
                    input: function ($event) {
                      return _vm.updateField(field, $event)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("b-form-group", { staticClass: "translation-form-group" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column" },
          [
            _c("b-form-select", {
              staticClass: "flex-grow-1 mr-2",
              attrs: { options: _vm.languages },
              model: {
                value: _vm.translateTargetLanguage,
                callback: function ($$v) {
                  _vm.translateTargetLanguage = $$v
                },
                expression: "translateTargetLanguage",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "button-wrapper mt-3" },
              [
                _c(
                  "b-dropdown",
                  {
                    attrs: {
                      id: "translate-options-dropdown",
                      text: "Translate",
                      variant: "primary",
                    },
                  },
                  [
                    _c(
                      "b-list-group",
                      { attrs: { flush: "" } },
                      [
                        _c(
                          "b-list-group-item",
                          [
                            _c(
                              "b-overlay",
                              {
                                staticClass: "d-inline-block w-100",
                                attrs: {
                                  show: _vm.loadingTranslate,
                                  rounded: "",
                                  opacity: "0.6",
                                  "spinner-small": "",
                                  "spinner-variant": "primary",
                                },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "flex-shrink-0 w-100",
                                    on: { click: _vm.translate },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Translate\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-list-group-item",
                          [
                            _c(
                              "b-overlay",
                              {
                                staticClass: "d-inline-block w-100",
                                attrs: {
                                  show: _vm.loadingCopyAndTranslate,
                                  rounded: "",
                                  opacity: "0.6",
                                  "spinner-small": "",
                                  "spinner-variant": "primary",
                                },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "flex-shrink-0 w-100",
                                    on: { click: _vm.copyAndTranslate },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Copy Template & Translate\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-list-group-item",
                          [
                            _c(
                              "b-overlay",
                              {
                                staticClass: "d-inline-block w-100",
                                attrs: {
                                  show: _vm.loadingCopyFieldsAndTranslate,
                                  rounded: "",
                                  opacity: "0.6",
                                  "spinner-small": "",
                                  "spinner-variant": "primary",
                                },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "flex-shrink-0 w-100",
                                    on: { click: _vm.copyFieldsAndTranslate },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Copy Fields & Translate\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Global Update" } },
        [
          _vm._v("\n    Apply to:\n    "),
          _c("SelectTemplateDropdown", {
            staticClass: "d-inline",
            attrs: {
              "local-storage-key": "selected-templates-global-update",
              "clear-local-storage": true,
            },
          }),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "exclude-for-b-overlay-wrap",
              attrs: { size: "sm", variant: "primary" },
              on: { click: _vm.confirmApplyToAllGlobal },
            },
            [_vm._v("\n      Apply\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { staticClass: "d-inline mr-1" },
        [
          _c(
            "b-form-checkbox",
            {
              staticClass: "mr-1",
              attrs: { inline: "" },
              on: { change: _vm.onShowRulerChange },
              model: {
                value: _vm.isRulerShown,
                callback: function ($$v) {
                  _vm.isRulerShown = $$v
                },
                expression: "isRulerShown",
              },
            },
            [_vm._v("\n      Show ruler\n    ")]
          ),
          _vm._v(" "),
          _c("b-icon", {
            staticClass: "cursor-pointer",
            attrs: { id: "rulerTooltip", icon: "question-circle" },
          }),
          _vm._v(" "),
          _c(
            "b-tooltip",
            { attrs: { target: "rulerTooltip", triggers: "hover" } },
            [
              _c("div", { staticClass: "ruler-tooltip" }, [
                _vm._v(
                  "\n        Hover the cursor on the ruler and press the left mouse button to create a line.\n        "
                ),
                _c("br"),
                _vm._v(" Hover the cursor on the horizontal ruler and press "),
                _c("strong", [_vm._v("Shift + Left Mouse Button")]),
                _vm._v(" to remove the last added horizontal line.\n        "),
                _c("br"),
                _vm._v(" Hover the cursor on the vertical ruler and press "),
                _c("strong", [_vm._v("Shift + Left Mouse Button")]),
                _vm._v(" to remove the last added vertical line.\n      "),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.rulerLinesCount
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.viewport.noninteractive",
                  value: `Reset lines`,
                  expression: "`Reset lines`",
                  modifiers: {
                    hover: true,
                    viewport: true,
                    noninteractive: true,
                  },
                },
              ],
              attrs: { variant: "outline-primary", size: "sm" },
              on: { click: _vm.removeAllLines },
            },
            [_c("b-icon", { attrs: { icon: "arrow-clockwise" } })],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }