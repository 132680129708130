export const APPLY_TO_TEXT = {
  SELECTED: 'Selected',
  ALL: 'All',
  HEADER: 'Header',
  BODY: 'Body',
  COLUMN: 'Current column',
  ALTERNATING_ROWS: 'Alternating rows',
};

export const APPLY_TO_VALUES = {
  SELECTED: 'selected',
  ALL: 'all',
  HEADER: 'header',
  BODY: 'body',
  COLUMN: 'column',
  ALTERNATING_ROWS: 'alternating-rows'
};

export const ALLOWED_STYLES_TO_APPLY_TO_ALL = [
  'font-size',
  'color',
  'font-family',
  'text-align',
  'font-family',
  'background-color'
];
