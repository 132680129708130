var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "select-way-import-from-psd",
        title: "Import PSD",
        centered: "",
        size: "lg",
      },
      on: { ok: _vm.handleOkBtn, hide: _vm.handleModalClose },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ cancel, ok }) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between w-100 align-items-center",
                },
                [
                  _c(
                    "div",
                    [
                      _vm.isSamplePsdExist
                        ? [
                            _c("b-icon", {
                              attrs: { id: "info-icon", icon: "info-circle" },
                            }),
                            _vm._v(" "),
                            _c(
                              "b-tooltip",
                              { attrs: { target: "info-icon" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "info-icon-tooltip" },
                                  [
                                    _vm._v(
                                      "\n              When editing your PSD file, "
                                    ),
                                    _c("br"),
                                    _vm._v("please use layer naming as shown "),
                                    _c("br"),
                                    _vm._v(
                                      "in the sample PSD file, such as:\n              "
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "info-icon-tooltip__list",
                                      },
                                      [
                                        _c("li", [_vm._v("Headline 1")]),
                                        _vm._v(" "),
                                        _c("li", [
                                          _vm._v("Headline 2 (optional)"),
                                        ]),
                                        _vm._v(" "),
                                        _c("li", [
                                          _vm._v("Subhead 1 (optional)"),
                                        ]),
                                        _vm._v(" "),
                                        _c("li", [_vm._v("Image Area")]),
                                        _vm._v(" "),
                                        _c("li", [_vm._v("Product Image 1")]),
                                        _vm._v(" "),
                                        _c("li", [_vm._v("Product Image 2")]),
                                        _vm._v(" "),
                                        _c("li", [_vm._v("Product Image 3")]),
                                        _vm._v(" "),
                                        _c("li", [_vm._v("Logo")]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "download-link",
                                attrs: { href: _vm.samplePsdUrl, download: "" },
                              },
                              [
                                _vm._v(
                                  "\n            Download sample PSD\n          "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("b-button", { on: { click: cancel } }, [
                        _vm._v("\n          Cancel\n        "),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mx-1" }),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            disabled: _vm.hasDisabledOkButton,
                          },
                          on: { click: ok },
                        },
                        [_vm._v("\n          OK\n        ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm.settings?.isAllowedToImportToExistingLayout
        ? _c("Multiselect", {
            staticClass: "mb-3",
            attrs: {
              options: _vm.layouts,
              loading: _vm.isLoading,
              searchable: "",
              multiple: "",
              "custom-label": _vm.customLabel,
              "track-by": "id",
              placeholder: "Add to existing layout (optional)",
            },
            on: {
              "search-change": _vm.searchCustomerLayouts,
              select: (option) => (_vm.selectedLayout = [option]),
            },
            scopedSlots: _vm._u(
              [
                _vm.layouts.length < _vm.layoutsTotalCount
                  ? {
                      key: "afterList",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "load-more-btn" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    disabled: _vm.isLoading,
                                    variant: "outline-primary",
                                  },
                                  on: { click: _vm.getCustomerLayouts },
                                },
                                [_vm._v("\n          Load More\n        ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedLayout,
              callback: function ($$v) {
                _vm.selectedLayout = $$v
              },
              expression: "selectedLayout",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("b-form-radio-group", {
        attrs: { name: "select-strategy", options: _vm.options },
        model: {
          value: _vm.selectedStrategy,
          callback: function ($$v) {
            _vm.selectedStrategy = $$v
          },
          expression: "selectedStrategy",
        },
      }),
      _vm._v(" "),
      _vm.selectedStrategy === _vm.IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY
        ? _c("SelectTemplateBanner", {
            staticClass: "mt-3",
            attrs: { customers: _vm.customers },
            model: {
              value: _vm.selectedTemplates,
              callback: function ($$v) {
                _vm.selectedTemplates = $$v
              },
              expression: "selectedTemplates",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.settings?.sourcePage === _vm.PSD_IMPORT_SOURCES.FILE_BROWSER &&
      _vm.selectedLayout.length === 0
        ? _c(
            "b-form-group",
            { staticClass: "mt-3", attrs: { label: "New layout name" } },
            [
              _c("b-form-input", {
                model: {
                  value: _vm.newLayoutName,
                  callback: function ($$v) {
                    _vm.newLayoutName = $$v
                  },
                  expression: "newLayoutName",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }