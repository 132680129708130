var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mt-2 mb-3" },
        _vm._l(_vm.presets, function (preset, key) {
          return _c(
            "b-button",
            {
              key: key,
              staticClass: "mr-4 text-capitalize",
              class: [`preset-${key}`],
              attrs: {
                variant: `${key === _vm.activePreset ? "" : "outline-"}primary`,
              },
              on: {
                click: function ($event) {
                  return _vm.usePreset(key)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(key) + "\n    ")]
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.selectedShadow === "oval"
        ? [
            _c("OvalShadow", {
              attrs: { "preset-data": _vm.presets["oval"], target: _vm.target },
            }),
          ]
        : [
            _c(
              "b-form-group",
              { attrs: { label: "Blur" } },
              [
                _c(
                  "b-input-group",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c("b-input", {
                              attrs: { max: 100, min: 0, type: "number" },
                              on: { input: _vm.setShadow },
                              model: {
                                value: _vm.blur,
                                callback: function ($$v) {
                                  _vm.blur = $$v
                                },
                                expression: "blur",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-input", {
                      attrs: { max: 100, min: 0, type: "range" },
                      on: { input: _vm.setShadow },
                      model: {
                        value: _vm.blur,
                        callback: function ($$v) {
                          _vm.blur = $$v
                        },
                        expression: "blur",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              { attrs: { label: "Distance" } },
              [
                _c(
                  "b-input-group",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c("b-input", {
                              attrs: { max: 100, min: 0, type: "number" },
                              on: { input: _vm.calculateOffsets },
                              model: {
                                value: _vm.distance,
                                callback: function ($$v) {
                                  _vm.distance = $$v
                                },
                                expression: "distance",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-input", {
                      attrs: { max: 100, min: 0, type: "range" },
                      on: { input: _vm.calculateOffsets },
                      model: {
                        value: _vm.distance,
                        callback: function ($$v) {
                          _vm.distance = $$v
                        },
                        expression: "distance",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              { attrs: { label: "Angle" } },
              [
                _c(
                  "b-input-group",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c("b-input", {
                              attrs: { max: 360, min: 0, type: "number" },
                              on: { input: _vm.calculateOffsets },
                              model: {
                                value: _vm.angle,
                                callback: function ($$v) {
                                  _vm.angle = $$v
                                },
                                expression: "angle",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-input", {
                      attrs: { max: 360, min: 0, type: "range" },
                      on: { input: _vm.calculateOffsets },
                      model: {
                        value: _vm.angle,
                        callback: function ($$v) {
                          _vm.angle = $$v
                        },
                        expression: "angle",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              { attrs: { label: "Intensity" } },
              [
                _c(
                  "b-input-group",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c("b-input", {
                              attrs: { max: 100, min: 0, type: "number" },
                              model: {
                                value: _vm.intensity,
                                callback: function ($$v) {
                                  _vm.intensity = $$v
                                },
                                expression: "intensity",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-input", {
                      attrs: { max: 100, min: 0, type: "range" },
                      model: {
                        value: _vm.intensity,
                        callback: function ($$v) {
                          _vm.intensity = $$v
                        },
                        expression: "intensity",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              { attrs: { label: "Color" } },
              [
                _c("ColorPicker", {
                  attrs: { opacity: "", copy: "", position: "right-middle" },
                  on: { input: _vm.setShadow },
                  model: {
                    value: _vm.color,
                    callback: function ($$v) {
                      _vm.color = $$v
                    },
                    expression: "color",
                  },
                }),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }