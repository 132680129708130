var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.rootClass }, [
    _vm.thumbnailPath
      ? _c("div", { staticClass: "thumbnail-container content" }, [
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "spinner-container" },
                [
                  _c("b-spinner", {
                    attrs: {
                      variant: "secondary",
                      type: "grow",
                      label: "Spinning",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoading,
                  expression: "!isLoading",
                },
              ],
              staticClass: "links-wrapper",
            },
            [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.viewport.noninteractive",
                      value: "Download",
                      expression: "'Download'",
                      modifiers: {
                        hover: true,
                        viewport: true,
                        noninteractive: true,
                      },
                    },
                  ],
                  attrs: { href: _vm.item.full_url, download: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [_c("b-icon", { attrs: { icon: "download" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.viewport.noninteractive",
                      value: "View",
                      expression: "'View'",
                      modifiers: {
                        hover: true,
                        viewport: true,
                        noninteractive: true,
                      },
                    },
                  ],
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.openViewer.apply(null, arguments)
                    },
                  },
                },
                [_c("b-icon", { attrs: { icon: "zoom-in" } })],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLoading,
                expression: "!isLoading",
              },
            ],
            attrs: {
              src: _vm.thumbnailPath,
              alt: "product-thumbnail",
              onError: "this.src='/images/image.svg'",
            },
            on: { load: _vm.onLoad },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.thumbnailPath
      ? _c("div", {
          staticClass:
            "d-flex justify-content-center align-items-center w-100 h-100 content",
          domProps: { innerHTML: _vm._s(_vm.drawFileSvg(_vm.item)) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }