var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "mb-2" }, [_vm._v("\n    Group:\n  ")]),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Background", "label-cols": "4" } },
        [
          _c("ColorPicker", {
            attrs: { value: _vm.bgColor, themes: _vm.themes, copy: "" },
            on: {
              input: function ($event) {
                return _vm.onChange("bgColor", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-form-group", { attrs: { label: "Stroke", "label-cols": "4" } }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("ColorPicker", {
              attrs: { value: _vm.strokeColor, themes: _vm.themes, copy: "" },
              on: {
                input: function ($event) {
                  return _vm.onChange("strokeColor", $event)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                attrs: {
                  variant: "primary",
                  size: "sm",
                  title: "Use background color",
                },
                on: {
                  click: function ($event) {
                    return _vm.onChange("strokeColor", _vm.target.fill)
                  },
                },
              },
              [_c("b-icon", { attrs: { icon: "back" } })],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Stroke width", disabled: _vm.target.locked } },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: {
                          value: _vm.strokeWidth,
                          min: 0,
                          max: _vm.maxStrokeWidth,
                          type: "number",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onChange("thickness", $event)
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: {
                  value: _vm.strokeWidth,
                  min: 0,
                  max: _vm.maxStrokeWidth,
                  type: "range",
                },
                on: {
                  input: function ($event) {
                    return _vm.onChange("thickness", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("PositionAndSize", {
        attrs: {
          h: _vm.target.height * _vm.target.scaleY,
          w: _vm.target.width * _vm.target.scaleX,
          x: _vm.target.left,
          y: _vm.target.top,
        },
        on: { input: _vm.onChange },
      }),
      _vm._v(" "),
      _c(
        "b-btn",
        {
          attrs: { variant: "primary" },
          on: {
            click: function ($event) {
              return _vm.onChange(_vm.isCustomGroup ? "ungroup" : "group", null)
            },
          },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.isCustomGroup ? "Ungroup" : "Group") + "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-btn",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: "Center to template size",
              expression: "'Center to template size'",
              modifiers: { hover: true },
            },
          ],
          staticClass: "center-btn",
          attrs: { variant: "primary" },
          on: {
            click: function ($event) {
              return _vm.onChange("center", $event)
            },
          },
        },
        [
          _c("img", {
            attrs: { src: "/img/icons/center-image.svg", alt: "center-icon" },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.isTextObjects
        ? [
            _c(
              "b-form-row",
              { staticClass: "mt-2" },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Alignment" } },
                      [
                        _c("b-form-select", {
                          attrs: {
                            value: _vm.textAlign,
                            options: _vm.alignments,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onChange("textAlignment", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Size" } },
                      [
                        _c("b-form-input", {
                          attrs: { value: _vm.fontSize, type: "number" },
                          on: {
                            input: function ($event) {
                              return _vm.onChange("fontSize", $event)
                            },
                            dblclick: _vm.selectValueOnDblclick,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Opacity" } },
                      [
                        _c("b-form-input", {
                          attrs: {
                            value: _vm.opacity,
                            type: "number",
                            min: "0",
                            step: "0.01",
                            max: "1",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.onChange("opacity", $event)
                            },
                            dblclick: _vm.selectValueOnDblclick,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex my-2",
                staticStyle: { "grid-gap": "1rem" },
              },
              [
                _c("ColorPicker", {
                  attrs: {
                    value: _vm.fontColor,
                    themes: _vm.themes,
                    position: "top-start",
                    copy: "",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.onChange("fontColor", $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "DropdownCounter",
                  {
                    attrs: {
                      value: _vm.charSpacing,
                      title: "Text Spacing",
                      step: 10,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onChange("textSpacing", $event)
                      },
                    },
                  },
                  [_c("i", { staticClass: "glyphicon glyphicon-text-width" })]
                ),
                _vm._v(" "),
                _c(
                  "LineHeightDropdown",
                  {
                    attrs: { value: _vm.lineHeight },
                    on: {
                      input: function ($event) {
                        return _vm.onChange("textLeading", $event)
                      },
                    },
                  },
                  [_c("i", { staticClass: "cil-line-spacing" })]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("ApplyToAllTextDropdown", {
              attrs: { options: _vm.options, target: _vm.target },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isProductImageObjects
        ? _c("ApplyToAllImageDropdown", {
            staticClass: "mt-3",
            attrs: { target: _vm.target },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }