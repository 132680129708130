var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        padding: "0 10px",
        "max-height": "600px",
        "overflow-y": "auto",
        "overflow-x": "hidden",
      },
    },
    [
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Enabled" } },
                [
                  _c("b-form-select", {
                    staticClass: "form-control",
                    attrs: { options: _vm.isEnabledOptions },
                    model: {
                      value: _vm.isEnabled,
                      callback: function ($$v) {
                        _vm.isEnabled = $$v
                      },
                      expression: "isEnabled",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isEnabled
        ? _c(
            "div",
            [
              _c("PageNumberUnderlyingShape", {
                attrs: { "underlying-shape": _vm.underlyingShape },
                on: {
                  "data-updated": function ($event) {
                    return _vm.onUnderlyingShapeUpdate($event)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "b-card",
                { attrs: { "bg-variant": "light" } },
                [
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "8" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Number Font" } },
                            [
                              _c("FontSelector", {
                                model: {
                                  value: _vm.fontFamily,
                                  callback: function ($$v) {
                                    _vm.fontFamily = $$v
                                  },
                                  expression: "fontFamily",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Number Font size" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "number" },
                                on: { input: _vm.updateResultData },
                                model: {
                                  value: _vm.fontSize,
                                  callback: function ($$v) {
                                    _vm.fontSize = $$v
                                  },
                                  expression: "fontSize",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { staticClass: "d-flex justify-content-center mt-1" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Color" } },
                            [
                              _c("ColorPicker", {
                                model: {
                                  value: _vm.fontColor,
                                  callback: function ($$v) {
                                    _vm.fontColor = $$v
                                  },
                                  expression: "fontColor",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Number horizontal position" } },
                            [
                              _c("b-form-select", {
                                staticClass: "form-control",
                                attrs: {
                                  options: _vm.horizontalPositionOptions,
                                },
                                on: { change: _vm.updateResultData },
                                model: {
                                  value: _vm.horizontalPosition,
                                  callback: function ($$v) {
                                    _vm.horizontalPosition = $$v
                                  },
                                  expression: "horizontalPosition",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Number vertical position" } },
                            [
                              _c("b-form-select", {
                                staticClass: "form-control",
                                attrs: { options: _vm.verticalPositionOptions },
                                on: { change: _vm.updateResultData },
                                model: {
                                  value: _vm.verticalPosition,
                                  callback: function ($$v) {
                                    _vm.verticalPosition = $$v
                                  },
                                  expression: "verticalPosition",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Mirror" } },
                            [
                              _c("b-form-select", {
                                staticClass: "form-control",
                                attrs: { options: _vm.mirrorOptions },
                                on: { change: _vm.updateResultData },
                                model: {
                                  value: _vm.mirrorType,
                                  callback: function ($$v) {
                                    _vm.mirrorType = $$v
                                  },
                                  expression: "mirrorType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Number horizontal offset" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "number" },
                                on: { input: _vm.updateResultData },
                                model: {
                                  value: _vm.horizontalOffset,
                                  callback: function ($$v) {
                                    _vm.horizontalOffset = $$v
                                  },
                                  expression: "horizontalOffset",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Number vertical offset" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "number" },
                                on: { input: _vm.updateResultData },
                                model: {
                                  value: _vm.verticalOffset,
                                  callback: function ($$v) {
                                    _vm.verticalOffset = $$v
                                  },
                                  expression: "verticalOffset",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Start from" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "number" },
                                on: { input: _vm.updateResultData },
                                model: {
                                  value: _vm.startFromPage,
                                  callback: function ($$v) {
                                    _vm.startFromPage = $$v
                                  },
                                  expression: "startFromPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }