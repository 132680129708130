<template>
  <div>
    <b-list-group>
      <draggable
        :list="layersOrdered"
        group="layers"
        @end="dragEnd"
      >
        <b-list-group-item
          v-for="field in layersOrdered"
          :key="field.id"
          button
          :active="isActive(field)"
          :variant="listItemVariant(field)"
          :class="['d-flex justify-content-start align-items-center px-2 py-1', {'font-weight-bold': !!field.relatedCanvasObject}]"
          @click="setActiveObject(field.relatedCanvasObject)"
        >
          <b-icon 
            v-if="!!field.relatedCanvasObject"
            :icon="getIcon(field.relatedCanvasObject)"
            @click.stop="handleLayerVisibility(field.relatedCanvasObject)" 
          />
          <b-icon
            v-if="isOffScreen(field.relatedCanvasObject)"
            v-b-tooltip.hover.viewport.noninteractive="'This item is off-screen. Click to center it'"
            icon="bullseye"
            class="rounded-circle bg-primary"
            variant="light"
            @click="reposition(field.relatedCanvasObject)"
          />
          <b-tooltip
            v-if="!!field?.note"
            :target="`note-${field.id}`"
            triggers="hover"
          >
            {{ field.note }}
          </b-tooltip>
          <div
            v-b-tooltip.hover.d500
            :title="field.name"
            :disabled="isTooltipDisabled(field.name)"
            class="flex-grow-1 text-center text-truncate"
          >
            {{ field.name }}
          </div>
          <b-icon
            v-if="!!field?.note"
            :id="`note-${field.id}`"
            icon="info-circle"
          />
          <b-icon icon="grip-vertical" />
        </b-list-group-item>
      </draggable>
    </b-list-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { editorControlEventBroker, EVENTS } from "@frontend/group/modules/editor-control-popup/event-broker";
import {
  ADDITIONAL_SAFE_ZONE_PREFIX_ELEMENT_ID, 
  SAFE_ZONE_PREFIX_ID, 
  HORIZONTAL_GUIDE_LINE_PREFIX_ID,
  VERTICAL_GUIDE_LINE_PREFIX_ID, SMART_OBJECT
} from "@frontend/constants/templates-field-prefixes";
import {FIELD_TYPES} from "@frontend/constants/field-types";

const LAYER_VISIBILITY_ACTION = 'layerVisibility'

const NON_DRAGGABLE_PREFIXES = [
    SAFE_ZONE_PREFIX_ID,
    ADDITIONAL_SAFE_ZONE_PREFIX_ELEMENT_ID,
];

const BOTTOM_LIST_ITEMS = [SAFE_ZONE_PREFIX_ID, ADDITIONAL_SAFE_ZONE_PREFIX_ELEMENT_ID];

export default {
  name: 'LayersModule',

  components: {
    draggable,
  },

  props: {
    isTabActive: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    layersOrdered: [],
  }),

  computed: {
    ...mapGetters('layout', [
      'layers',
      'target',
      'selectedInstance',
    ]),
    getIcon() {
      return object => object?.visible ? 'eye' : 'eye-slash';
    }
  },

  watch: {
    isTabActive() {
      this.rerenderList();
    },
  },

  methods: {
    isActive (element) {
      if (element.element_id.startsWith(SMART_OBJECT)) {
        return this.target?.type === FIELD_TYPES.SMART_OBJECT && (this.target.smartObjectId == element.id) || this.target?.type === FIELD_TYPES.NEW_SMART_OBJECT;
      } else {
        if (element.relatedCanvasObject?.element_id === undefined && this.target?.element_id === undefined) return false;
        return element.relatedCanvasObject?.element_id === this.target?.element_id
      }
    },
    setActiveObject (object) {
      if (!object) {
        return
      }
      if (object.element_id.startsWith(SMART_OBJECT)) {
        const preview = previews.find(preview => preview.id === this.selectedInstance.id)
        this.selectedInstance.canvas.fire('show:smart-object-editor', {instanceId: this.selectedInstance.id, preview: preview, smartObjectId: object.fieldId});
      } else {
        this.selectedInstance.canvas.setActiveObject(object)
        this.selectedInstance.canvas.renderAll() 
      }
    },
    dragEnd () {
      this.renderChanges()
      this.saveChanges()
    },
    renderChanges () {
      [...this.layersOrdered]
          .reverse()
          .filter(layer => layer.relatedCanvasObject)
          .forEach(layer => {
            (layer.relatedCanvasObject.group || layer.relatedCanvasObject).bringToFront()
          })
      this.selectedInstance.canvas.renderAll()
      
      this.moveRulerGuideLinesToFront();
    },
    saveChanges () {
      const usersFieldsOrder = _.map(this.layersOrdered, 'id')
      this.selectedInstance.canvas.fire('layers:changed', {
        instance_id: this.selectedInstance.id,
        key: 'users_fields_order',
        value: usersFieldsOrder,
      })
    },
    reorderLayers(instanceId) {
      const usersFieldsOrder = JSON.parse(this.selectedInstance.data.users_fields_order || null);

      this.selectedInstance.canvas.fire('layers:update', instanceId);

      if (usersFieldsOrder) {
        this.layersOrdered = _.sortBy(this.layers, layer => usersFieldsOrder.findIndex(id => id === layer.id));
        
        this.moveRulerGuideLinesToFront();
        
        return;
      }

      this.layersOrdered = this.layers;
      this.moveRulerGuideLinesToFront();
    },
    isTooltipDisabled(name) {
        return name.length < 25;
    },
    rerenderList() {
      if (this.isTabActive) {
        this.reorderLayers(this.selectedInstance.id);
      }
    },
    listItemVariant(field) {
      const isBottomItem = BOTTOM_LIST_ITEMS.some(prefix => field.element_id.startsWith(prefix));

      if (isBottomItem) {
        return 'dark';
      }

      return field.relatedCanvasObject ? '' : 'light'
    },
    handleLayerVisibility(object) {
      this.setActiveObject(object);
      
      editorControlEventBroker.fire(EVENTS.IMAGE_CHANGED, {
        action: LAYER_VISIBILITY_ACTION,
        object
      });
    },
    isOffScreen (object) {
      return object?.selectable && !object.isOnScreen() && !isNaN(object.left + object.top)
    },
    reposition (object) {
      this.selectedInstance.canvas.centerObject(object)
      this.selectedInstance.canvas.fire('object:moving', { target: object })
      this.renderChanges()
    },
    moveRulerGuideLinesToFront() {
      this.selectedInstance.canvas
        .getObjects()
        .filter(
          obj => [HORIZONTAL_GUIDE_LINE_PREFIX_ID, VERTICAL_GUIDE_LINE_PREFIX_ID]
            .some(id => obj.id.startsWith(id))
        )
        .forEach(obj => obj.bringToFront());
    }
  }
}
</script>

<style scoped lang="scss">

</style>
