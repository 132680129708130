export default {
  data() {
    return {
      positionWarning: null,
      localHideWarnings: false
    }
  },

  computed: {
    showPositionWarning() {
      return this.positionWarning && !this.localHideWarnings;
    }
  },

  watch: {
    target: {
      handler() {
        this.checkObjectPosition();
        this.localHideWarnings = !!this.target.hideWarnings;
      },
      deep: true
    }
  },

  mounted() {
    this.localHideWarnings = !!this.target.hideWarnings;
    this.checkObjectPosition();
  },

  methods: {
    checkObjectCanvasPosition(object, templateWidth, templateHeight) {
      const boundingRect = object.getBoundingRect();
      
      return {
        isFullyOffCanvas: boundingRect.left >= templateWidth || 
                         boundingRect.top >= templateHeight ||
                         boundingRect.left + boundingRect.width <= 0 ||
                         boundingRect.top + boundingRect.height <= 0,
        isPartiallyOffCanvas: boundingRect.left < 0 ||
                            boundingRect.top < 0 ||
                            boundingRect.left + boundingRect.width > templateWidth ||
                            boundingRect.top + boundingRect.height > templateHeight,
        position: {
          left: boundingRect.left,
          top: boundingRect.top,
          right: boundingRect.left + boundingRect.width,
          bottom: boundingRect.top + boundingRect.height
        }
      };
    },

    checkObjectPosition() {
      if (!this.target || !this.selectedInstance) return;
      const { width: templateWidth, height: templateHeight } = this.selectedInstance.canvas;
      const position = this.checkObjectCanvasPosition(this.target, templateWidth, templateHeight);
      
      if (position.isFullyOffCanvas) {
        this.positionWarning = 'This element is completely outside the canvas area.';
      } else if (position.isPartiallyOffCanvas) {
        this.positionWarning = 'This element is partially outside the canvas area.';
      } else {
        this.positionWarning = null;
      }
    },

    dismissWarning() {
      this.localHideWarnings = true;
      this.onChange('hideWarnings', true);
    },

    handleHideWarnings(value) {
      this.localHideWarnings = value;
      this.onChange('hideWarnings', value);
    }
  }
} 