import copyPasteBannerCanvas from '../../../../common/preview/copy-paste-banner-canvas';
import {
    FIELD_TYPE_TEXT,
    FIELD_TYPE_MULTI_LINE_TEXT,
    FIELD_TYPE_PRODUCT_IMAGE,
    FIELD_TYPE_BACKGROND_THEME_IMAGE,
    FIELD_TYPE_RECTANGLE,
    FIELD_TYPE_CIRCLE
} from '../../../constants/type-fields-of-template';
import parseText from '../../../../common/preview/parse-text';
import { DEFAULT_TEXT_FIELD_PROPERTIES } from '../additional-fields';
import { addAddField } from '../additional-fields';
import {pasteImage} from "../../../services/helpers";
import store from '../../../store'
import { addProductImage } from '@frontend/group/modules/copy-paste-object/fields/product-image';
import {moveToStart} from "@frontend/utils";

let state = {
    selectedInstance: null
};
let scope = {};

const DEFAULT_ADD_PARAMS = {
    hasUsersOrder: true,
    bringToFront: true
};

function initSelectTemplate() {
    $(document).on('click', '.canvas-container, .actions-grid', function () {
        const $templateItemContainer = $(this).closest('.template-item');
        const instanceId = $templateItemContainer.data('instance');
        setSelectedInstance(instanceId);
    })

    $(document).on('click', function(event) {
        if ($(event.target).closest('.canvas-container').length) {
          return;
        }
        state.selectedInstance = null;
    })

    setDefaultSelectedInstance()
}

export function setSelectedInstance(instanceId) {
    if (!scope?.previews) return;

    const $templateItem = $(`[data-instance=${instanceId}]`)
    $('.template-name-canvas').css('font-weight', 'normal')
    $templateItem.find('.template-name-canvas').css('font-weight', 'bold')
    $('.grid-stack-item').css('border-color', '#e1e1e1')
    $templateItem.find('.grid-stack-item').css('border-color', '#209c86')

    state.selectedInstance = previews.find(preview => preview.id === instanceId) || null

    store.commit('layout/SET_SELECTED_INSTANCE', state.selectedInstance)

    copyPasteBannerCanvas.setScope({canvas: state?.selectedInstance?.canvas });
}

function setDefaultSelectedInstance () {
    const firstInstance = store.getters['layout/currentPagePreviews'][0]
    setSelectedInstance(firstInstance?.id)
}

function setScope(data = {}) {
    scope = { ...scope, ...data };
}

function findObjectByNameAndType(name, type, instanceId) {
    const canvasData = scope.canvas_data[instanceId];
    const templateInstance = canvasData.templateInstance;
    const preview = state.selectedInstance;
    const objects = preview.canvas.getObjects();

    const field = templateInstance.getFirstFieldByNameAndType(name, type);

    if (!field) return null;

    return objects.find(obj => +obj.fieldId === field?.id);
}

async function pasteAdditionalField({ object, id, text }) {
    if (!text && !object) return;

    let type = object?.fieldType;

    if (!object) {
        type = type || FIELD_TYPE_MULTI_LINE_TEXT;
    }

    switch (type) {
        case FIELD_TYPE_TEXT:
        case FIELD_TYPE_MULTI_LINE_TEXT:
            await addAddField(
              type,
              id,
              {
                text,
                ...(object ? {
                    ...DEFAULT_TEXT_FIELD_PROPERTIES,
                    _color: object.fontColor,
                    text: object.text,
                    _font: object.fontFamily,
                    _fontsize: object.fontSize,
                    _offset_x: object.left,
                    _offset_y: object.top,
                    _width: object.width,
                    _alignment: object.textAlign
                } : {})
              },
              DEFAULT_ADD_PARAMS
            );

            break;
        case FIELD_TYPE_BACKGROND_THEME_IMAGE:
            await addAddField(
              type,
              id,
              {
                additional_background_images: object.src,
                add_bk_img_offset_x: object.left,
                add_bk_img_offset_y: object.top,
                add_bk_img_scale: object.scaleX,
                add_bk_img_scale_y: object.scaleY,
              },
              DEFAULT_ADD_PARAMS
            );

            break;

        case FIELD_TYPE_PRODUCT_IMAGE:
            await addProductImage(
              {
                scope,
                state,
                instanceId: id,
                copyObject: object
              },
              DEFAULT_ADD_PARAMS
            );

            break;
        case FIELD_TYPE_CIRCLE:
        case FIELD_TYPE_RECTANGLE:
            await addAddField(
              type,
              id,
              {
                _fill_color: object.fill,
                _offset_x: object.left,
                _offset_y: object.top,
                _scaleX: object.scaleX,
                _scaleY: object.scaleY,
                _stroke_color: object.stroke,
                _thickness: object.strokeWidth,
                _opacity: object.opacity,
                _radius: object.rx,
                _corner_radius: object.rx,
                _angle: object.angle,
                _corners: JSON.stringify(object.corners),
                _height: object.height * object.scaleY + object.strokeWidth,
                _width: object.width * object.scaleX + object.strokeWidth,
                _order: object.order,
                originalId: object.id,
              },
              DEFAULT_ADD_PARAMS
            );

            break;

        default:
            break;
    }
}


export async function pasteObjectField({ object, id, text } = data) {
    if (object && false) {
        const obj = findObjectByNameAndType(object.name, object.fieldType, id);

        if (obj) {
            switch (object.fieldType) {
                case FIELD_TYPE_TEXT:
                case FIELD_TYPE_MULTI_LINE_TEXT:
                    const { text, styles } = parseText(object.text, object.fontColor);

                    obj.set({
                        styles,
                        fontColor: object.fontColor,
                        text,
                        fontFamily: object.fontFamily,
                        fontSize: object.fontSize,
                    });

                    scope.handleObjectMoving({ target: obj });
                    scope.handleTextChange({ target: obj });

                    break;
                case FIELD_TYPE_BACKGROND_THEME_IMAGE:
                    const newImage = await scope.replaceBackgroundImage(object.src, obj.id, id);
                    newImage.set({
                        scaleX: object.scaleX,
                        scaleY: object.scaleY,
                    });

                    scope.handleObjectMoving({ target: newImage });

                    newImage.canvas.renderAll();
                    break;
                case FIELD_TYPE_CIRCLE:
                case FIELD_TYPE_RECTANGLE:
                    obj.set({
                        fill: object.fill,
                        scaleX: object.scaleX,
                        scaleY: object.scaleY,
                    });

                    scope.handleObjectMoving({ target: obj });

                    break;
            }

            obj.canvas.renderAll();

            return;
        }
    }

    await pasteAdditionalField({ object, id, text });
}

function init(data) {
    setScope(data);

    initSelectTemplate();

    copyPasteBannerCanvas.init();

    copyPasteBannerCanvas.on('paste', async function (event) {
        if (!state.selectedInstance) return;
        
        const $loadingOverlay = $('.loadingOverlay');

        $loadingOverlay.fadeIn();
        $('.loadingOverlay-text').html('Loading Layout...');
        
        const { theme } = state.selectedInstance.data;
        const { customer_id, id: templateId } = state.selectedInstance.template;
        const instanceId = state.selectedInstance.canvas.instance_id;

        if ((!event.object || typeof event.object === 'number') && !event.files.length) {
            await addAddField(
              FIELD_TYPE_MULTI_LINE_TEXT,
              instanceId,
              { text: event.text }
            );

            $loadingOverlay.fadeOut();

            return;
        }
        
        for (let i = 0; i < event.files.length; i++) {
            
            const file = event.files[i];
            const fileDataUrl = event.dataUrls[i];
            const fileUrl = await pasteImage(file, { customer_id, theme, templateId, fileDataUrl });
            let src = `${location.origin}/share?file=${fileUrl}`
            
            await addAddField(
              FIELD_TYPE_BACKGROND_THEME_IMAGE,
              instanceId, 
              { additional_background_images: src }
            );
        }

        $loadingOverlay.fadeOut();
        
        if (!event.object) return;

        await pasteObjectField({
            id: state.selectedInstance?.id || null,
            ...event
        });

        $loadingOverlay.fadeOut();
    });
}

export default {
    init,
}
