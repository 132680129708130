var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { inline: "" } },
        [
          _c(
            "label",
            [
              _vm._v("\n      Object Fill\n      "),
              _c(
                "b-tooltip",
                {
                  attrs: { target: "object-fill-info-icon", placement: "top" },
                },
                [
                  _vm._v(
                    "\n        To scale an image, set Object Fill to None\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("b-icon-info-circle", {
                staticClass: "ml-2 object-fill-fit-info",
                attrs: { id: "object-fill-info-icon", variant: "primary" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-form-select", {
            attrs: {
              options: _vm.objectFitAndFillOptions.objectFillOptions,
              "text-field": "name",
              "value-field": "value",
            },
            on: {
              change: function ($event) {
                return _vm.onChange("objectFillSettings")
              },
            },
            model: {
              value: _vm.objectFillSettings.align_to_fill,
              callback: function ($$v) {
                _vm.$set(_vm.objectFillSettings, "align_to_fill", $$v)
              },
              expression: "objectFillSettings.align_to_fill",
            },
          }),
          _vm._v(" "),
          _c(
            "b-form-checkbox",
            {
              attrs: { name: "crop-mode", switch: "" },
              on: {
                change: function ($event) {
                  return _vm.onChange("objectFillSettings")
                },
              },
              model: {
                value: _vm.objectFillCropMode,
                callback: function ($$v) {
                  _vm.objectFillCropMode = $$v
                },
                expression: "objectFillCropMode",
              },
            },
            [_vm._v("\n      Crop\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { inline: "" } },
        [
          _c(
            "label",
            [
              _vm._v("\n      Object Fit\n      "),
              _c(
                "b-tooltip",
                { attrs: { target: "object-fit-info-icon", placement: "top" } },
                [
                  _vm._v(
                    "\n        To scale an image, set Object Fit to None\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("b-icon-info-circle", {
                staticClass: "ml-2 object-fill-fit-info",
                attrs: { id: "object-fit-info-icon", variant: "primary" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-form-select", {
            attrs: {
              options: _vm.objectFitAndFillOptions.objectFitOptions,
              "text-field": "name",
              "value-field": "value",
            },
            on: {
              change: function ($event) {
                return _vm.onChange("objectFitSettings")
              },
            },
            model: {
              value: _vm.objectFitSettings.align_to_fit,
              callback: function ($$v) {
                _vm.$set(_vm.objectFitSettings, "align_to_fit", $$v)
              },
              expression: "objectFitSettings.align_to_fit",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }