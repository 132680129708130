import API_MEDIA from '@frontend/services/api/media';
import { ShowPageScopeCollector } from '@frontend/group/modules/scope-collectors/show-page-collector';
import {
    FIELD_TYPE_CIRCLE,
    FIELD_TYPE_RECTANGLE,
    FIELD_TYPE_MULTI_LINE_TEXT,
    FIELD_TYPE_OUTLINE
} from '@frontend/constants/type-fields-of-template';
import Template from '@/banner_template/Template';

const getShowPageScope = () => ShowPageScopeCollector.get([
    'previews',
    'canvas_data',
    'handleObjectMoving'
  ]);

export const PRIMARY_MODE = 'primary';
export const SECONDARY_MODE = 'secondary';

const cacheOfDominantColorsByImages = new Map();
let dominantHexColors = [];

/**
 * @param {string} previewId
 * @returns 
 */
const getFields = (previewId) => {
    const specifiedTerms = [PRIMARY_MODE, SECONDARY_MODE];
    const { canvas_data } = getShowPageScope();

    /** @type {Template} */
    const { templateInstance } = canvas_data[previewId];

    let fields = templateInstance.getFieldsByTypes([
        FIELD_TYPE_RECTANGLE,
        FIELD_TYPE_CIRCLE,
        FIELD_TYPE_MULTI_LINE_TEXT,
        FIELD_TYPE_OUTLINE
    ]);

    
    fields = fields.filter((field) => {
        const keys = {
            option2: [FIELD_TYPE_MULTI_LINE_TEXT],
            option3: [FIELD_TYPE_RECTANGLE, FIELD_TYPE_CIRCLE, FIELD_TYPE_OUTLINE]
        }

        for (const key in keys) {
            if (keys[key].includes(field.type) && specifiedTerms.includes(field.options[key])) {
                field.mode = field.options[key].toLowerCase();
                
                return true;
            }
        }

        return false;
    });

    return fields;
}

/**
 *
 * @param {string} image
 * @param {boolean} is_url
 * @param {string} previewId
 * @return Promise<void>
 */
export async function getDominantColors(image, previewId, is_url = false) {
    const fieldsWithPrimaryOrSecondary = getFields(previewId);

    if (!fieldsWithPrimaryOrSecondary.length) return;

    const hasImageCachedColors = cacheOfDominantColorsByImages.has(image);
    const { previews, handleObjectMoving } = getShowPageScope();
    const preview = previews.find(({ id }) => id === previewId);
    
    if (!hasImageCachedColors) {
        const { data } = await API_MEDIA.getDominantColors({
            image: image,
            is_url: is_url
        });

        cacheOfDominantColorsByImages.set(image, data)
        dominantHexColors = data;
    } else {
        dominantHexColors = cacheOfDominantColorsByImages.get(image);
    }

    if (!dominantHexColors.length) return;

    for (let field of fieldsWithPrimaryOrSecondary) {
        const { data, canvas } = preview;
        const colors = {
            [PRIMARY_MODE]: dominantHexColors[0],
            [SECONDARY_MODE]: dominantHexColors[1],
        }
        const object = canvas.getObjects().find(object => object?.templateField?.id === field.id);
        
        if (!object || object.fill === colors[field.mode]) continue;
        
        object.set({ fill: colors[field.mode] });
        canvas.renderAll();
        handleObjectMoving({ target: object })
    }
}
