import Vue from 'vue'
import Vuex from 'vuex'

// Global vuex
import media from './media'
import template from './template'
import project from './project'
import sceneDialog from './sceneDialog'
import scenes from './scenes'
import theme from './theme'
import audio from './audio'
import video from './video'
import backgroundStockImage from './backgroundStockImage'
import psdLayers from './psd-layers'
import fileBrowser from './file-browser'
import layoutFolderBrowser from './layout-folder-browser'
import fileBrowserProductSelector from './file-browser-product-selector'
import fileUploadImages from './file-upload-images'
import svgEditor from './svg-editor';
import StoreSaver from "./plugins/store-saver";
import layout from './layout'
import dashboard from './dashboard'
import productDescription from './productDescription'
import walmart from './walmart'

Vue.use(Vuex)

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  modules: {
    project,
    template,
    media,
    sceneDialog,
    scenes,
    theme,
    audio,
    video,
    backgroundStockImage,
    icon: backgroundStockImage,
    layout,
    dashboard,
    psdLayers,
    fileBrowser,
    layoutFolderBrowser,
    fileBrowserProductSelector,
    fileUploadImages,
    svgEditor,
    productDescription,
    walmart
  },
  plugins: process.env.NODE_ENV !== 'production'
      ? [StoreSaver]
      : []
})

export default store
