var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "svg-section" },
    [
      _c("SvgFileInput", {
        ref: "svgFileInput",
        attrs: {
          accept: "image/*,application/pdf",
          placeholder: "Select PDF or SVG file…",
          suffix: "svg-section",
        },
        on: {
          "on-file-selected": _vm.onFileSelected,
          "clear-canvas": _vm.clearCanvas,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-wrap mb-3 mt-2" }, [
        _c(
          "div",
          { staticClass: "optimize-wrapper d-flex align-items-center" },
          [
            _c("b-form-checkbox", {
              attrs: {
                id: "optimizeSvg",
                value: true,
                "unchecked-value": false,
                switch: "",
                size: "lg",
              },
              on: { change: _vm.setOptimizeSvg },
              model: {
                value: _vm.isOptimizeSvgChecked,
                callback: function ($$v) {
                  _vm.isOptimizeSvgChecked = $$v
                },
                expression: "isOptimizeSvgChecked",
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "align-self-center optimize-label",
                attrs: { for: "optimizeSvg" },
              },
              [_vm._v("\n        Optimize SVG\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between flex-wrap mt-1 mb-1" },
        [
          _c(
            "div",
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.resetCanvasToInitialState },
                },
                [_vm._v("\n        Reset\n      ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !_vm.isHasWorkingArea,
                    variant: "outline-primary",
                    size: "sm",
                  },
                  on: { click: _vm.resetCanvasWorkingArea },
                },
                [_vm._v("\n        Reset working area\n      ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  class: { active: _vm.isInteractiveMode },
                  attrs: {
                    disabled: !_vm.isHasSvgXml,
                    variant: "outline-primary",
                    size: "sm",
                  },
                  on: { click: _vm.loadSvgFromString },
                },
                [_vm._v("\n        Interactive mode\n      ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !_vm.isSvgInstanceHasSelectedObjects,
                    variant: "outline-primary",
                    size: "sm",
                  },
                  on: { click: _vm.deselectAll },
                },
                [_vm._v("\n        Clear selections\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.undo },
                },
                [_vm._v("\n        Undo\n      ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.redo },
                },
                [_vm._v("\n        Redo\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "control-buttons mt-2 mb-2" },
        [
          _c(
            "b-button",
            {
              attrs: {
                disabled: !_vm.isSvgInstanceHasObjects,
                variant: "outline-primary",
                size: "sm",
              },
              on: { click: _vm.createCanvasFromSelected },
            },
            [_vm._v("\n      Select working area\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: {
                disabled: !_vm.isSelectedImageObject,
                variant: "outline-primary",
                size: "sm",
              },
              on: { click: _vm.removeBG },
            },
            [_vm._v("\n      Remove BG\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              class: { active: _vm.isRecMaskMode },
              attrs: { variant: "outline-primary", size: "sm" },
              on: { click: _vm.rectangleMaskMode },
            },
            [_vm._v("\n      Mask\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { variant: "outline-primary", size: "sm" },
              on: { click: _vm.selectAll },
            },
            [_vm._v("\n      Select all\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { variant: "outline-primary", size: "sm" },
              on: { click: _vm.centerSelected },
            },
            [_vm._v("\n      Center\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { variant: "outline-primary", size: "sm" },
              on: { click: _vm.enlargeSelected },
            },
            [_vm._v("\n      Enlarge\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              class: { active: _vm.isBgPreviewOn },
              attrs: {
                disabled: !_vm.isHasBgImageInstance,
                variant: "outline-primary",
                size: "sm",
              },
              on: { click: _vm.setBgImagePreview },
            },
            [_c("b-icon", { attrs: { icon: "eye" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { variant: "outline-primary", size: "sm" },
              on: { click: _vm.zoomIn },
            },
            [_c("b-icon", { attrs: { icon: "plus-lg" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { variant: "outline-primary", size: "sm" },
              on: { click: _vm.zoomOut },
            },
            [_c("b-icon", { attrs: { icon: "dash-lg" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c(
                "b-button",
                {
                  class: { active: _vm.isAltSelectionMode },
                  attrs: {
                    disabled: !_vm.isHasSvgXml,
                    variant: "outline-primary",
                    size: "sm",
                  },
                  on: { click: _vm.altSelectionMode },
                },
                [_c("b-icon", { attrs: { icon: "plus-square-dotted" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  class: { active: _vm.isSelectionBoxMode },
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.selectionBoxMode },
                },
                [_c("b-icon", { attrs: { icon: "camera" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  class: { active: _vm.isSvgLassoMode },
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.initLasso },
                },
                [_c("b-icon", { attrs: { icon: "vector-pen" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !_vm.isHasSvgXml,
                    variant: "outline-primary",
                    size: "sm",
                  },
                  on: { click: _vm.showLayers },
                },
                [_c("b-icon", { attrs: { icon: "layers" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !_vm.isHasSvgXml,
                    variant: "outline-primary",
                    size: "sm",
                  },
                  on: { click: _vm.showPreview },
                },
                [_c("b-icon", { attrs: { icon: "window-dock" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !_vm.isSvgInstanceHasSelectedObjects,
                    variant: "outline-primary",
                    size: "sm",
                  },
                  on: { click: _vm.ocr },
                },
                [_vm._v("\n        OCR\n      ")]
              ),
              _vm._v(" "),
              _c("b-form-input", {
                staticClass: "c-svg-section__text-editbox",
                attrs: { size: "sm" },
                model: {
                  value: _vm.textEditbox,
                  callback: function ($$v) {
                    _vm.textEditbox = $$v
                  },
                  expression: "textEditbox",
                },
              }),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.copyHandler },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "b-icon bi",
                      attrs: {
                        width: "1em",
                        height: "1em",
                        fill: "currentColor",
                      },
                    },
                    [
                      _c("use", {
                        attrs: { href: "/img/icons/sprite.svg#copy" },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.collisionHandler },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "b-icon bi",
                      attrs: {
                        width: "1em",
                        height: "1em",
                        fill: "currentColor",
                      },
                    },
                    [
                      _c("use", {
                        attrs: { href: "/img/icons/sprite.svg#collision" },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !_vm.isSvgInstanceHasObjects,
                    variant: "outline-primary",
                    size: "sm",
                  },
                  on: { click: _vm.getIngredients },
                },
                [_vm._v("\n        Ingredients\n      ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !_vm.isSvgInstanceHasObjects,
                    variant: "outline-primary",
                    size: "sm",
                  },
                  on: { click: _vm.getNutritionFacts },
                },
                [_vm._v("\n        Nutrition Facts\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("SvgLayersModal", { ref: "svgLayersModal" }),
      _vm._v(" "),
      _c("SvgExtractModal", {
        ref: "svgExtractModal",
        on: { "on-file-loaded": _vm.onFileLoaded },
      }),
      _vm._v(" "),
      _c("SvgPreviewEditor", {
        ref: "svgPreviewEditor",
        attrs: { "svg-text": _vm.svgContent },
      }),
      _vm._v(" "),
      _c("SvgPdfApi", {
        ref: "svgPdfApi",
        on: {
          "cloud-convert": _vm.cloudConvertAPI,
          "convert-api": _vm.convertPdfAPI,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "cancel-outline",
          attrs: { tabindex: "1000" },
          on: { keyup: _vm.onKeyUp },
        },
        [
          _c("canvas", {
            ref: "svgCanvas",
            staticClass: "canvas-section cancel-outline",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }