<template>
  <b-modal
    id="save-layout-download-settings-modal"
    title="Save settings"
    centered
    @ok="onOk"
    @cancel="onCancel"
  >
    <b-form-group
      label="Name"
      label-for="saved-settings-name"
    >
      <b-form-input
        id="saved-settings-name"
        v-model="name"
        class="form-control"
        type="text"
      />
    </b-form-group>

    <b-form-group>
      <b-form-radio
        v-model="owner"
        value="user"
      >
        Save for me
      </b-form-radio>

      <b-form-radio
        v-model="owner"
        value="company"
      >
        Save for company
      </b-form-radio>

      <b-form-radio
        v-if="isMasterAdmin"
        v-model="owner"
        value="system"
      >
        Save for system
      </b-form-radio>
    </b-form-group>
  </b-modal>
</template>

<script>
import savedDowloadSettingsAPI from "@frontend/services/api/layout-saved-download-settings.js";

export default {
  name: 'SaveDownloadSettingsModal',
  props: {
    isMasterAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: null,
      owner: 'user',
      settings: null,
    };
  },
  methods: {
    show(settings) {
      this.settings = settings;
      this.$bvModal.show('save-layout-download-settings-modal');
    },
    async onOk(event) {
      event.preventDefault();

      try {
        const { data: response } = await savedDowloadSettingsAPI.store({
          settingsName: this.name,
          settingsOwner: this.owner,
          settings: this.settings,
        });

        this.$bvModal.hide('save-layout-download-settings-modal');
        window.toastr.success('Settings saved successfully.');
        this.$emit('saved', response);
      } catch (error) {
        console.error(error);
        window.toastr.error('Failed to save settings.');
      }
    },
    onCancel() {
      this.name = null;
      this.owner = 'user';
    },
  },
}
</script>
