var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isNotFieldTypeLine,
              expression: "isNotFieldTypeLine",
            },
          ],
          attrs: { label: "Fill Type", "label-cols": "4" },
        },
        [
          _c("b-form-radio-group", {
            staticClass: "fill-type-selector",
            attrs: { options: _vm.fillTypeOptions },
            on: { change: _vm.onFillTypeChange },
            model: {
              value: _vm.fillType,
              callback: function ($$v) {
                _vm.fillType = $$v
              },
              expression: "fillType",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex align-items-baseline position-relative" },
        [
          _c(
            "b-form-group",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isNotFieldTypeLine && _vm.fillType === "solid",
                  expression: "isNotFieldTypeLine && fillType === 'solid'",
                },
              ],
              staticClass: "flex-grow-1",
              attrs: {
                label: "Fill Color",
                "label-cols": "4",
                "label-class": "text-nowrap pr-3",
              },
            },
            [
              _c("ColorPicker", {
                attrs: {
                  value:
                    typeof _vm.target.fill === "string"
                      ? _vm.target.fill
                      : "#000000",
                  themes: _vm.themes,
                  copy: "",
                },
                on: {
                  input: (value) => {
                    _vm.onChange("bgColor", value)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isShodShowBrandColor
            ? _c(
                "b-button",
                {
                  staticClass: "p-0 no-focus-outline add-to-color-table",
                  attrs: { variant: "link" },
                  on: { click: _vm.showBrandColors },
                },
                [_vm._v("\n      Add to Color Table\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.fillType === "gradient"
        ? [
            _c(
              "b-form-group",
              { attrs: { label: "Gradient Type", "label-cols": "4" } },
              [
                _c("b-form-radio-group", {
                  attrs: { options: _vm.gradientTypeOptions },
                  on: { change: _vm.updateGradient },
                  model: {
                    value: _vm.gradientType,
                    callback: function ($$v) {
                      _vm.gradientType = $$v
                    },
                    expression: "gradientType",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.gradientType === "linear"
              ? _c(
                  "b-form-group",
                  { attrs: { label: "Direction", "label-cols": "4" } },
                  [
                    _c("b-form-select", {
                      attrs: { options: _vm.gradientDirectionOptions },
                      on: { change: _vm.updateGradient },
                      model: {
                        value: _vm.gradientDirection,
                        callback: function ($$v) {
                          _vm.gradientDirection = $$v
                        },
                        expression: "gradientDirection",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-form-group",
              { attrs: { label: "Gradient Colors", "label-cols": "4" } },
              [
                _vm._l(_vm.colorStops, function (stop, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "d-flex mb-2" },
                    [
                      _c("ColorPicker", {
                        attrs: {
                          value: stop.color,
                          themes: _vm.themes,
                          copy: "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.updateColorStop(index, $event)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("b-input", {
                        staticClass: "ml-2",
                        staticStyle: { width: "80px" },
                        attrs: {
                          type: "number",
                          min: "0",
                          max: "100",
                          step: "1",
                        },
                        on: { input: _vm.updateGradient },
                        model: {
                          value: stop.offset,
                          callback: function ($$v) {
                            _vm.$set(stop, "offset", _vm._n($$v))
                          },
                          expression: "stop.offset",
                        },
                      }),
                      _vm._v(" "),
                      index > 1
                        ? _c(
                            "b-button",
                            {
                              staticClass: "ml-2",
                              attrs: { variant: "danger", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeColorStop(index)
                                },
                              },
                            },
                            [_c("b-icon", { attrs: { icon: "trash" } })],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm.colorStops.length < 5
                  ? _c(
                      "b-button",
                      {
                        attrs: { variant: "primary", size: "sm" },
                        on: { click: _vm.addColorStop },
                      },
                      [_vm._v("\n        Add Color Stop\n      ")]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("b-form-group", { attrs: { label: "Border", "label-cols": "4" } }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("ColorPicker", {
              attrs: { value: _vm.target.stroke, themes: _vm.themes, copy: "" },
              on: {
                input: function ($event) {
                  return _vm.onChange("strokeColor", $event)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                attrs: {
                  variant: "primary",
                  size: "sm",
                  title: "Use background color",
                },
                on: {
                  click: function ($event) {
                    return _vm.onChange("strokeColor", _vm.target.fill)
                  },
                },
              },
              [_c("b-icon", { attrs: { icon: "back" } })],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          attrs: { label: _vm.borderWidthName(), disabled: _vm.target.locked },
        },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: {
                          value: _vm.target.strokeWidth,
                          min: 0,
                          max: _vm.maxStrokeWidth,
                          type: "number",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onChange("thickness", $event)
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: {
                  value: _vm.target.strokeWidth,
                  min: 0,
                  max: _vm.maxStrokeWidth,
                  type: "range",
                },
                on: {
                  input: function ($event) {
                    return _vm.onChange("thickness", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isNotFieldTypeLine
        ? _c(
            "b-form-group",
            { attrs: { label: "Line length", disabled: _vm.target.locked } },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c("b-input", {
                              attrs: {
                                value: parseInt(_vm.target.coords[2]),
                                min: 1,
                                max: 2000,
                                type: "number",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.onChange("coords", $event)
                                },
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2493972812
                  ),
                },
                [
                  _c("b-input", {
                    attrs: {
                      value: parseInt(_vm.target.coords[2]),
                      min: 1,
                      max: 2000,
                      type: "range",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onChange("coords", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.target.fieldType !== _vm.FIELD_TYPE_SAFE_ZONE &&
      _vm.isNotFieldTypeLine
        ? _c(
            "b-form-group",
            { attrs: { label: "Rounding" } },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c("b-input", {
                              attrs: {
                                value: _vm.target.rx,
                                min: 0,
                                max: _vm.maxRounding,
                                type: "number",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.onChange("radius", $event)
                                },
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3262683064
                  ),
                },
                [
                  _c("b-input", {
                    attrs: {
                      value: _vm.target.rx,
                      min: 0,
                      max: _vm.maxRounding,
                      type: "range",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onChange("radius", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Opacity" } },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: {
                          value: _vm.target.opacity,
                          min: 0,
                          max: _vm.maxOpacity,
                          step: "0.01",
                          type: "number",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onChange("opacity", $event)
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: {
                  value: _vm.target.opacity,
                  min: 0,
                  max: _vm.maxOpacity,
                  step: "0.01",
                  type: "range",
                },
                on: {
                  input: function ($event) {
                    return _vm.onChange("opacity", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isNotFieldTypeLine,
              expression: "isNotFieldTypeLine",
            },
          ],
          attrs: { label: "Fill opacity" },
        },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: { min: 0, max: 1, step: "0.01", type: "number" },
                        on: { input: _vm.onChangeFillOpacity },
                        model: {
                          value: _vm.fillOpacity,
                          callback: function ($$v) {
                            _vm.fillOpacity = $$v
                          },
                          expression: "fillOpacity",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: { min: 0, max: 1, step: "0.01", type: "range" },
                on: { input: _vm.onChangeFillOpacity },
                model: {
                  value: _vm.fillOpacity,
                  callback: function ($$v) {
                    _vm.fillOpacity = $$v
                  },
                  expression: "fillOpacity",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Border opacity" } },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: {
                          min: 0,
                          max: _vm.maxOpacity,
                          step: "0.01",
                          type: "number",
                        },
                        on: { input: _vm.onChangeStrokeOpacity },
                        model: {
                          value: _vm.strokeOpacity,
                          callback: function ($$v) {
                            _vm.strokeOpacity = $$v
                          },
                          expression: "strokeOpacity",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: {
                  min: 0,
                  max: _vm.maxOpacity,
                  step: "0.01",
                  type: "range",
                },
                on: { input: _vm.onChangeStrokeOpacity },
                model: {
                  value: _vm.strokeOpacity,
                  callback: function ($$v) {
                    _vm.strokeOpacity = $$v
                  },
                  expression: "strokeOpacity",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.targetTypeRectangleOrAdditionalFieldRectangleOrSaveZone
        ? [
            _c("PositionAndSize", {
              attrs: {
                w: _vm.target.width * _vm.target.scaleX,
                h: _vm.target.height * _vm.target.scaleY,
                x: _vm.target.left,
                y: _vm.target.top,
                "show-keep-aspect-ratio": false,
              },
              on: { input: _vm.onChange },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "shape-editor-actions d-flex my-2",
          staticStyle: { "grid-gap": "1rem" },
        },
        [
          _c(
            "button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              attrs: { title: "Set to template width" },
              on: {
                click: function ($event) {
                  return _vm.onChange("setTemplateSizeByWidth")
                },
              },
            },
            [_c("img", { attrs: { src: "/img/icons/expand.svg" } })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              attrs: { title: "Center to template width" },
              on: {
                click: function ($event) {
                  return _vm.onChange("centerToTemplateWidth")
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: "/img/icons/align-center.png",
                  alt: "align-center-icon",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              attrs: { title: "Left align to template" },
              on: {
                click: function ($event) {
                  return _vm.onChange("leftAlignToTemplate")
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: "/img/icons/align-left.png",
                  alt: "align-left-icon",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              attrs: { title: "Right align to template" },
              on: {
                click: function ($event) {
                  return _vm.onChange("rightAlignToTemplate")
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: "/img/icons/align-right.png",
                  alt: "align-right-icon",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Apply to all" } },
        [
          _c("b-form-checkbox-group", {
            attrs: { options: _vm.applyToAllOptions },
            on: { change: _vm.applyToAll },
            model: {
              value: _vm.selectedPropertyKeys,
              callback: function ($$v) {
                _vm.selectedPropertyKeys = $$v
              },
              expression: "selectedPropertyKeys",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isNonDeletable
        ? _c(
            "b-button",
            {
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.onChange("delete")
                },
              },
            },
            [
              _c("b-icon", { attrs: { icon: "trash" } }),
              _vm._v("\n    Delete\n  "),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("LockToggle"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }