import { EventBroker } from "@frontend/lib/utils/event-broker";

export const IMPORT_HISTORIES_EVENTS = {
    FETCH_HISTORIES: 'FETCH_HISTORIES',
    RERUN_FEED_IMPORT: 'RERUN_FEED_IMPORT',
    FEED_DROPDOWN_CHANGE: 'FEED_DROPDOWN_CHANGE',
    RERUN_GTIN_IMPORT: 'RERUN_GTIN_IMPORT',
    GTIN_DROPDOWN_CHANGE: 'GTIN_DROPDOWN_CHANGE',
};

export const importHistoriesEventBroker = new EventBroker();
