<template>
  <div
    id="icc-profile-modal"
    class="modal m-icc-profile"
    role="dialog"
    data-focus="false"
  >
    <div class="m-icc-profile__overlay" />
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            ICC profile selector
          </h5>
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <b-form-select
            v-model="selectedIcc"
            :options="options"
            @change="onIccChange"
          />
          <div class="mt-3 m-icc-profile__preview">
            <img
              class="m-icc-profile__preview--image"
              :src="previewUrl"
              alt=""
              @load="onLoadImage"
            >
            <div
              class="m-icc-profile__preview--overlay"
              :class="{'d-none': !isImageLoading}"
            >
              <div
                class="spinner-border m-icc-profile__preview--overlay__spinner"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <b-button
            variant="primary"
            :disabled="isApplying || !selectedIcc"
            @click="applyButtonHandler"
          >
            <span
              class="spinner-border spinner-border-sm"
              :class="{'d-none': !isApplying}"
              role="status"
              aria-hidden="true"
            />
            Apply
          </b-button>
          <b-button
            variant="secondary"
            @click="close"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {EVENTS, IccProfileEventBroker} from "@frontend/components/modules/psd-layers/services/icc-profile-event-broker";
import {createNamespacedHelpers} from "vuex";
import {recursiveWalker} from "@/includes/parse_psd_data";
import BackgroundImage from "@/includes/parse_psd_data/psd-types/background-image";
import share from "@frontend/components/modules/psd-layers/mixins/share";
import PSDLayerService from "@frontend/services/api/psd-layers";

const { mapGetters } = createNamespacedHelpers('psdLayers')

export default {
  name: "ICCProfileSelector",
  mixins: [
    share
  ],
  data: () => ({
    previewUrl: null,
    selectedIcc: null,
    options: [],
    isImageLoading: false,
    isApplying: false
  }),
  computed: {
    ...mapGetters([
      'templates',
      'entireImageThumbnail',
      'filename'
    ]),
  },
  mounted() {
    IccProfileEventBroker.on(EVENTS.INIT, this.init.bind(this))
  },
  methods: {
    init() {
      this.selectedIcc = null;
      this.options = [];
      this.isImageLoading = true;
      this.previewUrl = this.$options.filters.share(this.entireImageThumbnail)
      $('#icc-profile-modal').modal()
      recursiveWalker(this.templates, (item) => {
        if (!(item instanceof BackgroundImage) || !item.data.icc) {
          return;
        }
        this.options.push({
          text: item.name,
          value: item.data.icc,
        })
      })
    },
    onIccChange() {
      this.isImageLoading = true;
      this.previewUrl = `/file/icc/preview?image=${this.entireImageThumbnail}&icc=${this.selectedIcc}`
    },
    onLoadImage() {
      this.isImageLoading = false;
    },
    close() {
      $('#icc-profile-modal').modal('hide')
    },
    async applyButtonHandler() {
      const images = [];

      recursiveWalker(this.templates, (item) => {
        if (!(item instanceof BackgroundImage)) {
          return;
        }
        images.push(item.src)
      })

      try {
        this.isApplying = true;
        await PSDLayerService.applyICCProfile({
          icc: this.selectedIcc,
          images: images,
        });
        this.isApplying = false;
        $('#icc-profile-modal').modal('hide')
        toastr.success('Layers have been changed, ICC profile is applied')
      } catch (error) {
        console.error(error)
        toastr.error('Something went wrong while applying ICC profile')
      }
    },
  }
}
</script>

<style scoped lang="scss">
.m-icc-profile {
  &__overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: #000;
    opacity: 0.5;
  }
  &__preview {
    position: relative;

    &--image {
      max-height: 100%;
      max-width: 100%;
    }

    &--overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.5;

     &__spinner {
       position: absolute;
       top: calc(50% - 2rem);
       left: calc(50% - 2rem);
     }
    }
  }
}
</style>