<template>
  <b-modal
    id="edit-download-settings-modal"
    title="Edit settings"
    centered
    @ok="onOk"
    @cancel="onCancel"
  >
    <b-form-group
      label="Name"
      label-for="saved-settings-name"
    >
      <b-form-input
        id="saved-settings-name"
        v-model="name"
        class="form-control"
        type="text"
        required
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import savedDowloadSettingsAPI from "@frontend/services/api/layout-saved-download-settings.js";

export default {
  name: 'EditDownloadSettingsModal',
  data() {
    return {
      name: null,
      setting: null,
    };
  },
  methods: {
    validateSettings() {
      return this.name && this.name.length > 0;
    },
    async onOk(event) {
      event.preventDefault();

      if (!this.validateSettings()) {
        window.toast.error('Please fill in all required fields.');
        return;
      }

      try {
        const { data: setting } = await savedDowloadSettingsAPI.update(this.setting.id, {
          name: this.name,
        });

        window.toastr.success('Settings updated.');
        this.$emit('setting-updated', {...setting, name: this.name});
        this.onCancel();
        this.$bvModal.hide('edit-download-settings-modal');
      } catch (error) {
        window.toastr.error('Failed to update settings.');
        console.error(error);
      }
    },
    onCancel() {
      this.name = null;
      this.setting = null;
    },
    show(setting) {
      this.setting = setting;
      this.name = setting.name;

      this.$bvModal.show('edit-download-settings-modal');
    },
  }
}
</script>
