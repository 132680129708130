import savedDownloadSettingsAPI from "@frontend/services/api/layout-saved-download-settings";

export const getSavedSettings = async (requestParams = {}) => {
  const { data: { settings, download_formats } } = await savedDownloadSettingsAPI.index(requestParams);
  return {
    settings: settings.sort(
      (savedSetting1, savedSetting2) => savedSetting1.name.localeCompare(savedSetting2.name)
    ),
    downloadFormats: download_formats,
  }
}

export const applySavedSettings = (savedSettings, handleSettingsApply) => {
  if (!savedSettings) {
    return;
  }

  const loadingOverlay = document.querySelector('.loadingOverlay');
  const textItem = loadingOverlay.querySelector('.loadingOverlay-text');
  const oldText = textItem.textContent;

  textItem.textContent = 'Applying saved settings...';
  loadingOverlay.style.display = 'block';

  try {
    handleSettingsApply(savedSettings.settings);
  } catch (error) {
    window.toastr.error('Failed to apply saved settings');
    console.error(error);
  } finally {
    textItem.textContent = oldText;
    loadingOverlay.style.display = 'none';
  }
};
