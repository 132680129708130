var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "smart-object-ui-container" },
    [
      _c(
        "div",
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "select-smart-object",
                label: "Smart Object",
                "label-for": "select-smart-object",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  id: "select-smart-object",
                  options: _vm.smartObjectsList,
                  "text-field": "name",
                  "value-field": "id",
                },
                on: { change: _vm.onSelectSmartObject },
                model: {
                  value: _vm.selectedSmartObjectFieldId,
                  callback: function ($$v) {
                    _vm.selectedSmartObjectFieldId = $$v
                  },
                  expression: "selectedSmartObjectFieldId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-checkbox",
        {
          staticClass: "mb-2",
          attrs: { inline: true, "aria-label": "show" },
          on: { change: _vm.toggleShowingOtherUiElements },
          model: {
            value: _vm.showOtherUIElements,
            callback: function ($$v) {
              _vm.showOtherUIElements = $$v
            },
            expression: "showOtherUIElements",
          },
        },
        [_vm._v("\n    Show other template elements\n  ")]
      ),
      _vm._v(" "),
      _vm.smartObjectFabricRectangleObject
        ? _c("PositionAndSize", {
            attrs: {
              "aspect-ratio-option": false,
              h:
                _vm.smartObjectFabricRectangleObject.height *
                _vm.smartObjectFabricRectangleObject.scaleY,
              w:
                _vm.smartObjectFabricRectangleObject.width *
                _vm.smartObjectFabricRectangleObject.scaleX,
              x: parseFloat(_vm.smartObjectFabricRectangleObject.left),
              y: parseFloat(_vm.smartObjectFabricRectangleObject.top),
            },
            on: { input: _vm.onChange },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.option5, function (setting, key) {
        return _c(
          "b-form-group",
          {
            key: key,
            attrs: {
              id: key,
              label: key.charAt(0).toUpperCase() + key.slice(1) + " Setting",
            },
          },
          _vm._l(_vm.getKeys(setting), function (settingKey) {
            return _c(
              "b-form-row",
              { key: settingKey, staticClass: "ml-1" },
              [
                _c("b-col", { attrs: { cols: "3" } }, [
                  _c("label", { attrs: { for: "input-" + settingKey } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          settingKey.charAt(0).toUpperCase() +
                            settingKey.slice(1)
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "b-col",
                  { attrs: { cols: "9" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "pl-0" },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "input-range-" + settingKey,
                                max: _vm.rules[settingKey].max,
                                min: _vm.rules[settingKey].min,
                                step: _vm.rules[settingKey].step,
                                type: "range",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.onChange(settingKey, $event)
                                },
                              },
                              model: {
                                value: _vm.smartObjectData[settingKey],
                                callback: function ($$v) {
                                  _vm.$set(_vm.smartObjectData, settingKey, $$v)
                                },
                                expression: "smartObjectData[settingKey]",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { staticClass: "m-0 pl-0", attrs: { cols: "4" } },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "input-" + settingKey,
                                max: _vm.rules[settingKey].max,
                                min: _vm.rules[settingKey].min,
                                step: _vm.rules[settingKey].step,
                                type: "number",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onChange(settingKey, $event)
                                },
                              },
                              model: {
                                value: _vm.smartObjectData[settingKey],
                                callback: function ($$v) {
                                  _vm.$set(_vm.smartObjectData, settingKey, $$v)
                                },
                                expression: "smartObjectData[settingKey]",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          1
        )
      }),
      _vm._v(" "),
      _c("b-form-group", { attrs: { id: "members", label: "Members" } }, [
        _vm.hoveredItem !== "data:,"
          ? _c("img", {
              staticClass: "member-thumbnail",
              attrs: { src: _vm.hoveredItem, alt: "empty" },
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex flex-column members-container" },
          [
            _vm._l(_vm.fieldsOptions, function (object) {
              return _c(
                "b-form-checkbox",
                {
                  key: object.value,
                  staticClass: "member-item",
                  attrs: {
                    id: object.value + "_checkbox",
                    checked: object.isMember,
                    inline: true,
                    name: object.value + "_checkbox",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.updateMembers(object.value)
                    },
                  },
                },
                [
                  _c(
                    "p",
                    {
                      on: {
                        mouseenter: function ($event) {
                          _vm.hoveredItem = object.thumbnail
                        },
                        mouseleave: function ($event) {
                          _vm.hoveredItem = "data:,"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(object.name) + "\n        "
                      ),
                    ]
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _vm._l(_vm.additionalFields, function (object) {
              return _c(
                "b-form-checkbox",
                {
                  key: object.value,
                  staticClass: "member-item",
                  attrs: {
                    id: object.value + "_checkbox",
                    checked: object.isMember,
                    inline: true,
                    name: object.value + "_checkbox",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.updateAdditionalFieldsMembers(object.id)
                    },
                  },
                },
                [
                  _c(
                    "p",
                    {
                      on: {
                        mouseenter: function ($event) {
                          _vm.hoveredItem = object.thumbnail
                        },
                        mouseleave: function ($event) {
                          _vm.hoveredItem = "data:,"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(object.name) + "\n        "
                      ),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-btn",
        {
          attrs: { variant: "primary" },
          on: {
            click: function ($event) {
              return _vm.onSave("save", null)
            },
          },
        },
        [_vm._v("\n    Save\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-btn",
        { attrs: { variant: "outline-primary" }, on: { click: _vm.onCancel } },
        [_vm._v("\n    Cancel\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: { hover: true },
            },
          ],
          attrs: {
            title: _vm.lockedState ? "Unlock" : "Lock",
            variant: "outline-primary",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              _vm.lockedState = !_vm.lockedState
            },
          },
        },
        [
          _c("b-icon", {
            attrs: { icon: _vm.lockedState ? "lock-fill" : "unlock" },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }