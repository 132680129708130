<template>
  <div style="position: relative; z-index: 999999">
    <component
      :is="component"
      v-for="(component, key) in MODALS_COMPONENTS_MAP"
      :ref="key"
      :key="key"
      :payload="modal.payload"
      @close="freeModal"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import {MODALS} from "../constants";
import {MUTATIONS} from "@frontend/store/file-browser-product-selector";
import {isArray} from "lodash";
import {createModal} from "./action-creater";
import CropModal from "./CropModal.vue";
import RemoveBackgroundModal from "./RemoveBackgroundModal.vue";

const { mapGetters, mapMutations } = createNamespacedHelpers('fileBrowserProductSelector')

const MODALS_COMPONENTS_MAP = {
  [MODALS.CROP]: CropModal,
  [MODALS.REMOVE_BACKGROUND]: RemoveBackgroundModal,
}

const BOOTSTRAP_MODAL_MIN_ZINDEX = 1050;
const Z_INDEX_INCREMENT = 1;

export default {
  name: "AllModals",
  computed: {
    ...mapGetters(['modal']),
    MODALS_COMPONENTS_MAP() {
      return MODALS_COMPONENTS_MAP;
    },
  },
  watch: {
    modal(modal, prev) {
      if (!modal.type) {
        this.close(prev.type)
        return;
      }

      this.init(modal.type)
    }
  },
  mounted() {
    // When used with "bootstrap" modal, the z-index of "bootstrap-vue" modal is smaller, so we need to manually increase it
    this.$root.$on('bv::modal::show', (_, modalId) => {
      this.$nextTick(() => {
        if (!Object.keys(MODALS).includes(modalId)) {
          return;
        }

        const modalOuter = document.getElementById(`${modalId}___BV_modal_outer_`);

        if (!modalOuter) {
          return;
        }

        const existingStyle = document.querySelector(`style[data-modal-id="${modalId}"]`);

        if (existingStyle) {
          return;
        }

        const style = document.createElement('style');
        style.setAttribute('data-modal-id', modalId);

        style.innerHTML = `
          #${modalId}___BV_modal_outer_ {
            z-index: ${BOOTSTRAP_MODAL_MIN_ZINDEX + Z_INDEX_INCREMENT} !important;
          }
        `;

        document.head.appendChild(style);
      });
    });
  },
  methods: {
    ...mapMutations([MUTATIONS.SET_MODAL]),
    init(ref) {
      const _ref = isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];

      if (!_ref) {
        throw new Error(`There is no modal with ref ${ref}`)
      }

      _ref.init()
    },
    close(ref) {
      const _ref = isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
      if (_ref) {
        _ref.close()
      }
    },
    freeModal() {
      this[MUTATIONS.SET_MODAL](createModal())
    }
  }
}
</script>

<style scoped lang="scss">
</style>
