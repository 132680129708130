<template>
  <div>
    <b-modal
      v-model="isOpened"
      title="Catalog Management"
      centered
      ok-title="Save"
      @ok.prevent="handleSaveBtn"
    >
      <label for="input-live">Layout name</label>
      <b-form-input
        v-model="name"
        placeholder="Enter name"
      />

      <template #modal-footer="{ ok, cancel }">
        <b-button 
          variant="secondary" 
          :disabled="loading" 
          @click="cancel"
        >
          Cancel
        </b-button>
        <b-button 
          variant="primary" 
          :disabled="loading" 
          @click="ok"
        >
          <b-spinner 
            v-if="loading" 
            small
          />
          <span v-if="!loading">Save</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { RENAME_LAYOUT_EVENTS, renameLayoutEventBroker } from "@frontend/group/modules/rename-layout/event-broker";
import { RENAME_LAYOUT_ACTIONS } from "@frontend/group/modules/rename-layout";
import LAYOUT_API from '@frontend/services/api/layout';
export default {
  name: 'RenameLayoutModal',
  
  data: () => ({
    isOpened: false,
    name: '',
    layoutId: null,
    customerId: null,
    action: RENAME_LAYOUT_ACTIONS.RENAME_LAYOUTS_TAB,
    loading: false
  }),
  
  mounted() {
    renameLayoutEventBroker.on(RENAME_LAYOUT_EVENTS.INIT, this.init.bind(this));
  },

  methods: {
    init({ id, customerId, name, action }) {
      this.isOpened = true;
      this.action = action;
      this.name = name;
      this.layoutId = id;
      this.customerId = customerId;
    },
    
    async handleSaveBtn() {
      try {
        this.loading = true;
        
        const formData = new FormData();
        formData.append('name', this.name);
        
        await LAYOUT_API.updateLayout(this.customerId, this.layoutId, formData);

        renameLayoutEventBroker.fire(RENAME_LAYOUT_EVENTS.SAVE, {
          action: this.action,
          id: this.layoutId,
          name: this.name,
        });
        
      } catch (e) {
        console.error('An error occurred while renaming layout');
      } finally {
        this.loading = false;
        this.isOpened = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
