import {PARSED_FIELD_TYPES} from "@/includes/parse_psd_data/constants";
import Base from "@/includes/parse_psd_data/psd-types/base";
import { getInstanceByType } from "@/includes/parse_psd_data/type-resolver";
import Template from "@/includes/parse_psd_data/template";
import {recursiveWalker} from "@/includes/parse_psd_data";



export class Folder extends Base {
  name;

  type = PARSED_FIELD_TYPES.FOLDER

  visible;

  children = [];

  uuid;

  width;

  height;

  left;

  top;

  buildFromData(templateData) {
    super.buildFromData(templateData)
    this.name = templateData.name;
    this.visible = templateData.visible;
    this.uuid = templateData.uuid;
    this.width = templateData.width;
    this.height = templateData.height;
    this.left = templateData.left;
    this.top = templateData.top;

    this.children = templateData.children.map((field) => {
      return getInstanceByType({...field, parent: this});
    }).filter(i => !!i);
  }

  toTemplate() {
    const template = new Template();

    const EXCEPTIONS = [ 'type' ];

    for (const key in this) {
      if (EXCEPTIONS.includes(key)) {
        continue;
      }
      template[key] = this[key]
    }

    template.parent = undefined;

    recursiveWalker(template.children, node => {
      node.left -= template.left
      node.top -= template.top
      if (node.data) {
        node.data.left -= template.left
        node.data.top -= template.top
      }
    })

    template.left = 0
    template.top = 0

    return template
  }
}