var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-folder-layer" }, [
    _vm.node.type === _vm.PARSED_FIELD_TYPES.FOLDER ||
    _vm.node.type === _vm.PARSED_FIELD_TYPES.TEMPLATE
      ? _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass:
                  "row c-folder-layer__folder d-flex align-items-center",
              },
              [
                _c(
                  "div",
                  { staticClass: "col-9 d-flex align-items-center" },
                  [
                    _c("b-form-checkbox", {
                      staticClass: "mr-1",
                      attrs: { checked: _vm.node.visible },
                      on: { change: _vm.visibilityHandler },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "cursor-pointer",
                        on: { click: _vm.collapseHandler },
                      },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2",
                          class: { closed: !_vm.visible, opened: _vm.visible },
                          attrs: { icon: "triangle-fill" },
                        }),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            staticClass: "mr-2",
                            attrs: { width: "20", height: "20" },
                          },
                          [
                            _c("use", {
                              attrs: {
                                href: "/img/icons/sprite.svg#file-folder-svg",
                              },
                            }),
                          ]
                        ),
                        _vm._v(
                          "\n          " + _vm._s(_vm.node.name) + "\n        "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.node.type === _vm.PARSED_FIELD_TYPES.FOLDER
                  ? _c(
                      "div",
                      { staticClass: "col-3 d-flex justify-content-end" },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "mr-1",
                            attrs: { title: "To root" },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "cursor-pointer",
                              attrs: {
                                id: "tooltip-target-1",
                                icon: "chevron-double-up",
                              },
                              on: { click: _vm.toRoot },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.mergingNodes.includes(_vm.node.uuid)
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                attrs: { title: "Merge" },
                              },
                              [
                                _c("b-icon", {
                                  staticClass: "cursor-pointer",
                                  attrs: {
                                    id: "tooltip-target-1",
                                    icon: "file-zip",
                                  },
                                  on: { click: _vm.mergeHandler },
                                }),
                              ],
                              1
                            )
                          : _c("b-spinner", { attrs: { small: "" } }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c("b-collapse", { attrs: { visible: _vm.visible } }, [
              _c(
                "div",
                { staticClass: "ml-3 mb-2" },
                _vm._l(_vm.node.children, function (child) {
                  return _c(
                    "div",
                    { key: child.uuid },
                    [_c("FolderLayer", { attrs: { node: child } })],
                    1
                  )
                }),
                0
              ),
            ]),
          ],
          1
        )
      : _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "layer--item col-9 d-flex align-items-center position-relative",
            },
            [
              _c("b-form-checkbox", {
                staticClass: "mr-2",
                attrs: { checked: _vm.node.visible },
                on: { change: _vm.visibilityHandler },
              }),
              _vm._v(" "),
              _c(_vm.childComponent(_vm.node), {
                tag: "component",
                attrs: { node: _vm.node },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isTemplateFeatures
            ? _c(
                "div",
                {
                  staticClass:
                    "layer--item__actions col-3 d-flex justify-content-end w-100",
                },
                [
                  _vm.fieldsByType &&
                  _vm.fieldsByType.length &&
                  _vm.PSD_LAYER_TYPE_TO_BASIC_MAP[_vm.node.type] ===
                    _vm.FIELD_TYPES.IMAGE
                    ? _c(
                        "b-dropdown",
                        {
                          staticClass: "layer--item__setting-menu",
                          attrs: { variant: "link", "no-caret": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("i", {
                                      staticClass: "c-icon cil-settings",
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            785333150
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "px-4",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { checked: _vm.node.isFlipV },
                                  on: {
                                    input: (value) =>
                                      _vm.setSettings(
                                        _vm.node,
                                        "isFlipV",
                                        value
                                      ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            Flip vertical\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { checked: _vm.node.isFlipH },
                                  on: {
                                    input: (value) =>
                                      _vm.setSettings(
                                        _vm.node,
                                        "isFlipH",
                                        value
                                      ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            Flip horizontal\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "layer--item__mapper-menu",
                      attrs: { variant: "link", "no-caret": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "x-svg",
                                    attrs: { width: "22", height: "22" },
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        href: "/img/icons/sprite.svg#transfer",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        291896020
                      ),
                    },
                    [
                      _vm._v(" "),
                      _vm.fieldsByType && _vm.fieldsByType.length
                        ? [
                            _c(
                              "b-dropdown-item",
                              {
                                class: { selected: !_vm.node.field },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.fieldClickHandler(
                                      _vm.node,
                                      undefined
                                    )
                                  },
                                },
                              },
                              [_vm._v("\n            None\n          ")]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.fieldsByType, function (field) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: field.id,
                                  class: {
                                    selected:
                                      _vm.node.field &&
                                      _vm.node.field.id === field.id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.fieldClickHandler(
                                        _vm.node,
                                        field
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(field.name) +
                                      "\n          "
                                  ),
                                ]
                              )
                            }),
                          ]
                        : _vm._l(_vm.layerFieldMap, function (fieldType) {
                            return _c(
                              "b-dropdown-item",
                              {
                                key: fieldType,
                                class: {
                                  selected: _vm.node.fieldType === fieldType,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.setLayerTypeHandler(
                                      _vm.node,
                                      fieldType
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(fieldType) +
                                    "\n          "
                                ),
                              ]
                            )
                          }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }