<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            Date
          </th>
          <th scope="col">
            Time
          </th>
          <th scope="col">
            Type
          </th>
          <th scope="col">
            Layout
          </th>
          <th scope="col">
            Settings
          </th>
          <th scope="col">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="history in histories"
          :key="history.id"
          class="history-row"
        >
          <td>{{ formatDate(history.created_at) }}</td>
          <td>{{ formatTime(history.time) }}</td>
          <td>{{ history.type }}</td>
          <td>
            <a
              :href="`/banner/${history.layout.customer_id}/group/${history.layout_id}/show`"
              target="_blank"
            >
              {{ history.layout_id }}
            </a>
          </td>
          <td>{{ history?.mapping?.name || "N/A" }}</td>
          <td>
            <div class="dropdown">
              <a
                href="#"
                role="button"
                data-toggle="dropdown"
                class="action"
              >
                <div class="ellipsis-vertical">
                  <svg
                    width="26"
                    height="22"
                    viewBox="0 0 4 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke=""
                      d="M2 1.875C2.06904 1.875 2.125 1.93096 2.125 2L0.625 2C0.625 2.75939 1.24061 3.375 2 3.375L2 1.875ZM2.125 2C2.125 2.06904 2.06904 2.125 2 2.125L2 0.625C1.24061 0.625 0.625 1.24061 0.625 2L2.125 2ZM2 2.125C1.93096 2.125 1.875 2.06904 1.875 2L3.375 2C3.375 1.24061 2.75939 0.625 2 0.625L2 2.125ZM1.875 2C1.875 1.93096 1.93096 1.875 2 1.875L2 3.375C2.75939 3.375 3.375 2.75939 3.375 2L1.875 2ZM2 6.875C2.06904 6.875 2.125 6.93096 2.125 7H0.625C0.625 7.75939 1.24061 8.375 2 8.375L2 6.875ZM2.125 7C2.125 7.06904 2.06904 7.125 2 7.125L2 5.625C1.24061 5.625 0.625 6.24061 0.625 7H2.125ZM2 7.125C1.93096 7.125 1.875 7.06904 1.875 7H3.375C3.375 6.24061 2.75939 5.625 2 5.625L2 7.125ZM1.875 7C1.875 6.93096 1.93096 6.875 2 6.875L2 8.375C2.75939 8.375 3.375 7.75939 3.375 7H1.875ZM2 11.875C2.06904 11.875 2.125 11.931 2.125 12H0.625C0.625 12.7594 1.24061 13.375 2 13.375L2 11.875ZM2.125 12C2.125 12.069 2.06904 12.125 2 12.125L2 10.625C1.24061 10.625 0.625 11.2406 0.625 12H2.125ZM2 12.125C1.93096 12.125 1.875 12.069 1.875 12H3.375C3.375 11.2406 2.75939 10.625 2 10.625L2 12.125ZM1.875 12C1.875 11.931 1.93096 11.875 2 11.875L2 13.375C2.75939 13.375 3.375 12.7594 3.375 12H1.875Z"
                      fill="#464646"
                    />
                  </svg>
                </div>
              </a>
              <div class="dropdown-menu">
                <button
                  class="dropdown-item"
                  @click="downloadFile(history.file_path)"
                >
                  <i class="bi bi-cloud-arrow-down" />&nbsp;Download
                </button>
                <button
                  class="dropdown-item"
                  @click="rerun(history)"
                >
                  <i class="bi bi-repeat" />&nbsp;Rerun
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <nav v-if="pagination.last_page > 1">
      <ul class="pagination">
        <li
          class="page-item"
          :class="{ disabled: pagination.current_page === 1 }"
        >
          <button
            class="page-link"
            @click="changePage(pagination.current_page - 1)"
          >
            Previous
          </button>
        </li>
        <li
          v-for="page in pagination.last_page"
          :key="page"
          class="page-item"
          :class="{ active: pagination.current_page === page }"
        >
          <button
            class="page-link"
            @click="changePage(page)"
          >
            {{ page }}
          </button>
        </li>
        <li
          class="page-item"
          :class="{
            disabled: pagination.current_page === pagination.last_page,
          }"
        >
          <button
            class="page-link"
            @click="changePage(pagination.current_page + 1)"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {
  IMPORT_HISTORIES_EVENTS,
  importHistoriesEventBroker,
} from "@frontend/group/modules/import-histories/event-broker";

export default {
  name: "ImportHistoriesTable",
  data() {
    return {
      histories: [],
      pagination: {
        current_page: 1,
        last_page: 1,
        total: 0,
      },
    };
  },
  mounted() {
    importHistoriesEventBroker.on(
      IMPORT_HISTORIES_EVENTS.FETCH_HISTORIES,
      () => {
        this.fetchHistories();
      }
    );
  },
  methods: {
    async fetchHistories(page = 1) {
      try {
        const response = await axios.get("/import-history/fetch-histories", {
          params: {
            page: page,
          },
        });

        if (response.data.status === "success") {
          this.histories = response.data.data;
          this.pagination = response.data.pagination;
        }
      } catch (error) {
        console.log("Error fetching histories:", error);
      }
    },
    changePage(page) {
      if (page >= 1 && page <= this.pagination.last_page) {
        this.fetchHistories(page);
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
      });
    },
    formatTime(timeString) {
      return new Date(`2000-01-01 ${timeString}`).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    },
    async downloadFile(filePath) {
      try {
        const { data } = await axios.get("/import-history/download-file", {
          params: { file_path: filePath },
          responseType: "blob",
        });

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filePath.split("/").pop());
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

      } catch (error) {
        console.log("Error downloading file:", error);
        toastr.error("Failed to download the file.");
      }
    },
    rerun(history) {
      const mapping = history?.mapping ?? null;

      if (history.type === 'feed') {
        importHistoriesEventBroker.fire(IMPORT_HISTORIES_EVENTS.RERUN_FEED_IMPORT, {
          mapping: mapping,
          file_path: history.file_path
        });
      }

      if (history.type === 'gtin') {
        importHistoriesEventBroker.fire(IMPORT_HISTORIES_EVENTS.RERUN_GTIN_IMPORT, {
          mapping: mapping,
          file_path: history.file_path
        });
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  margin-top: 1rem;
  justify-content: center;
}
</style>
