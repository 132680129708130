var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-file-browser" },
    [
      _vm.breadcrumbs.length
        ? _c(
            "div",
            { staticClass: "p-file-browser__breadcrumbs-wrapper" },
            [
              _c("BreadcrumbItem", {
                attrs: { breadcrumbs: _vm.breadcrumbs },
                on: { goTo: _vm.breadcrumbsNavigation },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-card",
        { staticClass: "c-file-browser" },
        [_c("MainView"), _vm._v(" "), _c("AllModals")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }