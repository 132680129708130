var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "edit-download-settings-modal",
        title: "Edit settings",
        centered: "",
      },
      on: { ok: _vm.onOk, cancel: _vm.onCancel },
    },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Name", "label-for": "saved-settings-name" } },
        [
          _c("b-form-input", {
            staticClass: "form-control",
            attrs: { id: "saved-settings-name", type: "text", required: "" },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }