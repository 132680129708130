export const FIELD_TYPE_TEXT = 'Text'
export const FIELD_TYPE_TEXT_OPTIONS = 'Text Options'
export const FIELD_TYPE_TEXT_FROM_SPREADSHEET = 'Text from Spreadsheet'
export const FIELD_TYPE_MULTI_LINE_TEXT = 'Multi Line Text'
export const FIELD_TYPE_BACKGROND_THEME_COLOR = 'Background Theme Color'
export const FIELD_TYPE_BACKGROUND_COLOR = 'Background Color'
export const FIELD_TYPE_BACKGROND_THEME_IMAGE = 'Background Theme Image'
export const FIELD_TYPE_IMAGE_FROM_BACKGROUND = 'Image From Background'
export const FIELD_TYPE_BACKGROND_THEME_IMAGE_MULTIPLE = 'Background Theme Image Multiple'
export const FIELD_TYPE_PRODUCT_DIMENSIONS = 'Product Dimensions'
export const FIELD_TYPE_PRODUCT_IMAGE = 'Product Image'
export const FIELD_TYPE_STATIC_TEXT = 'Static Text'
export const FIELD_TYPE_UPC_GTIN = 'UPC/GTIN'
export const FIELD_TYPE_BACKGROUND_IMAGE_UPLOAD = 'Background Image Upload'
export const FIELD_TYPE_CIRCLE = 'Circle'
export const FIELD_TYPE_CIRCLE_TYPE = 'Circle Type'
export const FIELD_TYPE_RECTANGLE = 'Rectangle'
export const FIELD_TYPE_STATIC_IMAGE = 'Static Image'
export const FIELD_TYPE_UPLOAD_IMAGE = 'Upload Image'
export const FIELD_TYPE_IMAGE_LIST = 'Image List'
export const FIELD_TYPE_LIST_NUMBERED_CIRCLE = 'List Numbered Circle'
export const FIELD_TYPE_LIST_NUMBERED_SQUARE = 'List Numbered Square'
export const FIELD_TYPE_LIST_CHECKMARK = 'List Checkmark'
export const FIELD_TYPE_LIST_STAR = 'List Star'
export const FIELD_TYPE_LIST_ALL = 'List All'
export const FIELD_TYPE_LINE = 'Line'
export const FIELD_TYPE_SMART_OBJECT = 'Smart Object'
export const FIELD_TYPE_HTML = 'HTML'
export const FIELD_TYPE_ADDITIONAL_HTML_TABLE = 'Additional HTML Table'
export const FIELD_TYPE_IMAGE_LIST_GROUP = 'Image List Group'
export const FIELD_TYPE_ADDITIONAL_FIELDS = 'Additional Fields'
export const FIELD_TYPE_OUTLINE = 'Outline'
export const FIELD_TYPE_SAFE_ZONE = 'Safe Zone';
export const FIELD_TYPE_ICONS = 'Icons';
