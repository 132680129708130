var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "pickrContainer", staticClass: "d-inline-flex align-items-center" },
    [
      _c("div", { ref: "pickr" }),
      _vm._v(" "),
      _vm.copy
        ? _c("i", {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.viewport.noninteractive",
                value: _vm.copied ? "Copied!" : "Copy hex color",
                expression: "copied ? 'Copied!' : 'Copy hex color'",
                modifiers: {
                  hover: true,
                  viewport: true,
                  noninteractive: true,
                },
              },
            ],
            class: [
              "cursor-pointer font-xl px-2 bi",
              _vm.copied ? "bi-check2" : "bi-copy",
            ],
            on: { click: _vm.copyColorToClipboard },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }