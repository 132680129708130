export default {
  filters: {
    share(url) {
      const prefix = `${location.origin}/share?file=`;

      if (url.includes(prefix)) {
        return url;
      }

      return `${prefix}${url}`;
    }
  },
}