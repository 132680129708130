import { FIELD_TYPE_PRODUCT_IMAGE } from '@frontend/constants/type-fields-of-template';
import TemplateField from '@/banner_template/fields/TemplateField';

export const addProductImage = async ({ scope, state, instanceId, copyObject }) => {
  const { selectedInstance } = state;
  const { template, data, canvas } = selectedInstance;

  const newFileId = copyObject.fileName;
  const productImageFields = template.fields.filter(field => field.type === FIELD_TYPE_PRODUCT_IMAGE);
  const existingFileIds = data.file_ids?.split(',') || [];

  if (productImageFields.length > existingFileIds.length) {
    scope.addFileIdForProductImage(newFileId, instanceId);
  } else {
    const hasProductDimensionField = scope.checkHasTemplateProductDimensionField(window.preview_data, instanceId);

    const newFields = await scope.addProductDimAndGTIN(!hasProductDimensionField, selectedInstance, template.fields);

    if (newFields?.newProductDimensionField) {
      const productDimensionOptions = JSON.parse(newFields.newProductDimensionField.options);
      scope.canvas_data[instanceId].product = {
        left: parseInt(productDimensionOptions.X, 10),
        top: parseInt(productDimensionOptions.Y, 10),
        width: parseInt(productDimensionOptions.Width, 10),
        height: parseInt(productDimensionOptions.Height, 10),
        alignment: productDimensionOptions.Alignment,
      };
    }

    const [newProductImageField] = await scope.addProductImageFields(
      selectedInstance,
      canvas,
      [newFileId],
      scope.getStartIndex(instanceId),
      {
        defaultData: {
          angle: copyObject.angle,
          p_flipX: copyObject.flipX,
          p_flipY: copyObject.flipY,
          p_opacity: copyObject.opacity,
        }
      }
    );

    const newProductOptions = { ...JSON.parse(newProductImageField.options), id: newProductImageField.id };
    scope.canvas_data[instanceId].product_image_settings.push(newProductOptions);
    scope.canvas_data[instanceId].templateInstance.fields.push(new TemplateField(newProductImageField));

    scope.updateLayoutChanges(instanceId, 'file_ids', [...existingFileIds, newFileId].join(','));
  }

  scope.reDrawProductImage(instanceId);
};
