export const updateFileState = ({ state, index, updater }) => {
  const fileStateIndex = (index || index === 0) ? index : state.currentPreviewIndex;
  const fileState = state.files[fileStateIndex] || null;

  if (!fileState) {
    return;
  }

  updater(fileState);
}
