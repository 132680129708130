var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("FormGroupCharLimit", {
        attrs: { label: "Text", value: _vm.text, max: _vm.maxChars },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ state }) {
              return [
                _vm.isMultiLineField
                  ? _c("b-textarea", {
                      attrs: { value: _vm.text, state: state, "max-rows": "8" },
                      on: { input: _vm.handleTextInput, blur: _vm.onBlurText },
                    })
                  : _c("b-input", {
                      attrs: { value: _vm.text, state: state },
                      on: { input: _vm.handleTextInput },
                    }),
                _vm._v(" "),
                _c(
                  "b-checkbox",
                  {
                    on: { change: _vm.toggleTitleCase },
                    model: {
                      value: _vm.titleCase,
                      callback: function ($$v) {
                        _vm.titleCase = $$v
                      },
                      expression: "titleCase",
                    },
                  },
                  [_vm._v("\n        Title Case\n      ")]
                ),
                _vm._v(" "),
                _vm.isMultiLineField && _vm.options.hasSmartObject
                  ? _c(
                      "div",
                      { staticClass: "d-flex align-items-baseline" },
                      [
                        _c(
                          "div",
                          { staticClass: "btn-group" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-1 btn-sm",
                                attrs: { variant: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.redraw()
                                  },
                                },
                              },
                              [
                                _vm._v("\n            Align to "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.target.templateField.options.option1
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-dropdown",
                              {
                                staticClass: "mt-1 align-options-dropdown",
                                attrs: {
                                  variant: "primary",
                                  right: "",
                                  "no-caret": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "button-content",
                                      fn: function () {
                                        return [
                                          _c("i", {
                                            staticClass: "fas fa-cog",
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "b-dropdown-form",
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "mb-2",
                                        model: {
                                          value: _vm.keepFontSize,
                                          callback: function ($$v) {
                                            _vm.keepFontSize = $$v
                                          },
                                          expression: "keepFontSize",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                Keep font size\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        model: {
                                          value: _vm.keepLineBreaks,
                                          callback: function ($$v) {
                                            _vm.keepLineBreaks = $$v
                                          },
                                          expression: "keepLineBreaks",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                Keep line breaks\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-checkbox",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            staticClass: "ml-1",
                            attrs: {
                              checked: _vm.getAutoPositionValue(_vm.target),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onChangeAutoPositionValue($event)
                              },
                            },
                          },
                          [_vm._v("\n          Auto position\n        ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isMultiLineField && _vm.options.extendContainer
                  ? _c("ExtendContainerRow", {
                      staticClass: "mt-2",
                      attrs: {
                        target: _vm.target,
                        config: _vm.options.extendContainer,
                      },
                      on: {
                        "data-updated": _vm.updateContainerSettings,
                        "extend-container": _vm.onExtendContainer,
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("PositionAndSize", {
        attrs: {
          x: _vm.target.left,
          y: _vm.target.top,
          w: _vm.config.width,
          h: _vm.config.height,
        },
        on: { input: _vm.onChange },
      }),
      _vm._v(" "),
      _vm.options.fontSelector
        ? _c(
            "b-form-group",
            { attrs: { label: "Font" } },
            [
              _c("Multiselect", {
                attrs: {
                  placeholder: "Font",
                  "track-by": "value",
                  label: "name",
                  options: _vm.getFonts,
                  "allow-empty": false,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function (props) {
                        return [
                          _c(
                            "span",
                            { style: { fontFamily: props.option.value } },
                            [_vm._v(_vm._s(props.option.name))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "singleLabel",
                      fn: function (props) {
                        return [
                          _c(
                            "span",
                            { style: { fontFamily: props.option.value } },
                            [_vm._v(_vm._s(props.option.name))]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2835709206
                ),
                model: {
                  value: _vm.fontVal,
                  callback: function ($$v) {
                    _vm.fontVal = $$v
                  },
                  expression: "fontVal",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Alignment" } },
        [
          _c("b-form-select", {
            attrs: { value: _vm.target.textAlign, options: _vm.alignments },
            on: {
              change: function ($event) {
                return _vm.onChange("textAlignment", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Size", disabled: _vm.target.locked } },
                [
                  _c("b-form-input", {
                    attrs: { value: _vm.target.fontSize, type: "number" },
                    on: {
                      input: function ($event) {
                        return _vm.onChange("fontSize", $event)
                      },
                      dblclick: _vm.selectValueOnDblclick,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Opacity" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      value: _vm.target.opacity,
                      type: "number",
                      min: "0",
                      step: "0.01",
                      max: "1",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onChange("opacity", $event)
                      },
                      dblclick: _vm.selectValueOnDblclick,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Related field" } },
                [
                  _c("b-form-select", {
                    attrs: {
                      value: _vm.target.relatedField?.field,
                      options: _vm.relatedTemplateFields,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onChange("changeRelatedField", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Margin" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      value: _vm.target.relatedField?.margin,
                      type: "number",
                      min: "0",
                      step: "1",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onChange("changeMargin", $event)
                      },
                      dblclick: _vm.selectValueOnDblclick,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex my-2", staticStyle: { "grid-gap": "1rem" } },
        [
          _vm.options.colorSelector
            ? _c("ColorPicker", {
                attrs: {
                  value: _vm.target.fontColor,
                  themes: _vm.themes,
                  position: "top-start",
                  copy: "",
                },
                on: {
                  input: function ($event) {
                    return _vm.onChange("fontColor", $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "DropdownCounter",
            {
              attrs: {
                value: _vm.target.charSpacing,
                title: "Text Spacing",
                step: 10,
              },
              on: {
                input: function ($event) {
                  return _vm.onChange("textSpacing", $event)
                },
              },
            },
            [_c("i", { staticClass: "glyphicon glyphicon-text-width" })]
          ),
          _vm._v(" "),
          _vm.fontFamilyWeights.length
            ? _c("div", { staticClass: "dropdown" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dropdown-menu menu-wide p-0 w-100" },
                  _vm._l(_vm.fontFamilyWeights, function (font, index) {
                    return _c(
                      "button",
                      {
                        key: index,
                        staticClass: "dropdown-item p-2",
                        style: { "font-family": font.index },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.onChangeTextWeight(font.index)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.TEXT_WEIGHT[font.weight]) +
                            "\n        "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "LineHeightDropdown",
            {
              attrs: { value: _vm.target.lineHeight },
              on: {
                input: function ($event) {
                  return _vm.onChange("textLeading", $event)
                },
              },
            },
            [_c("i", { staticClass: "cil-line-spacing" })]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ApplyToAllTextDropdown", {
        attrs: { options: _vm.options, target: _vm.target },
      }),
      _vm._v(" "),
      _c("ChatGPTForm", {
        staticClass: "flex-grow-1 d-flex mt-2",
        attrs: { value: _vm.target.text },
        on: { change: _vm.onChange },
      }),
      _vm._v(" "),
      _c(
        "b-button",
        {
          attrs: { variant: "danger" },
          on: {
            click: function ($event) {
              return _vm.onChange("delete")
            },
          },
        },
        [
          _c("b-icon", { attrs: { icon: "trash" } }),
          _vm._v("\n    Delete\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c("LockToggle"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "dropdown-toggle d-flex align-items-center h-100",
        attrs: {
          "aria-expanded": "false",
          "aria-haspopup": "true",
          "data-toggle": "dropdown",
          title: "Tracking",
          type: "button",
        },
      },
      [_c("i", { staticClass: "glyphicon glyphicon glyphicon-bold" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }