export const INSTACART = 'instacart';

export const GOAL_FORMATS = {
    SHOPPABLE_DISPLAY : 'SHOPPABLE_DISPLAY',
    DISPLAY_BANNER: 'DISPLAY_BANNER',
}

export const ASSET_TYPES = {
    TOP_IMAGE_WEB: 'top_image_web',
    TOP_IMAGE_MOBILE: 'top_image_mobile',
    HERO_IMAGE: 'hero_image',
    BRAND_LOGO: 'brand_logo',
};

export const INSTACART_API_TYPES = {
    DISPLAY_ASSETS: 'display_assets',
    DISPLAY_CAMPAIGNS: 'display_campaigns',
    DISPLAY_AD_GROUPS: 'display_ad_groups',
    CAMPAIGN_FOR_APPROVAL: 'campaign_for_approval',
};
