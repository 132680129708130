export default [
    {
        name: "select_element",
        description:
            "Find/select an element based on their element_id. You can deduce the id based on extra information provided in the list of layers in the template. If you can't deduce, use the currently selected layer.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description:
                        "Id of the element that is going to be selected.",
                },
            },
            required: ["element_id"],
        },
    },
    {
        name: "change_element_product_id",
        description:
            "Edit product IDs, GTIN, or ASIN of an element based on their element_id. You can deduce the id based on extra information provided in the list of layers in the template. If you can't deduce, use the currently selected layer.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description:
                        "Id of the element that is going to be selected.",
                },
                new_product_id: {
                    type: "string",
                    description: "The new ID, GTIN or ASIN code.",
                },
            },
            required: ["element_id", "new_product_id"],
        },
    },
    {
        name: "replace_element_image",
        description:
            "Replace the image of an element based on their element_id. You can deduce the id based on extra information provided in the list of layers in the template. If you can't deduce, use the currently selected layer.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description:
                        "Id of the element that is going to be selected.",
                },
                description_of_the_new_image: {
                    type: "string",
                    description: "A description of the new image that will be generated with AI, and replace the old image.",
                },
            },
            required: ["element_id", "description_of_the_new_image"],
        },
    },
    {
        name: "edit_element_image",
        description:
            "Edit the image of an element based on their element_id. You can deduce the id based on extra information provided in the list of layers in the template. If you can't deduce, use the currently selected layer.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description:
                        "Id of the element that is going to be selected.",
                },
                changes_to_the_image: {
                    type: "string",
                    description: "A description of the changes that will be made to the image.",
                },
            },
            required: ["element_id", "changes_to_the_image"],
        },
    },
    {
        name: "edit_element_text",
        description:
            "Edit the text of an element based on their element_id. You can deduce the id based on extra information provided in the list of layers in the template. If you can't deduce, use the currently selected layer.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description:
                        "Id of the element that is going to be selected.",
                },
                new_text: {
                    type: "string",
                    description: "New text of the element.",
                },
                apply_to_all: {
                    type: "boolean",
                    description: "Apply the new text to all templates. It defaults to false unless requested by the user.",
                }
            },
            required: ["element_id", "new_text", "apply_to_all"],
        },
    },
    {
        name: "edit_element_color",
        description:
            "Edit the color of an element based on their element_id. You can deduce the id based on extra information provided in the list of layers in the template. If you can't deduce, use the currently selected layer.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description:
                        "Id of the element that is going to be edited.",
                },
                new_color: {
                    type: "string",
                    description: "New color of the element in hex format.",
                },
                apply_to_all: {
                    type: "boolean",
                    description: "Apply the new color to all templates. It defaults to false unless requested by the user.",
                }
            },
            required: ["element_id", "new_color", "apply_to_all"],
        },
    },
    {
        name: "edit_element_font_size",
        description:
            "Edit the font size of an element based on their element_id. You can deduce the id based on extra information provided in the list of layers in the template. If you can't deduce, use the currently selected layer.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description:
                        "Id of the element that is going to be edited.",
                },
                new_font_size: {
                    type: "string",
                    description: "New font size of the element.",
                },
                apply_to_all: {
                    type: "boolean",
                    description: "Apply the new font size to all templates. It defaults to false unless requested by the user.",
                }
            },
            required: ["element_id", "new_font_size", "apply_to_all"],
        },
    },
    {
        name: "edit_element_font_family",
        description:
            "Edit the font family of an element based on their element_id. You can deduce the id based on extra information provided in the list of layers in the template If you can't deduce, use the currently selected layer. Wathever font the user specifies should be accepted.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description:
                        "Id of the element that is going to be edited.",
                },
                apply_to_all: {
                    type: "boolean",
                    description: "Apply the new font family to all templates. It defaults to false unless requested by the user.",
                }
            },
            required: ["element_id", "apply_to_all"],
        },
    },
    {
        name: "edit_project_name",
        description: "Edit the project name of the template.",
        parameters: {
            type: "object",
            properties: {
                new_project_name: {
                    type: "string",
                    description: "New project name of the template.",
                },
            },
            required: ["new_project_name"],
        },
    },
    {
        name: "generate_proof_link",
        description: "Generate a proof link for the current project to share with others.",
    },
    {
        name: "check_product_id",
        description: "Use this function only when the user wants to verify if one or multiple product IDs, GTINs, or ASINs exist in the database. It should not be used as part of replacement or image editing flows.",
        parameters: {
            type: "object",
            properties: {
                product_id: {
                    type: "string",
                    description: "One or more product IDs, GTINs, or ASINs to check. For multiple IDs, separate them with spaces.",
                },
            },
            required: ["product_id"],
        }
    },
    {
        name: "publish_to_platform",
        description: "Open the publish dialog and select the specified platform",
        parameters: {
            type: "object",
            properties: {
                platform_type: {
                    type: "string",
                    enum: ['bynder', 'salsify', 'walmart'],
                    description: "Type of platform to publish to (Bynder, Salsify, or Walmart)"
                }
            },
            required: ['platform_type']
        },
        function: function(args) {
            const platformType = args.platform_type?.toLowerCase();
            if (!['bynder', 'salsify', 'walmart'].includes(platformType)) {
                return { error: `Unsupported platform type: ${platformType}` };
            }
            return { platform_type: platformType };
        }
    },
    {
        name: "move_image",
        description: "Move an image element in the specified direction. Works for product images and background theme images.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description: "Id of the image element that is going to be moved.",
                },
                direction: {
                    type: "string",
                    enum: ["up", "down", "left", "right"],
                    description: "Direction to move the image."
                },
                distance: {
                    type: "number",
                    description: "Distance to move the image in pixels. Default is 10 if not specified.",
                }
            },
            required: ["element_id", "direction"]
        }
    },
    {
        name: "rotate_image",
        description: "Rotate an image element by a specified angle. Works for product images and background theme images.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description: "Id of the image element that is going to be rotated.",
                },
                angle: {
                    type: "number",
                    description: "Angle in degrees to rotate the image. Use 90 for 90 degrees clockwise, -90 for 90 degrees counterclockwise, etc."
                }
            },
            required: ["element_id", "angle"]
        }
    },
    {
        name: "remove_image_background",
        description: "Remove the background from an image element. Works for product images and background theme images.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description: "Id of the image element to remove background from.",
                },
                crop_to_image: {
                    type: "boolean",
                    description: "Whether to crop to the image after removing the background."
                }
            },
            required: ["element_id"]
        }
    },
    {
        name: "align_text",
        description: "Change the text alignment of a text element.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description: "Id of the text element to align.",
                },
                alignment: {
                    type: "string",
                    enum: ["left", "center", "right"],
                    description: "Text alignment to apply."
                },
                apply_to_all: {
                    type: "boolean",
                    description: "Apply the alignment to all templates. Defaults to false unless requested by the user."
                }
            },
            required: ["element_id", "alignment", "apply_to_all"]
        }
    },
    {
        name: "move_text",
        description: "Move a text element in the specified direction.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description: "Id of the text element that is going to be moved.",
                },
                direction: {
                    type: "string",
                    enum: ["up", "down", "left", "right"],
                    description: "Direction to move the text element."
                },
                distance: {
                    type: "number",
                    description: "Distance to move the text element in pixels. Default is 10 if not specified."
                },
                apply_to_all: {
                    type: "boolean",
                    description: "Apply the movement to all templates. Defaults to false unless requested by the user."
                }
            },
            required: ["element_id", "direction", "apply_to_all"]
        }
    },
    {
        name: "edit_shape_color",
        description:
            "Change the background/fill color of a shape element. If no element_id is specified, the system will try to find a shape based on the context of the user's message.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description:
                        "Id of the element that is going to be edited. Optional - if not provided, will attempt to find a shape by context.",
                },
                new_color: {
                    type: "string",
                    description: "New color of the element in hex format.",
                },
                apply_to_all: {
                    type: "boolean",
                    description: "Apply the new color to all templates. It defaults to false unless requested by the user.",
                }
            },
            required: ["new_color", "apply_to_all"],
        },
    },
    {
        name: "edit_shape_border",
        description:
            "Edit the border of a shape based on their element_id. If no element_id is specified, the system will try to find a shape based on the context of the user's message.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description:
                        "Id of the element that is going to be edited. Optional - if not provided, will attempt to find a shape by context.",
                },
                border_color: {
                    type: "string",
                    description: "New border color in hex format.",
                },
                border_width: {
                    type: "number",
                    description: "New border width/thickness in pixels.",
                },
                apply_to_all: {
                    type: "boolean",
                    description: "Apply the changes to all templates. It defaults to false unless requested by the user.",
                }
            },
            required: ["apply_to_all"],
        },
    },
    {
        name: "move_shape",
        description:
            "Move a shape element in a specific direction. If no element_id is specified, the system will try to find a shape based on the context of the user's message.",
        parameters: {
            type: "object",
            properties: {
                element_id: {
                    type: "string",
                    description:
                        "Id of the element that is going to be moved. Optional - if not provided, will attempt to find a shape by context.",
                },
                direction: {
                    type: "string",
                    enum: ["up", "down", "left", "right"],
                    description: "Direction to move the shape."
                },
                distance: {
                    type: "number",
                    description: "Distance to move the shape in pixels. Default is 10 if not specified.",
                },
                apply_to_all: {
                    type: "boolean",
                    description: "Apply the changes to all templates. It defaults to false unless requested by the user.",
                }
            },
            required: ["direction", "apply_to_all"],
        },
    }
];
