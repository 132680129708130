import {PARSED_FIELD_TYPES} from "./constants";
import {Folder} from "@/includes/parse_psd_data/psd-types/folder";

export default class Template extends Folder {
    type = PARSED_FIELD_TYPES.TEMPLATE

    buildFromData(templateData) {
        super.buildFromData(templateData);
    }
}
