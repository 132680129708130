var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-button",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.html.hover.viewport.noninteractive",
          modifiers: {
            html: true,
            hover: true,
            viewport: true,
            noninteractive: true,
          },
        },
      ],
      staticClass: "rounded-circle d-flex p-1",
      attrs: {
        variant: "outline-primary",
        size: "sm",
        title: `<div class='text-left'>
    <b>To apply updates across templates:</b>
    <ol class='pl-3 m-0'>
      <li>Select the destination templates</li>
      <li>Select the ${_vm.actionsText}</li>
      <li>Click Apply</li>
    </ol>
  </div>`,
      },
    },
    [_c("b-icon", { attrs: { icon: "info", scale: "1.5" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }