export class FileFormURL {

    url;

    name;

    id;

    constructor(url, name, id) {
        this.url = url;
        this.name = name;
        this.id = id;
    }
}