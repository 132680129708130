<template>
  <div class="d-flex align-items-center apply-block">
    <b-form-checkbox
      v-model="isApplyToAll"
      class="mr-1"
      @change="onChange('applyToAll', isApplyToAll)"
    >
      Apply to
    </b-form-checkbox>

    <SelectTemplateDropdown
      :local-storage-key="LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE"
    />

    <div class="mx-1">
      :
    </div>

    <b-dropdown
      size="sm"
      variant="primary"
      text="Select properties"
      class="select-properties-dropdown"
      menu-class="p-0"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(property, index) in properties"
          :key="index"
        >
          <b-form-checkbox
            v-model="property.value"
            size="sm"
            @change="onChangeProperty(property)"
          >
            {{ property.text }}
          </b-form-checkbox>
        </b-list-group-item>
      </b-list-group>
    </b-dropdown>
  </div>
</template>

<script>
import SelectTemplateDropdown from './SelectTemplateDropdown.vue';
import { editorControlEventBroker, EVENTS } from '@frontend/group/modules/editor-control-popup/event-broker';
import {
  LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE,
} from '@frontend/group/modules/editor-control-popup/selected-template-for-apply-to-all'

export default {
  name: 'ApplyToAllImageDropdown',

  components: { SelectTemplateDropdown },

  props: {
    target: {
      type: Object,
      default: () => {
      },
    },
  },

  data() {
    return {
      LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE,
      isApplyToAll: false,
      properties: [
        {
          text: '% Size',
          key: 'size',
          targetKey: 'isApplyToImageSize',
          value: false,
        },
        {
          text: 'Position',
          key: 'position',
          targetKey: 'isApplyToImageSize',
          value: false,
        }
      ]
    };
  },

  methods: {
    onChange(action, value) {
      editorControlEventBroker.fire(EVENTS.IMAGE_CHANGED, { action, value })
    },
    onChangeProperty(property) {
      editorControlEventBroker.fire(EVENTS.CHANGE_IMAGE_PROPERTIES, property)
    }
  },
};
</script>

<style lang="scss">
.align-items-start {
  flex-wrap: wrap;
 .image-editor-actions--bg-actions {
   max-width: 100%;
 }
}
</style>