import Template from '@/banner_template/Template';
import { updateObject } from '../../helpers';
import {
  setDataForExistFields
} from "@frontend/group/modules/copy-and-resize/handlers/resize-proportional-template-handler/set-data-for-exist-fields";
import { FIELD_TYPE_MULTI_LINE_TEXT } from '@frontend/constants/type-fields-of-template';
import {
  handleObjectsIntersections
} from '@frontend/group/modules/copy-and-resize/handlers/resize-like-grid-handler/handle-objects-intersections';
import {
  getObjectScaleFactor
} from "@frontend/group/modules/copy-and-resize/handlers/resize-like-grid-handler/scale-functinonality";
import {
  interpolateVerticalView
} from "@frontend/group/modules/copy-and-resize/handlers/resize-like-grid-handler/interpolate-vertical-view";
import {
  COEFFICIENT_TO_APPLY_AFTER_TEXT_LINES_CONCAT,
  MINIMUM_OF_TEXT_TO_ENABLE_TEXT_LINES_REDUCING,
  PERCENTS_OF_HEIGHT_TO_ENABLE_TEXT_LINES_REDUCING,
  SHOULD_CLOSED_TO_THE_BOTTOM
} from "@frontend/group/modules/copy-and-resize/handlers/resize-like-grid-handler/constants";
import {applyPaddings} from "@frontend/group/modules/copy-and-resize/handlers/resize-like-grid-handler/padding";


const MAX_ITERATIONS_FOR_INTERSECTION = 20;

const inlinePositions = objects => JSON.stringify(objects.map(o => o.getPointByOrigin('left', 'top')))


const sortObjectsByXCoordinate = (objects, origins) => {
  return objects.sort((a, b) => {
    const aPoints = a.getPointByOrigin(...origins);
    const bPoints = b.getPointByOrigin(...origins);

    if (aPoints.x < bPoints.x) return -1;
    if (aPoints.x > bPoints.x) return 1;

    return 0;
  });
}

export const resizeLikeGridHandler = ({
  isCopyObject,
  dimension,
  mainTemplate,
  canvas,
  isUseDestinationTemplateFields,
  objects,
  templateSettings,
}) => {
  const origins = ['left', 'top'];
  const template = new Template(dimension?.template || {});
  const rsW = dimension.width / canvas.width;
  const rsH = dimension.height / canvas.height;

  const allObjects = isUseDestinationTemplateFields
    ? setDataForExistFields({
      objects,
      template,
      mainTemplate,
      isCopyObject,
      templateSettings
    })
    : objects;
  const sortedObjects = sortObjectsByXCoordinate(allObjects, origins);

  const resizedObjects = [];

  for (const _object of sortedObjects) {
    const object = isCopyObject ? _.cloneDeep(_object) : _object;
    const originalPoints = object.getPointByOrigin(...origins);
    const scale = getObjectScaleFactor({ object, dimension, canvas });

    let scaleX = object.scaleX * scale;
    let scaleY = object.scaleY * scale;

    // Calculate initial position
    let newOffsetX = dimension.width * (originalPoints.x / canvas.width);
    let newOffsetY = dimension.height * (originalPoints.y / canvas.height);

    // Ensure object stays within bounds
    const objectWidth = object.width * scaleX;
    const objectHeight = object.height * scaleY;

    // Adjust X position if outside bounds
    if (newOffsetX + objectWidth > dimension.width) {
      newOffsetX = dimension.width - objectWidth;
    }
    if (newOffsetX < 0) {
      newOffsetX = 0;
    }

    // Adjust Y position if outside bounds
    if (newOffsetY + objectHeight > dimension.height) {
      newOffsetY = dimension.height - objectHeight;
    }
    if (newOffsetY < 0) {
      newOffsetY = 0;
    }

    // If object is still too large, scale it down to fit
    if (objectWidth > dimension.width || objectHeight > dimension.height) {
      const widthRatio = dimension.width / objectWidth;
      const heightRatio = dimension.height / objectHeight;
      const adjustmentScale = Math.min(widthRatio, heightRatio) * 0.95; // 5% margin

      scaleX *= adjustmentScale;
      scaleY *= adjustmentScale;
    }

    // Apply new position and scale
    object.set({
      scaleX,
      scaleY,
    });

    object.setPositionByOrigin({
      x: newOffsetX,
      y: newOffsetY
    }, ...origins);

    object.setCoords();

    if (object.templateField?.type === FIELD_TYPE_MULTI_LINE_TEXT) {
      object.initDimensions();
    }

    updateObject(object, { x: rsW, y: rsH });
    resizedObjects.push(object);
  }

  // Apply padding without intersection checks
  applyPaddings({ objects: resizedObjects.filter(_object => !!_object.templateField), dimension, rsW, rsH, canvas });
}
