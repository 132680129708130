import { getInstanceByType } from "@/includes/parse_psd_data/type-resolver";


export default class PsdDataContainer {

    data;

    width;

    height;

    templates = [];

    entireImage = ''

    entireImageThumbnail = ''

    buildFromData(data) {
        this.data = data;
        this.width = data.width;
        this.height = data.height;
        this.templates = data.tree.map((template) => {
            return getInstanceByType({...template, parent: this});
        });
        this.entireImage = data.entireImage
        this.entireImageThumbnail = data.entireImageThumbnail

        return this;
    }

    toJSON() {
        return this.data;
    }
}
