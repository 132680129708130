import { dataURIToBlob } from "../helpers";
import axios from "axios";

export default {
  getTemplates (params = {}) {
    return axios.get('/video/templates/all', { params })
  },

  deleteTemplate (id) {
    return axios.delete(`/video/templates/${id}`)
  },

  attachTagToTemplate (id, tag) {
    return axios.post(`/video/templates/${id}/tag`, { tag })
  },

  detachTagFromTemplate (id, tagId) {
    return axios.delete(`/video/templates/${id}/tag/${tagId}`)
  },

  updateThumbnails (id, thumbnails) {
    const req = new FormData()
    thumbnails.forEach(({Image, Scene}) => {
      req.append('thumbnails[]', dataURIToBlob(Image))
      req.append('scenes[]', Scene)
    })
    return axios.post(`/video/templates/${id}/thumbnails`, req)
  },
  getTemplatesByCustomer(id) {
    return axios.post(`/banner/get-templates/${id}`)
  },
  getTemplatesByIds(ids) {
    return axios.get(`/templates?ids=${ids}`);
  },
  createTemplate(customerId, layoutId, payload) {
    return axios.post(`/banner/${customerId}/group/${layoutId}/template`, payload);
  },
  createAdditionalFieldRow(id) {
    return axios.post(`/banner/templates/${id}/additional-fields`)
  },
  importTemplateToDebug(customer, layout, formData) {
    return axios.post(`/banner/${customer}/group/${layout}/import-for-debug`, formData)
  },
  updateLayout(customer_id, layout_id, data) {
    return axios.post(`/banner/${customer_id}/group/${layout_id}/update_ajax`, data);
  },
  updateLayoutTemplate(customer_id, layout_id, data) {
    return axios.put(`/banner/${customer_id}/group/${layout_id}/save_changes`, data);
  },
  getTemplateFields(customer_id, template_id) {
    return axios.get(`/banner/${customer_id}/group/0/get_feed_mapping_fields?template_id=${template_id}&is_web=0`)
  },
}
