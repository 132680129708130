var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-badge",
    {
      staticClass: "a-psd-parsed-label",
      style: {
        left: _vm.left + "px",
        top: _vm.top + "px",
        right: _vm.right + "px",
        bottom: _vm.bottom + "px",
      },
      attrs: { pill: "", variant: "success" },
    },
    [_vm._v("\n  PSD parsed\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }