<template>
  <b-modal
    id="import-hyperlinks-modal"
    title="Import Hyperlinks"
    centered
    size="lg"
    @ok="handleOk"
    @hide="handleClose"
  >
    <b-form-row>
      <b-col cols="12">
        <b-form-group
          label="Upload JSON file"
        >
          <b-form-file
            v-model="file"
            placeholder="Choose JSON file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".json"
            @change="handleFileChange"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-row>
      <b-col cols="12">
        <b-table :items="hyperlinks" />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { importHyperlinksEventBroker, EVENTS } from '@frontend/group/modules/import-hyperlinks/event-broker';
import { mapHyperlinks, MAPPER_TYPES } from '@frontend/group/modules/import-hyperlinks/import-hyperlinks';

export default {
  name: 'ImportHyperlinks',
  data() {
    return {
      file: null,
      hyperlinks: [],
    };
  },
  computed: {
    ...mapGetters('layout', [
      'selectedInstance',
    ]),
  },
  watch: {
    file(newVal) {
      if (!newVal) {
        return;
      }

      this.readContent();
    }
  },
  mounted() {
    importHyperlinksEventBroker.on(EVENTS.INIT, this.init);
  },
  methods: {
    init() {
      this.$bvModal.show('import-hyperlinks-modal');
    },
    handleClose() {
      this.file = null;
      this.hyperlinks = [];
    },
    handleOk() {
      if (this.hyperlinks.length === 0) {
        toastr.error('No hyperlinks found');

        return;
      }

      importHyperlinksEventBroker.fire(EVENTS.DRAW, {
        hyperlinks: this.hyperlinks,
        instanceIds: this.selectedInstance?.id ? [this.selectedInstance.id] : null,
      });
    },
    handleFileChange(event) {
      if (event.target.files.length === 0) {
        return;
      }

      this.file = event.target.files[0];
    },
    readContent() {
      let reader = new FileReader();

      reader.onload = (event) => {
        const content = JSON.parse(event.target.result);

        this.mapHyperlinks(content);
      };

      reader.readAsText(this.file);
    },
    mapHyperlinks(content) {
      this.hyperlinks = mapHyperlinks(content, MAPPER_TYPES.DEFAULT);
    }
  }
}
</script>