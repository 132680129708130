import * as instacart from "@frontend/constants/instacart-constants";
import * as walmart from "@frontend/constants/walmart-constants";
import * as bynder from "@frontend/constants/bynder";
import store from '@frontend/store';
import {changeDpiBlob} from "changedpi";
import {
    FIELD_TYPE_TEXT,
    FIELD_TYPE_MULTI_LINE_TEXT,
} from '@frontend/constants/type-fields-of-template';

const MAX_NAME_LENGHT = 40;

export const getImageFromInstance = async (
    instanceId,
    previews,
    dpi = null,
    type = 'jpeg'
) => {
    let instance = previews.find(preview => preview.id === instanceId);

    const templateName = sanitizeTemplateName(instance);
    const fileName = `${templateName}_image.${type}`;

    const dataUrl = instance.canvas.toDataURL({
        format: type,
        quality: 0.8
    });

    return await convertDataUrlToFile(dataUrl, fileName, dpi, type);
}

export const convertDataUrlToFile = async (
    dataUrl,
    fileName = 'image.jpeg',
    dpi = null,
    type = 'jpeg'
) => {
    const base64 = dataUrl.replace(/^data:image\/\w+;base64,/, '');
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    let blob = new Blob([byteArray], { type: `image/${type}` });

    if (dpi) {
        blob = await changeDpiBlob(blob, dpi);
    }

    const file = new File([blob], fileName, { type: `image/${type}` });

    return file;
}

export const clearProducts = () => {
    $('#instacart-products').empty();
    $('#additional-product-ids-table').empty();
    $('#single-add-property').empty();
    $('#walmart-products').empty();
    $('#walmart-bulk-products').empty();
    $('#walmart-bulk-products-flex').empty();
    $('#additional-property-select').empty();
}

export const clearResults = () => {
    $('#publish_to_platform_result').empty();
    $('.asset-error').empty();
    $('#publishing_image_spinner').addClass('d-none');
    $('#salsify-publish-result').addClass('d-none');
    $('#salsify-publish-result-content').empty();
    $('#publish-progress-container').addClass('d-none');
    $('#publish-progress-text').html('');
    clearWalmartResponse();
}

export const resetButtons = () => {
    $('#publishToPlatform').removeAttr('disabled');
    $('#cancelPublish').text('Close');
    $('#publishToPlatform').html('Publish');
}

export const clearInstacartInputs = () => {
    $('#campaign-name').val('');
    $('#campaign-budget').val('1');
    $('#ad-group-name').val('');
    $('#default-bid').val('15');
    $('#ad-group-tagline').val('');
    $('.asset-alt-text').each(function () {
        $(this).val($(this).data('origin-value'));
    });
    $('.instacart-product-id').prop('checked', false);
    $('#existing-campaign').empty();
    $('#existing-ad-group').empty();
    $('#new-to-instacart').prop('checked', false);
    $('#targeting-everyone').prop('checked', false);

    clearInstacartAssets();
}

export const clearInstacartAssets = () => {
    $('.instacart-asset').each(function () {
        $(this).val('');
        $(this).removeAttr('data-alt-text');
        $(this).removeAttr('data-token');
        $(this).removeAttr('data-name');
        $(this).removeAttr('data-asset-type');
    });
}

export const instacartErrors = {
    campaignNameError: $('#campaign-name-error'),
    campaignBudgetError: $('#campaign-budget-error'),
    adGroupNameError: $('#ad-group-name-error'),
    defaultBidError: $('#default-bid-error'),
    adGroupTaglineError: $('#ad-group-tagline-error'),
    existingCampaignsError: $('#existing-campaigns-error'),
    campaignError: $('#instacart-campaign-error'),
    adGroupError: $('#instacart-ad-group-error')
}

export const clearInstacartErrors = () => {
    Object.values(instacartErrors).forEach(element => element.html(''));
}

export const getInstance = (previews, instanceId) => {
    return previews.find(preview => preview.id === instanceId);
}

export const fillWalmartInputs = (previews, instanceId) => {
    const headlineInput = $('#walmart-headline');
    const subheadInput = $('#walmart-subhead');
    const ctaInput = $('#walmart-cta');
    const imageAltTextInput = $('#walmart-image-alt-text');
    const logoAltTextInput = $('#walmart-logo-alt-text');

    const instance = getInstance(previews, instanceId);

    const headline = instance?.data?.multi_line_text_headline_1;
    const subhead = instance?.data?.multi_line_text_subhead_1;
    const cta = instance?.data?.multi_line_text_cta;

    if (headline) {
        headlineInput.val(headline);
        imageAltTextInput.val(headline);
        logoAltTextInput.val(headline);
    }

    if (subhead) {
        subheadInput.val(subhead);
    }

    if (cta) {
        ctaInput.val(cta);
    }

    clearWalmartFormErrors()
    validateWalmartForm()
}

export const fetchImages = (imageData = []) => {
    if (imageData.length) {
        const imageNames = imageData.map(url => {
            const decodedUrl = decodeURIComponent(url);

            const imageName = decodedUrl.split('/').pop().split('&')[0];

            return { [imageName]: decodedUrl };
        });

        return imageNames;
    } else {
        return [];
    }
}

export const clearWalmartInputs = () => {
    $('#walmart-creative-name').val('');
    $('#walmart-headline').val('');
    $('#walmart-subhead').val('');
    $('#walmart-cta').val('');
    $('#walmart-image-alt-text').val('');
    $('#walmart-logo-alt-text').val('');
}

export const walmartErrors = {
    creativeNameError: $('#walmart-creative-name-error'),
    headlineError: $('#walmart-headline-error'),
    subheadError: $('#walmart-subhead-error'),
    ctaError: $('#walmart-cta-error'),
    imageAltTextError: $('#walmart-image-alt-text-error'),
    logoAltTextError: $('#walmart-logo-alt-text-error')
}

export const clearWalmartFormErrors = () => {
    Object.values(walmartErrors).forEach(element => element.html('').siblings('input').removeClass('is-invalid'));
}

export const validateWalmartForm = () => {
    let isError = false;
    const creativeName = $('#walmart-creative-name').val();
    const headline = $('#walmart-headline').val();
    const subhead = $('#walmart-subhead').val();
    const imageAltText = $('#walmart-image-alt-text').val();
    const logoAltText = $('#walmart-logo-alt-text').val();
    const cta = $('#walmart-cta').val();
    const companyId = $('#company-id').val();

    if (!companyId && isMasterAdmin) {
        alert('Select Company');
        isError = true;
    }

    if (!creativeName) {
        walmartErrors.creativeNameError.html('Creative name is required').siblings('input').addClass('is-invalid');
        isError = true;
    }

    if (!headline) {
        walmartErrors.headlineError.html('Headline is required').siblings('input').addClass('is-invalid');
        isError = true;
    }

    if (headline.length > 25) {
        walmartErrors.headlineError.html('Headline should be max 25 characters').siblings('input').addClass('is-invalid');
        isError = true;
    }

    if (!subhead) {
        walmartErrors.subheadError.html('Subhead is required').siblings('input').addClass('is-invalid');
        isError = true;
    }

    if (subhead.length > 30) {
        walmartErrors.subheadError.html('Subhead should be max 30 characters').siblings('input').addClass('is-invalid');
        isError = true;
    }

    const subheadRegex = /[.!?*]$/;

    if (!subheadRegex.test(subhead)) {
        walmartErrors.subheadError.html('Subhead should end with one of these characters . ! ? *').siblings('input').addClass('is-invalid');
        isError = true;
    }

    if (!imageAltText) {
        walmartErrors.imageAltTextError.html('Image alt text is required').siblings('input').addClass('is-invalid');
        isError = true;
    }

    const numericRegex = /\d/;

    if (numericRegex.test(imageAltText)) {
        walmartErrors.imageAltTextError.html('Image alt text must not include numbers.<br> Instead, write numbers as words.').siblings('input').addClass('is-invalid');
        isError = true;
    }

    if (!logoAltText) {
        walmartErrors.logoAltTextError.html('Logo alt text is required').siblings('input').addClass('is-invalid');
        isError = true;
    }

    if (numericRegex.test(logoAltText)) {
        walmartErrors.logoAltTextError.html('Logo alt text must not include numbers.<br> Instead, write numbers as words.').siblings('input').addClass('is-invalid');
        isError = true;
    }

    if (!cta) {
        walmartErrors.ctaError.html('Cta is required').siblings('input').addClass('is-invalid');
        isError = true;
    }

    if (cta.length > 16) {
        walmartErrors.ctaError.html('Cta should be max 16 characters').siblings('input').addClass('is-invalid');
        isError = true;
    }

    const ctaRegex = /^[A-Z][a-z]*( [a-z][a-z]*)*[.!?]?$/;

    if (!ctaRegex.test(cta)) {
        walmartErrors.ctaError
            .html('Only the first word should be capitalized and CTA can end with one of these characters . ! ?')
            .siblings('input')
            .addClass('is-invalid');
        isError = true;
    }

    $('#walmart-create-creative').prop('disabled', isError)

    return !isError;
}

export const btnLoading = (button, loadingText = 'Loading...') => {
    $(button).prop('disabled', true).html(
        `<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>${loadingText}`
    );
};

export const btnLoaded = (button, defaultText = 'Submit') => {
    $(button).prop('disabled', false).html(defaultText);
};

export const fetchCampaigns = async (formData) => {
    const { data } = await axios.post('/instacart/fetch-campaigns', formData);

    return data;
}

export const fetchCampaign = async (formData) => {
    const { data } = await axios.post('/instacart/fetch-campaign', formData);

    return data;
}

export const fillExistingCampaignInputs = (data) => {
    $('#instacart-new-campaign').removeClass('d-none');

    $('#campaign-name').val(data.data.attributes.name);
    $('#campaign-type').val(data.data.attributes.goal_format);
    $('#pacing-type').val(data.data.attributes.pacing_type);
    $('#campaign-budget').val(data.data.attributes.budget);
    $('#campaign-starts-at').val(data.data.attributes.scheduled_start_at_date);
    $('#campaign-ends-at').val(data.data.attributes.scheduled_end_at_date);

    $('#instacart-campaign-id').val(data.data.id);
}

export const fetchAdGroups = async (formData) => {
    const { data } = await axios.post('/instacart/fetch-ad-groups', formData);

    return data;
}

export const fetchAdGroup = async (formData) => {
    const { data } = await axios.post('/instacart/fetch-ad-group', formData);

    return data;
}

export const fillExistingAdGroupInputs = (data) => {
    $('#instacart-new-ad-group').removeClass('d-none');

    $('#ad-group-name').val(data.data.attributes.name ?? '');
    $('#default-bid').val(data.data.attributes.default_bid ?? 15);
    $('#ad-group-tagline').val(data.data.attributes.creative.properties.tagline ?? '');
    $('#new-to-instacart').prop('checked', data.data.attributes.targeting_strategy.user_targeting.new_to_instacart ?? false);
    $('#targeting-everyone').prop('checked', data.data.attributes.targeting_strategy.user_targeting.targeting_everyone ?? false);

    $('#instacart-ad-group-id').val(data.data.id ?? '');
    $('#instacart-creative-id').val(data.data.attributes.creative.id ?? '');

    Object.keys(data.data.attributes.creative.assets).forEach(key => {
        const asset = data.data.attributes.creative.assets[key];

        if (key === instacart.ASSET_TYPES.TOP_IMAGE_WEB) {
            $('#top-image-web').val(asset.id);
            $('#top-image-web').attr('data-alt-text', asset.alt_text);
            $('#top-image-web').attr('data-token', asset.token);
            $('#top-image-web').attr('data-name', asset.uploaded_file_name);
            $('#top-image-web').attr('data-asset-type', key);
        }

        if (key === instacart.ASSET_TYPES.TOP_IMAGE_MOBILE) {
            $('#top-image-mobile').val(asset.id);
            $('#top-image-mobile').attr('data-alt-text', asset.alt_text);
            $('#top-image-mobile').attr('data-token', asset.token);
            $('#top-image-mobile').attr('data-name', asset.uploaded_file_name);
            $('#top-image-mobile').attr('data-asset-type', key);
        }

        if (key === instacart.ASSET_TYPES.HERO_IMAGE) {
            $('#hero-image').val(asset.id);
            $('#hero-image').attr('data-alt-text', asset.alt_text);
            $('#hero-image').attr('data-token', asset.token);
            $('#hero-image').attr('data-name', asset.uploaded_file_name);
            $('#hero-image').attr('data-asset-type', key);
        }

        if (key === instacart.ASSET_TYPES.BRAND_LOGO) {
            $('#brand-logo').val(asset.id);
            $('#brand-logo').attr('data-alt-text', asset.alt_text);
            $('#brand-logo').attr('data-token', asset.token);
            $('#brand-logo').attr('data-name', asset.uploaded_file_name);
            $('#brand-logo').attr('data-asset-type', key);
        }
    })
}

export const getAssetIdForUpdate = (assetType) => {
    switch (assetType) {
        case instacart.ASSET_TYPES.TOP_IMAGE_WEB:
            return $('#top-image-web').val();
        case instacart.ASSET_TYPES.TOP_IMAGE_MOBILE:
            return $('#top-image-mobile').val();
        case instacart.ASSET_TYPES.HERO_IMAGE:
            return $('#hero-image').val();
        case instacart.ASSET_TYPES.BRAND_LOGO:
            return $('#brand-logo').val();
        default:
            return null;
    }
}

export const isInstacartAvailable = async (platformId) => {
    const { data } = await axios.post('/instacart/is-platform-available', { platform_id: platformId });

    if (data === true) {
        $('#instacart-fields').removeClass('d-none');
        $('#instacart-not-available').addClass('d-none');
    } else {
        $('#instacart-fields').addClass('d-none');
        $('#instacart-not-available').removeClass('d-none');
    }
}

export const updateCampaign = async () => {
    toastr.clear();
    clearInstacartErrors();

    let isError = false;

    const platformId = $('#deployment_platform').find(':selected').val();
    const companyId = $('#company-id').val();

    const campaignType = $('#campaign-type').find(':selected').val();
    const pacingType = $('#pacing-type').find(':selected').val();
    const campaignName = $('#campaign-name').val();
    const campaignBudget = $('#campaign-budget').val();
    const campaignStartsAt = $('#campaign-starts-at').val();
    const campaignEndsAt = $('#campaign-ends-at').val();

    const campaignId = $('#instacart-campaign-id').val();

    if (!companyId && isMasterAdmin) {
        alert('Select Company');
        return;
    }

    if (!campaignName) {
        instacartErrors.campaignNameError.html('Campaign name is required');
        isError = true;
    }

    if (!campaignBudget) {
        instacartErrors.campaignBudgetError.html('Campaign budget is required');
        isError = true;
    }

    if (campaignBudget < 1) {
        instacartErrors.campaignBudgetError.html('Campaign budget must be a minimum of 1');
        isError = true;
    }

    if (isError) {
        toastr.error('Please check the errors');
        return;
    }

    const formData = {
        platform_id: platformId,
        company_id: companyId,
        campaign_type: campaignType,
        pacing_type: pacingType,
        campaign_name: campaignName,
        campaign_budget: campaignBudget,
        campaign_starts_at: campaignStartsAt,
        campaign_ends_at: campaignEndsAt,
        campaign_id: campaignId
    };

    btnLoading('#update-campaign-btn', 'Updating...');

    const { data } = await axios.post('/instacart/update-campaign', formData);

    if (data.status === 'error' || data.status === 'no_code' || data.status === 'invalid_grant') {
        instacartErrors.campaignError.html(data.message);
        btnLoaded('#update-campaign-btn', 'Update Campaign');
        return;
    }

    toastr.success('Campaign updated successfully');
    btnLoaded('#update-campaign-btn', 'Update Campaign');

    return;
}

export const updateAdGroup = async () => {
    toastr.clear();
    clearInstacartErrors();

    let isError = false;

    const platformId = $('#deployment_platform').find(':selected').val();
    const companyId = $('#company-id').val();
    const campaignType = $('#campaign-type').find(':selected').val();

    const adGroupName = $('#ad-group-name').val();
    const defaultBid = $('#default-bid').val();
    const adGroupTagline = $('#ad-group-tagline').val();
    const newToInstacart = $('#new-to-instacart').is(':checked');
    const targetingEveryone = $('#targeting-everyone').is(':checked');

    const campaignId = $('#instacart-campaign-id').val();
    const adGroupId = $('#instacart-ad-group-id').val();
    const creativeId = $('#instacart-creative-id').val();

    if (!companyId && isMasterAdmin) {
        alert('Select Company');
        return;
    }

    if (!adGroupName) {
        instacartErrors.adGroupNameError.html('Ad group name is required');
        isError = true;
    }

    if (!defaultBid) {
        instacartErrors.defaultBidError.html('Default bid is required');
        isError = true;
    }

    if (defaultBid < 15) {
        instacartErrors.defaultBidError.html('Default bid must be a minimum of 15');
        $('#default-bid').val(15);
        isError = true;
    }

    if (!adGroupTagline) {
        instacartErrors.adGroupTaglineError.html('Ad group tagline is required');
        isError = true;
    }

    if (isError) {
        toastr.error('Please check the errors');
        return;
    }

    let filesData = [];

    $('.instacart-asset').each(function () {
        if ($(this).val()) {
            filesData.push({
                file_name: $(this).data('name'),
                token: $(this).data('token'),
                asset_type: $(this).data('asset-type'),
                alt_text: $(this).data('alt-text'),
                asset_id: $(this).val()
            });
        }
    });

    const formData = {
        platform_id: platformId,
        company_id: companyId,
        campaign_id: campaignId,
        campaign_type: campaignType,
        ad_group_name: adGroupName,
        default_bid: defaultBid,
        ad_group_tagline: adGroupTagline,
        new_to_instacart: newToInstacart,
        targeting_everyone: targetingEveryone,
        ad_group_id: adGroupId,
        creative_id: creativeId,
        files_data: JSON.stringify(filesData)
    }

    btnLoading('#update-ad-group-btn', 'Updating...');

    const { data } = await axios.post('/instacart/update-ad-group', formData);

    if (data.status === 'error' || data.status === 'no_code' || data.status === 'invalid_grant') {
        instacartErrors.adGroupError.html(data.message);
        btnLoaded('#update-ad-group-btn', 'Update Ad Group');
        return;
    }

    toastr.success('Ad Group updated successfully');
    btnLoaded('#update-ad-group-btn', 'Update Ad Group');

    return;
}

export const clearWalmartResponse = () => {
    $('#walmart-publish-response').html('');
    $('#walmart-publish-error').html('');
    $('.walmart-image-error').html('');
}

export const clearWalmartAssetResponse = () => {
    $('.image-response').addClass('d-none');
    $('.image-response-text').html('');
}

export const createCreative = async () => {
    const walmartResponse = $('#walmart-publish-response');
    const walmartError = $('#walmart-publish-error');

    clearWalmartResponse();
    clearWalmartFormErrors();

    const assets = store.getters['walmart/getAssets'];
    const checkDefaultAssets = store.getters['walmart/checkGalleryDesktopAssets'];

    let activeInstances = [];
    let filesData = [];
    let assetsData = [];

    const uploadType = $('input[name="walmart-upload-type"]:checked').val();

    let singleUpload = false;
    let walmartBulkPublish = false;
    let assetSelector = false;
    let bulkPublishFlex = false;

    if (uploadType === 'walmart-single-upload') {
        singleUpload = true;
    }
    if (uploadType === 'walmart-bulk-upload') {
        walmartBulkPublish = true;
    }
    if (uploadType === 'walmart-asset-selector') {
        assetSelector = true;
    }
    if (uploadType === 'walmart-bulk-upload-flex') {
        bulkPublishFlex = true;
    }

    const customerId = $('#template-group-preview').attr('data-customer-id');
    const layoutId = $('#template-group-preview').attr('data-layout-id');

    const creativeName = $('#walmart-creative-name').val();
    const headline = $('#walmart-headline').val();
    const subhead = $('#walmart-subhead').val();
    const imageAltText = $('#walmart-image-alt-text').val();
    const logoAltText = $('#walmart-logo-alt-text').val();
    const cta = $('#walmart-cta').val();
    const platformId = $('#deployment_platform').find(':selected').val();
    const companyId = $('#company-id').val();
    const folderId = $('#walmart-folder-id').val();

    if (!validateWalmartForm()) {
        toastr.error('Please check the errors');
        return;
    }

    const formData = {
        'creative_name': creativeName,
        'headline': headline,
        'subhead': subhead,
        'image_alt_text': imageAltText,
        'logo_alt_text': logoAltText,
        'cta': cta,
        'platform_id': platformId,
        'company_id': companyId,
        'folder_id': folderId,
        'is_bulk_publish': walmartBulkPublish,
        'layout_id': layoutId,
        'customer_id': customerId,
        'is_asset_selector': assetSelector,
        'is_single_upload': singleUpload,
        'is_bulk_publish_flex': bulkPublishFlex
    };

    const fileData = new FormData();
    fileData.set('platform_id', platformId);
    fileData.set('company_id', companyId);

    if (singleUpload) {
        const logoId = $('#walmart-logo-image-id').val();
        const lifestyleId = $('#walmart-lifestyle-image-id').val();

        if (!logoId || !lifestyleId) {
            alert('Please upload Logo and Lifestyle');
            return;
        }

        formData.logo_id = logoId;
        formData.lifestyle_id = lifestyleId;

        btnLoading('#walmart-create-creative', 'Creating...');
    }

    if (walmartBulkPublish) {
        const maxCheckboxes = 8;

        const selectedCheckboxes = $('.walmart-creative-image-bulk:checked').length;

        if (selectedCheckboxes < maxCheckboxes) {
            alert('You need to select all asset types');
            return;
        }

        $('.walmart-creative-image-bulk').each(function () {
            let instanceId = null;
            if ($(this).is(':checked')) {
                instanceId = $(this).val();
                activeInstances.push(instanceId);
            }
        });

        for (const el of activeInstances) {
            const imageResponse = $('.image-response[data-instance-id="' + el + '"]');
            const imageResponseText = $('.image-response-text[data-instance-id="' + el + '"]');
            const imageError = $('.walmart-image-error[data-instance-id="' + el + '"]');

            const creativeType = $('.walmart-creative-image-type[data-instance-id="' + el + '"]').val();

            const file = await getImageFromInstance(el, previews);

            fileData.set('file', file);
            fileData.set('is_bulk_publish', walmartBulkPublish);

            btnLoading('#walmart-create-creative', 'Creating...');

            const { data } = await axios.post('/walmart/upload-creative-image', fileData);

            if (data?.result?.original?.status === 'error') {
                imageError.html(JSON.stringify(data?.result?.original?.message));
                btnLoaded('#walmart-create-creative', 'Create the Creative');
                return;
            }

            if (data?.status === 'error') {
                imageError.html(data.message);
                btnLoaded('#walmart-create-creative', 'Create the Creative');
                return;
            }

            imageResponse.removeClass('d-none');
            imageResponseText.html(data.data[0].assetId);

            filesData.push({
                asset_id: data.data[0].assetId,
                creative_type: creativeType
            });
        }
    }

    if (bulkPublishFlex) {
        const selectedTypes = [];

        const $selectedOptions = $('.walmart-creative-image-flex').find(':selected');

        $selectedOptions.each(function () {
            const select = $(this).closest('.walmart-creative-image-flex');
            const instanceId = select.data('instance-id');

            const creativeType = $('.walmart-creative-image-type-flex[data-instance-id="' + instanceId + '"]').val();

            if (creativeType) {
                selectedTypes.push(creativeType);
            }
        });

        const requiredTypes = Object.values(walmart.adUnits);

        const missingTypes = requiredTypes.filter(type => !selectedTypes.includes(type));

        if (missingTypes.length > 0) {
            toastr.options.timeOut = 0;

            toastr.error('You need to select all asset types. Missing: ' + missingTypes.join(', '));
            return;
        }

        for (const option of $selectedOptions) {
            const $option = $(option);

            const select = $option.closest('.walmart-creative-image-flex');
            const instanceId = select.data('instance-id');

            const imageResponse = $('.image-response[data-instance-id="' + instanceId + '"]');
            const imageResponseText = $('.image-response-text[data-instance-id="' + instanceId + '"]');
            const imageError = $('.walmart-image-error[data-instance-id="' + instanceId + '"]');

            const creativeType = $('.walmart-creative-image-type-flex[data-instance-id="' + instanceId + '"]').val();

            const isCanvasImage = $option.data('canvas-image');

            fileData.set('is_canvas_image', isCanvasImage);

            if (isCanvasImage) {
                fileData.set('file', await convertDataUrlToFile($option.val(), $option.data('file-name')));
            } else {
                fileData.set('image_url', $option.val());
            }

            btnLoading('#walmart-create-creative', 'Creating...');

            const { data } = await axios.post('/walmart/upload-creative-image', fileData);

            if (data?.result?.original?.status === 'error') {
                imageError.html(JSON.stringify(data?.result?.original?.message));
                btnLoaded('#walmart-create-creative', 'Create the Creative');
                return;
            }

            if (data?.status === 'error') {
                imageError.html(data.message);
                btnLoaded('#walmart-create-creative', 'Create the Creative');
                return;
            }

            imageResponse.removeClass('d-none');
            imageResponseText.html(data.data[0].assetId);

            filesData.push({
                asset_id: data.data[0].assetId,
                creative_type: creativeType
            });
        }
    }

    if (assetSelector) {
        if (assets.length < 2 || !checkDefaultAssets) {
            toastr.options = {
                timeOut: 10000,
                extendedTimeOut: 0
            };

            toastr.error('You need to select at least Image and Logo for Gallery Desktop.');
            return;
        }

        assetsData = assets;

        btnLoading('#walmart-create-creative', 'Creating...');
    }

    formData.files_data = JSON.stringify(filesData);
    formData.assets_data = JSON.stringify(assetsData);

    try {
        const { data } = await axios.post('/walmart/create-the-creatives', formData);

        if (data?.result?.original?.status === 'error') {
            walmartError.html(JSON.stringify(data?.result?.original?.message));
            return;
        }

        if (data?.status === 'error') {
            walmartError.html(data.message);
            return;
        }

        const heading = $('<p>', {
            text: 'You can check your Creative Previews here'
        });

        const button = $('<a>', {
            text: 'Previews',
            class: 'btn btn-primary btn-sm',
            href: '/walmart/show-preview?creative_id=' + data.data[0].creativeId + '&platform_id=' + platformId + '&company_id=' + companyId,
            target: '_blank'
        });

        const divWrapper = $('<div>', {
            class: 'form-group text-center preview-wrapper mt-2'
        });

        divWrapper.append(heading);
        divWrapper.append(button);

        walmartResponse.append(divWrapper);

        btnLoaded('#walmart-create-creative', 'Create the Creative');
    } catch (e) {
        console.log(e);
    } finally {
        btnLoaded('#walmart-create-creative', 'Create the Creative');
    }
}

export const newWalmartFolder = () => {
    $('#walmart-folder-name').removeClass('d-none');
    $('#walmart-create-folder').removeClass('d-none');
    $('#walmart-cancel-folder').removeClass('d-none');
    $('#walmart-delete-folder').addClass('d-none');
    $('#walmart-new-folder').addClass('d-none');
}

export const cancelWalmartFolder = () => {
    $('#walmart-folder-name').addClass('d-none').val('');
    $('#walmart-create-folder').addClass('d-none');
    $('#walmart-cancel-folder').addClass('d-none');
    $('#walmart-delete-folder').removeClass('d-none');
    $('#walmart-new-folder').removeClass('d-none');
}

export const createWalmartFolder = async () => {
    toastr.clear();
    toastr.options = {
        timeOut: 10000,
        extendedTimeOut: 0
    };

    const folderNameError = $('#walmart-folder-name-error');

    $('#walmart-folders-error').html('');
    folderNameError.html('');

    const folderName = $('#walmart-folder-name').val();
    const platformId = $('#deployment_platform').find(':selected').val();
    const parentFolder = $('#walmart-folder-id').val();

    if (!parentFolder) {
        toastr.error('Select Folder');
        return;
    }

    const companyId = $('#company-id').val();

    if (!companyId && isMasterAdmin) {
        toastr.error('Select Company');
        return;
    }

    if (!folderName) {
        folderNameError.html('Folder name is required');
        return;
    }

    const formData = {
        'folder_name': folderName,
        'platform_id': platformId,
        'parent_folder': parentFolder,
        'company_id': companyId
    };

    btnLoading('#walmart-create-folder', 'Creating...');

    const { data } = await axios.post('/walmart/create-folder', formData);

    try {
        if (data?.result?.original?.status === 'error') {
            folderNameError.html(extractErrorMessage(data.result.original));
            return;
        }

        if (data?.status === 'error') {
            folderNameError.html(extractErrorMessage(data));
            return;
        }

        const newFolder = {
            id: data.data[0].folderId,
            name: folderName,
            is_default: false
        };

        cancelWalmartFolder();

        fetchWalmartFolders(newFolder);

        toastr.success('Folder Created');
    } catch (e) {
        console.log('Folder error', e);
    } finally {
        btnLoaded('#walmart-create-folder', 'Create Folder');
    }

}

export const deleteWalmartFolder = async () => {
    toastr.clear();
    toastr.options = {
        timeOut: 10000,
        extendedTimeOut: 0
    };

    const companyId = $('#company-id').val();

    if (!companyId && isMasterAdmin) {
        alert('Select Company');
        return;
    }

    const formData = {
        'platform_id': $('#deployment_platform').find(':selected').val(),
        'company_id': companyId,
        'folder_id': $('#walmart-folder-id').val()
    };

    btnLoading('#walmart-delete-folder', 'Deleting...');

    try {
        const { data } = await axios.post('/walmart/delete-folder', formData);

        if (data?.result?.original?.status === 'error') {
            toastr.error(JSON.stringify(data?.result?.original?.message));
            return;
        }

        if (data?.status === 'error') {
            const errorMessage = extractErrorMessage(data);

            toastr.error(errorMessage);
            return;
        }

        toastr.success('Folder deleted successfully');
        fetchWalmartFolders();
    } catch (error) {
        toastr.error('Something went wrong while deleting the folder');
    } finally {
        btnLoaded('#walmart-delete-folder', 'Delete Folder');
    }
}

export const formatImageOption = (option) => {
    if (!option.id) return option.text;

    const imgUrl = $(option.element).data('img');

    return $(`
      <span class="d-flex align-items-center">
        <img src="${imgUrl}" style="height: 30px; margin-right: 8px;" />
        <span style="margin-left: auto;">${option.text}</span>
      </span>
    `);
}

export const formatImageSelection = (option) => {
    if (!option.id) return option.text;

    const imgUrl = $(option.element).data('img');

    return $(`
      <span class="d-flex align-items-center">
        <img src="${imgUrl}" style="height: 15px; margin-right: 4px;" />
        <span style="margin-left: auto;">${option.text}</span>
      </span>
    `);
}

export const walmartImageSelect = (id, selectClass) => {
    const select = $('.' + selectClass + '[data-instance-id="' + id + '"]');

    select.select2({
        placeholder: 'Select image',
        allowClear: true,
        templateResult: formatImageOption,
        templateSelection: formatImageSelection
    });

    select.next('.select2-container').find('.select2-search__field').css({'font-size': '80%'});
    select.next('.select2-container').find('.select2-selection--single').css({'height': '32px'});

    return select;
}

export const walmartGetImages = (instanceId, previews, selectClass = 'walmart-creative-image') => {
    const imageOptions = {
        format: 'jpeg',
        quality: 0.8
    };

    const select = walmartImageSelect(instanceId, selectClass);

    select.empty();

    const instance = getInstance(previews, instanceId);
    const templateName = sanitizeTemplateName(instance);

    const imageData = instance.data.background ?? [];

    const images = fetchImages(imageData);

    select.removeClass('d-none');

    const groups = instance.canvas.getObjects('group');

    let entireImage = instance.canvas.toDataURL(imageOptions);
    let entireName = `${templateName}_entire.jpeg`;
    let entireNameTruncated = truncateImgName(entireName, 30);

    select.append(
        `<option
            value="${entireImage}"
            data-img="${entireImage}"
            data-canvas-image="true"
            data-file-name="${entireName}"
        >
            ${entireNameTruncated}
        </option>`
    );

    if (groups.length) {
        const groupOptions = groups
            .map((group, index) => {
                let image = group.toDataURL(imageOptions);

                let groupName = `${templateName}_group_${index + 1}.jpeg`;
                let groupNameTruncated = truncateImgName(groupName, 30);

                return `
                    <option
                        value="${image}"
                        data-img="${image}"
                        data-canvas-image="true"
                        data-file-name="${groupName}"
                    >
                        ${groupNameTruncated}
                    </option>
                `;
            })
            .join('');

        select.append(groupOptions);
    }

    if (images.length) {
        const options = images
            .filter(item => {
                const [key, value] = Object.entries(item)[0];
                return key !== 'null' && value !== 'null' && value !== null;
            })
            .map(item => {
                const [key, value] = Object.entries(item)[0];

                let bgImgNameTruncated = truncateImgName(key, 30);

                return value
                    ? `<option
                      value="${value}"
                      data-img="${value}"
                      data-canvas-image="false"
                      data-file-name="${key}"
                  >
                      ${bgImgNameTruncated}
                  </option>`
                    : '';
            })
            .join('');

        select.append(options);
    }
}

export const handleWalmartUpload = async (instanceId, walmartImageData) => {
    const layoutId = $("#template-group-preview").attr("data-layout-id");
    const platformId = $('#deployment_platform').find(':selected').val();
    const imageResponse = $('.image-response[data-instance-id="' + instanceId + '"]');
    const imageError = $('.walmart-image-error[data-instance-id="' + instanceId + '"]');

    imageError.html('');
    imageResponse.empty();

    const companyId = $('#company-id').val();

    if (!companyId && isMasterAdmin) {
        alert('Select Company');
        return;
    }

    const formData = new FormData();

    formData.append('layout_id', layoutId);
    formData.append('instance_id', instanceId);
    formData.append('platform_id', platformId);
    formData.append('company_id', companyId);

    btnLoading('.walmart-upload-btn[data-instance-id="' + instanceId + '"]', 'Publishing...');

    for (let index = 0; index < walmartImageData[instanceId].length; index++) {
        const item = walmartImageData[instanceId][index];
        let isCanvasImage = item.isCanvasImage;
        let fileName = item.fileName;

        formData.set('is_canvas_image', isCanvasImage);

        if (isCanvasImage) {
            formData.set('file', await convertDataUrlToFile(item.value, fileName));
        } else {
            formData.set('image_url', item.value);
        }

        try {
            const { data } = await axios.post('/walmart/upload-creative-image', formData);

            if (data?.result?.original?.status === 'error') {
                imageError.html(JSON.stringify(data?.result?.original?.message));
                btnLoaded('.walmart-upload-btn[data-instance-id="' + instanceId + '"]', 'Publish');
                return;
            }

            if (data?.status === 'error') {
                imageError.html(data.message);
                btnLoaded('.walmart-upload-btn[data-instance-id="' + instanceId + '"]', 'Publish');
                return;
            }

            imageResponse.removeClass('d-none');

            let assetId = data.data[0].assetId;

            let itemName = truncateImgName(item.fileName);

            const responseBlock = `
                <div class="d-flex align-items-center mb-2">
                    <div class="d-flex align-items-center w-100">
                        <p
                            class="mb-0 mr-2 mt-2 flex-grow-1"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Asset ID: ${assetId}"
                        >
                            Uploaded:
                            <span class="text-success image-response-text mr-2" data-asset-id="${assetId}">
                                ${itemName}
                            </span>
                        </p>
                        <button
                            class="btn btn-sm btn-outline-primary copy-asset-id-btn mr-2"
                            data-asset-id="${assetId}"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Copy Asset ID"
                        >
                            <i class="bi bi-clipboard" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="dropdown ml-auto" id="walmart-use-buttons-${assetId}">
                        <button
                            data-asset-id="${assetId}"
                            type="button"
                            class="btn btn-sm btn-primary dropdown-toggle walmart-use-buttons mr-2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style="width: 145px;"
                        >
                            Select image type
                        </button>
                        <div class="dropdown-menu">
                            <button
                                data-asset-id="${assetId}"
                                type="button"
                                class="dropdown-item walmart-use-as-lifestyle"
                                style="width: 145px;"
                            >
                                Lifestyle
                            </button>
                            <button
                                data-asset-id="${assetId}"
                                type="button"
                                class="dropdown-item walmart-use-as-logo"
                                style="width: 145px;"
                            >
                                Logo
                            </button>
                        </div>
                    </div>
                    <div>
                        <button
                            data-asset-id="${assetId}"
                            type="button"
                            class="btn btn-sm btn-outline-secondary drop-asset-selection-btn"
                            disabled
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Drop selection"
                        >
                            <i class="bi bi-x-circle"></i>
                        </button>
                    </div>
                </div>
                `;

            imageResponse.append(responseBlock);

            $(function () {
                $('[data-toggle="tooltip"]').tooltip();
            });
        } catch (error) {
            imageError.html('An error occurred while uploading.');
            console.error(error);
        }
    }

    btnLoaded('.walmart-upload-btn[data-instance-id="' + instanceId + '"]', 'Publish');
}

export const fetchWalmartFolders = async (newFolder = null) => {
    $('#walmart-folder-name-error').html('');

    const foldersError = $('#walmart-folders-error');
    foldersError.html('');

    const platformId = Number($('#deployment_platform').find(':selected').val());

    let companyId = $('#company-id').val();
    companyId = companyId ? Number(companyId) : null;

    if (!companyId && isMasterAdmin) {
        alert('Select Company');
        return;
    }

    const formData = {
        'platform_id': platformId,
        'company_id': companyId,
        'is_default_folder': true
    };

    btnLoading('#request-walmart-folders', 'Loading...');

    try {
        const { data } = await axios.post('/walmart/fetch-folders', formData);

        if (data?.status === 'error') {
            const errorMessage = extractErrorMessage(data);

            foldersError.html(errorMessage);
            return;
        }

        if (data?.data?.status === 'error') {
            foldersError.html(data.data.message);
            return;
        }

        if (data?.result?.original?.status === 'error') {
            folderNameError.html(extractErrorMessage(data.result.original));
            return;
        }

        const defaultFolder = {
            id: data.folder_id,
            name: data.folder_name,
            is_default: true
        };

        const nestedFolders = [];

        data.data.response.forEach(folder => {
            nestedFolders.push({
                id: folder.folderId,
                name: folder.name,
                is_default: false
            });
        });

        $('#walmart-folders').children('walmart-folder-tree').remove();

        const el = document.createElement('walmart-folder-tree');

        el.defaultFolder = defaultFolder;
        el.nestedFolders = nestedFolders;
        el.platformId = platformId;
        el.companyId = companyId ?? null;
        el.newFolder = newFolder;

        $('#walmart-folders').prepend(el);

    } catch (error) {
        foldersError.html('Error fetching folders. Please try again.');
    } finally {
        btnLoaded('#request-walmart-folders', 'Request Folders');
    }
}

export const extractErrorMessage = (data) => {
    if (data?.status !== 'error') return null;

    try {
        const parsedMessage = JSON.parse(data.message);

        if (Array.isArray(parsedMessage) && parsedMessage[0]?.details) {
            return parsedMessage[0].details.join(", ");
        }
        else if (parsedMessage?.details) {
            return parsedMessage.details.join(", ");
        }

        return data.message;
    } catch (e) {
        return data.message;
    }
}

export const sanitizeTemplateName = (instance) => {
    return instance.template.name.replace(/\s+/g, ' ').replace(/[^\w\s]/gi, '').replace(/\s/g, '_');
}

export const clearBynderProducts = () => {
    $('#bynder-single-product').html('');
    $('#bynder-multi-products').html('');
}

export const clearBynderResponse = () => {
    $('.bynder-image-response').html('');
    $('.bynder-image-error').html('');
}

export const publishToBynder = async (isBynderMeta = false, bynderMeta = null) => {
    clearBynderResponse();

    toastr.clear();

    const progressBarContainer = $('#publish-progress-container');
    const progressBar = $('#publish-progress-bar');

    try {
        const uploadType = $('#bynder-upload-type').val();
        const dpi = $('#bynder-dpi').find(':selected').val();
        const imageType = $('#bynder-image-type').find(':selected').val();
        const layoutId = $("#template-group-preview").attr("data-layout-id");
        let includePsd = false;

        const layoutLink = window.location.href;
        const platformId = $('#deployment_platform').find(':selected').val();
        const formData = new FormData();
        formData.append('platform_id', platformId);
        formData.append('layout_link', layoutLink);
        formData.append('image_type', imageType);
        formData.append('layout_id', layoutId);
        formData.append('dpi', dpi);

        if (isBynderMeta) {
            formData.append('is_bynder_meta', true);
            formData.append('bynder_meta', JSON.stringify(bynderMeta));
        }

        let instancesToPublish = [];
        const singleProductId = $('#bynder-single-product-id').val();

        if (singleProductId) {
            instancesToPublish.push(singleProductId);
        }

        const selectedProducts = $('.bynder-multi-product:checked').length;

        if (selectedProducts > 0) {
            $('.bynder-multi-product').each(function () {
                let instanceId = null;
                if ($(this).is(':checked')) {
                    instanceId = $(this).val();
                    instancesToPublish.push(instanceId);
                }
            });
        }

        if (instancesToPublish.length === 0) {
            toastr.options = {
                timeOut: 10000,
                extendedTimeOut: 0
            };

            toastr.error('Select at least one image');

            return;
        }

        if ($('#remember-bynder-settings').is(':checked')) {
            const settings = {
                bynder_dpi: $('#bynder-dpi').find(':selected').val(),
                bynder_image_type: $('#bynder-image-type').find(':selected').val(),
            }

            await storePublishPreferences(bynder.BYNDER_PUBLISH_SETTINGS, JSON.stringify(settings));
        } else {
            await storePublishPreferences(bynder.BYNDER_PUBLISH_SETTINGS, null);
        }

        const totalInstances = instancesToPublish.length;
        let processedInstances = 0;

        if (uploadType === bynder.BYNDER_UPLOAD_TYPES.MULTIPLE) {
            progressBarContainer.removeClass('d-none');
            progressBar.css('width', '0%').attr('aria-valuenow', 0);
        }

        if (instancesToPublish.length > 0) {
            for (const instanceId of instancesToPublish) {
                try {
                    if (imageType === bynder.BYNDER_IMAGE_TYPES.PSD) {
                        includePsd = true;
                    } else {
                        let file = await getImageFromInstance(instanceId, previews, dpi, imageType);

                        formData.set('file', file);
                    }

                    let instance = previews.find(preview => preview.id === instanceId);

                    let templateName = sanitizeTemplateName(instance);

                    formData.set('include_psd', includePsd);
                    formData.set('instance_id', instanceId);
                    formData.set('template_name', templateName);

                    btnLoading('#bynder-publish', 'Publishing...');

                    const { data } = await axios.post('/bynder/file-upload', formData);

                    if (data?.status === 'error') {
                        $('.bynder-image-error[data-instance-id="' + instanceId + '"]').html(data.message);

                        return;
                    }

                    $('.bynder-image-response[data-instance-id="' + instanceId + '"]').html(data.message);

                    toastr.success('Successfully published to Bynder');

                    if (uploadType === bynder.BYNDER_UPLOAD_TYPES.MULTIPLE) {
                        processedInstances++;
                        const progressPercent = Math.round((processedInstances / totalInstances) * 100);
                        progressBar.css('width', `${progressPercent}%`).attr('aria-valuenow', progressPercent).html(`${progressPercent.toFixed(0)}%`);
                    }
                } catch (error) {
                    console.error('Error publishing instance:', error);
                }
            }
        }
    } catch (error) {
        console.error('Failed to publish to Bynder:', error);
    } finally {
        btnLoaded('#bynder-publish', 'Publish to Bynder');
    }
}

export const storePublishPreferences = async (key, value) => {
    const layoutId = $('#template-group-preview').attr('data-layout-id');

    const { data } = await axios.post('/grid-layout/store-publish-preferences', {
        layout_id: layoutId,
        key: key,
        value: value
    });

    if (data.status === 'error') {
        toastr.error(data.message);
        return;
    }

    return true;
}

export const getPublishPreferences = async (key) => {
    const layoutId = $('#template-group-preview').attr('data-layout-id');

    const { data } = await axios.get('/grid-layout/get-publish-preferences', {
        params: {
            key: key,
            layout_id: layoutId
        }
    });

    return data;
}

export const useSalsifyPreferences = async (settings) => {
    try {
        $('#remember-salsify-settings').prop('checked', true);

        if (!$('#publish_in_background_container').hasClass('d-none')) {
            $('#publish_in_background').val(settings.publish_in_background === true ? 1 : 0).prop('selected', true).trigger('change');
        }

        if (settings.add_property_to_filename) {
            $('#addPropertyToFilenameCheckbox').prop('checked', true).trigger('change');
            $('#filenameOptions').removeClass('d-none');
            $(`input[name="filenameOption"][value="${settings.filename_option}"]`).prop('checked', true);
        }

        $('#useProjectNameInFilenameCheckbox').prop('checked', settings.publish_project_name).trigger('change');
        $('#recentPropertiesSelect').val(settings.recent_properties_select).prop('selected', true).trigger('change');
        $('#publish_format').val(settings.publish_format).trigger('change');
        $('#property_id').val(settings.property_id);
        $('#use-additional-properties').prop('checked', settings.use_additional_properties).trigger('change');

        if (settings.use_additional_properties) {
            if (settings.additional_property_input) {
                $('#additional-property-input').val(settings.additional_property_input)
            }

            if (settings.additional_property_select) {
                const $select = $('#additional-property-select');

                $select.multipleSelect('setSelects', [settings.additional_property_select]);
                $select.multipleSelect('refresh');
            }

            if (settings.property_locale) {
                $('#property_locale').val(settings.property_locale).trigger('change');
            }
        }
    } catch (error) {
        console.error('Error parsing saved Salsify settings', error);
    }
}

export const useAdditionalPropertyLocale = (settings) => {
    if (settings.additional_property_input) {
        $('#additional-property-input').val(settings.additional_property_input);
    }

    if (settings.additional_property_select) {
        const $select = $('#additional-property-select');

        $select.multipleSelect('setSelects', [settings.additional_property_select]);
        $select.multipleSelect('refresh');
    }
}

export const truncateImgName = (name, length = MAX_NAME_LENGHT) => {
    if (name.length > length) {
        const startLength = Math.ceil(length / 2) - 1;
        const endLength = Math.floor(length / 2) - 1;

        return name.slice(0, startLength) + '…' + name.slice(-endLength);
    } else {
        return name;
    }
}

export const clearAssetSelection = (assetId, assetType) => {
    $('.walmart-use-buttons[data-asset-id!="' + assetId + '"]').each(function () {
        if ($(this).data('asset-type') === assetType) {
            const mappedAssetId = $(this).data('asset-id');

            $('.drop-asset-selection-btn[data-asset-id="' + mappedAssetId + '"]').prop('disabled', true);
            $(this).text('Select image type').data('asset-type', '');
        }
    });
}

export const fillAddPropsSelect = (canvas) => {
    const properties = canvas.getObjects().filter(el =>
        el?.templateField?.type === FIELD_TYPE_TEXT ||
        el?.templateField?.type === FIELD_TYPE_MULTI_LINE_TEXT
    );

    const $select = $('#additional-property-select');

    $select.multipleSelect('destroy');

    $select.empty();

    if (properties.length) {
        $select.append(`<option value="">Select Field</option>`);
        properties.forEach(el => {
            const fieldtext = el?.text || '';
            const elementId = el?.templateField?.element_id;
            const fieldName = el?.templateField?.name;

            const isDate = isExcelDate(fieldtext) || isStandardDate(fieldtext);

            const badge = isDate ? `<span class="badge badge-success">Date</span>` : '';

            $select.append(`
                <option value="${elementId}">
                    ${fieldName} ${badge}
                </option>
            `);
        });
    } else {
        $select.append(`<option value="" selected disabled>No additional properties</option>`);
    }

    $select.multipleSelect({
        filter: true,
        placeholder: "Search field...",
        dropWidth: '100%',
        width: '100%'
    });
}

export const getFieldText = (previews, instanceId, elementId) => {
    const preview = previews.find(preview => preview.id === instanceId);

    const field = preview?.canvas.getObjects().find(el => el?.templateField?.element_id === elementId);

    const fieldText = field?.text || '';

    return fieldText;
}

export const isExcelDate = (value) => {
    return !isNaN(value) && value > 25569 && value < 60000;
};

export const isStandardDate = (value) => {
    return /^(\d{4}|\d{1,2})[-\/.](\d{1,2})[-\/.](\d{4})$/.test(value);
};

export const formatDateToISO = (value) => {
    if (isExcelDate(value)) {
        return excelToISODate(value);
    }

    const match = value.match(/^(\d{4}|\d{1,2})[-\/.](\d{1,2})[-\/.](\d{4})$/);
    if (!match) {
        return null;
    }

    const first = match[1];
    const second = match[2];
    const third = match[3];

    if (first.length === 4) {
        return `${first}-${second}-${third}`;
    } else {
        return `${third}-${first.padStart(2, '0')}-${second.padStart(2, '0')}`;
    }
};

export const excelToISODate = (excelDate) => {
    const date = new Date((excelDate - 25569) * 86400000);
    return date.toISOString().split('T')[0];
};

export const isBynderMetaAvailable = async () => {
    const { data } = await axios.get('/bynder-metadata/is-meta-available');

    return data;
}

export const useBynderPreferences = async () => {
    try {
        const settings = await getPublishPreferences(bynder.BYNDER_PUBLISH_SETTINGS);

        if (settings) {
            $('#remember-bynder-settings').prop('checked', true);

            $('#bynder-dpi').val(settings.bynder_dpi).prop('selected', true).trigger('change');
            $('#bynder-image-type').val(settings.bynder_image_type).prop('selected', true).trigger('change');
        }
    } catch (error) {
        console.error('Error parsing saved Bynder settings', error);
    }
}

export const renderWalmartAssetTypeSelect = (instanceId, selectClass = 'walmart-creative-image-type') => {
    return `
    <label class="input-label">Asset type</label>
    <select class="form-control form-control-sm ${selectClass}" data-instance-id="${instanceId}">
        <option value="${walmart.adUnits.MARQUEE_DESKTOP}">
            Marquee Desktop
        </option>
        <option value="${walmart.adUnits.MARQUEE_APP}">
            Marquee App
        </option>
        <option value="${walmart.adUnits.SKYLINE_DESKTOP}">
            Skyline Desktop
        </option>
        <option value="${walmart.adUnits.BRANDBOX_DESKTOP}">
            Brandbox Desktop
        </option>
        <option value="${walmart.adUnits.BRANDBOX_APP}">
            Brandbox App
        </option>
        <option value="${walmart.adUnits.GALLERY_DESKTOP}">
            Gallery Desktop
        </option>
        <option value="${walmart.adUnits.GALLERY_APP}">
            Gallery App
        </option>
        <option value="${walmart.adUnits.LOGO}">
            Logo
        </option>
    </select>
    `;
}

export const detectAssetTypeFlex = (instanceId) => {
    const dimension = canvas_data[instanceId].dimension || {};

    const hasTemplateDimensions = dimension.width !== undefined && dimension.height !== undefined;

    if (hasTemplateDimensions) {
        const key = `${dimension.width}x${dimension.height}`;
        const creativeType = walmart.adUnitsDimensions[key];

        if (creativeType) {
            $(`.walmart-creative-image-type-flex[data-instance-id="${instanceId}"]`).val(creativeType);
        }
    }
}
