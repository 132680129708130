var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-selected-actions d-flex align-items-end flex-column" },
    [
      _c(
        "div",
        [_vm.isActionsAvailable ? _c("SelectedActionsDropdown") : _vm._e()],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }