<template>
  <div>
    <b-alert
      v-if="show"
      show
      variant="warning"
      class="position-warning"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <b-icon
            icon="exclamation-triangle-fill"
            class="mr-2"
          />
          <span>{{ message }}</span>
        </div>
        <div class="warning-actions">
          <b-button
            size="sm"
            variant="outline-warning"
            @click="$emit('dismiss')"
          >
            <b-icon
              icon="x"
              class="mr-1"
            />
            Dismiss
          </b-button>
        </div>
      </div>
    </b-alert>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    message: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.position-warning {
  font-size: 0.9rem;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  
  .warning-actions {
    .btn-sm {
      display: flex !important;
      align-items: center !important;
      min-width: auto;
      padding: 0.25rem 0.75rem;
      color: #000 !important;
    }
  }
}
</style> 